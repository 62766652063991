import { ITranslation, Translation } from './Translation';
import * as _ from 'lodash';

/**
 * A localization translation locator class
 */
export class TranslationLocator {
    /**
     * Will locate a locations item in an array of ITranslations. It will dynamically
     * set the property we are using for the translation.
     *
     * i.e.
     *
     * TranslationLocator( this.translations, 'kittens', 'fr_CA' );
     * TranslationLocator( this.translations, 'dogs', 'fr_CA' );
     * TranslationLocator( this.translations, 'josh', 'fr_CA' );
     *
     * @param {Array<ITranslation>} translations
     * @param {string} label
     * @param {string} locale
     * @param {boolean} createOnNotFound
     * @returns {ITranslation}
     */
    public static locate(
        translations: Array<ITranslation>,
        label: string,
        locale: string,
        createOnNotFound: boolean = true
    ): ITranslation {
        // find the matching translation and return on match
        let translation: ITranslation = _.find(translations, { locale: locale });

        // if no match AND createOnNotFound create a new empty translation
        if (!translation && createOnNotFound) {
            translation = Translation.make(label, locale);
            translations.push(translation);
        }

        return translation;
    }
}
