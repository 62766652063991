import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { ErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { BehaviorSubject } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Snapshot } from '@intouch/its.essential/app/essential/domain/snapshot/Snapshot';
import { ISnapshotOriginator } from '@intouch/its.essential/app/essential/domain/snapshot/ISnapshotOriginator';
import { SnapshotManager } from '@intouch/its.essential/app/essential/domain/snapshot/SnapshotManager';
import { Entity } from '@intouch/its.essential/app/essential/domain/Entity';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';

import { ISurvey, Survey } from '../domain/surveys/survey/Survey';
import { ISurveyApi } from '../api/SurveyApi';
import { BaseItem, IBaseItem } from '../domain/surveys/items/BaseItem';
import { ISection, Section } from '../domain/surveys/Section';
import { Group } from '../domain/surveys/items/Group';
import { IMatrix, IMatrixQuestion } from '../domain/surveys/items/Matrix';
import { DeleteItemModal } from '../modules/surveys/modals/DeleteItemModal';
import { LogicDeleter } from '../domain/surveys/logic/LogicDeleter';
import { ICalendar } from '../domain/surveys/items/Calendar';
import { LogicCreateModal } from '../modules/surveys/modals/LogicCreateModal';
import { ILogic } from '../domain/surveys/logic/Logic';
import { IContainItems } from '../domain/surveys/items/ContainItems';
import { SurveyQuestionMover } from '../domain/surveys/survey/utils/SurveyQuestionMover';
import { IElementLibraryGroupItem } from '../domain/surveys/element-library/IElementLibraryGroup';
import { ItemFactory } from '../domain/surveys/items/ItemFactory';
import { BaseResponseItem } from '../domain/surveys/items/BaseResponseItem';

export interface ISurveyService {
    getSurveyUrl(survey: ISurvey): string;
    /* @deprecated Use saveSurvey  instead */
    save(survey: ISurvey): ng.IPromise<ISurvey>;
    publish(survey: ISurvey): ng.IPromise<ISurvey>;
    saveAndPublish(survey: ISurvey): ng.IPromise<ISurvey>;
    reload(survey: ISurvey): void;
    setSurvey(survey: ISurvey): void;
    getSurvey(): ISurvey;
    loadSurvey(): ng.IPromise<Survey>;
    getSourceLanguage(): string;
}

class SurveyOriginator implements ISnapshotOriginator<Survey> {
    private survey: Survey = null;

    public get currentValue(): Survey {
        return this.survey;
    }

    public createSnapshot(): Snapshot<Survey> {
        return new Snapshot<Survey>(this.survey);
    }

    public setValue(survey: Survey): void {
        this.survey = survey;
    }

    public restoreFromSnapshot(surveyToRestoreTo: Snapshot<Survey>): void {
        this.survey = surveyToRestoreTo.getState();
    }
}

@Service('its.survey.services', SurveyService.IID, SurveyService)
export class SurveyService implements ISurveyService {
    static IID: string = 'itsSurveyService';
    static $inject: Array<string> = [
        'itsSurveyApi',
        '$mdDialog',
        '$translate',
        '$q',
        '$state',
        'iteToaster',
        'APPCONFIG',
    ];

    public surveyLoadDefer: ng.IDeferred<any> = null;
    public surveyValue: BehaviorSubject<Survey> = new BehaviorSubject<Survey>();
    public editingItem: BehaviorSubject<Entity> = new BehaviorSubject<Entity>();
    public isSaving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private snapshotManager: SnapshotManager<Survey> = null;
    private surveyOriginator: SurveyOriginator = new SurveyOriginator();
    private loading: boolean = false;
    private _editingItem: IBaseItem | ISection = null;

    public constructor(
        private surveyApi: ISurveyApi,
        private dialog: ng.material.IDialogService,
        private translate: ng.translate.ITranslateService,
        private q: ng.IQService,
        private stateService: ng.ui.IStateService,
        private toaster: IToaster,
        protected config: any
    ) {
        this.surveyLoadDefer = this.q.defer();
        this.snapshotManager = new SnapshotManager<Survey>(this.surveyOriginator);
    }

    /**
     * Gets the survey's custom url - defaults to config url
     *
     * @param {ISurvey} survey
     * @returns {string}
     */
    public getSurveyUrl(survey: ISurvey): string {
        return survey.settings && survey.settings.customDomain
            ? 'https://' + survey.settings.customDomain + '/#/survey'
            : this.config.app.endpoint;
    }

    public isLoading(): boolean {
        return this.loading;
    }

    public saveSurvey(surveyToSave: ISurvey): ng.IPromise<ISurvey> {
        this.isSaving.next(true);
        return this.getLatestSurveyByOriginalUuid(surveyToSave.originalUuid).then((latestSurvey: ISurvey) => {
            if (this.isLatestSurvey(surveyToSave, latestSurvey)) {
                return this.updateAndSetLatestSurvey(surveyToSave);
            } else {
                this.showIncompatibleVersionPrompt(latestSurvey);
                this.isSaving.next(false);
                return this.q.reject();
            }
        });
    }

    public saveSurveyAndNotify(surveyToSave: ISurvey): ng.IPromise<ISurvey> {
        return this.saveSurvey(surveyToSave)
            .then((savedSurvey) => {
                this.toaster.success('SURVEYS.SURVEY_SAVED');
                return savedSurvey;
            })
            .catch(() => {
                this.toaster.error('ERRORS.FAILED_SURVEY_UPDATE');
                return this.q.reject();
            });
    }

    /**
     * [IMPORTANT] Use saveSurvey instead
     *
     * Save the current survey, first verify we are on the latest version and if
     * we aren't, notify the user. If we're on the latest version, we update/save and reload with the latest information.
     *
     * @param survey
     * @param reload
     * @deprecated Use saveSurvey instead
     */
    public save(survey: ISurvey, reload: boolean = true): ng.IPromise<ISurvey> {
        const q: ng.IDeferred<any> = this.q.defer();
        let latestSurvey: ISurvey = null;

        this.isSaving.next(true);
        this.surveyApi
            .findSurveys(null, null, 'filter[original_uuid]=' + survey.originalUuid)
            .then((pagedResponse: PagedEntities) => {
                const surveys: Array<ISurvey> = pagedResponse.getEntities();
                if (surveys && surveys.length > 0) {
                    // we should always only have 1 result since we're filtering by original UUID
                    latestSurvey = surveys[0];

                    if (latestSurvey.updatedAt === survey.updatedAt && latestSurvey.uuid === survey.uuid) {
                        this.surveyApi
                            .updateSurvey(survey)
                            .then((updatedSurvey: ISurvey) => {
                                updatedSurvey.setAllItemLogicReferences();
                                if (reload) {
                                    this.reload(updatedSurvey);
                                }
                                this.setSurvey(updatedSurvey);
                                q.resolve(updatedSurvey);
                            })
                            .catch(() => {
                                q.reject(new ErrorResponse('Failed to update survey'));
                            });
                    } else {
                        this.dialog
                            .show(
                                Confirm.instantiate({
                                    locals: {
                                        cancelText: this.translate.instant('GENERAL.CLOSE'),
                                        confirmText: this.translate.instant('GENERAL.RELOAD_NOW'),
                                        primaryButton: 'confirm',
                                        title: this.translate.instant('GENERAL.CHANGES_MADE_TITLE'),
                                        description: this.translate.instant('GENERAL.CHANGES_MADE_TEXT'),
                                    },
                                })
                            )
                            .then((confirmed: boolean) => {
                                if (confirmed && latestSurvey) {
                                    this.reload(latestSurvey, true);
                                } else {
                                    q.reject(new ErrorResponse('Cancelled reloading'));
                                }
                            });
                    }
                } else {
                    // survey no longer exists
                    q.reject(new ErrorResponse('Survey no longer exists'));
                }
            });
        q.promise.finally(() => {
            this.isSaving.next(false);
        });

        return q.promise;
    }

    public editItemLogic(logic: ILogic, parentUuid: string): void {
        this.dialog
            .show(
                LogicCreateModal.instantiate({
                    locals: {
                        type: logic.type,
                        parentItemUuid: parentUuid,
                        logic: logic.clone(),
                        editing: true,
                    },
                })
            )
            .then(() => {
                this.toaster.success('SURVEYS.SETTINGS.LOGIC_UPDATED');
            });
    }

    /**
     * Publish the survey
     *
     * @param survey
     */
    public publish(survey: ISurvey): ng.IPromise<ISurvey> {
        return this.surveyApi.publishSurvey(survey.uuid).then((newSurvey: ISurvey) => {
            return newSurvey;
        });
    }

    /**
     * Save then publish, then reload the survey
     *
     * @param survey
     */
    public saveAndPublish(survey: ISurvey): ng.IPromise<ISurvey> {
        return this.save(survey, false).then((savedSurvey: ISurvey) => {
            return this.publish(savedSurvey).then((publishedSurvey: ISurvey) => {
                this.reload(publishedSurvey);
                return publishedSurvey;
            });
        });
    }

    /**
     * Reloads the current route with the latest survey passed, optionally force refreshing and notifying/reloading
     *
     * @param survey
     * @param forceReload
     */
    public reload(survey: ISurvey, forceReload: boolean = false): void {
        if (survey && survey.hasOwnProperty('uuid')) {
            this.stateService.go(
                this.stateService.current,
                {
                    uuid: survey.uuid,
                },
                {
                    location: 'replace',
                    notify: forceReload,
                    reload: forceReload,
                }
            );
        }
    }

    public setSurvey(survey: ISurvey): void {
        if (survey) {
            survey.setAllItemLogicReferences();
            survey.buildLabelMap();
        }
        this.surveyOriginator.setValue(<Survey>survey);
        this.surveyValue.next(<Survey>survey);
    }

    public getSurvey(): ISurvey {
        return this.surveyOriginator.currentValue;
    }

    public getSurveyPromise(): ng.IPromise<Survey> {
        if (this.loading) {
            return this.surveyLoadDefer.promise;
        } else if (this.surveyOriginator.currentValue) {
            return this.q.resolve(this.surveyOriginator.currentValue);
        } else {
            return this.loadSurvey();
        }
    }

    public showItemDeleteConfirmation(item: IBaseItem | ISection): void {
        // figure out what it is to serve up appropriate message.
        let itemType: string;
        if (item.type === 'section') {
            itemType = this.translate.instant('GENERAL.PAGE');
        } else if (item.type === 'group') {
            itemType = this.translate.instant('GENERAL.GROUP');
        } else {
            itemType = this.translate.instant('GENERAL.QUESTION');
        }

        // if this item is used in logic, show the warning modal
        if (
            item.logicReferences.length > 0 ||
            (<IBaseItem>item).logic ||
            (item instanceof Section && item.hasItemWithLogicReference()) ||
            (item instanceof Group && item.hasItemWithLogicReference())
        ) {
            this.showDeleteBlock(item);
            return;
        }

        // show confirmation dialog
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translate.instant('SURVEYS.ITEMS.DELETE.TITLE', { itemType: itemType }),
                        description: this.translate.instant('SURVEYS.ITEMS.DELETE.DESCRIPTION', {
                            itemType: itemType.toLowerCase(),
                        }),
                        confirmText: this.translate.instant('SURVEYS.ITEMS.DELETE.CONFIRM', { itemType: itemType }),
                        cancelText: this.translate.instant('GENERAL.CANCEL'),
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((result: boolean) => {
                if (result) {
                    this.executeDelete(item);
                }
            });
    }

    public removeQuestion(item: IMatrix, question: IMatrixQuestion): void {
        if (question.logicReferences.length > 0) {
            this.showDeleteQuestionBlock(item, question);
        } else {
            item.removeQuestion(question);
        }
    }

    public loadSurvey(surveyUuid?: string): ng.IPromise<Survey> {
        if (this.loading) {
            return this.surveyLoadDefer.promise;
        }

        if (!surveyUuid) {
            surveyUuid = this.stateService.params['uuid'];
        }

        this.loading = true;
        this.setSurvey(null);
        this.surveyApi
            .findSurveyByUuid(surveyUuid)
            .then((survey: ISurvey) => {
                this.setSurvey(survey);
                this.surveyLoadDefer.resolve(survey);
            })
            .catch(() => {
                this.surveyLoadDefer.reject();
            })
            .finally(() => {
                this.loading = false;
                this.surveyLoadDefer = this.q.defer();
            });
        return this.surveyLoadDefer.promise;
    }

    public setEditingItem(item: ISection | IBaseItem): void {
        this._editingItem = item;
        this.editingItem.next(item);
    }

    public saveSnapshot(): void {
        this.snapshotManager.saveSnapshot();
    }

    public restoreToPreviousSnapshot(): void {
        this.snapshotManager.restoreToPreviousSnapshot();
        this.setSurvey(this.surveyOriginator.currentValue);
    }

    public saveSnapshotAndEdit(selectedItem: BaseItem): void {
        this.saveSnapshot();
        this.editingItem.next(selectedItem);
    }

    public revertEditAndRestoreSnapshot(): void {
        this.restoreToPreviousSnapshot();
        this.editingItem.next(null);
    }

    public addAndEditNewSection(section: ISection, index: number = null): void {
        this.addNewSection(section, index);
        this.setEditingItem(section);
    }

    public addNewSection(section: ISection, index: number = null): void {
        const survey: ISurvey = this.getSurvey();
        this.saveSnapshot();
        if (index === null) {
            index = survey.sections.length;
        }
        survey.addSection(section, index);
        this.setSurvey(survey);
    }

    public addQuestionToParentAndEdit(question: IBaseItem, parent: IContainItems, index: number): void {
        this.saveSnapshot();
        try {
            this.addQuestionToParent(question, parent, index);
            this.setEditingItem(question);
        } catch (exception) {
            this.removeLatestSnapshot();
        }
    }

    /**
     *
     * @param {IElementLibraryGroupItem} libraryGroupItem
     * @param {IContainItems} targetParent
     * @param {number} targetIndex
     * @param {boolean} fromLibrary
     */
    public startQuestionEdit(
        libraryGroupItem: IElementLibraryGroupItem,
        targetParent: IContainItems = null,
        targetIndex: number = null,
        fromLibrary: boolean = false
    ): void {
        const newQuestion: IBaseItem = <IBaseItem>ItemFactory.create(libraryGroupItem.type, libraryGroupItem.data);

        this.initNewQuestion(newQuestion, fromLibrary);

        targetParent = targetParent || <IContainItems>this.surveyOriginator.currentValue.getLastSection();
        targetIndex = !NumberUtils.isNumber(targetIndex) || targetIndex < 0 ? targetParent.items.length : targetIndex;

        this.addQuestionToParentAndEdit(newQuestion, targetParent, targetIndex);
    }

    public getSourceLanguage(): string {
        const survey: ISurvey = this.getSurvey();
        return !!survey && !!survey.settings && !!survey.settings.sourceLanguage
            ? this.getSurvey().settings.sourceLanguage
            : 'en';
    }

    private initNewQuestion(newQuestion: IBaseItem, fromLibrary: boolean = false): void {
        if (fromLibrary) {
            if (newQuestion instanceof BaseResponseItem && newQuestion.settings['showOther']) {
                newQuestion.addOtherResponse();
            }
        } else {
            newQuestion.initialize(this.translate);
        }
    }

    private removeLatestSnapshot(): void {
        this.snapshotManager.restoreToPreviousSnapshot();
    }

    private addQuestionToParent(question: IBaseItem, parent: IContainItems, index: number): void {
        // don't allow more than one nps question
        const survey: ISurvey = this.getSurvey();
        if (!SurveyQuestionMover.canQuestionBeAddedToSurvey(question, survey)) {
            this.toaster.warn(this.translate.instant('SURVEYS.ITEMS.NPS.NPS_QUESTION_EXISTS'));
            throw new Error('Unable to add question to section');
        }

        try {
            parent.addItem(question, index);
        } catch (exception) {
            this.showInvalidItemAddToGroup(question);
            throw exception;
        }
    }

    private showInvalidItemAddToGroup(question: IBaseItem): void {
        const translationParams: any = { type: question.type };
        const warningMessage: string = this.translate.instant('ERRORS.SURVEY.INVALID_GROUP_ADD', translationParams);
        this.toaster.warn(warningMessage);
    }

    private executeDelete(item: IBaseItem | ISection): ng.IPromise<ISurvey> {
        const survey: ISurvey = this.getSurvey();
        survey.remove(item.uuid);
        // if we just removed the field designated as the custom submission date, disable it on the survey level.
        if (item.type === 'calendar' && (<ICalendar>item).settings.isDateOfDocument) {
            survey.settings.enableCustomSubmissionDate = false;
        }
        return this.saveSurveyAndNotify(survey);
    }

    private showDeleteQuestionBlock(question: IMatrix, matrixQuestion: IMatrixQuestion): void {
        const survey: ISurvey = this.getSurvey();
        this.dialog
            .show(
                DeleteItemModal.instantiate({
                    locals: {
                        surveyUuid: survey.uuid,
                    },
                })
            )
            .then((state: boolean) => {
                if (state) {
                    LogicDeleter.delete(survey, matrixQuestion);
                    question.removeQuestion(matrixQuestion);
                    this.setSurvey(survey);
                }
            });
    }

    private showDeleteBlock(item: IBaseItem | ISection): void {
        const survey: ISurvey = this.getSurvey();
        this.dialog
            .show(
                DeleteItemModal.instantiate({
                    locals: {
                        surveyUuid: survey.uuid,
                    },
                })
            )
            .then((state: boolean) => {
                if (state) {
                    this.saveSnapshot();
                    LogicDeleter.delete(survey, item);
                    this.executeDelete(item);
                }
            });
    }

    private getLatestSurveyByOriginalUuid(originalUuid: string): ng.IPromise<ISurvey> {
        return this.surveyApi
            .findSurveys(null, null, 'filter[original_uuid]=' + originalUuid)
            .then((pagedResponse: PagedEntities) => {
                const surveys: Array<ISurvey> = pagedResponse.getEntities();
                if (surveys && surveys.length > 0) {
                    return surveys[0];
                } else {
                    throw new Error('Survey no longer exists');
                }
            });
    }

    private updateAndSetLatestSurvey(survey: ISurvey): ng.IPromise<ISurvey> {
        return this.surveyApi
            .updateSurvey(survey)
            .then((updatedSurvey) => {
                updatedSurvey.setAllItemLogicReferences();
                this.setSurvey(updatedSurvey);
                return updatedSurvey;
            })
            .finally(() => {
                this.isSaving.next(false);
            });
    }

    private showIncompatibleVersionPrompt(latestSurvey: ISurvey): void {
        const confirmDialogSettings: any = {
            locals: {
                cancelText: this.translate.instant('GENERAL.CLOSE'),
                confirmText: this.translate.instant('GENERAL.RELOAD_NOW'),
                primaryButton: 'confirm',
                title: this.translate.instant('GENERAL.CHANGES_MADE_TITLE'),
                description: this.translate.instant('GENERAL.CHANGES_MADE_TEXT'),
            },
        };
        this.dialog.show(Confirm.instantiate(confirmDialogSettings)).then((confirmed: boolean) => {
            if (confirmed) {
                this.reload(latestSurvey, true);
                this.setEditingItem(null);
                this.restoreToPreviousSnapshot();
            }
        });
    }

    private isLatestSurvey(currentSurvey: ISurvey, latestSurvey: ISurvey): boolean {
        return latestSurvey.updatedAt === currentSurvey.updatedAt && latestSurvey.uuid === currentSurvey.uuid;
    }
}
