import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';
import { HiddenInput } from '../../../../../../domain/surveys/items/HiddenInput';

@Component('its.survey.module.surveys', HiddenInputSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/hidden-input/HiddenInputSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: HiddenInputSettingsComponent.getBindings(),
})
class HiddenInputSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'HiddenInputSettingsComponent';
    static CID: string = 'itsHiddenInputSettings';
    static $inject: string[] = [];

    public item: HiddenInput;
}
