import { Service } from '@intouch/its.essential/app/essential/decorators/Service';

/**
 * The group service interface
 */
export interface INavigationService {
    setBackRoute(state: string, data?: any): void;
    hasBackRoute(): boolean;
    goBack(): void;
    clearBackRoute(): void;
}

/**
 * The group service class
 */
@Service('its.survey.services', NavigationService.IID, NavigationService)
export class NavigationService implements INavigationService {
    static IID: string = 'itsNavigationService';
    static $inject: Array<string> = ['$state'];

    public backRoute: string = null;
    public backRouteData: any = {};

    public constructor(private state: ng.ui.IStateService) {}

    public setBackRoute(state: string, data: any = null): void {
        if (!state) {
            throw new Error('No state parameter set to save in setBackRoute');
        }

        this.clearBackRoute();
        this.backRoute = state;
        this.backRouteData = data;
    }

    public getBackRoute(): string {
        return this.backRoute;
    }

    public hasBackRoute(): boolean {
        return !!this.backRoute;
    }

    public clearBackRoute(): void {
        this.backRoute = null;
        this.backRouteData = {};
    }

    public goBack(): void {
        const stateCopy: string = this.backRoute,
            stateData: any = this.backRouteData;

        this.backRoute = null;
        this.backRouteData = null;
        this.state.go(stateCopy, stateData);
    }
}
