import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';

/**
 * A checkbox form item interface
 */
export interface ISkip extends IEntity {
    action: string;
    operator: string;
    operand: string;
}

/**
 * A checkbox form item class
 */
export class Skip extends Entity implements ISkip {
    action: string = null;
    operator: string = null;
    operand: string = null;
}
