import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { IUser } from '@intouch/its.essential/app/essential/domain/access/User';
import { IOrganization } from '@intouch/its.essential/app/essential/domain/access/Organization';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { IConfigurable } from '@intouch/its.essential/app/essential/services/IConfigurable';

/**
 * The survey session configuration interface
 */
interface ISurveySessionConfiguration {
    appUrl?: string;
}

/**
 * The session container to hold all session access logic
 */
export interface ISurveySession extends IConfigurable {
    hasUser(): boolean;
    getUser(): IUser;
    getToken(): IToken;
    hasOrganization(): boolean;
    getOrganization(): IOrganization;
    flush(): void;
}

/**
 * The user service class
 */
@Service('its.survey.services', SurveySession.IID, SurveySession)
class SurveySession implements ISurveySession {
    static IID: string = 'itsSurveySession';
    static $inject: Array<string> = ['iteAccessService'];

    /**
     * The  class constructor
     *
     * @param accessService
     */
    public constructor(private accessService: IAccessService) {}

    /**
     * Sets configurable data (such as the app url)
     *
     * @param data
     */
    public configure(data: ISurveySessionConfiguration): void {
        // todo; implement
    }

    /**
     * Checks if we have a user in the session
     *
     * @returns {boolean}
     */
    public hasUser(): boolean {
        return this.getUser() !== null;
    }

    /**
     * Retrieves our currently authenticated user
     *
     * @returns {IUser}
     */
    public getUser(): IUser {
        return this.getToken().getUser();
    }

    /**
     * Retrieves our current JWT token class
     *
     * @returns {IToken}
     */
    public getToken(): IToken {
        return this.accessService.getToken();
    }

    /**
     * Checks if we have an organization in the session
     *
     * @returns {boolean}
     */
    public hasOrganization(): boolean {
        return !!this.getOrganization();
    }

    /**
     * Gets the current organization from the session
     *
     * @returns {IOrganization}
     */
    public getOrganization(): IOrganization {
        return this.accessService.getOrganization();
    }

    /**
     * Flushes the current session
     */
    public flush(): void {
        return;
    }
}
