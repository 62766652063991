import { IContainItems } from '../../items/ContainItems';
import { IBaseItem } from '../../items/BaseItem';
import { ISurvey } from '../Survey';

export class SurveyQuestionMover {
    public static canQuestionBeAddedToSurvey(question: IBaseItem, survey: ISurvey): boolean {
        if (question.type === 'nps' && survey.hasQuestionType('nps')) {
            return false;
        }

        return true;
    }

    public static moveQuestion(
        currentParent: IContainItems,
        currentQuestionIndex: number,
        targetParent: IContainItems,
        targetIndex: number
    ): void {
        const questionChangingParent: IBaseItem = currentParent.items[currentQuestionIndex];

        if (targetParent.canHoldItemOfType(questionChangingParent.type)) {
            targetParent.items.splice(targetIndex, 0, questionChangingParent);
            const possibleIndexOffset: number = SurveyQuestionMover.calculateIndexOffsetFromInternalMove(
                currentParent,
                currentQuestionIndex,
                targetParent,
                targetIndex
            );
            currentQuestionIndex += possibleIndexOffset;
            SurveyQuestionMover.removeQuestionFromParent(currentParent, currentQuestionIndex);
        } else {
            throw new Error("Tried to change question to a parent which can't hold its type");
        }
    }

    private static removeQuestionFromParent(parent: IContainItems, questionIndex: number): void {
        parent.items.splice(questionIndex, 1);
    }

    private static calculateIndexOffsetFromInternalMove(
        currentParent: IContainItems,
        currentIndex: number,
        targetParent: IContainItems,
        targetIndex: number
    ): number {
        if (currentParent.items === targetParent.items && targetIndex <= currentIndex) {
            return 1;
        }

        return 0;
    }
}
