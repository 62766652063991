import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Text } from '../../../../../../domain/surveys/items/Text';
import {
    FroalaProvider,
    IFroalaCustomButtonConfig,
    IFroalaCustomButtonIcon,
} from '@intouch/its.essential/app/essential/domain/FroalaProvider';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';
import * as _ from 'lodash';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import FroalaEditor from 'froala-editor';
import PipedTextSvg from '../../../../../../../assets/images/piped-text-01.svg';

@Component('its.survey.module.surveys', TextSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/display-items/text/TextSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: TextSettingsComponent.getBindings(),
})
class TextSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'TextSettingsComponent';
    static CID: string = 'itsTextSettings';
    static $inject: string[] = ['$translate', 'iteLogger', 'APPCONFIG'];

    public item: Text;

    public froalaReference: any = null;
    public froalaOptions: any = null;

    public constructor(
        private translate: ng.translate.ITranslateService,
        private logger: ILogger,
        private config: any
    ) {
        super();
    }

    $onInit(): void {
        this.initFroala();
    }

    public bootstrapFroalaEditor(initControls: any): void {
        this.registerPipedTextFroala();
        initControls.initialize(this.froalaOptions);
        this.froalaReference = initControls.getEditor();
    }

    private registerPipedTextFroala(): void {
        const buttonConfig: IFroalaCustomButtonConfig = {
            title: this.translate.instant('GENERAL.INSERT_PIPED_TEXT'),
            icon: 'pipedText',
            undo: true,
            focus: true,
            showOnMobile: false,
            callback: () => {
                const element: HTMLElement = document.getElementById('open-pipe-text-menu-froalaEditor');
                element.click();
            },
        };

        const icon: IFroalaCustomButtonIcon = {
            name: 'pipedText',
            config: {
                SRC: PipedTextSvg,
                template: 'image',
            },
        };

        FroalaEditor.DefineIcon(icon.name, icon.config);
        FroalaEditor.RegisterCommand('itsInsertPipedText', buttonConfig);
    }

    private initFroala(): void {
        this.froalaOptions = _.merge(
            {
                placeholderText: this.translate.instant('SURVEYS.ITEMS.TEXT.TYPE_SOMETHING'),
                toolbarButtons: [
                    'undo',
                    'redo',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    'fontFamily',
                    'fontSize',
                    'lineHeight',
                    'color',
                    '|',
                    'outdent',
                    'indent',
                    '|',
                    'align',
                    'formatOL',
                    'formatUL',
                    'insertLink',
                    '|',
                    'html',
                    '|',
                    'itsInsertPipedText',
                ],
                heightMin: 125,
                linkInsertButtons: [],
                linkAlwaysBlank: true,
                quickInsertTags: null,
                pluginsEnabled: [
                    'align',
                    'charCounter',
                    'codeBeautifier',
                    'codeView',
                    'colors',
                    'embedly',
                    'fontFamily',
                    'fontSize',
                    'inlineStyle',
                    'lineBreaker',
                    'link',
                    'lists',
                    'paragraphFormat',
                    'paragraphStyle',
                    'quote',
                    'table',
                    'lineHeight',
                ],
                htmlAllowedTags: [
                    'a',
                    'b',
                    'em',
                    'u',
                    'i',
                    'sup',
                    'sub',
                    'strong',
                    's',
                    'span',
                    'strike',
                    'font',
                    'h1',
                    'h2',
                    'h3',
                    'pre',
                    'blockquote',
                    'div',
                    'code',
                    'p',
                    'center',
                    'del',
                    'dd',
                    'dl',
                    'dt',
                    'li',
                    'ol',
                    'ul',
                    'br',
                    'hr',
                ],
            },
            FroalaProvider.defaultConfig()
        );
        this.froalaOptions = FroalaProvider.convertOptionsToV4(this.froalaOptions);

        if (this.config.froala && this.config.froala.key) {
            this.froalaOptions.key = this.config.froala.key;
        } else {
            this.logger.warn('Unable to set Froala key');
        }
    }
}
