import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { BaseItem, IBaseItem } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { IHelpTextSettings, IHelpTextTranslation } from './shared/HelpText';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { ITranslatedEntity } from '../../ITranslatedEntity';

/**
 * The ImageUpload Translation interface
 */
export type IImageUploadTranslation = IHelpTextTranslation;

/**
 * The ImageUpload Translation class
 */
export class ImageUploadTranslation extends Translation implements IImageUploadTranslation {
    additionalText: string = null;
}

/**
 * The ImageUpload settings interface
 */
export type IImageUploadSettings = IBaseSettings;

/**
 * The ImageUpload settings class
 */
export class ImageUploadSettings
    extends BaseSettings
    implements IImageUploadSettings, IHelpTextSettings<ImageUploadTranslation>, ITranslatedEntity
{
    additionalText: string = null;
    translations: Array<ImageUploadTranslation> = [];

    public fromJson(json: any, convertToCamel: boolean = true): IImageUploadSettings {
        super.fromJson(json, convertToCamel);
        if (json.translations && json.translations.length) {
            this.translations = EntityBuilder.buildMany<IImageUploadTranslation>(
                ImageUploadTranslation,
                json.translations,
                convertToCamel
            );
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.additionalText) {
            keys.push('additionalText');
        }
        return keys;
    }

    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

/**
 * The ImageUpload interface
 */
export type IImageUpload = IBaseItem;

/**
 * The ImageUpload class
 */
export class ImageUpload extends BaseItem implements IImageUpload {
    type: string = 'image_upload';
    settings: IImageUploadSettings = new ImageUploadSettings();

    @NotEnumerable
    _healthPoints: number = 5;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey. This overrides the BaseItem default value.
     */
    get healthPoints(): number {
        return this._healthPoints;
    }

    /**
     * Build ImageUpload question from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IImageUpload}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): this {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<IImageUploadSettings>(
                ImageUploadSettings,
                jsonObject.settings,
                convertToCamel
            );
        }

        return this;
    }
}
