import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Survey } from '../../../../../domain/surveys/survey/Survey';
import { Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { EmailDistribution, IEmailDistribution } from '../../../../../domain/contact-center/EmailDistribution';
import { ISmsDistribution, SmsDistribution } from '../../../../../domain/contact-center/SmsDistribution';
import { SurveyIqlService } from '../../../../../services/SurveyIqlService';
import { SurveyApi } from '../../../../../api/SurveyApi';
import { IHighchartsSeries } from '@intouch/iql-ts-sdk/src/domain/data-structures/HighchartsSeries';
import * as _ from 'lodash';
import { SurveyChartService } from '../../../../../services/SurveyChartService';

class DistributionMetrics {
    submissions: number = 0;
    undelivered: number = 0;
    sent: number = 0;
}

interface IDistribution extends IEmailDistribution, ISmsDistribution {
    type: string;
    metrics: any;
}

@Component('its.survey.module.surveys', SurveyDistributionsListingComponent.CID, {
    template:
        require('/app/modules/surveys/summary/components/survey-distributions-listing/SurveyDistributionsListingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        survey: '<',
    },
})
class SurveyDistributionsListingComponent {
    static IID: string = 'SurveyDistributionsListingComponent';
    static CID: string = 'itsSurveyDistributionsListing';
    static $inject: Array<string> = [
        '$translate',
        '$mdMedia',
        '$state',
        '$q',
        'itsSurveyApi',
        '$timeout',
        'itsSurveyChartService',
        'itsSurveyIqlService',
    ];

    public survey: Survey;
    public selectedDistribution: IDistribution = null;
    public distributions: IDistribution[] = [];
    public distributionsLoading: boolean = true;
    public errorLoadingListing: boolean = false;

    public constructor(
        private translate: ng.translate.ITranslateService,
        private media: ng.material.IMedia,
        private stateService: ng.ui.IStateService,
        private q: ng.IQService,
        private surveyApi: SurveyApi,
        private timeout: any,
        private surveyChartService: SurveyChartService,
        private surveyIqlService: SurveyIqlService
    ) {}

    $onChanges(): void {
        if (this.survey) {
            this.loadDistributionsListing();
        }
    }

    public goToDistributions(): void {
        this.stateService.go('home.surveys.edit.distributions.link', { uuid: this.survey.uuid });
    }

    public selectDistribution(distribution: IDistribution): void {
        if (distribution !== this.selectedDistribution) {
            this.selectedDistribution = distribution;
            this.loadDistributionPieChart(this.selectedDistribution);
        }
    }

    public clearSelectedDistribution(): void {
        this.selectedDistribution = null;
    }

    public loadDistributionPieChart(distribution: IDistribution): void {
        const pieChartDistributionMetricsSeries: IHighchartsSeries[] =
            this.getMetricsSeriesForDistribution(distribution);
        const pieChart: any = this.surveyChartService.getDistributionMetricsPieChart('distribution-pie-chart');

        for (const series of pieChartDistributionMetricsSeries) {
            pieChart.addSeries(series);
        }
    }

    public getDistributionCompletionRate(distribution: IDistribution): number {
        if (distribution.metrics.sent > 0) {
            return Math.min(distribution.metrics.submissions / distribution.metrics.sent, 1) * 100;
        }
        return null;
    }

    private getMetricsSeriesForDistribution(distribution: IDistribution): IHighchartsSeries[] {
        const sentCount: number = distribution.metrics.sent;
        const submissionsCount: number = distribution.metrics.submissions;
        const undeliveredCount: number = distribution.metrics.undelivered;
        const totalSentCount: number = sentCount + undeliveredCount;
        let remainingCount: number = sentCount - submissionsCount;
        if (remainingCount < 0) {
            remainingCount = 0;
        }
        return [
            {
                name: distribution.name,
                data: [
                    {
                        name: this.translate.instant('SURVEYS.SUMMARY.UNDELIVERED'),
                        y: undeliveredCount,
                        x: null,
                    },
                    {
                        name: this.translate.instant('GENERAL.SUBMISSIONS'),
                        y: submissionsCount,
                        x: null,
                    },
                    {
                        name: this.translate.instant('GENERAL.REMAINING'),
                        y: remainingCount,
                        x: null,
                    },
                ],
            },
            {
                name: '<b>' + totalSentCount + '</b> ' + this.translate.instant('SURVEYS.SUMMARY.TOTAL_SURVEYS_SENT'),
                type: 'area',
                color: '#FFF',
                data: [],
            },
        ];
    }

    private loadDistributionsListing(): ng.IPromise<any> {
        const defaultPager: Pager = Pager.make(1, 'created_at', 'desc', 5);
        const surveyEmailsPromise: ng.IPromise<PagedEntities> = this.surveyApi.getSurveyEmails(
            this.survey.originalUuid,
            defaultPager
        );
        const surveySmsPromise: ng.IPromise<PagedEntities> = this.surveyApi.getSurveySmsList(
            this.survey.originalUuid,
            defaultPager
        );
        this.distributionsLoading = true;
        this.errorLoadingListing = false;
        return this.q
            .all([surveyEmailsPromise, surveySmsPromise])
            .then((promiseResults) => {
                const distributions: IDistribution[] = this.mergeDistributions(promiseResults);
                this.distributions = _.orderBy(distributions, 'createdAt');
                return this.setMetricsForDistributions(this.distributions);
            })
            .catch(() => {
                this.errorLoadingListing = true;
            })
            .finally(() => {
                this.distributionsLoading = false;
            });
    }

    private mergeDistributions(promiseResults: PagedEntities[]): IDistribution[] {
        const emailDistributions: EmailDistribution[] = promiseResults[0].getEntities();
        const emailList: IDistribution[] = this.setDistributionsTypeAndInitMetrics('email', emailDistributions);
        const smsDistributions: SmsDistribution[] = promiseResults[1].getEntities();
        const smsList: IDistribution[] = this.setDistributionsTypeAndInitMetrics('sms', smsDistributions);
        return _.concat<IDistribution>(emailList, smsList);
    }

    private setDistributionsTypeAndInitMetrics(
        type: string,
        distributions: (EmailDistribution | SmsDistribution)[]
    ): IDistribution[] {
        return distributions.map((distribution: IDistribution) => {
            distribution.type = type;
            distribution.metrics = new DistributionMetrics();
            return distribution;
        });
    }

    private setMetricsForDistributions(distributions: IDistribution[]): ng.IPromise<any> {
        const promiseArray: any[] = [];
        for (const distribution of distributions) {
            promiseArray.push(this.getSendAndFailedCountByDistribution(distribution));
            promiseArray.push(this.getRecordCountByDistribution(distribution));
        }
        return this.q.all(promiseArray);
    }

    private getSendAndFailedCountByDistribution(distribution: IDistribution): ng.IPromise<any> {
        return this.surveyIqlService
            .getSentAndFailedCountByDistribution(distribution.uuid)
            .then((sentAndFailedMetrics) => {
                distribution.metrics = _.merge(distribution.metrics, sentAndFailedMetrics);
            });
    }

    private getRecordCountByDistribution(distribution: IDistribution): ng.IPromise<any> {
        return this.surveyIqlService.getRecordCountByDistribution(distribution.uuid).then((recordCount) => {
            distribution.metrics.submissions = recordCount;
        });
    }
}
