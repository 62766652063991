import { Directive } from '@intouch/its.essential/app/essential/decorators/Directive';
import { TokenTransformerService } from '../services/TokenTransformerService';

@Directive('its.survey.directives', TokensToChips.CID)
class TokensToChips {
    static CID: string = 'itsTokensToChips';
    static $inject: Array<string> = ['itsTokenTransformerService', '$compile', '$sanitize'];

    public restrict: string = 'A';

    public link: ng.IDirectiveLinkFn = (scope: ng.IScope, element: any, attrs: ng.IAttributes): void => {
        scope.$watch(attrs['itsTokensToChips'], (text: string) => {
            if (text) {
                const sanitized: string = this.sanitizeService(text);
                this.tokenTransformerService.transformToChips(sanitized).then((transformedTextWithHtml) => {
                    element.html(transformedTextWithHtml);
                    this.compileService(element.contents())(scope);
                });
            } else {
                element.html('');
            }
        });
    };

    public constructor(
        private tokenTransformerService: TokenTransformerService,
        private compileService: ng.ICompileService,
        private sanitizeService: any
    ) {
        TokensToChips.prototype.link = this.link;
    }
}
