import { Configuration } from '@intouch/its.essential/app/essential/decorators/Configuration';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IUser } from '@intouch/its.essential/app/essential/domain/access/User';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { ILogHandler } from '@intouch/its.essential/app/essential/services/logger/LogHandler';
import { IFileProcessingService } from '@intouch/its.essential/app/essential/services/FileProcessingService';
import { IAccessApi } from '@intouch/its.essential/app/essential/api/AccessApi';
import { AuthState } from '@intouch/its.essential/app/essential/services/access/AuthState';
import { datadogRum } from '@datadog/browser-rum';

@Configuration('its.survey.module.main', MainConfiguration)
class MainConfiguration {
    /**
     * Build an instance of the configuration for angular binding
     *
     * @returns {any}
     */
    public static instance(): any {
        const config: any = (
            mdThemingProvider: ng.material.IThemingProvider,
            urlRouterProvider: ng.ui.IUrlRouterProvider,
            httpProvider: ng.IHttpProvider,
            stateProvider: ng.ui.IStateProvider
        ) => new MainConfiguration(mdThemingProvider, urlRouterProvider, httpProvider, stateProvider);

        config.$inject = ['$mdThemingProvider', '$urlRouterProvider', '$httpProvider', '$stateProvider'];
        return config;
    }

    /**
     * Build the main sites configuration
     *
     * @param mdThemingProvider
     * @param urlRouterProvider
     * @param httpProvider
     * @param stateProvider
     */
    constructor(
        private mdThemingProvider: ng.material.IThemingProvider,
        private urlRouterProvider: ng.ui.IUrlRouterProvider,
        private httpProvider: ng.IHttpProvider,
        private stateProvider: ng.ui.IStateProvider
    ) {
        this.buildTheme();
        this.buildStates();
        this.setupHttpProvider();
    }

    /**
     * Build the main sites theme
     */
    public buildTheme(): void {
        this.mdThemingProvider.definePalette('IntouchSurvey - Primary', {
            '50': '#eaf8fd',
            '100': '#caeffa',
            '200': '#a7e4f7',
            '300': '#83d9f4',
            '400': '#69d0f1',
            '500': '#4ec8ef',
            '600': '#47c2ed',
            '700': '#3dbbeb',
            '800': '#35b4e8',
            '900': '#25a7e4',
            A100: '#ffffff',
            A200: '#4ec8ef',
            A400: '#b8e7ff',
            A700: '#9fdeff',
            contrastDefaultColor: 'light',
            contrastLightColors: '400 500 600 A400 A700',
            contrastDarkColors: '50 100 200 300 A100 A200',
        });

        this.mdThemingProvider.definePalette('IntouchSurvey - Accent', {
            '50': '#eaf8fd',
            '100': '#caeffa',
            '200': '#a7e4f7',
            '300': '#83d9f4',
            '400': '#69d0f1',
            '500': '#4ec8ef',
            '600': '#47c2ed',
            '700': '#3dbbeb',
            '800': '#35b4e8',
            '900': '#25a7e4',
            A100: '#ffffff',
            A200: '#4ec8ef',
            A400: '#b8e7ff',
            A700: '#9fdeff',
            contrastDefaultColor: 'light',
            contrastLightColors: '400 500 600 A400 A700',
            contrastDarkColors: '50 100 200 300 A100 A200',
        });

        this.mdThemingProvider
            .theme('default')
            .primaryPalette('IntouchSurvey - Primary')
            .accentPalette('IntouchSurvey - Accent');
    }

    public setupHttpProvider(): void {
        this.httpProvider.defaults.withCredentials = true;

        delete this.httpProvider.defaults.headers.common['X-Requested-With'];
    }

    /**
     * Build the main sites states (in this case default route)
     */
    public buildStates(): void {
        this.stateProvider
            .state('login-callback', {
                url: '/login/callback/:token?redirect',
                controller: 'LoginCallbackController as vm',
                template: require('/app/modules/main/views/LoginCallback.html'),
            })
            .state('trial-expired', {
                url: '/trial-expired',
                controller: 'TrialExpiredController as vm',
                template: require('/app/modules/main/views/TrialExpired.html'),
                data: {
                    title: 'ESSENTIAL.TRIAL_EXPIRED.TRIAL_EXPIRED',
                    translate: true,
                },
                resolve: {
                    hasToken: [
                        'iteAccessService',
                        '$state',
                        '$q',
                        '$timeout',
                        '$translate',
                        (
                            accessService: IAccessService,
                            state: ng.ui.IStateService,
                            q: ng.IQService,
                            timeout: ng.ITimeoutService,
                            translate: ng.translate.ITranslateService
                        ) => {
                            const defer: any = q.defer();
                            if (!accessService || !accessService.getToken()) {
                                timeout(() => {
                                    accessService.handleLogin();
                                });
                                defer.reject();
                            } else {
                                if (accessService.getToken().getUser()) {
                                    translate.use(accessService.getToken().getUser().language || 'en');
                                } else {
                                    translate.use('en');
                                }
                                defer.resolve();
                            }
                            return defer.promise;
                        },
                    ],
                },
            })
            .state('home', {
                abstract: true,
                template: require('/app/modules/main/views/SiteLayout.html'),
                controller: 'MainController as vm',
                resolve: {
                    authenticatedUser: [
                        'iteAccessService',
                        '$state',
                        '$timeout',
                        '$q',
                        'iteLogger',
                        'iteFileProcessingService',
                        'iteAccessApi',
                        (
                            accessService: IAccessService,
                            state: ng.ui.IStateService,
                            timeout: ng.ITimeoutService,
                            q: ng.IQService,
                            logger: ILogger,
                            fileProcessingService: IFileProcessingService,
                            accessApi: IAccessApi
                        ) => {
                            const defer: any = q.defer();
                            const token: IToken = accessService.getToken();

                            const authState: AuthState = new AuthState({
                                timeout: timeout,
                                state: state,
                                q: q,
                                accessService: accessService,
                                authContextProvider: accessApi,
                                logger: logger,
                            });

                            if (!token) {
                                authState.rejectNullToken();
                            } else {
                                // need to check if we are past our token session expiration

                                if (authState.isTokenExpired(token)) {
                                    authState.rejectExpiredToken();
                                } else {
                                    logger.debug(
                                        'User appears to be logged already, session will expire on ' +
                                            token.getDateExpired().toDate()
                                    );
                                    const user: IUser = token.getUser();
                                    authState.addLogHandler(<ILogHandler>logger.getHandler('sentry-handler-v2'), token);
                                    authState.setupDataDogRumContext(datadogRum, token);
                                    fileProcessingService.setHeaders({
                                        'Content-Type': undefined,
                                    });
                                    authState
                                        .loadAuthContext()
                                        .then(() => {
                                            if (!authState.hasProductAccess(['survey'])) {
                                                throw new Error('User does not have Survey product');
                                            }
                                            q.all([
                                                authState.checkProductTrialExpired('survey', {
                                                    name: 'trial-expired',
                                                }),
                                                authState.checkProductSubscriptionCanceled('survey', {
                                                    name: 'trial-expired',
                                                }),
                                            ]).then(() => {
                                                logger.info('Organization loaded');
                                                defer.resolve(user);
                                            });
                                        })
                                        .catch((error) => {
                                            logger.error(error.message);
                                            authState.rejectNullToken();
                                        });
                                }
                            }

                            return defer.promise;
                        },
                    ],
                },
            });

        this.urlRouterProvider.otherwise('/surveys');
    }

    private handleLogin(
        timeout: ng.ITimeoutService,
        accessService: IAccessService,
        q: ng.IQService,
        rememberLocation: boolean = false
    ): ng.IPromise<any> {
        return timeout(() => {
            if (rememberLocation) {
                accessService.rememberLocation();
            }
            accessService.handleLogin();
            return q.reject();
        });
    }
}
