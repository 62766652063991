import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { BaseItem, IBaseItem } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';

/**
 * The Video settings interface.
 */
export interface IVideoSettings extends IBaseSettings {
    scale: number;
    span: number;
    alignment: string;
    hideLabel: boolean;
}

/**
 * The Video settings class.
 */
export class VideoSettings extends BaseSettings implements IVideoSettings {
    scale: number = 50;
    alignment: string = 'start';
    hideLabel: boolean = false;
}

/**
 * A Video translation interface.
 */
export interface IVideoTranslation extends IEntity {
    locale: string;
    label: string;
    url: string;
}

/**
 * A Video translation class
 */
export class VideoTranslation extends Entity implements IVideoTranslation {
    locale: string = null;
    label: string = null;
    url: string = null;
}

/**
 * A Video object interface.
 */
export interface IVideoObject extends IEntity {
    url: string;
    vendor: string;
    autoPlay: boolean;
    loopVideo: boolean;
}

/**
 * A Video object class.
 */
export class VideoObject extends Entity implements IVideoObject {
    url: string = null;
    vendor: string = null;
    autoPlay: boolean = false;
    loopVideo: boolean = false;
}

export interface IVideo extends IBaseItem {
    video: IVideoObject;
    settings: IVideoSettings;
    translations: Array<IVideoTranslation>;
    useOriginal: boolean;
    required: boolean;
    determineVideoVendor(): void;
    transformVideoUrl(vendor: string): void;
    getVideoVendor(): string;
    getVideoId(vendor: string): string;
    isYoutubeVideo(): boolean;
    isVimeoVideo(): boolean;
}

/**
 *  The Video item class.
 *
 */
export class Video extends BaseItem implements IVideo {
    type: string = 'video';
    video: IVideoObject = new VideoObject();
    settings: IVideoSettings = new VideoSettings();
    translations: any = [];
    required: boolean = false;

    @NotEnumerable
    useOriginal: boolean = true;
    @NotEnumerable
    _healthPoints: number = 3;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey. This overrides the BaseItem default value.
     */
    get healthPoints(): number {
        return this._healthPoints;
    }

    /**
     * Build the image from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IImage}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<IVideoSettings>(VideoSettings, jsonObject.settings, true);
        }

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<IVideoTranslation>(
                VideoTranslation,
                jsonObject.translations,
                true
            );
        }

        if (jsonObject.video) {
            this.video = EntityBuilder.buildOne<IVideoObject>(VideoObject, jsonObject.video, true);
        }

        return this;
    }

    /**
     * Gets the video ID based on the video vendor set
     *
     * @param {string} vendor
     * @returns {string}
     */
    public getVideoId(vendor: string): string {
        switch (vendor) {
            case 'youtube':
                // eslint-disable-next-line no-case-declarations
                const youtubeMatches: any = this.video.url.match(
                    /(?:http:\/\/|https:\/\/)?(?:www.)?youtu(?:\.be|be\.com)\/(?:.*v(?:\/|=)|(?:.*\/)?)([\w'-]+)/
                );
                if (youtubeMatches && youtubeMatches[1]) {
                    return youtubeMatches[1];
                }
                break;
            case 'vimeo':
                // eslint-disable-next-line no-case-declarations
                const vimeoMatches: any = this.video.url.match(
                    /(?:http:\/\/|https:\/\/|)(?:player.|www.)?(?:vimeo\.com\/)(?:(?:channels\/[A-z]+\/)|(?:groups\/[A-z]+\/videos\/))?([0-9]+)/
                );
                if (vimeoMatches && vimeoMatches[1]) {
                    return vimeoMatches[1];
                }
                break;
            default:
                return null;
        }
    }

    /**
     * Transforms URL based on video vendor set
     *
     * @param {string} vendor
     */
    public transformVideoUrl(vendor: string): void {
        switch (vendor) {
            case 'youtube':
                this.video.url = 'https://youtube.com/embed/' + this.getVideoId('youtube');
                break;
            case 'vimeo':
                this.video.url = 'https://player.vimeo.com/video/' + this.getVideoId('vimeo');
                break;
            default:
                break;
        }
    }

    /**
     * Gets the video vendor currently set
     *
     * @returns {string}
     */
    public getVideoVendor(): string {
        return this.video.vendor;
    }

    /**
     * Sets the video vendor if applicable
     */
    public determineVideoVendor(): void {
        if (this.isYoutubeVideo()) {
            this.video.vendor = 'youtube';
        } else if (this.isVimeoVideo()) {
            this.video.vendor = 'vimeo';
        } else {
            this.video.vendor = null;
        }
    }

    /**
     * Checks if URL is youtube
     *
     * @returns {boolean}
     */
    public isYoutubeVideo(): boolean {
        return this.video.url.indexOf('youtu') > -1;
    }

    /**
     * Checks if URL is vimeo
     *
     * @returns {boolean}
     */
    public isVimeoVideo(): boolean {
        return this.video.url.indexOf('vimeo') > -1;
    }
}
