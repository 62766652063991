import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Survey } from '../../../../../domain/surveys/survey/Survey';

@Component('its.survey.module.surveys', SurveyDetailsBoxComponent.CID, {
    template: require('/app/modules/surveys/summary/components/survey-details-box/SurveyDetailsBoxComponent.html'),
    controllerAs: 'vm',
    bindings: {
        survey: '<',
    },
})
class SurveyDetailsBoxComponent {
    static IID: string = 'SurveyDetailsBoxComponent';
    static CID: string = 'itsSurveyDetailsBox';
    static $inject: Array<string> = [];

    public survey: Survey;
}
