import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Survey } from '../../../../../domain/surveys/survey/Survey';

@Component('its.survey.module.surveys', SurveyPublishedStateComponent.CID, {
    template: require('/app/modules/surveys/summary/components/survey-published-state/SurveyPublishedStateComponent.html'),
    controllerAs: 'vm',
    bindings: {
        survey: '<',
    },
})
class SurveyPublishedStateComponent {
    static IID: string = 'SurveyPublishedStateComponent';
    static CID: string = 'itsSurveyPublishedState';
    static $inject: Array<string> = ['$translate'];

    public survey: Survey;
    public surveyStatusIcon: string = null;
    public surveyStatusText: string = null;

    public constructor(private translate: ng.translate.ITranslateService) {}

    $onChanges(): void {
        if (this.survey) {
            this.surveyStatusIcon = this.survey.getStatusIcon();
            const statusTextTranslationKey: string = this.survey.getStatusTextTranslationKey();
            this.surveyStatusText = this.translate.instant(statusTextTranslationKey);
        }
    }
}
