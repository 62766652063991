import { AbstractTheme } from '../AbstractTheme';
import { IThemeSettings } from '../ThemeSettings';
import { ThemeType } from '../../../../services/ThemeService';

export class SoftBaseTheme extends AbstractTheme {
    public slug: ThemeType = null;

    private font: string = 'Arial';

    public constructor(primaryColour: string) {
        super();
        // font family
        this.customizations['::placeholder']['font-family'] = this.font;
        this.customizations['its-theme--font']['font-family'] = this.font;
        // main bg
        this.customizations['its-theme--background']['background-color'] = primaryColour;
        // survey padding
        this.customizations['its-theme--content-container']['margin-top'] = '60px';
        this.customizations['its-theme--content-container']['margin-bottom'] = '60px';
        // survey shadow
        this.customizations['its-theme--background-content']['box-shadow'] = '0 5px 25px 0 rgb(0 0 0 / 20%)';
        // survey bg
        this.customizations['its-theme--background-content']['background-color'] = '#FCFCFC';
        this.customizations['its-theme--question-container']['background-color'] = 'transparent';
        this.customizations['its-theme--title']['background-color'] = 'transparent';
        // survey width
        this.customizations['its-theme--background-content']['max-width'] = '70%';
        this.customizations['its-theme--background-content']['width'] = '70%';
        // question font
        this.customizations['its-theme--question-label']['color'] = primaryColour;
        // error container
        this.customizations['its-theme--error-container']['background-color'] = '#f9cadf';
        // help font
        this.customizations['its-theme--help-text']['color'] = primaryColour;
        // title
        this.customizations['its-theme--title']['color'] = primaryColour;
        // brand color
        this.customizations['its-theme--brand-primary']['background-color'] = primaryColour;
        // border radius
        this.customizations['its-theme--background-content']['border-radius'] = '30px';
        this.customizations['its-theme--question-container']['border-radius'] = '30px';
        this.customizations['its-theme--title-container']['border-radius'] = '30px';
        this.customizations['its-theme--button-container']['border-radius'] = '30px';
    }

    public getName(): string {
        return null;
    }

    public getSettings(): IThemeSettings {
        return this.customizations;
    }
}
