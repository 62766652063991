import { BaseSettings, IBaseSettings } from './BaseSettings';

export interface IImageSettings extends IBaseSettings {
    scale: number;
    hideLabel: boolean;
    alignment: string;
}

export class ImageSettings extends BaseSettings implements IImageSettings {
    scale: number = 50;
    hideLabel: boolean = false;
    alignment: string = 'start';
}
