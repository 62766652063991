import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { ObjectUtils } from '@intouch/its.essential/app/essential/utils/ObjectUtils';
import { IPhotoService, IParsedImage } from '@intouch/its.essential/app/essential/services/PhotoService';

import { ISurvey } from '../../../domain/surveys/survey/Survey';
import { ISurveyApi } from '../../../api/SurveyApi';
import { IPageService } from '../../../services/PageService';
import {
    FroalaProvider,
    IFroalaCustomButtonConfig,
    IFroalaCustomButtonIcon,
} from '@intouch/its.essential/app/essential/domain/FroalaProvider';
import { EmailDistribution, IEmailDistribution } from '../../../domain/contact-center/EmailDistribution';
import { ICustomField } from '../../../domain/settings/CustomField';
import { IDefaultContactField } from '../../../domain/contact-center/DefaultContactField';
import { EmailPreviewModal } from './email/EmailPreviewModal';
import { DistributionTrigger } from '../../../domain/contact-center/DistributionTrigger';
import { IDistributionTriggerCondition } from '../../../domain/contact-center/DistributionTriggerCondition';
import { IGeneralSettings } from '../../../domain/settings/GeneralSettings';
import FroalaEditor from 'froala-editor';
import PipedTextSvg from '../../../../assets/images/piped-text-01.svg';

import * as _ from 'lodash';
// import * as $ from 'jquery';
// import 'froala-editor';

export abstract class AbstractEmailController {
    static $inject: Array<string> = [
        'itsSurveyApi',
        '$state',
        'itsPageService',
        '$q',
        'APPCONFIG',
        'iteToaster',
        'iteAccessService',
        '$mdDialog',
        '$state',
        '$translate',
        'itePhotoService',
    ];

    protected survey: ISurvey = null;
    protected editing: boolean = false;
    protected froalaOptions: any = null;
    protected emailCompositionForm: ng.IFormController;
    protected emailDistribution: IEmailDistribution = null;
    protected operators: Array<{ label: string; value: string }> = [];
    protected enableAutoDelivery: boolean = true;
    protected customFields: Array<ICustomField> = [];
    protected defaultFields: Array<IDefaultContactField> = [];
    protected fieldIsDeleted: boolean = false;
    protected isContactAdmin: boolean = false;
    protected isAdmin: boolean = false;
    protected limitToggle: boolean = false;
    protected emailExpiryDays: number | string = null;
    protected emailCustomExpiry: number = null;
    protected settings: IGeneralSettings = null;
    protected fromAddresses: Array<{ uuid: string; fromAddress: string }> = [];
    protected clearReminderOnSave: boolean;
    public selectedFromAddress: { uuid: string; fromAddress: string };
    private froalaReference: any = null;
    private froalaReference2: any = null;

    constructor(
        protected surveyApi: ISurveyApi,
        protected stateService: ng.ui.IStateService,
        protected pageService: IPageService,
        protected q: ng.IQService,
        protected config: any,
        protected toaster: IToaster,
        protected accessService: IAccessService,
        protected dialog: ng.material.IDialogService,
        protected state: ng.ui.IStateService,
        protected translate: ng.translate.ITranslateService,
        protected photoService: IPhotoService
    ) {
        // initializes emailDistribution
        this.setupEmailDefault();
        this.isContactAdmin = this.accessService.getToken().getUser().hasAcl('contact_center_admin');
        this.isAdmin = this.accessService.getToken().getUser().isAdmin();
        this.operators = this.emailDistribution.getAvailableOperators(translate);
        this.load(this.stateService.params['uuid'], this.stateService.params['emailUuid']);
        this.setupEmailFroalaSettings();
        this.clearReminderOnSave = false;

        try {
            this.fromAddresses.push({
                uuid: null,
                fromAddress: this.config.defaultEmail.from,
            });
        } catch (e) {
            throw new Error('Please add the APPCONFIG__DEFAULT_EMAIL__FROM key to .env');
        }
    }

    /**
     * Load the given form by its uuid
     */
    public abstract load(uuid: string, emailUuid: string): void;

    public loadExistingEmail(emailUuid: string): any {
        const q: ng.IDeferred<any> = this.q.defer();
        if (emailUuid) {
            this.surveyApi
                .findSurveyEmail(this.survey.originalUuid, emailUuid)
                .then((existingEmail: IEmailDistribution) => {
                    q.resolve(existingEmail);
                });
        } else {
            q.resolve();
        }

        return q.promise;
    }

    /**
     * Open up the email preview modal
     *
     * @param useReminder
     */
    public openEmailPreview(useReminder: boolean): void {
        const token: IToken = this.accessService.getToken();

        if (!token || !token.getOrganization()) {
            this.toaster.error('ERRORS.CONTACT_CENTER.MISSING_TOKEN_INFO');
        }

        const distribution: any = ObjectUtils.cloneObject(this.emailDistribution);

        if (useReminder) {
            distribution.email.subject = this.emailDistribution.reminder.subject;
            distribution.email.body = this.emailDistribution.reminder.body;
        } else {
            distribution.email.subject = this.emailDistribution.email.subject;
            distribution.email.body = this.emailDistribution.email.body;
        }

        distribution.mailSenderUuid = this.selectedFromAddress.uuid;
        distribution.email.fromAddress = this.selectedFromAddress.fromAddress;

        this.dialog.show(
            EmailPreviewModal.instantiate({
                locals: {
                    emailDistribution: distribution,
                    surveyOriginalUuid: this.survey.originalUuid,
                },
            })
        );
    }

    /**
     * Decide whether it's valid to send a preview email.
     * Must have body, subject, and from name.
     *
     * @returns {boolean}
     */
    public isPreviewEmailValid(): boolean {
        if (
            this.emailDistribution.email.body &&
            this.emailDistribution.email.subject &&
            this.emailDistribution.email.from
        ) {
            return true;
        }

        return false;
    }

    public getSavedField(fieldName: string): IDefaultContactField | ICustomField {
        const customField: ICustomField = _.find(this.customFields, { name: fieldName });

        if (customField) {
            return customField;
        }

        const defaultField: IDefaultContactField = _.find(this.defaultFields, { name: fieldName });

        if (defaultField) {
            return defaultField;
        }

        return null;
    }

    public handleEmailExpiryChange(defaultValue: number | string, customValue: number): void {
        if (defaultValue === 'custom' && customValue) {
            this.emailDistribution.expirationDays = customValue;
        } else if (defaultValue !== 'custom') {
            this.emailDistribution.expirationDays = <number>defaultValue;
        }
    }

    public handleEnablingAutoDelivery(status: boolean): void {
        if (status && (!this.emailDistribution.triggers || this.emailDistribution.triggers.length === 0)) {
            this.emailDistribution.triggers = [new DistributionTrigger()];
        }
    }

    public handleLimitToggle(status: boolean): void {
        if (status) {
            this.emailDistribution.submissionsPeriodInDays = null;
            this.emailDistribution.submissionsPerPeriod = 1;
        } else {
            this.emailDistribution.submissionsPeriodInDays = null;
            this.emailDistribution.submissionsPerPeriod = null;
        }
    }

    public setField(condition: IDistributionTriggerCondition, field: any, value?: any, init: boolean = false): void {
        condition.fieldIsDeleted = false;
        if (field) {
            condition.operand = field.name;
            condition.operandType = field.type;

            // preserve when loading existing email
            if (value && condition.operator !== 'empty' && condition.operator !== 'nempty') {
                condition.value = value;
            } else {
                condition.value = null;
            }

            if (field.isDefaultField) {
                condition.fieldType = 'default_contact_field';
            } else if (field.name === 'created_at') {
                condition.fieldType = 'contact_creation_date';
            } else {
                condition.fieldType = 'custom_contact_field';
            }
            condition.selectedField = field;
        } else if (init) {
            condition.fieldIsDeleted = true;
        }
    }

    /**
     * Confirmation prompt when cancelling email creation
     */
    public cancelEmailCreation(): void {
        if (!this.editing) {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: this.translate.instant('DISTRIBUTIONS.EMAIL.DISCARD_EMAIL'),
                            description: this.translate.instant('DISTRIBUTIONS.EMAIL.DISCARD_EMAIL_DESC'),
                            confirmText: this.translate.instant('DISTRIBUTIONS.EMAIL.DISCARD'),
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((response) => {
                    if (response) {
                        this.state.go('home.surveys.edit.distributions.email', {
                            uuid: this.survey.uuid,
                        });
                    }
                });
        } else {
            this.state.go('home.surveys.edit.distributions.email', {
                uuid: this.survey.uuid,
            });
        }
    }

    /**
     * Send email settings off to the API to create and redirect to this route (showing listing
     * and resetting state)
     *
     * @param email
     */
    public saveEmail(email: IEmailDistribution): void {
        if (this.froalaReference) {
            this.emailDistribution.email.body = this.froalaReference.html.get();
        }

        this.emailDistribution.mailSenderUuid = this.selectedFromAddress.uuid;
        this.emailDistribution.email.fromAddress = this.selectedFromAddress.fromAddress;

        if (this.clearReminderOnSave) {
            this.emailDistribution.reminder = null;
        } else {
            if (this.froalaReference2) {
                this.emailDistribution.reminder.body = this.froalaReference2.html.get();
            }
        }

        if (email.uuid) {
            this.surveyApi
                .updateSurveyEmail(this.survey.originalUuid, email)
                .then(() => {
                    this.toaster.success('DISTRIBUTIONS.EMAIL.UPDATED');
                    this.state.go('home.surveys.edit.distributions.email', {
                        uuid: this.survey.uuid,
                    });
                })
                .catch(() => {
                    this.toaster.error('ERRORS.CONTACT_CENTER.FAILED_EMAIL_CREATE');
                });
        } else {
            this.surveyApi
                .createSurveyEmail(this.survey.originalUuid, email)
                .then(() => {
                    this.toaster.success('DISTRIBUTIONS.EMAIL.CREATED');
                    this.state.go('home.surveys.edit.distributions.email', {
                        uuid: this.survey.uuid,
                    });
                })
                .catch(() => {
                    this.toaster.error('ERRORS.CONTACT_CENTER.FAILED_EMAIL_CREATE');
                });
        }
    }

    public initFroalaEditor(initControls: any, reference: number): void {
        this.setupEmailFroalaSettings();
        this.registerFroalaPipedTextButton();
        initControls.initialize(this.froalaOptions);

        if (reference === 1) {
            this.froalaReference = initControls.getEditor();
        } else {
            this.froalaReference2 = initControls.getEditor();
        }
    }

    public handleFromAddressChange(): void {
        this.emailDistribution.email.fromAddress = this.selectedFromAddress.fromAddress;
        this.emailDistribution.mailSenderUuid = this.selectedFromAddress.uuid;
    }

    protected setupEmailDefault(): void {
        const token: IToken = this.accessService.getToken();

        if (token && token.getOrganization()) {
            this.emailDistribution = new EmailDistribution({
                org: token.getOrganization().name,
                orgImageUrl: token.getOrganization().avatarThumbUrl,
            });
        }
    }

    protected setFromAddressOptions(): void {
        for (const mailSender of this.settings.mailSenders) {
            for (const from of mailSender.configuration.from) {
                this.fromAddresses.push({ uuid: mailSender.uuid, fromAddress: from });
            }
        }
    }

    /**
     * Set custom settings specific to email composition froala
     */
    private setupEmailFroalaSettings(): void {
        this.froalaOptions = FroalaProvider.defaultConfig();

        this.froalaOptions.key = this.config.froala.key;
        // set and/or overwrite certain settings specific to email
        this.froalaOptions.toolbarButtons = [
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            'fontFamily',
            'fontSize',
            'color',
            '|',
            'outdent',
            'indent',
            '|',
            'align',
            'formatOL',
            'formatUL',
            '|',
            'insertLink',
            'insertImage',
            '|',
            'html',
            '|',
            'itsInsertPipedText',
        ];
        this.froalaOptions.toolbarButtons.imageInsertButtons = ['imageUpload'];
        this.froalaOptions.toolbarButtons.fontFamilySelection = true;
        this.froalaOptions.toolbarButtons.fontFamily = {
            'Arial,Helvetica,sans-serif': 'Arial',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            'Roboto,sans-serif': 'Roboto',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            'Times New Roman,Times,serif': 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana',
        };

        // our default + image
        this.froalaOptions.pluginsEnabled = [
            'align',
            'charCounter',
            'codeView',
            'colors',
            'embedly',
            'fontFamily',
            'fontSize',
            'inlineStyle',
            'lineBreaker',
            'link',
            'lists',
            'paragraphFormat',
            'paragraphStyle',
            'quote',
            'table',
            'image',
        ];

        this.froalaOptions.imageAllowedTypes = ['jpg', 'jpeg', 'png'];
        this.froalaOptions.htmlDoNotWrapTags = ['script', 'style', 'img'];
        this.froalaOptions.htmlAllowedTags = [
            'a',
            'abbr',
            'address',
            'area',
            'article',
            'aside',
            'audio',
            'b',
            'base',
            'bdi',
            'bdo',
            'blockquote',
            'br',
            'button',
            'canvas',
            'caption',
            'cite',
            'code',
            'col',
            'colgroup',
            'datalist',
            'dd',
            'del',
            'details',
            'dfn',
            'dialog',
            'div',
            'dl',
            'dt',
            'em',
            'embed',
            'fieldset',
            'figcaption',
            'figure',
            'footer',
            'form',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'header',
            'hgroup',
            'hr',
            'i',
            'iframe',
            'img',
            'input',
            'ins',
            'kbd',
            'keygen',
            'label',
            'legend',
            'li',
            'link',
            'main',
            'map',
            'mark',
            'menu',
            'menuitem',
            'meter',
            'nav',
            'noscript',
            'object',
            'ol',
            'optgroup',
            'option',
            'output',
            'p',
            'param',
            'pre',
            'progress',
            'queue',
            'rp',
            'rt',
            'ruby',
            's',
            'samp',
            'script',
            'style',
            'section',
            'select',
            'small',
            'source',
            'span',
            'strike',
            'strong',
            'sub',
            'summary',
            'sup',
            'table',
            'tbody',
            'td',
            'textarea',
            'tfoot',
            'th',
            'thead',
            'time',
            'tr',
            'track',
            'u',
            'ul',
            'var',
            'video',
            'wbr',
        ];

        this.froalaOptions.htmlExecuteScripts = false;
        this.froalaOptions.htmlUntouched = true;
        this.froalaOptions.htmlRemoveTags = ['script'];
        this.froalaOptions.pasteDeniedAttrs = ['style'];

        this.froalaOptions.linkEditButtons = ['linkStyle', 'linkEdit', 'linkRemove'];
        this.froalaOptions.charCounterMax = 500000;
        this.froalaOptions.charCounterCount = false;
        this.froalaOptions.imageUpload = true;

        const self: this = this;
        this.froalaOptions.events = {
            initialized: function () {
                if (!self.isContactAdmin) {
                    this.edit.off();
                }
            },
            'image.beforeUpload': function (images: any[]): boolean {
                const file: any = images[0];
                self.photoService
                    .parse(file, null, null, false)
                    .then((parsedImage: IParsedImage) => {
                        self.surveyApi
                            .addPhoto(parsedImage.base64)
                            .then((result) => {
                                this.image.insert(result.data.large);
                            })
                            .finally(() => {
                                this.image.hideProgressBar(true); // close froala upload progress bar
                            });
                    })
                    .catch((error) => {
                        self.toaster.error('ERRORS.PHOTO_NOT_ADDED', error);
                    });
                return false; // cancels progression of froala upload path
            },
        };
        this.froalaOptions = FroalaProvider.convertOptionsToV4(this.froalaOptions);
    }

    /**
     * Registers the Piped Text button for Froala.
     *
     * @returns {void}
     */
    private registerFroalaPipedTextButton(): void {
        const buttonConfig: IFroalaCustomButtonConfig = {
            title: 'Insert Piped Text',
            icon: 'pipedText',
            undo: true,
            focus: true,
            showOnMobile: false,
            callback: () => {
                const element: HTMLElement = document.getElementById('open-pipe-text-menu-emailBody');
                element.click();
            },
        };

        const icon: IFroalaCustomButtonIcon = {
            name: 'pipedText',
            config: {
                SRC: PipedTextSvg,
                template: 'image',
            },
        };

        FroalaEditor.DefineIcon(icon.name, icon.config);
        FroalaEditor.RegisterCommand('itsInsertPipedText', buttonConfig);
    }
}
