import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import ContactsExportModalHtml from './ContactsExportModal.html';

export class ContactsExportModal extends Modal {
    static $inject: Array<string> = ['$mdDialog'];

    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.disableParentScroll = true;
        config.bindToController = true;
        config.template = ContactsExportModalHtml;
        config.controller = ContactsExportModal;

        return super.instantiate(config);
    }

    public constructor(protected dialog: ng.material.IDialogService) {
        super();
    }

    public cancel(): void {
        this.dialog.cancel();
    }
}
