import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', VideoRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/display-items/video/VideoRenderComponent.html'),
    controllerAs: 'vm',
    bindings: VideoRenderComponent.getBindings(),
})
class VideoRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'VideoRenderComponent';
    static CID: string = 'itsVideoRender';
    static $inject: string[] = ['$sce'];

    public constructor(private sce: ng.ISCEService) {
        super();
    }

    public trustAsResourceUrl(url: string): string {
        return this.sce.trustAsResourceUrl(url);
    }
}
