import { Entity } from '@intouch/its.essential/app/essential/domain/Entity';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { EntityEncoder } from '@intouch/its.essential/app/essential/domain/EntityEncoder';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { ISection, Section } from '../Section';
import { IUser, User } from '../User';
import { ITranslation, Translation } from '../Translation';
import { BaseItem, IBaseItem } from '../items/BaseItem';
import { TranslationLocator } from '../TranslationLocator';
import { ILogic, Logic } from '../logic/Logic';
import { Group } from '../items/Group';
import { Select } from '../items/Select';
import { Radio } from '../items/Radio';
import { Checkbox } from '../items/Checkbox';
import { IMatrix, Matrix } from '../items/Matrix';
import { BaseResponseItem, IBaseResponseItem } from '../items/BaseResponseItem';
import { ILanguage, LanguageProvider } from '../../LanguageProvider';
import { ISurveyLanguage, SurveyLanguage } from './SurveyLanguage';
import { ISurveySettings, SurveySettings } from './SurveySettings';
import { IWeightedTag } from '../items/shared/WeightedTag';
import { ILogicReference, LogicOwner, LogicReference } from '../logic/LogicReference';
import * as _ from 'lodash';
import { ITranslatedEntity } from '../../ITranslatedEntity';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import { IAbstractTheme } from '../themes/AbstractTheme';
import { DefaultTheme } from '../themes/DefaultTheme';

/**
 * The form interface
 */
export interface ISurvey extends ITranslatedEntity {
    uuid: string;
    originalUuid: string;
    name: string;
    revision: number;
    active: boolean;
    status: string;
    createdAt: string;
    updatedAt: string;
    createdBy: IUser;
    updatedBy: IUser;
    sections: Array<Section>;
    translations: Array<ITranslation>;
    settings: ISurveySettings;
    logic: Array<ILogic>;
    labelMap: any;
    points: number;
    previewImage: string;

    theme: any;

    addSection(section: ISection, index?: number): ISurvey;

    removeSection(section: ISection): ISurvey;

    remove(uuid: string): boolean;

    getSections(): Array<ISection>;

    getLastSection(): ISection;

    getLogic(): Array<ILogic>;

    findLogicByUuid(uuid: string): ILogic;

    getSectionItems(excludeTypes?: Array<string>, includeResponses?: boolean): Array<IBaseItem>;

    getGroupItems(): Array<IBaseItem>;

    getLogicItems(): Array<IBaseItem>;

    getItemByUuid(uuid: string): IBaseItem | IBaseResponseItem | IMatrix;

    getSectionByUuid(uuid: string): ISection;

    recursiveFind(uuid: string, stack: Array<any>, replaceWith?: any): any;

    findAndSetLogic(uuid: string, logic: ILogic): ISurvey;

    isPublished(): boolean;

    getTranslation(locale: string, createOnNotFound?: boolean): ITranslation;

    getAvailableLanguages(): Array<ISurveyLanguage>;

    getLocationQuestions(): Array<IBaseItem>;

    clearDisabledSettings(): void;

    hasQuestionType(type: string): boolean;

    hasMultipleOfQuestionType(type: string): boolean;

    buildLabelMap(): void;

    getItemParentByUuid(uuid: string): IBaseItem | IBaseResponseItem;

    getItems(comparator: (item: IBaseItem) => boolean): Array<IBaseItem>;

    isItemScorable(item: IBaseItem): boolean;

    isItemTaggable(item: IBaseItem): boolean;

    findSectionsWhereItems(comparator: (i: IBaseItem) => boolean): Array<ISection>;

    hasQuestions(): boolean;

    hasScoringQuestion(): boolean;

    hasValidTagQuestion(): boolean;

    updateTagWeight(tagUuid: string, weight: number): void;

    getAllTags(): Array<IWeightedTag>;

    findTagByUuid(tagUuid: string): IWeightedTag;

    getAllLogicItemBelongsTo(itemUuid: string): Array<ILogicReference>;

    setAllItemLogicReferences(): void;

    getTagWeight(tagUuid: string): number;
}

/**
 * The form object
 */
export class Survey extends Entity implements ISurvey {
    uuid: string = null;
    originalUuid: string = null;
    name: string = null;
    revision: number = null;
    active: boolean = null;
    status: string = null;
    previewImage: string = null;
    createdAt: string = null;
    updatedAt: string = null;
    createdBy: IUser = null;
    updatedBy: IUser = null;
    sections: Array<Section> = [];
    translations: Array<ITranslation> = [];
    logic: Array<ILogic> = [];
    settings: ISurveySettings = new SurveySettings();
    theme: IAbstractTheme = new DefaultTheme();

    @NotEnumerable
    availableLanguages: Array<ISurveyLanguage> = [];

    @NotEnumerable
    labelMap: any = {};

    private totalPoints: number = null;

    get points(): number {
        this.totalPoints = 0;
        for (const section of this.sections) {
            this.totalPoints += section.points;
        }

        return this.totalPoints;
    }

    /**
     * Build the form class from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {ISurvey}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): ISurvey {
        super.fromJson(jsonObject, true);

        this.setAvailableLanguages();

        if (jsonObject.created_by) {
            this.createdBy = EntityBuilder.buildOne<IUser>(User, jsonObject.created_by, true);
        }

        if (jsonObject.updated_by) {
            this.updatedBy = EntityBuilder.buildOne<IUser>(User, jsonObject.updated_by, true);
        }

        if (jsonObject.sections) {
            this.sections = EntityBuilder.buildMany<Section>(Section, jsonObject.sections, true);
        }

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ITranslation>(Translation, jsonObject.translations, true);
        }

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<ISurveySettings>(SurveySettings, jsonObject.settings, true);
        }

        if (jsonObject.logic) {
            this.logic = EntityBuilder.buildMany<ILogic>(Logic, jsonObject.logic, true);
        }

        if (jsonObject.theme) {
            this.theme = jsonObject.theme;

            // hack for a server side issue not keep customizations as an object (will fix with a server change)
            if (this.theme.customizations instanceof Array && this.theme.customizations.length === 0) {
                this.theme.customizations = null;
            }
        }

        this.setAllItemLogicReferences();

        return this;
    }

    /**
     * Return if item is scorable
     *
     * @param item
     */
    public isItemScorable(item: IBaseItem): boolean {
        return ['radio', 'matrix', 'select', 'rating', 'group'].indexOf(item.type) > -1;
    }

    /**
     * Return if item is taggable
     *
     * @param item
     */
    public isItemTaggable(item: IBaseItem): boolean {
        return (
            [
                'location',
                'slider',
                'matrix',
                'radio',
                'calendar',
                'nps',
                'select',
                'input',
                'textarea',
                'checkbox',
                'group',
                'rating',
                'ranking',
            ].indexOf(item.type) > -1
        );
    }

    /**
     * Find sections where items in the section match a comparator and return at least one result
     *
     * @param {(i: IBaseItem) => boolean} comparator
     * @return {Array<ISection>}
     */
    public findSectionsWhereItems(comparator: (i: IBaseItem) => boolean): Array<ISection> {
        const sections: Array<ISection> = [];

        for (const section of this.sections) {
            const items: Array<IBaseItem> = section.findItems(comparator);

            if (items && items.length > 0) {
                sections.push(section);
            }
        }

        return sections;
    }

    /**
     * Remove any item (section, item) by UUID
     *
     * @param {string} uuid
     */
    public remove(uuid: string): boolean {
        return this.recursiveRemove(uuid, this.getSections()) === true || false;
    }

    /**
     *
     * @param {ISection} section
     * @param {number} index
     * @returns {ISurvey}
     */
    public addSection(section: Section, index: number = 0): ISurvey {
        this.sections.splice(index, 0, section);

        return this;
    }

    /**
     * Will remove a given section from the form
     *
     * @param {ISection} section
     * @returns {ISurvey}
     */
    public removeSection(section: Section): ISurvey {
        const index: number = this.sections.indexOf(section);

        if (index > -1) {
            this.sections.splice(index, 1);
        }
        return this;
    }

    /**
     * Retrieves the survey's sections
     *
     * @returns {Array<ISection>}
     */
    public getSections(): Array<ISection> {
        return this.sections;
    }

    /**
     * Retrieves the survey's last sections
     *
     * @returns {<ISection}
     */
    public getLastSection(): Section {
        if (this.sections.length > 0) {
            return this.sections[this.sections.length - 1];
        } else {
            return null;
        }
    }

    /**
     * Retrieves the survey's logic
     *
     * @returns {Array<ILogic>}
     */
    public getLogic(): Array<ILogic> {
        return this.logic;
    }

    /**
     * Gets and returns an item by uuid
     *
     * @param {string} uuid
     * @returns {IBaseItem}
     */
    public getItemByUuid(uuid: string): IBaseItem {
        for (const section of this.sections) {
            const item: any = section.getItemByUuid(uuid);
            if (item) {
                if (item.uuid === uuid) {
                    return item;
                }
            }
        }
        return null;
    }

    /**
     * Find the parent of the UUID
     * Useful when you need the parent of a response/question/item/grouped item
     *
     * @param {string} uuid
     * @returns {IBaseItem|IBaseResponseItem}
     */
    public getItemParentByUuid(uuid: string): IBaseItem | IBaseResponseItem {
        for (const section of this.sections) {
            for (const item of section.items) {
                if (item.type === 'radio' || item.type === 'checkbox' || item.type === 'select') {
                    for (const response of (<any>item).responses) {
                        if (response.uuid === uuid) {
                            return <IBaseItem | IBaseResponseItem>item;
                        }
                    }
                }

                if (item.type === 'matrix') {
                    for (const question of (<any>item).questions) {
                        if (question.uuid === uuid) {
                            return <IBaseItem | IBaseResponseItem>item;
                        }
                    }
                }

                if (item.type === 'group') {
                    for (const groupItem of (<any>item).items) {
                        if (groupItem.uuid === uuid) {
                            return <IBaseItem | IBaseResponseItem>item;
                        }

                        if (
                            groupItem.type === 'radio' ||
                            groupItem.type === 'checkbox' ||
                            groupItem.type === 'select'
                        ) {
                            for (const response of (<Checkbox | Radio | Select>groupItem).responses) {
                                if (response.uuid === uuid) {
                                    return groupItem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Gets and returns a section by uuid
     *
     * @param {string} uuid
     * @returns {ISection}
     */
    public getSectionByUuid(uuid: string): ISection {
        for (const section of this.sections) {
            if (section.uuid === uuid) {
                return section;
            }
        }
        return null;
    }

    /**
     * Builds a string map of all items in the survey by uuid : label
     *
     * @returns {any}
     */
    public buildLabelMap(): void {
        for (const section of this.sections) {
            this.labelMap = _.merge(this.labelMap, section.buildLabelMap());
            for (const item of section.items) {
                this.labelMap = _.merge(this.labelMap, item.buildLabelMap());
                if (item.type === 'group') {
                    for (const groupItem of (<any>item).items) {
                        this.labelMap = _.merge(this.labelMap, groupItem.buildLabelMap());
                    }
                }
            }
        }
    }

    public getLocationQuestions(): IBaseItem[] {
        const locationQuestions: IBaseItem[] = [];

        for (const section of this.sections) {
            for (const item of section.items) {
                if (item.type === 'location') {
                    locationQuestions.push(item);
                }
            }
        }
        return locationQuestions;
    }

    /**
     *  Retrieves all the survey's section's items
     *  Can optionally exclude types to return
     *
     * @returns {Array<IBaseItem>}
     */
    public getSectionItems(excludeTypes?: Array<string>, includeResponses: boolean = false): Array<IBaseItem> {
        let items: Array<any> = [];

        for (const section of this.sections) {
            items = _.concat(items, section.getItems(excludeTypes, includeResponses));
        }

        return items;
    }

    /**
     * Gets all group items
     *
     * @returns {Array<IBaseItem>}
     */
    public getGroupItems(): Array<IBaseItem> {
        let items: Array<IBaseItem> = [];

        for (const section of this.sections) {
            items = _.concat(items, section.getGroupItems());
        }

        return items;
    }

    /**
     * Gets all items with logic
     * @returns {Array<IBaseItem>}
     */
    public getLogicItems(): Array<IBaseItem> {
        let items: Array<any> = [];

        for (const section of this.sections) {
            items = _.concat(items, section.getLogicItems());
        }
        return items;
    }

    /**
     * Finds logic by its uuid
     * @param uuid
     */
    public findLogicByUuid(uuid: string): ILogic {
        // top level, jump and end survey
        if (this.logic) {
            for (const logic of this.logic) {
                if (logic.uuid === uuid) {
                    return logic;
                }
            }
        }

        for (const section of this.sections) {
            const sectionLogic: ILogic = section.findLogicByUuid(uuid);
            if (sectionLogic) {
                return sectionLogic;
            }
        }
        return null;
    }

    /**
     * Recursively find an item or section by UUID. If a new item is passed, replace the found item with the new item
     * and return it.
     *
     * @param {string} uuid
     * @param {Array<any>} stack
     * @param newItem
     * @returns {IBaseItem}
     */
    public recursiveFind(uuid: string, stack: Array<any>, newItem: any): IBaseItem | ISection {
        if (stack && stack.length) {
            for (const index in stack) {
                if (stack[index]) {
                    const item: any = stack[index];
                    if (item.uuid === uuid) {
                        if (newItem !== undefined) {
                            stack[index] = newItem;
                        }
                        return stack[index];
                    } else if (item.items && item.items.length) {
                        const recursionItem: IBaseItem | ISection = this.recursiveFind(uuid, item.items, newItem);
                        if (recursionItem) {
                            return recursionItem;
                        }
                    }
                }
            }
        }
    }

    public getSectionLogic(uuid: string): ILogic {
        if (this.logic) {
            for (const logic of this.logic) {
                if (logic.settings && logic.settings.sectionUuid === uuid) {
                    return logic;
                }
            }
        }
        return null;
    }

    public hasSectionLogic(uuid: string): boolean {
        if (this.logic) {
            for (const logic of this.logic) {
                if (logic.settings && logic.settings.sectionUuid === uuid) {
                    return true;
                }
            }
        }
        return false;
    }

    public getAllLogicItemBelongsTo(itemUuid: string): Array<ILogicReference> {
        let allLogic: Array<ILogicReference> = [];
        if (this.logic) {
            for (const logic of this.logic) {
                if (logic.settings && logic.settings.sectionUuid === itemUuid) {
                    const section: ISection = this.getSectionByUuid(logic.settings.sectionUuid);
                    if (section) {
                        allLogic.push(new LogicReference(new LogicOwner(section.uuid, section.label), logic));
                    }
                }
                for (const logicItem of logic.items) {
                    if (logicItem.operand === itemUuid) {
                        const section: ISection = this.getSectionByUuid(logic.settings.sectionUuid);
                        if (section) {
                            allLogic.push(new LogicReference(new LogicOwner(section.uuid, section.label), logic));
                        } else {
                            allLogic.push(new LogicReference(new LogicOwner(this.uuid, this.name), logic));
                        }
                    }
                }
            }
        }

        for (const section of this.sections) {
            allLogic = _.concat(allLogic, section.getAllLogicItemBelongsTo(itemUuid));
        }

        return allLogic;
    }

    public setAllItemLogicReferences(): void {
        for (const section of this.sections) {
            section.logicReferences = [];
            section.logicReferences = _.concat(section.logicReferences, this.getAllLogicItemBelongsTo(section.uuid));
            for (const item of section.items) {
                item.logicReferences = [];
                if (item instanceof Group) {
                    for (const groupItem of item.items) {
                        groupItem.logicReferences = [];
                        groupItem.logicReferences = _.concat(
                            groupItem.logicReferences,
                            this.getAllLogicItemBelongsTo(groupItem.uuid)
                        );
                        if (groupItem.logic) {
                            groupItem.logicReferences.push(
                                new LogicReference(new LogicOwner(groupItem.uuid, groupItem.label), groupItem.logic)
                            );
                        }
                    }
                } else {
                    item.logicReferences = _.concat(item.logicReferences, this.getAllLogicItemBelongsTo(item.uuid));

                    if (item instanceof BaseResponseItem) {
                        for (const response of item.responses) {
                            response.logicReferences = [];
                            response.logicReferences = _.concat(
                                response.logicReferences,
                                this.getAllLogicItemBelongsTo(response.uuid)
                            );
                            if (response.logic) {
                                response.logicReferences.push(
                                    new LogicReference(new LogicOwner(response.uuid, response.label), response.logic)
                                );
                            }
                        }

                        if (item instanceof Matrix) {
                            for (const question of item.questions) {
                                question.logicReferences = [];
                                question.logicReferences = _.concat(
                                    question.logicReferences,
                                    this.getAllLogicItemBelongsTo(question.uuid)
                                );
                                if (question.logic) {
                                    question.logicReferences.push(
                                        new LogicReference(
                                            new LogicOwner(question.uuid, question.label),
                                            question.logic
                                        )
                                    );
                                }
                            }
                        }
                    }
                }

                if (item.logic) {
                    item.logicReferences.push(new LogicReference(new LogicOwner(item.uuid, item.label), item.logic));
                }
            }
        }
    }

    public getTagWeight(tagUuid: string): number {
        // find any tag with same uuid that has weight assigned
        const tag: IWeightedTag = _.find(this.getAllTags(), (i) => {
            return i.uuid === tagUuid && NumberUtils.isNumber(i.weight);
        });

        if (tag) {
            return tag.weight;
        }

        return null;
    }

    /**
     * Finds item or survey level logic and sets it to the passed in logic
     *
     * @param {string} uuid
     * @param {ILogic} logic
     * @returns {ISurvey}
     */
    public findAndSetLogic(uuid: string, logic: ILogic = null): ISurvey {
        // check section level (jump/end) logic
        for (let i: number = 0; i < this.logic.length; i++) {
            if (this.logic[i].settings) {
                if (this.logic[i].settings.sectionUuid && this.logic[i].settings.sectionUuid === uuid) {
                    if (logic === null) {
                        this.logic.splice(i, 1);
                    } else {
                        this.logic[i] = logic;
                    }
                    return this;
                }
            }
        }

        // check item level (display show/hide) logic
        for (const section of this.sections) {
            if (section.items && section.items.length) {
                for (const item of section.items) {
                    if (item.uuid === uuid) {
                        item.logic = logic;
                        return this;
                    }

                    if (item instanceof BaseResponseItem) {
                        for (const response of item.responses) {
                            if (response.uuid === uuid) {
                                response.logic = logic;
                                return this;
                            }
                        }
                    }

                    if (item.type === 'matrix') {
                        for (const question of (<any>item).questions) {
                            if (question.uuid === uuid) {
                                question.logic = logic;
                                return this;
                            }
                        }
                    }

                    if (item.type === 'group') {
                        for (const groupItem of (<any>item).items) {
                            if (groupItem.uuid === uuid) {
                                groupItem.logic = logic;
                                return this;
                            }

                            if (groupItem instanceof BaseResponseItem) {
                                for (const response of groupItem.responses) {
                                    if (response.uuid === uuid) {
                                        response.logic = logic;
                                        return this;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return this;
    }

    public isPublished(): boolean {
        return this.status === 'published';
    }

    public isDraft(): boolean {
        return this.status === 'draft';
    }

    public hasBeenPublishedOnce(): boolean {
        return this.uuid !== this.originalUuid;
    }

    public getStatusIcon(): string {
        if (this.isPublished()) {
            return 'cloud_done';
        }

        if (this.hasBeenPublishedOnce() && this.isDraft()) {
            return 'sync';
        }

        if (!this.hasBeenPublishedOnce()) {
            return 'edit';
        }
    }

    public getStatus(): string {
        if (this.status === 'draft' && this.originalUuid !== this.uuid) {
            return 'published';
        }

        return this.status;
    }

    public getAvailableLanguagesText(): string {
        const currentLanguages: ISurveyLanguage[] = this.settings.getSupportedLanguages();
        const languageLabels: string[] = _.map(currentLanguages, (language) => language.label);
        const sourceLanguageLabelArray: string[] = LanguageProvider.getLanguageLabelsByLocale([
            this.settings.sourceLanguage,
        ]);
        if (sourceLanguageLabelArray.length === 1 && languageLabels.indexOf(sourceLanguageLabelArray[0]) === -1) {
            languageLabels.unshift(sourceLanguageLabelArray[0]);
        }
        return languageLabels.join(', ');
    }

    public getStatusTextTranslationKey(): string {
        if (this.isPublished()) {
            return 'SURVEYS.SUMMARY.PUBLISHED_UP_TO_DATE';
        }

        if (this.hasBeenPublishedOnce() && this.isDraft()) {
            return 'SURVEYS.SUMMARY.UNPUBLISHED_CHANGES';
        }

        if (!this.hasBeenPublishedOnce()) {
            return 'SURVEYS.SUMMARY.NOT_PUBLISHED_YET';
        }
    }

    /**
     * Return if the survey contains a section which has at least 1 item on it
     */
    public hasQuestions(): boolean {
        for (const section of this.sections) {
            if (section.items.length > 0) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns if the survey contains at least 1 scoring question
     */
    public hasScoringQuestion(): boolean {
        for (const section of this.sections) {
            if (section.hasScoringQuestion()) {
                return true;
            }
        }

        return false;
    }

    public getQuestionCount(): number {
        let totalQuestionCount: number = 0;
        for (const section of this.sections) {
            totalQuestionCount += section.getItemCount();
        }

        return totalQuestionCount;
    }

    public getPageCount(): number {
        return this.sections.length;
    }

    /**
     * Returns if the survey contains at least 1 question which is able to be tagged
     */
    public hasValidTagQuestion(): boolean {
        for (const section of this.sections) {
            if (section.hasValidTagQuestion()) {
                return true;
            }
        }

        return false;
    }

    /**
     * Retrieve the given translation for a locale
     *
     * @param {string} locale
     * @param {boolean} createOnNotFound
     * @returns {ITranslation}
     */
    public getTranslation(locale: string, createOnNotFound: boolean = true): ITranslation {
        return TranslationLocator.locate(this.translations, 'name', locale, createOnNotFound);
    }

    /**
     * Get the list of languages
     *
     * @returns {Array<ISurveyLanguage>}
     */
    public getAvailableLanguages(): Array<ISurveyLanguage> {
        return this.availableLanguages;
    }

    /**
     * Reset values of certain settings when their toggle
     * is
     */
    public clearDisabledSettings(): void {
        if (this.settings) {
            // reset custom buttons
            if (!this.settings.enableCustomButtons) {
                this.settings.backButtonText = null;
                this.settings.nextButtonText = null;
            }

            if (!this.settings.enableLanguageToggle) {
                this.settings.supportedLanguages = [];
            }

            if (!this.settings.enableCompleteRedirect) {
                this.settings.redirectSeconds = null;
                this.settings.redirectUrl = null;
            }
        }
    }

    public hasQuestionType(type: string): boolean {
        for (const section of this.sections) {
            if (section.hasItems()) {
                const sectionItemsFound: Array<BaseItem> = section.findItemsByType(type);
                if (sectionItemsFound.length >= 1) {
                    return true;
                }
            }
        }
        return false;
    }

    public hasMultipleOfQuestionType(type: string): boolean {
        let totalSectionItemsFound: number = 0;

        for (const section of this.sections) {
            if (section.hasItems()) {
                const sectionItemsFound: Array<BaseItem> = section.findItemsByType(type);
                totalSectionItemsFound += sectionItemsFound.length;
                if (totalSectionItemsFound > 1) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Get items based on comparator.
     */
    public getItems(comparator: (item: IBaseItem) => boolean): Array<IBaseItem> {
        let items: Array<IBaseItem> = [];
        for (const section of this.sections) {
            items = _.concat(items, section.getSectionItems(comparator));
        }

        return items;
    }

    /**
     * Loops down to every item to update an access tag's weight
     *
     * @param tagUuid
     * @param weight
     */
    public updateTagWeight(tagUuid: string, weight: number): void {
        for (const section of this.sections) {
            section.updateTagWeight(tagUuid, weight);
        }
    }

    /**
     * Build a tag map of uuid:tag and drill down into section to and add their tags if that uuid is not registered
     * Will return an array of all the tags
     *
     */
    public getAllTags(): Array<IWeightedTag> {
        const tags: { [uuid: string]: IWeightedTag } = {};
        for (const section of this.sections) {
            section.buildTagMap(tags);
        }

        return <Array<IWeightedTag>>_.values(tags);
    }

    /**
     * Find a tag within the sections/items by a UUID
     * Will return the first tag found
     *
     * @param tagUuid
     */
    public findTagByUuid(tagUuid: string): IWeightedTag {
        for (const section of this.sections) {
            const foundTag: IWeightedTag = section.findTagByUuid(tagUuid);
            if (foundTag) {
                return foundTag;
            }
        }
    }

    /**
     * Get the list of translation keys a survey can have
     */
    public getTranslationKeys(): Array<string> {
        return ['name'];
    }

    /**
     * Converts this entity into a JSON string
     * @param convertToSnake
     */
    public toJson(convertToSnake: boolean): string {
        // needed to have the set/get trigger the total points calculation
        // when programmatically setting points to an item
        // and then converting to a string without view rendering
        this.totalPoints = this.points;

        if (
            this.settings &&
            this.settings.hasOwnProperty('limitSubmissions') &&
            this.settings.limitSubmissions === false
        ) {
            this.settings.resetSubmissionSettings();
        }

        return EntityEncoder.jsonEncode(this, convertToSnake);
    }

    /**
     * Recursively loop through and checking if items have children via the "items" key
     * and remove by index
     *
     * @param {string} uuid
     * @param {Array<any>} stack
     */
    private recursiveRemove(uuid: string, stack: Array<any>): boolean {
        if (stack && stack.length) {
            for (const index in stack) {
                if (stack[index]) {
                    const item: any = stack[index];
                    if (item.uuid === uuid) {
                        stack.splice(parseInt(index, 10), 1);
                        return true;
                    } else if (item.items && item.items.length) {
                        if (this.recursiveRemove(uuid, item.items)) {
                            return true;
                        }
                    }
                }
            }
        }
    }

    /**
     * Sets the available survey languages.
     */
    private setAvailableLanguages(): void {
        for (const lang of <Array<ILanguage>>LanguageProvider.getAvailableLanguages()) {
            this.availableLanguages.push(
                SurveyLanguage.make(lang.label, lang.locale, lang.locale === this.settings.sourceLanguage)
            );
        }
    }
}
