import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItem } from '../../../../../../../domain/surveys/items/BaseItem';

@Component('its.survey.module.surveys', PlaceholderTextSettingComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/shared/inputs/placeholder-text-setting/PlaceholderTextSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class PlaceholderTextSettingComponent {
    static IID: string = 'PlaceholderTextSettingComponent';
    static CID: string = 'itsPlaceholderTextSetting';
    static $inject: string[] = [];

    public item: BaseItem;

    public onPlaceholderTextChange(): void {
        if ((<any>this.item.settings).placeholder) {
            const placeholder: string = String((<any>this.item.settings).placeholder).replace(/<[^>]+>/gm, '');
            (<any>this.item.settings).placeholder = placeholder;
        }
    }
}
