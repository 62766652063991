import { IThemeSettings } from '../ThemeSettings';
import { ThemeType } from '../../../../services/ThemeService';
import { BaseImageTheme } from './BaseImageTheme';

export class DarkPalmTheme extends BaseImageTheme {
    public slug: ThemeType = 'dark-palm';

    public constructor() {
        super('ferns.jpg');
        // main bg
        this.customizations['its-theme--background-content']['width'] = '100%';
        this.customizations['its-theme--background-content']['max-width'] = '100%';
        // padding
        this.customizations['its-theme--content-container']['margin-top'] = '0';
        this.customizations['its-theme--content-container']['margin-bottom'] = '0';
        // survey bg color
        this.customizations['its-theme--background-content']['background-color'] = 'rgba(0,15,22,0.65)';
        // question font
        this.customizations['its-theme--question-label']['color'] = '#FFFFFF';
        // response font
        this.customizations['its-theme--response-label-color']['color'] = '#FFFFFF';
        // help font
        this.customizations['its-theme--help-text']['color'] = '#EEEEEE';
        this.customizations['::placeholder']['color'] = '#EEEEEE';
        // title
        this.customizations['its-theme--title']['color'] = '#46a690';
        // brand color
        this.customizations['its-theme--brand-primary']['background-color'] = '#46a690';

        // error container
        this.customizations['its-theme--error-container']['background-color'] = 'rgba(250,0,0,0.15)';

        // border radius
        this.customizations['its-theme--background-content']['border-radius'] = '0';
        this.customizations['its-theme--question-container']['border-radius'] = '0';
        this.customizations['its-theme--title-container']['border-radius'] = '0';
        this.customizations['its-theme--button-container']['border-radius'] = '0';
    }

    public getName(): string {
        return 'SURVEYS.THEMES.MIDNIGHT_FERNS';
    }

    public getSettings(): IThemeSettings {
        return this.customizations;
    }
}
