import { ISurvey } from '../survey/Survey';
import { ISection, Section } from '../Section';
import { BaseItem, IBaseItem } from '../items/BaseItem';
import { ILogic } from './Logic';
import { BaseResponseItem } from '../items/BaseResponseItem';
import { IMatrix, IMatrixQuestion, Matrix } from '../items/Matrix';
import { IBaseResponse } from '../items/BaseResponse';

export class LogicDeleter {
    public static delete(survey: ISurvey, item: ISection | IBaseItem | IBaseResponse | IMatrixQuestion): ISurvey {
        if (item instanceof Section) {
            this.handleSectionDelete(survey, item);
        } else {
            this.processLogicDelete(survey, item);
        }

        survey.setAllItemLogicReferences();
        return survey;
    }

    /**
     * Handles section deletion and iterates through section items
     * to remove all of their references as well.
     *
     * @param survey
     * @param section
     */
    private static handleSectionDelete(survey: ISurvey, section: ISection): void {
        this.processLogicDelete(survey, section);

        // loop through and scrub each item's logic references
        if ((<any>section).items.length > 0) {
            for (const item of (<any>section).items) {
                this.processLogicDelete(survey, item);
            }
        }
    }

    /**
     * Iterates through the item's logic references of a given item and removes
     * all logic and logic items/conditions in association to it.
     *
     * @param survey
     * @param item
     */
    private static processLogicDelete(
        survey: ISurvey,
        item: IBaseItem | ISection | IBaseResponse | IMatrixQuestion
    ): void {
        if (item.logicReferences.length > 0) {
            for (const logicReference of item.logicReferences) {
                const logic: ILogic = survey.findLogicByUuid(logicReference.logic.uuid);
                if (logic) {
                    this.deleteLogicReference(survey, logic, logicReference.logicOwner.uuid, item.uuid);
                }

                if (logic && item instanceof BaseResponseItem && !(item instanceof Matrix)) {
                    for (const response of item.responses) {
                        this.deleteLogicReference(survey, logic, logicReference.logicOwner.uuid, response.uuid);
                    }
                }
            }
            // empty out references once we looped and removed
            item.logicReferences = [];
        }

        if (item instanceof BaseResponseItem && item instanceof Matrix) {
            this.processMatrixLogicDelete(survey, item);
        }

        if (item instanceof BaseItem && item.logic) {
            item.logic = null;
        }
    }

    /**
     * Matrix questions have a special case whereas questions can hold logic.
     * Loops through the questions to see if they are used in any logic and handle it.
     *
     * @param survey
     * @param item
     */
    private static processMatrixLogicDelete(survey: ISurvey, item: IMatrix): void {
        for (const question of item.questions) {
            if (question.logicReferences.length > 0) {
                for (const questionLogicReference of question.logicReferences) {
                    const questionLogic: ILogic = survey.findLogicByUuid(questionLogicReference.logic.uuid);
                    if (questionLogic) {
                        this.deleteLogicReference(
                            survey,
                            questionLogic,
                            questionLogicReference.logicOwner.uuid,
                            question.uuid
                        );
                    }
                }
                // empty out references once we looped and removed
                question.logicReferences = [];
            }
        }
    }

    /**
     * Verifies the item being removed against the operand (of the logic item) and the conditions (logic item conditions)
     * to see if it takes part in the logic.
     *
     * @param survey
     * @param logic
     * @param logicOwnerUuid
     * @param itemUuid
     */
    private static deleteLogicReference(
        survey: ISurvey,
        logic: ILogic,
        logicOwnerUuid: string,
        itemUuid: string
    ): void {
        // if we're deleting a section, check if we have jump to logic
        if (logic.settings && logic.settings.sectionUuid === itemUuid) {
            this.nullifyOwnerLogic(survey, logicOwnerUuid, logic.uuid);
        }

        // start at end of stack for removals, avoids creating invalid index
        if (logic.items.length > 0) {
            for (let itemIndex: number = logic.items.length - 1; itemIndex >= 0; itemIndex--) {
                // checks to see if the item uuid is used as the operand
                // if it is, jump to next because we're removing the entire item
                // so we don't need to check conditions
                if (logic.items[itemIndex].operand === itemUuid) {
                    logic.items.splice(itemIndex, 1);
                    continue;
                }

                // checks to see if the value (used in base response item) references our item uuid
                this.verifyLogicConditions(logic, itemIndex, itemUuid);
            }
        }

        // if we have no logic items left, delete this logic
        if (logic.items.length <= 0) {
            this.nullifyOwnerLogic(survey, logicOwnerUuid, logic.uuid);
        }
    }

    /**
     * Verify all logic item conditions to see if the item UUID is used as the value of the condition.
     * Remove any conditions referencing this item UUID and if the logic item no longer has conditions, remove it as well.
     *
     * @param logic
     * @param logicItemIndex
     * @param itemUuid
     */
    private static verifyLogicConditions(logic: ILogic, logicItemIndex: number, itemUuid: string): void {
        if (logic.items[logicItemIndex].conditions.length > 0) {
            // need to start from the end so we don't remove invalid index
            for (
                let conditionIndex: number = logic.items[logicItemIndex].conditions.length - 1;
                conditionIndex >= 0;
                conditionIndex--
            ) {
                if (
                    logic.items[logicItemIndex].conditions.length > 0 &&
                    logic.items[logicItemIndex].conditions[conditionIndex].value === itemUuid
                ) {
                    logic.items[logicItemIndex].conditions.splice(conditionIndex, 1);
                }

                // if we have no conditions left, remove this logic item
                if (logic.items[logicItemIndex].conditions.length <= 0) {
                    logic.items.splice(logicItemIndex, 1);
                }
            }
        }
    }

    /**
     * Find the owner of the logic in the survey and nulls it out
     * @param survey
     * @param logicOwnerUuid
     * @param logicUuid
     */
    private static nullifyOwnerLogic(survey: ISurvey, logicOwnerUuid: string, logicUuid: string): void {
        for (let i: number = survey.logic.length - 1; i >= 0; i--) {
            if (survey.logic[i].uuid === logicUuid) {
                survey.logic.splice(i, 1);
                return;
            }
        }

        const item: any = survey.getItemByUuid(logicOwnerUuid);

        if (item) {
            item.logic = null;
        }
    }
}
