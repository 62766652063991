import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IScrollService } from '@intouch/its.essential/app/essential/services/ScrollService';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { IFeatureLockCondition } from '@intouch/its.essential/app/essential/utils/FeatureLockUtils';
import { PermissionUtils } from '@intouch/its.essential/app/essential/utils/PermissionUtils';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';

import { IPageService } from '../../services/PageService';
import { ISection, Section } from '../../domain/surveys/Section';
import { ISurvey, Survey } from '../../domain/surveys/survey/Survey';
import { IBaseItem } from '../../domain/surveys/items/BaseItem';
import { IDefinedResponse } from '../../domain/surveys/items/ResponseFactory';
import { SurveyService } from '../../services/SurveyService';
import { ElementLibraryService } from '../../services/ElementLibraryService';
import { IElementLibraryGroupItem } from '../../domain/surveys/element-library/IElementLibraryGroup';
import { IContainItems } from '../../domain/surveys/items/ContainItems';
import { QuestionTooltipService } from '../../services/QuestionTooltipService';

@Controller('its.survey.module.surveys', DetailsController.IID, DetailsController)
class DetailsController {
    static IID: string = 'its.survey.module.surveys.DetailsController';
    static $inject: Array<string> = [
        '$state',
        'itsPageService',
        'itsSurveyService',
        'itsElementLibraryService',
        'iteScrollService',
        '$mdDialog',
        'iteToaster',
        '$timeout',
        '$document',
        '$scope',
        'APPCONFIG',
        '$mdMedia',
        '$stateParams',
        'iteAccessService',
        '$translate',
        'itePanelService',
        'itsQuestionTooltipService',
    ];

    public survey: Survey = null;
    public editingItem: ISection | IBaseItem = null;
    public publishing: boolean = false;
    public editing: boolean = false;
    public submitting: boolean = false;
    public openPanel: string = 'elements';
    public showPanel: boolean = true;
    public showResponseTemplates: boolean = false;
    public loading: boolean = false;
    public availableTemplates: Array<IDefinedResponse> = [];
    public elementLibraryGroups: any = [];

    constructor(
        private stateService: ng.ui.IStateService,
        private pageService: IPageService,
        private surveyService: SurveyService,
        private elementLibraryService: ElementLibraryService,
        private scrollService: IScrollService,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private timeout: ng.ITimeoutService,
        private document: ng.IDocumentService,
        private scope: ng.IScope,
        private config: any,
        private mdMedia: ng.material.IMedia,
        private stateParams: ng.ui.IStateParamsService,
        private accessService: IAccessService,
        private translate: ng.translate.ITranslateService,
        private panelService: IPanelService,
        private questionTooltipService: QuestionTooltipService
    ) {
        const excluded: Array<string> = [];

        for (const product of this.accessService.getUserProducts()) {
            if (product.slug === 'survey') {
                if (!product.enabledFeatures.includes('video-question')) {
                    excluded.push('video_input');
                }

                if (!product.enabledFeatures.includes('audio-question')) {
                    excluded.push('audio');
                }
            }
        }
        this.elementLibraryGroups = this.elementLibraryService.getElementLibraryGroups(excluded);
        this.surveyService.setSurvey(null);
        this.openPanel = stateParams['tab'];
        this.showPanel = stateParams['showPanel'];
        const surveyUuid: string = this.stateService.params['uuid'];
        this.surveyService.loadSurvey(surveyUuid);
        this.pageService.startLoading();
        this.subscribeToSurveyValue();
        this.subscribeToEditingItem();
        this.subscribeToCancelEditEscKeydownEvent();
        this.subscribeToSurveyState();
        this.scope.$on('$destroy', () => {
            this.surveyService.setSurvey(null);
            this.surveyService.setEditingItem(null);
        });
    }

    public permitQuestionType(type: string): boolean {
        if (type === 'audio' || type === 'video_input' || type === 'hidden_input') {
            return PermissionUtils.isEnterprise(this.accessService.getToken());
        }
        return true;
    }

    /**
     * Set focus on the settings input for label
     */
    public focusSettingsLabelInput(): void {
        this.timeout(() => {
            const ele: HTMLElement = this.document[0].getElementById('settingsLabel');
            if (ele) {
                ele.focus();
            }
        }, 500);
    }

    /**
     * Reset editing item and template values
     */
    public resetEditing(): void {
        this.editing = false;
        this.surveyService.setEditingItem(null);
    }

    /**
     * Cancel editing state, nullify editing item and reset survey to original survey
     */
    public cancelEdit(form?: ng.IFormController): void {
        this.resetEditing();
        if (!this.mdMedia('gt-sm')) {
            this.showPanel = false;
        }
        this.surveyService.revertEditAndRestoreSnapshot();
        if (form) {
            form.$setPristine();
            form.$setUntouched();
        }
    }

    public saveSurvey(survey: ISurvey): void {
        this.surveyService.saveSurveyAndNotify(survey).finally(() => {
            this.resetEditing();
            if (!this.mdMedia('gt-sm')) {
                this.showPanel = false;
            }
        });
    }

    public publishSurvey(): void {
        this.surveyService.publish(this.survey);
    }

    /**
     * Go to preview screen
     */
    public preview(): void {
        this.stateService.go('home.surveys.edit.preview', { uuid: this.survey.uuid }, { reload: true, notify: true });
    }

    public addQuestionToLastSectionAndEdit(
        libraryGroupItem: IElementLibraryGroupItem,
        fromLibrary: boolean = false
    ): void {
        this.hideTooltipPanel();
        this.surveyService.startQuestionEdit(libraryGroupItem, null, null, fromLibrary);
    }

    /**
     * Must return boolean to work with the angular drag and drop library.
     * Returning true means we added the item ourselves (or didn't so nothing changes), false will cancel the operation
     * but will bubble upwards to the next callback. Any other value will be treated as the value to add to the array.
     *
     * @param {IElementLibraryGroupItem} libraryGroupItem
     * @param {IContainItems} targetParent
     * @param {number} targetIndex
     * @param {boolean} fromLibrary
     */
    public handleDropCreate(
        libraryGroupItem: IElementLibraryGroupItem,
        targetParent: IContainItems,
        targetIndex: number,
        fromLibrary: boolean = false
    ): boolean {
        this.surveyService.startQuestionEdit(libraryGroupItem, targetParent, targetIndex, fromLibrary);
        return true;
    }

    public showTooltipPanel(
        libraryGroupItem: IElementLibraryGroupItem,
        event: any,
        featureLockedConditions?: Array<IFeatureLockCondition>
    ): void {
        this.questionTooltipService.openQuestionTooltip(libraryGroupItem, event, featureLockedConditions);
    }

    public hideTooltipPanel(): void {
        this.questionTooltipService.cancelExistingQuestionTooltips();
    }

    private subscribeToSurveyValue(): void {
        const surveySubscription: SubjectSubscription = this.surveyService.surveyValue.subscribe((survey) => {
            this.survey = survey;
            if (survey) {
                this.pageService.setPageTitle(survey.name);
                this.pageService.stopLoading();
                this.addSectionToSurveyIfNoneExist(survey);
            }
        });
        surveySubscription.unsubscribeOnScopeDestruction(this.scope);
    }

    private addSectionToSurveyIfNoneExist(survey: Survey): void {
        if (survey.sections.length === 0) {
            const newSection: Section = Section.make('Page 1');
            this.surveyService.addNewSection(newSection);
        }
    }

    private subscribeToSurveyState(): void {
        const surveyStateSubscription: SubjectSubscription = this.surveyService.isSaving.subscribe((isSaving) => {
            this.submitting = isSaving;
        });
        surveyStateSubscription.unsubscribeOnScopeDestruction(this.scope);
    }

    private subscribeToEditingItem(): void {
        const editingItemSubscription: SubjectSubscription = this.surveyService.editingItem.subscribe((editingItem) => {
            this.editingItem = <IBaseItem | ISection>editingItem;
            this.editing = !!editingItem;
            if (editingItem) {
                this.focusLabelInput();
                this.scrollToEditingItem();
            }
        });
        editingItemSubscription.unsubscribeOnScopeDestruction(this.scope);
    }

    private focusLabelInput(): void {
        this.showPanel = true;
        this.focusSettingsLabelInput();
    }

    private scrollToEditingItem(): void {
        const questionLabelId: string = 'itsSectionItem' + this.editingItem.uuid;
        this.timeout(() => {
            this.scrollService.scrollTo(questionLabelId);
        }, 500);
    }

    private subscribeToCancelEditEscKeydownEvent(): void {
        const escapeKeyCode: number = 27;
        this.document.on('keydown', (event) => {
            if (event.which === escapeKeyCode && this.editingItem) {
                this.cancelEdit();
                this.scope.$apply();
            }
        });
    }
}
