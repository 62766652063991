import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';
import { Calendar } from '../../../../../../domain/surveys/items/Calendar';

@Component('its.survey.module.surveys', CalendarRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/calendar/CalendarRenderComponent.html'),
    controllerAs: 'vm',
    bindings: CalendarRenderComponent.getBindings(),
})
class CalendarRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'CalendarRenderComponent';
    static CID: string = 'itsCalendarRender';
    static $inject: string[] = [];

    public item: Calendar;
}
