import { IElementLibraryGroup } from './IElementLibraryGroup';
import { Geo } from '@intouch/its.essential/app/essential/utils/Geo';
import { ResponseFactory } from '../items/ResponseFactory';
import { IScaleResponse, ScaleResponse } from '../items/Scale';

export class LibraryGroups {
    constructor(private translate: ng.translate.ITranslateService) {}

    public getElementLibraryGroups(): Array<IElementLibraryGroup> {
        return [
            {
                label: this.translate.instant('SURVEYS.INPUT'),
                items: [
                    {
                        icon: 'radio_button_checked',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.MULTIPLE_CHOICE'),
                        type: 'radio',
                    },
                    {
                        icon: 'check_box_outline_blank',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.CHECKBOXES'),
                        type: 'checkbox',
                    },
                    {
                        icon: 'star_border',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.RATING'),
                        type: 'rating',
                    },
                    {
                        icon: 'arrow_drop_down',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.DROPDOWN'),
                        type: 'select',
                    },
                    {
                        icon: 'format_list_numbered',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.RANKING'),
                        type: 'ranking',
                    },
                    {
                        icon: 'sentiment_satisfied',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.NPS'),
                        type: 'nps',
                    },
                    {
                        icon: 'featured_play_list',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.TEXT_AREA_2'),
                        type: 'textarea',
                    },
                    {
                        icon: 'crop_7_5',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.TEXT_INPUT_2'),
                        type: 'input',
                    },
                    {
                        icon: 'linear_scale',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.SLIDER'),
                        type: 'slider',
                    },
                    {
                        icon: 'settings_ethernet',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.SCALE'),
                        type: 'scale',
                    },
                    {
                        icon: 'my_location',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.LOCATION'),
                        type: 'location',
                    },
                    {
                        icon: 'looks_one',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.NUMBER'),
                        type: 'number',
                    },
                    {
                        icon: 'rotate_right',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.CAPTCHA'),
                        type: 'captcha',
                        featureLockConditions: [{ product: 'survey', unlockTiers: ['lead'] }],
                    },
                    {
                        icon: 'date_range',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.CALENDAR'),
                        type: 'calendar',
                    },
                    {
                        icon: 'view_comfy',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.MATRIX'),
                        type: 'matrix',
                        featureLockConditions: [{ product: 'survey', unlockTiers: ['lead'] }],
                    },
                    {
                        icon: 'add_a_photo',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.IMAGE_UPLOAD'),
                        type: 'image_upload',
                        featureLockConditions: [{ product: 'survey', unlockTiers: ['lead'] }],
                    },
                    { icon: 'mic', label: this.translate.instant('SURVEYS.ITEMS.LABELS.AUDIO'), type: 'audio' },
                    {
                        icon: 'videocam',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.VIDEO_INPUT'),
                        type: 'video_input',
                    },
                    {
                        icon: 'visibility_off',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.HIDDEN_INPUT'),
                        type: 'hidden_input',
                    },
                ],
            },
            {
                label: this.translate.instant('SURVEYS.DISPLAY'),
                items: [
                    {
                        icon: 'short_text',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.DISPLAY_TEXT'),
                        type: 'text',
                    },
                    { icon: 'photo', label: this.translate.instant('SURVEYS.ITEMS.LABELS.GRAPHIC'), type: 'image' },
                    {
                        icon: 'content_copy',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.GROUP'),
                        type: 'group',
                    },
                    {
                        icon: 'ondemand_video',
                        label: this.translate.instant('SURVEYS.ITEMS.LABELS.VIDEO'),
                        type: 'video',
                    },
                ],
            },
        ];
    }

    public getQuestionLibraryGroups(): Array<IElementLibraryGroup> {
        return [
            {
                icon: 'people_outline',
                label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.LABEL'),
                items: [
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.AGE.LABEL'),
                        type: 'radio',
                        data: {
                            label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.AGE.LABEL'),
                            responses: [
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.AGE.RESPONSES.UNDER',
                                        { age: 18 }
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.AGE.RESPONSES.BETWEEN',
                                        { lowerAge: 18, upperAge: 24 }
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.AGE.RESPONSES.BETWEEN',
                                        { lowerAge: 25, upperAge: 34 }
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.AGE.RESPONSES.BETWEEN',
                                        { lowerAge: 35, upperAge: 44 }
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.AGE.RESPONSES.BETWEEN',
                                        { lowerAge: 45, upperAge: 54 }
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.AGE.RESPONSES.BETWEEN',
                                        { lowerAge: 55, upperAge: 64 }
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.AGE.RESPONSES.BETWEEN',
                                        { lowerAge: 65, upperAge: 74 }
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.AGE.RESPONSES.OVER',
                                        { age: 75 }
                                    ),
                                },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.GENDER.LABEL'),
                        type: 'radio',
                        data: {
                            label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.GENDER.LABEL'),
                            responses: [
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.GENDER.RESPONSES.MALE'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.GENDER.RESPONSES.FEMALE'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.GENDER.RESPONSES.PREFER_NOT_TO_ANSWER'
                                    ),
                                },
                            ],
                            settings: {
                                show_other: true,
                            },
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.FIRST_NAME.LABEL'),
                        type: 'input',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.FIRST_NAME.LABEL'
                            ),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.LAST_NAME.LABEL'),
                        type: 'input',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.LAST_NAME.LABEL'
                            ),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.COUNTRY.LABEL'),
                        type: 'select',
                        data: {
                            label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.COUNTRY.LABEL'),
                            responses: this.getCountryDDList(),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.PROVINCE.LABEL'),
                        type: 'select',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.PROVINCE.LABEL'
                            ),
                            responses: this.getProvinceDDList(),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.STATE.LABEL'),
                        type: 'select',
                        data: {
                            label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.DEMOGRAPHICS.STATE.LABEL'),
                            responses: this.getStateDDList(),
                        },
                    },
                ],
            },
            {
                label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.LABEL'),
                items: [
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.POSITIVE_IMPACT.LABEL'
                        ),
                        type: 'radio',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.POSITIVE_IMPACT.LABEL'
                            ),
                            responses: [
                                { label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.YES') },
                                { label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.NO') },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.TRUST_EACH_OTHER.LABEL'
                        ),
                        type: 'radio',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.TRUST_EACH_OTHER.LABEL'
                            ),
                            responses: [
                                { label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.YES') },
                                { label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.NO') },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.EMPLOYEES_TASKS.LABEL'
                        ),
                        type: 'radio',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.EMPLOYEES_TASKS.LABEL'
                            ),
                            responses: [
                                { label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.YES') },
                                { label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.NO') },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.WORKING_CONDITIONS.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.WORKING_CONDITIONS.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('agree_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.WORK_LIFE_BALANCE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.WORK_LIFE_BALANCE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('agree_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.BENEFITS_PACKAGE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.BENEFITS_PACKAGE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('agree_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.HARD_WORK.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.HARD_WORK.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('agree_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.GOOD_PERFORMANCE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_ENGAGEMENT.GOOD_PERFORMANCE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('agree_5'),
                        },
                    },
                ],
            },
            {
                label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.LABEL'),
                items: [
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_ARRANGEMENT.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_ARRANGEMENT.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.LAST_WEEK_WFH.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.LAST_WEEK_WFH.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: [
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.EXTREMELY_PESSIMISTIC'
                                    ),
                                    numeric_value: 1,
                                },
                                {
                                    label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.PESSIMISTIC'),
                                    numeric_value: 2,
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.OPTIMISTIC_NOR_PESSIMISTIC'
                                    ),
                                    numeric_value: 3,
                                },
                                {
                                    label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.OPTIMISTIC'),
                                    numeric_value: 4,
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.EXTREMELY_OPTIMISTIC'
                                    ),
                                    numeric_value: 5,
                                },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_CHALLENGES.LABEL'
                        ),
                        type: 'checkbox',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_CHALLENGES.LABEL'
                            ),
                            settings: {
                                show_other: true,
                            },
                            responses: [
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_CHALLENGES.RESPONSES.SOCIAL_ISOLATION'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_CHALLENGES.RESPONSES.INTERNET_CONNECTIVITY'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_CHALLENGES.RESPONSES.COMMUNICATION_IS_DIFFICULT'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_CHALLENGES.RESPONSES.MY_WORKSPACE'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_CHALLENGES.RESPONSES.KEEPING_A_SCHEDULE'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_CHALLENGES.RESPONSES.CHILDCARE'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_CHALLENGES.RESPONSES.TOO_MANY_DISTRACTIONS'
                                    ),
                                },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_REQUIREMENTS.LABEL'
                        ),
                        type: 'radio',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WFH_REQUIREMENTS.LABEL'
                            ),
                            responses: [
                                { label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.YES') },
                                { label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.NO') },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.DEDICATED_WORKSPACE.LABEL'
                        ),
                        type: 'radio',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.DEDICATED_WORKSPACE.LABEL'
                            ),
                            responses: [
                                { label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.YES') },
                                { label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.NO') },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WORK_SCHEDULE.LABEL'
                        ),
                        type: 'checkbox',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WORK_SCHEDULE.LABEL'
                            ),
                            responses: [
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WORK_SCHEDULE.RESPONSES.EVERY_DAY'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WORK_SCHEDULE.RESPONSES.MOST_DAYS'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WORK_SCHEDULE.RESPONSES.HALF_TIME'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WORK_SCHEDULE.RESPONSES.RARELY'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.EMPLOYEE_CHECK_IN.WORK_SCHEDULE.RESPONSES.NEVER'
                                    ),
                                },
                            ],
                        },
                    },
                ],
            },
            {
                label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.LABEL'),
                items: [
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.MADE_YOU_FEEL.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.MADE_YOU_FEEL.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.CLEANLINESS.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.CLEANLINESS.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.ATMOSPHERE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.ATMOSPHERE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.SERVICE_SPEED.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.SERVICE_SPEED.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.PRODUCT_QUALITY.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.PRODUCT_QUALITY.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.PRODUCT_PRICE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.PRODUCT_PRICE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.PRODUCT_VARIETY.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.PRODUCT_VARIETY.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.EXPERIENCE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.EXPERIENCE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.TOP_INFLUENTIAL_FACTORS.LABEL_2'
                        ),
                        type: 'checkbox',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.TOP_INFLUENTIAL_FACTORS.LABEL_2'
                            ),
                            responses: [
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.TOP_INFLUENTIAL_FACTORS.RESPONSES.PRICE'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.TOP_INFLUENTIAL_FACTORS.RESPONSES.BRANDING'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.TOP_INFLUENTIAL_FACTORS.RESPONSES.LOCATION'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.TOP_INFLUENTIAL_FACTORS.RESPONSES.QUALITY'
                                    ),
                                },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.RECOMMEND_US.LABEL'
                        ),
                        type: 'nps',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_PETRO_CONVENIENCE_STORES.RECOMMEND_US.LABEL'
                            ),
                        },
                    },
                ],
            },
            {
                label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.LABEL'),
                items: [
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.MADE_YOU_FEEL.LABEL'),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.MADE_YOU_FEEL.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.ASSISTED_BY_TEAM.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.ASSISTED_BY_TEAM.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.OVERALL_CLEANLINESS.LABEL'
                        ),
                        type: 'rating',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.OVERALL_CLEANLINESS.LABEL'
                            ),
                            settings: {
                                icon: 'star',
                                max_score: 5,
                            },
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.OVERALL_ATMOSPHERE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.OVERALL_ATMOSPHERE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.SERVICE_SPEED.LABEL'),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.SERVICE_SPEED.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.RESPONSIVENESS_TO_CONCERNS.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.RESPONSIVENESS_TO_CONCERNS.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.PRODUCT_PURCHASED.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.PRODUCT_PURCHASED.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.PRODUCT_PRICE.LABEL'),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.PRODUCT_PRICE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.PRODUCT_QUALITY.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.PRODUCT_QUALITY.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.EXPERIENCE.LABEL'),
                        type: 'rating',
                        data: {
                            label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.EXPERIENCE.LABEL'),
                            settings: {
                                icon: 'star',
                                max_score: 5,
                            },
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.TOP_INFLUENTIAL_FACTORS.LABEL_2'
                        ),
                        type: 'checkbox',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.TOP_INFLUENTIAL_FACTORS.LABEL_2'
                            ),
                            responses: [
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.TOP_INFLUENTIAL_FACTORS.RESPONSES.PRICE'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.TOP_INFLUENTIAL_FACTORS.RESPONSES.BRANDING'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.TOP_INFLUENTIAL_FACTORS.RESPONSES.LOCATION'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.TOP_INFLUENTIAL_FACTORS.RESPONSES.QUALITY'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.TOP_INFLUENTIAL_FACTORS.RESPONSES.CLEANLINESS'
                                    ),
                                },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.RECOMMEND_US.LABEL'),
                        type: 'nps',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RETAIL.RECOMMEND_US.LABEL'
                            ),
                        },
                    },
                ],
            },
            {
                label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.LABEL'),
                items: [
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.MEAL_PREP_TIME.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.MEAL_PREP_TIME.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.MEAL_ARRIVAL_TIME.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.MEAL_ARRIVAL_TIME.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.ONLINE_ORDERING.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.ONLINE_ORDERING.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.MEAL_SATISFACTION.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.MEAL_SATISFACTION.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.CUSTOMER_SERVICE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.CUSTOMER_SERVICE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.SERVICE_SPEED.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.SERVICE_SPEED.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.DOING_BUSINESS.LABEL'
                        ),
                        type: 'rating',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.DOING_BUSINESS.LABEL'
                            ),
                            settings: {
                                icon: 'star',
                                max_score: 5,
                            },
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.EXPERIENCE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.EXPERIENCE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.RECOMMEND_US.LABEL'
                        ),
                        type: 'nps',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_RESTAURANTS.RECOMMEND_US.LABEL'
                            ),
                        },
                    },
                ],
            },
            {
                label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.LABEL'),
                items: [
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.SERVICE_SPEED.LABEL'),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.SERVICE_SPEED.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.CUSTOMER_SERVICE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.CUSTOMER_SERVICE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.CLEANLINESS.LABEL'),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.CLEANLINESS.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.EASY_TO_FIND.LABEL'),
                        type: 'radio',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.EASY_TO_FIND.LABEL'
                            ),
                            responses: [
                                { label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.YES') },
                                { label: this.translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.NO') },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.WHY_DIFFICULT.LABEL'),
                        type: 'textarea',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.WHY_DIFFICULT.LABEL'
                            ),
                            settings: {
                                rows: '5',
                            },
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.PRODUCT_PURCHASED.LABEL_2'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.PRODUCT_PURCHASED.LABEL_2'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.PRODUCT_PRICE.LABEL'),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.PRODUCT_PRICE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.PRODUCT_QUALITY.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.PRODUCT_QUALITY.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.PRODUCT_AVAILABILITY.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.PRODUCT_AVAILABILITY.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.CHECKOUT_EFFICIENCY.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.CHECKOUT_EFFICIENCY.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.LOCATION_EXPERIENCE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.LOCATION_EXPERIENCE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.RECOMMEND_US.LABEL'),
                        type: 'nps',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.RECOMMEND_US.LABEL'
                            ),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.TOP_INFLUENTIAL_FACTORS.LABEL_2'
                        ),
                        type: 'checkbox',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.TOP_INFLUENTIAL_FACTORS.LABEL_2'
                            ),
                            responses: [
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.TOP_INFLUENTIAL_FACTORS.RESPONSES.PRICE'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.TOP_INFLUENTIAL_FACTORS.RESPONSES.BRANDING'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.TOP_INFLUENTIAL_FACTORS.RESPONSES.LOCATION'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.TOP_INFLUENTIAL_FACTORS.RESPONSES.QUALITY'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.TOP_INFLUENTIAL_FACTORS.RESPONSES.CLEANLINESS'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_GROCERY.TOP_INFLUENTIAL_FACTORS.RESPONSES.PRODUCT_AVAILABILITY'
                                    ),
                                },
                            ],
                        },
                    },
                ],
            },
            {
                label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.LABEL'),
                items: [
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.ASSISTANCE_SATISFACTION.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.ASSISTANCE_SATISFACTION.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.CUSTOMER_SERVICE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.CUSTOMER_SERVICE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.CLEANLINESS.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.CLEANLINESS.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.ATMOSPHERE.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.ATMOSPHERE.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.MADE_YOU_FEEL.LABEL'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.MADE_YOU_FEEL.LABEL'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.PRODUCT_SERVICE_QUALITY.LABEL_2'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.PRODUCT_SERVICE_QUALITY.LABEL_2'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.RESPONSIVENESS_TO_CONCERNS.LABEL_2'
                        ),
                        type: 'scale',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.RESPONSIVENESS_TO_CONCERNS.LABEL_2'
                            ),
                            settings: {
                                response_type: '5',
                            },
                            responses: this.getScaleResponses('satisfied_5'),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.EXPERIENCE.LABEL'
                        ),
                        type: 'rating',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.EXPERIENCE.LABEL'
                            ),
                            settings: {
                                icon: 'star',
                                max_score: 5,
                            },
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.RECOMMEND_US.LABEL'
                        ),
                        type: 'nps',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.RECOMMEND_US.LABEL'
                            ),
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.TOP_INFLUENTIAL_FACTORS.LABEL_2'
                        ),
                        type: 'checkbox',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.TOP_INFLUENTIAL_FACTORS.LABEL_2'
                            ),
                            responses: [
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.TOP_INFLUENTIAL_FACTORS.RESPONSES.PRICE'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.TOP_INFLUENTIAL_FACTORS.RESPONSES.LUXURY_ITEMS'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.TOP_INFLUENTIAL_FACTORS.RESPONSES.CONVENIENT_LOCATION'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.TOP_INFLUENTIAL_FACTORS.RESPONSES.QUALITY'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.TOP_INFLUENTIAL_FACTORS.RESPONSES.CLEANLINESS'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.QUESTION_LIBRARY_GROUPS.CS_HOSPITALITY.TOP_INFLUENTIAL_FACTORS.RESPONSES.PUBLIC_TRANSPORT'
                                    ),
                                },
                            ],
                        },
                    },
                ],
            },
            {
                icon: 'sentiment_satisfied',
                label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.NPS.LABEL'),
                items: [
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.NPS.RECOMMEND_COMPANY.LABEL'),
                        type: 'nps',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.NPS.RECOMMEND_COMPANY.LABEL'
                            ),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.NPS.RECOMMEND_LOCATION.LABEL'),
                        type: 'nps',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.NPS.RECOMMEND_LOCATION.LABEL'
                            ),
                        },
                    },
                    {
                        label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.NPS.RECOMMEND_BRAND.LABEL'),
                        type: 'nps',
                        data: {
                            label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.NPS.RECOMMEND_BRAND.LABEL'),
                        },
                    },
                ],
            },
            {
                icon: 'chat_bubble_outline',
                label: this.translate.instant('SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.LABEL'),
                items: [
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.SATISFACTION_COMPANY_RATING.DISPLAY_LABEL'
                        ),
                        type: 'rating',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.SATISFACTION_COMPANY_RATING.LABEL'
                            ),
                            settings: {
                                icon: 'star',
                                maxScore: 10,
                            },
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.SATISFACTION_LOCATION_RATING.DISPLAY_LABEL'
                        ),
                        type: 'rating',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.SATISFACTION_LOCATION_RATING.LABEL'
                            ),
                            settings: {
                                icon: 'star',
                                maxScore: 10,
                            },
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.SATISFACTION_BRAND_RATING.DISPLAY_LABEL'
                        ),
                        type: 'rating',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.SATISFACTION_BRAND_RATING.LABEL'
                            ),
                            settings: {
                                icon: 'star',
                                maxScore: 10,
                            },
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.SATISFACTION_COMPANY_RADIO.DISPLAY_LABEL'
                        ),
                        type: 'radio',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.SATISFACTION_COMPANY_RADIO.LABEL'
                            ),
                            responses: [
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.EXTREMELY_SATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.MODERATELY_SATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SLIGHTLY_SATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SATISFIED_NOR_DISSATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SLIGHTLY_DISSATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.MODERATELY_DISSATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.EXTREMELY_DISSATISFIED'
                                    ),
                                },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.SATISFACTION_LOCATION_RADIO.DISPLAY_LABEL'
                        ),
                        type: 'radio',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.SATISFACTION_LOCATION_RADIO.LABEL'
                            ),
                            responses: [
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.EXTREMELY_SATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.MODERATELY_SATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SLIGHTLY_SATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SATISFIED_NOR_DISSATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SLIGHTLY_DISSATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.MODERATELY_DISSATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.EXTREMELY_DISSATISFIED'
                                    ),
                                },
                            ],
                        },
                    },
                    {
                        label: this.translate.instant(
                            'SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.SATISFACTION_BRAND_RADIO.DISPLAY_LABEL'
                        ),
                        type: 'radio',
                        data: {
                            label: this.translate.instant(
                                'SURVEYS.QUESTION_LIBRARY_GROUPS.CSAT.SATISFACTION_BRAND_RADIO.LABEL'
                            ),
                            responses: [
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.EXTREMELY_SATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.MODERATELY_SATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SLIGHTLY_SATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SATISFIED_NOR_DISSATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SLIGHTLY_DISSATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.MODERATELY_DISSATISFIED'
                                    ),
                                },
                                {
                                    label: this.translate.instant(
                                        'SURVEYS.RESPONSE_TEMPLATES.RESPONSES.EXTREMELY_DISSATISFIED'
                                    ),
                                },
                            ],
                        },
                    },
                ],
            },
        ];
    }

    private getCountryDDList(): Array<{ label: string }> {
        return Geo.countriesFull().map((countryItem) => {
            return { label: countryItem.text };
        });
    }

    private getProvinceDDList(): Array<{ label: string }> {
        return Geo.provincesFull().map((provinceItem) => {
            return { label: provinceItem.text };
        });
    }

    private getStateDDList(): Array<{ label: string }> {
        return Geo.statesFull().map((stateItem) => {
            return { label: stateItem.text };
        });
    }

    private getScaleResponses(responseType: string): Array<{ label: string; numeric_value: number }> {
        return ResponseFactory.buildDefinedResponses(responseType, ScaleResponse, this.translate).map(
            (scaleResponseItem: IScaleResponse) => {
                return { label: scaleResponseItem.label, numeric_value: scaleResponseItem.numericValue };
            }
        );
    }
}
