import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { IPageService } from '../../../../services/PageService';
import { BaseSurvey } from '../../BaseSurvey';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ISurveyService } from '../../../../services/SurveyService';
import { UpsellTool } from '@intouch/its.essential/app/essential/tools/UpsellTool';
import { ISiteNavigationService } from '@intouch/its.essential/app/essential/services/SiteNavigationService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';
import { renderSVG, encode } from 'uqr';
@Controller('its.survey.module.surveys.distributions', DistributionQrCodeController.IID, DistributionQrCodeController)
class DistributionQrCodeController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.distributions.DistributionQrCodeController';
    static $inject: Array<string> = [
        'APPCONFIG',
        '$state',
        '$mdDialog',
        '$stateParams',
        'itsSurveyService',
        'iteToaster',
        'itsPageService',
        'itsSurveyApi',
        '$scope',
        'iteAccessService',
        'iteSiteNavigationService',
        'iteUserLocalTimeService',
    ];

    public upsellTool: UpsellTool = null;

    protected qrCodeLink: string = null;
    protected qrCodeSize: number = 200;

    constructor(
        private config: any,
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected scope: ng.IScope,
        private accessService: IAccessService,
        private siteNavService: ISiteNavigationService,
        protected userLocalTimeService: IUserLocalTimeService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );

        this.upsellTool = new UpsellTool(this.accessService, this.siteNavService).setStateByProductTiers('survey', [
            'grow',
            'scale',
            'lead',
        ]);

        if (!this.upsellTool.showUpsell()) {
            this.load().then(() => {
                this.setQrCodeUrl();
            });
        }
    }

    /**
     * Retrieves a QR code image of the survey URL
     *
     * @returns {string}
     */
    public setQrCodeUrl(): void {
        // This svg string that gets generated is too long to be put directly into an img tag
        const svg: string = renderSVG(this.getSurveyUrl(false), { ecc: 'L' });
        const blob: Blob = new Blob([svg], { type: 'image/svg+xml' });
        const url: string = URL.createObjectURL(blob);
        this.qrCodeLink = url;
    }

    /**
     * Retrieves the public facing URL of the survey
     *
     * @param {boolean} urlEncoded
     * @returns {string}
     */
    public getSurveyUrl(urlEncoded: boolean = true): string {
        const url: string = this.surveyService.getSurveyUrl(this.survey) + '/' + this.survey.originalUuid;
        return urlEncoded ? encodeURIComponent(url) : url;
    }
}
