import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', AudioInputRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/audio-input/AudioInputRenderComponent.html'),
    controllerAs: 'vm',
    bindings: AudioInputRenderComponent.getBindings(),
})
class AudioInputRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'AudioInputRenderComponent';
    static CID: string = 'itsAudioInputRender';
    static $inject: string[] = [];
}
