export interface IAddContactToContactListUpload {
    type: string;
    file: File;
    configuration: any;
}

/**
 * Add Contact To Contact List upload class
 */
export class AddContactToContactListUpload implements IAddContactToContactListUpload {
    public file: File;
    public configuration: any = '';
    public type: string = 'contact_lists';
}
