import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', DropdownRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/response-items/dropdown/DropdownRenderComponent.html'),
    controllerAs: 'vm',
    bindings: DropdownRenderComponent.getBindings(),
})
class DropdownRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'DropdownRenderComponent';
    static CID: string = 'itsDropdownRender';
    static $inject: string[] = [];
}
