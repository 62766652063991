import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Select } from '../../../../../../domain/surveys/items/Select';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';
import { ItemSettingsTab } from '../../shared/item-settings-tabs/ItemSettingsTabs';

@Component('its.survey.module.surveys', DropdownSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/response-items/dropdown/DropdownSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: DropdownSettingsComponent.getBindings(),
})
class DropdownSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'DropdownSettingsComponent';
    static CID: string = 'itsDropdownSettings';
    static $inject: string[] = ['$translate'];

    public item: Select;
    public tabs: ItemSettingsTab[] = ['basic', 'advanced', 'points'];
}
