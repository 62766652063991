import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IPhotoService, IParsedImage } from '@intouch/its.essential/app/essential/services/PhotoService';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { IImage } from '../../../../domain/surveys/items/Image';

@Component('its.survey.module.surveys', TranslationImageItem.CID, {
    template: require('/app/modules/surveys/components/translations/TranslationImageItemComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
        locale: '<',
        imgUrl: '<',
        transImgUrl: '<',
        flexValue: '<?',
        flexOffsetValue: '<?',
    },
})
class TranslationImageItem {
    static IID: string = 'TranslationImageItem';
    static CID: string = 'itsTranslationImageItem';
    static $inject: Array<string> = ['itePhotoService', 'itsSurveyApi', 'iteToaster'];

    protected item: IImage;
    protected locale: string;
    protected imgUrl: string;
    protected flexValue: number;
    protected flexOffsetValue: number;

    constructor(private photoService: IPhotoService, private surveyApi: ISurveyApi, private toaster: IToaster) {
        if (!this.flexValue) {
            this.flexValue = 40;
        }
        if (!this.flexOffsetValue) {
            this.flexOffsetValue = 5;
        }
    }

    /**
     * Uploads an image
     *
     * @param {File} file
     */
    public uploadImage(file: File): void {
        if (!file) {
            return;
        }

        this.photoService
            .parse(file, null, null, true)
            .then((parsedImage: IParsedImage) => {
                this.surveyApi.addPhoto(parsedImage.base64).then((response) => {
                    (<IImage>this.item).setTranslatedImage(this.locale, response.data);
                });
            })
            .catch((error) => {
                // todo translations
                this.toaster.error('ERRORS.PHOTO_NOT_ADDED', error);
            });
    }
}
