import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IMailSender, MailSender } from './MailSender';

export interface IGeneralSettings extends IEntity {
    fatigueProtection: number;
    customDomains: Array<string>;
    mailSenders: Array<IMailSender>;
}

export class GeneralSettings extends Entity implements IGeneralSettings {
    fatigueProtection: number = 1;
    customDomains: Array<string> = [];
    mailSenders: Array<IMailSender> = [];

    public fromJson(json: any, convert: boolean = true): IGeneralSettings {
        super.fromJson(json, convert);

        if (json.mail_senders) {
            this.mailSenders = EntityBuilder.buildMany<IMailSender>(MailSender, json.mail_senders, convert);
        }

        return this;
    }
}
