import { IThemeSettings } from '../ThemeSettings';
import { ThemeType } from '../../../../services/ThemeService';
import { BaseImageTheme } from './BaseImageTheme';

export class PinkDawnTheme extends BaseImageTheme {
    public slug: ThemeType = 'pink-dawn';

    public constructor() {
        super('clouds.jpg');
        // main bg

        // padding
        this.customizations['its-theme--content-container']['margin-top'] = '40px';
        this.customizations['its-theme--content-container']['margin-bottom'] = '40px';
        // survey bg color
        this.customizations['its-theme--background-content']['background-color'] = 'transparent';
        this.customizations['its-theme--background-content']['box-shadow'] = 'none';

        this.customizations['its-theme--question-container']['background-color'] = 'rgba(255,254,254,0.95)';

        // question spacing
        this.customizations['its-theme--question-container']['margin-top'] = '20px';
        this.customizations['its-theme--question-container']['margin-bottom'] = '20px';

        // drop shadow
        this.customizations['its-theme--question-container']['box-shadow'] = '0 3px 15px 0 rgb(0 0 0 / 20%)';
        this.customizations['its-theme--title-container']['box-shadow'] = '0 3px 15px 0 rgb(0 0 0 / 20%)';
        this.customizations['its-theme--button-container']['box-shadow'] = '0 3px 15px 0 rgb(0 0 0 / 20%)';
        // help font
        this.customizations['its-theme--help-text']['color'] = '#444444';
        // title
        this.customizations['its-theme--title']['color'] = '#f57769';
        // brand color
        this.customizations['its-theme--brand-primary']['background-color'] = '#f57769';

        // border radius
        this.customizations['its-theme--background-content']['border-radius'] = '10px';
        this.customizations['its-theme--question-container']['border-radius'] = '10px';
        this.customizations['its-theme--title-container']['border-radius'] = '10px';
        this.customizations['its-theme--button-container']['border-radius'] = '10px';
    }

    public getName(): string {
        return 'Pink Dawn';
    }

    public getSettings(): IThemeSettings {
        return this.customizations;
    }
}
