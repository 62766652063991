import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', SurveyCompletionRateComponent.CID, {
    template: require('/app/modules/surveys/summary/components/survey-completion-rate/SurveyCompletionRateComponent.html'),
    controllerAs: 'vm',
    bindings: {
        metrics: '<',
    },
})
class SurveyCompletionRateComponent {
    static IID: string = 'SurveyCompletionRateComponent';
    static CID: string = 'itsSurveyCompletionRate';
    static $inject: Array<string> = ['$mdMedia'];

    public metrics: { submissions: number; views: number };
    public actualCompletionRate: number = null;

    public constructor(public media: ng.material.IMedia) {
        this.calculateCompletionRate();
    }

    private calculateCompletionRate(): void {
        if (!!this.metrics && !!this.metrics.submissions && !!this.metrics.views) {
            this.actualCompletionRate = Math.min((this.metrics.submissions / this.metrics.views) * 100, 100);
        }
    }
}
