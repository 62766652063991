import { Configuration } from '@intouch/its.essential/app/essential/decorators/Configuration';
import { StateRerouter } from '@intouch/its.essential/app/essential/utils/StateRerouter';

/**
 * Will setup the routing states
 *
 * @param $stateProvider
 */
@Configuration('its.survey.module.surveys', DistributionsConfiguration)
class DistributionsConfiguration {
    /**
     * Create the instance of the configuration used for angular binding
     *
     * @returns {any}
     */
    public static instance(): any {
        const config: any = ($stateProvider: ng.ui.IStateProvider) => new DistributionsConfiguration($stateProvider);
        config.$inject = ['$stateProvider'];
        return config;
    }

    /**
     * Create the configuration class
     *
     * @param stateProvider
     */
    constructor(private stateProvider: ng.ui.IStateProvider) {
        this.buildStates();
    }

    /**
     * Initialize the forms module
     */
    public buildStates(): void {
        this.stateProvider
            .state('home.surveys.edit.distributions', {
                url: '/distributions',
                abstract: true,
                template: require('/app/modules/surveys/distributions/Distributions.html'),
                controller: 'its.survey.module.surveys.distributions.DistributionsControllerNew as vm',
            })
            .state('home.surveys.edit.distributions.email', {
                url: '/email?page&sort_by&order&qsd_page&qsd_sort_by&qsd_order&hsd_page&hsd_sort_by&hsd_order',
                data: { activeTab: 'email' },
                template: require('/app/modules/surveys/distributions/email/Email.html'),
                controller: 'its.survey.module.surveys.distributions.DistributionEmailController as vm',
                params: {
                    page: {
                        value: '1',
                        squash: true,
                    },
                    sort_by: {
                        value: 'created_at',
                        squash: true,
                    },
                    order: {
                        value: 'desc',
                        squash: true,
                    },
                    qsd_page: {
                        value: '1',
                        squash: true,
                    },
                    qsd_sort_by: {
                        value: 'scheduled_at',
                        squash: true,
                    },
                    qsd_order: {
                        value: 'desc',
                        squash: true,
                    },
                    hsd_page: {
                        value: '1',
                        squash: true,
                    },
                    hsd_sort_by: {
                        value: 'scheduled_at',
                        squash: true,
                    },
                    hsd_order: {
                        value: 'desc',
                        squash: true,
                    },
                },
            })
            .state('home.surveys.edit.distributions.new-email', {
                url: '/new-email',
                data: { activeTab: 'email' },
                template: require('/app/modules/surveys/distributions/email/CreateEmail.html'),
                controller: 'its.survey.module.surveys.distributions.DistributionCreateEmailController as vm',
                resolve: {
                    hasAccess: StateRerouter.onNoProductFeature(
                        'survey',
                        'email-distributions',
                        {
                            state: 'home.surveys.edit.distributions.email',
                        },
                        undefined,
                        ['authenticatedUser']
                    ),
                },
            })
            .state('home.surveys.edit.distributions.edit-email', {
                url: '/email/:emailUuid',
                data: { activeTab: 'email' },
                template: require('/app/modules/surveys/distributions/email/CreateEmail.html'),
                controller: 'its.survey.module.surveys.distributions.DistributionCreateEmailController as vm',
                resolve: {
                    hasAccess: StateRerouter.onNoProductFeature(
                        'survey',
                        'email-distributions',
                        {
                            state: 'home.surveys.edit.distributions.email',
                        },
                        undefined,
                        ['authenticatedUser']
                    ),
                },
            })
            .state('home.surveys.edit.distributions.edit-email-reminder', {
                url: '/email-reminder/:emailUuid',
                data: { activeTab: 'email' },
                template: require('/app/modules/surveys/distributions/email/CreateEmailReminder.html'),
                controller: 'its.survey.module.surveys.distributions.CreateEmailReminderController as vm',
                resolve: {
                    hasAccess: StateRerouter.onNoProductFeature(
                        'survey',
                        'email-distributions',
                        {
                            state: 'home.surveys.edit.distributions.email',
                        },
                        undefined,
                        ['authenticatedUser']
                    ),
                },
            })
            .state('home.surveys.edit.distributions.edit-sms', {
                url: '/sms/:smsUuid',
                data: { activeTab: 'sms' },
                template: require('/app/modules/surveys/distributions/sms/CreateSms.html'),
                controller: 'its.survey.module.surveys.distributions.DistributionCreateSmsController as vm',
            })
            .state('home.surveys.edit.distributions.health', {
                url: '/health',
                data: { activeTab: 'health' },
                template: require('/app/modules/surveys/distributions/health/Health.html'),
                controller: 'its.survey.module.surveys.distributions.HealthController as vm',
            })
            .state('home.surveys.edit.distributions.link', {
                url: '/link',
                data: { activeTab: 'link' },
                template: require('/app/modules/surveys/distributions/link/Link.html'),
                controller: 'its.survey.module.surveys.distributions.DistributionLinkController as vm',
            })
            .state('home.surveys.edit.distributions.website', {
                url: '/website',
                data: { activeTab: 'website' },
                template: require('/app/modules/surveys/distributions/website/Website.html'),
                controller: 'its.survey.module.surveys.distributions.DistributionWebsiteController as vm',
            })
            .state('home.surveys.edit.distributions.qr-code', {
                url: '/qr-code',
                data: { activeTab: 'qr-code' },
                template: require('/app/modules/surveys/distributions/qr-code/QrCode.html'),
                controller: 'its.survey.module.surveys.distributions.DistributionQrCodeController as vm',
            })
            .state('home.surveys.edit.distributions.social-media', {
                url: '/social-media',
                data: { activeTab: 'social-media' },
                template: require('/app/modules/surveys/distributions/social-media/SocialMedia.html'),
                controller: 'its.survey.module.surveys.distributions.DistributionSocialMediaController as vm',
            })
            .state('home.surveys.edit.distributions.sms', {
                url: '/sms?page&sort_by&order&qsd_page&qsd_sort_by&qsd_order&hsd_page&hsd_sort_by&hsd_order',
                data: { activeTab: 'sms' },
                template: require('/app/modules/surveys/distributions/sms/Sms.html'),
                controller: 'its.survey.module.surveys.distributions.DistributionSmsController as vm',
                params: {
                    page: {
                        value: '1',
                        squash: true,
                    },
                    sort_by: {
                        value: 'created_at',
                        squash: true,
                    },
                    order: {
                        value: 'desc',
                        squash: true,
                    },
                    qsd_page: {
                        value: '1',
                        squash: true,
                    },
                    qsd_sort_by: {
                        value: 'scheduled_at',
                        squash: true,
                    },
                    qsd_order: {
                        value: 'desc',
                        squash: true,
                    },
                    hsd_page: {
                        value: '1',
                        squash: true,
                    },
                    hsd_sort_by: {
                        value: 'scheduled_at',
                        squash: true,
                    },
                    hsd_order: {
                        value: 'desc',
                        squash: true,
                    },
                },
            })
            .state('home.surveys.edit.distributions.new-sms', {
                url: '/new-sms',
                data: { activeTab: 'sms' },
                template: require('/app/modules/surveys/distributions/sms/CreateSms.html'),
                controller: 'its.survey.module.surveys.distributions.DistributionCreateSmsController as vm',
            });
    }
}
