import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ILogicReference } from '../../../domain/surveys/logic/LogicReference';
import { ILogic } from '../../../domain/surveys/logic/Logic';

/**
 *  The UI for advanced options
 */
@Component('its.survey.module.surveys', LogicEditComponent.CID, {
    template: require('/app/modules/surveys/components/LogicEditComponent.html'),
    controllerAs: 'vm',
    bindings: {
        logicReferences: '<',
        onClick: '&?',
    },
})
class LogicEditComponent {
    static IID: string = 'LogicEditComponent';
    static CID: string = 'itsLogicEdit';
    static $inject: Array<string> = [];

    public logicReferences: Array<ILogicReference>;
    public onClick: (data: any) => void;

    public handleLogicClick(logic: ILogic, ownerUuid: string): void {
        if (this.onClick != null) {
            this.onClick({
                logic: logic,
                ownerUuid: ownerUuid,
            });
        }
    }
}
