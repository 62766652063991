import { ISurvey } from '../../domain/surveys/survey/Survey';
import { ISurveyService } from '../../services/SurveyService';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IPageService } from '../../services/PageService';
import { ISurveyApi } from '../../api/SurveyApi';
import { ISection, Section } from '../../domain/surveys/Section';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';
import * as angular from 'angular';

export class BaseSurvey {
    public activeState: string;
    public searchText: string;
    // inherited by children scope
    public survey: ISurvey;
    public publishing: boolean = false;
    public loading: boolean = false;
    public saving: boolean = false;
    public surveyLoaded: boolean = false;

    constructor(
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected scope: ng.IScope,
        protected userLocalTimeService: IUserLocalTimeService
    ) {
        this.activeState = this.buildActiveStateFromRoute();

        this.scope.$watch(
            () => {
                return angular.equals(this.survey, this.surveyService.getSurvey());
            },
            () => {
                this.survey = this.survey = this.surveyService.getSurvey();
            }
        );
    }

    /**
     * Load the given survey by its uuid
     *
     */
    public load(): ng.IPromise<void> {
        this.loading = true;
        this.pageService.startLoading();
        return this.surveyService
            .loadSurvey()
            .then((survey: ISurvey) => {
                this.survey = survey;

                if (this.survey.getSections().length === 0) {
                    const newSection: ISection = Section.make('Section 1');
                    this.survey.addSection(newSection);
                    this.surveyService.setSurvey(this.survey);
                }

                this.surveyService.setSurvey(this.survey);
                this.pageService.setPageTitle(survey.name);
            })
            .catch(() => {
                this.pageService.failLoading();
                this.pageService.setPageTitle('Survey');
            })
            .finally(() => {
                this.pageService.stopLoading();
                this.loading = false;
                this.surveyLoaded = true;
            });
    }

    /**
     * Handles nested routes of distribution to always highlight the
     * correct tab even on page refresh within a child route
     *
     * @returns {string}
     */
    public buildActiveStateFromRoute(): string {
        const state: string = this.stateService.current.name;
        if (state.indexOf('home.surveys.edit.distributions') > -1) {
            return 'home.surveys.edit.distributions';
        } else if (state.indexOf('home.surveys.edit.scoring') > -1) {
            return 'home.surveys.edit.scoring';
        } else {
            return state;
        }
    }

    public navigateTo(state: string, childState?: string): void {
        this.survey = this.surveyService.getSurvey();
        this.activeState = state;
        if (childState) {
            this.stateService.go(childState);
        } else {
            this.stateService.go(state, { childState: null });
        }
    }

    /**
     * Returns full date and time in user's local time
     *
     * @param {string} date
     * @param {string} format
     * @return {string}
     */
    public getDateTime(date: string, format?: string): string {
        return this.userLocalTimeService.fromUtc(date, format);
    }

    /**
     * Publish survey
     */
    protected publish(updatedSurvey: ISurvey, updatedChecklistRoute?: string): void {
        this.publishing = true;

        this.surveyService
            .saveAndPublish(updatedSurvey)
            .then((survey: ISurvey) => {
                this.updateSurvey(survey, updatedChecklistRoute);
                this.toaster.success('SURVEYS.SURVEY_PUBLISHED');
            })
            .catch(() => {
                // todo: handle any specific type of errors and log second to Sentry if not recognized
                this.toaster.error('ERRORS.FAILED_SURVEY_PUBLISH');
            })
            .finally(() => {
                this.publishing = false;
            });
    }

    /**
     * Handle the case in which we have a new draft of an original live version. If its a new version, we need to
     * transition as the old id will still be exposed in the URL
     *
     * @param newSurvey
     * @param route
     */
    protected checkForNewDraft(newSurvey: ISurvey, route: string): void {
        if (newSurvey.updatedAt !== this.survey.updatedAt) {
            this.stateService.go(
                route,
                {
                    uuid: newSurvey.uuid,
                },
                {
                    location: 'replace',
                    notify: false,
                }
            );
        }
    }

    /**
     * Save the survey by its uuid
     */
    protected save(updatedSurvey: ISurvey, updatedChecklistRoute?: string): ng.IPromise<void> {
        this.saving = true;

        return this.surveyService
            .save(updatedSurvey)
            .then((survey: ISurvey) => {
                this.updateSurvey(survey, updatedChecklistRoute);
                this.toaster.success('SURVEYS.SURVEY_SAVED');
            })
            .catch(() => {
                this.toaster.error('ERRORS.FAILED_SURVEY_UPDATE');
            })
            .finally(() => {
                this.saving = false;
            });
    }

    protected updateSurvey(survey: ISurvey, route: string = this.stateService.current.name): void {
        this.checkForNewDraft(survey, route);
        this.survey = survey;
        this.surveyService.setSurvey(survey);
    }
}
