import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', MatrixRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/response-items/matrix/MatrixRenderComponent.html'),
    controllerAs: 'vm',
    bindings: MatrixRenderComponent.getBindings(),
})
class MatrixRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'MatrixRenderComponent';
    static CID: string = 'itsMatrixRender';
    static $inject: string[] = [];
}
