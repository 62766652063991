import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { String } from '@intouch/its.essential/app/essential/utils/String';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { ITranslation, Translation } from './Translation';
import { ItemFactory } from './items/ItemFactory';
import { BaseItem, IBaseItem, ItemType } from './items/BaseItem';
import { Group, IGroup } from './items/Group';
import { TranslationLocator } from './TranslationLocator';
import * as _ from 'lodash';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import { Matrix } from './items/Matrix';
import { Radio } from './items/Radio';
import { Checkbox } from './items/Checkbox';
import { ILogic } from './logic/Logic';
import { BaseResponseItem } from './items/BaseResponseItem';
import { Select } from './items/Select';
import { IWeightedTag } from './items/shared/WeightedTag';
import { ILogicReference } from './logic/LogicReference';
import { BaseItemJS, IBaseItemJS } from './items/BaseItemJS';
import { ITranslatedEntity } from '../ITranslatedEntity';
import { IContainItems } from './items/ContainItems';

/**
 * The section settings interface
 */
export interface ISectionSettings extends IEntity {
    locked: boolean;
    disableBackButton: boolean;
    hidePageHeader: boolean;
    hidePageFooter: boolean;
    questionRandomization: boolean;
    customJavascript: IBaseItemJS;
    showSectionName: boolean;

    resetCustomJavascript(): void;
    hasCustomJavascript(): boolean;
}

/**
 * The section settings object
 */
export class SectionSettings extends Entity implements ISectionSettings {
    locked: boolean = false;
    disableBackButton: boolean = false;
    hidePageHeader: boolean = false;
    hidePageFooter: boolean = false;
    questionRandomization: boolean = false;
    customJavascript: IBaseItemJS = new BaseItemJS();
    showSectionName: boolean = false;

    public fromJson(jsonObject: any, convert: boolean): ISectionSettings {
        super.fromJson(jsonObject, convert);

        if (jsonObject.custom_javascript) {
            this.customJavascript = EntityBuilder.buildOne<IBaseItemJS>(BaseItemJS, jsonObject.custom_javascript, true);
        }

        return this;
    }

    public resetCustomJavascript(): void {
        this.customJavascript = new BaseItemJS();
    }

    public hasCustomJavascript(): boolean {
        return (
            !!this.customJavascript.onLoad ||
            !!this.customJavascript.onChange ||
            !!this.customJavascript.onHide ||
            !!this.customJavascript.onShow ||
            !!this.customJavascript.onNext
        );
    }
}

/**
 * The section scoring interface
 */
export interface ISectionScoring extends IEntity {
    points: number;
    weight: number;
}

/**
 * The section scoring class
 */
export class SectionScoring extends Entity implements ISectionScoring {
    points: number = null;
    weight: number = null;
}

/**
 * A form user object interface
 */
export interface ISection extends ITranslatedEntity {
    uuid: string;
    label: string;
    disabled: boolean;
    settings: ISectionSettings;
    scoring: ISectionScoring;
    translations: Array<ITranslation>;
    type: ItemType;
    expanded: boolean;
    points: number;
    logicReferences: Array<ILogicReference>;

    addItem(item: IBaseItem, index?: number): ISection;
    getItems(excludeTypes?: Array<string>, includeResponses?: boolean, includeGroupedItems?: boolean): Array<IBaseItem>;
    findItems(comparator: (i: IBaseItem) => boolean): Array<IBaseItem>;
    getGroupItems(): Array<IBaseItem>;
    getLogicItems(): Array<IBaseItem>;
    findLogicByUuid(uuid: string): ILogic;
    getItemByUuid(uuid: string): IBaseItem;
    removeItem(item: IBaseItem): void;
    hasAdvancedSettingsChecked(): boolean;
    getItemCount(): number;
    moveItem(fromIndex: number, toIndex: number): void;
    buildLabelMap(): any;
    toggleCollapse(): void;
    getSectionItems(comparator: (item: IBaseItem) => boolean): Array<IBaseItem>;
    hasQuestions(): boolean;
    hasScoringQuestion(): boolean;
    hasValidTagQuestion(): boolean;
    updateTagWeight(tagUuid: string, weight: number): void;
    buildTagMap(tagObj: { [uuid: string]: IWeightedTag }): void;
    findTagByUuid(tagUuid: string): IWeightedTag;
    getAllLogicItemBelongsTo(itemUuid: string): Array<ILogicReference>;
    getHealthPoints(): number;
}

/**
 * A form user object
 */
export class Section extends Entity implements ISection, IContainItems {
    uuid: string = null;
    label: string = null;
    disabled: boolean = false;
    type: ItemType = 'section';
    settings: ISectionSettings = new SectionSettings();
    scoring: ISectionScoring = new SectionScoring();
    translations: Array<ITranslation> = [];
    items: Array<BaseItem | BaseResponseItem> = [];
    @NotEnumerable
    expanded: boolean = true;
    @NotEnumerable
    editingLabel: boolean = false;
    @NotEnumerable
    editIcon: boolean = false;
    @NotEnumerable
    editedLabel: string = null;
    @NotEnumerable
    originalLabel: string = null;
    @NotEnumerable
    logicReferences: Array<ILogicReference> = [];

    get points(): number {
        this.scoring.points = 0;
        for (const item of this.items) {
            if (item.isValidScoringItemType() && NumberUtils.isNumber((<any>item).points)) {
                this.scoring.points += (<any>item).points;
            }
        }

        return this.scoring.points;
    }

    set points(value: number) {
        this.scoring.points = value;
    }

    /**
     * Allow a user to quickly create a section
     *
     * @param name
     * @returns {ISection}
     */
    public static make(name: string = ''): Section {
        const section: Section = new Section();
        section.label = name;
        section.uuid = String.uuid();

        return section;
    }

    /**
     * Build the section object from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {ISection}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<ISectionSettings>(SectionSettings, jsonObject.settings, true);
        }

        if (jsonObject.scoring) {
            this.scoring = EntityBuilder.buildOne<ISectionScoring>(SectionScoring, jsonObject.scoring, true);
        }

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ITranslation>(Translation, jsonObject.translations, true);
        }

        if (jsonObject.items && jsonObject.items.length > 0) {
            for (const item of jsonObject.items) {
                this.items.push(<BaseItem>ItemFactory.create(item.type, item));
            }
        }

        this.type = 'section';

        return this;
    }

    /**
     * Can be used to add a new item to this section
     *
     * @param {IBaseItem} item
     * @param {index} index
     * @returns {IGroup}
     */
    public addItem(item: BaseItem, index?: number): ISection {
        if (index >= 0 && index <= this.items.length) {
            this.items.splice(index, 0, item);
        } else {
            this.items.push(item);
        }

        return this;
    }

    /**
     * Gets an item by uuid
     *
     * @param {string} uuid
     * @returns {IBaseItem}
     */
    public getItemByUuid(uuid: string): IBaseItem {
        let found: any = null;
        for (const item of this.items) {
            if (item.uuid === uuid) {
                return item;
            }

            if (item instanceof Group) {
                found = (<IGroup>item).getItemByUuid(uuid);
                if (found) {
                    return found;
                }
            }

            if (item instanceof BaseResponseItem) {
                if (item instanceof Matrix) {
                    found = item.getQuestionByUuid(uuid);
                    if (found) {
                        return found;
                    }
                } else {
                    found = item.getResponseByUuid(uuid);
                }
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    /**
     * Creates a string map of uuid and label
     *
     * @returns {any}
     */
    public buildLabelMap(): any {
        const mapObj: any = {};

        mapObj[this.uuid] = this.label;

        return mapObj;
    }

    /**
     * Get items array
     *
     * @returns {Array<any>}
     */
    public getItems(
        excludeTypes: Array<string> = [],
        includeResponses: boolean = false,
        includeGroupedItems: boolean = false
    ): Array<any> {
        let filteredArr: Array<any> = [];
        for (const item of this.items) {
            if (excludeTypes.length === 0 || excludeTypes.indexOf(item.type) === -1) {
                filteredArr.push(item);

                if (includeResponses) {
                    if (item.type === 'radio' || item.type === 'checkbox' || item.type === 'select') {
                        filteredArr = _.concat(filteredArr, (<Checkbox | Radio | Select>item).responses);
                    } else if (item.type === 'matrix') {
                        filteredArr = _.concat(filteredArr, (<Matrix>item).questions);
                    }
                    if (item.type === 'group') {
                        filteredArr = _.concat(
                            filteredArr,
                            _.filter((<Group>item).getItems(), (groupItem) => {
                                return excludeTypes.indexOf(groupItem.type) === -1;
                            })
                        );
                    }
                }
            } else if (item.type === 'group' && includeGroupedItems && !includeResponses) {
                filteredArr = _.concat(
                    filteredArr,
                    _.filter((<Group>item).getItems(), (groupItem) => {
                        return excludeTypes.indexOf(groupItem.type) === -1;
                    })
                );
            }
        }

        return filteredArr;
    }

    /**
     * Find all items matching a comparator
     *
     * @param comparator
     */
    public findItems(comparator: (i: IBaseItem) => boolean): Array<IBaseItem> {
        const items: Array<IBaseItem> = [];
        if (this.items) {
            for (const item of this.items) {
                if (comparator(item)) {
                    items.push(item);
                }
            }
        }

        return items;
    }

    public getAllLogicItemBelongsTo(itemUuid: string): Array<ILogicReference> {
        let allLogic: Array<ILogicReference> = [];
        for (const item of this.items) {
            allLogic = _.concat(allLogic, item.getAllLogicItemBelongsTo(itemUuid));
        }

        return allLogic;
    }

    /**
     * Find logic by uuid
     *
     * @param uuid
     */
    public findLogicByUuid(uuid: string): ILogic {
        for (const item of this.items) {
            if (item.logic && item.logic.uuid === uuid) {
                return item.logic;
            }

            if (item instanceof Group) {
                const groupItemLogic: ILogic = item.findLogicByUuid(uuid);
                if (groupItemLogic) {
                    return groupItemLogic;
                }
            }

            if (item instanceof BaseResponseItem) {
                if (item instanceof Matrix) {
                    for (const question of item.questions) {
                        if (question.logic && question.logic.uuid === uuid) {
                            return question.logic;
                        }
                    }
                } else {
                    for (const response of item.responses) {
                        if (response.logic && response.logic.uuid === uuid) {
                            return response.logic;
                        }
                    }
                }
            }
        }

        return null;
    }

    public findItemsByType(type: string): Array<BaseItem> {
        let itemsFound: Array<BaseItem> = [];
        for (const item of this.items) {
            if (item.type === type) {
                itemsFound.push(item);
            }

            if (item instanceof Group) {
                itemsFound = itemsFound.concat(item.findItemsByType(type));
            }
        }

        return itemsFound;
    }

    public hasItems(): boolean {
        return this.items && this.items.length > 0;
    }

    public hasItemWithLogicReference(): boolean {
        for (const item of this.items) {
            if (item.logicReferences.length > 0) {
                return true;
            }

            if (item instanceof Group) {
                for (const groupItem of item.items) {
                    if (groupItem.logicReferences.length > 0) {
                        return true;
                    }

                    if (groupItem instanceof BaseResponseItem) {
                        for (const groupResponse of groupItem.responses) {
                            if (groupResponse.logicReferences.length > 0) {
                                return true;
                            }
                        }
                    }
                }
            }

            if (item instanceof Matrix) {
                for (const question of item.questions) {
                    if (question.logicReferences.length > 0) {
                        return true;
                    }
                }
            }

            if (item instanceof BaseResponseItem) {
                for (const response of item.responses) {
                    if (response.logicReferences.length > 0) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * Gets items in a section's groups
     *
     * @returns {Array<IBaseItem>}
     */
    public getGroupItems(): Array<IBaseItem> {
        let items: Array<IBaseItem> = [];
        for (const item of this.items) {
            if (item.type === 'group') {
                items = _.concat(items, (<Group>item).getItems());
            }
        }

        return items;
    }

    /**
     * Get items that have logic
     *
     * @returns {Array<any>}
     */
    public getLogicItems(): Array<any> {
        let logicItems: Array<ILogic> = [];
        for (const item of this.items) {
            logicItems = _.concat(logicItems, item.getLogicItems());
        }

        return logicItems;
    }

    /**
     * Allows a user to remove the given item from the section
     *
     * @param {IBaseItem} item
     */
    public removeItem(item: IBaseItem): void {
        const index: number = _.findIndex(this.items, { uuid: item.uuid });

        if (index > -1) {
            this.items.splice(index, 1);
        }
    }

    /**
     * Get total number of items including group children items
     *
     * @returns {number}
     */
    public getItemCount(): number {
        let totalCount: number = 0;

        for (const item of this.items) {
            if ((<Group>item).items && (<Group>item).items.length) {
                totalCount += (<Group>item).getItemCount();
            } else if (item.type !== 'group') {
                totalCount++;
            }
        }

        return totalCount;
    }

    /**
     * Returns if the section has any advanced settings checked
     *
     * @returns {boolean}
     */
    public hasAdvancedSettingsChecked(): boolean {
        return this.settings.disableBackButton || this.settings.hidePageFooter || this.settings.hidePageHeader;
    }

    /**
     * Move item from one position in array to a destination index
     *
     * @param {number} fromIndex
     * @param {number} toIndex
     */
    public moveItem(fromIndex: number, toIndex: number): void {
        if (toIndex < 0) {
            toIndex = 0;
        }

        if (toIndex > this.items.length) {
            toIndex = this.items.length;
        }
        this.items.splice(toIndex, 0, this.items.splice(fromIndex, 1)[0]);
    }

    /**
     * Retrieve the given translation for a locale
     *
     * @param {string} locale
     * @param {boolean} createOnNotFound
     * @returns {ITranslation}
     */
    public getTranslation(locale: string, createOnNotFound: boolean = true): ITranslation {
        return TranslationLocator.locate(this.translations, 'label', locale, createOnNotFound);
    }

    /**
     * Toggle section collapse
     */
    public toggleCollapse(): void {
        this.expanded = !this.expanded;
    }

    /**
     * Get the current index of the item
     *
     * @param {IBaseItem} item
     * @returns {number}
     */
    public getItemIndex(item: BaseItem): number {
        return this.items.indexOf(item);
    }

    public getValidScoringItems(): Array<IBaseItem> {
        const validItems: Array<IBaseItem> = [];
        for (const item of this.items) {
            if (item.isValidScoringItemType()) {
                validItems.push(item);
            }
        }

        return validItems;
    }

    /**
     * Get items based comparator.
     */
    public getSectionItems(comparator: (item: IBaseItem) => boolean): Array<IBaseItem> {
        const items: Array<IBaseItem> = [];

        for (const item of this.items) {
            if (comparator(item)) {
                items.push(item);
                continue;
            }

            if (item.type === 'group') {
                for (const groupItem of (<Group>item).items) {
                    if (comparator(groupItem)) {
                        items.push(groupItem);
                    }
                }
            }
        }

        return items;
    }

    /**
     * Returns if this section contains at least 1 item that can be scored
     */
    public hasScoringQuestion(): boolean {
        for (const item of this.items) {
            if (item.isValidScoringItemType()) {
                return true;
            }
        }
    }

    /**
     * Returns if this section contains at least 1 item that can be tagged
     */
    public hasValidTagQuestion(): boolean {
        for (const item of this.items) {
            if (item.isTaggable()) {
                return true;
            }
        }

        return false;
    }

    /**
     * Return if this section contains any questions
     */
    public hasQuestions(): boolean {
        return this.items.length > 0;
    }

    /**
     * Loops down to every item to update an access tag's weight
     *
     * @param tagUuid
     * @param weight
     */
    public updateTagWeight(tagUuid: string, weight: number): void {
        for (const item of this.items) {
            item.updateTagWeight(tagUuid, weight);
        }
    }

    /**
     * Build a tag map of uuid:tag and drill down items to and add their tags if that uuid is not registered
     *
     * @param tagObj
     */
    public buildTagMap(tagObj: { [uuid: string]: IWeightedTag }): void {
        for (const item of this.items) {
            item.buildTagMap(tagObj);
        }
    }

    /**
     * Finds a tag by uuid within its items
     *
     * @param tagUuid
     */
    public findTagByUuid(tagUuid: string): IWeightedTag {
        for (const item of this.items) {
            const foundTag: IWeightedTag = item.findTagByUuid(tagUuid);
            if (foundTag) {
                return foundTag;
            }
        }
    }

    /**
     * Returns total health points for this section, health points
     * are used to calculate the estimated completion time.
     */
    public getHealthPoints(): number {
        let healthPoints: number = 0;

        for (const item of this.items) {
            healthPoints += item.healthPoints;
        }

        return healthPoints;
    }

    /**
     * Returns the translation keys that apply to the section
     */
    public getTranslationKeys(): Array<string> {
        return ['label'];
    }

    public canHoldItemOfType(itemType: string): boolean {
        return true;
    }
}
