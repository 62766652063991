import * as angular from 'angular';
import 'highcharts';

import 'angular-froala';
import 'angular-moment';
import 'angular-material';
import 'angular-messages';
import 'angular-translate';
import 'angular-ui-router';
import 'angular-ui-mask';
import 'angular-sanitize';
import 'angular-drag-and-drop-lists';
import 'md-color-picker';

import '@intouch/its.essential/app/essential/http/_module';
import '@intouch/its.essential/app/essential/panels/_module';
import '@intouch/its.essential/app/essential/services/_module';
import '@intouch/its.essential/app/essential/components/_module';
import '@intouch/its.essential/app/essential/filters/_module';
import '@intouch/its.essential/app/essential/directives/_module';
import '@intouch/its.essential/app/essential/api/_module';

import './services/_module';
import './directives/_module';
import './modules/main/_module';
import './modules/surveys/_module';
import './modules/surveys/distributions/_module';
import './modules/contact-center/_module';
import './modules/settings/_module';
import './api/_module';

import './components/_module';
import 'angular-jwt';
import 'angular-socialshare';

import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ILogger, Logger } from '@intouch/its.essential/app/essential/services/Logger';
import { IStorage } from '@intouch/its.essential/app/essential/services/LocalStorage';
import { ISessionService } from '@intouch/its.essential/app/essential/services/SessionService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IAccessApi } from '@intouch/its.essential/app/essential/api/AccessApi';
import { SentryHandlerV2 } from '@intouch/its.essential/app/essential/services/logger/SentryHandlerV2';
import { IFeatureFlagService } from '@intouch/its.essential/app/essential/services/FeatureFlagService';
import { IGoogleTagManager } from '@intouch/its.essential/app/essential/services/GoogleTagManager';
import { IProgramsApi } from '@intouch/its.essential/app/essential/api/ProgramsApi';

import { ISurveySession } from './services/SurveySession';
import { ISurveyApi } from './api/SurveyApi';
import config from './config';
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import { ITemplateLibraryApi } from '@intouch/its.essential/app/essential/api/TemplateLibraryApi';
import { ILimitServiceApi } from '@intouch/its.essential/app/essential/api/LimitServiceApi';

// logging
import * as Sentry from '@sentry/browser';
import { DataDogLogUtils } from '@intouch/its.essential/app/essential/utils/DataDogLogUtils';
import { DataDogRumUtils } from '@intouch/its.essential/app/essential/utils/DataDogRumUtils';
import { DataDogHandler } from '@intouch/its.essential/app/essential/services/logger/DataDogHandler';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import 'moment/locale/fr-ca';
import 'angular-cookies';

const intouchSurveyModules: Array<string> = [
    'its.survey.module.main',
    'its.survey.module.surveys',
    'its.survey.module.surveys.distributions',
    'its.survey.module.contactCenter',
    'its.survey.module.settings',
    'its.survey.services',
    'its.survey.directives',
    'its.survey.api',
    'its.survey.components',
];

// todo: these might be moved to app.js in the essential repo as well as the module binding
const intouchEssentialModules: Array<string> = [
    'its.essential.services',
    'its.essential.http',
    'its.essential.components',
    'its.essential.directives',
    'its.essential.api',
    'its.essential.filters',
    'its.essential.panels',
];

const modulesToLoad: Array<string> = intouchEssentialModules.concat(intouchSurveyModules),
    thirdPartyModules: Array<string> = [
        'ngMaterial',
        'ngMessages',
        'ngCookies',
        'ngSanitize',
        'ngCookies',
        'ui.router',
        'pascalprecht.translate',
        'angularMoment',
        'dndLists',
        'translations',
        'froala',
        'angular-jwt',
        '720kb.socialshare',
        'ui.mask',
        'mdColorPicker',
    ];

let sentryInitialized: boolean = false;
if (config && config.sentry && config.sentry.dsn && config.sentry.release) {
    console.debug('Configuring Sentry');
    Sentry.init({
        dsn: config.sentry.dsn,
        release: config.sentry.release,
        integrations: [],
        environment: config.sentry.environment,
        attachStacktrace: true,
    });

    sentryInitialized = true;
}

const survey: any = angular.module('IntouchSurvey', thirdPartyModules.concat(modulesToLoad));

/**
 * Configure the application
 */
survey.config([
    '$translateProvider',
    '$mdInkRippleProvider',
    '$provide',
    (translateProvider, inkRippleProvider, provide) => {
        inkRippleProvider.disableInkRipple();
        translateProvider.useSanitizeValueStrategy('escapeParameters');
        translateProvider.registerAvailableLanguageKeys(['en', 'fr_CA', 'es_US'], {
            'en_*': 'en',
            fr: 'fr_CA',
            'fr_*': 'fr_CA',
            'es_*': 'es_US',
            es: 'es_US',
        });
        translateProvider.fallbackLanguage('en');
    },
]);

/**
 * Critical service bootstrap (we do this first so that any services built during bootstrap will have these configured when injected). Any service
 * which has constructor or configuration that is to be used when creating other services will need to be here -- this should be a very rare need case.
 */
survey.run([
    'iteLogger',
    'APPCONFIG',
    '$templateCache',
    function (logger: ILogger, appConfig: any, $templateCache: ng.ITemplateCacheService): any {
        logger.configure(appConfig.logger);

        // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/typedef
        const bulUploaderStep2 = require('@intouch/its.essential/app/essential/components/bulkuploader/BulkUploaderStep2.html');
        // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/typedef
        const contactUploadStep1Html = require('./modules/contact-center/components/ContactUploaderStep1.html');
        // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/typedef
        const contactUploadStep3Html = require('./modules/contact-center/components/ContactUploaderStep3.html');
        // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/typedef
        const addContactsToListUploadStep1Html = require('./modules/contact-center/contact-lists/add-contacts/components/AddContactsToListUploaderStep1.html');
        // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/typedef
        const addContactsToListUploadStep3Html = require('./modules/contact-center/contact-lists/add-contacts/components/AddContactsToListUploaderStep3.html');

        $templateCache.put('app/modules/contact-center/components/ContactUploaderStep1.html', contactUploadStep1Html);
        $templateCache.put('app/essential/components/bulkuploader/BulkUploaderStep2.html', bulUploaderStep2);
        $templateCache.put('app/modules/contact-center/components/ContactUploaderStep3.html', contactUploadStep3Html);
        $templateCache.put(
            'app/modules/contact-center/contact-lists/add-contacts/components/AddContactsToListUploaderStep1.html',
            addContactsToListUploadStep1Html
        );
        $templateCache.put(
            'app/modules/contact-center/contact-lists/add-contacts/components/AddContactsToListUploaderStep3.html',
            addContactsToListUploadStep3Html
        );
    },
]);

/**
 * Our application boostrap (configure any services we need to)
 */
survey.run([
    '$rootScope',
    'iteLogger',
    'iteLocalStorage',
    'iteToaster',
    'iteFeatureFlagService',
    'itsSurveyApi',
    'amMoment',
    'iteSessionService',
    'itsSurveySession',
    'APPCONFIG',
    '$translate',
    'iteAccessService',
    'iteAccessApi',
    'iteIqlApi',
    'iteTemplateLibraryApi',
    '$mdDialog',
    'iteGoogleTagManager',
    'iteLimitServiceApi',
    'iteProgramsApi',
    function (
        $rootScope: ng.IRootScopeService,
        logger: ILogger,
        storage: IStorage,
        toaster: IToaster,
        featureFlagService: IFeatureFlagService,
        surveyApi: ISurveyApi,
        amMoment: any,
        session: ISessionService,
        surveySession: ISurveySession,
        appConfig: any,
        translate: angular.translate.ITranslateService,
        accessService: IAccessService,
        accessApi: IAccessApi,
        iqlApi: IIqlApi,
        templateLibraryApi: ITemplateLibraryApi,
        mdDialog: ng.material.IDialogService,
        googleTagManager: IGoogleTagManager,
        limitServiceApi: ILimitServiceApi,
        programsApi: IProgramsApi
    ): any {
        DataDogLogUtils.init(datadogLogs, appConfig.datadog);
        DataDogRumUtils.init(datadogRum, appConfig.datadog);
        if (appConfig.datadog && appConfig.datadog.clientToken) {
            logger.pushHandler(new DataDogHandler(appConfig, Logger.ERROR));
            logger.debug('Loading datadog');
        } else {
            logger.debug('Skipping datadog load');
        }

        logger.debug('Starting the IntouchSurvey admin application');
        translate.use('en');

        if (sentryInitialized) {
            logger.debug('Adding Sentry Handler');
            logger.pushHandler(new SentryHandlerV2(appConfig, Logger.ERROR));
            logger.debug('Sentry Handler V2 added');
        }

        featureFlagService.initalize();

        // configure the services
        toaster.configure(appConfig.toaster);
        accessService.configure(appConfig.access);
        surveyApi.configure(appConfig.api);
        accessApi.configure(appConfig.access);
        amMoment.changeLocale(appConfig.amMoment.locale);
        storage.configure(appConfig.storage);
        session.configure(appConfig.session);
        surveySession.configure(appConfig.surveySession);
        iqlApi.configure(appConfig.products.apis);
        limitServiceApi.configure(appConfig.limitService);
        templateLibraryApi.configure(appConfig.products.apis);
        programsApi.configure(appConfig.products.apis);
        googleTagManager.configure(appConfig.gtm);

        $rootScope.$on('$stateChangeStart', () => {
            mdDialog.cancel();
        });

        logger.debug('App bootstrap complete');
    },
]);
