import { IBaseResponse } from './BaseResponse';
import { ScaleResponse } from './Scale';

export interface IDefinedResponse {
    label: string;
    value: string;
}

export class ResponseFactory {
    public static getAvailableDefinedResponses(
        itemType: string,
        translate: ng.translate.ITranslateService
    ): Array<IDefinedResponse> {
        if (!translate) {
            throw new Error('Translate ($translate) service not found.');
        }
        switch (itemType) {
            case 'matrix':
            case 'checkbox':
            case 'radio':
            case 'select':
                return [
                    {
                        label: translate.instant('SURVEYS.RESPONSE_TEMPLATES.DISAGREE_AGREE'),
                        value: 'disagree_agree',
                    },
                    {
                        label: translate.instant('SURVEYS.RESPONSE_TEMPLATES.DISSATISFIED_SATISFIED'),
                        value: 'dissatisfied_satisfied',
                    },
                    {
                        label: translate.instant('SURVEYS.RESPONSE_TEMPLATES.NEVER_ALWAYS'),
                        value: 'never_always',
                    },
                ];
            default:
                return [];
        }
    }

    public static buildDefinedResponses(
        template: string,
        baseClass: any,
        translate: ng.translate.ITranslateService
    ): Array<IBaseResponse> {
        const definedResponses: Array<IBaseResponse> = [];
        if (!translate) {
            throw new Error('Translate ($translate) service not found.');
        }

        if (template) {
            switch (template) {
                case 'disagree_agree':
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.1') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.STRONGLY_DISAGREE')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.2') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.DISAGREE')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.3') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SOMEWHAT_DISAGREE')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.4') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.AGREE_NOR_DISAGREE')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.5') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SOMEWHAT_AGREE')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.6') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.AGREE')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.7') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.STRONGLY_AGREE')
                        )
                    );
                    break;
                case 'dissatisfied_satisfied':
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.1') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.VERY_DISSATISFIED')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.2') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.DISSATISFIED')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.3') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SOMEWHAT_DISSATISFIED')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.4') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SATISFIED_NOR_DISSATISFIED')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.5') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SOMEWHAT_SATISFIED')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.6') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SATISFIED')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.7') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.VERY_SATISFIED')
                        )
                    );
                    break;
                case 'never_always':
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.1') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.NEVER')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.2') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SOMETIMES')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.3') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.HALF_TIME')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.4') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.MOST_TIME')
                        )
                    );
                    definedResponses.push(
                        baseClass.make(
                            translate.instant('GENERAL.5') +
                                ' - ' +
                                translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.ALWAYS')
                        )
                    );
                    break;
                case 'agree_5':
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.DO_NOT_AGREE'),
                            false,
                            1
                        )
                    );
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SLIGHTLY_AGREE'),
                            false,
                            2
                        )
                    );
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.MODERATELY_AGREE'),
                            false,
                            3
                        )
                    );
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.MOSTLY_AGREE'),
                            false,
                            4
                        )
                    );
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.COMPLETELY_AGREE'),
                            false,
                            5
                        )
                    );
                    break;
                case 'agree_7':
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.STRONGLY_DISAGREE'),
                            false,
                            1
                        )
                    );
                    definedResponses.push(
                        ScaleResponse.make(translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.DISAGREE'), false, 2)
                    );
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SOMEWHAT_DISAGREE'),
                            false,
                            3
                        )
                    );
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.AGREE_NOR_DISAGREE'),
                            false,
                            4
                        )
                    );
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SOMEWHAT_AGREE'),
                            false,
                            5
                        )
                    );
                    definedResponses.push(
                        ScaleResponse.make(translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.AGREE'), false, 6)
                    );
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.STRONGLY_AGREE'),
                            false,
                            7
                        )
                    );
                    break;
                case 'agree_11':
                    // cause this makes sense
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.STRONGLY_DISAGREE'),
                            false,
                            0
                        )
                    );
                    definedResponses.push(ScaleResponse.make('', false, 1));
                    definedResponses.push(ScaleResponse.make('', false, 2));
                    definedResponses.push(ScaleResponse.make('', false, 3));
                    definedResponses.push(ScaleResponse.make('', false, 4));
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.AGREE_NOR_DISAGREE'),
                            false,
                            5
                        )
                    );
                    definedResponses.push(ScaleResponse.make('', false, 6));
                    definedResponses.push(ScaleResponse.make('', false, 7));
                    definedResponses.push(ScaleResponse.make('', false, 8));
                    definedResponses.push(ScaleResponse.make('', false, 9));
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.STRONGLY_AGREE'),
                            false,
                            10
                        )
                    );
                    break;
                case 'satisfied_5':
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.EXTREMELY_DISSATISFIED'),
                            false,
                            1
                        )
                    );
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.DISSATISFIED'),
                            false,
                            2
                        )
                    );
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SATISFIED_NOR_DISSATISFIED'),
                            false,
                            3
                        )
                    );
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.SATISFIED'),
                            false,
                            4
                        )
                    );
                    definedResponses.push(
                        ScaleResponse.make(
                            translate.instant('SURVEYS.RESPONSE_TEMPLATES.RESPONSES.EXTREMELY_SATISFIED'),
                            false,
                            5
                        )
                    );
                    break;
                default:
                    throw new Error('Invalid defined response template to build: ' + template);
            }
        }

        return definedResponses;
    }
}
