import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { ISurveyApi } from '../../../api/SurveyApi';
import { ICustomField } from '../../../domain/settings/CustomField';
import { Survey } from '../../../domain/surveys/survey/Survey';
import { IBaseItem } from '../../../domain/surveys/items/BaseItem';
import { BaseResponseItem } from '../../../domain/surveys/items/BaseResponseItem';
import PipedTextSvg from '../../../../assets/images/piped-text-01.svg';

/**
 *  The UI for advanced options
 */
@Component('its.survey.module.surveys', PipedTextSelectorComponent.CID, {
    template: require('/app/modules/surveys/components/PipedTextSelectorComponent.html'),
    controllerAs: 'vm',
    bindings: {
        model: '=',
        modelId: '@',
        froalaEditor: '<',
        survey: '<',
        showSurveyQuestions: '<',
        isTextArea: '<?',
    },
})
class PipedTextSelectorComponent {
    static IID: string = 'PipedTextSelectorComponent';
    static CID: string = 'itsPipedTextSelector';
    static $inject: Array<string> = ['itsSurveyApi', '$timeout'];

    public pipedTextSvg: string = PipedTextSvg;

    // bindings
    protected model: string;
    protected modelId: string;
    protected froalaEditor: any;
    protected survey: Survey;
    protected isTextArea: boolean;
    protected showSurveyQuestions: boolean;
    protected customFields: Array<ICustomField> = [];
    protected customFieldsLoaded: boolean = false;
    protected loading: boolean = false;
    protected surveyQuestions: Array<IBaseItem> = [];

    protected sourceElement: HTMLInputElement;
    protected cursorPos: number;

    private selectableSurveyQuestionTypes: Array<string> = [
        'calendar',
        'checkbox',
        'select',
        'radio',
        'rating',
        'input',
        'nps',
        'ranking',
        'slider',
        'scale',
        'matrix',
        'location',
    ];

    constructor(private surveyApi: ISurveyApi, private timeout: ng.ITimeoutService) {}

    $onInit(): void {
        this.sourceElement = <HTMLInputElement>document.getElementById(this.modelId);
        if (this.sourceElement) {
            this.sourceElement.onblur = (event: FocusEvent) => {
                this.cursorPos = (<HTMLInputElement>event.target).selectionStart;
            };
        }
    }

    $onDestroy(): void {
        if (this.sourceElement) {
            this.sourceElement.onkeypress = null;
            this.sourceElement.onclick = null;
        }
    }

    public setLatestSurveyQuestions(): void {
        this.surveyQuestions = this.survey.getItems(
            (item) => this.selectableSurveyQuestionTypes.indexOf(item.type) > -1
        );
    }

    public addToken(tokenKey: string): void {
        if (this.model === null || this.model === undefined) {
            this.model = '';
        }

        const token: string = '[[' + tokenKey + ']]',
            caretPosition: number =
                this.cursorPos !== null && this.cursorPos !== undefined ? this.cursorPos : this.model.length,
            stringPadding: string = this.needsStringPadding() ? ' ' : '',
            beforeToken: string = this.model.substr(0, caretPosition),
            afterToken: string = this.model.substr(caretPosition),
            newCursorOffset: number = stringPadding.length + token.length;

        if (this.froalaEditor) {
            this.froalaEditor.html.insert(token);
        } else {
            this.model = beforeToken + stringPadding + token + afterToken;
        }

        this.cursorPos += newCursorOffset;

        // if it's not padded with a space after the token, insert one and move the cursor up.
        if (this.model.charAt(this.cursorPos) !== ' ' && this.cursorPos < this.model.length) {
            this.model =
                this.model.slice(0, this.cursorPos) + ' ' + this.model.slice(this.cursorPos, this.model.length);
            this.cursorPos++;
        }

        this.timeout(() => {
            this.sourceElement.focus();
            this.sourceElement.setSelectionRange(this.cursorPos, this.cursorPos);
        });
    }

    /**
     * Load custom fields and toggle them on
     */
    public showCustomFields(): void {
        if (!this.customFieldsLoaded) {
            this.loading = true;
            this.surveyApi
                .findAllCustomFields()
                .then((customFields: PagedEntities) => {
                    this.customFields = customFields.getEntities();
                })
                .finally(() => {
                    this.loading = false;
                    this.customFieldsLoaded = true;
                });
        }
    }

    public questionHasResponses(question: IBaseItem): boolean {
        return question instanceof BaseResponseItem;
    }

    /**
     * Check if the character before the current cursor is a space, if it isn't, return an empty space to pad token.
     * If it is or if we're at the start of the input, do nothing.
     *
     * @returns {string}
     */
    private needsStringPadding(): boolean {
        return (
            (this.cursorPos !== null &&
                this.cursorPos !== undefined &&
                this.cursorPos > 0 &&
                this.cursorPos >= this.model.length) ||
            (this.cursorPos !== null &&
                this.cursorPos !== undefined &&
                this.cursorPos > 0 &&
                this.model.charAt(this.cursorPos - 1) !== ' ')
        );
    }
}
