import { BaseResponseItem, BaseResponseItemSettings, IBaseResponseItem } from './BaseResponseItem';
import { BaseResponse, IBaseResponse } from './BaseResponse';
import { IBaseSettings } from './BaseSettings';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { ResponseFactory } from './ResponseFactory';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { IHelpTextTranslation } from './shared/HelpText';
import { ITranslatedEntity } from '../../ITranslatedEntity';

export interface IScaleTranslation extends IHelpTextTranslation {
    additionalText: string;
    notApplicable: string;
}

export class ScaleTranslation extends Translation implements IScaleTranslation {
    additionalText: string = null;
    notApplicable: string = null;
}

export interface IScaleResponse extends IBaseResponse {
    numericValue: number;
}

export class ScaleResponse extends BaseResponse implements IScaleResponse {
    numericValue: number = null;

    // public constructor(props?: Partial<ScaleResponse>) {
    //     super();
    //     Object.assign(this, props);
    // }
    // public static make<T extends typeof BaseResponse>(
    //     this: new (label: string, isOther?: boolean, value?: number) => T,
    //     label: string,
    //     isOther?: boolean,
    //     value?: number
    // ) {
    //     const me:ScaleResponse = BaseResponse.make(label);
    //     return new this(label, isOther, value) as InstanceType<T>;
    // }
    public static make<T extends typeof BaseResponse>(
        label: string = '',
        isOther: boolean = false,
        value?: number
    ): InstanceType<T> {
        const r: ScaleResponse = new ScaleResponse();
        r.label = label;
        r.numericValue = value;
        return r as InstanceType<T>;
    }

    public getLabel(): string {
        return this.numericValue + (this.label ? ' - ' + this.label : '');
    }
}

export interface IScaleSettings extends IBaseSettings {
    responseType: string;
    additionalText: string;
    randomizeOptions: boolean;
    enableNotApplicable: boolean;
    reverseAnswerOrder: boolean;
}

export class ScaleSettings extends BaseResponseItemSettings implements IScaleSettings, ITranslatedEntity {
    responseType: string = '5';
    additionalText: string = null;
    randomizeOptions: boolean = false;
    enableNotApplicable: boolean = false;
    reverseAnswerOrder: boolean = false;
    translations: Array<IScaleTranslation> = [];

    public fromJson(jsonObject: any, convertToCamel?: boolean): IScaleSettings {
        super.fromJson(jsonObject, true);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<IScaleTranslation>(
                ScaleTranslation,
                jsonObject.translations,
                true
            );
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.additionalText) {
            keys.push('additionalText');
        }

        if (this.enableNotApplicable) {
            keys.push('notApplicable');
        }
        return keys;
    }

    public getTranslation(locale: string, label: string, createOnNotFound: boolean = true): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

export interface IScale extends IBaseResponseItem {
    settings: ScaleSettings;
    changeResponseType(type: string, translate: ng.translate.ITranslateService): void;
}

export class Scale extends BaseResponseItem implements IScale {
    type: string = 'scale';
    settings: ScaleSettings = new ScaleSettings();
    responses: Array<IScaleResponse> = [];
    points: number;
    _healthPoints: number = 1;

    get healthPoints(): number {
        return this._healthPoints;
    }

    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<ScaleSettings>(ScaleSettings, jsonObject.settings, true);
        }

        if (jsonObject.responses) {
            this.responses = EntityBuilder.buildMany<IScaleResponse>(ScaleResponse, jsonObject.responses, true);
        }

        return this;
    }

    /**
     * Override default initialize to 3 blank responses
     */
    public initialize(translate?: ng.translate.ITranslateService): void {
        this.changeResponseType(this.settings.responseType, translate);
    }

    /**
     * Creates a string map of uuid and label
     *
     * @returns {any}
     */
    public buildLabelMap(): any {
        const mapObj: any = super.buildLabelMap();
        for (const response of this.responses) {
            mapObj[response.uuid] = response.numericValue + (response.label ? ' - ' + response.label : '');
        }
        return mapObj;
    }

    public changeResponseType(type: string, translate: ng.translate.ITranslateService): void {
        switch (type) {
            case '5':
                this.responses = <IScaleResponse[]>(
                    ResponseFactory.buildDefinedResponses('agree_5', ScaleResponse, translate)
                );
                break;
            case '7':
                this.responses = <IScaleResponse[]>(
                    ResponseFactory.buildDefinedResponses('agree_7', ScaleResponse, translate)
                );
                break;
            case '11':
                this.responses = <IScaleResponse[]>(
                    ResponseFactory.buildDefinedResponses('agree_11', ScaleResponse, translate)
                );
                break;
            default:
                break;
        }
    }
}
