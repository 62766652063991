import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', DigitRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/digit/DigitRenderComponent.html'),
    controllerAs: 'vm',
    bindings: DigitRenderComponent.getBindings(),
})
class DigitRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'DigitRenderComponent';
    static CID: string = 'itsDigitRender';
    static $inject: string[] = [];
}
