import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', ResponseDefaultSelectComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/response-items/shared/response-default-select/ResponseDefaultSelectComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class ResponseDefaultSelectComponent {
    static IID: string = 'ResponseDefaultSelectComponent';
    static CID: string = 'itsResponseDefaultSelect';
    static $inject: string[] = [];
}
