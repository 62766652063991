import * as angular from 'angular';

const module: any = angular.module('its.survey.module.contactCenter', []);

import './_configuration';
import './ContactCenterController';
import './contact-details/ContactDetailsController';
import './contact-upload/ContactUploadController';
import './components/ContactUploader';
import './contact-lists/add-contacts/AddContactsController';
import './contact-lists/add-contacts/components/AddContactToListUploader';
import './contact-download/ContactDownloadController';

export default module;
