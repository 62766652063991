import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', HideLabelSettingComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/shared/switches/hide-label-setting/HideLabelSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class HideLabelSettingComponent {
    static IID: string = 'HideLabelSettingComponent';
    static CID: string = 'itsHideLabelSetting';
    static $inject: string[] = [];
}
