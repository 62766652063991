import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { ISurveyApi } from '../../../api/SurveyApi';
import { ISurvey, Survey } from '../../../domain/surveys/survey/Survey';
import { IProgramCollectionItem } from '@intouch/its.essential/app/essential/domain/programs/ProgramCollectionItem';
import SurveyCopyModalHtml from './SurveyCopyModal.html';

export class SurveyCopyModal extends Modal {
    static $inject: Array<string> = ['itsSurveyApi', '$mdDialog', 'iteToaster', '$scope'];

    public surveyToCopy: ISurvey;
    public formName: string = 'surveyCopyForm';

    public survey: ISurvey = new Survey();
    public surveyToCopyUuid: string = null;
    public creating: boolean = false;
    public selectingSurveyFromModal: boolean = false;
    public surveyProgramCollectionItem: IProgramCollectionItem = null;

    /**
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = SurveyCopyModalHtml;
        config.controller = SurveyCopyModal;

        return super.instantiate(config);
    }

    public constructor(
        private api: ISurveyApi,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private scope: ng.IScope
    ) {
        super();

        // if a survey is not given initially, set a flag for the view to indicate the user needs to pick one.
        if (this.surveyToCopy) {
            this.surveyToCopyUuid = this.surveyToCopy.uuid;
        } else {
            this.selectingSurveyFromModal = true;
        }
    }

    public copy(): void {
        this.creating = true;
        this.api
            .copySurvey(this.surveyToCopyUuid, this.survey)
            .then((newSurvey: ISurvey) => {
                this.dialog.hide(newSurvey);
            })
            .catch((error: IErrorResponse) => {
                if (error && error.type && error.type === 'SurveyAlreadyExistsException') {
                    this.scope[this.formName].name.$setValidity('SurveyAlreadyExistsException', false);
                } else {
                    this.toaster.error('ERRORS.FAILED_SURVEY_CREATE', 'Failed to create Survey: ' + this.survey.name);
                }
            })
            .finally(() => {
                this.creating = false;
            });
    }

    public cancel(): void {
        this.dialog.cancel();
    }

    public getForm(): any {
        return this.scope[this.formName];
    }

    public onNameChange(): void {
        // clear name duplication exception
        if (this.getForm()) {
            this.getForm().name.$setValidity('SurveyAlreadyExistsException', true);
        }
    }

    public selectSurveyToCopy(): void {
        this.surveyToCopyUuid = this.surveyProgramCollectionItem.uuid;
    }
}
