import { Configuration } from '@intouch/its.essential/app/essential/decorators/Configuration';
import { StateRerouter } from '@intouch/its.essential/app/essential/utils/StateRerouter';

/**
 * Will setup the routing states
 *
 * @param $stateProvider
 */
@Configuration('its.survey.module.settings', SettingsConfiguration)
class SettingsConfiguration {
    /**
     * Create the instance of the configuration used for angular binding
     *
     * @returns {any}
     */
    public static instance(): any {
        const config: any = ($stateProvider: ng.ui.IStateProvider) => new SettingsConfiguration($stateProvider);
        config.$inject = ['$stateProvider'];
        return config;
    }

    /**
     * Create the configuration class
     *
     * @param stateProvider
     */
    constructor(private stateProvider: ng.ui.IStateProvider) {
        this.buildStates();
    }

    /**
     * Initialize the forms module
     */
    public buildStates(): void {
        this.stateProvider
            .state('home.settings', {
                url: '/settings',
                abstract: true,
                template: require('/app/modules/settings/Settings.html'),
                controller: 'its.survey.module.settings.SettingsController as vm',
            })
            .state('home.settings.custom-fields', {
                url: '/custom-fields?page&sort_by&order&search',
                data: { title: 'CONTACT_CENTER.SETTINGS.CUSTOM_FIELDS', translate: true, activeTab: 'custom-fields' },
                template: require('/app/modules/settings/custom-fields/CustomFields.html'),
                controller: 'its.survey.module.settings.CustomFieldsController as vm',
                params: {
                    page: {
                        value: '1',
                        squash: true,
                    },
                    sort_by: {
                        value: 'label',
                        squash: true,
                    },
                    order: {
                        value: 'asc',
                        squash: true,
                    },
                    search: {
                        value: null,
                        squash: true,
                    },
                },
            })
            .state('home.settings.fatigue-protection', {
                url: '/fatigue-protection',
                data: {
                    title: 'CONTACT_CENTER.SETTINGS.FATIGUE_PROTECTION',
                    translate: true,
                    activeTab: 'fatigue-protection',
                },
                template: require('/app/modules/settings/fatigue-protection/FatigueProtection.html'),
                controller: 'its.survey.module.settings.FatigueProtectionController as vm',
                resolve: {
                    hasFeatureEnabled: StateRerouter.onNoProductFeature(
                        'survey',
                        'email-distributions',
                        {
                            state: 'home.settings.custom-fields',
                        },
                        undefined,
                        ['authenticatedUser']
                    ),
                },
            })
            .state('home.settings.custom-domains', {
                url: '/custom-domains',
                data: { title: 'CONTACT_CENTER.SETTINGS.CUSTOM_DOMAINS', translate: true, activeTab: 'custom-domains' },
                template: require('/app/modules/settings/custom-domains/CustomDomains.html'),
                controller: 'its.survey.module.settings.CustomDomainsController as vm',
                resolve: {
                    hasFeatureEnabled: StateRerouter.onNoProductFeature(
                        'survey',
                        'custom-domains',
                        {
                            state: 'home.settings.custom-fields',
                        },
                        undefined,
                        ['authenticatedUser']
                    ),
                },
            })
            .state('home.settings.mail-senders', {
                url: '/mail-senders',
                data: {
                    title: 'CONTACT_CENTER.SETTINGS.CUSTOM_EMAIL_SENDERS.TITLE',
                    translate: true,
                    activeTab: 'mail-senders',
                },
                template: require('/app/modules/settings/mail-senders/MailSenders.html'),
                controller: 'its.survey.module.settings.MailSendersController as vm',
            });
    }
}
