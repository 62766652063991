import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IPager } from '@intouch/its.essential/app/essential/domain/Pager';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { IPageService } from '../../../../services/PageService';
import { IContact } from '../../../../domain/contact-center/Contact';
import ClipboardJS from 'clipboard/dist/clipboard';
import { ISurveyService } from '../../../../services/SurveyService';
import { BaseSurvey } from '../../BaseSurvey';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';

@Controller('its.survey.module.surveys.distributions', DistributionLinkController.IID, DistributionLinkController)
class DistributionLinkController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.distributions.DistributionLinkController';
    static $inject: Array<string> = [
        '$window',
        'APPCONFIG',
        '$translate',
        '$state',
        '$mdDialog',
        '$stateParams',
        'itsSurveyService',
        'iteToaster',
        'itsPageService',
        'itsSurveyApi',
        '$scope',
        'iteAccessService',
        'iteUserLocalTimeService',
    ];

    public filters: string = 'filter[status]=subscribed';
    public hasEmailDistributionsFeature: boolean = false;

    constructor(
        private window: ng.IWindowService,
        private config: any,
        private translate: ng.translate.ITranslateService,
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected scope: ng.IScope,
        protected accessService: IAccessService,
        protected userLocalTimeService: IUserLocalTimeService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );

        if (this.accessService.getToken().hasFeatureEnabled('survey', 'email-distributions')) {
            this.hasEmailDistributionsFeature = true;
        }

        this.load();

        // wiring for the copy paste button on a link
        const clipboard: ClipboardJS = new ClipboardJS('#triggerBtn');
        clipboard.on('success', () => {
            this.toaster.info(this.translate.instant('SURVEYS.THEMES.COPIED_URL_TO_CLIPBOARD'));
        });
        const clipboard2: ClipboardJS = new ClipboardJS('#triggerBtn2');
        clipboard2.on('success', () => {
            this.toaster.info(this.translate.instant('SURVEYS.THEMES.COPIED_URL_TO_CLIPBOARD'));
        });
        this.scope.$on('$destroy', () => {
            clipboard.destroy();
            clipboard2.destroy();
        });
    }

    /**
     * Opens the survey in a new tab
     */
    public open(contact?: IContact): void {
        this.window.open(this.getSurveyUrl(false, contact), '_blank');
    }

    /**
     * Allow a user to search for a contact to generate a personal link
     *
     * @param {string} search
     * @param {IPager} pager
     * @returns {angular.IPromise<any>}
     */
    public searchContacts(search: string, pager: IPager): ng.IPromise<any> {
        return this.surveyApi.findContacts(pager, search, this.filters);
    }

    /**
     * Display a contact in a string format
     *
     * @param {IContact} contact
     * @returns {string}
     */
    public displayContact(contact: IContact): string {
        return contact ? contact.firstName + ' ' + contact.lastName + ' - ' + contact.email : '';
    }

    /**
     * Retrieves the public facing URL of the survey
     *
     * @param {boolean} urlEncoded
     * @param contact
     * @returns {string}
     */
    public getSurveyUrl(urlEncoded: boolean = true, contact?: IContact): string {
        let url: string = this.surveyService.getSurveyUrl(this.survey) + '/' + this.survey.originalUuid;

        // if we have a contact; add it to the url
        if (contact) {
            url += '/p/' + contact.uuid;
        }

        return urlEncoded ? encodeURIComponent(url) : url;
    }
}
