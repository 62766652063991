import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', CaptchaRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/captcha/CaptchaRenderComponent.html'),
    controllerAs: 'vm',
    bindings: CaptchaRenderComponent.getBindings(),
})
class CaptchaRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'CaptchaRenderComponent';
    static CID: string = 'itsCaptchaRender';
    static $inject: string[] = [];
}
