import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Survey } from '../../../../../domain/surveys/survey/Survey';
import { IHealthCalculator } from '../../../../../domain/surveys/survey/health/IHealthCalcuator';
import { CompletionTime } from '../../../../../domain/surveys/survey/health/CompletionTime';
import { CompletionRate } from '../../../../../domain/surveys/survey/health/CompletionRate';
import { QuestionAnalysis } from '../../../../../domain/surveys/survey/health/QuestionAnalysis';
import { TranslationAnalysis } from '../../../../../domain/surveys/survey/health/TranslationAnalysis';
import { ProgressStatus } from '@intouch/its.essential/app/essential/domain/ProgressStatus';

@Component('its.survey.module.surveys', SurveyHealthSummaryComponent.CID, {
    template: require('/app/modules/surveys/summary/components/survey-health-summary/SurveyHealthSummaryComponent.html'),
    controllerAs: 'vm',
    bindings: {
        survey: '<',
    },
})
class SurveyHealthSummaryComponent {
    static IID: string = 'SurveyHealthSummaryComponent';
    static CID: string = 'itsSurveyHealthSummary';
    static $inject: Array<string> = ['$translate', '$mdMedia'];

    public survey: Survey;
    public completionTime: IHealthCalculator = null;
    public completionRate: IHealthCalculator = null;
    public questionAnalysis: IHealthCalculator = null;
    public translationAnalysis: IHealthCalculator = null;
    public questionCount: number = 0;
    public pageCount: number = 0;

    public constructor(private translate: ng.translate.ITranslateService, private media: ng.material.IMedia) {}

    $onChanges(): void {
        if (this.survey) {
            this.completionTime = new CompletionTime(this.survey);
            this.completionRate = new CompletionRate(this.completionTime.data);
            this.questionAnalysis = new QuestionAnalysis(this.survey, this.translate);
            this.translationAnalysis = new TranslationAnalysis(this.survey, this.translate);
            this.questionCount = this.survey.getQuestionCount();
            this.pageCount = this.survey.getPageCount();
        }
    }

    public getStatusColorClass(healthAnalysis: IHealthCalculator): string {
        if (!healthAnalysis) {
            return;
        }
        const healthStatus: ProgressStatus = healthAnalysis.getStatus();
        switch (healthStatus) {
            case ProgressStatus.Excellent:
                return 'its-txt-colour-positive';
            case ProgressStatus.Ok:
                return 'its-txt-colour-warn';
            case ProgressStatus.Bad:
                return 'its-txt-colour-danger';
            default:
                return null;
        }
    }

    public getStatusIcon(healthAnalysis: IHealthCalculator): string {
        if (!healthAnalysis) {
            return;
        }
        const healthStatus: ProgressStatus = healthAnalysis.getStatus();
        switch (healthStatus) {
            case ProgressStatus.Excellent:
                return 'checkmark';
            case ProgressStatus.Ok:
                return 'warning';
            case ProgressStatus.Bad:
                return 'close';
            default:
                return null;
        }
    }
}
