import { ProgressStatus } from '@intouch/its.essential/app/essential/domain/ProgressStatus';
import { IHealthCalculator } from './IHealthCalcuator';
import { HealthStatus } from './HealthStatus';
import { ISurvey } from '../Survey';
import { IBaseItem } from '../../items/BaseItem';
import * as _ from 'lodash';
import { ISection } from '../../Section';
import { IGroup } from '../../items/Group';
import { BaseResponseItem } from '../../items/BaseResponseItem';
import { ITranslationEntityAnalysis, TranslationEntityAnalysis } from './TranslationEntityAnalysis';
import { ITranslation } from '../../Translation';
import { ITranslatedEntity } from '../../../ITranslatedEntity';
import { TypeGuard } from '../../TypeGuard';
import { IBaseSettings } from '../../items/BaseSettings';
import { IMatrixQuestion, Matrix } from '../../items/Matrix';
import { IBaseResponse } from '../../items/BaseResponse';
import { TextArea } from '../../items/TextArea';
import { ISmartPrompt } from '../../items/shared/SmartPrompt';

export class TranslationAnalysis implements IHealthCalculator {
    // data in this scenario is the missing translations
    data: number = null;
    percentage: number = null;
    score: HealthStatus = null;
    scoreText: string = null;

    private supportedLanguages: Array<string> = [];
    private totalTranslations: number = 0;
    private totalMissingTranslations: number = 0;

    // private missingEntityStack:Array<ITranslationEntityAnalysis> = [];

    constructor(survey: ISurvey, private translate: ng.translate.ITranslateService) {
        this.setSupportedLanguages(survey);
        this.calculate(survey);
    }

    public calculate(survey: ISurvey): void {
        this.verifySurveyTranslations(survey);

        for (const section of survey.sections) {
            this.verifySectionTranslations(section);

            for (const item of section.items) {
                this.verifyItemTranslations(<any>item);

                if (item.type === 'group') {
                    for (const groupItem of (<any>item).items) {
                        this.verifyItemTranslations(groupItem);
                    }
                }
            }
        }

        this.calculatePercentage();
        this.data = this.totalMissingTranslations;
        this.score = this.percentage;
        this.scoreText =
            this.percentage === 100
                ? this.translate.instant('GENERAL.COMPLETE')
                : this.totalMissingTranslations.toString();
    }

    public getStatus(): ProgressStatus {
        if (this.percentage >= 80) {
            return ProgressStatus.Excellent;
        } else if (this.percentage >= 40) {
            return ProgressStatus.Ok;
        } else {
            return ProgressStatus.Bad;
        }
    }

    /**
     * Gets a flattened array of locales the survey supports and removes its source language from the array
     *
     * @param survey
     */
    private setSupportedLanguages(survey: ISurvey): void {
        const supportedLanguages: Array<string> = _.map(survey.settings.supportedLanguages, (language) => {
            return language.locale;
        });
        if (survey.settings.sourceLanguage) {
            const index: number = supportedLanguages.indexOf(survey.settings.sourceLanguage);
            if (index > -1) {
                supportedLanguages.splice(index, 1);
            }
        }
        this.supportedLanguages = supportedLanguages;
    }

    /**
     * Verifies and applies the translation analysis counts for the survey + survey settings entities
     *
     * @param survey
     */
    private verifySurveyTranslations(survey: ISurvey): void {
        const translationEntity: ITranslationEntityAnalysis = new TranslationEntityAnalysis(survey.uuid, survey.name);

        this.validateTranslations(translationEntity, survey, survey.getTranslationKeys());
        this.validateTranslations(translationEntity, survey.settings, survey.settings.getTranslationKeys());
        this.addEntityAnalysisCounts(translationEntity);
    }

    /**
     * Verifies and applies the translation analysis counts for requested section
     *
     * @param section
     */
    private verifySectionTranslations(section: ISection): void {
        const translationEntity: ITranslationEntityAnalysis = new TranslationEntityAnalysis(
            section.uuid,
            section.label
        );
        this.validateTranslations(translationEntity, section, ['label']);
        this.addEntityAnalysisCounts(translationEntity);
    }

    /**
     * Takes a base item and verifies its translations through various settings,
     * responses and questions.
     *
     * @param item
     */
    private verifyItemTranslations(item: IBaseItem): void {
        const translationEntity: ITranslationEntityAnalysis = new TranslationEntityAnalysis(item.uuid, item.label);
        this.validateTranslations(translationEntity, item, item.getTranslationKeys());
        const settings: IBaseSettings | ITranslatedEntity = item.settings;

        // check if we have settings that have translations
        if (settings && TypeGuard.isTranslatedEntity(settings)) {
            this.validateTranslations(translationEntity, settings, settings.getTranslationKeys());

            // if it's a text area, we check the smart prompts
            if (item instanceof TextArea) {
                for (const prompt of item.settings.smartPrompt) {
                    const pt: ISmartPrompt | ITranslatedEntity = prompt;
                    if (TypeGuard.isTranslatedEntity(pt)) {
                        this.validateTranslations(translationEntity, pt, pt.getTranslationKeys());
                    }
                }
            }
        }

        // check response translations for response items (matrix, checkbox, ranking, dropdown, ranking)
        if (item instanceof BaseResponseItem) {
            // check if matrix for questions since they have their own translations
            if (item instanceof Matrix) {
                for (const question of item.questions) {
                    const qt: IMatrixQuestion | ITranslatedEntity = question;
                    if (TypeGuard.isTranslatedEntity(qt)) {
                        this.validateTranslations(translationEntity, qt, qt.getTranslationKeys());
                    }
                }
            }

            // basic responses
            for (const response of item.responses) {
                const rt: IBaseResponse | ITranslatedEntity = response;
                if (TypeGuard.isTranslatedEntity(rt)) {
                    this.validateTranslations(translationEntity, rt, rt.getTranslationKeys());
                }
            }
        }

        this.addEntityAnalysisCounts(translationEntity);
    }

    /**
     * Analyzes the translations and verifies against the requested translation keys
     * and tracks the missing locale translations if necessary
     *
     * @param translationEntity
     * @param translationHolder
     * @param translationKeysToVerify
     */
    private validateTranslations(
        translationEntity: ITranslationEntityAnalysis,
        translationHolder: ITranslatedEntity,
        translationKeysToVerify: Array<string>
    ): void {
        if (translationKeysToVerify.length <= 0) {
            return;
        }
        const convertedTranslationsToHashMap: { [locale: string]: ITranslation } = _.keyBy(
            translationHolder.translations,
            'locale'
        );
        for (const locale of this.supportedLanguages) {
            const localeTranslationSet: ITranslation = convertedTranslationsToHashMap.hasOwnProperty(locale)
                ? convertedTranslationsToHashMap[locale]
                : null;
            if (!localeTranslationSet) {
                translationEntity.addMissing(translationKeysToVerify.length);
            } else {
                for (const translationKey of translationKeysToVerify) {
                    translationEntity.analyze(localeTranslationSet[translationKey]);
                }
            }
        }
    }

    /**
     * Takes the translated entity and applies its total/missing translation counts to the
     * overall counts. The overall counts are used to display overall translation completion percentage
     *
     * @param translatedEntity
     */
    private addEntityAnalysisCounts(translatedEntity: ITranslationEntityAnalysis): void {
        if (translatedEntity.hasMissingTranslations()) {
            this.totalMissingTranslations += translatedEntity.missingTranslationsCount;
        }

        this.totalTranslations += translatedEntity.translationsCount;
    }

    private calculatePercentage(): void {
        // ie. if there's nothing to translate.
        if (this.totalTranslations === 0) {
            this.percentage = 100;
        } else {
            this.percentage = Math.round(
                ((this.totalTranslations - this.totalMissingTranslations) / this.totalTranslations) * 100
            );
        }
    }
}
