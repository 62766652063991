import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Video } from '../../../../../../domain/surveys/items/Video';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';

@Component('its.survey.module.surveys', VideoSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/display-items/video/VideoSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: VideoSettingsComponent.getBindings(),
})
class VideoSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'VideoSettingsComponent';
    static CID: string = 'itsVideoSettings';
    static $inject: string[] = [];

    public item: Video;

    public transformVideoUrlIfNecessary(): void {
        if (this.item.video.url) {
            this.item.determineVideoVendor();
            this.item.transformVideoUrl(this.item.getVideoVendor());
        }
    }
}
