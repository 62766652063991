import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { SurveyService } from '../../../../../../../services/SurveyService';
import { ISurvey } from '../../../../../../../domain/surveys/survey/Survey';

@Component('its.survey.module.surveys', LabelSettingComponent.CID, {
    template: require('/app/modules/surveys/components/items/shared/inputs/label-setting/LabelSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
        label: '@?',
    },
})
class LabelSettingComponent {
    static IID: string = 'LabelSettingComponent';
    static CID: string = 'itsLabelSetting';
    static $inject: string[] = ['itsSurveyService'];

    public survey: ISurvey = null;

    public constructor(private surveyService: SurveyService) {}

    $onInit(): void {
        this.survey = this.surveyService.getSurvey();
    }
}
