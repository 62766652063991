import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { Locales, ILocale } from '@intouch/its.essential/app/essential/utils/Locales';
import * as _ from 'lodash';

export interface ILanguage extends IEntity {
    label: string;
    locale: string;
}

export class Language extends Entity implements ILanguage {
    label: string = null;
    locale: string = null;
}

export class LanguageProvider {
    private static languages: ILocale[] = Locales.getLocales();

    public static getAvailableLanguages(excludeLocale: Array<string> = [], extraOptions: any = {}): Array<ILanguage> {
        let languagesJson: Array<ILocale> = LanguageProvider.languages;

        if (excludeLocale && excludeLocale.length) {
            languagesJson = _.filter(languagesJson, (language) => {
                return excludeLocale.indexOf(language.locale) === -1;
            });
        }

        const languages: Array<ILanguage> = EntityBuilder.buildMany<ILanguage>(Language, languagesJson, true);

        if (typeof extraOptions === 'object' && !_.isEmpty(extraOptions)) {
            for (let i: number = 0; i < languages.length; i++) {
                languages[i] = _.merge(languages[i], extraOptions);
            }
        }

        return languages;
    }

    public static getLanguageLabelsByLocale(locales: string[]): string[] {
        const languageLabels: string[] = [];
        for (const locale of locales) {
            const languageObj: any = _.find(LanguageProvider.languages, { locale: locale });
            if (languageObj) {
                languageLabels.push(languageObj.label);
            }
        }
        return languageLabels;
    }
}
