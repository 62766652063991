import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import {
    BaseResponseItem,
    BaseResponseItemSettings,
    IBaseResponseItem,
    IBaseResponseItemSettings,
} from './BaseResponseItem';
import { BaseResponse, IBaseResponse } from './BaseResponse';
import { IHelpTextSettings, IHelpTextTranslation } from './shared/HelpText';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { ITranslatedEntity } from '../../ITranslatedEntity';

/**
 * The RankingTranslation interface
 */
export type IRankingTranslation = IHelpTextTranslation;

/**
 * The RankingTranslation class
 */
export class RankingTranslation extends Translation implements IRankingTranslation {
    additionalText: string = null;
}

/**
 * The RankingResponse interface
 */
export type IRankingResponse = IBaseResponse;

/**
 * The RankingResponse class
 */
export class RankingResponse extends BaseResponse implements IRankingResponse {}

/**
 * The RankingSettings interface
 */
export interface IRankingSettings extends IBaseResponseItemSettings {
    additionalText: string;
    translations: Array<IRankingTranslation>;
}

/**
 * The RankingSettings class
 */
export class RankingSettings
    extends BaseResponseItemSettings
    implements IRankingSettings, IHelpTextSettings<IRankingTranslation>, ITranslatedEntity
{
    additionalText: string = null;
    randomizeOptions: boolean = true;
    translations: Array<IRankingTranslation> = [];

    /**
     * Build the ranking item settings from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IRanking}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): IRankingSettings {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<IRankingTranslation>(
                RankingTranslation,
                jsonObject.translations,
                true
            );
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.additionalText) {
            keys.push('additionalText');
        }
        return keys;
    }

    /**
     * Gets a translation from a given locale/key.
     *
     * @param {string} locale
     * @param {string} label
     * @param {boolean} createOnNotFound
     * @returns {ITranslation}
     */
    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

/**
 * The Ranking interface
 */
export interface IRanking extends IBaseResponseItem {
    settings: IRankingSettings;
}

/**
 * The Ranking class
 */
export class Ranking extends BaseResponseItem implements IRanking {
    type: string = 'ranking';
    settings: IRankingSettings = new RankingSettings();
    responses: Array<IRankingResponse> = [];

    /**
     * Init the Ranking question.
     */
    public initialize(translate?: ng.translate.ITranslateService): void {
        this.addResponses(3);
        this.settings.additionalText = translate.instant('SURVEYS.ITEMS.RANKING.DEFAULT_HELP_TEXT');
    }

    /**
     * Determine whether we're at the response maximum.
     * @return {boolean}
     */
    public isAtResponseMaximum(): boolean {
        return this.responses.length >= 10;
    }

    /**
     * Determine whether we're at the response minimum.
     * @return {boolean}
     */
    public isAtResponseMinimum(): boolean {
        return this.responses.length <= 2;
    }

    /**
     * Build the ranking item from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {Ranking}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<IRankingSettings>(RankingSettings, jsonObject.settings, true);
        }

        if (jsonObject.responses) {
            this.responses = EntityBuilder.buildMany<IRankingResponse>(RankingResponse, jsonObject.responses, true);
        }

        return this;
    }
}
