import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', RandomizeAnswerOrderSettingComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/shared/switches/randomize-answer-order-setting/RandomizeAnswerOrderSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
        label: '@?',
    },
})
class RandomizeAnswerOrderSettingComponent {
    static IID: string = 'RandomizeAnswerOrderSettingComponent';
    static CID: string = 'itsRandomizeAnswerOrderSetting';
    static $inject: string[] = ['$translate'];

    public label: string;

    public constructor(private translate: ng.translate.ITranslateService) {}

    $onInit(): void {
        if (!this.label) {
            this.label = this.translate.instant('SURVEYS.ITEMS.ADVANCED.RANDOMISE_ANSWERS');
        }
    }
}
