import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { BaseItem, IBaseItem } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { IHelpTextSettings, IHelpTextTranslation } from './shared/HelpText';
import { ITranslatedEntity } from '../../ITranslatedEntity';
import { IIconDetails } from './shared/IconDetails';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';

export interface ISliderTranslation extends IHelpTextTranslation {
    minLabel: string;
    maxLabel: string;
    centerLabel: string;
}

export class SliderTranslation extends Translation implements ISliderTranslation {
    minLabel: string = null;
    maxLabel: string = null;
    centerLabel: string = null;
    additionalText: string = null;
}

export interface ISliderSettings extends IBaseSettings, IHelpTextSettings<ISliderTranslation> {
    minValue: number;
    minLabel: string;
    maxValue: number;
    maxLabel: string;
    centerLabel: string;
    iconGroup: string;
    showNumbers: boolean;
    interval: number;
    defaultSelection: number;
    enableNotApplicable: boolean;
    translations: Array<ISliderTranslation>;
}

export class SliderSettings extends BaseSettings implements ISliderSettings, ITranslatedEntity {
    interval: number = 5;
    minValue: number = 0;
    minLabel: string = null;
    maxValue: number = 100;
    maxLabel: string = null;
    centerLabel: string = null;
    iconGroup: string = null;
    showNumbers: boolean = true;
    defaultSelection: number = 0;
    additionalText: string = null;
    enableNotApplicable: boolean = false;
    translations: Array<ISliderTranslation> = [];

    @NotEnumerable
    public availableIcons: IIconDetails[] = [
        {
            icon: 'star',
            value: 'stars',
            label: 'GENERAL.STARS',
        },
        {
            icon: 'thumb_up',
            value: 'thumbs',
            label: 'GENERAL.THUMBS',
        },
        {
            icon: 'faces',
            value: 'faces',
            label: 'GENERAL.FACES',
        },
    ];

    public fromJson(jsonObject: any, convertToCamel?: boolean): ISliderSettings {
        super.fromJson(jsonObject, true);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ISliderTranslation>(
                SliderTranslation,
                jsonObject.translations,
                true
            );
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const translationKeys: Array<string> = [];
        if (this.minLabel) {
            translationKeys.push('minLabel');
        }

        if (this.maxLabel) {
            translationKeys.push('maxLabel');
        }

        if (this.centerLabel) {
            translationKeys.push('centerLabel');
        }

        if (this.additionalText) {
            translationKeys.push('additionalText');
        }
        return translationKeys;
    }

    /**
     * Retrieve the given translation for a locale
     *
     * @param {string} locale
     * @param {string} label
     * @param {boolean} createOnNotFound
     * @returns {ITranslation}
     */
    public getTranslation(locale: string, label: string, createOnNotFound: boolean = true): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

export interface ISlider extends IBaseItem {
    scoreMultiplier: number;
    points: number;
    validateDefaultSelection(model: ng.INgModelController): void;
}

export class Slider extends BaseItem implements ISlider {
    type: string = 'slider';
    scoreMultiplier: number = null;
    settings: ISliderSettings = new SliderSettings();

    get points(): number {
        if (this.scoreMultiplier === null || this.scoreMultiplier === undefined) {
            return 0;
        }
        return this.settings.maxValue * this.scoreMultiplier;
    }

    /**
     * Build the Slider class from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IBaseResponseItem}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<ISliderSettings>(SliderSettings, jsonObject.settings, true);
        }

        return this;
    }

    public validateDefaultSelection(model: ng.INgModelController): void {
        let validity: boolean = false;

        if (
            (parseInt(model.$viewValue, 10) > this.settings.minValue &&
                parseInt(model.$viewValue, 10) < this.settings.maxValue &&
                parseInt(model.$viewValue, 10) % this.settings.interval === 0) ||
            parseInt(model.$viewValue, 10) === this.settings.minValue ||
            parseInt(model.$viewValue, 10) === this.settings.maxValue
        ) {
            validity = true;
        }

        model.$setViewValue(parseInt(model.$viewValue, 10));
        model.$setValidity('defaultSelection', validity);
        model.$render();
    }

    /**
     * Gets the promoter icon
     * @returns {string}
     */
    public getPromoterIcon(): string {
        switch (this.settings.iconGroup) {
            case 'faces':
                return 'sentiment_very_satisfied';
            case 'thumbs':
                return 'thumb_up';
            case 'stars':
                return 'star';
            default:
                return null;
        }
    }
    /**
     * Gets the neutral icon
     * @returns {string}
     */
    public getNeutralIcon(): string {
        switch (this.settings.iconGroup) {
            case 'faces':
                return 'sentiment_satisfied';
            case 'thumbs':
                return 'thumbs_up_down';
            case 'stars':
                return 'star_half';
            default:
                return null;
        }
    }

    /**
     * Gets the detractor icon
     * @returns {string}
     */
    public getDetractorIcon(): string {
        switch (this.settings.iconGroup) {
            case 'faces':
                return 'sentiment_very_dissatisfied';
            case 'thumbs':
                return 'thumb_down';
            case 'stars':
                return 'star_outline';
            default:
                return null;
        }
    }
}
