import { IThemeSettings } from '../ThemeSettings';
import { ThemeType } from '../../../../services/ThemeService';
import { SoftBaseTheme } from './SoftBaseTheme';

export class SoftVioletTheme extends SoftBaseTheme {
    public slug: ThemeType = 'soft-violet';

    public constructor() {
        super('#7C3C94');
    }

    public getName(): string {
        return 'SURVEYS.THEMES.SOFT_VIOLET';
    }

    public getSettings(): IThemeSettings {
        return this.customizations;
    }
}
