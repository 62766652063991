import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ITooltipPanelLocals } from '@intouch/its.essential/app/essential/domain/tooltip-panel/TooltipPanelLocals';
import { TooltipPanel } from '@intouch/its.essential/app/essential/panels/tooltip-panel/TooltipPanel';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';
import { FeatureLockUtils, IFeatureLockCondition } from '@intouch/its.essential/app/essential/utils/FeatureLockUtils';
import { ISiteNavigationService } from '@intouch/its.essential/app/essential/services/SiteNavigationService';

import { IElementLibraryGroupItem } from '../domain/surveys/element-library/IElementLibraryGroup';
import { SurveyService } from './SurveyService';
import * as _ from 'lodash';

export interface IQuestionTooltipConfig {
    contentText: Array<string>;
    imageUrl?: {
        en: string;
        fr_CA?: string;
    };
    btnText?: string;
    btnClass?: string;
    btnOnClick?: () => void;
}

export interface IQuestionConfigItem {
    [questionType: string]: IQuestionTooltipConfig;
}

/**
 * The group service class
 */
@Service('its.survey.services', QuestionTooltipService.IID, QuestionTooltipService)
export class QuestionTooltipService {
    static IID: string = 'itsQuestionTooltipService';
    static $inject: Array<string> = [
        'iteAccessService',
        '$translate',
        '$timeout',
        'itsSurveyService',
        'itePanelService',
        'iteSiteNavigationService',
    ];

    public mdPanelRef: any;
    private openTimer: any;
    private defaultLockedBtnClass: string = 'its-btn--premium';
    private defaultLockedBtnText: string = 'SURVEYS.ITEMS.UNLOCK';
    private noTooltipRequiredList: Array<string> = [];

    private questionTooltipConfigs: IQuestionConfigItem = {
        calendar: {
            contentText: ['SURVEYS.ITEMS.CALENDAR.TOOLTIP_CONTENT.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/calendar_en.png',
                fr_CA: 'assets/images/question-tooltips/calendar_fr.png',
            },
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        checkbox: {
            contentText: ['SURVEYS.ITEMS.CHECKBOX.TOOLTIP_CONTENT.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/checkbox_en.png',
                fr_CA: 'assets/images/question-tooltips/checkbox_fr.png',
            },
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        select: {
            contentText: ['SURVEYS.ITEMS.SELECT.TOOLTIP_CONTENT.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/select_en.png',
                fr_CA: 'assets/images/question-tooltips/select_fr.png',
            },
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        radio: {
            contentText: ['SURVEYS.ITEMS.RADIO.TOOLTIP_CONTENT.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/radio_en.png',
                fr_CA: 'assets/images/question-tooltips/radio_fr.png',
            },
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        rating: {
            contentText: ['SURVEYS.ITEMS.RATING.TOOLTIP_CONTENT.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/rating_en.png',
                fr_CA: 'assets/images/question-tooltips/rating_fr.png',
            },
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        input: {
            contentText: ['SURVEYS.ITEMS.INPUT.TOOLTIP_CONTENT.PARAGRAPH_1'],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        audio: {
            contentText: [
                'SURVEYS.ITEMS.AUDIO.TOOLTIP_CONTENT.PARAGRAPH_1',
                'SURVEYS.ITEMS.AUDIO.TOOLTIP_CONTENT.PARAGRAPH_2',
            ],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        captcha: {
            contentText: ['SURVEYS.ITEMS.CAPTCHA.TOOLTIP_CONTENT.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/captcha_en.png',
            },
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        image_upload: {
            contentText: ['SURVEYS.ITEMS.IMAGE.TOOLTIP_CONTENT.PARAGRAPH_1'],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        location: {
            contentText: [
                'SURVEYS.ITEMS.LOCATION.TOOLTIP_CONTENT.PARAGRAPH_1',
                'SURVEYS.ITEMS.LOCATION.TOOLTIP_CONTENT.PARAGRAPH_2',
            ],
            imageUrl: {
                en: 'assets/images/question-tooltips/location_en.png',
                fr_CA: 'assets/images/question-tooltips/location_fr.png',
            },
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        matrix: {
            contentText: ['SURVEYS.ITEMS.MATRIX.TOOLTIP_CONTENT.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/matrix_en.png',
                fr_CA: 'assets/images/question-tooltips/matrix_fr.png',
            },
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        nps: {
            contentText: ['SURVEYS.ITEMS.NPS.TOOLTIP_CONTENT.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/nps_en.png',
                fr_CA: 'assets/images/question-tooltips/nps_fr.png',
            },
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        ranking: {
            contentText: ['SURVEYS.ITEMS.RANKING.TOOLTIP_CONTENT.PARAGRAPH_1'],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        scale: {
            contentText: ['SURVEYS.ITEMS.SCALE.TOOLTIP_CONTENT.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/scale_en.png',
                fr_CA: 'assets/images/question-tooltips/scale_fr.png',
            },
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        slider: {
            contentText: ['SURVEYS.ITEMS.SLIDER.TOOLTIP_CONTENT.PARAGRAPH_1'],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        textarea: {
            contentText: ['SURVEYS.ITEMS.TEXTAREA.TOOLTIP_CONTENT.PARAGRAPH_1'],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        video_input: {
            contentText: [
                'SURVEYS.ITEMS.VIDEO_INPUT.TOOLTIP_CONTENT.PARAGRAPH_1',
                'SURVEYS.ITEMS.VIDEO_INPUT.TOOLTIP_CONTENT.PARAGRAPH_2',
            ],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        text: {
            contentText: ['SURVEYS.ITEMS.TEXT.TOOLTIP_CONTENT.PARAGRAPH_1'],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        image: {
            contentText: ['SURVEYS.ITEMS.GRAPHIC.TOOLTIP_CONTENT.PARAGRAPH_1'],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        group: {
            contentText: ['SURVEYS.ITEMS.GROUP.TOOLTIP_CONTENT.PARAGRAPH_1'],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        video: {
            contentText: ['SURVEYS.ITEMS.VIDEO.TOOLTIP_CONTENT.PARAGRAPH_1'],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        hidden_input: {
            contentText: ['SURVEYS.ITEMS.HIDDEN_INPUT.TOOLTIP_CONTENT.PARAGRAPH_1'],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
        number: {
            contentText: ['SURVEYS.ITEMS.DIGIT.TOOLTIP_CONTENT.PARAGRAPH_1'],
            btnText: 'SURVEYS.ITEMS.ADD_TO_SURVEY',
        },
    };

    public constructor(
        private accessService: IAccessService,
        private translate: ng.translate.ITranslateService,
        private timeout: ng.ITimeoutService,
        private surveyService: SurveyService,
        private panelService: IPanelService,
        private siteNavService: ISiteNavigationService
    ) {}

    public openQuestionTooltip(
        libraryGroupItem: IElementLibraryGroupItem,
        event: any,
        featureLockedConditions?: Array<IFeatureLockCondition>
    ): void {
        this.cancelExistingQuestionTooltips();

        if (this.shouldShowTooltip(libraryGroupItem.type)) {
            this.openTimer = this.timeout(() => {
                if (!this.mdPanelRef) {
                    const questionConfig: ITooltipPanelLocals = this.getQuestionConfig(
                        libraryGroupItem,
                        featureLockedConditions
                    );
                    this.panelService
                        .openTooltipPanel(TooltipPanel.instantiate(event.target, questionConfig))
                        .then((ref) => {
                            this.mdPanelRef = ref;
                        });
                }
            }, 1500);
        }
    }

    public hideQuestionTooltip(): void {
        this.timeout(() => {
            const hoveredList: NodeListOf<Element> = document.querySelectorAll('.its-tooltip-panel--container:hover');
            if (hoveredList.length === 0) {
                this.closeTooltipPanel();
            }
        }, 300);
    }

    public closeTooltipPanel(): void {
        if (this.mdPanelRef) {
            this.mdPanelRef.close().then(() => {
                this.mdPanelRef.destroy();
                this.mdPanelRef = null;
            });
        }
    }

    public isFeatureLocked(featureLockedConditions?: Array<IFeatureLockCondition>): boolean {
        return !new FeatureLockUtils(this.accessService).checkConditions(featureLockedConditions);
    }

    public getQuestionConfig(
        libraryGroupItem: IElementLibraryGroupItem,
        featureLockedConditions?: Array<IFeatureLockCondition>
    ): ITooltipPanelLocals {
        if (this.questionTooltipConfigs[libraryGroupItem.type]) {
            const questionConfig: IQuestionTooltipConfig = this.questionTooltipConfigs[libraryGroupItem.type];
            const tooltipConfigLocals: ITooltipPanelLocals = { contentHtml: null };
            const isLocked: boolean = this.isFeatureLocked(featureLockedConditions);

            tooltipConfigLocals.titleIcon = libraryGroupItem.icon;
            tooltipConfigLocals.title = libraryGroupItem.label;

            this.buildHtmlContent(questionConfig, tooltipConfigLocals);
            this.getBtnOnClickCallback(libraryGroupItem, tooltipConfigLocals, isLocked);
            this.setBtnProperties(questionConfig, tooltipConfigLocals, isLocked);

            return tooltipConfigLocals;
        } else {
            throw new Error('Could not find question tooltip config for question type.');
        }
    }

    public cancelExistingQuestionTooltips(): void {
        if (this.openTimer) {
            this.timeout.cancel(this.openTimer);
        }

        this.hideQuestionTooltip();
    }

    private shouldShowTooltip(questionType: string): boolean {
        return _.indexOf(this.noTooltipRequiredList, questionType) === -1;
    }

    private getBtnOnClickCallback(
        libraryGroupItem: IElementLibraryGroupItem,
        tooltipConfigLocals: ITooltipPanelLocals,
        isLocked: boolean
    ): void {
        if (isLocked) {
            tooltipConfigLocals.btnOnClick = () => {
                this.siteNavService.goToOrgSubscriptions();
            };
        } else {
            tooltipConfigLocals.btnOnClick = () => {
                this.surveyService.startQuestionEdit(libraryGroupItem);
            };
        }
    }

    private setBtnProperties(
        questionConfig: IQuestionTooltipConfig,
        tooltipConfigLocals: ITooltipPanelLocals,
        isLocked: boolean
    ): void {
        if (isLocked) {
            tooltipConfigLocals.btnText = this.translate.instant(this.defaultLockedBtnText) || null;
            tooltipConfigLocals.btnClass = this.defaultLockedBtnClass;
        } else {
            tooltipConfigLocals.btnText = this.translate.instant(questionConfig.btnText) || null;
        }
    }

    private buildHtmlContent(questionConfig: IQuestionTooltipConfig, tooltipConfigLocals: ITooltipPanelLocals): void {
        tooltipConfigLocals.contentHtml = '';

        this.addContectTextToContentHtml(questionConfig, tooltipConfigLocals);
        this.addImgToContentHtml(questionConfig, tooltipConfigLocals);
    }

    private addContectTextToContentHtml(
        questionConfig: IQuestionTooltipConfig,
        tooltipConfigLocals: ITooltipPanelLocals
    ): void {
        for (const value of questionConfig.contentText) {
            tooltipConfigLocals.contentHtml +=
                '<div class="its-text--small its-text__muted its-margin-topbot--not-first">' +
                this.translate.instant(value) +
                '</div>';
        }
    }

    private addImgToContentHtml(
        questionConfig: IQuestionTooltipConfig,
        tooltipConfigLocals: ITooltipPanelLocals
    ): void {
        let imageSrc: string = null;

        if (questionConfig.imageUrl) {
            imageSrc = questionConfig.imageUrl[this.translate.use()]
                ? questionConfig.imageUrl[this.translate.use()]
                : questionConfig.imageUrl.en || null;
        }

        if (imageSrc) {
            tooltipConfigLocals.contentHtml += '<p><b>' + this.translate.instant('SURVEYS.ITEMS.EXAMPLE') + ':</b></p>';
            tooltipConfigLocals.contentHtml += '<div><img src="' + imageSrc + '" /></div>';
        }
    }
}
