import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { SurveyService } from '../../services/SurveyService';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IPageService } from '../../services/PageService';
import { ISurveyApi } from '../../api/SurveyApi';
import { BaseSurvey } from './BaseSurvey';
import { SurveyDeleteModal } from './modals/SurveyDeleteModal';
import { SurveyHistoryModal } from './modals/SurveyHistoryModal';
import { ISurvey, Survey } from '../../domain/surveys/survey/Survey';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { SurveyCopyModal } from './modals/SurveyCopyModal';
import { QueryFilter, IQueryFilter } from '@intouch/its.essential/app/essential/domain/api/QueryFilter';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';
import { IFilterService } from '@intouch/its.essential/app/essential/domain/filters/FilterService';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';

/**
 * Form edit Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.surveys', EditController.IID, EditController)
class EditController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.EditController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        '$stateParams',
        'itsSurveyService',
        'iteToaster',
        'itsPageService',
        'itsSurveyApi',
        '$scope',
        'iteUserLocalTimeService',
        '$mdMedia',
        '$translate',
        'itePanelService',
        'iteIqlRecordDateOfDocumentFilterService',
    ];

    public activeState: string;
    public childState: string;
    public searchText: string;
    public translations: any;
    public isEditingItem: boolean;

    constructor(
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: SurveyService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected scope: ng.IScope,
        protected userLocalTimeService: IUserLocalTimeService,
        private mdMedia: ng.material.IMedia,
        private translate: ng.translate.ITranslateService,
        private panelService: IPanelService,
        public dateFilterService: IFilterService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );

        this.translate([
            'GENERAL.DELETE',
            'GENERAL.CANCEL',
            'SURVEYS.DELETE.DELETE_SUCCESS',
            'SURVEYS.DELETE.DELETE_FAIL',
            'SURVEYS.DELETE_DRAFT.TITLE',
            'SURVEYS.DELETE_DRAFT.DESCRIPTION',
            'SURVEYS.DELETE_DRAFT.DELETE_SUCCESS',
            'SURVEYS.DELETE_DRAFT.DELETE_FAIL',
        ]).then((translations: any) => {
            this.translations = translations;
        });

        if (this.stateService.params['childState']) {
            this.childState = 'home.surveys.edit.' + this.stateService.params['childState'];
        }

        const editingItemSubscription: SubjectSubscription = this.surveyService.editingItem.subscribe((editingItem) => {
            this.isEditingItem = !!editingItem;
        });
        editingItemSubscription.unsubscribeOnScopeDestruction(this.scope);

        const surveySubscription: SubjectSubscription = this.surveyService.surveyValue.subscribe((survey) => {
            if (survey) {
                if (this.surveyHasNewUuid(survey)) {
                    this.updateSurveyUrlUuid(survey.uuid);
                }
            }
        });
        surveySubscription.unsubscribeOnScopeDestruction(this.scope);

        scope.$on('$stateChangeSuccess', () => {
            this.activeState = this.buildActiveStateFromRoute();
        });

        scope.$on('$destroy', () => {
            this.surveyService.setSurvey(null);
            this.surveyService.setEditingItem(null);
        });
    }

    public openDateFilterPanel(event: any): void {
        this.panelService.openDateFilterPanel({
            relativeTo: event.target,
            path: 'meta->date_of_document',
            filterService: this.dateFilterService,
        });
    }

    public isResultsTab(): boolean {
        return (<any>this.stateService.$current).name === 'home.surveys.edit.results';
    }

    /**
     * Handles nested routes of distribution to always highlight the
     * correct tab even on page refresh within a child route
     *
     * @returns {string}
     */
    public buildActiveStateFromRoute(): string {
        const state: string = this.stateService.current.name;

        if (state.indexOf('home.surveys.edit.distributions') > -1) {
            return 'home.surveys.edit.distributions';
        } else if (state.indexOf('home.surveys.edit.settings') > -1) {
            return 'home.surveys.edit.settings';
        } else {
            return state;
        }
    }

    public publishSurvey(): void {
        if (!(this.survey.isPublished() || this.publishing)) {
            this.publish(this.survey);
        }
    }

    public getPublishButtonClass(): string {
        if (this.mdMedia('xs')) {
            return 'md-primary md-icon-button';
        }
    }

    public openHistoryModal(): void {
        this.dialog.show(SurveyHistoryModal.instantiate({ locals: { surveyUuid: this.survey.originalUuid } }));
    }

    public print(): void {
        window.print();
    }

    public showPrint(): boolean {
        const state: string = this.stateService.current.name;
        return state.indexOf('home.surveys.edit.build.details') > -1;
    }

    public getDistributionState(): string {
        const state: string = this.stateService.current.name;

        if (state.indexOf('home.surveys.edit.distributions') > -1) {
            return state;
        }

        return null;
    }

    public getSettingState(): string {
        const state: string = this.stateService.current.name;

        if (state.indexOf('home.surveys.edit.settings') > -1) {
            return state;
        }

        return null;
    }

    /**
     * Show confirmation modal and if confirmed, delete the specified survey and reload the list
     * of surveys
     *
     * @param {ISurvey} survey
     */
    public remove(survey: ISurvey): void {
        this.dialog.show(SurveyDeleteModal.instantiate()).then((response: boolean) => {
            if (response) {
                const type: string = survey.status === 'published' ? 'revision' : 'original';
                this.surveyApi
                    .deleteSurvey(survey.uuid, type)
                    .then(() => {
                        this.toaster.success('SURVEYS.DELETE.DELETE_SUCCESS');
                    })
                    .catch(() => {
                        this.toaster.error('SURVEYS.DELETE.DELETE_FAIL');
                    })
                    .finally(() => {
                        this.stateService.go('home.surveys.listing');
                    });
            }
        });
    }

    /**
     * Show confirmation modal and if confirmed, delete the draft version of the survey and reload the list
     * of surveys
     *
     * @param {ISurvey} survey
     */
    public removeDraft(survey: ISurvey): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translations['SURVEYS.DELETE_DRAFT.TITLE'],
                        description: this.translations['SURVEYS.DELETE_DRAFT.DESCRIPTION'],
                        confirmText: this.translations['GENERAL.DELETE'],
                        cancelText: this.translations['GENERAL.CANCEL'],
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((response: boolean) => {
                if (response) {
                    const originalUuid: string = survey.originalUuid;
                    this.surveyApi
                        .deleteSurvey(survey.uuid)
                        .then(() => {
                            this.toaster.success('SURVEYS.DELETE_DRAFT.DELETE_SUCCESS');

                            // use originalUuid from draft to get and reload the previously published version of the survey
                            const queryFilter: IQueryFilter = new QueryFilter();
                            queryFilter.addParam('type', 'original');
                            queryFilter.addParam('filter[status]', 'published');
                            this.surveyApi
                                .findSurveyByUuid(originalUuid, queryFilter)
                                .then((prevSurvey: ISurvey) => {
                                    this.stateService.go('home.surveys.edit.build.details', { uuid: prevSurvey.uuid });
                                })
                                .catch(() => {
                                    this.stateService.go('home.surveys.listing');
                                });
                        })
                        .catch(() => {
                            this.toaster.error('SURVEYS.DELETE_DRAFT.DELETE_FAIL');
                        });
                }
            });
    }

    /**
     * Copy a survey
     *
     * @param {ISurvey} survey
     */
    public copy(survey: ISurvey): void {
        this.dialog
            .show(
                SurveyCopyModal.instantiate({
                    locals: {
                        subjectSurvey: survey,
                    },
                })
            )
            .then((newSurvey: ISurvey) => {
                this.stateService.go('home.surveys.edit.build.details', { uuid: newSurvey.uuid });
            });
    }

    private surveyHasNewUuid(survey: Survey): boolean {
        return this.stateService.params['uuid'] !== survey.uuid;
    }

    private updateSurveyUrlUuid(newSurveyUuid: string): void {
        this.stateService.go(
            this.stateService.current.name,
            {
                uuid: newSurveyUuid,
            },
            {
                notify: false,
                reload: false,
                location: 'replace',
            }
        );
    }
}
