import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { IPageService } from '../../../../services/PageService';
import { BaseSurvey } from '../../BaseSurvey';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ISurveyService } from '../../../../services/SurveyService';
import { UpsellTool } from '@intouch/its.essential/app/essential/tools/UpsellTool';
import { ISiteNavigationService } from '@intouch/its.essential/app/essential/services/SiteNavigationService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';

@Controller(
    'its.survey.module.surveys.distributions',
    DistributionSocialMediaController.IID,
    DistributionSocialMediaController
)
class DistributionSocialMediaController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.distributions.DistributionSocialMediaController';
    static $inject: Array<string> = [
        'APPCONFIG',
        '$state',
        '$mdDialog',
        '$stateParams',
        'itsSurveyService',
        'iteToaster',
        'itsPageService',
        'itsSurveyApi',
        '$scope',
        'iteAccessService',
        'iteSiteNavigationService',
        'iteUserLocalTimeService',
    ];

    /**
     * Pinterest is an outlier, their API throws the following if you DON'T give it the socialshare-media property
     *      You need to upload an image or provide the 'image_url' parameter
     * So giving it a media URL and some description text makes it work.
     */
    public socialProviders: Array<{
        provider: string;
        label: string;
        icon: string;
        text: string;
        media: string;
        class: string;
    }> = [
        {
            provider: 'facebook',
            label: 'Facebook',
            icon: 'fa-facebook-f',
            text: '',
            media: '',
            class: 'its-distributions--facebook',
        },
        {
            provider: 'twitter',
            label: 'Twitter',
            icon: 'fa-twitter',
            text: '',
            media: '',
            class: 'its-distributions--twitter',
        },
        {
            provider: 'linkedin',
            label: 'Linkedin',
            icon: 'fa-linkedin-square',
            text: '',
            media: '',
            class: 'its-distributions--linked-in',
        },
        {
            provider: 'pinterest',
            label: 'Pinterest',
            icon: 'fa-pinterest',
            text: 'Take our online survey!',
            media: 'https://www.intouchsurvey.com/assets/images/social_share.jpg',
            class: 'its-distributions--pinterest',
        },
    ];

    public upsellTool: UpsellTool = null;

    constructor(
        private config: any,
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected scope: ng.IScope,
        private accessService: IAccessService,
        private siteNavService: ISiteNavigationService,
        protected userLocalTimeService: IUserLocalTimeService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );

        this.upsellTool = new UpsellTool(this.accessService, this.siteNavService).setStateByProductTiers('survey', [
            'grow',
            'scale',
            'lead',
        ]);
        if (!this.upsellTool.showUpsell()) {
            this.load();
        }
    }

    /**
     * Retrieves the public facing URL of the survey
     *
     * @param {boolean} urlEncoded
     * @returns {string}
     */
    public getSurveyUrl(urlEncoded: boolean = true): string {
        const url: string = this.surveyService.getSurveyUrl(this.survey) + '/' + this.survey.originalUuid;
        return urlEncoded ? encodeURIComponent(url) : url;
    }
}
