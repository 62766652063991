import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ISurveyApi } from '../../../api/SurveyApi';
import { IPageService } from '../../../services/PageService';
import { ISurveyService } from '../../../services/SurveyService';
import { ISubNavMenuItem } from '@intouch/its.essential/app/essential/components/SubNavPanel';

@Controller('its.survey.module.surveys.distributions', DistributionsController.IID, DistributionsController)
class DistributionsController {
    static IID: string = 'its.survey.module.surveys.distributions.DistributionsControllerNew';
    static $inject: Array<string> = [
        'iteAccessService',
        '$state',
        '$mdDialog',
        '$stateParams',
        'itsSurveyService',
        'iteToaster',
        '$mdMedia',
        'itsPageService',
        'itsSurveyApi',
        '$scope',
    ];

    public activeTab: string;
    public navExpanded: boolean = false;
    private menuItems: ISubNavMenuItem[];

    constructor(
        private accessService: IAccessService,
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected media: ng.material.IMedia,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected scope: ng.IScope
    ) {
        this.menuItems = [
            {
                state: 'home.surveys.edit.distributions.link',
                title: 'DISTRIBUTIONS.LINK.TITLE',
                icon: 'link',
                stateName: 'link',
            },
            {
                state: 'home.surveys.edit.distributions.website',
                title: 'DISTRIBUTIONS.WEBSITE.TITLE',
                icon: 'devices',
                stateName: 'website',
                featureLockConditions: [
                    {
                        product: 'survey',
                        unlockTiers: ['grow', 'scale', 'lead'],
                    },
                ],
            },
            {
                state: 'home.surveys.edit.distributions.qr-code',
                title: 'DISTRIBUTIONS.QRCODE.TITLE',
                icon: 'center_focus_strong',
                stateName: 'qr-code',
                featureLockConditions: [
                    {
                        product: 'survey',
                        unlockTiers: ['grow', 'scale', 'lead'],
                    },
                ],
            },
            {
                state: 'home.surveys.edit.distributions.social-media',
                title: 'DISTRIBUTIONS.SOCIAL.TITLE',
                icon: 'share',
                stateName: 'social-media',
                featureLockConditions: [
                    {
                        product: 'survey',
                        unlockTiers: ['grow', 'scale', 'lead'],
                    },
                ],
            },
            {
                state: 'home.surveys.edit.distributions.email',
                title: 'DISTRIBUTIONS.EMAIL.TITLE',
                icon: 'email',
                stateName: 'email',
                featureLockConditions: [
                    {
                        product: 'survey',
                        unlockProductFeatures: ['email-distributions'],
                    },
                ],
            },
            {
                state: 'home.surveys.edit.distributions.sms',
                title: 'DISTRIBUTIONS.SMS.TITLE',
                icon: 'sms',
                stateName: 'sms',
                featureLockConditions: [
                    {
                        product: 'survey',
                        unlockProductFeatures: ['sms-distributions'],
                    },
                ],
            },
            {
                state: 'home.surveys.edit.distributions.health',
                title: 'GENERAL.FORECAST',
                icon: 'local_hospital',
                stateName: 'health',
            },
        ];

        // if we have a title (handles page reload) use it
        if (
            this.stateService.current.hasOwnProperty('data') &&
            this.stateService.current.data.hasOwnProperty('activeTab')
        ) {
            this.setActiveTab((<any>this.stateService.current).activeTab);
            this.scope.$on('$stateChangeSuccess', (event, toState) => {
                if (toState.hasOwnProperty('data') && toState.data.hasOwnProperty('activeTab')) {
                    this.setActiveTab(toState.data.activeTab);
                }
            });
        }
    }

    public navIsExpanded(): boolean {
        return this.media('gt-sm') || this.navExpanded;
    }

    /**
     * Set the active tab
     *
     * @param {string} tab
     */
    public setActiveTab(tab: string): void {
        this.activeTab = tab;
    }
}
