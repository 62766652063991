import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { SurveyChartBuilder } from '../domain/surveys/survey/SurveyChartBuilder';
import { IIqlQueryResultSeries } from '@intouch/iql-ts-sdk/src/domain/IqlQueryResult';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import * as _ from 'lodash';

declare let Highcharts: any;

@Service('its.survey.services', SurveyChartService.IID, SurveyChartService)
export class SurveyChartService {
    static IID: string = 'itsSurveyChartService';
    static $inject: Array<string> = ['$translate', '$mdMedia', '$timeout'];

    public constructor(
        private translate: ng.translate.ITranslateService,
        private media: ng.material.IMedia,
        private timeout: ng.ITimeoutService
    ) {}

    public getSubmissionsChartByDateFilter(
        data: { submissions: IIqlQueryResultSeries; views: IIqlQueryResultSeries },
        dateTimestamps: { start: number; end: number },
        dateIntervalPeriod: string
    ): any {
        const renderTarget: string = 'distribution-overview-chart';
        const startDateValue: number = dateTimestamps.start;
        const endDateValue: number = dateTimestamps.end;
        const submissionsText: string = this.translate.instant('SURVEYS.SUMMARY.SURVEY_SUBMISSIONS_BREAKDOWN');
        const countText: string = this.translate.instant('GENERAL.COUNT');
        const chartTitleDatePeriod: string = this.translate.instant(
            'ESSENTIAL.GENERAL.DATES.' + dateIntervalPeriod.toUpperCase()
        );
        const surveyViewsText: string = this.translate.instant('SURVEYS.SUMMARY.SURVEY_VIEWS');
        const surveySubmissionsText: string = this.translate.instant('SURVEYS.SUMMARY.SURVEY_SUBMISSIONS');
        const completionRateText: string = this.translate.instant('SURVEYS.SUMMARY.COMPLETION_RATE');

        let dates: Array<number> = [];
        let submissionsData: Array<{ x: string | number; y: string | number }> = [];
        let viewsData: Array<{ x: string | number; y: string | number }> = [];

        if (data.submissions) {
            submissionsData = !data.submissions.hasData()
                ? []
                : data.submissions.data.map((value) => {
                      if (dates.indexOf(<number>value.x) === -1) {
                          dates.push(<number>value.x);
                      }
                      return {
                          x: value.x,
                          y: value.y,
                      };
                  });
        }

        if (data.views) {
            viewsData = !data.views.hasData()
                ? []
                : data.views.data.map((value) => {
                      if (dates.indexOf(<number>value.x) === -1) {
                          dates.push(<number>value.x);
                      }
                      return {
                          x: value.x,
                          y: value.y,
                      };
                  });
        }

        if (dates.length > 0) {
            dates = dates.sort();
        }

        const completionData: Array<{ x: number; y: number }> =
            dates.length === 0
                ? []
                : dates.map((value) => {
                      const submissionValue: any = _.find(submissionsData, { x: value });
                      const viewsValue: any = _.find(viewsData, { x: value });
                      const numerator: number = submissionValue ? submissionValue.y || 0 : 0;
                      const denominator: number = viewsValue ? viewsValue.y || 0 : 0;

                      const yValue: any = denominator ? (numerator / denominator) * 100 : 0;

                      return {
                          x: value,
                          y: yValue > 100 ? 100 : yValue,
                      };
                  });

        Highcharts.chart(
            renderTarget,
            {
                chart: {
                    type: 'column',
                },
                time: {
                    useUTC: false,
                },
                credits: {
                    enabled: false,
                },
                title: {
                    text: submissionsText,
                    style: {
                        fontFamily: 'Roboto,sans-serif',
                        fontSize: '20px',
                    },
                },
                subtitle: {
                    text: chartTitleDatePeriod,
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        format: this.getFormatFromInterval(dateIntervalPeriod),
                    },
                    min: startDateValue,
                    max: endDateValue,
                    tickInterval: this.getTickIntervalFromPeriod(dateIntervalPeriod),
                },
                tooltip: {
                    xDateFormat: '%A, %b %e',
                },
                yAxis: [
                    {
                        min: 0,
                        title: {
                            text: countText,
                        },
                        allowDecimals: false,
                    },
                    {
                        title: {
                            text: completionRateText,
                        },
                        max: 100,
                        min: 0,
                        alignTicks: false,
                        opposite: true,
                        labels: {
                            format: '{value: 2f}%',
                        },
                    },
                ],
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false,
                        },
                        maxPointWidth: 25,
                    },
                    spline: {
                        marker: {
                            enabled: false,
                        },
                        lineWidth: 4,
                    },
                },
                legend: {
                    symbolSquare: true,
                    symbolRadius: 2,
                },
                series: [
                    {
                        name: surveyViewsText,
                        color: '#bae6f4',
                        legendIndex: 1,
                        animation: false,
                        data: viewsData,
                    },
                    {
                        name: surveySubmissionsText,
                        color: '#4EC8EF',
                        legendIndex: 0,
                        animation: false,
                        data: submissionsData,
                    },
                    {
                        name: completionRateText,
                        color: '#03B04C',
                        yAxis: 1,
                        legendIndex: 2,
                        animation: false,
                        type: 'spline',
                        data: completionData,
                        tooltip: {
                            valueDecimals: 0,
                            valueSuffix: '%',
                        },
                    },
                ],
            },
            (chart) => {
                this.timeout(() => {
                    chart.reflow();
                });
            }
        );
    }

    public getDistributionMetricsPieChart(renderTargetId: string): any {
        const chartBuilder: SurveyChartBuilder = new SurveyChartBuilder();
        const legendLabelFormatter: () => string = function (): string {
            if (this.y !== undefined && this.y !== null) {
                return (
                    this.y +
                    ' ' +
                    this.name +
                    ' <span style="fill:#757575">(' +
                    NumberUtils.round(this.percentage, 1) +
                    '%)</span>'
                );
            } else {
                return this.name;
            }
        };

        const plotOptions: any = {
            pie: {
                allowPointSelect: false,
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f}%',
                    connectorWidth: 0,
                    connectorPadding: -10,
                },
                showInLegend: true,
            },
        };

        const legendAlignment: 'bottom' | 'right' = this.media('xs') || this.media('sm') ? 'bottom' : 'right';
        const legendVerticalAlignment: 'bottom' | 'middle' = this.media('xs') || this.media('sm') ? 'bottom' : 'middle';
        chartBuilder
            .setRenderTo(renderTargetId)
            .setChartType('pie')
            .setTitle(null)
            .hideAxes()
            .setLegendLayout('vertical')
            .setLegendAlign(legendAlignment)
            .setLegendFormatter(legendLabelFormatter)
            .setLegendVerticalAlign(legendVerticalAlignment)
            .setPlotOptions(plotOptions)
            .setColors(['#FF4949', '#03B04C', '#FFBB33']);
        return chartBuilder.renderChart();
    }

    private getFormatFromInterval(dateIntervalPeriod: string): any {
        if (dateIntervalPeriod === 'today' || dateIntervalPeriod === 'yesterday') {
            return '{value:%H:%M}';
        }
        if (dateIntervalPeriod === 'custom_range') {
            // allow HighChart to use its default date format
            return '';
        }

        return '{value:%Y-%m-%d}';
    }

    private getTickIntervalFromPeriod(period: string): any {
        if (period === 'week_to_date' || period === 'month_to_date' || period === 'past_week') {
            return 24 * 3600 * 1000;
        }
        return undefined;
    }
}
