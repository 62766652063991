import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { DistributionTriggerCondition, IDistributionTriggerCondition } from './DistributionTriggerCondition';

import * as _ from 'lodash';

export interface IDistributionTrigger extends IEntity {
    conditions: Array<IDistributionTriggerCondition>;
}

export class DistributionTrigger extends Entity implements IDistributionTrigger {
    public conditions: Array<IDistributionTriggerCondition> = [new DistributionTriggerCondition()];

    public fromJson(jsonObject: any, convert: boolean): IDistributionTrigger {
        if (jsonObject.conditions && jsonObject.conditions.length) {
            this.conditions = EntityBuilder.buildMany<IDistributionTriggerCondition>(
                DistributionTriggerCondition,
                jsonObject.conditions,
                convert
            );
        }

        return this;
    }

    public addCondition(): void {
        this.conditions.push(new DistributionTriggerCondition());
    }

    public removeCondition(condition: IDistributionTriggerCondition): void {
        _.remove(this.conditions, condition);
    }
}
