import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IPager } from '@intouch/its.essential/app/essential/domain/Pager';
import * as _ from 'lodash';
import { IContactList } from '../../../domain/contact-center/ContactList';
import { ISurveyApi } from '../../../api/SurveyApi';

/**
 * Content Filter Panel controller class
 *
 * @constructor
 */
@Controller('its.survey.components', ContactListPickerPanelController.IID, ContactListPickerPanelController)
class ContactListPickerPanelController {
    static IID: string = 'ContactListPickerPanelController';
    static $inject: Array<string> = ['mdPanelRef', 'itsSurveyApi', '$scope'];

    public items: Array<IContactList> = [];
    public search: string = null;
    public pager: IPager = null;
    public loading: boolean = false;
    public loadingMore: boolean = false;
    public error: boolean = false;

    // locals passed from caller
    public multiSelect: boolean;
    public maxWidth: string;
    public showClose: boolean;
    public preSelect: Array<string>;
    private onSelect: (item: IContactList) => void;
    private onDeselect: (item: IContactList) => void;

    private debouncedSearch: () => void = _.debounce(this.loadItems, 300, { trailing: true });

    public constructor(private mdPanelRef: any, private surveyApi: ISurveyApi, private scope: ng.IScope) {
        this.preSelect = this.preSelect || [];
        this.loadItems().finally(() => {
            this.scope.$watch(
                () => {
                    return this.search;
                },
                () => {
                    if (
                        this.scope['iteContactListPickerForm'] &&
                        this.scope['iteContactListPickerForm'].$dirty &&
                        !this.loading &&
                        !this.loadingMore
                    ) {
                        this.debouncedSearch();
                    }
                }
            );
        });
    }

    /**
     * Close the panel
     *
     */
    public close(): void {
        this.mdPanelRef.close().then(() => {
            this.mdPanelRef.destroy();
        });
    }

    public clearSearch(): void {
        this.search = null;
        this.loadItems();
    }

    public select(item: IContactList): void {
        const index: number = this.preSelect.indexOf(item.uuid);
        if (index === -1) {
            this.preSelect.push(item.uuid);
            if (this.onSelect) {
                this.onSelect(item);
            }
            if (!this.multiSelect) {
                this.close();
            }
        } else if (this.multiSelect) {
            this.preSelect.splice(index, 1);
            if (this.onDeselect) {
                this.onDeselect(item);
            }
        }
        item['selected'] = !item['selected'];
    }

    public loadMore(): void {
        if (this.pager && this.pager.canGoNext()) {
            this.pager.currentPage++;
            this.loadItems(true);
        }
    }

    public isFullScreen(): boolean {
        return this.mdPanelRef.config.fullscreen;
    }

    private loadItems(concat: boolean = false): ng.IPromise<any> {
        this.error = false;

        if (!concat) {
            this.items = [];
            this.loading = true;
            this.pager = null;
        } else {
            this.loadingMore = true;
        }

        return this.surveyApi
            .findContactLists(this.pager, this.search)
            .then((results: PagedEntities) => {
                const items: Array<IContactList> = results.getEntities().map((i) => {
                    if (this.preSelect.indexOf(i.uuid) > -1) {
                        i['selected'] = true;
                    }
                    return i;
                });
                this.items = concat ? (this.items || []).concat(items) : items;
                this.pager = results.getPager();
            })
            .catch(() => {
                this.error = true;
            })
            .finally(() => {
                this.loading = false;
                this.loadingMore = false;
            });
    }
}
