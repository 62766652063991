import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { BaseItem } from './BaseItem';
import { BaseResponseItemSettings } from './BaseResponseItem';

export class HiddenInputSettings extends BaseResponseItemSettings {
    urlParam: string = null;
    defaultValue: string = null;
}

export class HiddenInput extends BaseItem {
    type: string = 'hidden_input';
    settings: HiddenInputSettings = new HiddenInputSettings();

    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, convertToCamel);
        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<HiddenInputSettings>(HiddenInputSettings, jsonObject.settings, true);
        }
        return this;
    }
}
