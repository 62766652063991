import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Matrix } from '../../../../../../domain/surveys/items/Matrix';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';
import { ItemSettingsTab } from '../../shared/item-settings-tabs/ItemSettingsTabs';

@Component('its.survey.module.surveys', MatrixSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/response-items/matrix/MatrixSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: MatrixSettingsComponent.getBindings(),
})
class MatrixSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'MatrixSettingsComponent';
    static CID: string = 'itsMatrixSettings';
    static $inject: string[] = ['$translate'];

    public item: Matrix;
    public tabs: ItemSettingsTab[] = ['basic', 'advanced', 'points'];
}
