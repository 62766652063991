import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { BaseItem, IBaseItem } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { IHelpTextSettings, IHelpTextTranslation } from './shared/HelpText';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { ITranslatedEntity } from '../../ITranslatedEntity';

export type IVideoInputTranslation = IHelpTextTranslation;

export class VideoInputTranslation extends Translation implements IVideoInputTranslation {
    additionalText: string = null;
}

/**
 * The VideoInput settings interface
 */
export interface IVideoInputSettings extends IBaseSettings, IHelpTextSettings<IVideoInputTranslation> {
    rows: string;
    enableSentiment: boolean;
    enableTextEntry: boolean;
    maxRecordingLength: number;
}

/**
 * The VideoInput settings class
 */
export class VideoInputSettings extends BaseSettings implements IVideoInputSettings, ITranslatedEntity {
    rows: string = '5';
    enableSentiment: boolean = false;
    enableTextEntry: boolean = true;
    maxRecordingLength: number = 5;
    additionalText: string = null;
    translations: Array<IVideoInputTranslation> = [];

    public fromJson<VideoInputSettings>(jsonObject: any, convertToCamel?: boolean): IVideoInputSettings {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<IVideoInputTranslation>(
                VideoInputTranslation,
                jsonObject.translations,
                true
            );
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.additionalText) {
            keys.push('additionalText');
        }
        return keys;
    }

    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

/**
 * The VideoInput interface
 */
export type IVideoInput = IBaseItem;

/**
 * The VideoInput class
 */
export class VideoInput extends BaseItem implements IVideoInput {
    type: string = 'video_input';
    settings: IVideoInputSettings = new VideoInputSettings();

    @NotEnumerable
    _healthPoints: number = 5;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey. This overrides the BaseItem default value.
     */
    get healthPoints(): number {
        return this._healthPoints;
    }

    /**
     * Build Video Input question from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IVideoInput}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): this {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<VideoInputSettings>(
                VideoInputSettings,
                jsonObject.settings,
                convertToCamel
            );
        }

        return this;
    }
}
