import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', HorizontalAlignmentSettingComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/shared/selects/horizontal-alignment-setting/HorizontalAlignmentSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class HorizontalAlignmentSettingComponent {
    static IID: string = 'HorizontalAlignmentSettingComponent';
    static CID: string = 'itsHorizontalAlignmentSetting';
    static $inject: string[] = [];
}
