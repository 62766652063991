import { IEntity, Entity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface ISurveySms extends IEntity {
    name: string;
    message: string;

    isValid(): boolean;
}

export class SurveySms extends Entity implements ISurveySms {
    name: string = null;
    message: string = null;

    /**
     * Returns if all the required fields are there and if they pass validation
     *
     * @returns {boolean}
     */
    public isValid(): boolean {
        return this.message && this.message.length > 0;
    }
}
