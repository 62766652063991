import * as angular from 'angular';

const module: any = angular.module('its.survey.components', []);

// chips
import './chips/contact-list/ContactListChipsComponent';

// pickers
import './pickers/contact-list/ContactListPickerPanelController';

export default module;
