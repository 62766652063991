import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { VideoInput } from '../../../../../../domain/surveys/items/VideoInput';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';

@Component('its.survey.module.surveys', VideoInputSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/video-input/VideoInputSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: VideoInputSettingsComponent.getBindings(),
})
class VideoInputSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'VideoInputSettingsComponent';
    static CID: string = 'itsVideoInputSettings';
    static $inject: string[] = [];

    public item: VideoInput;
}
