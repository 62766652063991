import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Slider } from '../../../../../../domain/surveys/items/Slider';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';
import { ItemSettingsTab } from '../../shared/item-settings-tabs/ItemSettingsTabs';

@Component('its.survey.module.surveys', SliderSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/slider/SliderSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: SliderSettingsComponent.getBindings(),
})
class SliderSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'SliderSettingsComponent';
    static CID: string = 'itsSliderSettings';

    public item: Slider;
    public tabs: ItemSettingsTab[] = ['basic', 'advanced', 'points'];
}
