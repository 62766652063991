import { String } from '@intouch/its.essential/app/essential/utils/String';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import {
    BaseResponseItem,
    BaseResponseItemSettings,
    IBaseResponseItem,
    IBaseResponseItemSettings,
} from './BaseResponseItem';
import { IBaseResponse } from './BaseResponse';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { ILogic, Logic } from '../logic/Logic';
import { IBaseItem } from './BaseItem';
import { IHelpTextSettings, IHelpTextTranslation } from './shared/HelpText';
import { IWeightedTag } from './shared/WeightedTag';
import { ILogicReference, LogicOwner, LogicReference } from '../logic/LogicReference';
import { ITranslatedEntity } from '../../ITranslatedEntity';
import * as _ from 'lodash';

export interface IMatrixQuestion extends IEntity {
    uuid: string;
    points: number;
    type: string;
    label: string;
    translations: Array<ITranslation>;
    accessTags: Array<IWeightedTag>;
    logic: ILogic;
    logicReferences: Array<ILogicReference>;

    getTranslation(locale: string, createOnNotFound: boolean): ITranslation;
    updateTagWeight(tagUuid: string, weight: number): void;
    buildTagMap(tagObj: { [uuid: string]: IWeightedTag }): void;
    findTagByUuid(tagUuid: string): IWeightedTag;
}

export class MatrixQuestion extends Entity implements IMatrixQuestion, ITranslatedEntity {
    uuid: string = String.uuid();
    points: number = null;
    type: string = 'question';
    label: string = null;
    translations: Array<ITranslation> = [];
    accessTags: Array<IWeightedTag> = [];
    logic: ILogic = null;
    @NotEnumerable
    value: string = null;
    @NotEnumerable
    $$mdSelectId: number; /* tslint:disable-line */
    @NotEnumerable
    logicReferences: Array<ILogicReference> = [];

    /**
     * Retrieve the given translation for a locale
     *
     * @param {string} locale
     * @param {boolean} createOnNotFound
     * @returns {ITranslation}
     */
    public getTranslation(locale: string, createOnNotFound: boolean = true): ITranslation {
        return TranslationLocator.locate(this.translations, 'label', locale);
    }

    /**
     * Retrieve the translation keys available to this entity
     */
    public getTranslationKeys(): Array<string> {
        return ['label'];
    }

    /**
     * Update a specific tag's weight by uuid
     *
     * @param tagUuid
     * @param weight
     */
    public updateTagWeight(tagUuid: string, weight: number): void {
        for (const tag of this.accessTags) {
            if (tag.uuid === tagUuid) {
                tag.weight = weight;
            }
        }
    }

    /**
     * Builds tag map if the uuid of the tag is not already in the map
     *
     * @param tagObj
     */
    public buildTagMap(tagObj: { [uuid: string]: IWeightedTag }): void {
        for (const tag of this.accessTags) {
            if (!tagObj.hasOwnProperty(tag.uuid)) {
                tagObj[tag.uuid] = tag;
            }
        }
    }

    /**
     * Find tag by uuid
     *
     * @param tagUuid
     */
    public findTagByUuid(tagUuid: string): IWeightedTag {
        for (const tag of this.accessTags) {
            if (tag.uuid === tagUuid) {
                return tag;
            }
        }
    }

    public fromJson(jsonObject: any, convertToCamel?: boolean): IMatrixQuestion {
        super.fromJson(jsonObject, true);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ITranslation>(Translation, jsonObject.translations, true);
        }

        if (jsonObject.logic) {
            this.logic = EntityBuilder.buildOne<ILogic>(Logic, jsonObject.logic, true);
        }

        if (jsonObject.access_tags) {
            this.accessTags = jsonObject.access_tags;
        }

        return this;
    }
}

export type IMatrixTranslation = IHelpTextTranslation;

export class MatrixTranslation extends Translation implements IMatrixTranslation {
    additionalText: string = null;
}

export interface IMatrixSettings extends IBaseResponseItemSettings, IHelpTextSettings<IMatrixTranslation> {
    noMobileCollapse: boolean;
}

export class MatrixSettings extends BaseResponseItemSettings implements IMatrixSettings {
    noMobileCollapse: boolean = false;
    additionalText: string = null;
    translations: Array<IMatrixTranslation> = [];

    public fromJson<IMatrixSettings>(jsonObject: any, convertToCamel?: boolean): MatrixSettings {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<IMatrixTranslation>(
                MatrixTranslation,
                jsonObject.translations,
                true
            );
        }

        return this;
    }

    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

export interface IMatrix extends IBaseResponseItem {
    questions: Array<IMatrixQuestion>;
    responses: Array<IBaseResponse>;

    addQuestion(index?: number): void;
    removeQuestion(question: IMatrixQuestion): void;
    getQuestions(): Array<IMatrixQuestion>;
    buildLabelMap(): any;
    getQuestionCount(): number;
    getLogicItems(): Array<IBaseItem | IMatrixQuestion>;
    getQuestionByUuid(uuid: string): IMatrixQuestion;
    sortQuestionLabels(asc?: boolean): Array<IMatrixQuestion>;
}

export class Matrix extends BaseResponseItem implements IMatrix {
    type: string = 'matrix';
    points: number = null;
    settings: IMatrixSettings = new MatrixSettings();
    questions: Array<IMatrixQuestion> = [];
    responses: Array<IBaseResponse> = [];

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey.
     */
    get healthPoints(): number {
        return this.questions.length;
    }

    /**
     * Override the base init to add 3 questions/responses
     */
    public initialize(): void {
        this.questions = [];
        this.responses = [];
        this.addResponses(3);
        this.addQuestion();
        this.addQuestion();
        this.addQuestion();
    }

    /**
     * Add a matrix question
     *
     * @param {number} index
     */
    public addQuestion(index: number = -1): void {
        if (index === -1) {
            index = this.questions.length;
        }
        const newQuestion: IMatrixQuestion = new MatrixQuestion();
        this.questions.splice(index, 0, newQuestion);
    }

    /**
     * Get logic item and matrix question logic items
     * @returns {Array<ILogic>}
     */
    public getLogicItems(): Array<IBaseItem | IMatrixQuestion> {
        const logicArr: Array<IBaseItem | IMatrixQuestion> = [];
        if (this.logic) {
            logicArr.push(this);
        }

        if (this.questions.length > 0) {
            for (const question of this.questions) {
                if (question.logic) {
                    logicArr.push(question);
                }
            }
        }

        return logicArr;
    }

    /**
     * Duplicate current object and set a new UUID
     */
    public duplicate(): Matrix {
        const dupe: Matrix = super.duplicate() as Matrix;
        for (const question of dupe.questions) {
            question.uuid = String.uuid();
            question.logic = null;
            question.logicReferences = [];
        }

        return dupe;
    }

    /**
     * Returns if the uuid is used in defined logic
     *
     * @param itemUuid
     */
    public getAllLogicItemBelongsTo(itemUuid: string): Array<ILogicReference> {
        const allLogic: Array<ILogicReference> = [];
        if (this.logic) {
            for (const logicItem of this.logic.items) {
                if (logicItem.operand === itemUuid) {
                    allLogic.push(new LogicReference(new LogicOwner(this.uuid, this.label), this.logic));
                }
            }
        }

        for (const question of this.questions) {
            if (question.logic) {
                for (const logicItem of question.logic.items) {
                    if (logicItem.operand === itemUuid) {
                        allLogic.push(
                            new LogicReference(
                                new LogicOwner(question.uuid, this.label + ': ' + question.label),
                                question.logic
                            )
                        );
                    }

                    for (const logicCondition of logicItem.conditions) {
                        if (logicCondition.value === itemUuid) {
                            allLogic.push(
                                new LogicReference(
                                    new LogicOwner(question.uuid, this.label + ': ' + question.label),
                                    question.logic
                                )
                            );
                        }
                    }
                }
            }
        }

        for (const response of this.responses) {
            if (response.logic) {
                for (const logicItem of response.logic.items) {
                    if (logicItem.operand === itemUuid) {
                        allLogic.push(
                            new LogicReference(
                                new LogicOwner(response.uuid, this.label + ': ' + response.label),
                                response.logic
                            )
                        );
                    }
                    for (const logicCondition of logicItem.conditions) {
                        if (logicCondition.value === itemUuid) {
                            allLogic.push(
                                new LogicReference(
                                    new LogicOwner(this.uuid, this.label + ': ' + response.label),
                                    response.logic
                                )
                            );
                        }
                    }
                }
            }
        }

        return allLogic;
    }

    /**
     * Creates a string map of uuid and label
     *
     * @returns {any}
     */
    public buildLabelMap(): any {
        const mapObj: any = super.buildLabelMap();
        for (const question of this.questions) {
            mapObj[question.uuid] = question.label;
        }
        return mapObj;
    }

    /**
     * Remove a specific matrix question
     *
     * @param {IMatrixQuestion} question
     */
    public removeQuestion(question: IMatrixQuestion): void {
        const index: number = this.questions.indexOf(question);

        if (index > -1) {
            this.questions.splice(index, 1);
        }
    }

    /**
     * Get matrix questions
     *
     * @returns {Array<IMatrixQuestion>}
     */
    public getQuestions(): Array<IMatrixQuestion> {
        return this.questions;
    }

    /**
     * Return number of questions
     *
     * @returns {number}
     */
    public getQuestionCount(): number {
        return this.questions.length;
    }

    /**
     * Get a question by its UUID
     *
     * @param {string} uuid
     * @returns {IMatrixQuestion}
     */
    public getQuestionByUuid(uuid: string): IMatrixQuestion {
        for (const question of this.questions) {
            if (question && question.uuid === uuid) {
                return question;
            }
        }

        return null;
    }

    /**
     * Update questions tag weight
     *
     * @param tagUuid
     * @param weight
     */
    public updateTagWeight(tagUuid: string, weight: number): void {
        for (const question of this.questions) {
            question.updateTagWeight(tagUuid, weight);
        }
    }

    /**
     * Adds tags associated to questions
     *
     * @param tagObj
     */
    public buildTagMap(tagObj: { [uuid: string]: IWeightedTag }): void {
        for (const question of this.questions) {
            question.buildTagMap(tagObj);
        }
    }

    /**
     * Find access tag in questions by uuid
     *
     * @param tagUuid
     */
    public findTagByUuid(tagUuid: string): IWeightedTag {
        for (const question of this.questions) {
            const foundTag: IWeightedTag = question.findTagByUuid(tagUuid);
            if (foundTag) {
                return foundTag;
            }
        }
    }

    /**
     * Sort the questions by label asc|desc
     * @param asc
     */
    public sortQuestionLabels(asc: boolean = true): Array<IMatrixQuestion> {
        return (this.questions = _.orderBy(this.questions, ['label'], asc ? ['asc'] : ['desc']));
    }

    /**
     * Build the Matrix object from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IBaseResponseItem}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.questions) {
            this.questions = EntityBuilder.buildMany<IMatrixQuestion>(MatrixQuestion, jsonObject.questions, true);
        }

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<IMatrixSettings>(MatrixSettings, jsonObject.settings, true);
        }

        return this;
    }
}
