import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IIconDetails } from '../../../../../../../domain/surveys/items/shared/IconDetails';

@Component('its.survey.module.surveys', IconSettingComponent.CID, {
    template: require('/app/modules/surveys/components/items/shared/selects/icon-setting/IconSettingComponent.html'),
    controllerAs: 'vm',
    require: {
        ngModel: 'ngModel',
    },
    bindings: {
        label: '@',
        icons: '<',
        allowToggle: '<',
    },
})
class IconSettingComponent {
    static IID: string = 'IconSettingComponent';
    static CID: string = 'itsIconSetting';
    static $inject: string[] = [];

    public icons: IIconDetails;
    public ngModel: ng.INgModelController;
    public allowToggle: boolean;

    public selectedIconDetails: IIconDetails = null;

    public selectIcon(iconDetails: IIconDetails): void {
        if (this.allowToggle && iconDetails.value === this.ngModel.$modelValue) {
            this.ngModel.$setViewValue(null);
        } else {
            this.ngModel.$setViewValue(iconDetails.value);
        }
    }
}
