import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItem } from '../../../../../../domain/surveys/items/BaseItem';
import { SurveyService } from '../../../../../../services/SurveyService';
import { ILogic } from '../../../../../../domain/surveys/logic/Logic';

@Component('its.survey.module.surveys', ItemExtraInformationComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/shared/item-extra-information/ItemExtraInformationComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class ItemExtraInformationComponent {
    static IID: string = 'ItemExtraInformationComponent';
    static CID: string = 'itsItemExtraInformation';
    static $inject: string[] = ['itsSurveyService'];

    public item: BaseItem;

    public constructor(private surveyService: SurveyService) {}

    public editLogic(logic: ILogic, parentUuid: string): void {
        this.surveyService.editItemLogic(logic, parentUuid);
    }
}
