import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { TranslationLocator } from '../../TranslationLocator';
import { ITranslation, Translation } from '../../Translation';
import { ITranslatedEntity } from '../../../ITranslatedEntity';

export interface ISmartPromptTranslation extends ITranslation {
    keywords: string;
    prompt: string;
}

export class SmartPromptTranslation extends Translation {
    keywords: string = null;
    prompt: string = null;
}

export interface ISmartPrompt extends IEntity {
    keywords: string;
    prompt: string;
}

export class SmartPrompt extends Entity implements ITranslatedEntity {
    keywords: string = null;
    prompt: string = null;
    translations: Array<ISmartPromptTranslation> = [];

    public fromJson(jsonObject: any, convertToCamel: boolean = true): ISmartPrompt {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ISmartPromptTranslation>(
                SmartPromptTranslation,
                jsonObject.translations,
                true
            );
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.keywords) {
            keys.push('keywords');
        }
        if (this.prompt) {
            keys.push('prompt');
        }

        return keys;
    }

    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}
