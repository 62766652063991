import { Service } from '@intouch/its.essential/app/essential/decorators/Service';

@Service('its.survey.services', BuilderService.IID, BuilderService)
export class BuilderService {
    static IID: string = 'itsBuilderService';
    static $inject: Array<string> = [];

    public currentTab: string = null;

    public setTab(tab: string): void {
        this.currentTab = tab;
    }

    public getTab(): string {
        return this.currentTab;
    }
}
