import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { BaseItem, IBaseItem } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { IHelpTextSettings, IHelpTextTranslation } from './shared/HelpText';
import { ISmartPrompt, SmartPrompt } from './shared/SmartPrompt';
import { IHealthPoints } from './IHealthPoints';
import { ITranslatedEntity } from '../../ITranslatedEntity';

export interface ITextAreaTranslation extends IHelpTextTranslation {
    placeholder: string;
}

export class TextAreaTranslation extends Translation implements ITextAreaTranslation {
    additionalText: string;
    placeholder: string = null;
}

/**
 * The text area settings interface
 */
export interface ITextAreaSettings extends IBaseSettings, IHelpTextSettings<ITextAreaTranslation> {
    maxLength: number;
    minLength: number;
    rows: string;
    enableSentiment: boolean;
    placeholder: string;
    smartPrompt: Array<ISmartPrompt>;
}

/**
 * The text area settings class
 */
export class TextAreaSettings extends BaseSettings implements ITextAreaSettings, ITranslatedEntity {
    maxLength: number = null;
    minLength: number = null;
    rows: string = '5';
    enableSentiment: boolean = false;
    placeholder: string = null;
    additionalText: string = null;
    translations: Array<ITextAreaTranslation> = [];
    smartPrompt: Array<ISmartPrompt> = [];

    public fromJson(jsonObject: any, convertToCamel?: boolean): TextAreaSettings {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ITextAreaTranslation>(
                TextAreaTranslation,
                jsonObject.translations,
                true
            );
        }

        if (jsonObject.smart_prompt) {
            this.smartPrompt = EntityBuilder.buildMany<ISmartPrompt>(SmartPrompt, jsonObject.smart_prompt, true);
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const keysToReturn: Array<string> = [];
        if (this.additionalText) {
            keysToReturn.push('additionalText');
        }

        if (this.placeholder) {
            keysToReturn.push('placeholder');
        }

        return keysToReturn;
    }

    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

/**
 * The text area interface
 */
export type ITextArea = IBaseItem;

/**
 * The text area class
 */
export class TextArea extends BaseItem implements ITextArea, IHealthPoints {
    type: string = 'textarea';
    settings: TextAreaSettings = new TextAreaSettings();

    @NotEnumerable
    _healthPoints: number = 3;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey. This overrides the BaseItem default value.
     */
    get healthPoints(): number {
        return this._healthPoints;
    }

    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<TextAreaSettings>(TextAreaSettings, jsonObject.settings, true);
        }

        return this;
    }
}
