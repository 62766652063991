import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface IBaseItemJS extends IEntity {
    onLoad: string;
    onChange: string;
    onShow: string;
    onHide: string;
    onNext: string;
}

export class BaseItemJS extends Entity implements IBaseItemJS {
    onLoad: string = null;
    onChange: string = null;
    onShow: string = null;
    onHide: string = null;
    onNext: string = null;
}
