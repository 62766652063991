import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Ranking } from '../../../../../../domain/surveys/items/Ranking';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';

@Component('its.survey.module.surveys', RankingSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/response-items/ranking/RankingSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: RankingSettingsComponent.getBindings(),
})
class RankingSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'RankingSettingsComponent';
    static CID: string = 'itsRankingSettings';
    static $inject: string[] = [];

    public item: Ranking;
}
