import './SurveyItemSettingsComponent';

import './shared/add-new-section/AddNewSectionComponent';
import './shared/collapse-arrow/CollapseArrowComponent';
import './shared/custom-js-setting/CustomJSSettingComponent';
import './shared/drag-and-drop-handle/DragAndDropHandleComponent';
import './shared/inputs/help-text-setting/HelpTextSettingComponent';
import './shared/inputs/label-setting/LabelSettingComponent';
import './shared/inputs/placeholder-text-setting/PlaceholderTextSettingComponent';
import './shared/inputs/points-setting/PointsSettingsComponent';
import './shared/item-extra-information/ItemExtraInformationComponent';
import './shared/item-settings-tabs/ItemSettingsTabsComponent';
import './shared/selects/horizontal-alignment-setting/HorizontalAlignmentSettingComponent';
import './shared/selects/icon-setting/IconSettingComponent';
import './shared/selects/text-rows-setting/TextRowsSettingComponent';
import './shared/sliders/span-setting/SpanSettingComponent';
import './shared/sliders/max-recording-length-setting/MaxRecordingLengthSettingComponent';
import './shared/smart-prompt-setting/SmartPromptSettingComponent';
import './shared/survey-item-extra-actions/SurveyItemExtraActions';
import './shared/switches/align-answers-vertically-setting/AlignAnswersVerticallySettingComponent';
import './shared/switches/allow-text-entry-setting/AllowTextEntrySettingComponent';
import './shared/switches/analyze-sentiment-setting/AnalyzeSentimentSettingComponent';
import './shared/switches/hide-from-reporting-setting/HideFromReportingSettingComponent';
import './shared/switches/hide-label-setting/HideLabelSettingComponent';
import './shared/switches/not-applicable-setting/NotApplicableSettingComponent';
import './shared/switches/other-answer-setting/OtherAnswerSettingComponent';
import './shared/switches/randomize-answer-order-setting/RandomizeAnswerOrderSettingComponent';
import './shared/switches/required-setting/RequiredSettingComponent';
import './shared/switches/use-original-scale-setting/UseOriginalScaleSettingComponent';
import './shared/tag-setting/TagSettingComponent';

import './captcha/CaptchaSettingsComponent';
import './captcha/CaptchaRenderComponent';

import './section/SectionSettingsComponent';
import './section/SectionRenderComponent';

import './group/GroupSettingsComponent';
import './group/GroupRenderComponent';

import './display-items/image/ImageSettingsComponent';
import './display-items/image/ImageRenderComponent';

import './display-items/text/TextSettingsComponent';
import './display-items/text/TextRenderComponent';

import './display-items/video/VideoSettingsComponent';
import './display-items/video/VideoRenderComponent';

import './input-items/audio-input/AudioInputSettingsComponent';
import './input-items/audio-input/AudioInputRenderComponent';

import './input-items/calendar/CalendarSettingsComponent';
import './input-items/calendar/CalendarRenderComponent';

import './input-items/digit/DigitSettingsComponent';
import './input-items/digit/DigitRenderComponent';

import './input-items/hidden-input/HiddenInputSettingsComponent';

import './input-items/input/InputSettingsComponent';
import './input-items/input/InputRenderComponent';

import './input-items/image-upload/ImageUploadSettingsComponent';
import './input-items/image-upload/ImageUploadRenderComponent';

import './input-items/location/LocationSettingsComponent';
import './input-items/location/LocationRenderComponent';

import './input-items/nps/NpsSettingsComponent';
import './input-items/nps/NpsRenderComponent';

import './input-items/rating/RatingSettingsComponent';
import './input-items/rating/RatingRenderComponent';

import './input-items/scale/ScaleSettingsComponent';
import './input-items/scale/ScaleRenderComponent';

import './input-items/slider/SliderSettingsComponent';
import './input-items/slider/SliderRenderComponent';

import './input-items/textarea/TextareaSettingsComponent';
import './input-items/textarea/TextareaRenderComponent';

import './input-items/video-input/VideoInputSettingsComponent';
import './input-items/video-input/VideoInputRenderComponent';

import './response-items/checkbox/CheckboxSettingsComponent';
import './response-items/checkbox/CheckboxRenderComponent';

import './response-items/dropdown/DropdownSettingsComponent';
import './response-items/dropdown/DropdownRenderComponent';

import './response-items/matrix/MatrixSettingsComponent';
import './response-items/matrix/MatrixRenderComponent';

import './response-items/matrix/matrix-questions/MatrixQuestionsComponent';

import './response-items/radio/RadioSettingsComponent';
import './response-items/radio/RadioRenderComponent';

import './response-items/ranking/RankingSettingsComponent';
import './response-items/ranking/RankingRenderComponent';

import './response-items/shared/response-type-select/ResponseTypeSelectComponent';
import './response-items/shared/response-template-select/ResponseTemplateSelectComponent';
import './response-items/shared/responses/ItemResponsesComponent';
import './response-items/shared/response-default-select/ResponseDefaultSelectComponent';
