import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IPageService } from '../../../services/PageService';
import { ISurveyService } from '../../../services/SurveyService';
import { ISurveyApi } from '../../../api/SurveyApi';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';

/**
 * Form edit Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.surveys', ScoringController.IID, ScoringController)
class ScoringController {
    static IID: string = 'its.survey.module.surveys.ScoringController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        '$stateParams',
        'itsSurveyService',
        'iteToaster',
        'itsPageService',
        'itsSurveyApi',
        '$scope',
    ];

    public activeTab: string = null;

    /**
     * Create the controller
     *
     * @param stateService
     * @param pageService
     */
    constructor(
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected scope: ng.IScope
    ) {
        switch (this.stateService.current.name) {
            case 'home.surveys.edit.scoring.tagging':
                this.activeTab = 'tagging';
                break;
            case 'home.surveys.edit.scoring.weighting':
                this.activeTab = 'weighting';
                break;
            case 'home.surveys.edit.scoring.points':
                this.activeTab = 'points';
                break;
            default:
                this.activeTab = 'points';
        }
    }

    /**
     * Sets the active tab
     */
    public setActiveTab(tab: string): void {
        this.activeTab = tab;
        this.stateService.go('home.surveys.edit.scoring.' + tab.toLowerCase(), {
            reload: false,
            notify: false,
            inherit: true,
        });
    }

    public getTabTranslation(tab: string): string {
        switch (tab) {
            case 'points':
                return 'SURVEYS.SCORING.SIDE_BAR.POINTS';
            case 'tagging':
                return 'SURVEYS.SCORING.SIDE_BAR.TAGGING';
            case 'weighting':
                return 'SURVEYS.SCORING.SIDE_BAR.WEIGHTING';
            default:
                return tab;
        }
    }
}
