import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface ISurveyJS extends IEntity {
    onLoad: string;
    onSubmit: string;
}

export class SurveyJS extends Entity implements ISurveyJS {
    onLoad: string = null;
    onSubmit: string = null;
}
