import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseResponseItem, IBaseResponseItem } from '../../../../../../../domain/surveys/items/BaseResponseItem';
import { IBaseResponse } from '../../../../../../../domain/surveys/items/BaseResponse';
import { DeleteItemModal } from '../../../../../modals/DeleteItemModal';
import { LogicDeleter } from '../../../../../../../domain/surveys/logic/LogicDeleter';
import { Survey } from '../../../../../../../domain/surveys/survey/Survey';
import { SurveyService } from '../../../../../../../services/SurveyService';

@Component('its.survey.module.surveys', OtherAnswerSettingComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/shared/switches/other-answer-setting/OtherAnswerSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class OtherAnswerSettingComponent {
    static IID: string = 'OtherAnswerSettingComponent';
    static CID: string = 'itsOtherAnswerSetting';
    static $inject: string[] = ['$mdDialog', 'itsSurveyService'];

    public item: BaseResponseItem;
    public survey: Survey = null;

    public constructor(private dialog: ng.material.IDialogService, private surveyService: SurveyService) {}

    $onInit(): void {
        this.survey = this.surveyService.getSurvey() as Survey;
    }

    public toggleOther(item: IBaseResponseItem): void {
        if (item.settings.showOther === true) {
            item.addOtherResponse();
        } else {
            const response: IBaseResponse = item.responses.find(function (response: IBaseResponse): boolean {
                return response.uuid === item.settings.showOtherUuid;
            });

            if (response.logicReferences.length > 0) {
                this.showDeleteResponseBlock(item, response);
            } else {
                item.removeResponse(response);
            }
        }
    }

    public showDeleteResponseBlock(item: IBaseResponseItem, response: IBaseResponse): void {
        this.dialog
            .show(
                DeleteItemModal.instantiate({
                    locals: {
                        surveyUuid: this.survey.uuid,
                    },
                })
            )
            .then((state: boolean) => {
                if (state) {
                    LogicDeleter.delete(this.survey, response);
                    item.removeResponse(response);
                } else {
                    item.settings.showOther = true;
                }
            });
    }
}
