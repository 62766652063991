import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { SurveyService } from '../../../../../../services/SurveyService';
import { ISection } from '../../../../../../domain/surveys/Section';
import { IBaseItem } from '../../../../../../domain/surveys/items/BaseItem';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';

@Component('its.survey.module.surveys', DragAndDropHandleComponent.CID, {
    template: require('/app/modules/surveys/components/items/shared/drag-and-drop-handle/DragAndDropHandleComponent.html'),
    controllerAs: 'vm',
    bindings: {
        expanded: '=',
        ngDisabled: '<',
    },
})
class DragAndDropHandleComponent {
    static IID: string = 'DragAndDropHandleComponent';
    static CID: string = 'itsDragAndDropHandle';
    static $inject: string[] = ['itsSurveyService', '$scope'];

    public editingItem: IBaseItem | ISection = null;

    public constructor(private surveyService: SurveyService, private scope: ng.IScope) {
        const editingItemSubscription: SubjectSubscription = this.surveyService.editingItem.subscribe((editingItem) => {
            this.editingItem = editingItem as IBaseItem | ISection;
        });
        editingItemSubscription.unsubscribeOnScopeDestruction(this.scope);
    }
}
