import { ILogic } from './Logic';

export interface ILogicOwner {
    uuid: string;
    label: string;
}

export class LogicOwner implements ILogicOwner {
    uuid: string;
    label: string;
    public constructor(uuid: string, label: string) {
        this.uuid = uuid;
        this.label = label;
    }
}

export interface ILogicReference {
    logicOwner: ILogicOwner;
    logic: ILogic;
}

export class LogicReference implements ILogicReference {
    logicOwner: ILogicOwner = null;
    logic: ILogic = null;

    public constructor(logicOwner: ILogicOwner, logic: ILogic) {
        this.logicOwner = logicOwner;
        this.logic = logic;
    }
}
