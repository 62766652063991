import { IThemeSettings } from './ThemeSettings';
import { ThemeType } from '../../../services/ThemeService';

export interface IAbstractTheme {
    slug: ThemeType;
    customizations: IThemeSettings;

    getName(): string;
    getSettings(): IThemeSettings;
}

export abstract class AbstractTheme implements IAbstractTheme {
    public slug: ThemeType = null;
    public customizations: IThemeSettings = {
        '::placeholder': {
            'font-family': 'Roboto',
            color: '#242424',
        },

        'its-theme--background': {
            'background-color': '#F4F4F4',
            'background-image': 'none',
        },

        'its-theme--progress-language-container': {
            'background-color': 'transparent',
            'border-radius': '6px',
        },

        'its-theme--background-content': {
            'max-width': '60%',
            width: '60%',
            'background-color': '#FFFFFF',
            'border-radius': '6px',
            'box-shadow': '0 1px 5px 0 rgb(0 0 0 / 20%)',
        },

        'its-theme--content-container': {
            'margin-top': '20px',
            'margin-bottom': '20px',
        },

        'its-theme--question-container': {
            'background-color': 'transparent',
            'border-radius': '6px',
            'margin-top': '10px',
            'margin-bottom': '10px',
        },

        'its-theme--brand-font': {
            color: '#FFFFFF',
        },

        'its-theme--brand-primary': {
            'background-color': '#4ec8ef',
        },

        'its-theme--error-container': {
            'background-color': '#FFE4E4',
            'border-radius': '6px',
        },

        'its-theme--error-messages': {
            color: '#FF5533',
            'font-weight': 600,
            'font-size': '14px',
        },

        'its-theme--font': {
            'font-family': 'Roboto',
        },

        'its-theme--header-logo': {
            'background-image': 'none',
            'background-position': 'center center',
        },

        'its-theme--header-background': {
            display: 'none',
            'background-color': '#FFFFFF',
        },

        'its-theme--footer-logo': {
            'background-image': 'none',
            'background-position': 'center center',
        },

        'its-theme--footer-background': {
            display: 'none',
            'background-color': '#FFFFFF',
        },

        'its-theme--help-text': {
            color: '#242424',
            'font-weight': 400,
            'font-size': '12px',
        },

        'its-theme--question-label': {
            color: '#242424',
            'font-weight': 600,
            'font-size': '16px',
        },

        'its-theme--response-label': {
            'font-weight': 400,
            'font-size': '14px',
        },

        'its-theme--response-label-color': {
            color: '#242424',
        },

        'its-theme--title': {
            color: '#242424',
            'font-weight': 600,
            'font-size': '24px',
            'background-color': 'transparent',
            'border-radius': '6px',
        },

        'its-theme--title-container': {
            'background-color': 'transparent',
            'border-radius': '6px',
        },

        'its-theme--button-container': {
            'background-color': 'transparent',
            'border-radius': '6px',
        },
    };

    public abstract getName(): string;
    public abstract getSettings(): IThemeSettings;
}
