import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { IElementLibraryGroup, IElementLibraryGroupItem } from '../domain/surveys/element-library/IElementLibraryGroup';
import { LibraryGroups } from '../domain/surveys/element-library/LibraryGroups';
import * as _ from 'lodash';

@Service('its.survey.services', ElementLibraryService.IID, ElementLibraryService)
export class ElementLibraryService {
    static IID: string = 'itsElementLibraryService';
    static $inject: Array<string> = ['$translate'];

    private surveyElementsAvailable: Array<IElementLibraryGroupItem> = [];
    private elementLibraryGroups: Array<IElementLibraryGroup> = [];
    private questionLibraryGroups: Array<IElementLibraryGroup> = [];

    public constructor(private translate: ng.translate.ITranslateService) {
        const libraryGroups: LibraryGroups = new LibraryGroups(translate);
        this.elementLibraryGroups = libraryGroups.getElementLibraryGroups();
        this.questionLibraryGroups = libraryGroups.getQuestionLibraryGroups();
        this.surveyElementsAvailable = _.flatMap(this.elementLibraryGroups, (g) => g.items);
    }

    public getElementLibraryGroups(exclusions?: Array<string>): Array<IElementLibraryGroup> {
        if (exclusions) {
            const groups: any = [];
            for (let i: number = 0; i < this.elementLibraryGroups[0].items.length; i++) {
                if (exclusions.includes(this.elementLibraryGroups[0].items[i].type)) {
                    this.elementLibraryGroups[0].items.splice(i, 1);
                }
            }

            return this.elementLibraryGroups;
        } else {
            return this.elementLibraryGroups;
        }
    }

    public getQuestionLibraryGroups(): Array<IElementLibraryGroup> {
        return this.questionLibraryGroups;
    }

    public getSurveyElementsAvailable(): Array<IElementLibraryGroupItem> {
        return this.surveyElementsAvailable;
    }

    public getSurveyElementByType(type: string): IElementLibraryGroupItem {
        return _.find(this.surveyElementsAvailable, { type: type });
    }
}
