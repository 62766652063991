import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ImageUpload } from '../../../../../../domain/surveys/items/ImageUpload';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';

@Component('its.survey.module.surveys', ImageUploadSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/image-upload/ImageUploadSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: ImageUploadSettingsComponent.getBindings(),
})
class ImageUploadSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'ImageUploadSettingsComponent';
    static CID: string = 'itsImageUploadSettings';
    static $inject: string[] = [];

    public item: ImageUpload;
}
