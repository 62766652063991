import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { IDefaultContactField } from './DefaultContactField';
import { ICustomField } from '../settings/CustomField';

export interface IDistributionTriggerCondition extends IEntity {
    operand: string;
    operandType: string;
    operator: string;
    value: Date | string | number;
    fieldType: string;
    selectedField: IDefaultContactField | ICustomField;
    fieldIsDeleted: boolean;
}

export class DistributionTriggerCondition extends Entity implements IDistributionTriggerCondition {
    public operand: string = null;
    public operandType: string = null;
    public operator: string = null;
    public value: Date | string | number = null;
    public fieldType: string = null;

    @NotEnumerable
    public selectedField: IDefaultContactField | ICustomField = null;
    @NotEnumerable
    public fieldIsDeleted: boolean = false;
}
