import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItem } from '../../../../../../../domain/surveys/items/BaseItem';

@Component('its.survey.module.surveys', ResponseTypeSelectComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/response-items/shared/response-type-select/ResponseTypeSelectComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
        label: '@',
    },
})
class ResponseTypeSelectComponent {
    static IID: string = 'ResponseTypeSelectComponent';
    static CID: string = 'itsResponseTypeSelect';
    static $inject: string[] = [];

    public item: BaseItem;

    public showImageType: boolean = false;
    public showButtonType: boolean = false;

    $onInit(): void {
        this.showImageType = this.item.type !== 'select';
        this.showButtonType = this.item.type === 'checkbox' || this.item.type === 'radio';
    }
}
