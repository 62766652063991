import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { ISurveyApi } from '../../../api/SurveyApi';
import { SurveyService } from '../../../services/SurveyService';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { ISection, Section } from '../../../domain/surveys/Section';
import { Survey } from '../../../domain/surveys/survey/Survey';
import { SurveySectionMover } from '../../../domain/surveys/survey/utils/SurveySectionMover';
import SectionMoveModalHtml from './SectionMoveModal.html';

export class SectionMoveModal extends Modal {
    static $inject: Array<string> = [
        '$mdDialog',
        'itsSurveyApi',
        'iteLogger',
        '$translate',
        'iteToaster',
        'itsSurveyService',
        '$scope',
    ];

    public surveySections: ISection[] = [];
    public survey: Survey = null;
    public submitting: boolean = false;
    public section: Section;
    public targetSection: Section;
    public targetDirection: 'before' | 'after' = 'after';

    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = SectionMoveModalHtml;
        config.controller = SectionMoveModal;

        return super.instantiate(config);
    }

    public constructor(
        private dialog: ng.material.IDialogService,
        private surveyApi: ISurveyApi,
        private logger: ILogger,
        private translate: ng.translate.ITranslateService,
        private toaster: IToaster,
        private surveyService: SurveyService,
        private scope: ng.IScope
    ) {
        super();
        this.subscribeToSurvey();
    }

    public cancel(): void {
        this.dialog.cancel();
    }

    public saveNewSectionPosition(): void {
        this.submitting = true;
        this.surveyService.saveSnapshot();
        try {
            SurveySectionMover.moveSection(this.survey, this.section, this.targetSection, this.targetDirection);
            this.surveyService
                .saveSurvey(this.survey)
                .then((survey) => {
                    this.surveyService.setSurvey(survey);
                    this.dialog.hide();
                })
                .catch(() => {
                    this.surveyService.restoreToPreviousSnapshot();
                    this.toaster.error('ERRORS.ITEMS.MOVE');
                })
                .finally(() => {
                    this.submitting = false;
                });
        } catch (e) {
            this.surveyService.restoreToPreviousSnapshot();
            this.toaster.error('ERRORS.ITEMS.MOVE');
            this.submitting = false;
        }
    }

    private subscribeToSurvey(): void {
        const subscription: SubjectSubscription = this.surveyService.surveyValue.subscribe((latestSurvey) => {
            if (latestSurvey) {
                this.survey = latestSurvey;
                const sections: ISection[] = this.survey.getSections();
                this.surveySections = this.excludeSelectedSection(sections);
            }
        });
        subscription.unsubscribeOnScopeDestruction(this.scope);
    }

    private excludeSelectedSection(sections: ISection[]): ISection[] {
        return sections.filter((section) => {
            return section.uuid !== this.section.uuid;
        });
    }
}
