import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { SurveyService } from '../../services/SurveyService';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { BuilderService } from '../../services/BuilderService';

/**
 * Form edit Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.surveys', BuildController.IID, BuildController)
class BuildController {
    static IID: string = 'its.survey.module.surveys.BuildController';
    static $inject: Array<string> = ['$mdMedia', '$scope', 'itsSurveyService', '$state', 'itsBuilderService'];

    public activeTab: string = 'elements';
    public showPanel: boolean = true;
    public isEditingItem: boolean = false;

    constructor(
        private mdMedia: ng.material.IMedia,
        private scope: ng.IScope,
        private surveyService: SurveyService,
        private state: ng.ui.IStateService,
        private builderService: BuilderService
    ) {
        const editingItemSubscription: SubjectSubscription = this.surveyService.editingItem.subscribe((editingItem) => {
            this.isEditingItem = !!editingItem;
        });
        this.setActiveTab(); //! This is not being updated on a ui-sref properly, we never do anything to change the tab?
        const vm: any = this;
        // We need to watch for changes in the serive to update the tab when it gets changed by something other than the user.
        this.scope.$watch(
            function () {
                return builderService.getTab();
            },
            function (newValue, oldValue, scope) {
                vm.setActiveTab(newValue);
            }
        );

        editingItemSubscription.unsubscribeOnScopeDestruction(this.scope);
    }

    public setActiveTab(tab?: string): void {
        if (tab) {
            this.activeTab = tab;
        } else {
            if (this.state.current.name.indexOf('build.details') > -1) {
                if (this.state.params['library']) {
                    this.activeTab = 'library';
                } else {
                    this.activeTab = 'elements';
                }
            } else if (this.state.current.name.indexOf('build.logic') > -1) {
                this.activeTab = 'logic';
            } else if (this.state.current.name.indexOf('build.settings') > -1) {
                this.activeTab = 'settings';
            } else {
                this.activeTab = 'elements';
            }
        }

        if (this.mdMedia('xs')) {
            this.showPanel = true;
        }
    }

    public navigateTo(tab: string): void {
        this.setActiveTab(tab);

        switch (tab) {
            case 'elements':
                return;
            default:
                return;
        }
    }

    public getTabTitle(): string {
        switch (this.activeTab) {
            case 'themes':
                return 'SURVEYS.MENU.THEMES';
            case 'layout':
                return 'GENERAL.LAYOUT';
            case 'colors':
                return 'SURVEYS.THEMES.COLORS';
            case 'text':
                return 'GENERAL.TEXT';
            case 'branding':
                return 'SURVEYS.THEMES.BRANDING';
            case 'features':
                return 'SURVEYS.SETTINGS.FEATURES';
            default:
                return 'SURVEYS.MENU.THEMES';
        }
    }
}
