import { ThemeType } from '../../../services/ThemeService';
import { AbstractTheme } from './AbstractTheme';
import { IThemeSettings } from './ThemeSettings';

export class DefaultTheme extends AbstractTheme {
    public slug: ThemeType = 'default';

    public constructor() {
        super();
    }

    public getName(): string {
        return 'SURVEYS.THEMES.DEFAULT';
    }

    public getSettings(): IThemeSettings {
        return this.customizations;
    }
}
