import { IContactList } from './ContactList';

export interface IContactUpload {
    type: string;
    file: File;
    configuration: Array<IContactList>;
}

/**
 * Contact upload class
 */
export class ContactUpload implements IContactUpload {
    public file: File;
    public configuration: Array<IContactList> = [];
    public type: string = 'contacts';
}
