import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';

import { ISurveyApi } from '../../../api/SurveyApi';
import { GeneralSettings, IGeneralSettings } from '../../../domain/settings/GeneralSettings';

@Controller('its.survey.module.settings', FatigueProtectionController.IID, FatigueProtectionController)
class FatigueProtectionController {
    static IID: string = 'its.survey.module.settings.FatigueProtectionController';
    static $inject: Array<string> = ['itsSurveyApi', 'iteToaster', 'iteAccessService'];

    public loading: boolean = false;
    protected generalSettings: IGeneralSettings = new GeneralSettings();
    protected failedLoading: boolean = false;
    protected submitting: boolean = false;

    constructor(private surveyApi: ISurveyApi, private toaster: IToaster, private accessService: IAccessService) {
        this.load();
    }

    public load(): void {
        const token: IToken = this.accessService.getToken();

        this.loading = true;
        if (token && token.getOrganization()) {
            this.surveyApi
                .getSettings()
                .then((savedSettings: IGeneralSettings) => {
                    this.generalSettings = savedSettings;
                })
                .catch(() => {
                    this.failedLoading = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    public submit(): void {
        const token: IToken = this.accessService.getToken();

        this.submitting = true;
        if (token && token.getOrganization()) {
            this.surveyApi
                .saveSettings(this.generalSettings)
                .then(() => {
                    this.toaster.success('CONTACT_CENTER.SETTINGS.SETTINGS_SAVED');
                })
                .catch(() => {
                    this.toaster.error('ERRORS.SETTINGS.FAILED_SETTINGS_SAVE');
                })
                .finally(() => {
                    this.submitting = false;
                });
        }
    }
}
