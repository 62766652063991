import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', SurveyPreviewThumbnailComponent.CID, {
    template: require('/app/modules/surveys/components/survey-preview-thumbnail/SurveyPreviewThumbnailComponent.html'),
    controllerAs: 'vm',
    bindings: {
        survey: '<',
        dimension: '@?',
    },
})
class SurveyPreviewThumbnailComponent {
    static IID: string = 'SurveyPreviewThumbnailComponent';
    static CID: string = 'itsSurveyPreviewThumbnail';
    static $inject: Array<string> = [];

    public dimension: string;

    $onInit(): void {
        if (!this.dimension) {
            this.dimension = '80x60';
        }
    }
}
