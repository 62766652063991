import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { ISmsDistribution, SmsDistribution } from '../../../../domain/contact-center/SmsDistribution';
import { ISurvey } from '../../../../domain/surveys/survey/Survey';
import { Contact } from '../../../../domain/contact-center/Contact';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { DefaultContactField, IDefaultContactField } from '../../../../domain/contact-center/DefaultContactField';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ICustomField } from '../../../../domain/settings/CustomField';
import { IPageService } from '../../../../services/PageService';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { DistributionTrigger } from '../../../../domain/contact-center/DistributionTrigger';
import { IDistributionTriggerCondition } from '../../../../domain/contact-center/DistributionTriggerCondition';
import * as _ from 'lodash';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { SendTestSmsModal } from './SendTestSmsModal';

@Controller(
    'its.survey.module.surveys.distributions',
    DistributionCreateSmsController.IID,
    DistributionCreateSmsController
)
class DistributionCreateSmsController {
    static IID: string = 'its.survey.module.surveys.distributions.DistributionCreateSmsController';
    static $inject: Array<string> = [
        'itsSurveyApi',
        '$state',
        'itsPageService',
        '$q',
        'APPCONFIG',
        'iteToaster',
        'iteAccessService',
        '$mdDialog',
        '$state',
        '$translate',
    ];

    protected survey: ISurvey = null;
    protected editing: boolean = false;
    protected limitToggle: boolean = false;
    protected smsCompositionForm: ng.IFormController;
    protected smsDistribution: ISmsDistribution = null;
    protected operators: Array<{ label: string; value: string }> = [];
    protected enableAutoDelivery: boolean = true;
    protected customFields: Array<ICustomField> = [];
    protected defaultFields: Array<IDefaultContactField> = [];
    protected fieldIsDeleted: boolean = false;
    protected isContactAdmin: boolean = false;
    protected smsExpiryDays: number | string = null;
    protected smsCustomExpiry: number = null;

    constructor(
        private surveyApi: ISurveyApi,
        private stateService: ng.ui.IStateService,
        private pageService: IPageService,
        private q: ng.IQService,
        private config: any,
        private toaster: IToaster,
        private accessService: IAccessService,
        private dialog: ng.material.IDialogService,
        private state: ng.ui.IStateService,
        private translate: ng.translate.ITranslateService
    ) {
        this.isContactAdmin = this.accessService.getToken().getUser().hasAcl('contact_center_admin');
        this.load(this.stateService.params['uuid'], this.stateService.params['smsUuid']);
    }

    /**
     * Load the given form by its uuid
     */
    public load(uuid: string, smsUuid: string): void {
        this.pageService.startLoading();
        this.surveyApi
            .findSurveyByUuid(uuid)
            .then((survey: ISurvey) => {
                this.survey = survey;
                this.pageService.setPageTitle(survey.name);
                this.surveyApi.findAllCustomFields().then((pagedCustomFields: PagedEntities) => {
                    this.customFields = pagedCustomFields.getEntities();
                    this.defaultFields = Contact.getDefaultFields(this.translate);
                    this.defaultFields.push(
                        DefaultContactField.make({
                            modelName: 'contactCreationDate',
                            name: 'created_at',
                            label: 'Contact Creation Date',
                            type: 'date',
                            isDefaultField: false,
                        })
                    );
                    this.loadExistingSms(smsUuid).then((smsDistribution: ISmsDistribution) => {
                        if (smsDistribution) {
                            this.smsDistribution = smsDistribution;
                            if (this.smsDistribution.submissionsPerPeriod === 1) {
                                this.limitToggle = true;
                            }

                            // todo: maybe.. not do this? we only have 1 trigger + condition for now so... easy way out.
                            if (
                                this.smsDistribution &&
                                this.smsDistribution.triggers &&
                                this.smsDistribution.triggers.length &&
                                this.smsDistribution.triggers[0].conditions &&
                                this.smsDistribution.triggers[0].conditions.length
                            ) {
                                for (const condition of this.smsDistribution.triggers[0].conditions) {
                                    this.setField(
                                        condition,
                                        this.getSavedField(condition.operand),
                                        condition.value,
                                        true
                                    );
                                }
                            }

                            if (this.smsDistribution.expirationDays) {
                                switch (this.smsDistribution.expirationDays) {
                                    case 3:
                                    case 7:
                                    case 14:
                                    case 30:
                                        this.smsExpiryDays = this.smsDistribution.expirationDays;
                                        this.smsCustomExpiry = null;
                                        break;
                                    default:
                                        this.smsExpiryDays = 'custom';
                                        this.smsCustomExpiry = this.smsDistribution.expirationDays;
                                        break;
                                }
                            }
                            this.editing = true;
                        } else {
                            this.smsDistribution = new SmsDistribution('', this.defaultMessage());
                        }

                        this.operators = this.smsDistribution.getAvailableOperators(this.translate);

                        this.pageService.stopLoading();
                    });
                });
            })
            .catch(() => {
                this.pageService.failLoading();
            });
    }

    /**
     * @param {string} smsUuid
     */
    public loadExistingSms(smsUuid: string): any {
        const q: ng.IDeferred<any> = this.q.defer();
        if (smsUuid) {
            this.surveyApi.getSurveySms(this.survey.originalUuid, smsUuid).then((existingSms: ISmsDistribution) => {
                q.resolve(existingSms);
            });
        } else {
            q.resolve();
        }

        return q.promise;
    }

    public handleSmsExpiryChange(defaultValue: number | string, customValue: number): void {
        if (defaultValue === 'custom' && customValue) {
            this.smsDistribution.expirationDays = customValue;
        } else if (defaultValue !== 'custom') {
            this.smsDistribution.expirationDays = <number>defaultValue;
        }
    }

    /**
     *
     */
    public handleLimitToggle(): void {
        this.smsDistribution.submissionsPeriodInDays = null;
        if (this.limitToggle) {
            this.smsDistribution.submissionsPerPeriod = 1;
        } else {
            this.smsDistribution.submissionsPerPeriod = null;
        }
    }

    /**
     * Confirmation prompt when cancelling sms creation
     */
    public cancelSmsCreation(): void {
        if (!this.editing) {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: this.translate.instant('DISTRIBUTIONS.SMS.DISCARD_SMS'),
                            description: this.translate.instant('DISTRIBUTIONS.SMS.DISCARD_SMS_DESC'),
                            confirmText: this.translate.instant('DISTRIBUTIONS.SMS.DISCARD'),
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((response) => {
                    if (response) {
                        this.state.go('home.surveys.edit.distributions.sms', {
                            uuid: this.survey.uuid,
                        });
                    }
                });
        } else {
            this.state.go('home.surveys.edit.distributions.sms', {
                uuid: this.survey.uuid,
            });
        }
    }

    public getSavedField(fieldName: string): IDefaultContactField | ICustomField {
        const customField: ICustomField = _.find(this.customFields, { name: fieldName });

        if (customField) {
            return customField;
        }

        const defaultField: IDefaultContactField = _.find(this.defaultFields, { name: fieldName });

        if (defaultField) {
            return defaultField;
        }

        return null;
    }

    /**
     * @param condition
     * @param field
     * @param value
     * @param init
     */
    public setField(condition: IDistributionTriggerCondition, field: any, value?: any, init: boolean = false): void {
        condition.fieldIsDeleted = false;
        if (field) {
            condition.operand = field.name;
            condition.operandType = field.type;

            // preserve when loading existing email
            if (value && condition.operator !== 'empty' && condition.operator !== 'nempty') {
                condition.value = value;
            } else {
                condition.value = null;
            }

            if (field.isDefaultField) {
                condition.fieldType = 'default_contact_field';
            } else if (field.name === 'created_at') {
                condition.fieldType = 'contact_creation_date';
            } else {
                condition.fieldType = 'custom_contact_field';
            }
            condition.selectedField = field;
        } else if (init) {
            condition.fieldIsDeleted = true;
        }
    }

    public saveSms(sms: ISmsDistribution): void {
        this.smsDistribution.name = this.smsDistribution.sms.name;

        if (sms.uuid) {
            // update
            this.surveyApi
                .updateSurveySms(this.survey.originalUuid, sms)
                .then(() => {
                    this.toaster.success('DISTRIBUTIONS.EMAIL.SUCCESSFULLY_UPDATED');
                    this.state.go('home.surveys.edit.distributions.sms', {
                        uuid: this.survey.uuid,
                    });
                })
                .catch(() => {
                    this.toaster.error('ERRORS.FAILED_SMS_UPDATE');
                });
        } else {
            // create
            this.surveyApi
                .createSurveySms(this.survey.originalUuid, sms)
                .then(() => {
                    this.toaster.success('DISTRIBUTIONS.EMAIL.SUCCESSFULLY_CREATED');
                    this.state.go('home.surveys.edit.distributions.sms', {
                        uuid: this.survey.uuid,
                    });
                })
                .catch(() => {
                    this.toaster.error('ERRORS.FAILED_SMS_CREATE');
                });
        }
    }

    public handleEnablingAutoDelivery(status: boolean): void {
        if (status && (!this.smsDistribution.triggers || this.smsDistribution.triggers.length === 0)) {
            this.smsDistribution.triggers = [new DistributionTrigger()];
        }
    }

    /**
     * Decide whether it's valid to send a test SMS
     *
     * @returns {boolean}
     */
    public isSmsValid(): boolean {
        if (this.smsDistribution.sms.message && this.smsDistribution.sms.message.length > 0) {
            return true;
        }

        return false;
    }

    /**
     * Open up the test SMS modal
     */
    public openSmsPreview(): void {
        const token: IToken = this.accessService.getToken();

        if (!token || !token.getOrganization()) {
            this.toaster.error('ERRORS.CONTACT_CENTER.MISSING_TOKEN_INFO');
        }

        this.dialog.show(
            SendTestSmsModal.instantiate({
                locals: {
                    smsMessage: this.smsDistribution.sms.message,
                    surveyOriginalUuid: this.survey.originalUuid,
                },
            })
        );
    }

    private defaultMessage(): string {
        return this.translate.instant('DISTRIBUTIONS.SMS.DEFAULT_MESSAGE');
    }
}
