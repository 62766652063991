import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', TextRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/display-items/text/TextRenderComponent.html'),
    controllerAs: 'vm',
    bindings: TextRenderComponent.getBindings(),
})
class TextRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'TextRenderComponent';
    static CID: string = 'itsTextRender';
    static $inject: string[] = ['$sce'];

    public constructor(private sce: ng.ISCEService) {
        super();
    }

    public trustAsHtml(text: string): string {
        return this.sce.trustAsHtml(text);
    }
}
