import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ISurveyApi } from '../../api/SurveyApi';
import { ISurveyCollectionItem, SurveyCollectionItem } from '../../domain/surveys/SurveyCollectionItem';
import { Filter } from '@intouch/iql-ts-sdk/src/domain/filters/Filter';
import { ISurvey } from '../../domain/surveys/survey/Survey';
import { ISurveySession } from '../../services/SurveySession';
import { Metric } from '@intouch/iql-ts-sdk/src/domain/metrics/Metric';
import { StringFilter } from '@intouch/iql-ts-sdk/src/domain/filters/StringFilter';
import { Dimension } from '@intouch/iql-ts-sdk/src/domain/dimensions/Dimension';
import { FilterGroup } from '@intouch/iql-ts-sdk/src/domain/filters/FilterGroup';
import { IIql, Iql } from '@intouch/iql-ts-sdk/src/domain/Iql';
import { IqlQueryResult } from '@intouch/iql-ts-sdk/src/domain/IqlQueryResult';
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import * as _ from 'lodash';
import { SurveyCopyModal } from './modals/SurveyCopyModal';
import { SurveyDeleteModal } from './modals/SurveyDeleteModal';
import { DatatableSearch } from '@intouch/its.essential/app/essential/domain/datatable/DatatableSearch';

/**
 * Form listing Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.surveys', ListingController.IID, ListingController)
class ListingController {
    static IID: string = 'its.survey.module.surveys.ListingController';
    static $inject: Array<string> = [
        '$state',
        'itsSurveyApi',
        '$mdDialog',
        'iteToaster',
        '$translate',
        'itsSurveySession',
        'iteIqlApi',
        'iteAccessService',
        'APPCONFIG',
        '$window',
    ];

    public surveys: Array<ISurveyCollectionItem> = [];
    public pager: IPager = null;
    public loading: boolean = false;
    public hasError: boolean = false;
    public searchActive: boolean = false;
    public translations: any;
    public datatableSearch: DatatableSearch = new DatatableSearch();
    public hasSurveyAdmin: boolean = false;
    public hasIq: boolean = false;

    private userUuid: string = null;
    private isAdmin: boolean = false;

    /**
     * Create the controller
     *
     * @param stateService
     * @param surveyApi
     * @param dialog
     * @param toaster
     * @param translate
     * @param session
     * @param iqlApi
     * @param accessService
     * @param config
     * @param windowService
     */
    constructor(
        private stateService: ng.ui.IStateService,
        private surveyApi: ISurveyApi,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private translate: ng.translate.ITranslateService,
        private session: ISurveySession,
        private iqlApi: IIqlApi,
        private accessService: IAccessService,
        private config: any,
        private windowService: ng.IWindowService
    ) {
        this.pager = Pager.make(
            this.stateService.params['page'],
            this.stateService.params['sort_by'],
            this.stateService.params['order']
        );
        this.datatableSearch.term = this.stateService.params['search'] || null;
        this.datatableSearch.hasSearched = !!this.datatableSearch.term;
        this.userUuid = this.session.getUser().uuid;
        this.isAdmin = this.session.getToken().isAdmin();
        this.translate([
            'SURVEYS.DELETE.TITLE_2',
            'SURVEYS.DELETE.DESCRIPTION_2',
            'GENERAL.DELETE',
            'SURVEYS.DELETE.DELETE_SUCCESS',
            'SURVEYS.DELETE.DELETE_FAIL',
            'GENERAL.CANCEL',
            'SURVEYS.DELETE_DRAFT.TITLE',
            'SURVEYS.DELETE_DRAFT.DESCRIPTION',
            'SURVEYS.DELETE_DRAFT.DELETE_SUCCESS',
            'SURVEYS.DELETE_DRAFT.DELETE_FAIL',
        ]).then((translations: any) => {
            this.translations = translations;
        });

        this.load();
        this.hasSurveyAdmin = this.session.getToken().getUser().hasAcl('survey_admin');
        this.hasIq = !!this.session.getToken().getProductBySlug('intelligence');
    }

    /**
     * Load the paged forms
     */
    public load(showLoader: boolean = true): void {
        this.loading = showLoader;
        this.surveyApi
            .findSurveys(this.pager, this.datatableSearch.term)
            .then((results: PagedEntities) => {
                this.surveys = results.getEntities();
                this.pager = results.getPager();
                this.hasError = false;

                if (this.surveys && this.surveys.length > 0) {
                    this.loadSumissionCounts();
                }
            })
            .then(() => {
                this.loading = false;
            })
            .catch(() => {
                this.hasError = true;
                this.loading = false;
            });
    }

    /**
     *
     * @param {string} createdByUuid
     * @returns {boolean}
     */
    public canEdit(createdByUuid: string): boolean {
        return this.hasSurveyAdmin || this.isAdmin;
    }

    /**
     * Allow a user to navigate to the edit screen for the given form uuid
     *
     * @param {string} uuid
     */
    public edit(uuid: string): void {
        if (this.hasSurveyAdmin) {
            this.stateService.go('home.surveys.edit.summary', { uuid: uuid });
        }
    }

    public create(): void {
        this.stateService.go('home.surveys.create');
    }

    /**
     * Show confirmation modal and if confirmed, delete the specified survey and reload the list
     * of surveys
     *
     * @param {ISurvey} survey
     */
    public remove(survey: ISurvey): void {
        this.dialog.show(SurveyDeleteModal.instantiate()).then((response: boolean) => {
            if (response) {
                const type: string = survey.status === 'published' ? 'revision' : 'original';
                this.surveyApi
                    .deleteSurvey(survey.uuid, type)
                    .then(() => {
                        this.toaster.success('SURVEYS.DELETE.DELETE_SUCCESS');
                    })
                    .catch((error: IErrorResponse) => {
                        this.toaster.error('SURVEYS.DELETE.DELETE_FAIL');
                    })
                    .finally(() => {
                        this.load();
                    });
            }
        });
    }

    /**
     * Show confirmation modal and if confirmed, delete the draft version of the survey and reload the list
     * of surveys
     *
     * @param {ISurvey} survey
     */
    public removeDraft(survey: ISurvey): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translations['SURVEYS.DELETE_DRAFT.TITLE'],
                        description: this.translations['SURVEYS.DELETE_DRAFT.DESCRIPTION'],
                        confirmText: this.translations['GENERAL.DELETE'],
                        cancelText: this.translations['GENERAL.CANCEL'],
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((response: boolean) => {
                if (response) {
                    this.surveyApi
                        .deleteSurvey(survey.uuid)
                        .then(() => {
                            this.toaster.info('SURVEYS.DELETE_DRAFT.DELETE_SUCCESS');
                        })
                        .catch((error: IErrorResponse) => {
                            this.toaster.error('SURVEYS.DELETE_DRAFT.DELETE_FAIL');
                        })
                        .finally(() => {
                            this.load();
                        });
                }
            });
    }

    /**
     * Allow a user to search for users by name
     */
    public search(): void {
        this.surveys = [];
        this.pager.currentPage = 1;
        this.goToSelf(this.pager);
    }

    /**
     * Allow a user to toggle the sort order of a column
     *
     * @param field
     */
    public toggleSort(field: string): void {
        if (this.pager.isCurrentSort(field)) {
            this.pager.toggleSortOrder();
        }
        this.pager.sortBy = field;
        this.goToSelf(this.pager);
    }

    public goToPage(page: string, uuid: string): void {
        this.stateService.go('home.surveys.edit.' + page, { uuid: uuid });
    }

    /**
     * Reload page with pager information
     *
     * @param {IPager} pager
     */
    public goToSelf(pager: IPager): void {
        this.stateService.go(
            'home.surveys.listing',
            {
                search: this.datatableSearch.term,
                page: pager.currentPage,
                sort_by: pager.sortBy,
                order: pager.order,
            },
            {
                notify: false, // prevent the events onStart and onSuccess from firing
                reload: false, // prevent reload of the current state
                location: 'replace', // replace the last record when changing the params so you don't hit the back button and get old params
                inherit: true, // inherit the current params on the url
            }
        );
        this.load();
    }

    /**
     * Allow a user to go to the next page of submissions
     */
    public next(): void {
        if (this.pager.canGoNext()) {
            this.pager.currentPage++;
            this.goToSelf(this.pager);
        }
    }

    /**
     * Allow a user to go to the previous page of submissions
     */
    public prev(): void {
        if (this.pager.canGoPrevious()) {
            this.pager.currentPage--;
            this.goToSelf(this.pager);
        }
    }

    /**
     * Copy a survey
     *
     * @param {ISurvey} survey
     */
    public copy(survey: ISurvey): void {
        this.dialog
            .show(
                SurveyCopyModal.instantiate({
                    locals: {
                        surveyToCopy: survey,
                    },
                })
            )
            .then((newSurvey: ISurvey) => {
                this.edit(newSurvey.uuid);
            });
    }

    /**
     * Navigate to the survey dashboard in IQ for the given survey originalUuid
     *
     * @param {string} originalUuid
     */
    public goToDashboard(originalUuid: string): void {
        const url: string =
            this.config.products.urls.intelligence.app +
            this.accessService.getToken().getRawToken() +
            '?redirect=/analytics/standard/survey/' +
            originalUuid;

        window.open(url, '_self');
    }

    public openQuickStartGuide(): void {
        let guideUrl: string;

        switch (this.accessService.getToken().getUser().language) {
            case 'fr_CA':
                // french url will be provided in the near future so leaving this switch/case here for now.
                guideUrl = 'https://intouchsurvey.zendesk.com/hc/en-us/articles/360053582894-Survey-Quick-Start-Guide';
                break;
            default:
                guideUrl = 'https://intouchsurvey.zendesk.com/hc/en-us/articles/360053582894-Survey-Quick-Start-Guide';
                break;
        }

        this.windowService.open(guideUrl, '_blank');
    }

    /**
     * Determine if header rows should be hidden-input due to empty intro state being shown
     */
    public hideForEmptyIntroState(): boolean {
        return (!this.surveys || this.surveys.length === 0) && !this.datatableSearch.hasSearched;
    }

    /**
     * @param {ISurvey}
     */
    public getStatusIcon(survey: SurveyCollectionItem): string {
        if (survey.pendingChanges) {
            return 'sync';
        }

        if (survey.status === 'published') {
            return 'cloud_done';
        }

        if (survey.status === 'draft') {
            return 'edit';
        }

        // if unhandled, return something generic
        return 'assignment';
    }

    /**
     * If the uuids here don't match on a draft, there is a previously published version of the survey,
     * listing will present it as published with pending changes
     */
    public getSurveyStatus(survey: SurveyCollectionItem): string {
        if (survey.status === 'draft' && survey.originalUuid !== survey.uuid) {
            return 'published';
        }

        return survey.status;
    }

    public openHelp(): void {
        window.open('https://intouchsurvey.zendesk.com/hc/en-us/categories/360001182334-Survey-Creation', '_blank');
    }

    /**
     * Disable the survey
     *
     * @param {SurveyCollectionItem} survey
     */
    public disableSurvey(survey: SurveyCollectionItem): void {
        this.surveyApi
            .disableSurvey(survey.uuid)
            .then(() => {
                this.toaster.info('SURVEYS.DISABLE.DISABLED_SUCCESS');
            })
            .catch((error: IErrorResponse) => {
                this.toaster.error('SURVEYS.DISABLE.DISABLED_FAILED');
            })
            .finally(() => {
                this.load(false);
            });
    }

    /**
     * Enable the survey
     *
     * @param {SurveyCollectionItem} survey
     */
    public enableSurvey(survey: SurveyCollectionItem): void {
        this.surveyApi
            .enableSurvey(survey.uuid)
            .then(() => {
                this.toaster.info('SURVEYS.ENABLE.ENABLED_SUCCESS');
            })
            .catch((error: IErrorResponse) => {
                this.toaster.error('SURVEYS.ENABLE.ENABLED_FAILED');
            })
            .finally(() => {
                this.load(false);
            });
    }

    /**
     * @return {void}
     */
    private loadSumissionCounts(): void {
        const query: IIql = new Iql();
        query.metric(new Metric('meta->synthesis_id', Iql.COUNT, Iql.COUNT));
        query.dimension(new Dimension('meta.reference->program_id', 'string', 'program_name'));

        // add the checklists that are currently shown as filters, product and users hierarchy
        query.filter(
            new FilterGroup(
                Iql.OR,
                _.map(this.surveys, (item: ISurveyCollectionItem) => {
                    return new StringFilter('meta.reference->program_id', Iql.EQUAL, item.originalUuid);
                })
            )
        );
        query.filter(new FilterGroup(Iql.AND, [new StringFilter('meta->product', Iql.EQUAL, 'survey')]));
        query.filter(
            new FilterGroup(
                Iql.OR,
                _.map(this.accessService.getToken().getUser().nodes, (node: string) => {
                    return new Filter('meta.hierarchy->uuid', Iql.EQUAL, node, Iql.HIERARCHY);
                })
            )
        );

        // run the query and merge the results with the current checklist listing items
        this.iqlApi
            .execute(query)
            .then((result: IqlQueryResult) => {
                if (result && result.series && result.series.length > 0) {
                    for (const item of result.series[0].data) {
                        const survey: ISurveyCollectionItem = _.find(this.surveys, { originalUuid: item.value });
                        if (survey) {
                            survey.recordCount = item.metrics['meta_count_count'];
                        }
                    }
                }
            })
            .finally(() => {
                this.loading = false;
            });
    }
}
