import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';

/**
 * A form user object interface
 */
export interface IUser extends IEntity {
    uuid: string;
    displayName: string;
}

/**
 * A form user object
 */
export class User extends Entity implements IUser {
    uuid: string = null;
    displayName: string = null;
}
