import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IPhotoService, IParsedImage } from '@intouch/its.essential/app/essential/services/PhotoService';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { IPageService } from '../../services/PageService';
import { ISurveyApi } from '../../api/SurveyApi';
import { ISurveyService } from '../../services/SurveyService';
import { BaseSurvey } from './BaseSurvey';
import { ColorPickerPanel } from '@intouch/its.essential/app/essential/panels/color-picker/ColorPickerPanel';
import ClipboardJS from 'clipboard/dist/clipboard';
import * as _ from 'lodash';
import { SurveyTestLinkModal } from './modals/SurveyTestLinkModal';
import { IThemeService } from '../../services/ThemeService';
import { IAbstractTheme } from '../../domain/surveys/themes/AbstractTheme';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';

@Controller('its.survey.module.surveys', PreviewController.IID, PreviewController)
class PreviewController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.PreviewController';
    static $inject: Array<string> = [
        '$state',
        'itsPageService',
        'itsSurveyApi',
        'itsSurveyService',
        'APPCONFIG',
        'iteToaster',
        'itePhotoService',
        '$mdDialog',
        '$translate',
        '$stateParams',
        '$scope',
        '$document',
        '$timeout',
        '$q',
        '$mdMedia',
        'itsThemeService',
        'itePanelService',
        'iteUserLocalTimeService',
    ];

    public waiting: any = true;
    public submitting: boolean = false;
    public publishing: boolean = false;
    public separateQuestions: boolean = false;
    public previewDevice: string = 'desktop';
    public activeTab: string = 'themes';
    public previewDeviceRotation: string = 'portrait';
    public backgroundStyle: string = 'cover';
    public uploadInProgress: Array<any> = [];
    public surveyUrl: string = null;
    public showPanel: boolean = true;

    // theme values
    public surveyWidth: number = 60;
    public surveyPadding: number = 20;
    public questionMargin: number = 10;
    public surveyBorderRadius: number = 3;
    public surveyBGColor: string = null;
    public surveyShadow: string = null;

    public backgroundAttachmentPositions: Array<{ key: string; label: string }> = [
        {
            key: 'fixed',
            label: this.translate.instant('SURVEYS.THEMES.BACKGROUND_SETTINGS.FIXED'),
        },
        {
            key: 'scroll',
            label: this.translate.instant('SURVEYS.THEMES.BACKGROUND_SETTINGS.SCROLL'),
        },
    ];
    public backgroundSizes: Array<{ key: string; label: string }> = [
        {
            key: 'cover',
            label: this.translate.instant('SURVEYS.THEMES.BACKGROUND_SETTINGS.FULL_SCREEN'),
        },
        {
            key: 'contain',
            label: this.translate.instant('SURVEYS.THEMES.BACKGROUND_SETTINGS.ORIGINAL_RATIO'),
        },
    ];

    public fontWeights: Array<{ key: number; label: string }> = [
        {
            key: 400,
            label: this.translate.instant('SURVEYS.THEMES.NORMAL'),
        },
        {
            key: 600,
            label: this.translate.instant('SURVEYS.THEMES.BOLD'),
        },
    ];

    public surveyDropShadow: Array<{ key: string; label: string }> = [
        {
            key: 'none',
            label: this.translate.instant('GENERAL.NONE'),
        },
        {
            key: '0 1px 5px 0 rgb(0 0 0 / 20%)',
            label: this.translate.instant('GENERAL.SMALL'),
        },
        {
            key: '0 3px 15px 0 rgb(0 0 0 / 20%)',
            label: this.translate.instant('GENERAL.MEDIUM'),
        },
        {
            key: '0 5px 25px 0 rgb(0 0 0 / 20%)',
            label: this.translate.instant('GENERAL.LARGE'),
        },
    ];

    public themes: Array<IAbstractTheme> = [];

    public loadingPreview: boolean = false;

    public fonts: Array<string> = [
        'Arial',
        'Arial Black',
        'Comic Sans MS',
        'Concert One',
        'Courier New',
        'Georgia',
        'Impact',
        'Lato',
        'Merriweather',
        'Montserrat',
        'Noto Sans',
        'Noto Serif',
        'Nunito Sans',
        'Open Sans',
        'Oswald',
        'PT Sans',
        'Prompt',
        'Raleway',
        'Roboto',
        'Source Sans Pro',
        'Tahoma',
        'Times New Roman',
        'Trebuchet MS',
        'Verdana',
        'Work Sans',
    ];

    constructor(
        protected stateService: ng.ui.IStateService,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected surveyService: ISurveyService,
        private config: any,
        protected toaster: IToaster,
        protected photoService: IPhotoService,
        protected dialog: any,
        private translate: ng.translate.ITranslateService,
        protected stateParams: ng.ui.IStateParamsService,
        protected scope: ng.IScope,
        private document: ng.IDocumentService,
        private timeout: ng.ITimeoutService,
        private q: ng.IQService,
        private mdMedia: ng.material.IMedia,
        private themeService: IThemeService,
        private panelService: IPanelService,
        protected userLocalTimeService: IUserLocalTimeService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );

        this.load().then(() => {
            this.themes = themeService.getAvailableThemes();
            this.surveyUrl = this.config.app.endpoint + '/' + this.survey.uuid + '?mode=preview';
            this.preview();
        });

        const clipboard: ClipboardJS = new ClipboardJS('#SurveyCopyLink');
        clipboard.on('success', () => {
            this.toaster.info(this.translate.instant('SURVEYS.THEMES.COPIED_URL_TO_CLIPBOARD'));
        });
        clipboard.on('error', () => {
            this.toaster.error(this.translate.instant('SURVEYS.THEMES.UNABLE_TO_COPY_URL'));
        });
        this.scope.$on('$destroy', () => {
            if (clipboard) {
                clipboard.destroy();
            }
        });
    }

    /**
     * Set theme variables that need to be converted from strings to ints
     */
    public setThemeVariables(): void {
        try {
            this.surveyBorderRadius = parseInt(
                this.survey.theme.customizations['its-theme--background-content']['border-radius'],
                10
            );
        } catch (e) {
            this.surveyBorderRadius = 0;
        }
        try {
            this.surveyWidth = parseInt(
                this.survey.theme.customizations['its-theme--background-content']['max-width'],
                10
            );
        } catch (e) {
            this.surveyWidth = 0;
        }
        try {
            this.surveyPadding = parseInt(
                this.survey.theme.customizations['its-theme--content-container']['margin-top'],
                10
            );
        } catch (e) {
            this.surveyPadding = 0;
        }

        try {
            this.separateQuestions =
                this.survey.theme.customizations['its-theme--background-content']['background-color'] === 'transparent';
        } catch (e) {
            this.separateQuestions = false;
        }

        try {
            this.questionMargin = parseInt(
                this.survey.theme.customizations['its-theme--question-container']['margin-top'],
                10
            );
        } catch (e) {
            this.questionMargin = 0;
        }

        if (this.separateQuestions) {
            try {
                this.surveyBGColor =
                    this.survey.theme.customizations['its-theme--question-container']['background-color'];
            } catch (e) {
                this.surveyBGColor = null;
            }
            try {
                this.surveyShadow = this.survey.theme.customizations['its-theme--question-container']['box-shadow'];
            } catch (e) {
                this.surveyShadow = null;
            }
        } else {
            try {
                this.surveyBGColor =
                    this.survey.theme.customizations['its-theme--background-content']['background-color'];
            } catch (e) {
                this.surveyBGColor = null;
            }
            try {
                this.surveyShadow = this.survey.theme.customizations['its-theme--background-content']['box-shadow'];
            } catch (e) {
                this.surveyShadow = null;
            }
        }
    }

    /**
     * Sets the active tab
     *
     * @param tab
     */
    public setActiveTab(tab: string): void {
        this.activeTab = tab;
        if (this.mdMedia('xs')) {
            this.showPanel = true;
        }
    }

    /**
     *
     */
    public showSettingsPanel(): boolean {
        if (this.mdMedia('gt-xs')) {
            return true;
        }

        return this.showPanel;
    }

    /**
     * Sets the survey background color
     */
    public setSurveyBGColor(): void {
        const colorPickerConfig: any = {
            relativeTo: event.target,
            onSelect: (color: string) => {
                this.surveyBGColor = color;
                this.applySurveyBackgroundStyle();
            },
        };
        this.openColorPicker(colorPickerConfig);
    }

    /**
     * Builds the color picker config based on the element clicked
     * then opens the color picker panel and applies the selected color
     * @param event
     * @param obj
     * @param selector
     * @param property
     */
    public setThemeColor(event: any, obj: any, selector: string, property: string): void {
        const colorPickerConfig: any = {
            relativeTo: event.target,
            onSelect: (color: string) => {
                if (!obj[selector]) {
                    obj[selector] = {};
                }
                obj[selector][property] = color;
                this.applyStyle(selector, property, color);
            },
        };
        this.openColorPicker(colorPickerConfig);
    }

    /**
     * Opens the color picker with the passed config
     * @param colorPickerConfig
     */
    public openColorPicker(colorPickerConfig: any): void {
        this.panelService.openColorPickerPanel(ColorPickerPanel.instantiate(colorPickerConfig));
    }

    /**
     * Sets the settings panel title
     */
    public getTabTitle(): string {
        switch (this.activeTab) {
            case 'themes':
                return 'SURVEYS.MENU.THEMES';
            case 'layout':
                return 'GENERAL.LAYOUT';
            case 'colors':
                return 'SURVEYS.THEMES.COLORS';
            case 'text':
                return 'GENERAL.TEXT';
            case 'branding':
                return 'SURVEYS.THEMES.BRANDING';
            case 'features':
                return 'SURVEYS.SETTINGS.FEATURES';
            default:
                return 'SURVEYS.MENU.THEMES';
        }
    }

    /**
     * Applies the background style
     *
     * @param {string} value
     */
    public applyBackgroundStyle(value: string): void {
        if (value === 'repeat') {
            this.applyStyle('its-theme--background', 'background-repeat', value);
            this.applyStyle('its-theme--background', 'background-size', 'auto');
        } else {
            this.applyStyle('its-theme--background', 'background-size', value);
            this.applyStyle('its-theme--background', 'background-repeat', 'no-repeat');
        }
    }

    /**
     * Apply font style to fonts and placeholder text
     */
    public applyFontStyle(): void {
        this.applyStyle('its-theme--font', 'font-family');
        this.applyStyle(
            '::placeholder',
            'font-family',
            this.survey.theme.customizations['its-theme--font']['font-family']
        );
    }

    /**
     * Apply logo height to footer or header and refresh the iframe styles
     *
     * @param logoLocation
     * @param height
     */
    public applyLogoHeight(logoLocation: string, height: string): void {
        if (!height) {
            if (logoLocation === 'header') {
                this.removeStyle('its-theme--header-logo');
                delete this.survey.theme.customizations['its-theme--header-logo']['height'];
                this.refreshCurrentStyles('its-theme--header-logo');
            } else if (logoLocation === 'footer') {
                this.removeStyle('its-theme--footer-logo');
                delete this.survey.theme.customizations['its-theme--footer-logo']['height'];
                this.refreshCurrentStyles('its-theme--footer-logo');
            }
        } else {
            if (logoLocation === 'header') {
                this.applyStyle('its-theme--header-logo', 'height', height);
            } else if (logoLocation === 'footer') {
                this.applyStyle('its-theme--footer-logo', 'height', height);
            }
        }
    }

    /**
     * Refreshes the styles to the iframe for a specific selector
     *
     * @param selector
     */
    public refreshCurrentStyles(selector: string): void {
        if (this.survey.theme.customizations && this.survey.theme.customizations.hasOwnProperty(selector)) {
            const customizationMap: any = this.survey.theme.customizations[selector];
            const cssProperties: Array<string> = Object.keys(customizationMap);
            for (const property of cssProperties) {
                this.applyStyle(selector, property);
            }
        }
    }

    /**
     * Allow the user to customize a survey by property
     *
     * @param {string} selector
     * @param {string} property
     * @param value
     */
    public applyStyle(selector: string, property: string, value?: any): void {
        if (!this.survey.theme.customizations) {
            this.survey.theme.customizations = {};
        }

        // if we are given the element set its value (mostly used in a no bind situation (div or other none form element)
        if (value && this.survey.theme.customizations) {
            if (_.isEmpty(this.survey.theme.customizations[selector])) {
                this.survey.theme.customizations[selector] = {};
            }
            this.survey.theme.customizations[selector][property] = value;
        }

        if (!this.survey.theme.customizations[selector]) {
            return;
        }

        // inject the new style customizations to the survey
        const frame: HTMLIFrameElement = <HTMLIFrameElement>document.getElementById('previewFrameContent');
        frame.contentWindow.postMessage(
            {
                type: 'style-override',
                selector: selector,
                styles: this.survey.theme.customizations[selector],
            },
            '*'
        );
    }

    /**
     * Sends event to iframe to remove all style for a selector
     *
     * @param selector
     */
    public removeStyle(selector: string): void {
        const frame: HTMLIFrameElement = <HTMLIFrameElement>document.getElementById('previewFrameContent');
        frame.contentWindow.postMessage(
            {
                type: 'style-override-remove',
                selector: selector,
            },
            '*'
        );
    }

    /**
     * Allow the user to change the device preview
     *
     * @param device
     */
    public selectPreviewDevice(device: string): void {
        switch (device) {
            case 'desktop':
            case 'tablet':
            case 'phone':
                this.previewDevice = device;
                break;
            default:
                throw new Error('Invalid preview device');
        }
    }

    /**
     * Allow the user to rotate the device
     */
    public togglePreviewDeviceRotation(): void {
        if (this.previewDeviceRotation === 'portrait') {
            this.previewDeviceRotation = 'landscape';
        } else {
            this.previewDeviceRotation = 'portrait';
        }
    }

    /**
     * Allows the user to render the form with different screen sizes. This will dynamically
     * build the iframe based on the given size via the class property
     *
     */
    public preview(): void {
        if (this.survey) {
            this.loadingPreview = true;
            const iframe: any = document.createElement('iframe');
            iframe.id = 'previewFrameContent';
            iframe.src = this.surveyUrl;

            iframe.allow = 'microphone; camera';

            this.document[0].getElementById('previewFrame').innerHTML = '';
            this.document[0].getElementById('previewFrame').appendChild(iframe);
            this.document[0].getElementById('previewFrameContent').addEventListener('load', () => {
                this.applyThemeCustomizations();
                this.loadingPreview = false;
                this.scope.$apply();
            });
        }
    }

    /**
     * Opens survey test link modal
     */
    public openSurveyTestLinkModal(): void {
        this.dialog.show(
            SurveyTestLinkModal.instantiate({
                locals: {
                    surveyUrl: this.surveyUrl,
                },
            })
        );
    }

    /**
     * Reload the survey to restart it
     */
    public reload(): void {
        this.preview();
    }

    /**
     * Sets the survey theme and applies the customizations
     *
     * @param theme
     */
    public applyTheme(theme: IAbstractTheme): void {
        this.survey.theme = this.themeService.buildThemeBySlug(theme.slug);
        this.applyThemeCustomizations();
    }

    /**
     * Apply the border radius
     */
    public applyBorderRadius(): void {
        this.applyStyle('its-theme--background-content', 'border-radius', this.surveyBorderRadius.toString() + 'px');
        this.applyStyle('its-theme--question-container', 'border-radius', this.surveyBorderRadius.toString() + 'px');
        this.applyStyle('its-theme--title-container', 'border-radius', this.surveyBorderRadius.toString() + 'px');
        this.applyStyle('its-theme--button-container', 'border-radius', this.surveyBorderRadius.toString() + 'px');
    }

    /**
     * Apply the survey width
     */
    public applyWidth(): void {
        this.applyStyle('its-theme--background-content', 'max-width', this.surveyWidth.toString() + '%');
        this.applyStyle('its-theme--background-content', 'width', this.surveyWidth.toString() + '%');
    }

    /**
     * Apply the survey padding
     */
    public applyPadding(): void {
        this.applyStyle('its-theme--content-container', 'margin-top', this.surveyPadding.toString() + 'px');
        this.applyStyle('its-theme--content-container', 'margin-bottom', this.surveyPadding.toString() + 'px');
    }

    /**
     * Apply the survey padding
     */
    public applyQuestionMargin(): void {
        if (this.separateQuestions) {
            this.applyStyle('its-theme--question-container', 'margin-top', this.questionMargin.toString() + 'px');
            this.applyStyle('its-theme--question-container', 'margin-bottom', this.questionMargin.toString() + 'px');
        } else {
            this.applyStyle('its-theme--question-container', 'margin-top', this.questionMargin.toString() + 'px');
            this.applyStyle('its-theme--question-container', 'margin-bottom', this.questionMargin.toString() + 'px');
        }
    }

    public applyLayoutType(): void {
        this.applySurveyBackgroundStyle();
        this.applyDropShadow();
    }

    /**
     *
     */
    public applySurveyBackgroundStyle(): void {
        if (this.separateQuestions) {
            this.applyStyle('its-theme--background-content', 'background-color', 'transparent');
            this.applyStyle('its-theme--question-container', 'background-color', this.surveyBGColor);
            this.applyStyle('its-theme--title-container', 'background-color', this.surveyBGColor);
            this.applyStyle('its-theme--button-container', 'background-color', this.surveyBGColor);
        } else {
            this.applyStyle('its-theme--background-content', 'background-color', this.surveyBGColor);
            this.applyStyle('its-theme--question-container', 'background-color', 'transparent');
            this.applyStyle('its-theme--title-container', 'background-color', 'transparent');
            this.applyStyle('its-theme--button-container', 'background-color', 'transparent');
        }
    }

    public applyDropShadow(): void {
        if (this.separateQuestions) {
            this.applyStyle('its-theme--background-content', 'box-shadow', 'none');
            this.applyStyle('its-theme--question-container', 'box-shadow', this.surveyShadow);
            this.applyStyle('its-theme--title-container', 'box-shadow', this.surveyShadow);
            this.applyStyle('its-theme--button-container', 'box-shadow', this.surveyShadow);
        } else {
            this.applyStyle('its-theme--background-content', 'box-shadow', this.surveyShadow);
            this.applyStyle('its-theme--question-container', 'box-shadow', 'none');
            this.applyStyle('its-theme--title-container', 'box-shadow', 'none');
            this.applyStyle('its-theme--button-container', 'box-shadow', 'none');
        }
    }

    /**
     * Loop through current theme customizations and apply them
     * by sending post message to the <iframe>
     */
    public applyThemeCustomizations(): void {
        this.setThemeVariables();
        this.applyDropShadow();
        this.applySurveyBackgroundStyle();

        if (this.survey.theme && !_.isEmpty(this.survey.theme.customizations)) {
            for (const selector in this.survey.theme.customizations) {
                if (this.survey.theme.customizations.hasOwnProperty(selector)) {
                    for (const property in this.survey.theme.customizations[selector]) {
                        if (this.survey.theme.customizations[selector].hasOwnProperty(property)) {
                            this.applyStyle(selector, property, this.survey.theme.customizations[selector][property]);
                        }
                    }
                }
            }
        }
    }

    /**
     * Allow a user to reset the image they are using (clears it)
     * @param {string} selector
     */
    public resetImage(selector?: string): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translate.instant('GENERAL.DELETE'),
                        confirmText: this.translate.instant('GENERAL.DELETE'),
                        description: this.translate.instant('SURVEYS.THEMES.DELETE_IMAGE_MESSAGE'),
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((response: boolean) => {
                if (response) {
                    if (selector === 'its-theme--header-logo' || selector === 'its-theme--footer-logo') {
                        delete this.survey.theme.customizations[selector];
                        this.removeStyle(selector);
                    }

                    // remove background properties
                    this.applyStyle(selector, 'background-image', 'none'); // only for preview

                    delete this.survey.theme.customizations[selector]['background-attachment'];
                    delete this.survey.theme.customizations[selector]['background-position'];
                    delete this.survey.theme.customizations[selector]['background-repeat'];
                    delete this.survey.theme.customizations[selector]['background-size'];
                }
            });
    }

    /**
     * Reset the survey theme
     */
    public resetTheme(): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translate.instant('SURVEYS.THEMES.RESET_TITLE'),
                        confirmText: this.translate.instant('SURVEYS.RESET_THEME'),
                        description: this.translate.instant('SURVEYS.THEMES.RESET_THEME_MESSAGE'),
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((response: boolean) => {
                if (response) {
                    this.survey.theme = this.themeService.buildThemeBySlug(this.survey.theme.slug);

                    this.saveSurvey();
                }
            });
    }

    public featureChange(type?: string): void {
        this.timeout.cancel(this.waiting);

        this.waiting = this.timeout((): void => {
            this.saveSurvey();
        }, 1500);
    }

    public saveSurvey(): void {
        this.submitting = true;

        this.save(this.survey).then(() => {
            this.showPanel = false;
            this.submitting = false;
            this.reload();
        });
    }

    public publishSurvey(): void {
        this.publish(this.survey);
    }

    /**
     * Update an image
     *
     * @param {File} file
     * @param selector
     * @param full
     */
    public uploadImage(file: File, selector: string, full: boolean): void {
        if (!file) {
            return;
        }

        this.uploadInProgress[selector] = true;
        this.photoService
            .parse(file, null, null, true)
            .then((parsedImage: IParsedImage) => {
                this.surveyApi
                    .addPhoto(parsedImage.base64)
                    .then((response) => {
                        this.applyStyle(selector, 'background-image', 'url(' + response.data.original + ')');
                        this.applyStyle(selector, 'background-position', 'center center');
                        this.applyStyle(selector, 'background-repeat', 'no-repeat');

                        if (full) {
                            this.applyStyle(selector, 'background-attachment', 'fixed');
                            this.applyStyle(selector, 'background-size', 'cover');
                        }

                        this.uploadInProgress[selector] = false;
                    })
                    .catch(() => {
                        this.toaster.error('ERRORS.PHOTO_NOT_ADDED');
                        this.uploadInProgress[selector] = false;
                    });
            })
            .catch(() => {
                this.toaster.error('ERRORS.PHOTO_NOT_ADDED');
                this.uploadInProgress[selector] = false;
            });
    }
}
