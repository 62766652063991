import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IBaseItem } from '../../../../domain/surveys/items/BaseItem';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { FroalaProvider } from '@intouch/its.essential/app/essential/domain/FroalaProvider';

/**
 *  The UI for rendering Group Translation Items
 */
@Component('its.survey.module.surveys', TranslationGroupItem.CID, {
    template: require('/app/modules/surveys/components/translations/TranslationGroupItemComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
        locale: '<',
    },
})
class TranslationGroupItem {
    static IID: string = 'TranslationGroupItem';
    static CID: string = 'itsTranslationGroupItem';
    static $inject: Array<string> = ['APPCONFIG', '$sce', 'iteLogger'];

    protected item: IBaseItem;
    protected locale: string;
    protected froalaOptions: any = null;

    constructor(private config: any, private sce: ng.ISCEService, private logger: ILogger) {
        this.froalaOptions = FroalaProvider.defaultConfig();
        if (this.config.froala && this.config.froala.key) {
            this.froalaOptions.key = this.config.froala.key;
        } else {
            this.logger.warn('Unable to set Froala key');
        }
    }

    /**
     * Trust the string as HTML and allow angular to render it
     *
     * @param {string} html
     * @returns {string}
     */
    public trustAsHtml(html: string): string {
        return this.sce.trustAsHtml(html);
    }
}
