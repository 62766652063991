import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BulkUploaderBase } from '@intouch/its.essential/app/essential/components/bulkuploader/BulkUploaderBase';
import { IFileProcessingService } from '@intouch/its.essential/app/essential/services/FileProcessingService';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';

import { ISurveySession } from '../../../services/SurveySession';
import { IContactList } from '../../../domain/contact-center/ContactList';
import { ISurveyApi } from '../../../api/SurveyApi';

import * as _ from 'lodash';

@Component('its.survey.module.contactCenter', ContactUploader.CID, {
    templateUrl: ContactUploader.getDefaultTemplate(),
    controllerAs: 'vm',
    bindings: ContactUploader.getBindings(),
})
class ContactUploader extends BulkUploaderBase {
    static IID: string = 'ContactUploader';
    static CID: string = 'itsContactUploader';

    static $inject: Array<string> = ['iteFileProcessingService', 'itsSurveySession', 'itsSurveyApi', '$translate'];

    public contactLists: Array<IContactList> = [];
    public uploadInstructionText: string = null;

    public attestations: Array<{ label: string; value: boolean }> = [];

    constructor(
        fileProcessing: IFileProcessingService,
        session: ISurveySession,
        private api: ISurveyApi,
        translate: ng.translate.ITranslateService
    ) {
        super(fileProcessing, translate);
        this.templates[0] = 'app/modules/contact-center/components/ContactUploaderStep1.html';
        this.templates[2] = 'app/modules/contact-center/components/ContactUploaderStep3.html';

        this.uploadInstructionText = translate.instant('CONTACT_CENTER.UPLOAD_BULK_CONTACT');

        this.attestations.push({
            label: translate.instant('CONTACT_CENTER.BULK_UPLOAD.ATTESTATION'),
            value: false,
        });
    }

    public querySearch(search: string): ng.IPromise<Array<IContactList>> {
        return this.api.findContactLists(null, search).then((results: PagedEntities) => {
            return results.getEntities();
        });
    }

    public transformChip(chip: IContactList): IContactList {
        if (_.find(this.contactLists, { uuid: chip.uuid })) {
            return null;
        } else {
            return chip;
        }
    }

    /**
     * Ensure all attestations are satisfied.
     */
    public attestationsSatisfied(): boolean {
        for (const attestation of this.attestations) {
            if (!attestation.value) {
                return false;
            }
        }

        return true;
    }
}
