import * as angular from 'angular';

const module: any = angular.module('its.survey.services', ['its.essential.http']);

import './PageService';
import './SurveySession';
import './TokenTransformerService';
import './NavigationService';
import './SurveyService';
import './SurveyChartService';
import './SurveyIqlService';
import './ElementLibraryService';
import './ThemeService';
import './QuestionTooltipService';
import './BuilderService';

export default module;
