import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Digit, DigitValidation } from '../../../../../../domain/surveys/items/Digit';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';

@Component('its.survey.module.surveys', DigitSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/digit/DigitSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: DigitSettingsComponent.getBindings(),
})
class DigitSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'DigitSettingsComponent';
    static CID: string = 'itsDigitSettings';
    static $inject: string[] = [];

    public item: Digit;

    $onInit(): void {
        if (!this.item.validation) {
            this.item.validation = new DigitValidation();
        }
    }
}
