import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ITemplateLibraryApi } from '@intouch/its.essential/app/essential/api/TemplateLibraryApi';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IQueryFilter, QueryFilter } from '@intouch/its.essential/app/essential/domain/api/QueryFilter';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import {
    ITemplateCategory,
    TemplateCategory,
} from '@intouch/its.essential/app/essential/domain/template-library/TemplateCategory';
import { IPageService } from '../../services/PageService';
import { DatatableSearch } from '@intouch/its.essential/app/essential/domain/datatable/DatatableSearch';
import { ITemplate } from '@intouch/its.essential/app/essential/domain/template-library/Template';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { SurveyCreateModal } from './modals/SurveyCreateModal';
import { ISurvey } from '../../domain/surveys/survey/Survey';
import { SurveyCopyModal } from './modals/SurveyCopyModal';

@Controller('its.survey.module.surveys', CreateController.IID, CreateController)
class CreateController {
    static IID: string = 'its.survey.module.surveys.CreateController';
    static $inject: Array<string> = [
        '$state',
        'iteTemplateLibraryApi',
        '$mdMedia',
        'itsPageService',
        '$translate',
        'iteAccessService',
        '$mdDialog',
    ];

    public loading: boolean = false;
    public hasError: boolean = false;
    public navExpanded: boolean = false;

    public categoryNavItems: Array<ITemplateCategory> = [];
    public templates: Array<ITemplate> = [];
    public templatePager: IPager;
    public categoryFilter: string;

    public datatableSearch: DatatableSearch = new DatatableSearch();

    constructor(
        private stateService: ng.ui.IStateService,
        private templateLibraryApi: ITemplateLibraryApi,
        private media: any,
        private pageService: IPageService,
        private translate: ng.translate.ITranslateService,
        private accessService: IAccessService,
        private dialog: ng.material.IDialogService
    ) {
        // if they don't have permissions they can't do anything on this screen, and shouldn't be here
        if (!this.accessService.getToken().getUser().hasAcl('survey_admin')) {
            this.stateService.go('home.surveys.listing');
            return;
        }

        this.templatePager = Pager.make(
            this.stateService.params['page'],
            this.stateService.params['sort_by'],
            this.stateService.params['order']
        );

        this.categoryFilter = this.stateService.params['category'];
        this.datatableSearch.term = this.stateService.params['search'] || null;

        this.loadCategories();
        this.loadTemplates();
    }

    public navIsExpanded(): boolean {
        return this.media('gt-sm') || this.navExpanded;
    }

    public isLinkActive(category: ITemplateCategory): boolean {
        return (
            (this.categoryFilter === null && category.uuid === 'all-templates') || this.categoryFilter === category.uuid
        );
    }

    public resetListing(): void {
        this.templatePager.currentPage = 1;
        this.goToSelf();
    }

    public back(): void {
        this.stateService.go('home.surveys.listing');
    }

    public next(): void {
        if (this.templatePager.canGoNext()) {
            this.templatePager.currentPage++;
            this.goToSelf();
        }
    }

    public prev(): void {
        if (this.templatePager.canGoPrevious()) {
            this.templatePager.currentPage--;
            this.goToSelf();
        }
    }

    /**
     * @param {string} field
     */
    public toggleSort(field: string): void {
        if (this.templatePager.isCurrentSort(field)) {
            this.templatePager.toggleSortOrder();
        }

        this.templatePager.sortBy = field;
        this.goToSelf();
    }

    /**
     * @param {string} uuid
     */
    public filterByCategory(uuid: string): void {
        this.categoryFilter = uuid;
        this.resetListing();
    }

    public openHelp(): void {
        window.open('https://intouchsurvey.zendesk.com/hc/en-us/articles/360013117493-Create-a-New-Survey', '_blank');
    }

    public createFromScratch(ev: MouseEvent): void {
        this.dialog
            .show(
                SurveyCreateModal.instantiate({
                    targetEvent: ev,
                })
            )
            .then((survey: ISurvey) => {
                this.stateService.go('home.surveys.edit.build.details', { uuid: survey.uuid });
            });
    }

    public createFromTemplate(ev: MouseEvent, template: ITemplate): void {
        this.dialog
            .show(
                SurveyCreateModal.instantiate({
                    targetEvent: ev,
                    locals: {
                        surveyTemplate: template,
                    },
                })
            )
            .then((survey: ISurvey) => {
                this.stateService.go('home.surveys.edit.build.details', { uuid: survey.uuid });
            });
    }

    public copyExisting(ev: MouseEvent): void {
        this.dialog
            .show(
                SurveyCopyModal.instantiate({
                    targetEvent: ev,
                })
            )
            .then((survey: ISurvey) => {
                this.stateService.go('home.surveys.edit.build.details', { uuid: survey.uuid });
            });
    }

    /**
     * Attempt to truncate the descriptions here to a 'natural' length.
     * There is no nice CSS solution for this that we could find.
     *
     * @param {string} description
     * @returns {string}
     */
    public getTemplateRowDescription(description: string): string {
        if (!description) {
            return '';
        }

        if (this.media('gt-md') && description.length > 200) {
            return description.substring(0, 200) + '...';
        }

        if (this.media('gt-xs') && description.length > 150) {
            return description.substring(0, 150) + '...';
        }

        if (description.length > 100) {
            return description.substring(0, 100) + '...';
        }

        return description;
    }

    /**
     * @param {string} description
     * @returns {string}
     */
    public getTemplateCardDescription(description: string): string {
        if (!description) {
            return '';
        }

        if (description.length > 200) {
            return description.substring(0, 200) + '...';
        }

        return description;
    }

    /**
     * @returns {void}
     */
    private goToSelf(): void {
        this.stateService.go(
            'home.surveys.create',
            {
                search: this.datatableSearch.term,
                page: this.templatePager.currentPage,
                sort_by: this.templatePager.sortBy,
                order: this.templatePager.order,
                category: this.categoryFilter,
            },
            {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true,
            }
        );

        this.loadTemplates();
    }

    /**
     * @returns {void}
     */
    private loadTemplates(): void {
        this.loading = true;

        const qf: IQueryFilter = new QueryFilter();
        qf.addPager(this.templatePager);
        qf.addParam('filter[product]', 'survey');

        if (this.categoryFilter) {
            qf.addParam('filter[category_uuids]', [this.categoryFilter]);
        }

        if (this.datatableSearch.term) {
            qf.addParam('search', this.datatableSearch.term);
        }

        this.templateLibraryApi
            .getTemplateListing(qf)
            .then((results: PagedEntities) => {
                this.templates = results.getEntities();
                this.templatePager = results.getPager();
            })
            .catch((error) => {
                this.hasError = true;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    /**
     * @returns {void}
     */
    private loadCategories(): void {
        const qf: IQueryFilter = new QueryFilter();
        qf.addPager(Pager.make(1, 'name', 'asc'));
        qf.addParam('filter[product]', 'survey');

        this.templateLibraryApi
            .getCategoryListing(qf)
            .then((results: PagedEntities) => {
                this.buildTemplateCategoryNavItems(results.getEntities());
            })
            .catch((error) => {
                this.hasError = true;
            });
    }

    /**
     * @returns {void}
     */
    private buildTemplateCategoryNavItems(categories: Array<ITemplateCategory>): void {
        const allTemplates: ITemplateCategory = new TemplateCategory();

        allTemplates.uuid = null;
        allTemplates.name = 'All Templates';
        allTemplates.materialIcon = 'view_quilt';

        categories.unshift(allTemplates);

        this.categoryNavItems = categories;
    }
}
