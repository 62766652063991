import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Input, InputValidation } from '../../../../../../domain/surveys/items/Input';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';

@Component('its.survey.module.surveys', InputSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/input/InputSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: InputSettingsComponent.getBindings(),
})
class InputSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'InputSettingsComponent';
    static CID: string = 'itsInputSettings';
    static $inject: string[] = [];

    public item: Input;

    $onInit(): void {
        if (!this.item.validation) {
            this.item.validation = new InputValidation();
        }
    }

    public setLengthValidity(
        minLengthModelCtrl: ng.INgModelController,
        maxLengthModelCtrl: ng.INgModelController
    ): void {
        const isMinLengthValid: boolean = !this.item.validation || this.item.validation.isMinLengthValid();
        minLengthModelCtrl.$setValidity('customMinLength', isMinLengthValid);
        const isMaxLengthValid: boolean = !this.item.validation || this.item.validation.isMaxLengthValid();
        maxLengthModelCtrl.$setValidity('customMaxLength', isMaxLengthValid);
    }
}
