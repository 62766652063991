import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IMainMenuItem } from '@intouch/its.essential/app/essential/components/SideNavBar';
import { ITopNavDropDownMenuItem } from '@intouch/its.essential/app/essential/components/TopNavBar';
import { IOrganization } from '@intouch/its.essential/app/essential/domain/access/Organization';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IPageService } from '../../services/PageService';
import { ISurveySession } from '../../services/SurveySession';
import { IStateService } from 'angular-ui-router';
import { IGoogleTagManager } from '@intouch/its.essential/app/essential/services/GoogleTagManager';
import moment from 'moment';
import Highcharts from 'highcharts';

@Controller('its.survey.module.main', MainController.IID, MainController)
class MainController {
    static IID: string = 'MainController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        'itsPageService',
        'itsSurveySession',
        '$translate',
        'iteAccessService',
        'APPCONFIG',
        '$sce',
        '$window',
        'iteGoogleTagManager',
        '$rootScope',
        '$state',
        '$location',
    ];

    public menuItems: Array<IMainMenuItem>;
    public topNavMenuItems: Array<ITopNavDropDownMenuItem>;
    public actionItems: Array<any>;
    public logoUrl: string;
    public homeState: string;
    public organizationLogoUrl: string;
    public organization: IOrganization;

    constructor(
        private stateService: ng.ui.IStateService,
        private dialog: ng.material.IDialogService,
        private pageService: IPageService,
        private session: ISurveySession,
        private translate: angular.translate.ITranslateService,
        private accessService: IAccessService,
        private config: any,
        private sce: ng.ISCEService,
        private window: ng.IWindowService,
        private googleTagManager: IGoogleTagManager,
        private rootScope: ng.IRootScopeService,
        private state: IStateService,
        private location: ng.ILocationService
    ) {
        this.setupGoogleTagManager();

        const language: string = accessService.getToken().getUser().language;
        this.translate.use(language ? language : 'en');

        moment.locale(this.convertToMomentLocaleKey(language));

        this.logoUrl = 'assets/images/branding/intouch-survey-leaf.png';
        this.organizationLogoUrl = this.accessService.getOrganization().avatarThumbUrl;
        this.homeState = 'home.survey.forms';

        this.setHighchartGlobalOptions();

        this.translate([
            'GENERAL.LOGOUT',
            'GENERAL.SURVEYS',
            'GENERAL.SWITCH_CLIENTS',
            'GENERAL.SWITCH_APP',
            'GENERAL.CONTACT_CENTER',
            'GENERAL.SETTINGS',
        ]).then((translations) => {
            this.menuItems = [
                {
                    icon: 'my_library_books',
                    state: 'home.surveys.listing',
                    title: translations['GENERAL.SURVEYS'],
                    position: 'top',
                },
            ];

            if (
                this.accessService.getToken().isAdmin() ||
                this.accessService.getToken().getUser().hasAcl('survey_admin') ||
                this.accessService.getToken().getUser().hasAcl('contact_center_admin')
            ) {
                if (this.accessService.getToken().hasFeatureEnabled('survey', 'email-distributions')) {
                    this.menuItems.push({
                        icon: 'contacts',
                        state: 'home.contact-center',
                        title: translations['GENERAL.CONTACT_CENTER'],
                        position: 'top',
                    });
                }

                this.menuItems.push({
                    icon: 'settings',
                    state: 'home.settings.custom-fields',
                    title: translations['GENERAL.SETTINGS'],
                    divider: true,
                    position: 'bottom',
                });
            }

            this.topNavMenuItems = [
                {
                    icon: this.sce.trustAsHtml('&#xE887;'),
                    htmlIcon: true,
                    onClick: (): void => {
                        this.window.open('https://intouchsurvey.zendesk.com/hc/en-us', '_blank');
                    },
                    label: this.translate.instant('GENERAL.GUIDE'),
                    cssClass: 'its-toolbar--divider-above',
                },
            ];

            this.actionItems = [];

            if (accessService.getToken().getUser().organizationCount > 1) {
                this.topNavMenuItems.unshift({
                    icon: 'cached',
                    onClick: (): void => {
                        this.accessService.switchOrganization();
                    },
                    label: translations['GENERAL.SWITCH_CLIENTS'],
                    cssClass: '',
                });
            }
        });
    }

    /**
     * Setup integration to GTM on state changes; passes the URL + state name
     */
    private setupGoogleTagManager(): void {
        this.rootScope.$on('$stateChangeSuccess', (event, toState) => {
            this.googleTagManager.pushTag({
                event: 'pageview',
                pagePath: this.location.url(),
                pageTitle: 'admin.' + toState.name,
            });
        });

        // track initial page load which is not caught by $stateChangeSuccess above. Only happens once on initial load.
        this.googleTagManager.pushTag({
            event: 'pageview',
            pagePath: this.location.url(),
            pageTitle: 'admin.' + this.state.current.name,
        });
    }

    /**
     * Returns the language key formatted for moment
     *
     * @param id
     * @return {any}
     */
    private convertToMomentLocaleKey(id: string): string {
        if (id) {
            return id.toLowerCase().replace('_', '-');
        } else {
            return null;
        }
    }

    private setHighchartGlobalOptions(): void {
        this.translate([
            'GENERAL.MONTHS.SHORT.JAN',
            'GENERAL.MONTHS.SHORT.FEB',
            'GENERAL.MONTHS.SHORT.MAR',
            'GENERAL.MONTHS.SHORT.APR',
            'GENERAL.MONTHS.SHORT.MAY',
            'GENERAL.MONTHS.SHORT.JUN',
            'GENERAL.MONTHS.SHORT.JUL',
            'GENERAL.MONTHS.SHORT.AUG',
            'GENERAL.MONTHS.SHORT.SEP',
            'GENERAL.MONTHS.SHORT.OCT',
            'GENERAL.MONTHS.SHORT.NOV',
            'GENERAL.MONTHS.SHORT.DEC',
        ]).then((translations) => {
            const keys: Array<string> = Object.keys(translations);
            Highcharts.setOptions({
                lang: {
                    shortMonths: keys.map((key) => {
                        return translations[key];
                    }),
                },
                time: {
                    useUTC: true,
                },
            });
        });
    }
}
