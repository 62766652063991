import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import {
    BaseResponseItem,
    BaseResponseItemSettings,
    BaseResponseType,
    IBaseResponseItem,
    IBaseResponseItemSettings,
} from './BaseResponseItem';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { IHelpTextSettings, IHelpTextTranslation } from './shared/HelpText';
import { IconResponse, IIconResponse } from './IconResponse';
import { IImageResponse, ImageResponse } from './ImageResponse';
import { BaseResponse, IBaseResponse } from './BaseResponse';
import { ITranslatedEntity } from '../../ITranslatedEntity';
import {
    IMultiselectSelectionRequirements,
    MultiselectSelectionRequirements,
} from './MultiselectSelectionRequirements';

export type ICheckboxTranslation = IHelpTextTranslation;

export class CheckboxTranslation extends Translation implements ICheckboxTranslation {
    additionalText: string = null;
}

export interface ICheckboxSettings extends IBaseResponseItemSettings, IHelpTextSettings<ICheckboxTranslation> {
    responseType: BaseResponseType;
    layoutDirection: string;
    selectionRequirements: IMultiselectSelectionRequirements;

    getLayoutDirection(invertDirection?: boolean): string;

    enableSelectionRequirements(): void;

    disableSelectionRequirements(): void;

    hasSelectionRequirements(): boolean;
}

export class CheckboxSettings extends BaseResponseItemSettings implements ICheckboxSettings, ITranslatedEntity {
    additionalText: string = null;
    responseType: BaseResponseType = 'labels';
    layoutDirection: string = 'column';
    translations: Array<ICheckboxTranslation> = [];
    selectionRequirements: IMultiselectSelectionRequirements = null;

    public fromJson(jsonObject: any, convertToCamel?: boolean): ICheckboxSettings {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ICheckboxTranslation>(
                CheckboxTranslation,
                jsonObject.translations,
                true
            );
        }

        if (jsonObject['selection_requirements']) {
            this.selectionRequirements = EntityBuilder.buildOne<IMultiselectSelectionRequirements>(
                MultiselectSelectionRequirements,
                jsonObject['selection_requirements'],
                convertToCamel
            );
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.additionalText) {
            keys.push('additionalText');
        }
        return keys;
    }

    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }

    /**
     * Gets the layout direction, returning the opposite if invertDireciton is true
     * @param {boolean} invertDirection
     * @returns {string}
     */
    public getLayoutDirection(invertDirection?: boolean): string {
        if (invertDirection) {
            if (this.layoutDirection === 'column') {
                return 'row';
            } else if (this.layoutDirection === 'row') {
                return 'column';
            }
        } else {
            return this.layoutDirection;
        }
    }

    /**
     * Enables selection requirements
     *
     */
    public enableSelectionRequirements(): void {
        this.selectionRequirements = new MultiselectSelectionRequirements();
    }

    /**
     * Disables selection requirements
     *
     */
    public disableSelectionRequirements(): void {
        this.selectionRequirements = null;
    }

    /**
     * Returns if item has selection requirements
     *
     */
    public hasSelectionRequirements(): boolean {
        return !!this.selectionRequirements;
    }
}

export type ICheckbox = IBaseResponseItem;

export class Checkbox extends BaseResponseItem implements ICheckbox {
    type: string = 'checkbox';
    settings: ICheckboxSettings = new CheckboxSettings();
    responses: Array<IImageResponse | IIconResponse | IBaseResponse> = [];

    /**
     * Returns if the item label can contain a token which can reference
     * another item's response
     */
    get hasTokenReference(): boolean {
        return true;
    }

    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);
        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<ICheckboxSettings>(CheckboxSettings, jsonObject.settings, true);
        }

        if (jsonObject.responses) {
            switch (this.settings.responseType) {
                case 'images':
                case 'labels_images':
                    this.responses = EntityBuilder.buildMany<IImageResponse>(ImageResponse, jsonObject.responses, true);
                    break;
                case 'icons':
                case 'labels_icons':
                    this.responses = EntityBuilder.buildMany<IIconResponse>(IconResponse, jsonObject.responses, true);
                    break;
                default:
                    this.responses = EntityBuilder.buildMany<IBaseResponse>(BaseResponse, jsonObject.responses, true);
            }
            // only used to display pre-selected radio in the live preview
            const defaultResponse: IImageResponse | IIconResponse | IBaseResponse = this.getDefaultSelected();
            if (defaultResponse) {
                this.value = defaultResponse;
            }
        }

        return this;
    }

    /**
     * Gets default selected response
     *
     * @returns {IBaseResponse | IImageResponse | IIconResponse}
     */
    public getDefaultSelected(): IBaseResponse | IImageResponse | IIconResponse {
        for (const response of this.getResponses()) {
            if (response.defaultSelected) {
                return response;
            }
        }
        return null;
    }

    /**
     *  Gets all responses
     * @returns {Array<IImageResponse | IIconResponse | IBaseResponse>}
     */
    public getResponses(): Array<IBaseResponse | IImageResponse | IIconResponse> {
        return this.responses;
    }

    /**
     * Converts responses to the passed in type
     */
    public convertResponses(): void {
        switch (this.settings.responseType) {
            case 'images':
            case 'labels_images':
                this.responses = EntityBuilder.buildMany<IImageResponse>(ImageResponse, this.responses, true);
                break;
            case 'icons':
            case 'labels_icons':
                this.responses = EntityBuilder.buildMany<IIconResponse>(IconResponse, this.responses, true);
                break;
            default:
                this.responses = EntityBuilder.buildMany<IBaseResponse>(BaseResponse, this.responses, true);
                break;
        }
    }

    /**
     * Adds responses to the item.
     * Defaults to adding one empty response at the end of the array of responses.
     *
     * @param {number} count
     * @param {Array<string>} labels
     * @param {boolean} isOther
     */
    public addResponses(count: number = 1, labels: Array<string> = [], isOther: boolean = false): void {
        for (let i: number = 0; i < count; i++) {
            const label: string = labels.length > i ? labels[i] : '';
            switch (this.settings.responseType) {
                case 'images':
                case 'labels_images':
                    this.responses.push(ImageResponse.make(label, isOther));
                    break;
                case 'icons':
                case 'labels_icons':
                    this.responses.push(IconResponse.make(label, isOther));
                    break;
                default:
                    this.responses.push(BaseResponse.make(label, isOther));
            }
        }
    }
}
