import { ProgressStatus } from '@intouch/its.essential/app/essential/domain/ProgressStatus';
import { IHealthCalculator } from './IHealthCalcuator';
import { ISurvey } from '../Survey';
import { IBaseItem } from '../../items/BaseItem';
import { HealthStatus } from './HealthStatus';
import { IText } from '../../items/Text';

export interface IQuestionAnalysisReport {
    matrix: any;
}

export class QuestionAnalysis implements IHealthCalculator {
    // minutes estimated to complete survey
    public percentage: number = null;
    public data: IQuestionAnalysisReport;
    public score: number;
    public scoreText: string;

    constructor(survey: ISurvey, private translate: ng.translate.ITranslateService) {
        if (!survey) {
            throw new Error('Missing survey in constructor');
        }
        if (!translate) {
            throw new Error('Missing translation service in constructor');
        }

        this.calculate(survey);
    }

    public calculate(survey: ISurvey): void {
        const items: Array<IBaseItem> = survey.getItems(() => {
                return true;
            }),
            questionWarnings: any = [];
        let matrixCount: number = 0,
            locationCount: number = 0,
            textAreaVideoAudioCount: number = 0,
            videoCount: number = 0;

        for (const item of items) {
            if (item.type === 'matrix') {
                matrixCount++;
            } else if (item.type === 'textarea' || item.type === 'video' || item.type === 'audio') {
                if (item.type === 'video') {
                    videoCount++;
                }
                textAreaVideoAudioCount++;
            } else if (item.type === 'location') {
                locationCount++;
            }

            const labelToDisplay: string =
                item.label.length > 30 ? item.label.slice(0, 30) + ' ... - ' : item.label + ' - ';

            if (item.type === 'text') {
                const contentSplit: Array<string> = (<IText>item).content.split(' ');
                if (contentSplit.length > 90) {
                    questionWarnings.push({
                        question: item,
                        status: HealthStatus.Unhealthy,
                        text:
                            labelToDisplay +
                            this.translate.instant('DISTRIBUTIONS.HEALTH.QUESTION_ANALYSIS.DESCRIPTIVE_TEXT_BAD'),
                    });
                } else if (contentSplit.length > 60) {
                    questionWarnings.push({
                        question: item,
                        status: HealthStatus.Average,
                        text:
                            labelToDisplay +
                            this.translate.instant('DISTRIBUTIONS.HEALTH.QUESTION_ANALYSIS.DESCRIPTIVE_TEXT_OK'),
                    });
                }
            } else {
                const labelSplit: Array<string> = item.label.split(' ');
                if (labelSplit.length >= 25) {
                    questionWarnings.push({
                        question: item,
                        status: HealthStatus.Unhealthy,
                        text:
                            labelToDisplay +
                            this.translate.instant('DISTRIBUTIONS.HEALTH.QUESTION_ANALYSIS.QUESTION_TEXT_BAD'),
                    });
                } else if (labelSplit.length > 15) {
                    questionWarnings.push({
                        question: item,
                        status: HealthStatus.Average,
                        text:
                            labelToDisplay +
                            this.translate.instant('DISTRIBUTIONS.HEALTH.QUESTION_ANALYSIS.QUESTION_TEXT_OK'),
                    });
                }
            }
        }
        if (matrixCount >= 2) {
            questionWarnings.push({
                status: HealthStatus.Unhealthy,
                text: this.translate.instant('DISTRIBUTIONS.HEALTH.QUESTION_ANALYSIS.MATRIX_BAD'),
            });
        } else if (matrixCount === 1) {
            questionWarnings.push({
                status: HealthStatus.Average,
                text: this.translate.instant('DISTRIBUTIONS.HEALTH.QUESTION_ANALYSIS.MATRIX_OK'),
            });
        }

        if (locationCount >= 2) {
            questionWarnings.push({
                status: HealthStatus.Average,
                text: this.translate.instant('DISTRIBUTIONS.HEALTH.QUESTION_ANALYSIS.LOCATION_OK'),
            });
        }

        if (textAreaVideoAudioCount >= 3) {
            questionWarnings.push({
                status: HealthStatus.Unhealthy,
                text: this.translate.instant('DISTRIBUTIONS.HEALTH.QUESTION_ANALYSIS.VIDEO_AUDIO_TEXT_BAD'),
            });
        } else if (textAreaVideoAudioCount === 2) {
            questionWarnings.push({
                status: HealthStatus.Average,
                text: this.translate.instant('DISTRIBUTIONS.HEALTH.QUESTION_ANALYSIS.VIDEO_AUDIO_TEXT_OK'),
            });
        }

        this.data = questionWarnings;
        this.score = questionWarnings.length;

        if (this.score <= 0) {
            this.percentage = 100;
        } else {
            let percentageCalc: number = this.score * 10;
            percentageCalc = percentageCalc > 100 ? 100 : percentageCalc;
            this.percentage = 100 - percentageCalc;
        }

        this.scoreText = this.percentage.toString() + '%';
    }

    public getStatus(): ProgressStatus {
        if (this.percentage >= 80) {
            return ProgressStatus.Excellent;
        } else if (this.percentage >= 40) {
            return ProgressStatus.Ok;
        } else {
            return ProgressStatus.Bad;
        }
    }

    public isStatusHealthy(status: HealthStatus): boolean {
        return status === HealthStatus.Healthy;
    }

    public isStatusAverage(status: HealthStatus): boolean {
        return status === HealthStatus.Average;
    }

    public isStatusUnhealthy(status: HealthStatus): boolean {
        return status === HealthStatus.Unhealthy;
    }
}
