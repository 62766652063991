import * as angular from 'angular';

const module: any = angular.module('its.survey.module.settings', []);

import './_configuration';
import './SettingsController';
import './custom-fields/CustomFieldsController';
import './custom-domains/CustomDomainsController';
import './fatigue-protection/FatigueProtectionController';
import './mail-senders/MailSendersController';

export default module;
