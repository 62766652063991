import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { ISurveyApi } from '../../../api/SurveyApi';
import { Contact, IContact } from '../../../domain/contact-center/Contact';
import { ILanguage, LanguageProvider } from '../../../domain/LanguageProvider';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { INavigationService } from '../../../services/NavigationService';
import { IPageService } from '../../../services/PageService';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { PhoneUtils } from '@intouch/its.essential/app/essential/utils/PhoneUtils';
import { ICustomField } from '../../../domain/settings/CustomField';
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import { IIql, Iql } from '@intouch/iql-ts-sdk/src/domain/Iql';
import { FilterGroup } from '@intouch/iql-ts-sdk/src/domain/filters/FilterGroup';
import { StringFilter } from '@intouch/iql-ts-sdk/src/domain/filters/StringFilter';
import { Column } from '@intouch/iql-ts-sdk/src/domain/Column';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { ReportRecord } from '@intouch/its.essential/app/essential/iql/record/ReportRecord';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IRecord } from '@intouch/its.essential/app/essential/iql/record/Record';
import { IQuestion } from '@intouch/its.essential/app/essential/iql/record/Question';
import { ExistsFilter } from '@intouch/iql-ts-sdk/src/domain/filters/ExistsFilter';
import { CustomFieldCreateModal } from '../../settings/custom-fields/CustomFieldCreateModal';
import { Trace } from '@intouch/its.essential/app/essential/iql/record/trace/Trace';
import moment from 'moment';
import * as angular from 'angular';
import { ContactListDistributeSurveyModal } from '../contact-lists/ContactListDistributeSurveyModal';

export interface IPanelState {
    loading: boolean;
    loadingMore: boolean;
    canLoadMore: boolean;
    error: boolean;
    pager: IPager;
}

export interface IHistoryPanelState extends IPanelState {
    data: Array<ReportRecord>;
}

export interface IDistributionHistoryPanelSate extends IPanelState {
    data: Array<Trace>;
}

export interface ICommentsPanelState extends IPanelState {
    data: Array<ICommentEntry>;
    buffer: Array<ICommentEntry>;
}

export interface IContactHistoryPanelState extends IPanelState {
    data: Array<ReportRecord>;
}

export interface ICommentEntry {
    synthesisId: string;
    dateOfDocument: string;
    question: IQuestion;
}

/**
 * Contact Center Listing Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.contactCenter', ContactDetailsController.IID, ContactDetailsController)
class ContactDetailsController {
    static IID: string = 'its.survey.module.contactCenter.ContactDetailsController';
    static $inject: Array<string> = [
        '$state',
        '$stateParams',
        '$scope',
        'iteLogger',
        'iteToaster',
        '$mdDialog',
        '$translate',
        'itsNavigationService',
        'itsPageService',
        'itsSurveyApi',
        'iteIqlApi',
        '$q',
        'iteAccessService',
        'APPCONFIG',
    ];

    public contact: IContact = null;
    public originalContact: IContact = null;
    public availableLanguages: Array<ILanguage> = [];
    public editing: boolean = false;
    public creating: boolean = false;
    public isAdmin: boolean = false;
    public hasContactAdmin: boolean = false;
    public submitting: boolean = false;
    public contactContactLists: string;
    public hasError: boolean = false;
    public customFields: Array<ICustomField> = [];
    public history: IHistoryPanelState;
    public comments: ICommentsPanelState;
    public contactHistory: IContactHistoryPanelState;
    public recentSentimentRecord: IRecord = null;
    public recentNpsRecord: IRecord = null;
    public timezone: string = null;
    public distributionHistory: IDistributionHistoryPanelSate;
    public hasCustomFieldsEnabled: boolean = false;

    constructor(
        private state: ng.ui.IStateService,
        private stateParams: ng.ui.IStateParamsService,
        private scope: ng.IScope,
        private logger: ILogger,
        private toaster: IToaster,
        private dialog: ng.material.IDialogService,
        private translate: ng.translate.ITranslateService,
        private navigationService: INavigationService,
        private pageService: IPageService,
        private api: ISurveyApi,
        private iqlApi: IIqlApi,
        private q: ng.IQService,
        private accessService: IAccessService,
        private config: any
    ) {
        this.availableLanguages = LanguageProvider.getAvailableLanguages();
        this.timezone = this.accessService.getToken().getUser().timezone;

        this.hasCustomFieldsEnabled = this.accessService.getOrganization().hasEnabledFeature('survey', 'custom-fields');

        this.isAdmin = this.accessService.getToken().isAdmin();
        this.hasContactAdmin = this.accessService.getToken().getUser().hasAcl('contact_center_admin');

        this.history = {
            loading: false,
            loadingMore: false,
            canLoadMore: true,
            error: false,
            pager: Pager.make(1, 'meta.date_of_document', 'desc', 5),
            data: [],
        };

        this.distributionHistory = {
            loading: false,
            loadingMore: false,
            canLoadMore: true,
            error: false,
            pager: Pager.make(1, 'data.created_at', 'desc', 5),
            data: [],
        };

        // set back route to a contact list if you came from a contact list
        if (this.stateParams['contact_list']) {
            this.navigationService.setBackRoute('home.contact-center', {
                filters: 'filter[contact_lists.uuid]=' + this.stateParams['contact_list'],
            });
        } else {
            this.navigationService.setBackRoute('home.contact-center');
        }

        this.comments = {
            loading: false,
            loadingMore: false,
            canLoadMore: true,
            error: false,
            pager: Pager.make(1, 'meta.date_of_document', 'desc', 2),
            data: [],
            buffer: [],
        };

        this.contactHistory = {
            loading: false,
            loadingMore: false,
            canLoadMore: true,
            error: false,
            pager: Pager.make(1, 'data.created_at', 'desc', 5),
            data: [],
        };

        this.load();
    }

    /**
     * Submit form name to be created to the API
     */
    public submit(): void {
        this.logger.info('Submitting contact: ' + this.contact.email);
        this.submitting = true;

        if (this.creating) {
            this.api
                .createContact(this.contact)
                .then((contact) => {
                    this.contact = contact;
                    this.toaster.success('Contact created!');
                    this.navigationService.goBack();
                    this.creating = false;
                })
                .catch((error: IErrorResponse) => {
                    // if there are more errors, change if/else to switch
                    if (error && error.type && error.type === 'ContactAlreadyExistsException') {
                        this.toaster.error('ERRORS.CONTACT_CENTER.FAILED_CONTACT_UNIQUE');
                    } else {
                        this.toaster.error('ERRORS.FAILED_CONTACT_UPDATE');
                    }
                })
                .finally(() => {
                    this.submitting = false;
                    this.editing = true;
                });
        } else {
            this.api
                .updateContact(this.contact)
                .then((updatedContact: IContact) => {
                    this.contact = updatedContact;
                    this.originalContact = angular.copy(updatedContact);
                    this.contactContactLists = this.contact.getConcatContactLists();
                    this.logger.info('Updated ' + updatedContact.email + ' - ' + updatedContact.uuid);
                    this.toaster.success(
                        this.translate.instant('CONTACT_CENTER.MESSAGES.UPDATED_CONTACT', {
                            email: updatedContact.email,
                        })
                    );
                })
                .catch(() => {
                    this.toaster.error('ERRORS.FAILED_CONTACT_UPDATE');
                })
                .finally(() => {
                    this.submitting = false;
                });
        }
    }

    /**
     * Cancel editing, reset contact changes to original data
     */
    public cancel(): void {
        this.contact = angular.copy(this.originalContact);
        this.editing = false;
    }

    /**
     * Open distribute survey modal
     *
     */
    public distributeSurvey(): void {
        this.dialog.show(
            ContactListDistributeSurveyModal.instantiate({
                locals: {
                    contact: this.contact,
                },
            })
        );
    }

    /**
     * Manually reset custom validation state
     *
     * @param {angular.INgModelController} element
     * @param {string} key
     */
    public resetValidity(element: ng.INgModelController, key: string): void {
        element.$setValidity(key, true);
    }

    public mapContactLists(): void {
        this.contact.contactLists = this.contact.contactLists.map((i) => {
            return {
                name: i.name,
                uuid: i.uuid,
            };
        });
    }

    /**
     * Toggle subscription of a contact
     *
     * @param {IContact} contact
     */
    public toggleSubscription(contact: IContact): void {
        if (contact.status === 'unsubscribed') {
            // call subscribe
            this.api
                .subscribeContact(contact)
                .then((updatedContact: IContact) => {
                    this.toaster.success(
                        this.translate.instant('CONTACT_CENTER.MESSAGES.UPDATED_CONTACT', {
                            email: updatedContact.email,
                        })
                    );
                    this.contact = updatedContact;
                })
                .catch(() => {
                    this.toaster.error('ERRORS.FAILED_CONTACT_UPDATE');
                });
        } else {
            // call unsubscribe
            this.api
                .unsubscribeContact(contact)
                .then((updatedContact: IContact) => {
                    this.toaster.success(
                        this.translate.instant('CONTACT_CENTER.MESSAGES.UPDATED_CONTACT', {
                            email: updatedContact.email,
                        })
                    );
                    this.contact = updatedContact;
                })
                .catch(() => {
                    this.toaster.error('ERRORS.FAILED_CONTACT_UPDATE');
                });
        }
    }

    /**
     * Creates a custom field
     * @param {boolean} createAnother
     */
    public createCustomField(createAnother: boolean = false): void {
        this.dialog
            .show(
                CustomFieldCreateModal.instantiate({
                    locals: {
                        createAnother: createAnother,
                    },
                })
            )
            .then((data: { customField: ICustomField; createAnother: boolean }) => {
                this.toaster.info(
                    this.translate.instant('CONTACT_CENTER.SETTINGS.CREATED_CUSTOM_FIELD', {
                        label: data.customField.label,
                    })
                );
                this.load(false);
                if (data.createAnother) {
                    this.createCustomField(true);
                }
            });
    }

    /**
     * Remove a contact
     *
     * @param {IContact} contact
     */
    public remove(contact: IContact): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        cancelText: this.translate.instant('GENERAL.CANCEL'),
                        confirmText: this.translate.instant('GENERAL.DELETE'),
                        description: this.translate.instant('CONTACT_CENTER.DELETE_CONTACT_TEXT_UNSUBSCRIBE'),
                        title: this.translate.instant('CONTACT_CENTER.DELETE_CONTACT_CONFIRM'),
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((confirmation: boolean) => {
                if (confirmation) {
                    this.api
                        .deleteContact(contact.uuid)
                        .then(() => {
                            this.toaster.info(
                                this.translate.instant('CONTACT_CENTER.MESSAGES.DELETED_CONTACT', {
                                    email: contact.email,
                                })
                            );
                            this.state.go('home.contact-center');
                        })
                        .catch(() => {
                            this.toaster.error('ERRORS.FAILED_CONTACT_DELETE');
                        });
                }
            });
    }

    /**
     * Loads the contact's most recent highlights.
     */
    public loadHighlights(): void {
        this.q.all([this.getRecentSentiment(), this.getRecentNps()]).then((responses: Array<PagedEntities>) => {
            if (responses[0].getEntities().length > 0) {
                this.recentSentimentRecord = responses[0].getEntities()[0];
            }

            if (responses[1].getEntities().length > 0) {
                this.recentNpsRecord = responses[1].getEntities()[0];
            }
        });
    }

    /**
     * Loads the contact's survey submission history.
     */
    public loadHistory(): void {
        this.history.loading = true;

        this.getHistory()
            .then((results: PagedEntities) => {
                this.history.data = results.getEntities();
                this.history.pager = results.getPager();
                this.history.canLoadMore = this.history.pager.canGoNext();
            })
            .catch(() => {
                this.history.error = true;
            })
            .finally(() => {
                this.history.loading = false;
            });
    }

    /**
     * Loads next page of the contact's survey submission history.
     */
    public loadMoreHistory(): void {
        this.history.pager.currentPage++;
        this.history.loadingMore = true;

        this.getHistory()
            .then((results: PagedEntities) => {
                this.history.data = this.history.data.concat(results.getEntities());
                this.history.pager = results.getPager();
                this.history.canLoadMore = this.history.pager.canGoNext();
            })
            .catch(() => {
                this.toaster.info('There was an error fetching contact history');
            })
            .finally(() => {
                this.history.loadingMore = false;
            });
    }

    /**
     * Loads the contact's distribution history.
     */
    public loadDistributionHistory(): void {
        this.distributionHistory.loading = true;

        this.getDistributionHistory()
            .then((results: PagedEntities) => {
                this.distributionHistory.data = results.getEntities(); // help
                this.distributionHistory.pager = results.getPager();
                this.distributionHistory.canLoadMore = this.distributionHistory.pager.canGoNext();
            })
            .catch(() => {
                this.distributionHistory.error = true;
            })
            .finally(() => {
                this.distributionHistory.loading = false;
            });
    }

    /**
     * Loads next page of the contact's survey submission history.
     */
    public loadMoreDistributionHistory(): void {
        this.distributionHistory.pager.currentPage++;
        this.distributionHistory.loadingMore = true;

        this.getDistributionHistory()
            .then((results: PagedEntities) => {
                this.distributionHistory.data = this.distributionHistory.data.concat(results.getEntities());
                this.distributionHistory.pager = results.getPager();
                this.distributionHistory.canLoadMore = this.distributionHistory.pager.canGoNext();
            })
            .catch(() => {
                this.toaster.info('There was an error fetching contact history');
            })
            .finally(() => {
                this.distributionHistory.loadingMore = false;
            });
    }

    /**
     * Loads the comments (textarea responses) for the contact.
     */
    public loadComments(): void {
        this.comments.loading = true;

        this.getComments()
            .then((results: PagedEntities) => {
                this.comments.buffer = this.parseComments(results.getEntities());
                this.loadCommentsFromBuffer();

                this.comments.pager = results.getPager();
                this.comments.canLoadMore = this.comments.buffer.length > 0 || this.comments.pager.canGoNext();
            })
            .catch(() => {
                this.comments.error = true;
            })
            .finally(() => {
                this.comments.loading = false;
            });
    }

    /**
     * Loads more comments. From the buff if possible or from the api.
     */
    public loadMoreComments(): void {
        if (
            this.comments.buffer.length >= this.comments.pager.perPage ||
            (this.comments.buffer.length > 0 && !this.comments.pager.canGoNext())
        ) {
            this.loadCommentsFromBuffer();
            return;
        }

        if (this.comments.pager.canGoNext()) {
            this.comments.pager.currentPage++;
            this.comments.loadingMore = true;

            this.getComments()
                .then((results: PagedEntities) => {
                    this.comments.buffer = this.parseComments(results.getEntities());
                    this.loadCommentsFromBuffer();

                    this.comments.pager = results.getPager();
                    this.comments.canLoadMore = this.comments.buffer.length > 0 || this.comments.pager.canGoNext();
                })
                .catch(() => {
                    this.toaster.info('There was an error fetching contact comments');
                })
                .finally(() => {
                    this.comments.loadingMore = false;
                });
        }
    }

    /**
     * Loads the contact history for the contact.
     */
    public loadContactHistory(): void {
        this.contactHistory.loading = true;

        this.getContactHistory()
            .then((results: PagedEntities) => {
                this.contactHistory.data = results.getEntities();
                this.contactHistory.pager = results.getPager();
                this.contactHistory.canLoadMore = this.contactHistory.pager.canGoNext();
            })
            .catch(() => {
                this.contactHistory.error = true;
            })
            .finally(() => {
                this.contactHistory.loading = false;
            });
    }

    /**
     * Loads next page of the contact's survey submission history.
     */
    public loadMoreContactHistory(): void {
        this.contactHistory.pager.currentPage++;
        this.contactHistory.loadingMore = true;

        this.getContactHistory()
            .then((results: PagedEntities) => {
                this.contactHistory.data = this.contactHistory.data.concat(results.getEntities());
                this.contactHistory.pager = results.getPager();
                this.contactHistory.canLoadMore = this.contactHistory.pager.canGoNext();
            })
            .catch(() => {
                this.toaster.info('There was an error fetching contact history');
            })
            .finally(() => {
                this.contactHistory.loadingMore = false;
            });
    }

    /**
     * Check to see if the user has access to IQ.
     */
    public userHasIq(): boolean {
        return this.accessService.getToken().getProductsSlugs().indexOf('intelligence') > -1;
    }

    /**
     * Set form validity on phone number
     *
     * @param form
     * @param {string} elementId
     */
    public validatePhoneNumber(form: any, elementId: string): void {
        form.$setValidity(elementId, PhoneUtils.isPhoneNumberValid(this.contact.phoneNumber));
    }

    /**
     * Naviagate to a record in IQ via the given SynthesisId
     *
     * @param synthesisId
     */
    public goToRecord(synthesisId: string): void {
        const url: string =
            this.config.products.urls.intelligence.app +
            this.accessService.getToken().getRawToken() +
            '?redirect=/records/record/' +
            synthesisId;

        window.open(url, '_blank');
    }

    /**
     * Returns the sentiment icon
     *
     * @param {string} sentimentLabel
     * @returns {string}
     */
    public getSentimentIcon(sentimentLabel: string): string {
        switch (sentimentLabel) {
            case 'positive':
                return 'sentiment_very_satisfied';
            case 'neutral':
                return 'sentiment_satisfied';
            case 'negative':
                return 'sentiment_very_dissatisfied';
            default:
                return 'sentiment_satisfied';
        }
    }

    /**
     * Returns the class for the sentiment icon
     *
     * @param {string} sentimentLabel
     * @returns {string}
     */
    public getSentimentClass(sentimentLabel: string): string {
        switch (sentimentLabel) {
            case 'positive':
            case 'promoter':
                return 'its-contact--sentiment-positive';
            case 'neutral':
            case 'passive':
                return 'its-contact--sentiment-neutral';
            case 'negative':
            case 'detractor':
                return 'its-contact--sentiment-negative';
            default:
                return 'its-contact--sentiment-neutral';
        }
    }

    public shouldShowRecentHighlights(): boolean {
        return !!(this.recentNpsRecord && this.recentSentimentRecord);
    }

    /**
     * Get the YYYY-MM-DD part of a timestamp
     *
     * @param {string} date
     *
     * @returns {string}
     */
    public formatTraceDate(date: string): string {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    /**
     * Format the trace action value for use as translation key
     *
     * @param action
     */
    public formatActionForTranslation(action: string): string {
        return action.replace('-', '_').toUpperCase();
    }

    /**
     * Loads contact comments from the buffer.
     */
    private loadCommentsFromBuffer(): void {
        this.comments.data = this.comments.data.concat(this.comments.buffer.splice(0, this.comments.pager.perPage));
    }

    private load(initNewContact: boolean = true): void {
        this.pageService.startLoading();

        if ((<string>this.state.current.url).indexOf('/contact-create') > -1) {
            if (initNewContact) {
                this.contact = new Contact();
            }

            this.creating = true;

            if (this.stateParams['contact_list']) {
                this.api.getContactList(this.stateParams['contact_list']).then((contactList) => {
                    this.contact.addContactList(contactList);
                });
            }

            this.api.findAllCustomFields().then((customFieldsResult: PagedEntities) => {
                this.customFields = customFieldsResult.getEntities();
            });
            this.pageService.stopLoading();
        } else {
            this.api
                .getContact(this.stateParams['uuid'])
                .then((contact: IContact) => {
                    this.api
                        .findAllCustomFields()
                        .then((customFieldsResult: PagedEntities) => {
                            this.editing = true;
                            this.contact = contact;
                            this.originalContact = angular.copy(this.contact);
                            this.contactContactLists = this.contact.getConcatContactLists();
                            this.customFields = customFieldsResult.getEntities();
                            if (contact.firstName) {
                                this.pageService.setPageTitle(
                                    contact.firstName + ' ' + contact.lastName ? contact.lastName : ''
                                );
                            } else {
                                this.pageService.setPageTitle(
                                    this.translate.instant('CONTACT_CENTER.CONTACT_SETTINGS')
                                );
                            }
                            this.hasError = false;

                            this.loadHighlights();
                            this.loadHistory();
                            this.loadComments();
                            this.loadDistributionHistory();
                            this.loadContactHistory();
                        })
                        .catch(() => {
                            this.hasError = true;
                        })
                        .finally(() => {
                            this.pageService.stopLoading();
                        });
                })
                .catch((error: IErrorResponse) => {
                    this.toaster.error('ERRORS.CONTACT_CENTER.FAILED_CONTACT_LOAD');
                    this.logger.warn(
                        'ERRORS.CONTACT_CENTER.FAILED_CONTACT_LOAD',
                        'Failed to load contact : ' + this.stateParams['uuid'] + ' ' + error.message
                    );
                    this.pageService.stopLoading();
                });
        }
    }

    private getHistory(): ng.IPromise<PagedEntities> {
        const defer: ng.IDeferred<PagedEntities> = this.q.defer();
        const query: IIql = new Iql()
            .dataSource('records')
            .select([
                new Column('meta.reference->program_name', 'Program Name', null, 'string'),
                new Column('meta->date_of_document', 'Date of Document', null, 'datetime'),
            ])
            .filter(
                new FilterGroup(Iql.AND, [
                    new StringFilter('meta->product', Iql.EQUAL, 'survey'),
                    new StringFilter('meta.contact->uuid', Iql.EQUAL, this.contact.uuid),
                ])
            );

        this.iqlApi
            .query(query, this.history.pager, { ref: 'contact-history' })
            .then((result: PagedEntities) => {
                defer.resolve(result);
            })
            .catch((error: IErrorResponse) => {
                defer.reject(error);
            });

        return defer.promise;
    }

    private getComments(): ng.IPromise<PagedEntities> {
        const defer: ng.IDeferred<PagedEntities> = this.q.defer();
        const query: IIql = new Iql()
            .dataSource('records')
            .filter(
                new FilterGroup(Iql.AND, [
                    new StringFilter('meta->product', Iql.EQUAL, 'survey'),
                    new StringFilter('meta.contact->uuid', Iql.EQUAL, this.contact.uuid),
                    new StringFilter('data.questions.*->type', Iql.EQUAL, 'textarea'),
                ])
            );

        this.iqlApi
            .query(query, this.comments.pager, { ref: 'contact-comments' })
            .then((result: PagedEntities) => {
                defer.resolve(result);
            })
            .catch((error: IErrorResponse) => {
                defer.reject(error);
            });

        return defer.promise;
    }

    private getContactHistory(): ng.IPromise<PagedEntities> {
        const defer: ng.IDeferred<PagedEntities> = this.q.defer();
        const query: IIql = new Iql()
            .dataSource('trace')
            .select([
                new Column('data->actor.name', 'Actor', null, 'string'),
                new Column('data->action', 'Action', null, 'string'),
                new Column('data->domain', 'Domain', null, 'string'),
                new Column('data->created_at', 'Created At', null, 'datetime'),
            ])
            .filter(
                new FilterGroup(Iql.AND, [
                    new StringFilter('data->product', Iql.EQUAL, 'survey'),
                    new StringFilter('data->domain', Iql.EQUAL, 'contact'),
                    new StringFilter('data.targets.*->type', Iql.EQUAL, 'contact'),
                    new StringFilter('data.targets.*->id', Iql.EQUAL, this.contact.uuid),
                ])
            );

        this.iqlApi
            .query(query, this.contactHistory.pager, { ref: 'contact-history-trace' })
            .then((result: PagedEntities) => {
                defer.resolve(result);
            })
            .catch((error: IErrorResponse) => {
                defer.reject(error);
            });

        return defer.promise;
    }

    private getRecentSentiment(): ng.IPromise<PagedEntities> {
        const defer: ng.IDeferred<PagedEntities> = this.q.defer();
        const query: IIql = new Iql()
            .dataSource('records')
            .filter(
                new FilterGroup(Iql.AND, [
                    new StringFilter('meta->product', Iql.EQUAL, 'survey'),
                    new StringFilter('meta.contact->uuid', Iql.EQUAL, this.contact.uuid),
                    new ExistsFilter('meta.sentiment->label'),
                ])
            );

        this.iqlApi
            .query(query, Pager.make(1, 'meta.date_of_document', 'desc', 1), { ref: 'contact-recent-sentiment' })
            .then((result: PagedEntities) => {
                defer.resolve(result);
            })
            .catch((error: IErrorResponse) => {
                defer.reject(error);
            });

        return defer.promise;
    }

    private getRecentNps(): ng.IPromise<PagedEntities> {
        const defer: ng.IDeferred<PagedEntities> = this.q.defer();
        const query: IIql = new Iql()
            .dataSource('records')
            .filter(
                new FilterGroup(Iql.AND, [
                    new StringFilter('meta->product', Iql.EQUAL, 'survey'),
                    new StringFilter('meta.contact->uuid', Iql.EQUAL, this.contact.uuid),
                    new StringFilter('data.questions.*->type', Iql.EQUAL, 'nps'),
                ])
            );

        this.iqlApi
            .query(query, Pager.make(1, 'meta.date_of_document', 'desc', 1), { ref: 'contact-recent-nps' })
            .then((result: PagedEntities) => {
                defer.resolve(result);
            })
            .catch((error: IErrorResponse) => {
                defer.reject(error);
            });

        return defer.promise;
    }

    private getDistributionHistory(): ng.IPromise<PagedEntities> {
        const defer: ng.IDeferred<PagedEntities> = this.q.defer();
        const query: IIql = new Iql()
            .dataSource('trace')
            .filter(
                new FilterGroup(Iql.AND, [
                    new StringFilter('data->domain', Iql.EQUAL, 'distribution'),
                    new StringFilter('data.targets.*.->type', Iql.EQUAL, 'contact'),
                    new StringFilter('data.targets.*.->id', Iql.EQUAL, this.contact.uuid),
                ])
            );

        this.iqlApi
            .execute(query, this.distributionHistory.pager, { ref: 'contact-distribution-history' }, Trace)
            .then((result: PagedEntities) => {
                defer.resolve(result);
            })
            .catch((error: IErrorResponse) => {
                defer.reject(error);
            });

        return defer.promise;
    }

    /**
     * Parse and transform all textarea responses from records.
     *
     * @param records
     */
    private parseComments(records: Array<IRecord>): Array<ICommentEntry> {
        const comments: Array<ICommentEntry> = [];
        for (const record of records) {
            for (const question of record.data.questions) {
                if (question.type === 'textarea') {
                    comments.push({
                        synthesisId: record.meta.synthesisId,
                        dateOfDocument: record.meta.dateOfDocument,
                        question: question,
                    });
                }
            }
        }

        return comments;
    }
}
