import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { BaseItemJS, IBaseItemJS } from './BaseItemJS';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';

/**
 * A form user object interface
 */
export interface IBaseSettings extends IEntity {
    locked: boolean;
    hideFromReporting: boolean;
    span: number;
    customJavascript: IBaseItemJS;

    resetCustomJavascript(): void;
    hasCustomJavascript(): boolean;
}

/**
 * A form user object
 */
export class BaseSettings extends Entity implements IBaseSettings {
    locked: boolean = false;
    hideFromReporting: boolean = false;
    span: number = 100;
    customJavascript: IBaseItemJS = new BaseItemJS();

    public fromJson(jsonObject: any, convert: boolean): IBaseSettings {
        super.fromJson(jsonObject, convert);

        if (jsonObject.custom_javascript) {
            this.customJavascript = EntityBuilder.buildOne<IBaseItemJS>(BaseItemJS, jsonObject.custom_javascript, true);
        }

        return this;
    }

    public resetCustomJavascript(): void {
        this.customJavascript = new BaseItemJS();
    }

    public hasCustomJavascript(): boolean {
        return (
            !!this.customJavascript.onLoad ||
            !!this.customJavascript.onChange ||
            !!this.customJavascript.onHide ||
            !!this.customJavascript.onShow ||
            !!this.customJavascript.onNext
        );
    }
}
