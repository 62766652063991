import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { String } from '@intouch/its.essential/app/essential/utils/String';

/**
 * A form translation interface
 */
export interface ITranslation extends IEntity {
    locale: string;
    name?: string;
}

/**
 * A form translation object
 */
export class Translation extends Entity implements ITranslation {
    locale: string = null;

    // this class can have dynamic properties assigned to it at runtime (funtime)

    /**
     * Make the class
     *
     * @param {string} type
     * @param {string} locale
     * @returns {ITranslation}
     */
    public static make(type: string, locale: string): ITranslation {
        const me: ITranslation = new Translation();
        me.locale = locale;
        me[type] = '';

        return me;
    }

    /**
     * Build the translation object
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {ITranslation}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        for (const key in jsonObject) {
            if (jsonObject.hasOwnProperty(key)) {
                this[convertToCamel ? String.toCamel(key) : key] = null;
            }
        }

        return super.fromJson(jsonObject, true);
    }
}

/**
 * An interface for any settings class that has translatable keys
 */
export interface ITranslatableSettings<T extends ITranslation> {
    translations: Array<T>;

    getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation;
}
