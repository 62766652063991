import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { ISurveyApi } from '../../../api/SurveyApi';
import { CustomField, ICustomField } from '../../../domain/settings/CustomField';
import CustomFieldCreateModalHtml from './CustomFieldCreateModal.html';

/**
 * A class to show the simple check edit/create fields
 */
export class CustomFieldCreateModal extends Modal {
    static $inject: Array<string> = ['itsSurveyApi', 'iteLogger', '$translate', '$mdDialog', 'iteToaster'];

    public customField: ICustomField;
    public editing: boolean = false;
    public submitting: boolean = false;
    public createAnother: boolean;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = CustomFieldCreateModalHtml;
        config.controller = CustomFieldCreateModal;

        return super.instantiate(config);
    }

    public constructor(
        private api: ISurveyApi,
        private logger: ILogger,
        private translate: ng.translate.ITranslateService,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster
    ) {
        super();

        if (!this.customField) {
            this.customField = new CustomField();
        } else {
            this.customField = <ICustomField>this.customField.clone();
            this.editing = true;
        }
    }

    /**
     * Submit form name to be created to the API
     */
    public submit(form: ng.IFormController): void {
        this.logger.info('Creating custom field: ' + this.customField.label);
        if (this.editing) {
            this.updateCustomField(form);
        } else {
            this.createCustomField(form);
        }
    }

    /**
     * Cancels form creation and closes dialog
     */
    public cancel(): void {
        this.dialog.cancel();
    }

    public resetValidity(validator: string, model: ng.INgModelController): void {
        model.$setValidity(validator, true);
    }

    private createCustomField(form: ng.IFormController): void {
        this.submitting = true;
        this.api
            .createCustomField(this.customField)
            .then((newCustomField: ICustomField) => {
                this.logger.info('Created custom field ' + newCustomField.label + ' - ' + newCustomField.uuid);
                form.$setPristine();
                form.$setUntouched();
                this.dialog.hide({ customField: newCustomField, createAnother: this.createAnother });
            })
            .catch((error: IErrorResponse) => {
                // if there are more errors, change if/else to switch
                if (error && error.type && error.type === 'CustomFieldAlreadyExistsException') {
                    this.toaster.error('ERRORS.SETTINGS.UNIQUE_NAME');
                    form['name'].$setValidity('unique', false);
                } else {
                    this.toaster.error(
                        this.translate.instant('ERRORS.SETTINGS.FAILED_CUSTOM_FIELD_CREATE'),
                        'Failed to create custom field with name: ' + this.customField.name + ' ' + error.message
                    );
                }
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    private updateCustomField(form: ng.IFormController): void {
        this.submitting = true;
        this.api
            .updateCustomField(this.customField)
            .then((newCustomField: ICustomField) => {
                this.logger.info('Updated custom field ' + newCustomField.label + ' - ' + newCustomField.uuid);
                form.$setPristine();
                form.$setUntouched();
                this.dialog.hide(newCustomField);
            })
            .catch((error: IErrorResponse) => {
                // if there are more errors, change if/else to switch
                if (error && error.type && error.type === 'CustomFieldAlreadyExistsException') {
                    this.toaster.error('ERRORS.SETTINGS.UNIQUE_NAME');
                    form['name'].$setValidity('unique', false);
                } else {
                    this.toaster.error(
                        this.translate.instant('ERRORS.SETTINGS.FAILED_CUSTOM_FIELD_UPDATE'),
                        'Failed to create custom field with name: ' + this.customField.name + ' ' + error.message
                    );
                }
            })
            .finally(() => {
                this.submitting = false;
            });
    }
}
