import { IEntity, Entity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface ISurveyEmail extends IEntity {
    from: string;
    name: string;
    subject: string;
    reply: string;
    fromAddress: string;
    button: string;
    body: string;

    hasValidBody(): boolean;
    isValid(): boolean;
}

export interface ISurveyEmailSettings {
    org: string;
    orgImageUrl: string;
}

export class SurveyEmail extends Entity implements ISurveyEmail {
    from: string = null;
    name: string = null;
    subject: string = null;
    reply: string = null;
    fromAddress: string = null;
    button: string = 'BEGIN SURVEY';
    body: string = '';

    public constructor(settings: ISurveyEmailSettings) {
        super();
        if (settings) {
            this.buildEmailBody(settings);
        }
    }

    /**
     * Returns if the email body contains the required content ([[SURVEY.LINK]] and [[SURVEY.UNSUBSCRIBE]])
     *
     * @returns {boolean}
     */
    public hasValidBody(): boolean {
        return (
            this.body && this.body.indexOf('[[SURVEY.LINK]]') > -1 && this.body.indexOf('[[SURVEY.UNSUBSCRIBE]]') > -1
        );
    }

    /**
     * Returns if all the required fields are there and if they pass validation
     *
     * @returns {boolean}
     */
    public isValid(): boolean {
        return this.from && this.subject && this.button && this.hasValidBody();
    }

    private buildEmailBody(settings: ISurveyEmailSettings): void {
        this.body =
            '<h1 style="background:#585858;color:white;padding:8px 0;margin:0;text-align:center;">Your feedback is important to us</h1>\n' +
            '<p>\n' +
            '\t<br>\n' +
            '</p>\n' +
            '<p>We are conducting a survey and your feedback would be appreciated.</p>\n' +
            '\n' +
            '<p>\n' +
            '\t<br>\n' +
            '</p>\n' +
            '\n' +
            '<p><strong>Please take a few moments to complete our survey using the button below:</strong></p>\n' +
            '\n' +
            '<p>\n' +
            '\t<br>\n' +
            '</p>\n' +
            '\n' +
            '<p><a href="[[SURVEY.LINK]]" style="background:#9B9B9B;color:#ffffff;padding:8px 20px;text-decoration:none;box-shadow:0 2px 5px 0 rgba(0,0,0,0.26); border-radius:2px;" rel="noopener noreferrer" target="_blank">BEGIN SURVEY</a></p>' +
            '\n' +
            '<p>\n' +
            '\t<br>\n' +
            '</p>\n' +
            '\n' +
            '<p>Or copy and paste the URL below in your internet browser:</p>\n' +
            '\n' +
            '<p><a href="[[SURVEY.LINK]]" target="_blank">[[SURVEY.LINK]]</a></p>\n' +
            '\n' +
            '<p>\n' +
            '\t<br>\n' +
            '</p>\n' +
            '\n' +
            '<hr />' +
            '<div style="color:#444;text-align:center;font-weight:bold;font-size:10px;">\n' +
            (settings && settings.org ? '<div>' + settings.org + '</div>\n' : '') +
            "<div>&lt; Organization's Address &gt;</div>\n" +
            '<div>&lt; Phone Number &gt;</div>\n' +
            '<div>&lt; Email &gt;</div>\n' +
            '<div>&lt; Website &gt;</div>\n' +
            '</div>' +
            '<p style="text-align:center;font-size:10px;">This survey link is unique to you. Please do not forward this email.</p>\n' +
            '\n' +
            '<p style="text-align:center;font-size:10px;"><a style="color:#444;" href="[[SURVEY.UNSUBSCRIBE]]" rel="noopener noreferrer" target="_blank">Click here</a> to unsubscribe from future emails.</p>\n' +
            (settings && settings.orgImageUrl
                ? '<div style="text-align:center;"><img src="' +
                  settings.orgImageUrl +
                  '" width="40" alt="' +
                  settings.org +
                  ' Logo" title="' +
                  settings.org +
                  '"/></div>'
                : '');
    }
}
