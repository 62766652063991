import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', MaxRecordingLengthSettingComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/shared/sliders/max-recording-length-setting/MaxRecordingLengthSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class MaxRecordingLengthSettingComponent {
    static IID: string = 'MaxRecordingLengthSettingComponent';
    static CID: string = 'itsMaxRecordingLengthSetting';
    static $inject: string[] = [];
}
