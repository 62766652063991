import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { ISurvey } from '../../../../domain/surveys/survey/Survey';
import { IPageService } from '../../../../services/PageService';
import { ISurveyService } from '../../../../services/SurveyService';
import { BaseSurvey } from '../../BaseSurvey';

/**
 * Form edit Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.surveys', PointsController.IID, PointsController)
class PointsController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.PointsController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        '$stateParams',
        'itsSurveyService',
        'iteToaster',
        'itsPageService',
        'itsSurveyApi',
        '$scope',
        'iteAccessApi',
        'iteUserLocalTimeService',
    ];

    public survey: ISurvey = null;
    public saving: boolean = false;
    public publishing: boolean = false;
    public hasQuestions: boolean = false;
    public hasScoringQuestion: boolean = false;

    constructor(
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected scope: ng.IScope,
        protected userLocalTimeService: IUserLocalTimeService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );

        this.load().then(() => {
            this.setLoadedSurvey();
        });
    }

    public saveSurvey(): void {
        this.save(this.survey);
    }

    /**
     * Sets controller survey and other potentially long recursive function calls to booleans and page title
     * @param survey
     */
    private setLoadedSurvey(): void {
        this.hasQuestions = this.surveyService.getSurvey().hasQuestions();
        this.hasScoringQuestion = this.surveyService.getSurvey().hasScoringQuestion();
    }
}
