import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IElementLibraryGroupItem } from '../../../../domain/surveys/element-library/IElementLibraryGroup';
import { ElementLibraryService } from '../../../../services/ElementLibraryService';
import { SurveyService } from '../../../../services/SurveyService';
import { BaseItem } from '../../../../domain/surveys/items/BaseItem';
import { Image } from '../../../../domain/surveys/items/Image';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { ISurvey } from '../../../../domain/surveys/survey/Survey';

@Component('its.survey.module.surveys', SurveyItemSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/SurveyItemSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
        onEditCancel: '&',
        onSurveyUpdate: '&',
        onDelete: '&',
    },
})
class SurveyItemSettingsComponent {
    static IID: string = 'SurveyItemSettingsComponent';
    static CID: string = 'itsSurveyItemSettings';
    static $inject: string[] = ['$scope', 'iteAccessService', 'itsElementLibraryService', 'itsSurveyService'];

    public item: BaseItem;
    public onEditCancel: () => void;
    public onSurveyUpdate: () => void;
    public onDelete: (data: any) => void;

    public itemSettingsForm: ng.IFormController = null;
    public itemInformation: IElementLibraryGroupItem = null;
    public saving: boolean = false;

    constructor(
        private scope: ng.IScope,
        private accessService: IAccessService,
        private elementLibraryService: ElementLibraryService,
        private surveyService: SurveyService
    ) {
        const surveySavingSubscription: SubjectSubscription = this.surveyService.isSaving.subscribe((isSaving) => {
            this.saving = isSaving;
        });
        surveySavingSubscription.unsubscribeOnScopeDestruction(this.scope);
    }

    $onInit(): void {
        this.itemInformation = this.elementLibraryService.getSurveyElementByType(this.item.type);
    }

    public cancelEdit(): void {
        this.onEditCancel();
    }

    public saveSurvey(): void {
        const survey: ISurvey = this.surveyService.getSurvey();
        this.surveyService.saveSurveyAndNotify(survey).then(() => {
            this.surveyService.setEditingItem(null);
        });
    }

    public isFormInvalid(form: ng.IFormController): boolean {
        return (
            !form ||
            form.$invalid ||
            (this.item.type === 'image' && !(<Image>this.item).sizes) ||
            (this.item.type === 'nps' && this.surveyService.getSurvey().hasMultipleOfQuestionType('nps'))
        );
    }

    public deleteItemCallback(): void {
        this.onDelete({ item: this.item });
    }
}
