import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';

import { ISurveyApi } from '../../../../api/SurveyApi';
import { ISurvey } from '../../../../domain/surveys/survey/Survey';
import { IPageService } from '../../../../services/PageService';
import { ISurveyService } from '../../../../services/SurveyService';
import { IWeightedTag } from '../../../../domain/surveys/items/shared/WeightedTag';
import { BaseSurvey } from '../../BaseSurvey';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';

/**
 * Form edit Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.surveys', WeightingController.IID, WeightingController)
class WeightingController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.WeightingController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        '$stateParams',
        'itsSurveyService',
        'iteToaster',
        'itsPageService',
        'itsSurveyApi',
        '$scope',
        'iteUserLocalTimeService',
    ];

    public survey: ISurvey = null;
    public saving: boolean = false;
    public publishing: boolean = false;
    public hasQuestions: boolean = false;
    public tags: Array<IWeightedTag> = [];

    constructor(
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected scope: ng.IScope,
        protected userLocalTimeService: IUserLocalTimeService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );

        this.load().then(() => {
            this.setLoadedSurvey();
        });
    }

    public saveSurvey(): void {
        this.save(this.survey);
    }

    /**
     * Updates the weights for tags
     */
    public updateWeighting(tagUuid: string, weight: number): void {
        this.survey.updateTagWeight(tagUuid, weight);
        this.tags = this.survey.getAllTags();
    }

    /**
     * Loop through tags and add weight for the total weight
     */
    public getTotalWeight(): number {
        let total: number = 0;
        for (const tag of this.tags) {
            if (tag.hasOwnProperty('weight') && NumberUtils.isNumber(tag.weight)) {
                total += tag.weight;
            }
        }
        return total;
    }

    /**
     * Returns the class to apply to the total weight percentage text
     * @returns {string}
     */
    public getWeightTextClass(): string {
        if (this.getTotalWeight() === 0) {
            return 'its-scoring--weight-text-grey';
        } else if (this.getTotalWeight() !== 100) {
            return 'its-scoring--weight-text-red';
        }
    }

    /**
     * Sets controller survey and other potentially long recursive function calls to booleans and page title
     * @param survey
     */
    private setLoadedSurvey(): void {
        this.tags = this.surveyService.getSurvey().getAllTags();
        this.hasQuestions = this.surveyService.getSurvey().hasQuestions();
    }
}
