import { ILogicCondition, ILogicItem } from './Logic';
import { ISurvey } from '../survey/Survey';
import { ICustomField } from '../../settings/CustomField';
import { ILogicListItem, LogicListItem } from '../../../modules/surveys/LogicController';
import { IBaseItem } from '../items/BaseItem';
import { IDefaultContactField } from '../../contact-center/DefaultContactField';

import * as _ from 'lodash';
import { Contact } from '../../contact-center/Contact';
import { ILogicItemEquation, LogicItemEquation } from './LogicItemEquation';
import { ILogicContactEquation, LogicContactEquation } from './LogicContactEquation';
import { ILocationTag, LocationTag } from '../Location/LocationTag';
import { ILocationTagEquation, LocationTagEquation } from './LogicLocationTagEquation';
import { ILocationNode, LocationNode } from '../Location/LocationNode';

export class LogicListBuilder {
    private logicListItems: Array<ILogicListItem> = [];

    constructor(
        private itemsWithLogic: Array<any>,
        private survey: ISurvey,
        private customFields: Array<ICustomField>,
        private locationNodes: Array<ILocationNode>,
        private translate: ng.translate.ITranslateService
    ) {
        if (!this.itemsWithLogic || !this.survey || !this.customFields || !this.translate) {
            throw new Error('Invalid initialization of LogicListBuilder, missing constructor parameters');
        }

        this.buildLogicItems();

        return this;
    }

    public getLogicListItems(): Array<ILogicListItem> {
        return this.logicListItems;
    }

    /**
     * Main function, builds out all the list items based on survey/section/item logic with translations
     */
    private buildLogicItems(): Array<ILogicListItem> {
        for (const item of this.itemsWithLogic) {
            const listItem: ILogicListItem = new LogicListItem();

            // set all the base level information of the list item
            listItem.uuid = item.logic.uuid;
            listItem.label =
                item.type === 'survey'
                    ? this.survey.labelMap[item.logic.settings.sectionUuid]
                    : (<IBaseItem>item).label;
            listItem.type = item.logic.type;
            if (item.logic.action) {
                listItem.action = this.translate.instant(
                    'SURVEYS.LOGIC.' + (<string>item.logic.action).toLocaleUpperCase()
                );
            }
            if (item.logic.condition) {
                listItem.condition = this.translate
                    .instant('GENERAL.' + (<string>item.logic.condition).toLocaleUpperCase())
                    .toLocaleLowerCase();
            }
            listItem.parentUuid = item.type === 'survey' ? item.logic.settings.sectionUuid : (<IBaseItem>item).uuid;

            // build the equations for the list item, loops through conditions
            listItem.equations = <any>this.buildLogicItemEquation(listItem, item.logic.items);
            if (item.logic.settings && item.logic.settings.customMessage) {
                listItem.customMessage = item.logic.settings.customMessage.message;
            } else if (this.survey.settings.enableCustomMessage && this.survey.settings.customMessage) {
                listItem.customMessage = this.survey.settings.customMessage;
            }

            if (item.logic.settings) {
                listItem.endSurvey = item.logic.settings.endSurvey;
            }

            this.logicListItems.push(listItem);
        }

        return this.logicListItems;
    }

    /**
     * Handles different cases for the equation details, hands off for handling contact/meta specific
     * alternatives
     *
     * @param listItem
     * @param logicItems
     */
    private buildLogicItemEquation(
        listItem: ILogicListItem,
        logicItems: Array<ILogicItem>
    ): Array<ILogicContactEquation | ILogicItemEquation | ILocationTagEquation> {
        const equations: Array<any> = [];
        for (const logicItem of logicItems) {
            for (const condition of logicItem.conditions) {
                if (logicItem.type === 'contact') {
                    equations.push(this.buildContactEquation(listItem, logicItem, condition));
                } else if (logicItem.type === 'location_tag') {
                    equations.push(this.buildLocationTagEquation(condition));
                } else if (logicItem.type === 'location_node') {
                    equations.push(this.buildLocationNodeEquation(condition));
                } else {
                    equations.push(this.buildItemEquation(logicItem, condition));
                }
            }
        }

        return equations;
    }

    /**
     * Handle building the equation for a condition set with contact value
     *
     * @param listItem
     * @param logicItem
     * @param condition
     */
    private buildContactEquation(
        listItem: ILogicListItem,
        logicItem: ILogicItem,
        condition: ILogicCondition
    ): ILogicContactEquation {
        let question: string = null;
        const value: string = <string>condition.value;
        if (logicItem.operandType === 'custom_contact_field') {
            const customField: ICustomField = _.find(this.customFields, { name: logicItem.operand });
            if (customField) {
                question = customField.label;
            } else {
                listItem.hasError = true;
                question = logicItem.operand;
            }
        } else {
            const defaultField: IDefaultContactField = _.find(Contact.getDefaultFields(this.translate), {
                name: logicItem.operand,
            });
            question = defaultField.label;
        }

        return LogicContactEquation.make(question, this.getOperator(condition.operator), condition.operator, value);
    }

    /**
     * Handle building the equation for a tag value condition
     *
     * @param condition
     */
    private buildLocationTagEquation(condition: ILogicCondition): ILocationTagEquation {
        const question: string = 'Location Tag';
        if (!condition.value) {
            return LocationTagEquation.make(question, this.getOperator(condition.operator), '', condition.operator);
        }
        let tag: ILocationTag;
        for (const location of this.locationNodes) {
            tag = _.find(location.tags, { uuid: <string>condition.value });
            if (tag) {
                break;
            }
        }

        return LocationTagEquation.make(question, this.getOperator(condition.operator), tag.name, condition.operator);
    }

    /**
     * Handle building the equation for a location node value condition
     *
     * @param condition
     */
    private buildLocationNodeEquation(condition: ILogicCondition): ILocationTagEquation {
        const question: string = 'Location Node';
        if (!condition.value) {
            return LocationTagEquation.make(question, this.getOperator(condition.operator), '', condition.operator);
        }
        const locationUuid: string = <string>condition.value;
        const locationNode: LocationNode = _.find(this.locationNodes, { uuid: locationUuid });

        return LocationTagEquation.make(
            question,
            this.getOperator(condition.operator),
            this.getAddressString(locationNode),
            condition.operator
        );
    }

    /**
     * Returns the location's address as a nicely formatted string.
     *
     * @returns {string}
     */
    public getAddressString(location: ILocationNode): string {
        return (
            location.name +
            ' - ' +
            _.filter([
                location.addressLine1,
                location.addressLine2,
                location.city,
                location.region,
                location.zipPostal,
                location.country,
            ]).join(', ')
        );
    }

    /**
     * Handle building the equation for a condition set with a survey item
     *
     * @param logicItem
     * @param condition
     */
    private buildItemEquation(logicItem: ILogicItem, condition: ILogicCondition): ILogicItemEquation {
        const question: string =
            logicItem.type === 'metadata' ? logicItem.operand : this.survey.labelMap[logicItem.operand];
        let value: string = <string>condition.value,
            operandItem: any = null;

        if (condition.value && condition.type === 'response') {
            if (condition.value === 'promoter' || condition.value === 'passive' || condition.value === 'detractor') {
                value = this.translate.instant('SURVEYS.ITEMS.NPS.' + (<string>condition.value).toLocaleUpperCase());
            } else {
                value = this.survey.labelMap[<string>condition.value];
            }
        } else if (condition.type === 'input' && logicItem.operandType !== 'rating') {
            value = <string>condition.value;
        } else if (condition.type === 'input' && logicItem.operandType === 'rating') {
            operandItem = this.survey.getItemByUuid(logicItem.operand);
            value = <string>condition.value;
        }

        return LogicItemEquation.make(
            question,
            this.getOperator(condition.operator),
            condition.operator,
            value,
            operandItem
        );
    }

    /**
     * Gets the label for the operator
     *
     * @param {string} operator
     * @returns {string}
     */
    private getOperator(operator: string): string {
        switch (operator) {
            case 'eq':
                return this.translate.instant('SURVEYS.LOGIC.OPERATORS.EQUALS');
            case 'neq':
                return this.translate.instant('SURVEYS.LOGIC.OPERATORS.NOT_EQUAL');
            case 'empty':
                return this.translate.instant('SURVEYS.LOGIC.OPERATORS.EMPTY');
            case 'nempty':
                return this.translate.instant('SURVEYS.LOGIC.OPERATORS.NOT_EMPTY');
            case 'gt':
                return this.translate.instant('SURVEYS.LOGIC.OPERATORS.GREATER_THAN');
            case 'gte':
                return this.translate.instant('SURVEYS.LOGIC.OPERATORS.GREATER_THAN_EQUAL');
            case 'lt':
                return this.translate.instant('SURVEYS.LOGIC.OPERATORS.LESS_THAN');
            case 'lte':
                return this.translate.instant('SURVEYS.LOGIC.OPERATORS.LESS_THAN_EQUAL');
            case 'exists':
                return this.translate.instant('SURVEYS.LOGIC.OPERATORS.EXISTS');
            case 'nexist':
                return this.translate.instant('SURVEYS.LOGIC.OPERATORS.NOT_EXIST');
            case 'contains':
                return this.translate.instant('SURVEYS.LOGIC.OPERATORS.CONTAINS');
            case 'ncontains':
                return this.translate.instant('SURVEYS.LOGIC.OPERATORS.NOT_CONTAIN');
            default:
                return null;
        }
    }
}
