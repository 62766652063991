import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { BaseSurvey } from '../../BaseSurvey';
import { IHealthCalculator } from '../../../../domain/surveys/survey/health/IHealthCalcuator';
import { ISurveyService } from '../../../../services/SurveyService';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IPageService } from '../../../../services/PageService';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import { CompletionTime } from '../../../../domain/surveys/survey/health/CompletionTime';
import { CompletionRate } from '../../../../domain/surveys/survey/health/CompletionRate';
import { QuestionAnalysis } from '../../../../domain/surveys/survey/health/QuestionAnalysis';
import { TranslationAnalysis } from '../../../../domain/surveys/survey/health/TranslationAnalysis';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';

@Controller('its.survey.module.surveys.distributions', HealthController.IID, HealthController)
class HealthController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.distributions.HealthController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        '$stateParams',
        'itsSurveyService',
        'iteToaster',
        'itsPageService',
        'itsSurveyApi',
        '$scope',
        '$mdMedia',
        '$translate',
        'iteIqlApi',
        'iteUserLocalTimeService',
    ];

    public completionTime: IHealthCalculator = null;
    public completionRate: IHealthCalculator = null;
    public questionAnalysis: IHealthCalculator = null;
    public translationAnalysis: IHealthCalculator = null;
    public overallPercentage: number = null;

    constructor(
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected scope: ng.IScope,
        protected media: ng.material.IMedia,
        protected translate: ng.translate.ITranslateService,
        protected iqlApi: IIqlApi,
        protected userLocalTimeService: IUserLocalTimeService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );
        this.load().then(() => {
            this.initialize();
        });
    }

    public getTranslationDescription(): string {
        if (!this.translationAnalysis) {
            return;
        }
        if (this.translationAnalysis.data === 0) {
            return this.translate.instant('DISTRIBUTIONS.HEALTH.TRANSLATION_ANALYSIS.TRANSLATIONS_COMPLETED');
        } else {
            return this.translate.instant('DISTRIBUTIONS.HEALTH.TRANSLATION_ANALYSIS.MISSING_TRANSLATION_COUNT', {
                count: this.translationAnalysis.data,
            });
        }
    }

    private initialize(): void {
        this.completionTime = new CompletionTime(this.surveyService.getSurvey());
        this.completionRate = new CompletionRate(this.completionTime.data);
        this.questionAnalysis = new QuestionAnalysis(this.surveyService.getSurvey(), this.translate);
        this.translationAnalysis = new TranslationAnalysis(this.surveyService.getSurvey(), this.translate);
    }
}
