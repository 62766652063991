import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ISubNavMenuItem } from '@intouch/its.essential/app/essential/components/SubNavPanel';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ISurveySession } from '../../services/SurveySession';

/**
 * Contact Center Listing Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.settings', SettingsController.IID, SettingsController)
class SettingsController {
    static IID: string = 'its.survey.module.settings.SettingsController';
    static $inject: Array<string> = ['$state', '$mdMedia', '$scope', 'itsSurveySession', 'iteAccessService'];

    public activeTab: string = null;
    public hasContactAdmin: boolean;
    public navExpanded: boolean = false;
    private isAdmin: boolean;
    private menuItems: ISubNavMenuItem[];

    /**
     * Create the controller
     *
     * @param stateService
     * @param media
     * @param scope
     * @param session
     * @param accessService
     */
    constructor(
        private stateService: ng.ui.IStateService,
        private media: ng.material.IMedia,
        private scope: ng.IScope,
        private session: ISurveySession,
        private accessService: IAccessService
    ) {
        this.isAdmin = this.accessService.getToken().isAdmin();
        this.hasContactAdmin = this.session.getToken().getUser().hasAcl('contact_center_admin');
        // if we have a title (handles page reload) use it
        if (
            this.stateService.current.hasOwnProperty('data') &&
            this.stateService.current.data.hasOwnProperty('activeTab')
        ) {
            this.setActiveTab((<any>this.stateService.current).activeTab);
            this.scope.$on('$stateChangeSuccess', (event, toState) => {
                if (toState.hasOwnProperty('data') && toState.data.hasOwnProperty('activeTab')) {
                    this.setActiveTab(toState.data.activeTab);
                }
            });
        }

        this.menuItems = [
            {
                state: 'home.settings.custom-fields',
                title: 'CONTACT_CENTER.SETTINGS.CUSTOM_FIELDS',
                icon: 'playlist_add',
                stateName: 'custom-fields',
                condition: this.hasContactAdmin || this.isAdmin,
                featureLockConditions: [
                    {
                        product: 'survey',
                        unlockProductFeatures: ['custom-fields'],
                    },
                ],
            },
            {
                state: 'home.settings.mail-senders',
                title: 'CONTACT_CENTER.SETTINGS.CUSTOM_EMAIL_SENDERS.TITLE',
                icon: 'send',
                stateName: 'mail-senders',
                condition: this.hasContactAdmin || this.isAdmin,
                featureLockConditions: [
                    {
                        product: 'survey',
                        unlockProductFeatures: ['email-senders'],
                    },
                ],
            },
        ];

        if (this.accessService.getToken().hasFeatureEnabled('survey', 'email-distributions')) {
            const fatigueProtectionItem: ISubNavMenuItem = {
                state: 'home.settings.fatigue-protection',
                title: 'CONTACT_CENTER.SETTINGS.FATIGUE_PROTECTION',
                icon: 'accessibility_new',
                stateName: 'fatigue-protection',
                condition: this.hasContactAdmin,
            };

            this.menuItems.splice(1, 0, fatigueProtectionItem);
        }
        if (this.accessService.getToken().hasFeatureEnabled('survey', 'custom-domains')) {
            const customDomainMenuItem: ISubNavMenuItem = {
                state: 'home.settings.custom-domains',
                title: 'CONTACT_CENTER.SETTINGS.CUSTOM_DOMAINS',
                icon: 'language',
                stateName: 'custom-domains',
            };
            this.menuItems.splice(2, 0, customDomainMenuItem);
        }
    }

    public navIsExpanded(): boolean {
        return this.media('gt-sm') || this.navExpanded;
    }

    /**
     * Set the active tab
     *
     * @param {string} tab
     */
    public setActiveTab(tab: string): void {
        this.activeTab = tab;
    }
}
