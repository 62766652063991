import { BaseResponse, IBaseResponse } from './BaseResponse';
import { IFontawesomeIcon } from '../../FontawesomeProvider';
import { String } from '@intouch/its.essential/app/essential/utils/String';

export interface IIconResponse extends IBaseResponse {
    icon: IFontawesomeIcon;
}

export class IconResponse extends BaseResponse implements IIconResponse {
    icon: IFontawesomeIcon = {
        icon: 'grin',
        label: 'grin',
        type: 'outline',
        types: ['solid', 'outline'],
        category: 'emoji',
    };

    /**
     * Allow a user to quickly create a response item
     *
     * @param label
     * @param isOther
     * @returns {ISection}
     */
    public static make<T extends typeof BaseResponse>(label: string = '', isOther: boolean = false): InstanceType<T> {
        const response: IIconResponse = new IconResponse();
        response.label = label;
        response.uuid = String.uuid();
        response.isOther = isOther;

        return response as InstanceType<T>;
    }

    /**
     * Build the icon response from JSON
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IIconResponse}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.icon) {
            this.icon = jsonObject.icon;
        }

        return this;
    }

    /**
     * gets the css class for the icon
     * @returns {string}
     */
    public getCssClass(): string {
        let css: string = '';
        if (this.icon) {
            css = 'fa-' + this.icon.icon + ' ';

            if (this.icon.type === 'brand') {
                css += 'fab ';
            } else {
                css += 'fas ';
            }

            if (this.icon.type === 'outline') {
                css += 'its-icon--outline ';
            }
        }
        return css;
    }
}
