import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Rating } from '../../../../../../domain/surveys/items/Rating';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';
import { ItemSettingsTab } from '../../shared/item-settings-tabs/ItemSettingsTabs';

@Component('its.survey.module.surveys', RatingSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/rating/RatingSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: RatingSettingsComponent.getBindings(),
})
class RatingSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'RatingSettingsComponent';
    static CID: string = 'itsRatingSettings';
    static $inject: string[] = ['$translate'];

    public item: Rating;
    public tabs: ItemSettingsTab[] = ['basic', 'advanced', 'points'];
}
