import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ISiteNavigationService } from '@intouch/its.essential/app/essential/services/SiteNavigationService';
import { IGeneralSettings } from '../../../domain/settings/GeneralSettings';
import { ISurveyApi } from '../../../api/SurveyApi';
import { MailSendersCreateModal } from './MailSendersCreateModal';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { ISurveySession } from '../../../services/SurveySession';
import { IMailSender } from '../../../domain/settings/MailSender';
import * as _ from 'lodash';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { UpsellTool } from '@intouch/its.essential/app/essential/tools/UpsellTool';

/**
 * Class MailSendersController
 *
 * @constructor
 */
@Controller('its.survey.module.settings', MailSendersController.IID, MailSendersController)
class MailSendersController {
    static IID: string = 'its.survey.module.settings.MailSendersController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        '$stateParams',
        '$translate',
        'iteToaster',
        'itsSurveyApi',
        'itsSurveySession',
        'iteSiteNavigationService',
        'iteAccessService',
    ];

    public inputFieldTypes: any = {
        secret: 'password',
        confirmSecret: 'password',
        password: 'password',
    };

    public foo: string = 'password';

    public hasContactAdmin: boolean = false;
    public upsellText: string[] = [];
    public upsellTool: UpsellTool = null;
    private mailSenders: Array<IMailSender> = [];
    private settings: IGeneralSettings = null;
    private loading: boolean = false;
    private hasError: boolean = false;

    constructor(
        private stateService: ng.ui.IStateService,
        private dialog: ng.material.IDialogService,
        private stateParams: ng.ui.IStateParamsService,
        private translate: ng.translate.ITranslateService,
        private toaster: IToaster,
        private surveyApi: ISurveyApi,
        private session: ISurveySession,
        private siteNavigationService: ISiteNavigationService,
        private accessService: IAccessService
    ) {
        this.hasContactAdmin = this.session.getToken().getUser().hasAcl('contact_center_admin');
        this.upsellTool = new UpsellTool(this.accessService, this.siteNavigationService).setStateByProductFeature(
            'survey',
            'email-senders'
        );

        this.translate([
            'CONTACT_CENTER.SETTINGS.CUSTOM_EMAIL_SENDERS.UPGRADE.CUSTOM_EMAIL_SENDERS',
            'CONTACT_CENTER.SETTINGS.CUSTOM_EMAIL_SENDERS.UPGRADE.UPGRADE_TO',
            'ESSENTIAL.GENERAL.CONTACT_ADMIN',
            'ESSENTIAL.GENERAL.FIND_ADMIN',
        ]).then((translations) => {
            this.upsellText = [
                translations['CONTACT_CENTER.SETTINGS.CUSTOM_EMAIL_SENDERS.UPGRADE.CUSTOM_EMAIL_SENDERS'],
                translations['CONTACT_CENTER.SETTINGS.CUSTOM_EMAIL_SENDERS.UPGRADE.UPGRADE_TO'],
            ];
            if (!this.upsellTool.isAdmin) {
                this.upsellText.push(
                    translations['ESSENTIAL.GENERAL.CONTACT_ADMIN'],
                    translations['ESSENTIAL.GENERAL.FIND_ADMIN']
                );
            }
        });
        if (!this.upsellTool.showUpsell()) {
            this.load();
        }
    }

    public create(): void {
        this.dialog
            .show(
                MailSendersCreateModal.instantiate({
                    locals: {
                        settings: this.settings,
                        mode: 'create',
                    },
                })
            )
            .then((data: { mailSender: IMailSender }) => {
                this.toaster.success('CONTACT_CENTER.SETTINGS.CUSTOM_EMAIL_SENDERS.CREAtED_CUSTOM_EMAIL_SENDER');
                this.load();
            });
    }

    public edit(mailSender: IMailSender): void {
        this.dialog
            .show(
                MailSendersCreateModal.instantiate({
                    locals: {
                        settings: this.settings,
                        mailSender: mailSender,
                        mode: 'edit',
                    },
                })
            )
            .then((newCustomField: IMailSender) => {
                this.toaster.success('CONTACT_CENTER.SETTINGS.CUSTOM_EMAIL_SENDERS.UPDATED_CUSTOM_EMAIL_SENDER');
                this.load();
            });
    }

    /**
     * Show confirmation modal and if confirmed, delete the specified mail sender.
     *
     * @param {IMailSender} mailSender
     */
    public remove(mailSender: IMailSender): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translate.instant('CONTACT_CENTER.SETTINGS.CUSTOM_EMAIL_SENDERS.DELETE.TITLE'),
                        description: this.translate.instant('CONTACT_CENTER.SETTINGS.CUSTOM_EMAIL_SENDERS.DELETE.TEXT'),
                        confirmText: this.translate.instant('GENERAL.DELETE'),
                        cancelText: this.translate.instant('GENERAL.CANCEL'),
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((response: boolean) => {
                if (response) {
                    this.settings.mailSenders.splice(
                        _.findIndex(this.settings.mailSenders, { uuid: mailSender.uuid }),
                        1
                    );
                    this.surveyApi
                        .saveSettings(this.settings)
                        .then(() => {
                            this.toaster.success('CONTACT_CENTER.SETTINGS.CUSTOM_EMAIL_SENDERS.DELETE.SUCCESS');
                        })
                        .catch(() => {
                            this.toaster.error('CONTACT_CENTER.SETTINGS.CUSTOM_EMAIL_SENDERS.DELETE.FAIL');
                        })
                        .finally(() => {
                            this.load();
                        });
                }
            });
    }

    /**
     * Loads the Settings and grab the mail senders for display in the list.
     */
    public load(): void {
        this.loading = true;
        this.surveyApi
            .getSettings()
            .then((settings: IGeneralSettings) => {
                this.settings = settings;
                this.mailSenders = settings.mailSenders;
            })
            .catch(() => {
                this.hasError = true;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    /**
     * Gets the desired label from the from address filed in the listing
     *
     * @param {IMailSender} mailSender
     *
     * @return {string}
     */
    public getFromEmailFieldLabel(mailSender: IMailSender): string {
        if (mailSender.configuration.from.length === 1) {
            return mailSender.configuration.from[0];
        } else if (mailSender.configuration.from.length > 1) {
            return `${mailSender.configuration.from[0]}, +${mailSender.configuration.from.length - 1}`;
        } else {
            return ''; // shouldn't ever be zero, but stranger things etc.
        }
    }
}
