import { IDefaultContactField } from '../../contact-center/DefaultContactField';
import { ICustomField } from '../../settings/CustomField';

export interface ILogicOperator {
    type: string;
    label: string;
}

export class LogicOperatorFactory {
    /**
     * This function is used for Logic to make the logic rules be a readable sentence
     *
     * @param {string} operator
     * @param {angular.translate.ITranslateService} translate
     * @returns {string}
     */
    public static getOperatorReadableLabel(operator: string, translate: ng.translate.ITranslateService = null): string {
        switch (operator) {
            case 'eq':
                return translate ? translate.instant('SURVEYS.LOGIC.OPERATORS.EQUALS') : 'Equals';
            case 'neq':
                return translate ? translate.instant('SURVEYS.LOGIC.OPERATORS.NOT_EQUAL') : 'Is not equal to';
            case 'gt':
                return translate ? translate.instant('SURVEYS.LOGIC.OPERATORS.GREATER_THAN') : 'Greater than';
            case 'gte':
                return translate
                    ? translate.instant('SURVEYS.LOGIC.OPERATORS.GREATER_THAN_EQUAL')
                    : 'Greater than or equal to';
            case 'lt':
                return translate ? translate.instant('SURVEYS.LOGIC.OPERATORS.LESS_THAN') : 'Less than';
            case 'lte':
                return translate
                    ? translate.instant('SURVEYS.LOGIC.OPERATORS.LESS_THAN_EQUAL')
                    : 'Less than or equal to';
            case 'nempty':
                return translate ? translate.instant('SURVEYS.LOGIC.OPERATORS.NOT_EMPTY') : 'Is not empty';
            case 'empty':
                return translate ? translate.instant('SURVEYS.LOGIC.OPERATORS.EMPTY') : 'Is empty';
            case 'exists':
                return translate ? translate.instant('SURVEYS.LOGIC.OPERATORS.EXISTS') : 'Exists';
            case 'nexist':
                return translate ? translate.instant('SURVEYS.LOGIC.OPERATORS.NOT_EXIST') : 'Does not exist';
            case 'contains':
                return translate ? translate.instant('SURVEYS.LOGIC.OPERATORS.CONTAINS') : 'Contains';
            case 'ncontains':
                return translate ? translate.instant('SURVEYS.LOGIC.OPERATORS.NOT_CONTAIN') : 'Does Not Contain';
            default:
                throw new Error('Trying to translate invalid operator: ' + operator);
        }
    }

    /**
     * This is used in email triggers, being consistent with IQ triggers. Also used in the email listing.
     *
     * @param {string} operator
     * @param {angular.translate.ITranslateService} translate
     * @returns {string}
     */
    public static getSimpleOperatorLabel(operator: string, translate: ng.translate.ITranslateService = null): string {
        switch (operator) {
            case 'eq':
                return translate ? translate.instant('SURVEYS.LOGIC.SIMPLE_OPERATORS.EQUALS') : 'Equals';
            case 'neq':
                return translate ? translate.instant('SURVEYS.LOGIC.SIMPLE_OPERATORS.NOT_EQUAL') : 'Does Not Equal';
            case 'gt':
                return translate ? translate.instant('SURVEYS.LOGIC.SIMPLE_OPERATORS.GREATER_THAN') : 'Greater Than';
            case 'lt':
                return translate ? translate.instant('SURVEYS.LOGIC.SIMPLE_OPERATORS.LESS_THAN') : 'Less Than';
            case 'nempty':
                return translate ? translate.instant('SURVEYS.LOGIC.SIMPLE_OPERATORS.NOT_EMPTY') : 'Empty';
            case 'empty':
                return translate ? translate.instant('SURVEYS.LOGIC.SIMPLE_OPERATORS.EMPTY') : 'Not Empty';
            case 'contains':
                return translate ? translate.instant('SURVEYS.LOGIC.SIMPLE_OPERATORS.CONTAINS') : 'Contains';
            case 'ncontains':
                return translate ? translate.instant('SURVEYS.LOGIC.SIMPLE_OPERATORS.NOT_CONTAIN') : 'Does Not Contain';
            default:
                throw new Error('Trying to translate invalid operator: ' + operator);
        }
    }

    public static getOperators(
        type: string,
        translate: ng.translate.ITranslateService = null,
        contactField: IDefaultContactField | ICustomField = null
    ): Array<ILogicOperator> {
        const operators: Array<any> = [];

        switch (type) {
            case 'metadata':
                operators.push(
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('eq', translate),
                        type: 'eq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('neq', translate),
                        type: 'neq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('empty', translate),
                        type: 'empty',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('nempty', translate),
                        type: 'nempty',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('exists', translate),
                        type: 'exists',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('nexist', translate),
                        type: 'nexist',
                    }
                );
                return operators;
            case 'location_tag':
                operators.push(
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('eq', translate),
                        type: 'eq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('neq', translate),
                        type: 'neq',
                    }
                );
                return operators;
            case 'location_node':
                operators.push(
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('eq', translate),
                        type: 'eq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('neq', translate),
                        type: 'neq',
                    }
                );
                return operators;
            case 'contact':
                if (contactField && contactField.type === 'string') {
                    operators.push(
                        {
                            label: LogicOperatorFactory.getOperatorReadableLabel('eq', translate),
                            type: 'eq',
                        },
                        {
                            label: LogicOperatorFactory.getOperatorReadableLabel('neq', translate),
                            type: 'neq',
                        },
                        {
                            label: LogicOperatorFactory.getOperatorReadableLabel('empty', translate),
                            type: 'empty',
                        },
                        {
                            label: LogicOperatorFactory.getOperatorReadableLabel('nempty', translate),
                            type: 'nempty',
                        }
                    );
                } else if (contactField && (contactField.type === 'number' || contactField.type === 'date')) {
                    operators.push(
                        {
                            label: LogicOperatorFactory.getOperatorReadableLabel('eq', translate),
                            type: 'eq',
                        },
                        {
                            label: LogicOperatorFactory.getOperatorReadableLabel('neq', translate),
                            type: 'neq',
                        },
                        {
                            label: LogicOperatorFactory.getOperatorReadableLabel('empty', translate),
                            type: 'empty',
                        },
                        {
                            label: LogicOperatorFactory.getOperatorReadableLabel('nempty', translate),
                            type: 'nempty',
                        },
                        {
                            label: LogicOperatorFactory.getOperatorReadableLabel('gt', translate),
                            type: 'gt',
                        },
                        {
                            label: LogicOperatorFactory.getOperatorReadableLabel('gte', translate),
                            type: 'gte',
                        },
                        {
                            label: LogicOperatorFactory.getOperatorReadableLabel('lt', translate),
                            type: 'lt',
                        },
                        {
                            label: LogicOperatorFactory.getOperatorReadableLabel('lte', translate),
                            type: 'lte',
                        }
                    );
                }
                return operators;
            case 'slider':
                operators.push(
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('eq', translate),
                        type: 'eq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('neq', translate),
                        type: 'neq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('gt', translate),
                        type: 'gt',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('gte', translate),
                        type: 'gte',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('lt', translate),
                        type: 'lt',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('lte', translate),
                        type: 'lte',
                    }
                );
                return operators;
            case 'input':
                operators.push(
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('eq', translate),
                        type: 'eq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('neq', translate),
                        type: 'neq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('empty', translate),
                        type: 'empty',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('nempty', translate),
                        type: 'nempty',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('gt', translate),
                        type: 'gt',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('gte', translate),
                        type: 'gte',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('lt', translate),
                        type: 'lt',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('lte', translate),
                        type: 'lte',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('contains', translate),
                        type: 'contains',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('ncontains', translate),
                        type: 'ncontains',
                    }
                );
                return operators;
            case 'calendar':
            case 'rating':
            case 'scale':
            case 'nps':
                operators.push(
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('eq', translate),
                        type: 'eq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('neq', translate),
                        type: 'neq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('empty', translate),
                        type: 'empty',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('nempty', translate),
                        type: 'nempty',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('gt', translate),
                        type: 'gt',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('gte', translate),
                        type: 'gte',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('lt', translate),
                        type: 'lt',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('lte', translate),
                        type: 'lte',
                    }
                );
                return operators;
            case 'checkbox':
            case 'radio':
            case 'select':
            case 'hidden_input':
            case 'matrix':
                operators.push(
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('eq', translate),
                        type: 'eq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('neq', translate),
                        type: 'neq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('empty', translate),
                        type: 'empty',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('nempty', translate),
                        type: 'nempty',
                    }
                );
                return operators;
            case 'textarea':
                operators.push(
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('contains', translate),
                        type: 'contains',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('ncontains', translate),
                        type: 'ncontains',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('empty', translate),
                        type: 'empty',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('nempty', translate),
                        type: 'nempty',
                    }
                );
                return operators;
            case 'audio':
            case 'video_input':
            case 'image_upload':
                operators.push(
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('empty', translate),
                        type: 'empty',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('nempty', translate),
                        type: 'nempty',
                    }
                );
                return operators;
            case 'number':
                operators.push(
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('eq', translate),
                        type: 'eq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('neq', translate),
                        type: 'neq',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('gt', translate),
                        type: 'gt',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('gte', translate),
                        type: 'gte',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('lt', translate),
                        type: 'lt',
                    },
                    {
                        label: LogicOperatorFactory.getOperatorReadableLabel('lte', translate),
                        type: 'lte',
                    }
                );
                return operators;
            default:
                throw new Error('Tried to get operators for an invalid element type: ' + type);
        }
    }
}
