import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { FontawesomeProvider, IFontawesomeIcon } from '../../../domain/FontawesomeProvider';
import { IIconResponse } from '../../../domain/surveys/items/IconResponse';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { User } from '@intouch/its.essential/app/essential/domain/access/User';
import IconSelectModalHtml from './IconSelectModal.html';

export class IconSelectModal extends Modal {
    static $inject: Array<string> = ['$mdDialog', '$translate', 'iteAccessService'];

    public solidIcons: Array<IFontawesomeIcon> = [];
    public brandIcons: Array<IFontawesomeIcon> = [];
    public outlineIcons: Array<IFontawesomeIcon> = [];
    public selectedIcon: IFontawesomeIcon = null;
    public selectedIconType: string = null;
    protected response: IIconResponse;

    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = IconSelectModalHtml;
        config.controller = IconSelectModal;

        return super.instantiate(config);
    }

    public constructor(
        private dialog: ng.material.IDialogService,
        private translate: ng.translate.ITranslateService,
        private accessService: IAccessService
    ) {
        super();
        this.sortIcons();
    }

    public submit(): void {
        this.response.icon = this.selectedIcon;
        this.dialog.hide();
    }

    /**
     * Sorts the icons into Solid, Outline, and Brand
     */
    public sortIcons(): void {
        for (const icon of FontawesomeProvider.getIcons(this.translate)) {
            for (const type of icon.types) {
                if (type === 'solid') {
                    this.solidIcons.push(icon);
                }
                if (type === 'outline') {
                    this.outlineIcons.push(icon);
                }
                if (type === 'brand') {
                    this.brandIcons.push(icon);
                }
            }
        }
    }

    /**
     * Cancel and close the modal
     */
    public cancel(): void {
        this.dialog.cancel();
    }

    public select(icon: IFontawesomeIcon, type: string): void {
        this.selectedIconType = type;
        this.selectedIcon = icon;
        this.selectedIcon.type = type;
    }

    public getIconClass(icon: string): string {
        return FontawesomeProvider.getIconClass(icon);
    }

    public isUserLanguageEnglish(): boolean {
        const token: IToken = this.accessService.getToken();
        if (token) {
            const user: User = token.getUser();
            return user && (user.language === 'en' || user.language === 'en_US');
        }
        return false;
    }
}
