import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IMatrixQuestion } from '../../../../../../../domain/surveys/items/Matrix';

@Component('its.survey.module.surveys', MatrixQuestionsComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/response-items/matrix/matrix-questions/MatrixQuestionsComponent.html'),
    controllerAs: 'vm',
    bindings: {
        questions: '<',
        onQuestionAdd: '&',
        onQuestionRemove: '&',
        onQuestionSort: '&',
    },
})
class MatrixQuestionsComponent {
    static IID: string = 'MatrixQuestionsComponent';
    static CID: string = 'itsMatrixQuestions';
    static $inject: string[] = [];

    public onQuestionAdd: (data: any) => void;
    public onQuestionRemove: (data: any) => void;
    public onQuestionSort: (data: any) => void;
    private sortedAscending: boolean = false;

    public addQuestion(index: number): void {
        this.onQuestionAdd({ index: index });
    }

    public removeQuestion(question: IMatrixQuestion): void {
        this.onQuestionRemove({ question: question });
    }

    public toggleQuestionLabelSorting(): void {
        this.sortedAscending = !this.sortedAscending;
        this.onQuestionSort({ ascending: this.sortedAscending });
    }
}
