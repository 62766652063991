import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { BaseItemRenderComponent } from '../shared/BaseItemRenderComponent';
import { BaseItem, IBaseItem } from '../../../../../domain/surveys/items/BaseItem';
import { SurveyService } from '../../../../../services/SurveyService';
import { Group } from '../../../../../domain/surveys/items/Group';
import { IContainItems } from '../../../../../domain/surveys/items/ContainItems';
import { Section } from '../../../../../domain/surveys/Section';
import { Survey } from '../../../../../domain/surveys/survey/Survey';
import { SurveyQuestionMover } from '../../../../../domain/surveys/survey/utils/SurveyQuestionMover';

@Component('its.survey.module.surveys', GroupRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/group/GroupRenderComponent.html'),
    controllerAs: 'vm',
    bindings: GroupRenderComponent.getBindings(),
})
class GroupRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'GroupRenderComponent';
    static CID: string = 'itsGroupRender';
    static $inject: string[] = ['itsSurveyService', '$scope'];

    public item: Group;

    public editingItem: IBaseItem = null;
    public survey: Survey = null;

    public constructor(private surveyService: SurveyService, private scope: ng.IScope) {
        super();
        this.subscribeToSurvey();
        this.subscribeToEditingItem();
    }

    $onChanges(): void {
        // need this because we use @NotEnumerable on the temporary property to handle
        // collapsing and when we recover from canceled edit, we lose the default state
        // hack fix but it was a tradeoff to sharing the label on all elements outside of the elements
        this.item.expanded = true;
    }

    public edit(selectedItem: BaseItem): void {
        this.surveyService.saveSnapshotAndEdit(selectedItem);
    }

    public handleMoveItem(
        section: Section,
        currentQuestionIndex: number,
        targetParent: IContainItems,
        targetIndex: number
    ): boolean {
        SurveyQuestionMover.moveQuestion(section, currentQuestionIndex, targetParent, targetIndex);
        this.surveyService.saveSurvey(this.survey);
        return true;
    }

    private subscribeToEditingItem(): void {
        const editingItemSubscription: SubjectSubscription = this.surveyService.editingItem.subscribe((editingItem) => {
            this.editingItem = <IBaseItem>editingItem;
        });
        editingItemSubscription.unsubscribeOnScopeDestruction(this.scope);
    }

    private subscribeToSurvey(): void {
        const surveySubscription: SubjectSubscription = this.surveyService.surveyValue.subscribe((survey) => {
            this.survey = survey;
        });
        surveySubscription.unsubscribeOnScopeDestruction(this.scope);
    }
}
