import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { SurveyService } from '../../../services/SurveyService';
import { PageService } from '../../../services/PageService';
import { Survey } from '../../../domain/surveys/survey/Survey';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { SurveyIqlService } from '../../../services/SurveyIqlService';
import { SurveyChartService } from '../../../services/SurveyChartService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IFilterService } from '@intouch/its.essential/app/essential/domain/filters/FilterService';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';
import ClipboardJS from 'clipboard/dist/clipboard';
import * as _ from 'lodash';

@Controller('its.survey.module.surveys', SummaryController.IID, SummaryController)
class SummaryController {
    static IID: string = 'its.survey.module.surveys.SummaryController';
    static $inject: Array<string> = [
        '$scope',
        '$mdMedia',
        '$timeout',
        '$state',
        '$translate',
        'itsSurveyService',
        'itsSurveyIqlService',
        'itsSurveyChartService',
        'iteToaster',
        'iteAccessService',
        '$window',
        'itsPageService',
        'iteIqlRecordDateOfDocumentFilterService',
        'itePanelService',
        '$q',
    ];

    public survey: Survey = null;
    public publicSurveyUrl: string = null;
    public availableLanguageLabels: string = null;
    public loadingDistributionChart: boolean = true;
    public hasSurveyQuestions: boolean = false;
    public errorLoadingDistributionsChart: boolean = false;
    public completionRateMetrics: { submissions: number; views: number };
    private submissionsOverTimeChart: any = null;

    constructor(
        private scope: ng.IScope,
        private media: ng.material.IMedia,
        private timeout: ng.ITimeoutService,
        private stateService: ng.ui.IStateService,
        private translate: ng.translate.ITranslateService,
        private surveyService: SurveyService,
        private surveyIqlService: SurveyIqlService,
        private surveyChartService: SurveyChartService,
        private toaster: IToaster,
        private accessService: IAccessService,
        private windowService: ng.IWindowService,
        private pageService: PageService,
        private dateFilterService: IFilterService,
        private panelService: IPanelService,
        private q: ng.IQService
    ) {
        this.pageService.startLoading();
        const surveyUuid: string = this.stateService.params['uuid'];
        this.loadSurveyByUuid(surveyUuid).then(() => {
            this.subscribeToSurveyValue();
            this.subscribeToDateFilterOnChange();
            this.setUrlClipboardCopy();
        });
    }

    public openDateFilterPanel(event: any): void {
        this.panelService.openDateFilterPanel({
            relativeTo: event.target,
            path: 'meta->date_of_document',
            filterService: this.dateFilterService,
            allowCustomDateRange: true,
        });
    }

    public isDoneLoading(): boolean {
        return this.pageService.isDoneLoading();
    }

    public goToBuild(): void {
        this.stateService.go('home.surveys.edit.build.details', { uuid: this.survey.uuid });
    }

    public openQuickStartGuide(): void {
        let guideUrl: string;

        switch (this.accessService.getToken().getUser().language) {
            case 'fr_CA':
                // french url will be provided in the near future so leaving this switch/case here for now.
                guideUrl = 'https://intouchsurvey.zendesk.com/hc/en-us/articles/360053582894-Survey-Quick-Start-Guide';
                break;
            default:
                guideUrl = 'https://intouchsurvey.zendesk.com/hc/en-us/articles/360053582894-Survey-Quick-Start-Guide';
                break;
        }

        this.windowService.open(guideUrl, '_blank');
    }

    private loadSurveyByUuid(surveyUuid: string): ng.IPromise<void | Survey> {
        return this.surveyService.loadSurvey(surveyUuid).catch(() => {
            this.pageService.failLoading();
            this.surveyService.setSurvey(null);
            this.toaster.error('ERRORS.FAILED_SURVEY_LOAD');
            this.stateService.go('home.surveys.listing');
        });
    }

    private subscribeToDateFilterOnChange(): void {
        let isInitialized: boolean = false;
        const subscriber: SubjectSubscription = this.dateFilterService.filterObserver.subscribe(() => {
            if (!isInitialized) {
                isInitialized = true;
                return;
            }

            const surveyUuid: string = this.stateService.params['uuid'];
            this.loadSurveyByUuid(surveyUuid);
        });

        subscriber.unsubscribeOnScopeDestruction(this.scope);
    }

    private getSurveyPublicUrl(): string {
        return this.survey ? this.surveyService.getSurveyUrl(this.survey) + '/' + this.survey.originalUuid : null;
    }

    private subscribeToSurveyValue(): void {
        const surveySubscription: SubjectSubscription = this.surveyService.surveyValue.subscribe((survey) => {
            this.survey = survey;
            if (survey) {
                this.hasSurveyQuestions = survey.hasQuestions();
                this.publicSurveyUrl = this.getSurveyPublicUrl();
                this.availableLanguageLabels = this.survey.getAvailableLanguagesText();
                if (this.hasSurveyQuestions) {
                    this.loadDistributionsChart();
                }
                this.pageService.setPageTitle(this.survey.name);
                this.pageService.stopLoading();
            }
        });
        surveySubscription.unsubscribeOnScopeDestruction(this.scope);
    }

    private setUrlClipboardCopy(): void {
        const clipboard: any = new ClipboardJS('#triggerBtn');
        clipboard.on('success', () => {
            this.toaster.info(this.translate.instant('SURVEYS.THEMES.COPIED_URL_TO_CLIPBOARD'));
        });
        this.scope.$on('$destroy', () => {
            if (clipboard) {
                clipboard.destroy();
            }
        });
    }

    private loadDistributionsChart(): ng.IPromise<any> {
        this.loadingDistributionChart = true;
        this.errorLoadingDistributionsChart = false;
        this.completionRateMetrics = {
            submissions: null,
            views: null,
        };

        const data: any = {
            submissions: null,
            views: null,
        };

        const promises: Array<any> = [
            this.surveyIqlService.getSurveySubmissionsByDateFilter(this.survey.originalUuid).then((iqlSeriesData) => {
                data.submissions = iqlSeriesData;
            }),
            this.surveyIqlService.getSurveyViewsByDateFilter(this.survey.originalUuid).then((iqlSeriesData) => {
                data.views = iqlSeriesData;
            }),
        ];

        return this.q
            .all(promises)
            .then(() => {
                if (!!data.submissions && !!data.submissions.data) {
                    this.completionRateMetrics.submissions = _.sumBy(data.submissions.data || [], 'y');
                } else {
                    this.completionRateMetrics.submissions = 0;
                }

                if (!!data.views && !!data.views.data) {
                    this.completionRateMetrics.views = _.sumBy(data.views.data || [], 'y');
                } else {
                    this.completionRateMetrics.views = 0;
                }

                const dateTimeInterval: string = this.surveyIqlService.getChartTitleDatePeriod();
                const dateRangeValues: { start: number; end: number } =
                    this.getStartEndDateForTimeInterval(dateTimeInterval);
                this.submissionsOverTimeChart = this.surveyChartService.getSubmissionsChartByDateFilter(
                    data,
                    dateRangeValues,
                    dateTimeInterval
                );
            })
            .catch(() => {
                this.errorLoadingDistributionsChart = true;
            })
            .finally(() => {
                this.loadingDistributionChart = false;
            });
    }

    private getStartEndDateForTimeInterval(dateTimeInterval: string): { start: number; end: number } {
        const dateRange: { start: moment.Moment; end: moment.Moment } =
            this.surveyIqlService.getCurrentDateRangeFromFilter();
        const dateRangeValues: { start: number; end: number } = {
            start: dateRange.start.valueOf(),
            end: dateRange.end.valueOf(),
        };
        // this stops highcharts from padding an extra day (in the future) to date ranges which include today (outside of today/yesterday breakdown in hours)
        // the max value in highcharts seems to analyze based off the timestamp's day @ 00:00:00 and anything over will create a cushion with an extra day
        // added to the x-axis
        if (dateTimeInterval !== 'today' && dateTimeInterval !== 'yesterday' && dateTimeInterval !== 'custom_range') {
            dateRangeValues.end = dateRange.end.startOf('day').valueOf();
        }

        return dateRangeValues;
    }
}
