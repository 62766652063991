import { ILanguage, Language } from '../../LanguageProvider';

export interface ISurveyLanguage extends ILanguage {
    default: boolean;
    label: string;
    locale: string;
}

export class SurveyLanguage extends Language implements ILanguage {
    default: boolean = false;
    locale: string = null;
    label: string = null;

    /**
     * Make an ISurveyLanguage from an ILanguage
     */
    public static make(label: string, locale: string, isDefault?: boolean): ISurveyLanguage {
        const me: ISurveyLanguage = new SurveyLanguage();
        me.label = label;
        me.locale = locale;
        me.default = !!isDefault;
        return me;
    }
}
