import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { ISurveyApi } from '../../../api/SurveyApi';
import { ContactList, IContactList } from '../../../domain/contact-center/ContactList';
import ContactListCreateModalHtml from './ContactListCreateModal.html';

/**
 * A class to show the simple check edit/create fields
 */
export class ContactListCreateModal extends Modal {
    static $inject: Array<string> = ['itsSurveyApi', 'iteLogger', '$mdDialog', 'iteToaster'];

    public creationType: string = 'manual';
    public contactList: IContactList;
    public submitting: boolean = false;
    public creating: boolean = false;
    public submittedSuccessfully: boolean = false;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = ContactListCreateModalHtml;
        config.controller = ContactListCreateModal;

        return super.instantiate(config);
    }

    public constructor(
        private api: ISurveyApi,
        private logger: ILogger,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster
    ) {
        super();

        if (this.contactList) {
            this.contactList = <IContactList>this.contactList.clone();
        } else {
            this.creating = true;
            this.contactList = new ContactList();
        }
    }

    /**
     * Submit form name to be created to the API
     */
    public submit(form: ng.IFormController): void {
        this.logger.info('Creating contact list: ' + this.contactList.name);
        this.submitting = true;
        if (this.creating) {
            this.createContactList(form);
        } else {
            this.updateContactList(form);
        }
    }

    /**
     * Manually reset custom validation state
     *
     * @param {angular.INgModelController} element
     * @param {string} key
     */
    public resetValidity(element: ng.INgModelController, key: string): void {
        element.$setValidity(key, true);
    }

    /**
     * Cancels form creation and closes dialog
     */
    public cancel(): void {
        this.dialog.cancel();
    }

    private createContactList(form: ng.IFormController): void {
        this.api
            .createContactList(this.contactList)
            .then((newContactList: IContactList) => {
                this.logger.info('Created Contact List' + newContactList.name + ' - ' + newContactList.uuid);
                const returnData: any = {
                    contactList: newContactList,
                };
                this.dialog.hide(returnData);
            })
            .catch((error: IErrorResponse) => {
                // if there are more errors, change if/else to switch
                if (error && error.type && error.type === 'ContactListAlreadyExistsException') {
                    this.toaster.error('ERRORS.FAILED_CONTACT_LIST_UNIQUE');
                    form['name'].$setValidity('unique', false);
                } else {
                    this.toaster.error(
                        'ERRORS.CONTACT_CENTER.FAILED_CONTACT_CREATE',
                        'Failed to create contact with email: ' + this.contactList.name + ' ' + error.message
                    );
                }
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    private updateContactList(form: ng.IFormController): void {
        this.api
            .updateContactList(this.contactList)
            .then((newContactList: IContactList) => {
                this.logger.info('Updated Contact List' + newContactList.name + ' - ' + newContactList.uuid);
                form.$setPristine();
                form.$setUntouched();
                this.dialog.hide(newContactList);
            })
            .catch((error: IErrorResponse) => {
                // if there are more errors, change if/else to switch
                if (error && error.type && error.type === 'ContactListAlreadyExistsException') {
                    this.toaster.error('ERRORS.FAILED_CONTACT_LIST_UNIQUE');
                    form['name'].$setValidity('unique', false);
                } else {
                    this.toaster.error(
                        'ERRORS.CONTACT_CENTER.FAILED_CONTACT_CREATE',
                        'Failed to create contact with email: ' + this.contactList.name + ' ' + error.message
                    );
                }
            })
            .finally(() => {
                this.submitting = false;
            });
    }
}
