import { IConfig } from './IConfig';
/* tslint:disable */
/**
 * WARNING
 *
 * This file is generated at build time and overwrites the hard-coded.
 * local development config.
 * Do not edit, changes will not be persisted.
 */

let config: IConfig = {
  "access": {
    "appVersion": "d493a8c",
    "appName": "SurveyAdmin",
    "appSlug": "survey",
    "logoutUrl": "https://access.intouchinsight.com/#/logout/IntouchSurvey",
    "endpointV2": "https://api.access.intouchinsight.com/v2",
    "endpointV3": "https://api.access.intouchinsight.com/v3",
    "endpoint": "https://api.access.intouchinsight.com/v1",
    "subscriptionUrl": "https://access.intouchinsight.com/#/settings/subscriptions",
    "loginUrl": "https://access.intouchinsight.com/#/login/IntouchSurvey",
    "organizationUrl": "https://access.intouchinsight.com/#/organization/select/IntouchSurvey"
  },
  "sentry": {
    "dsn": "https://a6767b3a787646729cd8ddc948e363b8@sentry.io/362820",
    "release": "d493a8c538cd3949e9b713582e4736aeb9a23636",
    "environment": "production"
  },
  "storage": {
    "keyPrefix": "its"
  },
  "froala": {
    "key": "Ne2C1sA3E4I3B16B7A8jF1QUg1Xc2OZE1ABVJRDRNGGUH1ITrA1C7A6D5E1E4B3E1A9D9=="
  },
  "amMoment": {
    "locale": "en"
  },
  "api": {
    "trace": true,
    "version": "v1",
    "endpoint": "https://api.survey.intouchinsight.com"
  },
  "datadog": {
    "version": "d493a8c",
    "logSampleRate": 100,
    "service": "survey-admin",
    "rumSampleRate": 50,
    "applicationId": "fd9ad03f-2b7c-477d-a239-1e07b01edfc8",
    "env": "production",
    "clientToken": "puba71d9d9f3e606ba23f49624c318fb924"
  },
  "toaster": {
    "position": "top left",
    "translate": true
  },
  "zendeskKey": "f0110c9f-c1e7-474f-adf2-b3d7e1f646fa",
  "logger": {
    "level": 1
  },
  "session": {
    "snakeToCamelConvert": true
  },
  "products": {
    "urls": {
      "access": {
        "root": "https://access.intouchinsight.com/#/",
        "app": "https://access.intouchinsight.com/#/login/callback/"
      },
      "check": {
        "admin": "https://admin.check.intouchinsight.com/#/login/callback/",
        "app": "https://audit.check.intouchinsight.com/#/login/callback/"
      },
      "capture": {
        "admin": "https://admin.capture.intouchinsight.com/#/sign-in/callback/"
      },
      "survey": {
        "admin": "https://admin.survey.intouchinsight.com/#/login/callback/"
      },
      "intelligence": {
        "app": "https://intelligence.intouchinsight.com/#/login/callback/"
      }
    },
    "apis": {
      "survey": {
        "url": "https://api.survey.intouchinsight.com",
        "version": "v1"
      },
      "intelligence": {
        "url": "https://api.intelligence.intouchinsight.com",
        "version": "v1"
      },
      "templateLibraryApi": {
        "endpoint": "https://templates.intouchinsight.com"
      }
    }
  },
  "gtm": {
    "id": "GTM-K8W33W5",
    "gtmPreview": "env-1",
    "gtmAuth": "QifW12mAHVw7npJeGShwLQ"
  },
  "defaultEmail": {
    "from": "survey@intouchinsight.email"
  },
  "remoteStorageService": {
    "endpoint": "https://api.intouchinsight.com/services/remote-storage/v1/"
  },
  "app": {
    "endpoint": "https://www.intouchsurvey.com/survey",
    "themeBackgrounds": "https://www.intouchsurvey.com/assets/images/theme-bgs"
  },
  "limitService": {
    "endpoint": "https://api.intouchinsight.com/services/limit/v1/"
  },
  "checkSession": {
    "adminUrl": "https://admin.check.intouchinsight.com/#/login/callback/"
  },
  "media": {
    "resizerUrl": "https://images.intouchinsight.com/unsafe"
  }
};

export default config;

