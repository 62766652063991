import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { ISurveySession } from '../../services/SurveySession';

/**
 * The users controller
 *
 * @param UserService
 * @param $state
 * @constructor
 */
@Controller('its.survey.module.main', LoginCallbackController.IID, LoginCallbackController)
class LoginCallbackController {
    static IID: string = 'LoginCallbackController';
    static $inject: Array<string> = [
        '$stateParams',
        'iteAccessService',
        'iteLogger',
        'itsSurveySession',
        '$state',
        '$location',
        '$translate',
    ];

    public translations: any = {};

    /**
     * The class constructor
     *
     * @param stateParams
     * @param accessService
     * @param logger
     * @param session
     * @param state
     * @param location
     * @param translate
     */
    constructor(
        private stateParams: ng.ui.IStateParamsService,
        private accessService: IAccessService,
        private logger: ILogger,
        private session: ISurveySession,
        private state: ng.ui.IStateService,
        private location: ng.ILocationService,
        private translate: ng.translate.ITranslateService
    ) {
        if (!this.stateParams.hasOwnProperty('token')) {
            throw new Error('Was unable to locate a token needed for authentication');
        }

        this.translate(['GENERAL.LOGIN.COMPLETING', 'GENERAL.LOGIN.WAIT'])
            .then((translations) => {
                this.translations = translations;
            })
            .finally(() => {
                // flush the session
                this.session.flush();

                const token: IToken = this.accessService.handleLoginSuccess(this.stateParams['token']);

                this.logger.debug('Received a new token from the login callback');
                this.logger.debug('    > Issued time of: ' + token.getDateIssued().toDate());
                this.logger.debug('    > Expire time of: ' + token.getDateExpired().toDate());

                if (this.stateParams['redirect']) {
                    this.location.url(this.stateParams['redirect']);
                } else {
                    this.state.go('home.surveys.listing');
                }
            });
    }
}
