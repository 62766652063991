import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ISurveyApi } from '../../../api/SurveyApi';
import { ContactUpload, IContactUpload } from '../../../domain/contact-center/ContactUpload';
import { INavigationService } from '../../../services/NavigationService';

/**
 * Contact Center Contact Upload Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.contactCenter', ContactUploadController.IID, ContactUploadController)
class ContactUploadController {
    static IID: string = 'its.survey.module.contactCenter.ContactUploadController';
    static $inject: Array<string> = ['itsSurveyApi', 'iteAccessService', 'itsNavigationService'];

    public csvUrl: string;
    public upload: IContactUpload = new ContactUpload();
    public endpointUrl: string = 'contacts/upload';

    /**
     * Create the controller
     */
    constructor(
        private api: ISurveyApi,
        private accessService: IAccessService,
        private navigationService: INavigationService
    ) {
        this.navigationService.setBackRoute('home.contact-center');
        const token: IToken = accessService.getToken();
        if (token && token.getOrganization()) {
            this.csvUrl = this.api.buildContactCsvUrl(token.getOrganization().uuid);
        }
    }
}
