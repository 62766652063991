/** USAGE
 * <its-contact-list-chips-component
 *    items="vm.myItems"
 *    on-change="vm.mapItems()"  // optional
 *    readonly="false"          // optional
 *    removable="true"          // optional
 *    single-select="false"     // optional
 *    max-width="300px">        // optional
 * </its-contact-list-chips-component>
 */

import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { AbstractChipsComponent } from '@intouch/its.essential/app/essential/components/chips/AbstractChipsComponent';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';
import * as _ from 'lodash';
import { IContactList } from '../../../domain/contact-center/ContactList';

@Component('its.essential.components', ContactListChipsComponent.CID, AbstractChipsComponent.getComponentConfig())
class ContactListChipsComponent extends AbstractChipsComponent {
    static IID: string = 'ContactListChipsComponent';
    static CID: string = 'itsContactListChipsComponent';

    static $inject: Array<string> = ['itePanelService', '$element'];

    public constructor(private panelService: IPanelService, element: any) {
        super(element);
    }

    public getLabel(list: IContactList): string {
        return list.name;
    }

    /**
     * Open the tag picker panel
     *
     */
    public openPicker(): void {
        this.panelService.openCustomPickerPanel({
            relativeTo: this.element.querySelector('.its-btn--text'),
            onSelect: (list: IContactList) => {
                this.addItem(list);
            },
            onDeselect: (list: IContactList) => {
                this.removeItem(list);
            },
            preSelect: this.items.map((i) => {
                return i.uuid;
            }),
            maxWidth: this.maxWidth,
            multiSelect: !this.singleSelect,
            controller: 'ContactListPickerPanelController',
            template: require('/app/components/pickers/contact-list/ContactListPickerPanel.html'),
        });
    }

    private removeItem(list: IContactList): void {
        const index: number = _.findIndex(this.items, { uuid: list.uuid });
        if (index > -1) {
            this.removeItemByIndex(index);
        }
    }
}
