import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { BaseItem, IBaseItem } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';

/**
 * The Audio settings interface
 */
export interface IAudioSettings extends IBaseSettings {
    rows: string;
    enableSentiment: boolean;
    enableTextEntry: boolean;
    maxRecordingLength: number;
}

/**
 * The Audio settings class
 */
export class AudioSettings extends BaseSettings implements IAudioSettings {
    rows: string = '5';
    enableSentiment: boolean = false;
    enableTextEntry: boolean = true;
    maxRecordingLength: number = 5;
}

/**
 * The Audio interface
 */
export type IAudio = IBaseItem;

/**
 * The Audio class
 */
export class Audio extends BaseItem implements IAudio {
    type: string = 'audio';
    settings: IAudioSettings = new AudioSettings();

    @NotEnumerable
    _healthPoints: number = 5;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey. This overrides the BaseItem default value.
     */
    get healthPoints(): number {
        return this._healthPoints;
    }

    /**
     * Build Audio question from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IAudio}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): this {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<IAudioSettings>(AudioSettings, jsonObject.settings, convertToCamel);
        }

        return this;
    }
}
