import { ItemSettingsTab } from './item-settings-tabs/ItemSettingsTabs';

export abstract class BaseItemSettingsComponent {
    public tab: ItemSettingsTab = 'basic';
    public tabs: ItemSettingsTab[] = BaseItemSettingsComponent.defaultTabs();

    public static defaultTabs(): ItemSettingsTab[] {
        return ['basic', 'advanced'];
    }

    public static getBindings(): any {
        return {
            item: '<',
        };
    }

    public showBasicSettings(): boolean {
        return this.tab === 'basic';
    }

    public showAdvancedSettings(): boolean {
        return this.tab === 'advanced';
    }

    public showPointsSettings(): boolean {
        return this.tab === 'points';
    }

    public handleTabChange(activeTab: ItemSettingsTab): void {
        this.tab = activeTab;
    }
}
