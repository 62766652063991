import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { ITemplate } from '@intouch/its.essential/app/essential/domain/template-library/Template';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IOrganization } from '@intouch/its.essential/app/essential/domain/access/Organization';
import { ISurveyApi } from '../../../api/SurveyApi';
import { ISurvey, Survey } from '../../../domain/surveys/survey/Survey';
import SurveyCreateModalHtml from './SurveyCreateModal.html';

export class SurveyCreateModal extends Modal {
    static $inject: Array<string> = [
        '$mdDialog',
        'iteToaster',
        '$scope',
        '$sce',
        '$translate',
        'itsSurveyApi',
        'iteAccessService',
    ];

    public survey: ISurvey = new Survey();
    public surveyTemplate: ITemplate;
    public saving: boolean = false;
    public hasError: boolean = false;
    public formName: string = 'createSurvey';

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = SurveyCreateModalHtml;
        config.controller = SurveyCreateModal;

        return super.instantiate(config);
    }

    constructor(
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private scope: ng.IScope,
        private sce: ng.ISCEService,
        private translate: ng.translate.ITranslateService,
        private surveyApi: ISurveyApi,
        private accessService: IAccessService
    ) {
        super();

        if (this.surveyTemplate) {
            this.survey.name = this.surveyTemplate.name;
        }
    }

    public create(): void {
        this.saving = true;

        if (this.surveyTemplate) {
            this.createFromTemplate();
        } else {
            this.createFromScratch();
        }
    }

    /**
     * Handler to trigger on survey name change
     */
    public onNameChange(): void {
        // clear name duplication exception
        if (this.getForm()) {
            this.getForm().name.$setValidity('SurveyAlreadyExistsException', true);
        }
    }

    /**
     * Returns the form
     *
     * @return {any}
     */
    public getForm(): any {
        return this.scope[this.formName];
    }

    /**
     * Cancel the dialog
     */
    public cancel(): void {
        this.dialog.cancel();
    }

    /**
     * Check if the form is valid to submit.
     */
    public isValid(): boolean {
        return !!this.survey.name;
    }

    public disableSave(): boolean {
        return this.saving || !this.isValid();
    }

    private createFromScratch(): void {
        const organization: IOrganization = this.accessService.getOrganization();
        this.survey.settings.hideIntouchBranding = organization.isEnterprise();

        this.surveyApi
            .createSurvey(this.survey)
            .then((newSurvey: ISurvey) => {
                this.dialog.hide(newSurvey);
            })
            .catch((error: IErrorResponse) => {
                this.handleCreationError(error);
            })
            .finally(() => {
                this.saving = false;
            });
    }

    private createFromTemplate(): void {
        this.surveyApi
            .createSurveyFromTemplate(this.survey.name, this.surveyTemplate.uuid)
            .then((newSurvey: ISurvey) => {
                this.dialog.hide(newSurvey);
            })
            .catch((error: IErrorResponse) => {
                this.handleCreationError(error);
            })
            .finally(() => {
                this.saving = false;
            });
    }

    private handleCreationError(error: IErrorResponse): void {
        if (error && error.type === 'SurveyAlreadyExistsException') {
            this.getForm().name.$setValidity('SurveyAlreadyExistsException', false);
        } else {
            this.toaster.error(this.translate.instant('ERRORS.FAILED_SURVEY_CREATE'));
        }
    }
}
