import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IBaseItem } from '../../../../domain/surveys/items/BaseItem';
import { FroalaProvider } from '@intouch/its.essential/app/essential/domain/FroalaProvider';

/**
 *  The UI for rendering Normal Translation Items
 */
@Component('its.survey.module.surveys', TranslationItem.CID, {
    template: require('/app/modules/surveys/components/translations/TranslationItemComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
        locale: '<',
    },
})
class TranslationItem {
    static IID: string = 'TranslationItem';
    static CID: string = 'itsTranslationItem';
    static $inject: Array<string> = ['APPCONFIG', '$sce', 'iteLogger', '$translate'];

    protected item: IBaseItem;
    protected locale: string;
    protected froalaOptions: any = null;

    constructor(
        private config: any,
        private sce: ng.ISCEService,
        private logger: ILogger,
        private translate: ng.translate.ITranslateService
    ) {
        this.froalaOptions = FroalaProvider.defaultConfig();
        this.froalaOptions = FroalaProvider.convertOptionsToV4(this.froalaOptions);
        if (this.config.froala && this.config.froala.key) {
            this.froalaOptions.key = this.config.froala.key;
        } else {
            this.logger.warn('Unable to set Froala key');
        }
    }

    /**
     * Trust the string as HTML and allow angular to render it
     *
     * @param {string} html
     * @returns {string}
     */
    public trustAsHtml(html: string): string {
        return this.sce.trustAsHtml(html);
    }

    /**
     *
     * @returns {string}
     */
    public getItemTypeLabel(): string {
        switch (this.item.type) {
            case 'nps':
                return 'NPS®';
            case 'checkbox':
                return this.translate.instant('SURVEYS.ITEMS.LABELS.CHECKBOXES');
            case 'radio':
                return this.translate.instant('SURVEYS.ITEMS.LABELS.MULTIPLE_CHOICE');
            case 'textarea':
                return this.translate.instant('SURVEYS.ITEMS.LABELS.TEXT_AREA_2');
            case 'input':
                return this.translate.instant('SURVEYS.ITEMS.LABELS.TEXT_INPUT_2');
            default:
                return this.item.type;
        }
    }
}
