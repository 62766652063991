import { Configuration } from '@intouch/its.essential/app/essential/decorators/Configuration';
import { StateRerouter } from '@intouch/its.essential/app/essential/utils/StateRerouter';

/**
 * Will setup the routing states
 *
 * @param $stateProvider
 */
@Configuration('its.survey.module.surveys', FormsConfiguration)
class FormsConfiguration {
    /**
     * Create the instance of the configuration used for angular binding
     *
     * @returns {any}
     */
    public static instance(): any {
        const config: any = ($stateProvider: ng.ui.IStateProvider) => new FormsConfiguration($stateProvider);
        config.$inject = ['$stateProvider'];
        return config;
    }

    /**
     * Create the configuration class
     *
     * @param stateProvider
     */
    constructor(private stateProvider: ng.ui.IStateProvider) {
        this.buildStates();
    }

    /**
     * Initialize the forms module
     */
    public buildStates(): void {
        this.stateProvider
            .state('home.surveys', {
                abstract: true,
                url: '/surveys',
                template:
                    '<div layout="row" flex><div ui-view data-state-id="home.surveys" layout="column" flex></div></div>',
            })
            .state('home.surveys.create', {
                url: '/create?page&sort_by&order&search&category',
                data: { title: 'SURVEYS.NEW', translate: true },
                template: require('/app/modules/surveys/views/Create.html'),
                controller: 'its.survey.module.surveys.CreateController as vm',
                params: {
                    page: {
                        value: '1',
                        squash: true,
                    },
                    sort_by: {
                        value: 'name',
                        squash: true,
                    },
                    order: {
                        value: 'asc',
                        squash: true,
                    },
                    search: {
                        value: null,
                        squash: true,
                    },
                    category: {
                        value: null,
                        squash: true,
                    },
                },
            })
            .state('home.surveys.listing', {
                url: '?page&sort_by&order&search',
                data: { title: 'GENERAL.SURVEYS', translate: true },
                template: require('/app/modules/surveys/views/Listing.html'),
                controller: 'its.survey.module.surveys.ListingController as vm',
                params: {
                    page: {
                        value: '1',
                        squash: true,
                    },
                    sort_by: {
                        value: 'updated_at',
                        squash: true,
                    },
                    order: {
                        value: 'desc',
                        squash: true,
                    },
                    search: {
                        value: null,
                        squash: true,
                    },
                },
            })
            .state('home.surveys.edit', {
                url: '/:uuid',
                abstract: true,
                template: require('/app/modules/surveys/views/EditLayout.html'),
                controller: 'its.survey.module.surveys.EditController as vm',
                params: {
                    childState: {
                        squash: true,
                        value: null,
                    },
                },
            })
            .state('home.surveys.edit.build', {
                abstract: true,
                template: require('/app/modules/surveys/views/Build.html'),
                controller: 'its.survey.module.surveys.BuildController as vm',
            })
            .state('home.surveys.edit.build.details', {
                url: '/details',
                template: require('/app/modules/surveys/views/Details.html'),
                controller: 'its.survey.module.surveys.DetailsController as vm',
                params: {
                    tab: {
                        squash: true,
                        value: 'elements',
                    },
                    showPanel: {
                        squash: true,
                        value: false,
                    },
                },
            })
            .state('home.surveys.edit.build.logic', {
                url: '/logic',
                template: require('/app/modules/surveys/views/Logic.html'),
                controller: 'its.survey.module.surveys.LogicController as vm',
            })
            .state('home.surveys.edit.build.settings.scoring', {
                abstract: true,
                url: '/scoring',
                template: require('/app/modules/surveys/scoring/Scoring.html'),
                controller: 'its.survey.module.surveys.ScoringController as vm',
            })
            .state('home.surveys.edit.build.settings.scoring.points', {
                url: '/points',
                template: require('/app/modules/surveys/scoring/points/Points.html'),
                controller: 'its.survey.module.surveys.PointsController as vm',
            })
            .state('home.surveys.edit.build.settings.scoring.tagging', {
                url: '/tagging',
                template: require('/app/modules/surveys/scoring/tagging/Tagging.html'),
                controller: 'its.survey.module.surveys.TaggingController as vm',
            })
            .state('home.surveys.edit.build.settings.scoring.weighting', {
                url: '/weighting',
                template: require('/app/modules/surveys/scoring/weighting/Weighting.html'),
                controller: 'its.survey.module.surveys.WeightingController as vm',
                resolve: {
                    hasFeatureEnabled: StateRerouter.onNoProductFeature(
                        'survey',
                        'weighted-scoring',
                        {
                            state: 'home.surveys.edit.settings.general',
                        },
                        undefined,
                        ['authenticatedUser']
                    ),
                },
            })
            .state('home.surveys.edit.build.settings.translations', {
                url: '/translations',
                params: {
                    lang: {
                        squash: true,
                        value: null,
                    },
                },
                template: require('/app/modules/surveys/views/Translations.html'),
                controller: 'its.survey.module.surveys.TranslationsController as vm',
            })
            .state('home.surveys.edit.preview', {
                url: '/preview',
                template: require('/app/modules/surveys/views/Preview.html'),
                controller: 'its.survey.module.surveys.PreviewController as vm',
            })
            .state('home.surveys.edit.results', {
                url: '/results',
                template: require('/app/modules/surveys/views/Results.html'),
                controller: 'its.survey.module.surveys.ResultsController as vm',
            })
            .state('home.surveys.edit.build.settings', {
                url: '/settings',
                abstract: true,
                template: require('/app/modules/surveys/views/Settings.html'),
                controller: 'its.survey.module.surveys.SettingsController as vm',
            })
            .state('home.surveys.edit.build.settings.general', {
                url: '/general',
                template: require('/app/modules/surveys/views/General.html'),
                controller: 'its.survey.module.surveys.SettingsController as vm',
            })
            .state('home.surveys.edit.summary', {
                url: '/summary',
                template: require('/app/modules/surveys/summary/Summary.html'),
                controller: 'its.survey.module.surveys.SummaryController as vm',
            });
    }
}
