import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { IPageService } from '../../../../services/PageService';
import { BaseSurvey } from '../../BaseSurvey';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ISurveyService } from '../../../../services/SurveyService';
import { UpsellTool } from '@intouch/its.essential/app/essential/tools/UpsellTool';
import { ISiteNavigationService } from '@intouch/its.essential/app/essential/services/SiteNavigationService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';

@Controller('its.survey.module.surveys.distributions', DistributionWebsiteController.IID, DistributionWebsiteController)
class DistributionWebsiteController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.distributions.DistributionWebsiteController';
    static $inject: Array<string> = [
        'APPCONFIG',
        '$state',
        '$mdDialog',
        '$stateParams',
        'itsSurveyService',
        'iteToaster',
        'itsPageService',
        'itsSurveyApi',
        '$scope',
        'iteAccessService',
        'iteSiteNavigationService',
        'iteUserLocalTimeService',
    ];

    protected showEmbed: boolean = false;

    public selectedItem: any;
    public surveyTypes: Array<{ name: string; method: () => void }> = [
        { name: 'DISTRIBUTIONS.WEBSITE.TYPES.EMBED_CODE', method: this.toggleEmbed },
    ];
    public upsellTool: UpsellTool = null;

    constructor(
        private config: any,
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected scope: ng.IScope,
        private accessService: IAccessService,
        private siteNavService: ISiteNavigationService,
        protected userLocalTimeService: IUserLocalTimeService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );

        this.upsellTool = new UpsellTool(this.accessService, this.siteNavService).setStateByProductTiers('survey', [
            'grow',
            'scale',
            'lead',
        ]);

        if (!this.upsellTool.showUpsell()) {
            this.load().then(() => {
                this.selectedItem = this.surveyTypes[0].method;
                this.toggleType();
            });
        }
    }

    public toggleType(): void {
        this.selectedItem.call(this);
    }

    /**
     * Toggle the embed display
     */
    public toggleEmbed(): void {
        this.showEmbed = !this.showEmbed;
    }

    /**
     * Retrieves the public facing URL of the survey
     *
     * @returns {string}
     */
    public getSurveyUrl(): string {
        return this.surveyService.getSurveyUrl(this.survey) + '/' + this.survey.originalUuid;
    }
}
