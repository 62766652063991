import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { Section } from '../../../domain/surveys/Section';
import { IBaseItem } from '../../../domain/surveys/items/BaseItem';
import { Group } from '../../../domain/surveys/items/Group';
import { ISurvey } from '../../../domain/surveys/survey/Survey';
import { ISurveyApi } from '../../../api/SurveyApi';
import { SurveyService } from '../../../services/SurveyService';
import * as _ from 'lodash';
import { IContainItems } from '../../../domain/surveys/items/ContainItems';
import { SurveyQuestionMover } from '../../../domain/surveys/survey/utils/SurveyQuestionMover';
import ItemMoveModalHtml from './ItemMoveModal.html';

export class ItemMoveModal extends Modal {
    static $inject: Array<string> = [
        '$mdDialog',
        'itsSurveyApi',
        'iteLogger',
        '$translate',
        'iteToaster',
        'itsSurveyService',
    ];

    // passed via config.locals
    protected parent: IContainItems;
    protected item: IBaseItem;
    protected survey: ISurvey;

    // personal properties
    private submitting: boolean = false;
    private targetSection: Section = null;
    private targetItem: IBaseItem = null;
    private targetGroup: Group = null;
    private targetDirection: string = 'after';
    private addToGroup: boolean = false;

    /**
     * Create an instance of this modal
     *
     * @param {ng.material.IDialogOptions} config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = ItemMoveModalHtml;
        config.controller = ItemMoveModal;

        return super.instantiate(config);
    }

    public constructor(
        private dialog: ng.material.IDialogService,
        private surveyApi: ISurveyApi,
        private logger: ILogger,
        private translate: ng.translate.ITranslateService,
        private toaster: IToaster,
        private surveyService: SurveyService
    ) {
        super();
        this.surveyService.saveSnapshot();
    }

    /**
     * Handle the selection of an item (convert to targetGroup or set to targetItem)
     */
    public handleItemSelection(item: IBaseItem): void {
        if (item.type === 'group') {
            this.targetGroup = <Group>item;
            this.targetItem = null;
        } else {
            this.targetGroup = null;
            this.targetItem = item;
        }
    }

    /**
     * Cancel and close the modal
     */
    public cancel(): void {
        this.dialog.cancel();
    }

    /**
     * Returns text used for dynamic translation text (hint when adding to group)
     */
    public getPositionText(direction: string): string {
        switch (direction) {
            case 'after':
                return 'end';
            case 'before':
                return 'start';
            default:
                return direction;
        }
    }

    public canBeGrouped(): boolean {
        return Group.canHoldItem(this.item.type);
    }

    public submit(): void {
        this.submitting = true;

        if (this.handleMovingItem()) {
            this.surveyService
                .save(this.survey)
                .then((survey) => {
                    this.surveyService.setSurvey(survey);
                    this.dialog.hide({ movedItem: this.item });
                })
                .catch(() => {
                    this.surveyService.restoreToPreviousSnapshot();
                    this.toaster.error('ERRORS.ITEMS.MOVE');
                    this.submitting = false;
                });
        } else {
            this.toaster.error('ERRORS.ITEMS.MOVE');
            this.submitting = false;
        }
    }

    public handleMovingItem(): boolean {
        let destination: IContainItems;
        const targetItem: IBaseItem = this.targetGroup && !this.targetItem ? this.targetGroup : this.targetItem,
            indexOffset: number = this.targetDirection === 'after' ? 1 : 0;

        const currentIndex: number = _.findIndex(this.parent.items, { uuid: this.item.uuid });
        if (this.addToGroup) {
            destination = this.targetGroup;
        } else {
            destination = this.targetSection;
        }

        let destinationIndex: number = targetItem
            ? _.findIndex(destination.items, { uuid: targetItem.uuid })
            : destination.items.length;
        destinationIndex += indexOffset;
        SurveyQuestionMover.moveQuestion(this.parent, currentIndex, destination, destinationIndex);

        return true;
    }
}
