import { AbstractTheme } from './AbstractTheme';
import { IThemeSettings } from './ThemeSettings';
import { ThemeType } from '../../../services/ThemeService';

export class DarkTheme extends AbstractTheme {
    public slug: ThemeType = 'dark-default';

    public constructor() {
        super();
        // main bg
        this.customizations['its-theme--background']['background-color'] = '#1f1f1f';
        // survey bg
        this.customizations['its-theme--background-content']['background-color'] = '#3f3f3f';
        this.customizations['its-theme--question-container']['background-color'] = 'transparent';
        this.customizations['its-theme--title']['background-color'] = 'transparent';
        // header bg
        this.customizations['its-theme--header-background']['background-color'] = '#3f3f3f';
        // footer bg
        this.customizations['its-theme--footer-background']['background-color'] = '#3f3f3f';
        // question font
        this.customizations['its-theme--question-label']['color'] = '#FFFFFF';
        // error container
        this.customizations['its-theme--error-container']['background-color'] = '#673737';
        // response font
        this.customizations['its-theme--response-label-color']['color'] = '#FFFFFF';
        // help font
        this.customizations['its-theme--help-text']['color'] = '#dddddd';
        this.customizations['::placeholder']['color'] = '#DDDDDD';
        // title
        this.customizations['its-theme--title']['color'] = '#FFFFFF';
    }

    public getName(): string {
        return 'SURVEYS.THEMES.DARK';
    }

    public getSettings(): IThemeSettings {
        return this.customizations;
    }
}
