import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface IAbstractScheduledDistribution extends IEntity {
    uuid: string;
    type: string;
    contactListName: string;
    sent: boolean;
    sentTo: number;
    scheduledAt: string;
}

export abstract class AbstractScheduledDistribution extends Entity implements IAbstractScheduledDistribution {
    uuid: string = null;
    type: string = null;
    contactListName: string = null;
    sent: boolean = false;
    sentTo: number = 0;
    scheduledAt: string = null;
}
