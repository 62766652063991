import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', LocationRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/location/LocationRenderComponent.html'),
    controllerAs: 'vm',
    bindings: LocationRenderComponent.getBindings(),
})
class LocationRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'LocationRenderComponent';
    static CID: string = 'itsLocationRender';
    static $inject: string[] = [];
}
