import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IAccessApi } from '@intouch/its.essential/app/essential/api/AccessApi';

import { ISurveyApi } from '../../../../api/SurveyApi';
import { ISurvey } from '../../../../domain/surveys/survey/Survey';
import { IPageService } from '../../../../services/PageService';
import { ISurveyService } from '../../../../services/SurveyService';
import { IWeightedTag } from '../../../../domain/surveys/items/shared/WeightedTag';
import { IBaseItem } from '../../../../domain/surveys/items/BaseItem';
import { BaseSurvey } from '../../BaseSurvey';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import { UpsellTool } from '@intouch/its.essential/app/essential/tools/UpsellTool';
import { ISiteNavigationService } from '@intouch/its.essential/app/essential/services/SiteNavigationService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';

/**
 * Form edit Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.surveys', TaggingController.IID, TaggingController)
class TaggingController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.TaggingController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        '$stateParams',
        'itsSurveyService',
        'iteToaster',
        'itsPageService',
        'itsSurveyApi',
        '$scope',
        'iteAccessApi',
        'iteAccessService',
        'iteSiteNavigationService',
        'iteUserLocalTimeService',
    ];

    public survey: ISurvey = null;
    public saving: boolean = false;
    public publishing: boolean = false;
    public hasQuestions: boolean = false;
    public hasValidTagQuestion: boolean = false;
    public upsellTool: UpsellTool = null;

    constructor(
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected scope: ng.IScope,
        private accessApi: IAccessApi,
        private accessService: IAccessService,
        private siteNavService: ISiteNavigationService,
        protected userLocalTimeSerice: IUserLocalTimeService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeSerice
        );

        this.upsellTool = new UpsellTool(this.accessService, this.siteNavService).setStateByProductTiers('survey', [
            'grow',
            'scale',
            'lead',
        ]);

        if (!this.upsellTool.showUpsell()) {
            this.load().then(() => {
                this.setLoadedSurvey();
            });
        }
    }

    public saveSurvey(): void {
        this.save(this.survey);
    }

    /**
     * Remap tags on change. Whenever a change is made to the tags of an item, the tag weight needs to be set to the current weight of the same tag
     * already applied from another item. This way same tag has same weight across items
     *
     * @param item
     */
    public mapTags(item: IBaseItem): void {
        if (item.accessTags && item.accessTags.length > 0) {
            item.accessTags = item.accessTags.map((tag: IWeightedTag) => {
                return {
                    name: tag.name,
                    uuid: tag.uuid,
                    weight: NumberUtils.isNumber(tag.weight) ? tag.weight : this.survey.getTagWeight(tag.uuid),
                };
            });
        }
    }

    /**
     * Sets controller survey and other potentially long recursive function calls to booleans and page title
     * @param survey
     */
    private setLoadedSurvey(): void {
        this.hasQuestions = this.surveyService.getSurvey().hasQuestions();
        this.hasValidTagQuestion = this.surveyService.getSurvey().hasValidTagQuestion();
    }
}
