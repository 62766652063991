import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { BaseItem, IBaseItem } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { IHelpTextSettings, IHelpTextTranslation } from './shared/HelpText';
import { IHealthPoints } from './IHealthPoints';
import { ITranslatedEntity } from '../../ITranslatedEntity';

export interface IDigitTranslation extends IHelpTextTranslation {
    placeholder: string;
}

export class DigitTranslation extends Translation implements IDigitTranslation {
    placeholder: string = null;
    additionalText: string = null;
}

export interface IDigitValidation extends IEntity {
    type: string;
    regex: string;
}

export class DigitValidation extends Entity implements IDigitValidation {
    type: string = null;
    regex: string = null;
}

export interface IDigitSettings extends IBaseSettings, IHelpTextSettings<IDigitTranslation> {
    maxLength: number;
    placeholder: string;
}

export class DigitSettings extends BaseSettings implements IDigitSettings {
    maxLength: number = null;
    placeholder: string = null;
    additionalText: string = null;
    translations: Array<IDigitTranslation> = [];

    public fromJson(jsonObject: any, convertToCamel?: boolean): IDigitSettings {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<IDigitTranslation>(
                DigitTranslation,
                jsonObject.translations,
                true
            );
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.placeholder) {
            keys.push('placeholder');
        }

        if (this.additionalText) {
            keys.push('additionalText');
        }
        return keys;
    }

    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

export interface IDigit extends IBaseItem {
    validation: IDigitValidation;
}

export class Digit extends BaseItem implements IDigit, IHealthPoints {
    type: string = 'number';
    settings: IDigitSettings = new DigitSettings();
    validation: DigitValidation = new DigitValidation();

    @NotEnumerable
    _healthPoints: number = 2;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey. This overrides the BaseItem default value.
     */
    get healthPoints(): number {
        return this._healthPoints;
    }

    /**
     * Build the text input item from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IInput}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<IDigitSettings>(DigitSettings, jsonObject.settings, true);
        }

        if (jsonObject.validation) {
            this.validation = EntityBuilder.buildOne<DigitValidation>(DigitValidation, jsonObject.validation, true);
        }

        return this;
    }

    public toJSON(convert: boolean): any {
        if (this.validation && !this.validation.type) {
            this.validation = null;
        }
        return this;
    }
}
