import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';
import { ISiteNavigationService } from '@intouch/its.essential/app/essential/services/SiteNavigationService';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { IPageService } from '../../../../services/PageService';
import { IEmailDistribution } from '../../../../domain/contact-center/EmailDistribution';
import { IScheduledEmailDistribution } from '../../../../domain/contact-center/ScheduledEmailDistribution';
import { BaseSurvey } from '../../BaseSurvey';
import { EmailPreviewModal } from './EmailPreviewModal';
import { ISurveyService } from '../../../../services/SurveyService';
import { DistributeSurveyModal } from '../modals/DistributeSurveyModal';
import { UpsellTool } from '@intouch/its.essential/app/essential/tools/UpsellTool';

@Controller('its.survey.module.surveys.distributions', DistributionEmailController.IID, DistributionEmailController)
class DistributionEmailController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.distributions.DistributionEmailController';
    static $inject: Array<string> = [
        '$translate',
        'iteAccessService',
        '$mdMedia',
        '$state',
        '$mdDialog',
        '$stateParams',
        'itsSurveyService',
        'iteToaster',
        'itsPageService',
        'itsSurveyApi',
        'iteUserLocalTimeService',
        'iteSiteNavigationService',
        '$scope',
    ];

    public searchText: string = null;
    public loadingEmails: boolean = false;
    public loadingQueuedScheduledDistributions: boolean = false;
    public loadingHistoricScheduledDistributions: boolean = false;
    public upsellText: string[] = [];
    public upsellTool: UpsellTool = null;
    protected hasError: boolean = false;
    protected emails: Array<IEmailDistribution> = [];
    protected pager: IPager = new Pager();
    protected qsdPager: IPager = new Pager();
    protected hsdPager: IPager = new Pager();
    protected isContactAdmin: boolean = false;
    protected queuedScheduledDistributions: Array<IScheduledEmailDistribution> = [];
    protected historicScheduledDistributions: Array<IScheduledEmailDistribution> = [];

    constructor(
        protected translate: ng.translate.ITranslateService,
        private accessService: IAccessService,
        private mdMedia: ng.material.IMedia,
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected userLocalTimeService: IUserLocalTimeService,
        protected siteNavigationService: ISiteNavigationService,
        protected scope: ng.IScope
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );

        this.setPagers();
        this.isContactAdmin = this.accessService.getToken().getUser().hasAcl('contact_center_admin');
        this.upsellTool = new UpsellTool(this.accessService, this.siteNavigationService).setStateByProductFeature(
            'survey',
            'email-distributions'
        );

        this.translate([
            'CONTACT_CENTER.UPGRADE.EMAIL_ADMIN.EMAIL_DISTRIBUTIONS_REACH',
            'CONTACT_CENTER.UPGRADE.EMAIL_ADMIN.UPGRADE',
            'ESSENTIAL.GENERAL.CONTACT_ADMIN',
            'ESSENTIAL.GENERAL.FIND_ADMIN',
        ]).then((translations) => {
            this.upsellText = [
                translations['CONTACT_CENTER.UPGRADE.EMAIL_ADMIN.EMAIL_DISTRIBUTIONS_REACH'],
                translations['CONTACT_CENTER.UPGRADE.EMAIL_ADMIN.UPGRADE'],
            ];

            if (!this.upsellTool.isAdmin) {
                this.upsellText.push(
                    translations['ESSENTIAL.GENERAL.CONTACT_ADMIN'],
                    translations['ESSENTIAL.GENERAL.FIND_ADMIN']
                );
            }
        });

        if (!this.upsellTool.showUpsell()) {
            this.load().then(() => {
                this.loadEmailDetails();
                this.loadQueuedScheduledDistributions();
                this.loadHistoricScheduledDistributions();
            });
        }
    }

    public loadEmailDetails(): ng.IPromise<any> {
        this.loadingEmails = true;

        return this.surveyApi
            .getSurveyEmails(this.surveyService.getSurvey().originalUuid, this.pager, this.searchText)
            .then((emailResponse: PagedEntities) => {
                this.emails = emailResponse.getEntities();
                this.pager = emailResponse.getPager();
            })
            .catch(() => {
                this.hasError = true;
                this.toaster.error('ERRORS.FAILED_EMAIL_LOAD');
            })
            .finally(() => {
                this.loadingEmails = false;
            });
    }

    /**
     * Load the Queued Distributions
     *
     * @param showLoader
     */
    public loadQueuedScheduledDistributions(showLoader: boolean = true): ng.IPromise<any> {
        this.loadingQueuedScheduledDistributions = showLoader;

        return this.surveyApi
            .getScheduledDistributionsList(this.surveyService.getSurvey().originalUuid, false, 'email', this.qsdPager)
            .then((qsdResponse: PagedEntities) => {
                this.queuedScheduledDistributions = qsdResponse.getEntities();
                this.qsdPager = qsdResponse.getPager();
            })
            .catch(() => {
                this.hasError = true;
                this.toaster.error('ERRORS.FAILED_QUEUED_DISTRIBUTION_LOAD');
            })
            .finally(() => {
                this.loadingQueuedScheduledDistributions = false;
            });
    }

    /**
     * Load the Historic Distributions
     */
    public loadHistoricScheduledDistributions(): ng.IPromise<any> {
        this.loadingHistoricScheduledDistributions = true;

        return this.surveyApi
            .getScheduledDistributionsList(this.surveyService.getSurvey().originalUuid, true, 'email', this.hsdPager)
            .then((hsdResponse: PagedEntities) => {
                this.historicScheduledDistributions = hsdResponse.getEntities();
                this.hsdPager = hsdResponse.getPager();
            })
            .catch(() => {
                this.hasError = true;
                this.toaster.error('ERRORS.FAILED_HISTORIC_DISTRIBUTION_LOAD');
            })
            .finally(() => {
                this.loadingHistoricScheduledDistributions = false;
            });
    }

    /**
     * Confirm deletion of email
     *
     * @param {string} uuid
     */
    public confirmDelete(uuid: string): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translate.instant('DISTRIBUTIONS.EMAIL.DELETE_EMAIL_TITLE'),
                        description: this.translate.instant('DISTRIBUTIONS.EMAIL.CONFIRM_DELETE_EMAIL'),
                        confirmText: this.translate.instant('GENERAL.DELETE'),
                        cancelText: this.translate.instant('GENERAL.CANCEL'),
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((confirmed) => {
                if (confirmed && this.survey) {
                    this.surveyApi
                        .deleteSurveyEmail(this.survey.originalUuid, uuid)
                        .then(() => {
                            this.toaster.success('DISTRIBUTIONS.EMAIL.DELETED');
                            this.pager.currentPage = 1;
                            this.loadEmailDetails();
                        })
                        .catch(() => {
                            this.toaster.error('ERRORS.FAILED_EMAIL_DELETE');
                        });
                }
            });
    }

    /**
     * Opens the distribute survey modal
     */
    public distributeSurvey(emailDistribution: IEmailDistribution): void {
        this.dialog.show(
            DistributeSurveyModal.instantiate({
                locals: {
                    distributionType: 'email',
                    emailOrSmsDistribution: emailDistribution,
                    survey: this.survey,
                    surveyOriginalUuid: this.survey.originalUuid,
                },
            })
        );
    }

    public previewEmail(emailDistribution: IEmailDistribution): void {
        this.dialog.show(
            EmailPreviewModal.instantiate({
                locals: {
                    emailDistribution: emailDistribution,
                    surveyOriginalUuid: this.survey.originalUuid,
                },
            })
        );
    }

    /**
     * Reload page with pager information
     *
     * @param {IPager} pager
     */
    public goToSelf(pager: IPager): void {
        this.stateService
            .go(
                this.stateService.current.name,
                {
                    search: this.searchText,
                    page: pager.currentPage,
                    sort_by: pager.sortBy,
                    order: pager.order,
                },
                {
                    notify: false, // prevent the events onStart and onSuccess from firing
                    reload: false, // prevent reload of the current state
                    location: 'replace', // replace the last record when changing the params so you don't hit the back button and get old params
                    inherit: true, // inherit the current params on the url
                }
            )
            .then(() => {
                this.loadEmailDetails();
            });
    }

    /**
     * Reload page with pager information
     *
     * @param {IPager} pager
     */
    public goToSelfQsd(pager: IPager): void {
        this.stateService
            .go(
                this.stateService.current.name,
                {
                    search: this.searchText,
                    qsd_page: pager.currentPage,
                    qsd_sort_by: pager.sortBy,
                    qsd_order: pager.order,
                },
                {
                    notify: false, // prevent the events onStart and onSuccess from firing
                    reload: false, // prevent reload of the current state
                    location: 'replace', // replace the last record when changing the params so you don't hit the back button and get old params
                    inherit: true, // inherit the current params on the url
                }
            )
            .then(() => {
                this.loadQueuedScheduledDistributions();
            });
    }

    /**
     * Reload page with pager information
     *
     * @param {IPager} pager
     */
    public goToSelfHsd(pager: IPager): void {
        this.stateService
            .go(
                this.stateService.current.name,
                {
                    search: this.searchText,
                    hsd_page: pager.currentPage,
                    hsd_sort_by: pager.sortBy,
                    hsd_order: pager.order,
                },
                {
                    notify: false, // prevent the events onStart and onSuccess from firing
                    reload: false, // prevent reload of the current state
                    location: 'replace', // replace the last record when changing the params so you don't hit the back button and get old params
                    inherit: true, // inherit the current params on the url
                }
            )
            .then(() => {
                this.loadHistoricScheduledDistributions();
            });
    }

    /**
     * Allow a user to toggle the sort order of a column
     *
     * @param field
     */
    public toggleSort(field: string): void {
        if (this.pager.isCurrentSort(field)) {
            this.pager.toggleSortOrder();
        }
        this.pager.sortBy = field;
        this.goToSelf(this.pager);
    }

    /**
     * Allow a user to toggle the sort order of a column
     *
     * @param field
     */
    public toggleSortQsd(field: string): void {
        if (this.qsdPager.isCurrentSort(field)) {
            this.qsdPager.toggleSortOrder();
        }
        this.qsdPager.sortBy = field;
        this.goToSelfQsd(this.qsdPager);
    }

    /**
     * Allow a user to toggle the sort order of a column
     *
     * @param field
     */
    public toggleSortHsd(field: string): void {
        if (this.hsdPager.isCurrentSort(field)) {
            this.hsdPager.toggleSortOrder();
        }
        this.hsdPager.sortBy = field;
        this.goToSelfHsd(this.hsdPager);
    }

    public toggleEnabled(email: IEmailDistribution): void {
        if (email.active) {
            this.surveyApi
                .disableEmailDistribution(this.survey.originalUuid, email)
                .then(() => {
                    this.goToSelf(this.pager);
                })
                .catch(() => {
                    this.toaster.error(this.translate.instant('ERRORS.FAILED_EMAIL_DISABLE'));
                });
        } else {
            this.surveyApi
                .enableEmailDistribution(this.survey.originalUuid, email)
                .then(() => {
                    this.goToSelf(this.pager);
                })
                .catch(() => {
                    this.toaster.error(this.translate.instant('ERRORS.FAILED_EMAIL_ENABLE'));
                });
        }
    }

    /**
     * Allow a user to go to the next page of submissions
     */
    public next(): void {
        if (this.pager.canGoNext()) {
            this.pager.currentPage++;
            this.goToSelf(this.pager);
        }
    }

    /**
     * Allow a user to go to the next page of submissions
     */
    public nextQueuedDistributions(): void {
        if (this.qsdPager.canGoNext()) {
            this.qsdPager.currentPage++;
            this.loadQueuedScheduledDistributions();
        }
    }

    /**
     * Allow a user to go to the next page of submissions
     */
    public nextHistoricDistributions(): void {
        if (this.hsdPager.canGoNext()) {
            this.hsdPager.currentPage++;
            this.loadHistoricScheduledDistributions();
        }
    }

    /**
     * Allow a user to go to the previous page of submissions
     */
    public prev(): void {
        if (this.pager.canGoPrevious()) {
            this.pager.currentPage--;
            this.goToSelf(this.pager);
        }
    }

    public prevQueuedDistributions(): void {
        if (this.qsdPager.canGoPrevious()) {
            this.qsdPager.currentPage--;
            this.loadQueuedScheduledDistributions();
        }
    }

    public prevHistoricDistributions(): void {
        if (this.hsdPager.canGoPrevious()) {
            this.hsdPager.currentPage--;
            this.loadHistoricScheduledDistributions();
        }
    }

    /**
     * Delete the queued Scheduled Distribution
     *
     * @param {string} uuid
     */
    public confirmDeleteScheduledDistribution(uuid: string): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translate.instant('DISTRIBUTIONS.SCHEDULED.DELETE_SCHEDULED_DISTRIBUTION_TITLE'),
                        description: this.translate.instant(
                            'DISTRIBUTIONS.SCHEDULED.CONFIRM_DELETE_SCHEDULED_DISTRIBUTION'
                        ),
                        confirmText: this.translate.instant('GENERAL.DELETE'),
                        cancelText: this.translate.instant('GENERAL.CANCEL'),
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((confirmed) => {
                if (confirmed && this.survey) {
                    this.surveyApi
                        .deleteScheduledDistribution(this.survey.originalUuid, uuid)
                        .then(() => {
                            this.toaster.success('DISTRIBUTIONS.SCHEDULED.DISTRIBUTION_DELETED');
                            this.qsdPager.currentPage = 1;
                            this.loadQueuedScheduledDistributions(false);
                        })
                        .catch(() => {
                            this.toaster.warn('ERRORS.FAILED_SCHEDULED_DISTRIBUTION_DELETE');
                        });
                }
            });
    }

    /**
     * Sets up all the pagers
     */
    private setPagers(): void {
        this.pager = Pager.make(
            this.stateService.params['page'],
            this.stateService.params['sort_by'],
            this.stateService.params['order'],
            5
        );

        this.qsdPager = Pager.make(
            this.stateService.params['qsd_page'],
            this.stateService.params['qsd_sort_by'],
            this.stateService.params['qsd_order'],
            5
        );

        this.hsdPager = Pager.make(
            this.stateService.params['hsd_page'],
            this.stateService.params['hsd_sort_by'],
            this.stateService.params['hsd_order']
        );
    }
}
