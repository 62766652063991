import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Scale } from '../../../../../../domain/surveys/items/Scale';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';
import { ItemSettingsTab } from '../../shared/item-settings-tabs/ItemSettingsTabs';

@Component('its.survey.module.surveys', ScaleSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/scale/ScaleSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: ScaleSettingsComponent.getBindings(),
})
class ScaleSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'ScaleSettingsComponent';
    static CID: string = 'itsScaleSettings';
    static $inject: string[] = ['$translate'];

    public item: Scale;
    public tabs: ItemSettingsTab[] = ['basic', 'advanced', 'points'];

    public constructor(private translate: ng.translate.ITranslateService) {
        super();
    }

    public handleScaleResponseChange(item: Scale): void {
        item.changeResponseType(item.settings.responseType, this.translate);
    }
}
