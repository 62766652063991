import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', InputRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/input/InputRenderComponent.html'),
    controllerAs: 'vm',
    bindings: InputRenderComponent.getBindings(),
})
class InputRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'InputRenderComponent';
    static CID: string = 'itsInputRender';
    static $inject: string[] = [];
}
