import { TranslationLocator } from '../TranslationLocator';
import { Entity } from '@intouch/its.essential/app/essential/domain/Entity';
import { ITranslation } from '../Translation';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { ISurveyLanguage, SurveyLanguage } from './SurveyLanguage';
import { ISurveySettingTranslation, SurveySettingTranslation } from './SurveySettingTranslation';
import { ISurveyJS, SurveyJS } from './SurveyJS';
import { ITranslatedEntity } from '../../ITranslatedEntity';

export interface ISurveySettings extends ITranslatedEntity {
    showSurveyName: boolean;
    showProgressBar: boolean;
    showPageNumbers: boolean;
    enableCustomButtons: boolean;
    enableCompleteRedirect: boolean;
    enableLanguageToggle: boolean;
    enableCustomSubmissionDate: boolean;
    enableCustomMessage: boolean;
    hideOptionalMessage: boolean;
    customMessage: string;
    ignoreData: boolean;
    supportedLanguages: Array<ISurveyLanguage>;
    backButtonText: string;
    nextButtonText: string;
    redirectUrl: string;
    redirectSeconds: number;
    limitSubmissions: boolean;
    submissionsPeriodInDays: number;
    submissionsPerPeriod: number;
    enableWeightedTagScoring: boolean;
    translations: Array<ISurveySettingTranslation>;
    sourceLanguage: string;
    customJavascript: ISurveyJS;
    customDomain: string;
    showContactInfo: boolean;
    hideIntouchBranding: boolean;

    getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation;
    getSupportedLanguages(): Array<ISurveyLanguage>;
    resetSubmissionSettings(): void;
}

export class SurveySettings extends Entity implements ISurveySettings {
    showSurveyName: boolean = false;
    showProgressBar: boolean = false;
    showPageNumbers: boolean = false;
    enableCustomButtons: boolean = false;
    enableCompleteRedirect: boolean = false;
    enableLanguageToggle: boolean = false;
    enableCustomSubmissionDate: boolean = false;
    enableCustomMessage: boolean = false;
    hideOptionalMessage: boolean = false;
    customMessage: string = null;
    ignoreData: boolean = false;
    defaultLanguage: string = null;
    supportedLanguages: Array<ISurveyLanguage> = [];
    backButtonText: string = null;
    nextButtonText: string = null;
    redirectUrl: string = null;
    redirectSeconds: number = null;
    limitSubmissions: boolean = false;
    submissionsPeriodInDays: number = null;
    submissionsPerPeriod: number = null;
    enableWeightedTagScoring: boolean = false;
    translations: Array<ISurveySettingTranslation> = [];
    sourceLanguage: string = 'en_US';
    customJavascript: ISurveyJS = new SurveyJS();
    customDomain: string = null;
    showContactInfo: boolean = false;
    hideIntouchBranding: boolean = true;

    public fromJson(jsonObject: any, convertToCamel?: boolean): ISurveySettings {
        super.fromJson(jsonObject, true);

        if (jsonObject.custom_javascript) {
            this.customJavascript = EntityBuilder.buildOne<ISurveyJS>(
                SurveyJS,
                jsonObject.custom_javascript,
                convertToCamel
            );
        }

        if (jsonObject.supported_languages) {
            this.supportedLanguages = EntityBuilder.buildMany<ISurveyLanguage>(
                SurveyLanguage,
                jsonObject.supported_languages,
                convertToCamel
            );
        }

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ISurveySettingTranslation>(
                SurveySettingTranslation,
                jsonObject.translations,
                convertToCamel
            );
        }

        return this;
    }

    /**
     * Reset submission limit settings
     */
    public resetSubmissionSettings(): void {
        this.submissionsPeriodInDays = null;
        this.submissionsPerPeriod = null;
    }

    /**
     * Retrieve the given translation for a locale
     *
     * @param {string} locale
     * @param {string} label
     * @param {boolean} createOnNotFound
     * @returns {ITranslation}
     */
    public getTranslation(locale: string, label: string, createOnNotFound: boolean = true): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }

    /**
     * Get translation keys for survey settings
     */
    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = ['backButtonText', 'nextButtonText'];
        if (this.enableCustomMessage) {
            keys.push('customMessage');
        }
        return keys;
    }

    public getSupportedLanguages(): Array<ISurveyLanguage> {
        return this.supportedLanguages;
    }
}
