import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { BaseItem, IBaseItem } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { IHelpTextSettings, IHelpTextTranslation } from './shared/HelpText';
import moment, { DurationInputArg2 } from 'moment';
import { IHealthPoints } from './IHealthPoints';
import { ITranslatedEntity } from '../../ITranslatedEntity';

/**
 * Calendar date restriction ( range checking ) interface
 */
export interface ICalendarDateRestriction extends IEntity {
    restrictionType: string;
    dateInput: string;
    calculationUnit: string;
    dateAmount: number;
    plusMinus: string;
    getDate(): any;
}

export class CalendarDateRestriction extends Entity implements ICalendarDateRestriction {
    restrictionType: string = null;
    dateInput: string = null;
    calculationUnit: DurationInputArg2 = null;
    dateAmount: number = 0;
    plusMinus: string = 'positive';

    public getDate(): any {
        if (this.restrictionType === 'calculated') {
            if (this.plusMinus === 'positive') {
                return moment().add(this.dateAmount, this.calculationUnit).toDate();
            }
            return moment().subtract(this.dateAmount, this.calculationUnit).toDate();
        } else if (this.restrictionType === 'specific') {
            return moment(this.dateInput).toDate();
        } else {
            return null;
        }
    }
}

export type ICalendarTranslation = IHelpTextTranslation;

export class CalendarTranslation extends Translation implements ICalendarTranslation {
    additionalText: string = null;
}

export interface ICalendarSettings extends IBaseSettings, IHelpTextSettings<ICalendarTranslation> {
    format: string;
    isDateOfDocument: boolean;
    restrictDateSelection: boolean;
    earliestDate: ICalendarDateRestriction;
    latestDate: ICalendarDateRestriction;
    translations: Array<ICalendarTranslation>;

    getAvailableFormats(): Array<string>;
}

export class CalendarSettings extends BaseSettings implements ICalendarSettings, ITranslatedEntity {
    format: string = 'MM/DD/YYYY';
    isDateOfDocument: boolean = false;
    restrictDateSelection: boolean = false;
    earliestDate: ICalendarDateRestriction = new CalendarDateRestriction();
    latestDate: ICalendarDateRestriction = new CalendarDateRestriction();
    additionalText: string = null;
    translations: Array<ICalendarTranslation> = [];

    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.earliest_date) {
            this.earliestDate = EntityBuilder.buildOne<ICalendarDateRestriction>(
                CalendarDateRestriction,
                jsonObject.earliest_date,
                true
            );
        }
        if (jsonObject.latest_date) {
            this.latestDate = EntityBuilder.buildOne<ICalendarDateRestriction>(
                CalendarDateRestriction,
                jsonObject.latest_date,
                true
            );
        }

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ICalendarTranslation>(
                CalendarTranslation,
                jsonObject.translations,
                true
            );
        }

        return this;
    }

    /**
     * Available calendar format settings
     * @returns {Array<string>}
     */
    public getAvailableFormats(): Array<string> {
        return ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD'];
    }

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.additionalText) {
            keys.push('additionalText');
        }
        return keys;
    }

    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

export interface ICalendar extends IBaseItem {
    settings: ICalendarSettings;
}

export class Calendar extends BaseItem implements ICalendar, IHealthPoints {
    type: string = 'calendar';
    settings: ICalendarSettings = new CalendarSettings();

    @NotEnumerable
    _healthPoints: number = 2;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey. This overrides the BaseItem default value.
     */
    get healthPoints(): number {
        return this._healthPoints;
    }

    /**
     * Build the Calendar item from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {ICalendar}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<ICalendarSettings>(CalendarSettings, jsonObject.settings, true);
        }

        return this;
    }

    public toJSON(convert: boolean): any {
        /* ensure settings object gets cleared if restrict date is disabled */
        if (!this.settings.restrictDateSelection) {
            this.settings.earliestDate.restrictionType = null;
            this.settings.earliestDate.dateInput = null;
            this.settings.earliestDate.dateAmount = null;

            this.settings.latestDate.restrictionType = null;
            this.settings.latestDate.dateInput = null;
            this.settings.latestDate.dateAmount = null;
        }
        return this;
    }
}
