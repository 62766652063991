import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';

export interface IFontawesomeIcon {
    icon: string;
    label: string;
    category: string;
    type: string;
    types: Array<string>;
}

export class FontawesomeIcon implements IFontawesomeIcon {
    icon: string = null;
    label: string = null;
    category: string = null;
    type: string = null;
    @NotEnumerable
    types: Array<string> = [];
}

export class FontawesomeProvider {
    public static getIcons(translate: ng.translate.ITranslateService): Array<IFontawesomeIcon> {
        return [
            // solid and outline types
            // communication icons
            {
                icon: 'ban',
                label: translate.instant('ICONS.BAN'),
                type: null,
                types: ['solid'],
                category: 'communication',
            },
            {
                icon: 'at',
                label: translate.instant('ICONS.AT'),
                type: null,
                types: ['solid'],
                category: 'communication',
            },
            {
                icon: 'comment',
                label: translate.instant('ICONS.COMMENT'),
                type: null,
                types: ['solid', 'outline'],
                category: 'communication',
            },
            {
                icon: 'comments',
                label: translate.instant('ICONS.COMMENTS'),
                type: null,
                types: ['solid', 'outline'],
                category: 'communication',
            },
            {
                icon: 'envelope',
                label: translate.instant('ICONS.ENVELOPE'),
                type: null,
                types: ['solid', 'outline'],
                category: 'communication',
            },
            {
                icon: 'fax',
                label: translate.instant('ICONS.FAX'),
                type: null,
                types: ['solid'],
                category: 'communication',
            },
            {
                icon: 'phone',
                label: translate.instant('ICONS.BAN'),
                type: null,
                types: ['solid'],
                category: 'communication',
            },
            // devices icons
            {
                icon: 'desktop',
                label: translate.instant('ICONS.DESKTOP'),
                type: null,
                types: ['solid'],
                category: 'devices',
            },
            {
                icon: 'laptop',
                label: translate.instant('ICONS.LAPTOP'),
                type: null,
                types: ['solid'],
                category: 'devices',
            },
            {
                icon: 'mobile-alt',
                label: translate.instant('ICONS.SMARTPHONE'),
                type: null,
                types: ['solid'],
                category: 'devices',
            },
            {
                icon: 'tablet-alt',
                label: translate.instant('ICONS.TABLET'),
                type: null,
                types: ['solid'],
                category: 'devices',
            },
            // emoji icons
            {
                icon: 'angry',
                label: translate.instant('ICONS.ANGRY'),
                type: null,
                types: ['solid', 'outline'],
                category: 'emoji',
            },
            {
                icon: 'frown',
                label: translate.instant('ICONS.FROWN'),
                type: null,
                types: ['solid', 'outline'],
                category: 'emoji',
            },
            {
                icon: 'grin',
                label: translate.instant('ICONS.GRIN'),
                type: null,
                types: ['solid', 'outline'],
                category: 'emoji',
            },
            {
                icon: 'laugh',
                label: translate.instant('ICONS.LAUGH'),
                type: null,
                types: ['solid', 'outline'],
                category: 'emoji',
            },
            {
                icon: 'meh',
                label: translate.instant('ICONS.MEH'),
                type: null,
                types: ['solid', 'outline'],
                category: 'emoji',
            },
            // transportation
            {
                icon: 'bicycle',
                label: translate.instant('ICONS.BICYCLE'),
                type: null,
                types: ['solid'],
                category: 'transportation',
            },
            {
                icon: 'bus',
                label: translate.instant('ICONS.BUS'),
                type: null,
                types: ['solid'],
                category: 'transportation',
            },
            {
                icon: 'car',
                label: translate.instant('ICONS.CAR'),
                type: null,
                types: ['solid'],
                category: 'transportation',
            },
            {
                icon: 'ship',
                label: translate.instant('ICONS.SHIP'),
                type: null,
                types: ['solid'],
                category: 'transportation',
            },
            {
                icon: 'shuttle-van',
                label: translate.instant('ICONS.SHUTTLE_VAN'),
                type: null,
                types: ['solid'],
                category: 'transportation',
            },
            {
                icon: 'subway',
                label: translate.instant('ICONS.SUBWAY'),
                type: null,
                types: ['solid'],
                category: 'transportation',
            },
            {
                icon: 'taxi',
                label: translate.instant('ICONS.TAXI'),
                type: null,
                types: ['solid'],
                category: 'transportation',
            },
            {
                icon: 'train',
                label: translate.instant('ICONS.TRAIN'),
                type: null,
                types: ['solid'],
                category: 'transportation',
            },
            // shapes icons
            {
                icon: 'equals',
                label: translate.instant('ICONS.EQUALS'),
                type: null,
                types: ['solid'],
                category: 'shapes',
            },
            {
                icon: 'heart',
                label: translate.instant('ICONS.HEART'),
                type: null,
                types: ['solid', 'outline'],
                category: 'shapes',
            },
            {
                icon: 'minus',
                label: translate.instant('ICONS.MINUS'),
                type: null,
                types: ['solid'],
                category: 'shapes',
            },
            {
                icon: 'plus',
                label: translate.instant('ICONS.PLUS'),
                type: null,
                types: ['solid'],
                category: 'shapes',
            },
            {
                icon: 'star',
                label: translate.instant('ICONS.STAR'),
                type: null,
                types: ['solid', 'outline'],
                category: 'shapes',
            },
            {
                icon: 'thumbs-up',
                label: translate.instant('ICONS.THUMBS_UP'),
                type: null,
                types: ['solid', 'outline'],
                category: 'shapes',
            },
            {
                icon: 'thumbs-down',
                label: translate.instant('ICONS.THUMBS_DOWN'),
                type: null,
                types: ['solid', 'outline'],
                category: 'shapes',
            },
            // people
            {
                icon: 'child',
                label: translate.instant('ICONS.CHILD'),
                type: null,
                types: ['solid'],
                category: 'people',
            },
            {
                icon: 'female',
                label: translate.instant('ICONS.FEMALE'),
                type: null,
                types: ['solid'],
                category: 'people',
            },
            {
                icon: 'male',
                label: translate.instant('ICONS.MALE'),
                type: null,
                types: ['solid'],
                category: 'people',
            },
            {
                icon: 'mars',
                label: translate.instant('ICONS.MARS'),
                type: null,
                types: ['solid'],
                category: 'people',
            },
            {
                icon: 'venus',
                label: translate.instant('ICONS.VENUS'),
                type: null,
                types: ['solid'],
                category: 'people',
            },
            // brand type: null, typess
            {
                icon: 'facebook',
                label: translate.instant('ICONS.FACEBOOK'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'instagram',
                label: translate.instant('ICONS.INSTAGRAM'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'twitter',
                label: translate.instant('ICONS.TWITTER'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'google-plus',
                label: translate.instant('ICONS.GOOGLE_PLUS'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'linkedin',
                label: translate.instant('ICONS.LINKEDIN'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'pinterest',
                label: translate.instant('ICONS.PINTEREST'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'reddit',
                label: translate.instant('ICONS.REDDIT'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'youtube',
                label: translate.instant('ICONS.YOUTUBE'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'skype',
                label: translate.instant('ICONS.SKYPE'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'slack',
                label: translate.instant('ICONS.SLACK'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'snapchat',
                label: translate.instant('ICONS.SNAPCHAT'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'tripadvisor',
                label: translate.instant('ICONS.TRIPADVISOR'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'whatsapp',
                label: translate.instant('ICONS.WHATSAPP'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            {
                icon: 'yelp',
                label: translate.instant('ICONS.YELP'),
                type: null,
                types: ['brand'],
                category: 'social',
            },
            // payment icons
            {
                icon: 'credit-card',
                label: translate.instant('ICONS.CREDIT_CARD'),
                type: null,
                types: ['solid', 'outline'],
                category: 'payment',
            },
            {
                icon: 'money-bill',
                label: translate.instant('ICONS.MONEY_BILL'),
                type: null,
                types: ['solid'],
                category: 'payment',
            },
            {
                icon: 'cc-amex',
                label: translate.instant('ICONS.AMERICAN_EXPRESS'),
                type: null,
                types: ['brand'],
                category: 'payment',
            },
            {
                icon: 'cc-apple-pay',
                label: translate.instant('ICONS.APPLE_PAY'),
                type: null,
                types: ['brand'],
                category: 'payment',
            },
            {
                icon: 'cc-mastercard',
                label: translate.instant('ICONS.MASTERCARD'),
                type: null,
                types: ['brand'],
                category: 'payment',
            },
            {
                icon: 'paypal',
                label: translate.instant('ICONS.PAYPAL'),
                type: null,
                types: ['brand'],
                category: 'payment',
            },
            {
                icon: 'cc-visa',
                label: translate.instant('ICONS.VISA'),
                type: null,
                types: ['brand'],
                category: 'payment',
            },
            // online search icons
            {
                icon: 'chrome',
                label: translate.instant('ICONS.CHROME'),
                type: null,
                types: ['brand'],
                category: 'search',
            },
            {
                icon: 'edge',
                label: translate.instant('ICONS.EDGE'),
                type: null,
                types: ['brand'],
                category: 'search',
            },
            {
                icon: 'firefox',
                label: translate.instant('ICONS.FIREFOX'),
                type: null,
                types: ['brand'],
                category: 'search',
            },
            {
                icon: 'google',
                label: translate.instant('ICONS.GOOGLE'),
                type: null,
                types: ['brand'],
                category: 'search',
            },
            {
                icon: 'internet-explorer',
                label: translate.instant('ICONS.INTERNET_EXPLORER'),
                type: null,
                types: ['brand'],
                category: 'search',
            },
            {
                icon: 'yahoo',
                label: translate.instant('ICONS.YAHOO'),
                type: null,
                types: ['brand'],
                category: 'search',
            },
            // operating system icons
            {
                icon: 'android',
                label: translate.instant('ICONS.ANDROID'),
                type: null,
                types: ['brand'],
                category: 'os',
            },
            {
                icon: 'apple',
                label: translate.instant('ICONS.APPLE'),
                type: null,
                types: ['brand'],
                category: 'os',
            },
            {
                icon: 'windows',
                label: translate.instant('ICONS.WINDOWS'),
                type: null,
                types: ['brand'],
                category: 'os',
            },
        ];
    }

    public static getIconClass(icon: string): string {
        return 'fa-' + icon;
    }
}
