import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Survey } from '../../../../../domain/surveys/survey/Survey';
import { SurveyIqlService } from '../../../../../services/SurveyIqlService';

@Component('its.survey.module.surveys', SurveySubmissionCountScoreComponent.CID, {
    template:
        require('/app/modules/surveys/summary/components/survey-submission-count-score/SurveySubmissionCountScoreComponent.html'),
    controllerAs: 'vm',
    bindings: {
        survey: '<',
    },
})
class SurveySubmissionCountScoreComponent {
    static IID: string = 'SurveySubmissionCountScoreComponent';
    static CID: string = 'itsSurveySubmissionCountScore';
    static $inject: Array<string> = ['itsSurveyIqlService', '$mdMedia'];

    public survey: Survey;
    public loadingScores: boolean = true;
    public metrics: { score: number; count: number } = null;
    public errorLoading: boolean = false;

    public constructor(private surveyIqlService: SurveyIqlService, public media: ng.material.IMedia) {}

    $onChanges(): void {
        if (this.survey) {
            this.loadSurveySubmissionCount(this.survey);
        }
    }

    private loadSurveySubmissionCount(survey: Survey): void {
        this.loadingScores = true;
        this.errorLoading = false;
        this.surveyIqlService
            .getSurveySubmissionCountAndAverageScoreByOriginalUuid(survey.originalUuid)
            .then((submissionCountsByOriginalUuid) => {
                this.metrics = submissionCountsByOriginalUuid[survey.originalUuid];
            })
            .catch(() => {
                this.errorLoading = true;
            })
            .finally(() => {
                this.loadingScores = false;
            });
    }
}
