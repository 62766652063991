import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';

import { LogicCreateModal } from './modals/LogicCreateModal';
import { IPageService } from '../../services/PageService';
import { ISurveyApi } from '../../api/SurveyApi';
import { ISurvey } from '../../domain/surveys/survey/Survey';
import { ILogic } from '../../domain/surveys/logic/Logic';
import { ISection } from '../../domain/surveys/Section';
import { IBaseItem } from '../../domain/surveys/items/BaseItem';
import { ISurveyService } from '../../services/SurveyService';
import { BaseSurvey } from './BaseSurvey';
import { ICustomField } from '../../domain/settings/CustomField';
import { LogicListBuilder } from '../../domain/surveys/logic/LogicListBuilder';
import { UpsellTool } from '@intouch/its.essential/app/essential/tools/UpsellTool';
import { ISiteNavigationService } from '@intouch/its.essential/app/essential/services/SiteNavigationService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';

import * as _ from 'lodash';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';
import { ILocationTag } from '../../domain/surveys/Location/LocationTag';
import { ILocationNode } from '../../domain/surveys/Location/LocationNode';

export interface ILogicListItem extends IEntity {
    uuid: string;
    label: string;
    action: string;
    condition: string;
    type: string;
    parentUuid: string;
    customMessage: string;
    equations: Array<{
        question: string;
        operator: string;
        operatorCode: string;
        item: IBaseItem;
        value: string;
    }>;
    hasError: boolean;
    endSurvey: boolean;
}

export class LogicListItem extends Entity implements ILogicListItem {
    uuid: string = null;
    label: string = null;
    action: string = null;
    condition: string = null;
    type: string = null;
    parentUuid: string = null;
    customMessage: string = null;
    equations: Array<{
        question: string;
        operator: string;
        operatorCode: string;
        value: string;
        item: IBaseItem;
    }> = [];
    hasError: boolean = false;
    endSurvey: boolean = true;
}

@Controller('its.survey.module.surveys', LogicController.IID, LogicController)
class LogicController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.LogicController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        'iteToaster',
        'itsPageService',
        'itsSurveyApi',
        'itsSurveyService',
        '$sce',
        '$translate',
        '$stateParams',
        '$scope',
        '$q',
        'iteAccessService',
        'iteSiteNavigationService',
        'iteUserLocalTimeService',
    ];

    public saving: boolean = false;
    public publishing: boolean = false;
    public logicListItems: Array<ILogicListItem> = [];
    public customFields: Array<ICustomField> = [];
    public locationTags: Array<ILocationTag> = [];
    public locationNodes: Array<ILocationNode> = [];
    public upsellTool: UpsellTool = null;

    constructor(
        protected stateService: ng.ui.IStateService,
        protected dialog: ng.material.IDialogService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected surveyService: ISurveyService,
        private sce: ng.ISCEService,
        private translate: ng.translate.ITranslateService,
        protected stateParams: ng.ui.IStateParamsService,
        protected scope: ng.IScope,
        private q: ng.IQService,
        private accessService: IAccessService,
        private siteNavService: ISiteNavigationService,
        protected userLocalTimeService: IUserLocalTimeService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );

        this.upsellTool = new UpsellTool(this.accessService, this.siteNavService).setStateByProductTiers('survey', [
            'grow',
            'scale',
            'lead',
        ]);

        if (!this.upsellTool.showUpsell()) {
            this.load().then(() => {
                this.logicLoad(this.stateService.params['uuid']);
            });
        }
    }

    public logicLoad(uuid: string): void {
        this.q
            .all([
                this.surveyApi.findAllCustomFields(),
                this.surveyApi.findSurveyByUuid(uuid),
                this.surveyApi.findAllLocationNodes(uuid),
            ])
            .then((responses: Array<PagedEntities | ISurvey | ILocationTag[]>) => {
                this.survey = <ISurvey>responses[1];
                this.customFields = (<PagedEntities>responses[0]).getEntities();
                this.locationNodes = <ILocationNode[]>responses[2];

                this.survey.buildLabelMap();
                this.buildLogicListItems();
                this.pageService.stopLoading();
            })
            .catch(() => {
                this.pageService.failLoading();
            })
            .finally(() => {
                this.pageService.stopLoading();
            });
    }

    public trustAsHtml(html: string): string {
        return this.sce.trustAsHtml(html);
    }

    /**
     * Builds the list of logic items to display
     */
    public buildLogicListItems(): void {
        this.survey.buildLabelMap();
        const itemsWithLogic: Array<any> = this.survey.getLogicItems();
        if (this.survey.logic.length) {
            for (const surveyLogic of this.survey.logic) {
                itemsWithLogic.push({
                    type: 'survey',
                    logic: surveyLogic,
                });
            }
        }
        this.logicListItems = new LogicListBuilder(
            itemsWithLogic,
            this.survey,
            this.customFields,
            this.locationNodes,
            this.translate
        ).getLogicListItems();
    }

    /**
     * Returns an array of base items filtered by their logic type
     *
     * @param {string} filter
     * @returns {Array<ILogicListItem>}
     */
    public getLogicListItems(filter?: string): Array<ILogicListItem> {
        if (filter) {
            return _.filter(this.logicListItems, (logicItem) => {
                return logicItem.type === filter;
            });
        } else {
            return this.logicListItems;
        }
    }

    /**
     * Finds are returns a logic object based on uuid
     *
     * @param {string} uuid
     * @returns {ILogic}
     */
    public getLogicByUuid(uuid: string): ILogic {
        for (const surveyLogic of this.survey.getLogic()) {
            if (surveyLogic.uuid === uuid) {
                return surveyLogic;
            }
        }

        for (const itemLogic of this.survey.getLogicItems()) {
            if (itemLogic.logic.uuid === uuid) {
                return itemLogic.logic;
            }
        }
    }

    /**
     * Returns if an operator will have a value
     * @param {string} operator
     * @returns {boolean}
     */
    public equationHasValue(operator: string): boolean {
        return ['eq', 'neq', 'gt', 'gte', 'lt', 'lte', 'contains', 'ncontains'].indexOf(operator) > -1;
    }

    /**
     * Opens the add logic modal and passes what type of logic
     * you're creating
     *
     * @param {string} type
     */
    public add(type: string): void {
        this.dialog
            .show(
                LogicCreateModal.instantiate({
                    locals: {
                        type: type,
                        survey: this.survey,
                    },
                })
            )
            .then((newSurvey) => {
                this.survey = newSurvey;
                this.buildLogicListItems();
                this.surveyService.reload(this.survey);
            });
    }

    /**
     * Edits the logic
     *
     * @param {ILogicListItem} logicItem
     */
    public edit(logicItem: ILogicListItem): void {
        const logic: ILogic = this.getLogicByUuid(logicItem.uuid);

        let parentItem: IBaseItem | ISection | ISurvey = <IBaseItem>this.survey.getItemByUuid(logicItem.parentUuid);
        if (!parentItem) {
            for (const section of this.survey.sections) {
                if (section.uuid === logicItem.parentUuid) {
                    parentItem = section;
                }
            }
        }
        this.dialog
            .show(
                LogicCreateModal.instantiate({
                    locals: {
                        type: logicItem.type,
                        survey: this.survey.clone(),
                        parentItemUuid: parentItem ? parentItem.uuid : null,
                        logic: logic.clone(),
                        editing: true,
                    },
                })
            )
            .then((newSurvey) => {
                this.survey = newSurvey;
                this.buildLogicListItems();
                this.surveyService.reload(this.survey);
            });
    }

    /**
     * Deletes the logic from the passed item's parent
     *
     * @param {ILogicListItem} item
     */
    public delete(item: ILogicListItem): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translate.instant('GENERAL.CONFIRM_DELETE'),
                        description: this.translate.instant('SURVEYS.LOGIC.DELETE_LOGIC_CONFIRM'),
                        confirmText: this.translate.instant('GENERAL.DELETE'),
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((result: boolean) => {
                if (result) {
                    this.survey.findAndSetLogic(item.parentUuid, null);
                    this.buildLogicListItems();
                    this.saveSurvey();
                }
            });
    }

    public saveSurvey(): void {
        this.save(this.survey).then(() => {
            this.buildLogicListItems();
        });
    }

    public publishSurvey(): void {
        this.publish(this.survey);
    }
}
