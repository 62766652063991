import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { EntityEncoder } from '@intouch/its.essential/app/essential/domain/EntityEncoder';
import { ContactList, IContactList } from './ContactList';
import * as _ from 'lodash';
import moment from 'moment';
import Moment = moment.Moment;
import { DefaultContactField, IDefaultContactField } from './DefaultContactField';

export interface IContactCustomField {
    [key: string]: string | number | Date | Moment;
}

export interface IContact extends IEntity {
    uuid: string;
    status: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    customFields: IContactCustomField;
    contactLists: Array<{ uuid: string; name: string }>;
    language: string;
    updatedAt: string;
    deliverable: boolean;
    contactListsString: string;
    phoneNumberFormatted: string;

    getConcatContactLists(): string;
    getFormattedPhoneNumber(): string;
    addContactList(contactList: IContactList): void;
    computedStatus(): string;
    getValueFromKey(contactKey: string): any;
}

export class Contact extends Entity implements IContact {
    uuid: string = null;
    status: string = null;
    firstName: string = null;
    lastName: string = null;
    email: string = null;
    phoneNumber: string = null;
    contactLists: Array<IContactList> = [];
    customFields: IContactCustomField = {};
    language: string = 'en_US';
    updatedAt: string = null;
    deliverable: boolean = null;

    public static getDefaultFields(translate: ng.translate.ITranslateService): Array<IDefaultContactField> {
        const defaultFields: Array<IDefaultContactField> = [];

        defaultFields.push(
            DefaultContactField.make({
                modelName: 'firstName',
                name: 'first_name',
                type: 'string',
                required: false,
                label: translate.instant('GENERAL.FIRST_NAME'),
            })
        );

        defaultFields.push(
            DefaultContactField.make({
                modelName: 'lastName',
                name: 'last_name',
                type: 'string',
                required: false,
                label: translate.instant('GENERAL.LAST_NAME'),
            })
        );

        defaultFields.push(
            DefaultContactField.make({
                modelName: 'phoneNumber',
                name: 'phone_number',
                type: 'string',
                required: false,
                label: translate.instant('GENERAL.PHONE_NUMBER'),
            })
        );

        defaultFields.push(
            DefaultContactField.make({
                modelName: 'email',
                name: 'email',
                type: 'string',
                required: true,
                label: translate.instant('GENERAL.EMAIL'),
            })
        );

        defaultFields.push(
            DefaultContactField.make({
                modelName: 'language',
                name: 'language',
                type: 'string',
                required: false,
                label: translate.instant('GENERAL.LANGUAGE'),
            })
        );

        return defaultFields;
    }

    public get contactListsString(): string {
        return this.getConcatContactLists();
    }

    public get phoneNumberFormatted(): string {
        return this.getFormattedPhoneNumber();
    }

    public getValueFromKey(contactKey: string): any {
        return _.get(this, contactKey);
    }

    public fromJson(json: any, convert: boolean): IContact {
        super.fromJson(json, convert);

        if (!_.isEmpty(json.custom_fields)) {
            this.customFields = json.custom_fields;
        }

        if (json.contact_lists) {
            this.contactLists = EntityBuilder.buildMany<IContactList>(ContactList, json.contact_lists, convert);
        }

        return this;
    }

    /**
     * Override toJson to handle a special case where we don't want to transform custom fields.
     * To achieve this, we create a clone, delete the property, convert via the Encoder,
     * restore the JSON into an object, add the custom fields and re-stringify the object.
     *
     * @param {boolean} convert
     * @returns {string}
     */
    public toJson(convert: boolean): string {
        const clone: IContact = <IContact>this.clone();
        delete clone.customFields;
        const json: string = EntityEncoder.jsonEncode(clone, convert),
            remakeJson: any = JSON.parse(json),
            customFields: any = <any>this.customFields;
        for (const value in customFields) {
            if (
                customFields.hasOwnProperty(value) &&
                (customFields[value] instanceof Date || customFields[value] instanceof moment)
            ) {
                customFields[value] = moment(customFields[value]).utc().format('YYYY-MM-DD');
            }
        }

        remakeJson['custom_fields'] = this.customFields;
        return JSON.stringify(remakeJson);
    }

    /**
     * Get contact's contact lists
     * @returns {string}
     */
    public getConcatContactLists(): string {
        if (this.contactLists.length) {
            return this.contactLists.map((contactList) => contactList.name).join(', ');
        } else {
            return null;
        }
    }

    /**
     * Get a formatted string version of the phone number
     *
     * @return {string}
     */
    public getFormattedPhoneNumber(): string {
        if (!this.phoneNumber || this.phoneNumber.length !== 10) {
            return this.phoneNumber || '';
        }

        return (
            '(' +
            this.phoneNumber.substr(0, 3) +
            ') ' +
            this.phoneNumber.substr(3, 3) +
            '-' +
            this.phoneNumber.substr(6, 4)
        );
    }

    public getFullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    /**
     * Adds a contact list to the contact
     * @param {IContactList} contactList
     */
    public addContactList(contactList: IContactList): void {
        this.contactLists.push(contactList);
    }

    public computedStatus(): string {
        if (!this.deliverable) {
            return 'undeliverable'; // yellow
        } else if (this.status === 'unsubscribed') {
            return 'unsubscribed'; // grey
        } else {
            return 'subscribed'; // blue
        }
    }
}
