import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IBaseItem } from '../../../domain/surveys/items/BaseItem';
import { ISurveyJS } from '../../../domain/surveys/survey/SurveyJS';
import { IBaseItemJS } from '../../../domain/surveys/items/BaseItemJS';
import { ISurvey } from '../../../domain/surveys/survey/Survey';
import * as angular from 'angular';
import CustomJSModalHtml from './CustomJSModal.html';

export class CustomJSModal extends Modal {
    static $inject: Array<string> = ['$mdDialog'];

    protected item: IBaseItem | ISurvey;
    protected backupJS: IBaseItemJS | ISurveyJS;

    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = CustomJSModalHtml;
        config.controller = CustomJSModal;

        return super.instantiate(config);
    }

    constructor(private dialog: ng.material.IDialogService) {
        super();
        this.backupJS = angular.copy(this.item.settings.customJavascript);
    }

    public submit(): void {
        this.dialog.hide();
    }

    public cancel(): void {
        this.item.settings.customJavascript = this.backupJS;
        this.dialog.cancel();
    }
}
