export interface ILogicContactEquation {
    question: string;
    operator: string;
    operatorCode: string;
    value: string;
    operandItem: void;
}

export class LogicContactEquation implements ILogicContactEquation {
    question: string = null;
    operator: string = null;
    operatorCode: string = null;
    value: string = null;
    operandItem: void = null;

    public static make(question: string, operator: string, operatorCode: string, value: string): ILogicContactEquation {
        const me: ILogicContactEquation = new LogicContactEquation();
        me.question = question;
        me.operator = operator;
        me.operatorCode = operatorCode;
        me.value = value;
        return me;
    }
}
