import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';

import { IEmailDistribution } from '../../../../domain/contact-center/EmailDistribution';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import EmailPreviewModalHtml from './EmailPreviewModal.html';

/**
 * A class to show the simple check edit/create fields
 */
export class EmailPreviewModal extends Modal {
    static $inject: Array<string> = [
        'iteLogger',
        '$translate',
        'itsSurveyApi',
        'iteToaster',
        '$mdDialog',
        'iteAccessService',
    ];

    protected emailDistribution: IEmailDistribution;
    protected surveyOriginalUuid: string;
    protected loading: boolean = true;
    protected sending: boolean = false;

    protected canSendPreview: boolean;
    protected fromAddress: string;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = EmailPreviewModalHtml;
        config.controller = EmailPreviewModal;

        return super.instantiate(config);
    }

    /**
     * @param {ILogger} logger
     * @param {ng.translate.ITranslateService} translate
     * @param {ISurveyApi} surveyApi
     * @param {IToaster} toaster
     * @param {ng.material.IDialogService} dialog
     * @param {IAccessService} accessService
     */
    public constructor(
        private logger: ILogger,
        private translate: ng.translate.ITranslateService,
        private surveyApi: ISurveyApi,
        private toaster: IToaster,
        private dialog: ng.material.IDialogService,
        private accessService: IAccessService
    ) {
        super();

        this.fromAddress = this.accessService.getToken().getUser().email;
        this.canSendPreview = this.accessService.getToken().getUser().isAdmin();

        if (
            this.emailDistribution &&
            (!this.emailDistribution.email || !this.emailDistribution.email.body) &&
            this.surveyOriginalUuid
        ) {
            this.surveyApi
                .findSurveyEmail(this.surveyOriginalUuid, this.emailDistribution.uuid)
                .then((emailDist) => {
                    this.emailDistribution = emailDist;
                })
                .catch(() => {
                    this.toaster.error('ERRORS.FAILED_EMAIL_LOAD');
                })
                .finally(() => {
                    this.loading = false;
                });
        } else {
            this.loading = false;
        }
    }

    /**
     * Sends a preview email.
     */
    public sendPreviewEmail(): void {
        this.sending = true;
        this.surveyApi
            .sendTestSurveyEmail(this.surveyOriginalUuid, this.emailDistribution)
            .then((response) => {
                this.dialog.hide();
                this.toaster.success(this.translate.instant('DISTRIBUTIONS.EMAIL.TEST_EMAIL_SENT_SUCCESSFULLY'));
            })
            .catch(() => {
                this.toaster.error(this.translate.instant('ERRORS.FAILED_TEST_EMAIL_SEND'));
            })
            .finally(() => {
                this.sending = false;
            });
    }

    /**
     * Cancels form creation and closes dialog
     */
    public close(): void {
        this.dialog.hide();
    }
}
