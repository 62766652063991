import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { TranslationLocator } from './TranslationLocator';
import { ITranslation, Translation } from './Translation';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';

/**
 *
 */
export interface ISurveyCollectionItem extends IEntity {
    uuid: string;
    originalUuid: string;
    name: string;
    status: string;
    pendingChanges: boolean;
    previewImage: string;
    active: boolean;
    createdAt: string;
    updatedAt: string;
    translations: Array<ITranslation>;
    recordCount: number;

    getTranslation(locale: string, label: string): string;
}

/**
 *
 */
export class SurveyCollectionItem extends Entity implements ISurveyCollectionItem {
    uuid: string = null;
    originalUuid: string = null;
    name: string = null;
    status: string = null;
    pendingChanges: boolean = null;
    previewImage: string = null;
    active: boolean = null;
    createdAt: string = null;
    updatedAt: string = null;
    translations: Array<ITranslation> = [];
    accessCreatedById: string = null;
    recordCount: number = null;

    /**
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {ISurveyCollectionItem}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ITranslation>(Translation, jsonObject.translations, true);
        }

        return this;
    }

    /**
     * Retrieve the given translation for a locale
     *
     * @param {string} locale
     * @returns {ITranslation}
     */
    public getTranslation(locale: string): string {
        const translation: ITranslation = TranslationLocator.locate(this.translations, 'name', locale, false);

        if (!translation || translation.name === '') {
            return this.name;
        }

        return translation.name;
    }
}
