// if this file does not exist, you must run `gulp build:config`
import config from '../../config';
import * as angular from 'angular';

const module: any = angular.module('its.survey.module.main', []);
module.constant('APPCONFIG', config);

import './_configuration';
import './LoginCallbackController';
import './MainController';
import './TrialExpiredController';

export default module;
