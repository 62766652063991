import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { ISurveyApi } from '../../../api/SurveyApi';
import { MailSender, IMailSender } from '../../../domain/settings/MailSender';
import { IGeneralSettings } from '../../../domain/settings/GeneralSettings';
import MailSendersCreateModalHtml from './MailSendersCreateModal.html';
import * as _ from 'lodash';

export class MailSendersCreateModal extends Modal {
    static $inject: Array<string> = ['itsSurveyApi', 'iteLogger', '$translate', '$mdDialog', 'iteToaster'];

    public mailSender: IMailSender;
    public mailSenders: Array<IMailSender>;
    public settings: IGeneralSettings;
    public mode: string;
    public editing: boolean = false;
    public submitting: boolean = false;
    public createAnother: boolean;

    public confirmSecret: string;
    public confirmPassword: string;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = MailSendersCreateModalHtml;
        config.controller = MailSendersCreateModal;

        return super.instantiate(config);
    }

    public constructor(
        private api: ISurveyApi,
        private logger: ILogger,
        private translate: ng.translate.ITranslateService,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster
    ) {
        super();

        this.settings = <IGeneralSettings>this.settings.clone();

        if (!this.mailSender) {
            this.mailSender = new MailSender();
            this.mailSender.type = 'mailgun'; // mailgun by default.
        } else {
            this.mailSender = <IMailSender>this.mailSender.clone();
            this.confirmSecret = this.mailSender.configuration.secret;
            this.confirmPassword = this.mailSender.configuration.password;
            this.editing = true;
        }
    }

    public toggleVisibility(field: string, event: Event): void {
        event.preventDefault();
        const el: HTMLInputElement = document.getElementById('its-mail-sender-' + field) as HTMLInputElement;

        if (el.type === 'password') {
            el.type = 'text';
        } else if (el.type === 'text') {
            el.type = 'password';
        }
    }

    public showForTypes(types: Array<string>): boolean {
        return types.indexOf(this.mailSender.type) > -1;
    }

    /**
     * @returns {boolean}
     */
    public shouldShowInfoMessage(): boolean {
        return ['mailgun', 'mandrill'].indexOf(this.mailSender.type) > -1;
    }

    /**
     * Submit form name to be created to the API
     */
    public submit(form: ng.IFormController): void {
        if (this.editing) {
            this.updateMailSender(form);
        } else {
            this.createMailSender(form);
        }
    }

    /**
     * Cancels form creation and closes dialog
     */
    public cancel(): void {
        this.dialog.cancel();
    }

    /**
     * Sets the validity of an element on the form
     *
     * @param {angular.INgModelController} ngModel
     * @param {string} errorType
     * @param {boolean} isValid
     */
    public setValidity(ngModel: ng.INgModelController, errorType: string, isValid: boolean): void {
        ngModel.$setValidity(errorType, isValid);
    }

    private createMailSender(form: ng.IFormController): void {
        this.submitting = true;
        this.settings.mailSenders.push(this.mailSender);

        this.api
            .saveSettings(this.settings)
            .then((newMailSender: IMailSender) => {
                form.$setPristine();
                form.$setUntouched();
                this.dialog.hide(true);
            })
            .catch((error: IErrorResponse) => {
                this.toaster.error(this.translate.instant('ERRORS.SETTINGS.FAILED_CUSTOM_MAIL_SENDER_CREATE'));
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    private updateMailSender(form: ng.IFormController): void {
        this.submitting = true;
        this.settings.mailSenders.splice(
            _.findIndex(this.settings.mailSenders, { uuid: this.mailSender.uuid }),
            1,
            this.mailSender
        );

        this.api
            .saveSettings(this.settings)
            .then((settings: IGeneralSettings) => {
                form.$setPristine();
                form.$setUntouched();
                this.dialog.hide(true);
            })
            .catch((error: IErrorResponse) => {
                this.toaster.error(this.translate.instant('ERRORS.SETTINGS.FAILED_CUSTOM_MAIL_SENDER_UPDATE'));
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    /**
     * Set password or confirm password input to be required.
     * @returns {boolean}
     */
    public requirePassword(): boolean {
        if (this.mailSender.configuration.password) {
            return true;
        }

        if (this.mode === 'create') {
            return true;
        }
        return false;
    }
}
