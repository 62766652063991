import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import {
    AddContactToContactListUpload,
    IAddContactToContactListUpload,
} from '../../../../domain/contact-center/AddContactToContactListUpload';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { INavigationService } from '../../../../services/NavigationService';

/**
 * Add Contact Center Listing Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.contactCenter', AddContactsController.IID, AddContactsController)
class AddContactsController {
    static IID: string = 'its.survey.module.contactCenter.AddContactsController';
    static $inject: Array<string> = ['$stateParams', 'itsSurveyApi', 'itsNavigationService'];

    public csvUrl: string;
    public upload: IAddContactToContactListUpload = new AddContactToContactListUpload();
    public endpointUrl: string = null;

    constructor(
        private stateParams: ng.ui.IStateParamsService,
        private surveyApi: ISurveyApi,
        private navigationService: INavigationService
    ) {
        this.navigationService.setBackRoute('home.contact-center');

        this.setEndpoint();
        this.setCsvUrl();
    }

    protected setEndpoint(): void {
        this.endpointUrl = 'contacts/contact_lists/' + this.stateParams['contactListUuid'] + '/upload';
    }

    protected setCsvUrl(): void {
        this.csvUrl = this.surveyApi.buildAddContactsToContactListCsvUrl();
    }
}
