import { ProgressStatus } from '@intouch/its.essential/app/essential/domain/ProgressStatus';
import { IHealthCalculator } from './IHealthCalcuator';
import { HealthStatus } from './HealthStatus';

export class CompletionRate implements IHealthCalculator {
    // the percentage score used to fill the progress circle associated to this calculation: 1-100
    public percentage: number = null;
    // (data varies per implementation) the estimated percentage chance of completion: 1-100
    public data: number;
    // the string of the estimated percentage chance of completion: 80%
    public scoreText: string = null;
    // score used to calculate overall survey health, 0,1,2
    public score: HealthStatus = null;

    constructor(estimatedCompletionTimeInSeconds: number) {
        this.calculate(estimatedCompletionTimeInSeconds / 60);
    }

    calculate(minutes: number): void {
        this.data = Math.round(100 - (0.892 * minutes + 19.066));
        this.percentage = this.data;
        this.score = this.calculateScore(this.data);
        this.scoreText = this.percentage + '%';
    }

    getStatus(): ProgressStatus {
        if (this.score === HealthStatus.Healthy) {
            return ProgressStatus.Excellent;
        } else if (this.score === HealthStatus.Average) {
            return ProgressStatus.Ok;
        } else {
            return ProgressStatus.Bad;
        }
    }

    private calculateScore(percentage: number): number {
        if (percentage >= 67) {
            return HealthStatus.Healthy;
        } else if (percentage >= 34) {
            return HealthStatus.Average;
        } else {
            return HealthStatus.Unhealthy;
        }
    }
}
