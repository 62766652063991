import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { BaseItem, IBaseItem } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { BaseResponse, IBaseResponse } from './BaseResponse';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import { ILogicReference, LogicOwner, LogicReference } from '../logic/LogicReference';
import { String } from '@intouch/its.essential/app/essential/utils/String';
import { ImageResponse } from './ImageResponse';
import { IconResponse } from './IconResponse';

export type BaseResponseType = 'labels' | 'images' | 'labels_images' | 'icons' | 'labels_icons';

/**
 * The drop down settings interface
 */
export interface IBaseResponseItemSettings extends IBaseSettings {
    randomizeOptions: boolean;
    showOther: boolean;
    showOtherUuid: string;
}

/**
 * The drop down settings class
 */
export class BaseResponseItemSettings extends BaseSettings implements IBaseResponseItemSettings {
    randomizeOptions: boolean = false;
    showOther: boolean = false;
    showOtherUuid: string = null;
}

/**
 * A drop down form item interface
 */
export interface IBaseResponseItem extends IBaseItem {
    settings: IBaseResponseItemSettings;
    responses: Array<IBaseResponse>;
    points: number;

    addResponses(count?: number, labels?: Array<string>, isOther?: boolean): void;
    addResponse(index?: number, responseType?: BaseResponseType): IBaseResponse;
    addOtherResponse(): IBaseResponse;
    removeResponse(response: IBaseResponse): void;
    getResponses(): Array<IBaseResponse>;
    getDefaultSelected(): IBaseResponse;
    setDefaultSelected(response: IBaseResponse): void;
    buildLabelMap(): any;
    getLogicItems(): Array<any>;
    getResponseByUuid(uuid: string): IBaseResponse;
    setResponses(responses: Array<IBaseResponse>): void;
}

/**
 * A drop down form item class
 */
export class BaseResponseItem extends BaseItem implements IBaseResponseItem {
    settings: IBaseResponseItemSettings = new BaseResponseItemSettings();
    responses: Array<IBaseResponse> = [];
    points: number;
    @NotEnumerable
    $$mdSelectId: number = null;

    // value is strictly used to pre-populate rendered default selections
    @NotEnumerable
    value: any = null;

    private _points: number = 0;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey.
     */
    get healthPoints(): number {
        return Math.round(this.responses.length / 2);
    }

    constructor() {
        super();
        Object.defineProperties(this, {
            _points: { writable: true, enumerable: false },
            points: {
                get: () => {
                    this._points = 0;
                    for (const response of this.responses) {
                        if (response.points !== null && NumberUtils.isNumber(response.points)) {
                            if (response.points > this._points || this._points === null) {
                                this._points = response.points;
                            }
                        }
                    }

                    return this._points;
                },
                set: (value) => {
                    this._points = value;
                },
                enumerable: true,
                configurable: true,
            },
        });
    }

    /**
     * Returns if the uuid is used in defined logic
     *
     * @param itemUuid
     */
    public getAllLogicItemBelongsTo(itemUuid: string): Array<ILogicReference> {
        const allLogic: Array<ILogicReference> = [];
        if (this.logic) {
            for (const logicItem of this.logic.items) {
                if (logicItem.operand === itemUuid) {
                    allLogic.push(new LogicReference(new LogicOwner(this.uuid, this.label), this.logic));
                }
                for (const logicCondition of logicItem.conditions) {
                    if (logicCondition.value === itemUuid) {
                        allLogic.push(new LogicReference(new LogicOwner(this.uuid, this.label), this.logic));
                    }
                }
            }
        }

        for (const response of this.responses) {
            if (response.logic) {
                for (const logicItem of response.logic.items) {
                    if (logicItem.operand === itemUuid) {
                        allLogic.push(
                            new LogicReference(
                                new LogicOwner(response.uuid, this.label + ': ' + response.label),
                                response.logic
                            )
                        );
                    }
                    for (const logicCondition of logicItem.conditions) {
                        if (logicCondition.value === itemUuid) {
                            allLogic.push(
                                new LogicReference(
                                    new LogicOwner(response.uuid, this.label + ': ' + response.label),
                                    response.logic
                                )
                            );
                        }
                    }
                }
            }
        }

        return allLogic;
    }

    /**
     * Adds responses to the item.
     * Defaults to adding one empty response at the end of the array of responses.
     *
     * @param {number} count
     * @param {Array<string>} labels
     * @param {boolean} isOther
     */
    public addResponses(count: number = 1, labels: Array<string> = [], isOther: boolean = false): void {
        for (let i: number = 0; i < count; i++) {
            const label: string = labels.length > i ? labels[i] : '';
            this.responses.push(BaseResponse.make(label, isOther));
        }
    }

    public addResponse(index: number = null, responseType: BaseResponseType = null): IBaseResponse {
        if (index === null) {
            index = this.responses.length;
        }

        const newResponse: IBaseResponse = this.buildResponseFromType(responseType);
        this.responses.splice(index, 0, newResponse);

        return newResponse;
    }

    public addOtherResponse(): IBaseResponse {
        const otherResponse: IBaseResponse = BaseResponse.make('Other', true);
        this.responses.push(otherResponse);
        this.settings.showOtherUuid = otherResponse.uuid;
        return otherResponse;
    }

    /**
     * Removes passed response
     * @param response
     */
    public removeResponse(response: IBaseResponse): void {
        const index: number = this.responses.indexOf(response);
        if (index > -1) {
            if (response.defaultSelected) {
                response.defaultSelected = false;
                this.value = null;
            }
            this.responses.splice(index, 1);
        }
    }

    /**
     * Get a reponse by UUID
     *
     * @param {string} uuid
     * @returns {IBaseResponse}
     */
    public getResponseByUuid(uuid: string): IBaseResponse {
        if (uuid && this.responses.length > 0) {
            for (const response of this.responses) {
                if (response.uuid === uuid) {
                    return response;
                }
            }
        }

        return null;
    }

    /**
     * Sets responses
     *
     * @param {Array<IBaseResponse>} responses
     */
    public setResponses(responses: Array<IBaseResponse>): void {
        this.responses = responses;
    }

    /**
     * Creates a string map of uuid and label
     *
     * @returns {any}
     */
    public buildLabelMap(): any {
        const mapObj: any = super.buildLabelMap();
        for (const response of this.responses) {
            mapObj[response.uuid] = response.label;
        }
        return mapObj;
    }

    /**
     * Get logic including response logic items
     *
     * @returns {Array<any>}
     */
    public getLogicItems(): Array<any> {
        const logicArr: Array<any> = [];
        if (this.logic) {
            logicArr.push(this);
        }

        if (this.responses.length > 0) {
            for (const response of this.responses) {
                if (response.logic) {
                    logicArr.push(response);
                }
            }
        }

        return logicArr;
    }

    /**
     * Gets all responses
     *
     * @returns {Array<IBaseResponse>}
     */
    public getResponses(): Array<IBaseResponse> {
        return this.responses;
    }

    /**
     * Gets the default selected response
     *
     * @returns {IBaseResponse}
     */
    public getDefaultSelected(): IBaseResponse {
        for (const response of this.getResponses()) {
            if (response.defaultSelected) {
                return response;
            }
        }
        return null;
    }

    /**
     * Sets the items default selected response to the passed response
     *
     * @param {IBaseResponse} response
     */
    public setDefaultSelected(response: IBaseResponse): void {
        for (const tempResponse of this.getResponses()) {
            tempResponse.defaultSelected = tempResponse.uuid === response.uuid;
        }
    }

    /**
     * Default initialize to 3 blank responses
     */
    public initialize(): void {
        this.responses = [];
        this.addResponses(3);
    }

    /**
     * Duplicate current object and set a new UUID
     */
    public duplicate(): BaseResponseItem {
        const dupe: BaseItem = super.duplicate();

        for (const response of (<BaseResponseItem>dupe).responses) {
            response.uuid = String.uuid();
            response.logic = null;
            response.logicReferences = [];
        }

        return dupe as BaseResponseItem;
    }

    /**
     * Build the dropdown object from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IBaseResponseItem}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<IBaseResponseItemSettings>(
                BaseResponseItemSettings,
                jsonObject.settings,
                true
            );
        }

        if (jsonObject.responses) {
            this.responses = EntityBuilder.buildMany<IBaseResponse>(BaseResponse, jsonObject.responses, true);
            // only used to display pre-selected radio in the live preview
            const defaultResponse: IBaseResponse = this.getDefaultSelected();
            if (defaultResponse) {
                this.value = defaultResponse;
            }
        }

        return this;
    }

    protected buildResponseFromType(responseType: string, isOther: boolean = false): IBaseResponse {
        switch (responseType) {
            case 'images':
            case 'labels_images':
                return ImageResponse.make('', isOther);
            case 'icons':
            case 'labels_icons':
                return IconResponse.make('', isOther);
            default:
                return BaseResponse.make('', isOther);
        }
    }
}
