import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IScrollService } from '@intouch/its.essential/app/essential/services/ScrollService';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { BaseItem, IBaseItem } from '../../../../../../domain/surveys/items/BaseItem';
import { ISection } from '../../../../../../domain/surveys/Section';
import { ItemMoveModal } from '../../../../modals/ItemMoveModal';
import { ISurvey, Survey } from '../../../../../../domain/surveys/survey/Survey';
import { SurveyService } from '../../../../../../services/SurveyService';
import { IContainItems } from '../../../../../../domain/surveys/items/ContainItems';
import * as _ from 'lodash';

@Component('its.survey.module.surveys', SurveyItemExtraActions.CID, {
    template: require('/app/modules/surveys/components/items/shared/survey-item-extra-actions/SurveyItemExtraActions.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
        parent: '<',
        ngDisabled: '<',
    },
})
class SurveyItemExtraActions {
    static IID: string = 'SurveyItemExtraActions';
    static CID: string = 'itsSurveyItemExtraActions';
    static $inject: string[] = ['$mdDialog', '$scope', 'itsSurveyService', 'iteScrollService'];

    public item: BaseItem;
    public parent: IContainItems;
    public ngDisabled: boolean;
    private survey: Survey = null;

    public constructor(
        private dialog: ng.material.IDialogService,
        private scope: ng.IScope,
        private surveyService: SurveyService,
        private scrollService: IScrollService
    ) {
        const surveySubscription: SubjectSubscription = this.surveyService.surveyValue.subscribe((survey) => {
            this.survey = survey;
        });
        surveySubscription.unsubscribeOnScopeDestruction(this.scope);
    }

    public openMoveModal(item: IBaseItem, itemParent: IContainItems): void {
        const itemMoveModalConfig: ng.material.IDialogOptions = {
            locals: {
                parent: itemParent,
                item: item,
                survey: this.survey,
            },
        };
        this.dialog
            .show(ItemMoveModal.instantiate(itemMoveModalConfig))
            .then((result: { survey: Survey; movedItem: IBaseItem }) => {
                this.scrollService.scrollTo('itsSectionItem' + result.movedItem.uuid);
            });
    }

    public copyQuestion(item: IBaseItem, parent: IContainItems): void {
        const index: number = _.findIndex(parent.items, { uuid: item.uuid });
        if (index > -1) {
            const dupeItem: IBaseItem = item.duplicate();
            this.surveyService.saveSnapshot();
            parent.addItem(dupeItem, index + 1);
            this.surveyService.setEditingItem(parent.items[index + 1]);
        }
    }

    public deleteQuestion(item: BaseItem): void {
        this.surveyService.showItemDeleteConfirmation(item);
    }

    public disableQuestion(item: ISection | IBaseItem): void {
        item.disabled = true;
        const survey: ISurvey = this.surveyService.getSurvey();
        this.surveyService.saveSurveyAndNotify(survey);
    }

    public enableQuestion(item: ISection | IBaseItem): void {
        item.disabled = false;
        const survey: ISurvey = this.surveyService.getSurvey();
        this.surveyService.saveSurveyAndNotify(survey);
    }
}
