import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Checkbox, ICheckboxSettings } from '../../../../../../domain/surveys/items/Checkbox';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';

@Component('its.survey.module.surveys', CheckboxSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/response-items/checkbox/CheckboxSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: CheckboxSettingsComponent.getBindings(),
})
class CheckboxSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'CheckboxSettingsComponent';
    static CID: string = 'itsCheckboxSettings';
    static $inject: string[] = ['$scope'];

    public item: Checkbox;

    public showSelectionRequirements: boolean = false;

    public constructor(private scope: ng.IScope) {
        super();
    }

    $onInit(): void {
        if (this.item.type !== 'checkbox') {
            return;
        }
        this.showSelectionRequirements = this.item.settings.hasSelectionRequirements();

        // set up watch to invalidate selection requirements input value
        this.scope.$watch(
            () => {
                return this.item.responses.length;
            },
            () => {
                // length of responses changed so we fire this function to apply view changes to control based on validation
                this.setSelectionRequirementValueValidation();
            }
        );
    }

    public toggleSelectionRequirements(): void {
        if (this.item.type !== 'checkbox') {
            return;
        }

        const settings: ICheckboxSettings = this.item.settings;
        settings.hasSelectionRequirements()
            ? settings.disableSelectionRequirements()
            : settings.enableSelectionRequirements();
    }

    private setSelectionRequirementValueValidation(): void {
        if (
            this.item.settings.hasSelectionRequirements() &&
            this.scope['checkboxSettingsForm'] &&
            this.scope['checkboxSettingsForm']['itsSelectionRequirementsValue']
        ) {
            this.scope['checkboxSettingsForm']['itsSelectionRequirementsValue'].$setDirty();
            this.scope['checkboxSettingsForm']['itsSelectionRequirementsValue'].$setTouched();
        }
    }
}
