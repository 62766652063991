import { Section } from '../../Section';
import { Survey } from '../Survey';
import * as _ from 'lodash';

export class SurveySectionMover {
    public static moveSection(
        survey: Survey,
        sourceSection: Section,
        targetSection: Section,
        afterOrBeforeTarget: 'after' | 'before' = 'before'
    ): void {
        const sourceSectionIndex: number = this.getSectionIndex(survey, sourceSection);
        let targetSectionIndex: number = this.getSectionIndex(survey, targetSection);

        targetSectionIndex = this.applyAfterOrBeforeOffset(targetSectionIndex, afterOrBeforeTarget);
        targetSectionIndex = this.applyInternalIndexChangeOffset(sourceSectionIndex, targetSectionIndex);
        const section: Section = survey.sections[sourceSectionIndex];

        if (!this.isValidIndex(survey, targetSectionIndex)) {
            throw new Error('Trying to move section to a target index beyond current stack');
        }

        if (!this.isValidIndex(survey, sourceSectionIndex)) {
            throw new Error('Trying to move a source section which is out of bounds');
        }

        survey.getSections().splice(sourceSectionIndex, 1);
        survey.getSections().splice(targetSectionIndex, 0, section);
    }

    private static applyInternalIndexChangeOffset(sourceSectionIndex: number, targetSectionIndex: number): number {
        if (targetSectionIndex > sourceSectionIndex) {
            targetSectionIndex -= 1;
        }
        return targetSectionIndex;
    }

    private static applyAfterOrBeforeOffset(targetIndex: number, afterOrBefore: 'after' | 'before'): number {
        if (afterOrBefore === 'after') {
            return targetIndex + 1;
        }
        return targetIndex;
    }

    private static isValidIndex(survey: Survey, indexToVerify: number): boolean {
        return indexToVerify >= 0 && indexToVerify <= survey.sections.length;
    }

    private static getSectionIndex(survey: Survey, section: Section): number {
        return _.findIndex(survey.sections, { uuid: section.uuid });
    }
}
