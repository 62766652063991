import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import DeleteItemModalHtml from './DeleteItemModal.html';
import { BuilderService } from '../../../services/BuilderService';
/**
 * Confirmation dialog modal class
 *
 */
export class DeleteItemModal extends Modal {
    static $inject: Array<string> = ['$mdDialog', 'itsBuilderService'];

    public surveyUuid: string;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = DeleteItemModalHtml;
        config.controller = DeleteItemModal;
        config.disableParentScroll = true;
        config.hasBackdrop = true;
        config.clickOutsideToClose = false;
        config.escapeToClose = false;

        return super.instantiate(config);
    }

    /**
     * Create the class
     *
     * @param dialog
     */
    constructor(private dialog: ng.material.IDialogService, private builderService: BuilderService) {
        super();
    }

    /**
     * Will confirm the action that we wanted to do
     */
    public confirm(): void {
        this.dialog.hide(true);
    }

    /**
     * Cancel the dialog and indicate we don't want to do this
     */
    public cancel(): void {
        this.builderService.setTab('logic');
        this.dialog.hide(false);
    }
}
