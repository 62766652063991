import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { IAbstractTheme } from '../domain/surveys/themes/AbstractTheme';
import { DefaultTheme } from '../domain/surveys/themes/DefaultTheme';
import { DarkTheme } from '../domain/surveys/themes/DarkTheme';
import { SoftAquaTheme } from '../domain/surveys/themes/soft/SoftAquaTheme';
import { SoftCrimsonTheme } from '../domain/surveys/themes/soft/SoftCrimsonTheme';
import { SoftVioletTheme } from '../domain/surveys/themes/soft/SoftVioletTheme';
import { SoftOceanTheme } from '../domain/surveys/themes/soft/SoftOceanTheme';
import { DarkPalmTheme } from '../domain/surveys/themes/image/DarkPalmTheme';
import { FineDiningTheme } from '../domain/surveys/themes/image/FineDiningTheme';
import { PinkDawnTheme } from '../domain/surveys/themes/image/PinkDawnTheme';
import { HomeOfficeTheme } from '../domain/surveys/themes/image/HomeOfficeTheme';

export interface IThemeService {
    getAvailableThemes(): Array<IAbstractTheme>;
    buildThemeBySlug(theme: string): IAbstractTheme;
}

/**
 * Theme types
 */
export type ThemeType =
    | 'default'
    | 'dark-default'
    | 'soft-aqua'
    | 'soft-crimson'
    | 'soft-ocean'
    | 'soft-violet'
    | 'dark-palm'
    | 'fine-dining'
    | 'pink-dawn'
    | 'home-office';

@Service('its.survey.services', ThemeService.IID, ThemeService)
export class ThemeService implements IThemeService {
    static IID: string = 'itsThemeService';
    static $inject: Array<string> = [];

    private themes: Array<IAbstractTheme> = [
        new DefaultTheme(),
        new DarkTheme(),
        new SoftAquaTheme(),
        new SoftVioletTheme(),
        new SoftOceanTheme(),
        new SoftCrimsonTheme(),
        new HomeOfficeTheme(),
        new DarkPalmTheme(),
        new FineDiningTheme(),
        new PinkDawnTheme(),
    ];

    /**
     * Returns a theme that matches the slug
     *
     * @param slug
     */
    public buildThemeBySlug(slug: ThemeType): IAbstractTheme {
        switch (slug) {
            case 'default':
                return new DefaultTheme();
            case 'dark-default':
                return new DarkTheme();
            case 'soft-aqua':
                return new SoftAquaTheme();
            case 'soft-violet':
                return new SoftVioletTheme();
            case 'soft-ocean':
                return new SoftOceanTheme();
            case 'soft-crimson':
                return new SoftCrimsonTheme();
            case 'dark-palm':
                return new DarkPalmTheme();
            case 'fine-dining':
                return new FineDiningTheme();
            case 'pink-dawn':
                return new PinkDawnTheme();
            case 'home-office':
                return new HomeOfficeTheme();
            default:
                return new DefaultTheme();
        }
    }

    /**
     * Returns an array of available themes
     */
    public getAvailableThemes(): Array<IAbstractTheme> {
        return this.themes;
    }
}
