import { ProgressStatus } from '@intouch/its.essential/app/essential/domain/ProgressStatus';
import { HealthStatus } from './HealthStatus';
import { IHealthCalculator } from './IHealthCalcuator';
import { ISurvey } from '../Survey';

export class CompletionTime implements IHealthCalculator {
    public score: HealthStatus = null;
    public scoreText: string = null;
    public percentage: number = null;
    public data: number = null;

    constructor(survey: ISurvey) {
        this.calculate(survey);

        const minutes: number = Math.floor(this.data / 60),
            seconds: number = Math.round(this.data % 60);

        // padStart has a polyfill in index.html -- not supported in IE and typings doesn't recognize it so wrapped in <any>
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
        this.scoreText = (minutes ? minutes.toString() + 'm ' : '') + (<any>seconds.toString()).padStart(2, '0') + 's';
    }

    calculate(survey: ISurvey): void {
        let totalHealthPoints: number = 0;
        for (const section of survey.sections) {
            totalHealthPoints += section.getHealthPoints();
        }

        const totalMinutes: number = totalHealthPoints / 8;
        this.data = totalMinutes * 60;

        if (this.data > 1200 || !this.data) {
            this.percentage = 0;
            this.score = HealthStatus.Unhealthy;
        } else if (this.data >= 900) {
            this.percentage = 33;
            this.score = HealthStatus.Unhealthy;
        } else if (this.data >= 600) {
            this.percentage = 66;
            this.score = HealthStatus.Average;
        } else {
            this.percentage = 100;
            this.score = HealthStatus.Healthy;
        }
    }

    getStatus(): ProgressStatus {
        switch (this.score) {
            case HealthStatus.Healthy:
                return ProgressStatus.Excellent;
            case HealthStatus.Average:
                return ProgressStatus.Ok;
            case HealthStatus.Unhealthy:
                return ProgressStatus.Bad;
            default:
                return null;
        }
    }
}
