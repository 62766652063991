import * as _ from 'lodash';
import { IHighchartsSeries } from '@intouch/iql-ts-sdk/src/domain/data-structures/HighchartsSeries';
declare let Highcharts: any;

export class SurveyChartBuilder {
    private chartOptions: any = {};

    private series: any[] = [];

    public constructor() {
        this.resetOptions();
    }

    public setRenderTo(targetHtmlId: string): SurveyChartBuilder {
        this.chartOptions.chart.renderTo = targetHtmlId;
        return this;
    }

    public setChartType(chartType: string): SurveyChartBuilder {
        this.chartOptions.chart.type = chartType;
        return this;
    }

    public setChartDimensions(width: number = null, height: number = null): SurveyChartBuilder {
        this.chartOptions.chart.width = width;
        this.chartOptions.chart.height = height;
        return this;
    }

    public setColors(colors: string[]): SurveyChartBuilder {
        this.chartOptions.colors = colors;
        return this;
    }

    public setPlotOptions(plotOptions: any): SurveyChartBuilder {
        this.chartOptions.plotOptions = plotOptions;
        return this;
    }

    public setSeries(series: IHighchartsSeries[]): SurveyChartBuilder {
        this.series = series;
        return this;
    }

    public setTitle(title: string): SurveyChartBuilder {
        this.chartOptions.title.text = title;
        return this;
    }

    public setSubtitle(subtitle: string): SurveyChartBuilder {
        this.chartOptions.subtitle.text = subtitle;
        return this;
    }

    public setLegendLayout(layoutStyle: 'vertical' | 'horizontal'): SurveyChartBuilder {
        this.chartOptions.legend.layout = layoutStyle;
        return this;
    }

    public setLegendAlign(align: 'right' | 'left' | 'top' | 'bottom'): SurveyChartBuilder {
        this.chartOptions.legend.align = align;
        return this;
    }

    public setLegendVerticalAlign(verticalAlign: 'top' | 'middle' | 'bottom'): SurveyChartBuilder {
        this.chartOptions.legend.verticalAlign = verticalAlign;
        return this;
    }

    public setLegendFormatter(formatter: () => void): SurveyChartBuilder {
        this.chartOptions.legend.labelFormatter = formatter;
        return this;
    }

    public removeLegend(): SurveyChartBuilder {
        this.chartOptions.legend.enabled = false;
        return this;
    }

    public setYAxisTitle(title: string): SurveyChartBuilder {
        this.chartOptions.yAxis.title = title;
        return this;
    }

    public setXAxisType(type: string): SurveyChartBuilder {
        this.chartOptions.xAxis.type = type;
        return this;
    }

    public setXAxisMinMax(min?: number, max?: number): SurveyChartBuilder {
        this.chartOptions.xAxis.min = min;
        this.chartOptions.xAxis.max = max;
        return this;
    }

    public hideAxes(): SurveyChartBuilder {
        this.chartOptions.chart.showAxes = false;
        this.chartOptions.yAxis.gridLineWidth = 0;
        this.chartOptions.yAxis.title.text = null;
        this.chartOptions.xAxis.lineWidth = 0;
        this.chartOptions.xAxis.tickWidth = 0;
        this.chartOptions.xAxis.title.text = null;
        this.chartOptions.xAxis.labels.enabled = false;
        return this;
    }

    public renderChart(): any {
        const options: any = _.cloneDeep(this.chartOptions);
        const highchart: any = Highcharts.chart(options);
        if (this.series.length > 0) {
            const seriesArr: any[] = _.cloneDeep(this.series);
            for (const series of seriesArr) {
                highchart.addSeries(series);
            }
        }
        this.resetOptions();
        return highchart;
    }

    private resetOptions(): void {
        this.series = [];
        this.chartOptions = {
            chart: {
                alignTicks: false,
                className: 'its-rounded',
            },
            time: {},
            title: {
                style: {
                    fontFamily: 'Roboto,sans-serif',
                    fontSize: '20px',
                },
            },
            subtitle: {},
            xAxis: {
                title: {},
                labels: {},
            },
            yAxis: {
                title: {},
            },
            legend: {
                symbolRadius: 6,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
            colors: [
                '#00a3e0',
                '#0B8043',
                '#AD1457',
                '#D81B60',
                '#EF6C00',
                '#F6BF26',
                '#33B679',
                '#3F51B5',
                '#9E69AF',
                '#B39DDB',
            ],
        };
    }
}
