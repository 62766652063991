import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { SmartPrompt } from '../../../../../../domain/surveys/items/shared/SmartPrompt';
import { BaseItem } from '../../../../../../domain/surveys/items/BaseItem';

@Component('its.survey.module.surveys', SmartPromptSettingComponent.CID, {
    template: require('/app/modules/surveys/components/items/shared/smart-prompt-setting/SmartPromptSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class SmartPromptSettingComponent {
    static IID: string = 'SmartPromptSettingComponent';
    static CID: string = 'itsSmartPromptSetting';
    static $inject: string[] = [];

    public item: any;
    public showSmartPromptInput: boolean = false;

    $onInit(): void {
        if (this.item.settings.smartPrompt) {
            this.showSmartPromptInput = this.item.settings.smartPrompt.length > 0;
        }
    }

    public onSmartPromptCheckboxChange(): void {
        if (this.showSmartPromptInput && !this.item.settings.smartPrompt.length) {
            this.item.settings.smartPrompt.push(new SmartPrompt());
        } else {
            this.item.settings.smartPrompt = [];
        }
    }

    public addSmartPrompt(): void {
        this.item.settings.smartPrompt.push(new SmartPrompt());
    }

    public deleteSmartPrompt(index: number): void {
        this.item.settings.smartPrompt.splice(index, 1);

        if (this.item.settings.smartPrompt.length === 0) {
            this.showSmartPromptInput = false;
        }
    }
}
