export interface ILocationTagEquation {
    question: string;
    operator: string;
    value: string;
    operatorCode: string;
}

export class LocationTagEquation implements ILocationTagEquation {
    question: string = null;
    operator: string = null;
    value: string = null;
    operatorCode: string;

    public static make(question: string, operator: string, value: string, operatorCode: string): ILocationTagEquation {
        const locationTagEquation: ILocationTagEquation = new LocationTagEquation();
        locationTagEquation.question = question;
        locationTagEquation.operator = operator;
        locationTagEquation.value = value;
        locationTagEquation.operatorCode = operatorCode;
        return locationTagEquation;
    }
}
