import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { BaseItem, IBaseItem } from './BaseItem';
import { IHelpTextSettings, IHelpTextTranslation } from './shared/HelpText';
import { ITranslatedEntity } from '../../ITranslatedEntity';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { IIconDetails } from './shared/IconDetails';

export interface INpsTranslation extends IHelpTextTranslation {
    maxLabel: string;
    minLabel: string;
}

export class NpsTranslation extends Translation implements INpsTranslation {
    maxLabel: string = null;
    minLabel: string = null;
    additionalText: string = null;
}

export interface INpsSettings extends IBaseSettings, IHelpTextSettings<INpsTranslation> {
    minLabel: string;
    maxLabel: string;
    iconGroup: string;
    showRangeLabels: boolean;
}

export class NpsSettings extends BaseSettings implements INpsSettings, ITranslatedEntity {
    minLabel: string = 'Not Likely';
    maxLabel: string = 'Extremely Likely';
    iconGroup: string = null;
    showRangeLabels: boolean = true;
    additionalText: string = null;
    translations: Array<INpsTranslation> = [];

    @NotEnumerable
    public availableIcons: IIconDetails[] = [
        {
            icon: 'star',
            value: 'stars',
            label: 'GENERAL.STARS',
        },
        {
            icon: 'thumb_up',
            value: 'thumbs',
            label: 'GENERAL.THUMBS',
        },
        {
            icon: 'faces',
            value: 'faces',
            label: 'GENERAL.FACES',
        },
    ];

    public fromJson(jsonObject: any, convertToCamel?: boolean): INpsSettings {
        super.fromJson(jsonObject, true);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<INpsTranslation>(NpsTranslation, jsonObject.translations, true);
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.minLabel) {
            keys.push('minLabel');
        }
        if (this.maxLabel) {
            keys.push('maxLabel');
        }
        if (this.additionalText) {
            keys.push('additionalText');
        }
        return keys;
    }

    /**
     * Retrieve the given translation for a locale
     *
     * @param {string} locale
     * @param {string} label
     * @param {boolean} createOnNotFound
     * @returns {ITranslation}
     */
    public getTranslation(locale: string, label: string, createOnNotFound: boolean = true): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

export interface INps extends IBaseItem {
    scoreMultiplier: number;
    points: number;
}

export class Nps extends BaseItem implements INps {
    type: string = 'nps';
    scoreMultiplier: number = null;
    settings: INpsSettings = new NpsSettings();

    get points(): number {
        return 10 * this.scoreMultiplier;
    }

    public initialize(translate?: ng.translate.ITranslateService): void {
        super.initialize();
        if (translate && typeof translate.use === 'function' && translate.use() && this.settings) {
            this.settings.minLabel = translate.instant('SURVEYS.ITEMS.NPS.NOT_LIKELY');
            this.settings.maxLabel = translate.instant('SURVEYS.ITEMS.NPS.EXTREMELY_LIKELY');
        }
    }
    /**
     * Build the NPS item from json
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {INps}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<INpsSettings>(NpsSettings, jsonObject.settings, true);
        }

        return this;
    }

    public toggleShowRangeLabels(): void {
        if (this.settings.iconGroup) {
            this.settings.showRangeLabels = false;
        } else {
            this.settings.showRangeLabels = true;
        }
    }

    /**
     * Gets the icon group
     * @returns {string}
     */
    public getPromoterIcon(): string {
        switch (this.settings.iconGroup) {
            case 'faces':
                return 'sentiment_very_satisfied';
            case 'thumbs':
                return 'thumb_up';
            case 'stars':
                return 'star';
            default:
                return null;
        }
    }

    /**
     * Gets the icon group
     * @returns {string}
     */
    public getDetractorIcon(): string {
        switch (this.settings.iconGroup) {
            case 'faces':
                return 'sentiment_very_dissatisfied';
            case 'thumbs':
                return 'thumb_down';
            case 'stars':
                return 'star_outline';
            default:
                return null;
        }
    }
}
