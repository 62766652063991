import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { SurveyService } from '../../../../services/SurveyService';
import { Survey } from '../../../../domain/surveys/survey/Survey';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { IBaseItem } from '../../../../domain/surveys/items/BaseItem';
import { ICalendar } from '../../../../domain/surveys/items/Calendar';

@Component('its.survey.module.surveys', InlineEditFieldComponent.CID, {
    template: require('/app/modules/surveys/components/inline-edit-field/InlineEditFieldComponent.html'),
    controllerAs: 'vm',
    require: {
        ngModelCtrl: 'ngModel',
    },
    bindings: {
        ngModel: '<',
        item: '<?',
        ngDisabled: '<',
    },
})
class InlineEditFieldComponent {
    static IID: string = 'InlineEditFieldComponent';
    static CID: string = 'itsInlineEditField';
    static $inject: string[] = ['$timeout', '$scope', 'itsSurveyService'];

    public ngModel: string;
    public ngModelCtrl: ng.INgModelController;
    public item: IBaseItem;

    public isEditing: boolean = false;
    public inputText: string = null;
    public showEditIcon: boolean = false;
    private originalText: string = null;
    private survey: Survey = null;

    public constructor(
        private timeout: ng.ITimeoutService,
        private scope: ng.IScope,
        private surveyService: SurveyService
    ) {}

    $onInit(): void {
        const surveySubscription: SubjectSubscription = this.surveyService.surveyValue.subscribe((survey) => {
            this.survey = survey;
        });
        surveySubscription.unsubscribeOnScopeDestruction(this.scope);
    }

    public enableEditing(): void {
        this.inputText = this.ngModel;
        this.originalText = this.ngModel;
        this.isEditing = true;
        this.timeout(() => {
            const inputElement: HTMLElement = document.getElementById('inlineEditInput');
            inputElement.focus();
        });
    }

    public setValueToNgModel(value: string): void {
        this.ngModelCtrl.$setViewValue(value);
    }

    public questionIsNotRequired(): boolean {
        return this.item && !this.item.required && !this.item.disabled;
    }

    public isNotCustomSubmitCalendar(): boolean {
        return (
            this.item.type !== 'calendar' ||
            (this.item.type === 'calendar' && !(<ICalendar>this.item).settings.isDateOfDocument)
        );
    }

    public closeInlineEdit(event: KeyboardEvent, force: boolean): void {
        if (force) {
            this.isEditing = false;
            this.originalText = this.inputText;
            return;
        }
        // only save if we have edited and have an original label (this informs us we haven't saved)
        // the originalLabel property also fixes double saving caused by enter which then executes a blur
        if ((event.keyCode === 13 || event.type === 'blur') && this.inputText && this.originalText !== this.inputText) {
            this.isEditing = false;
            this.originalText = this.inputText;
            this.surveyService.saveSurveyAndNotify(this.survey);
        } else if (event.keyCode === 27 || event.type === 'blur') {
            // esc key
            this.inputText = this.originalText;
            this.setValueToNgModel(this.inputText);
            this.isEditing = false;
        }
    }
}
