import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', TextRowsSettingComponent.CID, {
    template: require('/app/modules/surveys/components/items/shared/selects/text-rows-setting/TextRowsSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class TextRowsSettingComponent {
    static IID: string = 'TextRowsSettingComponent';
    static CID: string = 'itsTextRowsSetting';
    static $inject: string[] = [];
}
