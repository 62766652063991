import { Iql, IIql } from '@intouch/iql-ts-sdk/src/domain/Iql';
import { FilterGroup } from '@intouch/iql-ts-sdk/src/domain/filters/FilterGroup';
import { Filter } from '@intouch/iql-ts-sdk/src/domain/filters/Filter';
import { StringFilter } from '@intouch/iql-ts-sdk/src/domain/filters/StringFilter';
import { CountMetric } from '@intouch/iql-ts-sdk/src/domain/metrics/CountMetric';
import { Dimension } from '@intouch/iql-ts-sdk/src/domain/dimensions/Dimension';
import * as _ from 'lodash';
import { DateDimension } from '@intouch/iql-ts-sdk/src/domain/dimensions/DateDimension';
import { DateFilter } from '@intouch/iql-ts-sdk/src/domain/filters/DateFilter';

export class SurveyIqlBuilder {
    private iql: IIql = new Iql();
    private currentGroup: FilterGroup = null;
    private PROGRAM_ID_PATH: string = 'meta.reference->program_id';

    constructor(type: string = 'records') {
        let surveyProductFilter: StringFilter = null;
        if (type === 'trace') {
            surveyProductFilter = new StringFilter('data->product', Iql.EQUAL, 'survey');
        } else {
            surveyProductFilter = new StringFilter('meta->product', Iql.EQUAL, 'survey');
        }
        this.iql.dataSource(type);
        this.iql.filter(new FilterGroup(Iql.AND, [surveyProductFilter]));
    }

    public addRecordCountMetric(): SurveyIqlBuilder {
        this.iql.metric(new CountMetric('meta->synthesis_id'));
        return this;
    }

    public addActionCountMetric(): SurveyIqlBuilder {
        this.iql.metric(new CountMetric('data->action'));
        return this;
    }

    public addActionDimension(): SurveyIqlBuilder {
        this.iql.dimension('data->action', Iql.STRING);
        return this;
    }

    public addDateDimension(): SurveyIqlBuilder {
        this.iql.dimension(new DateDimension('meta->date_of_document'));
        return this;
    }

    public addRecordAverageScoreMetric(): SurveyIqlBuilder {
        this.iql.metric('meta->score.percentage', Iql.SCORE, Iql.AVERAGE, 'score');
        return this;
    }

    public addProgramDimension(groupingLabel: string = null): SurveyIqlBuilder {
        this.iql.dimension(new Dimension('meta.reference->program_id', 'string', groupingLabel));
        return this;
    }

    public addSurveyEmailFilter(emailUuid: string): SurveyIqlBuilder {
        const surveyEmailFilter: StringFilter = new StringFilter(
            'meta.reference.survey_email->uuid',
            Iql.EQUAL,
            emailUuid
        );
        this.addFilter(surveyEmailFilter);
        return this;
    }

    public addSurveySMSFilter(smsUuid: string): SurveyIqlBuilder {
        const surveySMSFilter: StringFilter = new StringFilter('meta.reference.survey_sms->uuid', Iql.EQUAL, smsUuid);
        this.addFilter(surveySMSFilter);
        return this;
    }

    public addDistributionFilter(distributionUuid: string): SurveyIqlBuilder {
        const distributionFilter: StringFilter = new StringFilter(
            'meta.reference->distribution_uuid',
            Iql.EQUAL,
            distributionUuid
        );
        this.addFilter(distributionFilter);
        return this;
    }

    public addProgramIdFilterGroup(surveyOriginalUuid: string): SurveyIqlBuilder {
        const programIdFilter: StringFilter = new StringFilter(this.PROGRAM_ID_PATH, Iql.EQUAL, surveyOriginalUuid);
        const programIdFilterGroup: FilterGroup = new FilterGroup(Iql.AND, [programIdFilter]);
        this.iql.filter(programIdFilterGroup);
        return this;
    }

    public addProgramIdFilter(surveyOriginalUuid: string): SurveyIqlBuilder {
        const programIdFilter: StringFilter = new StringFilter(this.PROGRAM_ID_PATH, Iql.EQUAL, surveyOriginalUuid);
        this.addFilter(programIdFilter);
        return this;
    }

    public addPast30DaysFilter(): SurveyIqlBuilder {
        const past30DaysFilter: DateFilter = new DateFilter('meta->date_of_document', Iql.EQUAL, 'past_30_days');
        this.addFilter(past30DaysFilter);
        return this;
    }

    public addManyProgramIdFilters(surveyOriginalUuids: string[]): SurveyIqlBuilder {
        const programIdFilters: StringFilter[] = this.convertStringArrayToIqlStringFilters(
            this.PROGRAM_ID_PATH,
            surveyOriginalUuids
        );
        const programIdFilterGroup: FilterGroup = new FilterGroup(Iql.OR, programIdFilters);
        this.iql.filter(programIdFilterGroup);
        return this;
    }

    public addActionFilter(actionType: string): SurveyIqlBuilder {
        const actionFilter: StringFilter = new StringFilter('data->action', Iql.EQUAL, actionType);
        this.addFilter(actionFilter);
        return this;
    }

    public addHierarchyNodesFilterGroup(hierarchyNodeUuids: string[]): SurveyIqlBuilder {
        const hierarchyNodeFilters: Filter[] = this.convertStringArrayToIqlFilters(
            'meta.hierarchy->uuid',
            hierarchyNodeUuids,
            Iql.HIERARCHY
        );
        const hierarchyNodeFilterGroup: FilterGroup = new FilterGroup(Iql.OR, hierarchyNodeFilters);
        this.iql.filter(hierarchyNodeFilterGroup);
        return this;
    }

    public addDomainFilter(domain: string): SurveyIqlBuilder {
        const domainFilter: StringFilter = new StringFilter('data->domain', Iql.EQUAL, domain);
        this.addFilter(domainFilter);
        return this;
    }

    public addTargetIdFilter(targetUuid: string): SurveyIqlBuilder {
        const targetIdFilter: StringFilter = new StringFilter('data.targets.*.->id', Iql.EQUAL, targetUuid);
        this.addFilter(targetIdFilter);
        return this;
    }

    public openAndGroup(): SurveyIqlBuilder {
        this.openGroup(Iql.AND);
        return this;
    }

    public openOrGroup(): SurveyIqlBuilder {
        this.openGroup(Iql.OR);
        return this;
    }

    public closeGroup(): SurveyIqlBuilder {
        if (this.currentGroup) {
            this.iql.filter(this.currentGroup);
            this.currentGroup = null;
        } else {
            throw new Error('Tried to close a group without having opened one');
        }

        return this;
    }

    public getIql(): IIql {
        if (this.currentGroup) {
            this.iql.filter(this.currentGroup);
        }
        const iql: IIql = _.cloneDeep(this.iql);
        this.reset();
        return iql;
    }

    public reset(): void {
        this.iql = new Iql();
        this.iql.dataSource('followups');
        this.currentGroup = null;
    }

    private openGroup(condition: string = Iql.AND): SurveyIqlBuilder {
        if (this.currentGroup) {
            throw new Error('Tried to open a group while already having one opened');
        }
        this.currentGroup = new FilterGroup(condition);
        return this;
    }

    private addFilter(filterToAdd: Filter): void {
        if (!this.currentGroup) {
            throw new Error('Trying to add a filter without a group opened, start one with .openGroup()');
        }

        this.currentGroup.filters.push(filterToAdd);
    }

    private convertStringArrayToIqlFilters(iqlFilterPath: string, strings: string[], filterType: string): Filter[] {
        return _.map(strings, (originalUuid) => {
            return new Filter(iqlFilterPath, Iql.EQUAL, originalUuid, filterType);
        });
    }

    private convertStringArrayToIqlStringFilters(iqlFilterPath: string, strings: string[]): StringFilter[] {
        return _.map(strings, (originalUuid) => {
            return new StringFilter(iqlFilterPath, Iql.EQUAL, originalUuid);
        });
    }
}
