import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { BaseItem, IBaseItem } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { IHealthPoints } from './IHealthPoints';

export interface ICaptchaSettings extends IBaseSettings {
    hideLabel: boolean;
}

export class CaptchaSettings extends BaseSettings implements ICaptchaSettings {
    hideLabel: boolean = false;
    hideFromReporting: boolean = true;
}

export type ICaptcha = IBaseItem;

export class Captcha extends BaseItem implements ICaptcha, IHealthPoints {
    type: string = 'captcha';
    settings: ICaptchaSettings = new CaptchaSettings();
    _healthPoints: number = 1;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey.
     */
    public get healthPoints(): number {
        return this._healthPoints;
    }

    /**
     * Build the text input item from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {ICaptcha}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<ICaptchaSettings>(CaptchaSettings, jsonObject.settings, true);
        }

        return this;
    }
}
