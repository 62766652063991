import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', UseOriginalScaleSettingComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/shared/switches/use-original-scale-setting/UseOriginalScaleSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class UseOriginalScaleSettingComponent {
    static IID: string = 'UseOriginalScaleSettingComponent';
    static CID: string = 'itsUseOriginalScaleSetting';
    static $inject: string[] = [];
}
