import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Calendar, ICalendar } from '../../../../../../domain/surveys/items/Calendar';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';
import moment from 'moment';
import Moment = moment.Moment;

@Component('its.survey.module.surveys', CalendarSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/calendar/CalendarSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: CalendarSettingsComponent.getBindings(),
})
class CalendarSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'CalendarSettingsComponent';
    static CID: string = 'itsCalendarSettings';
    static $inject: string[] = [];

    public item: Calendar;

    public calendarFormats: string[] = [];
    public calendarValid: boolean = false;
    public invalidDateFormat: boolean = false;

    $onInit(): void {
        if (this.item.type !== 'calendar') {
            return;
        }
        this.calendarFormats = this.item.settings.getAvailableFormats();
    }

    public setInputValue(calendarItem: ICalendar, form: ng.IFormController): void {
        if (!moment((<any>calendarItem).dateInput, this.item.settings.format, true).isValid()) {
            this.calendarValid = false;
            this.invalidDateFormat = true;
            form['earlyDatePicker'].$setValidity(null, this.calendarValid);
            return;
        } else {
            this.invalidDateFormat = false;
        }

        const datePickerSelection: Moment = moment((<any>calendarItem).dateInput);
        (<any>calendarItem).dateInput = datePickerSelection.format(this.item.settings.format);
        this.validateCalendarForm(form);
    }

    /**
     * Set required if latest date field is not specified
     * @return {boolean}
     */
    public earlyDateRequired(): boolean {
        if (this.item.settings.restrictDateSelection) {
            if (
                this.item.settings.latestDate.restrictionType === null ||
                this.item.settings.latestDate.restrictionType === 'none'
            ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Set required if earliest date field not specified
     * @return {boolean}
     */
    public lateDateRequired(): boolean {
        if (this.item.settings.restrictDateSelection) {
            if (
                this.item.settings.earliestDate.restrictionType === null ||
                this.item.settings.earliestDate.restrictionType === 'none'
            ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Validate Calendar restrictions
     *
     * @param {angular.IFormController} form
     * @return {boolean}
     */
    public validateCalendarForm(form: ng.IFormController): void {
        this.calendarValid = true;

        if (this.item.settings.earliestDate.getDate() === null && this.item.settings.latestDate.getDate() === null) {
            this.calendarValid = false;
        } else {
            if (
                this.item.settings.earliestDate.getDate() !== null &&
                this.item.settings.latestDate.getDate() !== null
            ) {
                this.calendarValid = moment(this.item.settings.latestDate.getDate()).isSameOrAfter(
                    moment(this.item.settings.earliestDate.getDate()),
                    'day'
                );
            }
        }
        /* if user disables this option, form is valid again */
        if (this.item.settings.restrictDateSelection === false) {
            this.calendarValid = true;
        }
        form['earlyDatePicker'].$setValidity(null, this.calendarValid);
    }

    /**
     * Initialize Early Range according to restriction type and/or the state of late range
     */
    public initializeEarlyDate(form: ng.IFormController): void {
        if (this.item.settings.earliestDate.restrictionType === 'calculated') {
            this.item.settings.earliestDate.dateAmount = 0;
            this.item.settings.earliestDate.calculationUnit = 'days';
            this.item.settings.earliestDate.dateInput = null;
        } else if (this.item.settings.earliestDate.restrictionType === 'specific') {
            if (this.item.settings.latestDate.getDate()) {
                this.item.settings.earliestDate.dateInput = moment(this.item.settings.latestDate.getDate()).format(
                    this.item.settings.format
                );
            } else {
                this.item.settings.earliestDate.dateInput = moment().format(this.item.settings.format);
            }
        } else {
            this.item.settings.earliestDate.dateInput = null;
        }

        this.validateCalendarForm(form);
    }

    /**
     * Initialize Late Range according to restriction type and/or the state of early range
     */
    public initializeLateDate(form: ng.IFormController): void {
        if (this.item.settings.latestDate.restrictionType === 'calculated') {
            this.item.settings.latestDate.dateAmount = 0;
            this.item.settings.latestDate.calculationUnit = 'days';
            this.item.settings.latestDate.dateInput = null;
        } else if (this.item.settings.latestDate.restrictionType === 'specific') {
            if (this.item.settings.earliestDate.getDate()) {
                this.item.settings.latestDate.dateInput = moment(this.item.settings.earliestDate.getDate()).format(
                    this.item.settings.format
                );
            } else {
                this.item.settings.latestDate.dateInput = moment().format(this.item.settings.format);
            }
        } else {
            this.item.settings.latestDate.dateInput = null;
        }
        this.validateCalendarForm(form);
    }

    /**
     * Helper method to hide calendar advanced settings on save
     * @return {boolean}
     */
    public showDateRestrictionOptions(): boolean {
        return this.item.settings.restrictDateSelection;
    }

    /**
     * Handle pressing plus or minus button for early date calculation and triggering validation
     * @param direction
     * @param form
     */
    public earlyDateButtonHandler(direction: string, form: ng.IFormController): void {
        this.item.settings.earliestDate.plusMinus = direction;
        this.validateCalendarForm(form);
    }

    /**
     * Handle pressing plus or minus button for late date calculation and triggering validation
     * @param direction
     * @param form
     */
    public lateDateButtonHandler(direction: string, form: ng.IFormController): void {
        this.item.settings.latestDate.plusMinus = direction;
        this.validateCalendarForm(form);
    }
}
