import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ISurveySession } from '../../../services/SurveySession';
import { CustomDomainCreateModal } from './CustomDomainCreateModal';
import { ISurveyApi } from '../../../api/SurveyApi';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { GeneralSettings, IGeneralSettings } from '../../../domain/settings/GeneralSettings';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';

@Controller('its.survey.module.settings', CustomDomainsController.IID, CustomDomainsController)
class CustomDomainsController {
    static IID: string = 'its.survey.module.settings.CustomDomainsController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        'itsSurveyApi',
        'itsSurveySession',
        'iteAccessService',
        'iteToaster',
        '$translate',
    ];

    protected domains: Array<string> = [];
    protected settings: IGeneralSettings = new GeneralSettings();
    protected isIntouch: boolean = false;
    protected loading: boolean = false;
    protected hasError: boolean = false;

    constructor(
        private stateService: ng.ui.IStateService,
        private dialog: ng.material.IDialogService,
        private surveyApi: ISurveyApi,
        private session: ISurveySession,
        private accessService: IAccessService,
        private toaster: IToaster,
        private translate: ng.translate.ITranslateService
    ) {
        this.isIntouch = this.session.getUser().isIntouchUser();

        this.load();
    }

    /**
     * Opens the create custom domain modal
     * @param {boolean} createAnother
     */
    public create(createAnother: boolean = false): void {
        this.dialog
            .show(
                CustomDomainCreateModal.instantiate({
                    locals: {
                        createAnother: createAnother,
                        settings: this.settings,
                    },
                })
            )
            .then((result: { domain: string; createAnother: boolean }) => {
                this.toaster.success(
                    this.translate.instant('CONTACT_CENTER.SETTINGS.CREATED_CUSTOM_DOMAIN', { label: result.domain })
                );
                this.load();
                if (result.createAnother) {
                    this.create(true);
                }
            });
    }

    /**
     * Opens the edit custom domain modal
     * @param {boolean} createAnother
     */
    public edit(domain: string, createAnother: boolean = false): void {
        this.dialog
            .show(
                CustomDomainCreateModal.instantiate({
                    locals: {
                        domain: domain,
                        settings: this.settings,
                        editing: true,
                    },
                })
            )
            .then((result: string) => {
                this.toaster.info(
                    this.translate.instant('CONTACT_CENTER.SETTINGS.UPDATED_CUSTOM_DOMAIN', { label: result })
                );
                this.load();
            });
    }

    /**
     * Loads custom domain listing page
     */
    public load(): void {
        const token: IToken = this.accessService.getToken();
        this.loading = true;
        if (token && token.getOrganization()) {
            this.surveyApi
                .getSettings()
                .then((settings: IGeneralSettings) => {
                    this.settings = settings;
                    this.domains = settings.customDomains;
                })
                .catch(() => {
                    this.hasError = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
