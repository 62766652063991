import { IEntity, Entity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface ISurveyReminder extends IEntity {
    type: string;
    intervalDays: number;
    subject: string;
    body: string;

    hasValidBody(): boolean;
    isValid(): boolean;
}

export class SurveyReminder extends Entity implements ISurveyReminder {
    type: string = null;
    intervalDays: number = 1;
    subject: string = null;
    body: string = '';

    /**
     * Returns if the email body contains the required content ([[SURVEY.LINK]] and [[SURVEY.UNSUBSCRIBE]])
     *
     * @returns {boolean}
     */
    public hasValidBody(): boolean {
        return (
            this.body && this.body.indexOf('[[SURVEY.LINK]]') > -1 && this.body.indexOf('[[SURVEY.UNSUBSCRIBE]]') > -1
        );
    }

    /**
     * Returns if all the required fields are there and if they pass validation
     *
     * @returns {boolean}
     */
    public isValid(): boolean {
        return this.type && this.intervalDays && this.subject && this.hasValidBody();
    }
}
