import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';
import { ItemSettingsTab } from '../../shared/item-settings-tabs/ItemSettingsTabs';

@Component('its.survey.module.surveys', RadioSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/response-items/radio/RadioSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: RadioSettingsComponent.getBindings(),
})
class RadioSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'RadioSettingsComponent';
    static CID: string = 'itsRadioSettings';
    static $inject: string[] = ['$translate'];

    public tabs: ItemSettingsTab[] = ['basic', 'advanced', 'points'];
}
