import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IHelpTextSettings } from '../../../../../../../domain/surveys/items/shared/HelpText';
import { BaseItem } from '../../../../../../../domain/surveys/items/BaseItem';

@Component('its.survey.module.surveys', HelpTextSettingComponent.CID, {
    template: require('/app/modules/surveys/components/items/shared/inputs/help-text-setting/HelpTextSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
        ngRequired: '<',
    },
})
class HelpTextSettingComponent {
    static IID: string = 'HelpTextSettingComponent';
    static CID: string = 'itsHelpTextSetting';
    static $inject: string[] = [];

    public item: BaseItem;

    public sanitizeHtmlFromText(): void {
        const settings: IHelpTextSettings<any> = this.item.settings as any as IHelpTextSettings<any>;

        if (settings.additionalText) {
            settings.additionalText = this.sanitizeHtmlFromInput(settings.additionalText);
        }
    }

    private sanitizeHtmlFromInput(input: string): string {
        return String(input).replace(/<[^>]+>/gm, '');
    }
}
