import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { ITranslation, Translation } from '../Translation';
import { String } from '@intouch/its.essential/app/essential/utils/String';
import { TranslationLocator } from '../TranslationLocator';
import { ILogic, Logic } from '../logic/Logic';
import { ILogicReference } from '../logic/LogicReference';
import { ITranslatedEntity } from '../../ITranslatedEntity';

/**
 * A form user object interface
 */
export interface IBaseResponse extends IEntity {
    uuid: string;
    points: number;
    type: string;
    label: string;
    defaultSelected: boolean;
    translations: Array<ITranslation>;
    logic: ILogic;
    isOther: boolean;
    logicReferences: Array<ILogicReference>;

    getTranslation(locale: string, createOnNotFound: boolean): ITranslation;
}

/**
 * A form user object
 */
export class BaseResponse extends Entity implements IBaseResponse, ITranslatedEntity {
    uuid: string = String.uuid();
    points: number = null;
    type: string = 'response';
    label: string = null;
    defaultSelected: boolean = false;
    translations: Array<ITranslation> = [];
    logic: ILogic = null;
    isOther: boolean = false;
    @NotEnumerable
    $$mdSelectId: number = null;
    @NotEnumerable
    logicReferences: Array<ILogicReference> = [];

    /**
     * Allow a user to quickly create a response item
     *
     * @param label
     * @PARAM isOther
     * @returns {ISection}
     */
    public static make<T extends typeof BaseResponse>(label: string, isOther: boolean = false): InstanceType<T> {
        const me: BaseResponse = new BaseResponse();
        me.label = label;
        me.isOther = isOther;
        return me as InstanceType<T>;
    }

    /**
     * Build the base response item from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IBaseResponse}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ITranslation>(Translation, jsonObject.translations, true);
        }

        if (jsonObject.logic) {
            this.logic = EntityBuilder.buildOne<ILogic>(Logic, jsonObject.logic, true);
        }

        return this;
    }

    public getLabel(): string {
        return this.label;
    }

    public getTranslationKeys(): Array<string> {
        return ['label'];
    }

    /**
     * Retrieve the given translation for a locale
     *
     * @param {string} locale
     * @param {boolean} createOnNotFound
     * @returns {ITranslation}
     */
    public getTranslation(locale: string, createOnNotFound: boolean = true): ITranslation {
        return TranslationLocator.locate(this.translations, 'label', locale);
    }
}
