import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { ITopNavService } from '@intouch/its.essential/app/essential/services/TopNavService';

/**
 * The group service interface
 */
export interface IPageService {
    setPageTitle(title: string): void;
    startLoading(): void;
    stopLoading(): void;
    failLoading(): void;

    isDoneLoading(): boolean;
    isLoading(): boolean;
    isErrorLoading(): boolean;
}

/**
 * The group service class
 */
@Service('its.survey.services', PageService.IID, PageService)
export class PageService implements IPageService {
    static IID: string = 'itsPageService';
    static $inject: Array<string> = ['iteTopNavService'];

    protected loading: boolean = false;
    protected error: boolean = false;

    /**
     * Create the pager service class
     *
     * @param {ITopNavService} topNavService
     */
    constructor(private topNavService: ITopNavService) {}

    /**
     * Indicate if we are done loading the page without errors
     *
     * @returns {boolean}
     */
    public isDoneLoading(): boolean {
        return !this.loading && !this.error;
    }

    /**
     * Indicate if we are loading the page
     *
     * @returns {boolean}
     */
    public isLoading(): boolean {
        return this.loading && !this.error;
    }

    /**
     * Indicate if we had an error loading the page
     *
     * @returns {boolean}
     */
    public isErrorLoading(): boolean {
        return this.error && !this.loading;
    }

    /**
     * Start the page load routine
     */
    public startLoading(): void {
        this.loading = true;
        this.error = false;
    }

    /**
     * Stop the page load routine
     */
    public stopLoading(): void {
        this.loading = false;
        this.error = false;
    }

    /**
     * Fail the page load routine
     */
    public failLoading(): void {
        this.error = true;
        this.loading = false;
    }

    /**
     *
     * Sets the title of the page
     * @param title
     */
    public setPageTitle(title: string): void {
        this.topNavService.title = title;
    }
}
