import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { TextArea } from '../../../../../../../domain/surveys/items/TextArea';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';

@Component('its.survey.module.surveys', AnalyzeSentimentSettingComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/shared/switches/analyze-sentiment-setting/AnalyzeSentimentSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class AnalyzeSentimentSettingComponent {
    static IID: string = 'AnalyzeSentimentSettingComponent';
    static CID: string = 'itsAnalyzeSentimentSetting';
    static $inject: string[] = ['iteAccessService'];

    public item: TextArea;
    public hasSentimentFeature: boolean = false;

    public constructor(private accessService: IAccessService) {}

    $onInit(): void {
        this.hasSentimentFeature = this.accessService.hasFeatureEnabled('survey', 'sentiment-analysis');
    }
}
