import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', HideFromReportingSettingComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/shared/switches/hide-from-reporting-setting/HideFromReportingSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class HideFromReportingSettingComponent {
    static IID: string = 'HideFromReportingSettingComponent';
    static CID: string = 'itsHideFromReportingSetting';
    static $inject: string[] = [];
}
