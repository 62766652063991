import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IDefinedResponse, ResponseFactory } from '../../../../../../../domain/surveys/items/ResponseFactory';
import { IBaseResponseItem } from '../../../../../../../domain/surveys/items/BaseResponseItem';
import { BaseResponse } from '../../../../../../../domain/surveys/items/BaseResponse';
import { IconResponse } from '../../../../../../../domain/surveys/items/IconResponse';

@Component('its.survey.module.surveys', ResponseTemplateSelectComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/response-items/shared/response-template-select/ResponseTemplateSelectComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class ResponseTemplateSelectComponent {
    static IID: string = 'ResponseTemplateSelectComponent';
    static CID: string = 'itsResponseTemplateSelect';
    static $inject: string[] = ['$translate'];

    public item: IBaseResponseItem;

    public availableTemplates: IDefinedResponse[] = [];
    public responseTemplate: IDefinedResponse = null;

    constructor(private translate: ng.translate.ITranslateService) {}

    $onInit(): void {
        this.availableTemplates = ResponseFactory.getAvailableDefinedResponses(this.item.type, this.translate);
    }

    public applyResponseTemplate(template: string, item: IBaseResponseItem, withIcons: boolean = false): void {
        if (!template) {
            this.responseTemplate = null;
            item.initialize(this.translate);
        } else {
            if (!withIcons) {
                item.setResponses(ResponseFactory.buildDefinedResponses(template, BaseResponse, this.translate));
            } else {
                item.setResponses(ResponseFactory.buildDefinedResponses(template, IconResponse, this.translate));
            }
        }
    }
}
