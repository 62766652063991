import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { BaseItem, IBaseItem } from './BaseItem';
import { ITag, Tag } from '@intouch/its.essential/app/essential/domain/access/Tag';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { IHelpTextSettings, IHelpTextTranslation } from './shared/HelpText';
import { IHealthPoints } from './IHealthPoints';
import { ITranslatedEntity } from '../../ITranslatedEntity';

export type ILocationTranslation = IHelpTextTranslation;

export class LocationTranslation extends Translation implements ILocationTranslation {
    additionalText: string = null;
}

export interface ILocationSettings extends IBaseSettings, IHelpTextSettings<ILocationTranslation> {
    filterTags: Array<ITag>;
    displayType: string;
    placeholder: string;
    enableSearch: boolean;
}

export class LocationSettings extends BaseSettings implements ILocationSettings, ITranslatedEntity {
    filterTags: Array<ITag> = [];
    additionalText: string = null;
    placeholder: string = null;
    translations: Array<ILocationTranslation> = [];
    displayType: string = 'address';
    enableSearch: boolean = false;

    // used for boolean to show/hide filter tags
    @NotEnumerable
    limitSurveyResponses: boolean = false;

    /**
     * Build the tag array from JSON.
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {ILocationSettings}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): ILocationSettings {
        super.fromJson(jsonObject, true);

        if (jsonObject.filter_tags) {
            this.filterTags = EntityBuilder.buildMany<ITag>(Tag, jsonObject.filter_tags, true);
        }
        this.limitSurveyResponses = this.filterTags.length > 0;

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ILocationTranslation>(
                LocationTranslation,
                jsonObject.translations,
                true
            );
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.additionalText) {
            keys.push('additionalText');
        }
        return keys;
    }

    public handleLimitToggle(state: boolean): void {
        if (!state) {
            this.filterTags = [];
        }
    }

    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

export interface ILocation extends IBaseItem {
    settings: ILocationSettings;
}

export class Location extends BaseItem implements ILocation, IHealthPoints {
    type: string = 'location';
    settings: ILocationSettings = new LocationSettings();

    @NotEnumerable
    _healthPoints: number = 2;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey. This overrides the BaseItem default value.
     */
    get healthPoints(): number {
        return this._healthPoints;
    }

    /**
     * Build the location from JSON.
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {ILocation}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<ILocationSettings>(LocationSettings, jsonObject.settings, true);
        }

        return this;
    }
}
