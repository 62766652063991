import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface ISurveySettingTranslation extends IEntity {
    locale: string;
    backButtonText: string;
    nextButtonText: string;
    customMessage: string;
}

export class SurveySettingTranslation extends Entity {
    locale: string = null;
    backButtonText: string = null;
    nextButtonText: string = null;
    customMessage: string = null;
}
