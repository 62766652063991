import moment from 'moment';
import { IIqlQueryResult, IIqlQueryResultSeriesData } from '@intouch/iql-ts-sdk/src/domain/IqlQueryResult';
import { IqlQueryResultSeriesAdapter } from '@intouch/iql-ts-sdk/src/domain/adapters/IqlQueryResultSeriesAdapter';
import { Iql } from '@intouch/iql-ts-sdk/src/domain/Iql';

export interface ISubscriptionChartData {
    createdColor: string;
    unsubscribedColor: string;
    undeliverableColor: string;
    monthOverMonthGrowth: number;
    createdCurrentMonth: number;
    createdPreviousMonth: number;
    unsubscribedCurrentMonth: number;
    unsubscribedPreviousMonth: number;
    undeliverableCurrentMonth: number;
    undeliverablePreviousMonth: number;
    createdTotal: number;
    unsubscribedTotal: number;
    undeliverableTotal: number;

    setCreatedStats(data: IIqlQueryResult): ISubscriptionChartData;

    setUnsubscribedStats(data: IIqlQueryResult): ISubscriptionChartData;

    setUndeliverableStats(data: IIqlQueryResult): ISubscriptionChartData;

    setGrowthStats(): ISubscriptionChartData;
}

export class SubscriptionChartData implements ISubscriptionChartData {
    public createdColor: string = '#4ec8ef';
    public unsubscribedColor: string = '#b0b0b0';
    public undeliverableColor: string = '#f6bf26';
    public monthOverMonthGrowth: number = null;
    public createdCurrentMonth: number = null;
    public createdPreviousMonth: number = null;
    public unsubscribedCurrentMonth: number = null;
    public unsubscribedPreviousMonth: number = null;
    public undeliverableCurrentMonth: number = null;
    public undeliverablePreviousMonth: number = null;
    public createdTotal: number = null;
    public unsubscribedTotal: number = null;
    public undeliverableTotal: number = null;

    /**
     * Set stats for created stats
     *
     * @param data
     */
    public setCreatedStats(data: IIqlQueryResult): ISubscriptionChartData {
        this.createdCurrentMonth = 0;
        this.createdPreviousMonth = 0;
        this.createdTotal = 0;

        if (data && data.hasData()) {
            const current: IIqlQueryResultSeriesData = data.series[0].data[data.series[0].data.length - 1];
            if (moment().isSame(moment(current.value), 'month') && moment().isSame(moment(current.value), 'year')) {
                this.createdCurrentMonth = current.metrics['created'] || 0;
            }

            const previous: IIqlQueryResultSeriesData =
                data.series[0].data.length > 1 ? data.series[0].data[data.series[0].data.length - 2] : null;
            if (
                previous &&
                moment().subtract(1, 'month').isSame(moment(previous.value), 'month') &&
                moment().subtract(1, 'month').isSame(moment(previous.value), 'year')
            ) {
                this.createdPreviousMonth = previous.metrics['created'] || 0;
            }

            this.createdTotal =
                IqlQueryResultSeriesAdapter.toSingleValueAggregation(data.series[0], Iql.SUM, 'metrics.created') || 0;
        }
        return this;
    }

    /**
     * Set stats for unsubscribed stats
     *
     * @param data
     */
    public setUnsubscribedStats(data: IIqlQueryResult): ISubscriptionChartData {
        this.unsubscribedCurrentMonth = 0;
        this.unsubscribedPreviousMonth = 0;
        this.unsubscribedTotal = 0;

        if (data && data.hasData()) {
            const current: IIqlQueryResultSeriesData = data.series[0].data[data.series[0].data.length - 1];
            if (this.isCurrentMonth(current)) {
                this.unsubscribedCurrentMonth = current.metrics['unsubscribed'] || 0;
            }

            const previous: IIqlQueryResultSeriesData =
                data.series[0].data.length > 1 ? data.series[0].data[data.series[0].data.length - 2] : null;
            if (this.isPreviousMonth(previous)) {
                this.unsubscribedPreviousMonth = previous.metrics['unsubscribed'] || 0;
            }

            this.unsubscribedTotal =
                IqlQueryResultSeriesAdapter.toSingleValueAggregation(data.series[0], Iql.SUM, 'metrics.unsubscribed') ||
                0;
        }
        return this;
    }

    /**
     * Set undeliverable stats
     *
     * @param data
     */
    public setUndeliverableStats(data: IIqlQueryResult): ISubscriptionChartData {
        this.undeliverableCurrentMonth = 0;
        this.undeliverablePreviousMonth = 0;
        this.undeliverableTotal = 0;

        if (data && data.hasData()) {
            const current: IIqlQueryResultSeriesData = data.series[0].data[data.series[0].data.length - 1];
            if (this.isCurrentMonth(current)) {
                this.undeliverableCurrentMonth = current.metrics['undeliverable'] || 0;
            }

            const previous: IIqlQueryResultSeriesData =
                data.series[0].data.length > 1 ? data.series[0].data[data.series[0].data.length - 2] : null;
            if (this.isPreviousMonth(previous)) {
                this.undeliverablePreviousMonth = previous.metrics['undeliverable'] || 0;
            }

            this.undeliverableTotal =
                IqlQueryResultSeriesAdapter.toSingleValueAggregation(
                    data.series[0],
                    Iql.SUM,
                    'metrics.undeliverable'
                ) || 0;
        }
        return this;
    }

    /**
     * Set growth stats
     *
     */
    public setGrowthStats(): ISubscriptionChartData {
        this.monthOverMonthGrowth = 0;

        // month over month (mom)
        const currentMom: number = (this.createdCurrentMonth || 0) - (this.unsubscribedCurrentMonth || 0);
        const previousMom: number = (this.createdPreviousMonth || 0) - (this.unsubscribedPreviousMonth || 0);
        const diffMom: number = currentMom - previousMom;

        this.monthOverMonthGrowth = previousMom ? (diffMom / previousMom) * 100 : null;

        return this;
    }

    protected isCurrentMonth(current: IIqlQueryResultSeriesData): boolean {
        return moment().isSame(moment(current.value), 'month') && moment().isSame(moment(current.value), 'year');
    }

    protected isPreviousMonth(previous: IIqlQueryResultSeriesData): boolean {
        return (
            previous &&
            moment().subtract(1, 'month').isSame(moment(previous.value), 'month') &&
            moment().subtract(1, 'month').isSame(moment(previous.value), 'year')
        );
    }
}
