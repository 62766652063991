import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', AllowTextEntrySettingComponent.CID, {
    template:
        require('/app/modules/surveys/components/items/shared/switches/allow-text-entry-setting/AllowTextEntrySettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class AllowTextEntrySettingComponent {
    static IID: string = 'AllowTextEntrySettingComponent';
    static CID: string = 'itsAllowTextEntrySetting';
    static $inject: string[] = [];
}
