import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IOrganization } from '@intouch/its.essential/app/essential/domain/access/Organization';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IEmailDistribution } from '../../../domain/contact-center/EmailDistribution';

/**
 *  The UI for advanced options
 */
@Component('its.survey.module.surveys', EmailPreviewComponent.CID, {
    template: require('/app/modules/surveys/components/EmailPreviewComponent.html'),
    controllerAs: 'vm',
    bindings: {
        emailDistribution: '<',
        surveyOriginalUuid: '<',
    },
})
class EmailPreviewComponent {
    static IID: string = 'EmailPreviewComponent';
    static CID: string = 'itsEmailPreview';
    static $inject: Array<string> = ['iteAccessService', 'APPCONFIG'];

    protected previewEmailHtml: string = null;
    protected emailDistribution: IEmailDistribution;
    protected surveyOriginalUuid: string;

    public constructor(private accessService: IAccessService, private config: any) {
        this.previewEmailHtml = this.parseEmailBody(this.emailDistribution);
    }

    /**
     * Replace survey link token with an example link
     *
     * @param {IEmailDistribution} emailDistribution
     * @returns {string}
     */
    public parseEmailBody(emailDistribution: IEmailDistribution): string {
        let body: string = emailDistribution.email.body;
        body = body.replace(/href="\[\[SURVEY.LINK]]"/g, 'href="#" onclick="event.preventDefault()"');
        body = body.replace(/\[\[SURVEY.LINK]]/g, this.getSurveyUrl(false));
        body = body.replace(/href="\[\[SURVEY.UNSUBSCRIBE]]"/g, 'href="#" onclick="event.preventDefault()"');

        return body;
    }

    /**
     * Retrieves the public facing URL of the survey
     *
     * @param {boolean} urlEncoded
     * @returns {string}
     */
    public getSurveyUrl(urlEncoded: boolean = true): string {
        const url: string = this.config.app.endpoint + '/' + this.surveyOriginalUuid;
        return urlEncoded ? encodeURIComponent(url) : url;
    }

    /**
     * Get org logo
     *
     * @returns {string}
     */
    public getOrgLogo(): string {
        const token: IToken = this.accessService.getToken();
        if (token) {
            const org: IOrganization = token.getOrganization();
            if (org && org.avatarThumbUrl) {
                return org.avatarThumbUrl;
            }
        }

        return null;
    }
}
