import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { BaseItem, IBaseItem } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { IIconDetails } from './shared/IconDetails';
import * as _ from 'lodash';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';

/**
 * The rating settings interface
 */
export interface IRatingSettings extends IBaseSettings {
    icon: string;
    maxScore: number;
    availableIcons: IIconDetails[];
}

/**
 * The rating settings class
 */
export class RatingSettings extends BaseSettings implements IRatingSettings {
    public icon: string = 'star';
    public maxScore: number = 5;

    @NotEnumerable
    public availableIcons: IIconDetails[] = [
        {
            icon: 'star',
            value: 'star',
            label: 'Stars',
        },
        {
            icon: 'thumb_up',
            value: 'thumb_up',
            label: 'Thumbs',
        },
        {
            icon: 'tag_faces',
            value: 'tag_faces',
            label: 'Faces',
        },
        {
            icon: 'favorite',
            value: 'favorite',
            label: 'Hearts',
        },
        {
            icon: 'whatshot',
            value: 'whatshot',
            label: 'Fire',
        },
    ];
}

/**
 * The rating interface
 */
export interface IRating extends IBaseItem {
    settings: IRatingSettings;
    scoreMultiplier: number;
    points: number;

    getLabelFromIcon(icon: string): string;
}

/**
 * The rating class
 */
export class Rating extends BaseItem implements IRating {
    type: string = 'rating';
    settings: IRatingSettings = new RatingSettings();
    scoreMultiplier: number = null;

    get points(): number {
        if (this.scoreMultiplier === null || this.scoreMultiplier === undefined) {
            return 0;
        }
        return this.settings.maxScore * this.scoreMultiplier;
    }

    /**
     * Build the rating item from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IRating}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<IRatingSettings>(RatingSettings, jsonObject.settings, true);
        }

        return this;
    }

    public getLabelFromIcon(icon: string): string {
        const ratingObj: { icon: string; label: string } = _.find(this.settings.availableIcons, { icon: icon });
        if (ratingObj) {
            return ratingObj.label;
        } else {
            return null;
        }
    }
}
