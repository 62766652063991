import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface IContactList extends IEntity {
    uuid: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    subscribersCount: number;
}

export class ContactList extends Entity implements IContactList {
    uuid: string = null;
    name: string = null;
    createdAt: string = null;
    updatedAt: string = null;
    subscribersCount: number = null;
}
