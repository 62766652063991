import { IBaseItem } from '../items/BaseItem';

export interface ILogicItemEquation {
    question: string;
    operator: string;
    operatorCode: string;
    value: string;
    operandItem: IBaseItem;
}

export class LogicItemEquation implements ILogicItemEquation {
    question: string = null;
    operator: string = null;
    operatorCode: string = null;
    value: string = null;
    operandItem: IBaseItem = null;

    public static make(
        question: string,
        operator: string,
        operatorCode: string,
        value: string,
        operandItem: IBaseItem
    ): ILogicItemEquation {
        const me: ILogicItemEquation = new LogicItemEquation();
        me.question = question;
        me.operator = operator;
        me.operatorCode = operatorCode;
        me.value = value;
        me.operandItem = operandItem;
        return me;
    }
}
