import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface IMailSenderConfiguration {
    from: Array<string>;
    secret: string;
    domain: string;
    host: string;
    port: number;
    username: string;
    password: string;
    encryption: string;
}

export class MailSenderConfiguration {
    from: Array<string> = [''];
    secret: string = null;
    domain: string = null;
    host: string = null;
    port: number = null;
    username: string = null;
    password: string = null;
    encryption: string = null;
}

export interface IMailSender extends IEntity {
    uuid: string;
    type: string;
    configuration: IMailSenderConfiguration;
}

export class MailSender extends Entity implements IMailSender {
    uuid: string = null;
    type: string = null;
    configuration: IMailSenderConfiguration = new MailSenderConfiguration();

    public fromJson(json: any, convert: boolean = true): IMailSender {
        super.fromJson(json, convert);

        if (json.configuration) {
            this.configuration = json.configuration;
        }

        return this;
    }
}
