import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { Checkbox } from './Checkbox';
import { Radio } from './Radio';
import { Group } from './Group';
import { Select } from './Select';
import { Input } from './Input';
import { TextArea } from './TextArea';
import { Image } from './Image';
import { Text } from './Text';
import { Section } from '../Section';
import { Calendar } from './Calendar';
import { Nps } from './Nps';
import { Matrix } from './Matrix';
import { Slider } from './Slider';
import { Video } from './Video';
import { Location } from './Location';
import { Audio } from './Audio';
import { Rating } from './Rating';
import { Captcha } from './Captcha';
import { ImageUpload } from './ImageUpload';
import { VideoInput } from './VideoInput';
import { Ranking } from './Ranking';
import { Scale } from './Scale';
import { HiddenInput } from './HiddenInput';
import { Digit } from './Digit';

/**
 * A factory that dynamically builds items based on the type and the given data
 */
export class ItemFactory {
    /**
     * Create the given item based on the given JSON data
     *
     * @param type
     * @param data
     * @param convertToCamel
     */
    public static create<T>(type: string, data: any = {}, convertToCamel: boolean = true): T {
        switch (type) {
            case 'input':
                return EntityBuilder.buildOne<T>(Input, data, convertToCamel);
            case 'select':
                return EntityBuilder.buildOne<T>(Select, data, convertToCamel);
            case 'text':
                return EntityBuilder.buildOne<T>(Text, data, convertToCamel);
            case 'radio':
                return EntityBuilder.buildOne<T>(Radio, data, convertToCamel);
            case 'checkbox':
                return EntityBuilder.buildOne<T>(Checkbox, data, convertToCamel);
            case 'image':
                return EntityBuilder.buildOne<T>(Image, data, convertToCamel);
            case 'group':
                return EntityBuilder.buildOne<T>(Group, data, convertToCamel);
            case 'nps':
                return EntityBuilder.buildOne<T>(Nps, data, convertToCamel);
            case 'textarea':
                return EntityBuilder.buildOne<T>(TextArea, data, convertToCamel);
            case 'section':
                return EntityBuilder.buildOne<T>(Section, data, convertToCamel);
            case 'calendar':
                return EntityBuilder.buildOne<T>(Calendar, data, convertToCamel);
            case 'matrix':
                return EntityBuilder.buildOne<T>(Matrix, data, convertToCamel);
            case 'slider':
                return EntityBuilder.buildOne<T>(Slider, data, convertToCamel);
            case 'video':
                return EntityBuilder.buildOne<T>(Video, data, convertToCamel);
            case 'location':
                return EntityBuilder.buildOne<T>(Location, data, convertToCamel);
            case 'audio':
                return EntityBuilder.buildOne<T>(Audio, data, convertToCamel);
            case 'rating':
                return EntityBuilder.buildOne<T>(Rating, data, convertToCamel);
            case 'captcha':
                return EntityBuilder.buildOne<T>(Captcha, data, convertToCamel);
            case 'image_upload':
                return EntityBuilder.buildOne<T>(ImageUpload, data, convertToCamel);
            case 'video_input':
                return EntityBuilder.buildOne<T>(VideoInput, data, convertToCamel);
            case 'ranking':
                return EntityBuilder.buildOne<T>(Ranking, data, convertToCamel);
            case 'scale':
                return EntityBuilder.buildOne<T>(Scale, data, convertToCamel);
            case 'hidden_input':
                return EntityBuilder.buildOne<T>(HiddenInput, data, convertToCamel);
            case 'number':
                return EntityBuilder.buildOne<T>(Digit, data, convertToCamel);
            default:
                throw new Error('Tried to build an item (' + type + ') that can not be built via the item builder');
        }
    }
}
