import { IEntity, Entity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface IMultiselectSelectionRequirements extends IEntity {
    selectionsOperator: string; // lte, gte, eq
    selectionsValue: number;
}

/**
 * Adds requirements for selection on multi-select items
 *
 */
export class MultiselectSelectionRequirements extends Entity implements IMultiselectSelectionRequirements {
    public selectionsOperator: string = 'eq';
    public selectionsValue: number = null;
}
