import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', RequiredSettingComponent.CID, {
    template: require('/app/modules/surveys/components/items/shared/switches/required-setting/RequiredSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
        ngDisabled: '<',
    },
})
class RequiredSettingComponent {
    static IID: string = 'RequiredSettingComponent';
    static CID: string = 'itsRequiredSetting';
    static $inject: string[] = [];
}
