import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IParsedImage, IPhotoService } from '@intouch/its.essential/app/essential/services/PhotoService';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';
import { IImage, Image } from '../../../../../../domain/surveys/items/Image';
import { SurveyApi } from '../../../../../../api/SurveyApi';
import { ItemSettingsTab } from '../../shared/item-settings-tabs/ItemSettingsTabs';

@Component('its.survey.module.surveys', ImageSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/display-items/image/ImageSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: ImageSettingsComponent.getBindings(),
})
class ImageSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'ImageSettingsComponent';
    static CID: string = 'itsImageSettings';
    static $inject: string[] = ['iteToaster', 'iteLogger', 'itsSurveyApi', 'itePhotoService'];

    public item: Image;
    public tabs: ItemSettingsTab[] = ImageSettingsComponent.defaultTabs();

    public constructor(
        private toaster: IToaster,
        private logger: ILogger,
        private surveyApi: SurveyApi,
        private photoService: IPhotoService
    ) {
        super();
    }

    public uploadImage(file: File): void {
        if (!file) {
            return;
        }

        this.photoService
            .parse(file, null, null, true)
            .then((parsedImage: IParsedImage) => {
                this.surveyApi.addPhoto(parsedImage.base64).then((response) => {
                    (<IImage>this.item).sizes = response.data;
                });
            })
            .catch((error) => {
                this.toaster.error('ERRORS.PHOTO_NOT_ADDED', error);
                this.logger.error('Failed to parse item image', error);
            });
    }
}
