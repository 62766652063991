import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ProgressStatus } from '@intouch/its.essential/app/essential/domain/ProgressStatus';

@Component('its.survey.module.surveys', HealthSection.CID, {
    template: require('/app/modules/surveys/components/health/HealthSectionComponent.html'),
    controllerAs: 'vm',
    bindings: {
        title: '@', // title of the card with all the info
        description: '@', // text displayed next to the progress circle and score
        score: '@', // number 0-100
        scoreText: '@', // text displayed within the progress circle
        status: '<',
        size: '@',
    },
})
class HealthSection {
    static IID: string = 'HealthSection';
    static CID: string = 'itsHealthSection';
    static $inject: Array<string> = ['$sce', '$attrs', '$interpolate'];

    public title: string;
    public description: string;
    public score: number;
    public size: string;
    public status: ProgressStatus;

    public htmlDescription: string = null;
    public disabled: boolean = false;

    constructor(
        private sce: ng.ISCEService,
        private attrs: ng.IAttributes,
        private interpolate: ng.IInterpolateService
    ) {}

    $onInit(): void {
        if (
            this.attrs.hasOwnProperty('disabled') &&
            this.interpolate(this.attrs['disabled'])({ vm: this }) !== 'false'
        ) {
            this.disabled = true;
        }

        this.htmlDescription = this.sce.trustAsHtml(this.description);
    }
}
