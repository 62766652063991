import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', TextareaRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/textarea/TextareaRenderComponent.html'),
    controllerAs: 'vm',
    bindings: TextareaRenderComponent.getBindings(),
})
class TextareaRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'TextareaRenderComponent';
    static CID: string = 'itsTextareaRender';
    static $inject: string[] = [];
}
