import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IPhotoService, IParsedImage } from '@intouch/its.essential/app/essential/services/PhotoService';

import { ISurvey } from '../../../domain/surveys/survey/Survey';
import { IBaseItem } from '../../../domain/surveys/items/BaseItem';
import { LogicOperatorFactory } from '../../../domain/surveys/logic/LogicOperatorFactory';
import {
    ILogic,
    ILogicCondition,
    ILogicItem,
    Logic,
    LogicCondition,
    LogicItem,
    LogicSettings,
} from '../../../domain/surveys/logic/Logic';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { ISection } from '../../../domain/surveys/Section';
import { IMatrix, MatrixQuestion } from '../../../domain/surveys/items/Matrix';
import { IBaseResponseItem } from '../../../domain/surveys/items/BaseResponseItem';
import { FroalaProvider } from '@intouch/its.essential/app/essential/domain/FroalaProvider';
import { IRating } from '../../../domain/surveys/items/Rating';
import { ISurveyService } from '../../../services/SurveyService';
import { ISurveyApi } from '../../../api/SurveyApi';
import { ICustomField } from '../../../domain/settings/CustomField';
import { IDefaultContactField } from '../../../domain/contact-center/DefaultContactField';
import { Contact } from '../../../domain/contact-center/Contact';
import { BaseResponse } from '../../../domain/surveys/items/BaseResponse';
import LogicCreateModalHtml from './LogicCreateModal.html';

import * as _ from 'lodash';
import moment from 'moment';
import { ILocationTag } from '../../../domain/surveys/Location/LocationTag';
import { ILocation } from '../../../domain/surveys/items/Location';
import { ILocationNode } from '../../../domain/surveys/Location/LocationNode';

/**
 * A class to show the create logic modal and its fancy fields
 */
export class LogicCreateModal extends Modal {
    static $inject: Array<string> = [
        '$mdDialog',
        '$translate',
        'itsSurveyService',
        'itsSurveyApi',
        'iteToaster',
        'APPCONFIG',
        'iteLogger',
        'itePhotoService',
    ];

    public type: string;
    public action: string;
    public editing: boolean;
    public questions: Array<IBaseItem | IBaseResponseItem>;
    public sections: Array<{ label: string; questions: Array<IBaseItem | IBaseResponseItem> }> = [];
    public logic: ILogic;
    public parentItemUuid: string;
    public hasCustomMessage: boolean = false;
    public submitting: boolean = false;
    public displayLogicItems: Array<IBaseItem> = [];
    public currentParentItemUuid: string = null;
    public redirect: boolean = false;
    public ratingScoreArray: Array<any> = [];
    public customFields: Array<ICustomField> = [];
    public locationTags: Array<ILocationTag> = [];
    public locationNodes: Array<ILocationNode> = [];
    public defaultFields: Array<IDefaultContactField> = Contact.getDefaultFields(this.translate);
    public loading: boolean = true;
    public disallowLocationOperator: boolean = false;

    protected froalaOptions: any = null;

    /**
     * Create an instance of this modal
     *
     * @param {ng.material.IDialogOptions} config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = LogicCreateModalHtml;
        config.controller = LogicCreateModal;

        return super.instantiate(config);
    }

    public constructor(
        private dialog: ng.material.IDialogService,
        private translate: ng.translate.ITranslateService,
        private surveyService: ISurveyService,
        private surveyApi: ISurveyApi,
        private toaster: IToaster,
        private config: any,
        private logger: ILogger,
        private photoService: IPhotoService
    ) {
        super();
        this.loadLocationAndLocationTags();
        this.loadCustomFields();
        this.setupFroala();
    }

    public handleCustomMessageChange(): void {
        if (!this.hasCustomMessage && this.logic.settings.customMessage) {
            this.logic.settings.customMessage.message = null;
        }
    }

    public formatDate(date: Date): string {
        const m: any = moment(date);
        return m.isValid() ? m.format('YYYY-MM-DD') : '';
    }

    public handleConditionDateChange(condition: ILogicCondition): void {
        condition.value = moment(condition.tempValue).format('YYYY-MM-DD');
    }

    /**
     * Swaps the selected question based on choice from dropdown
     *
     * @param {ILogicItem} logicItem
     */
    public selectQuestion(logicItem: ILogicItem): void {
        logicItem.conditions = [new LogicCondition()];
        if (logicItem.question.type !== 'matrix') {
            logicItem.operand = logicItem.question.uuid;
        }

        if (logicItem.question.type === 'number') {
            logicItem.operandType = 'input';
        } else {
            logicItem.operandType = logicItem.question.type;
        }
        logicItem.operators = LogicOperatorFactory.getOperators(logicItem.question.type, this.translate);

        if (logicItem.question.type === 'rating') {
            const surveyItem: IRating = <IRating>this.surveyService.getSurvey().getItemByUuid(logicItem.operand);
            logicItem.selectionArray = this.buildRatingArray(surveyItem);
        }

        logicItem.setConditionTypes();
    }

    /**
     * Returns the location's address as a nicely formatted string.
     *
     * @returns {string}
     */
    public getAddressString(location: ILocationNode): string {
        return (
            location.name +
            ' - ' +
            _.filter([
                location.addressLine1,
                location.addressLine2,
                location.city,
                location.region,
                location.zipPostal,
                location.country,
            ]).join(', ')
        );
    }

    public showLogicResponseValues(itemType: string, conditionOperator: string, conditionType: string): boolean {
        if (conditionOperator === 'empty' || conditionOperator === 'nempty') {
            return false;
        }

        if (conditionType !== 'response') {
            return itemType === 'rating';
        }

        return (
            itemType === 'checkbox' ||
            itemType === 'scale' ||
            itemType === 'select' ||
            itemType === 'radio' ||
            itemType === 'matrix' ||
            itemType === 'nps' ||
            itemType === 'rating'
        );
    }

    /**
     * This is specifically for metadata
     * @param {ILogicItem} logicItem
     */
    public handleLogicItemTypeChange(logicItem: ILogicItem): void {
        this.disallowLocationOperator = false;
        logicItem.operandType = null;
        logicItem.conditions = [];
        logicItem.operators = [];
        logicItem.operand = null;
        logicItem.conditions.push(new LogicCondition());
        if (logicItem.type === 'metadata') {
            logicItem.operandType = 'metadata_key';
            logicItem.operators = LogicOperatorFactory.getOperators(logicItem.type, this.translate);
            logicItem.setConditionTypes();
        } else if (logicItem.type === 'contact') {
            // we use the logic item type instead of logic item question type (special metadata case)
            logicItem.operators = [];
            logicItem.setConditionTypes();
        } else if (logicItem.type === 'location_tag') {
            logicItem.operandType = 'location_tag';
            logicItem.operators = LogicOperatorFactory.getOperators(logicItem.type, this.translate);
            this.setLocationOperand(logicItem);
            logicItem.setConditionTypes();
        } else if (logicItem.type === 'location_node') {
            logicItem.operandType = 'location_node';
            logicItem.operators = LogicOperatorFactory.getOperators(logicItem.type, this.translate);
            this.setLocationOperand(logicItem);
            logicItem.setConditionTypes();
        }
    }

    private setLocationOperand(logicItem: ILogicItem): void {
        const locationQuestions: IBaseItem[] = this.surveyService.getSurvey().getLocationQuestions();
        if (locationQuestions.length) {
            const locationQuestion: IBaseItem = locationQuestions[locationQuestions.length - 1];
            logicItem.operand = locationQuestion.uuid;
        } else {
            this.disallowLocationOperator = true;
        }
    }

    /**
     * Sets the operand of the logic item to the matrix question's uuid
     *
     * @param {ILogicItem} logicItem
     */
    public selectMatrixQuestion(logicItem: ILogicItem): void {
        logicItem.operand = logicItem.question.uuid;
    }

    /**
     * Returns array of matrix questions
     *
     * @returns {Array<any>}
     */
    public getMatrixQuestions(): Array<any> {
        const items: Array<any> = [];

        for (const item of this.questions) {
            if (item.type === 'matrix') {
                for (const matrixItem of (<IMatrix>item).questions) {
                    if (matrixItem) {
                        items.push(matrixItem);
                    }
                }
            }
        }
        return items;
    }

    public getDisplayLogicItems(): Array<any> {
        return this.surveyService.getSurvey().getSectionItems([], true);
    }

    public isChildItem(item: any): boolean {
        return item instanceof BaseResponse || item instanceof MatrixQuestion;
    }

    /**
     * Add a question to the Logic's Items array
     */
    public addQuestion(): void {
        this.logic.items.push(new LogicItem());
    }

    /**
     * Removes the question from Logic's Items array
     *
     * @param {number} index
     */
    public removeQuestion(index: number): void {
        this.logic.items.splice(index, 1);
    }

    /**
     * Adds a condition to the logicItem
     *
     * @param {ILogicItem} logicItem
     * @param {string} type
     */
    public addCondition(logicItem: ILogicItem, type: string): void {
        const condition: ILogicCondition = new LogicCondition();
        condition.type = type;
        logicItem.conditions.push(condition);
    }

    /**
     *  Removes a condition from the logicItem
     * @param {ILogicItem} logicItem
     * @param {number} index
     */
    public removeCondition(logicItem: ILogicItem, index: number): void {
        logicItem.conditions.splice(index, 1);
    }

    /**
     * Cancels form creation and closes dialog
     */
    public cancel(): void {
        this.dialog.cancel();
    }

    /**
     * Build an array with the label, icon and value of the rating question (creates a readable option)
     *
     * @param {IRating} question
     * @returns {Array<any>}
     */
    public buildRatingArray(question: IRating): Array<any> {
        const ratingArray: Array<any> = [],
            ratingIcon: string = question.settings.icon,
            ratingLabel: string = question.getLabelFromIcon(ratingIcon);

        for (let i: number = 1; i <= question.settings.maxScore; i++) {
            ratingArray.push({
                label: ratingLabel,
                icon: ratingIcon,
                value: i,
            });
        }

        return ratingArray;
    }

    public canShowConditionValue(logicItem: ILogicItem, condition: ILogicCondition): boolean {
        return (
            condition.operator &&
            condition.operator !== 'empty' &&
            condition.operator !== 'nempty' &&
            condition.operator !== 'exists' &&
            condition.operator !== 'nexist' &&
            condition.type === 'input'
        );
    }

    /**
     * Submit the survey
     */
    public submit(): void {
        this.submitting = true;
        const parentItem: ISurvey | IBaseItem | ISection = this.getParentByLogicType(this.logic.type);
        const survey: ISurvey = this.surveyService.getSurvey();
        if (this.logic.type === 'end_survey') {
            if (!this.logic.settings.enableRedirect) {
                this.logic.settings.redirectUrl = null;
                this.logic.settings.redirectSeconds = null;
            }
        }
        if (this.logic.type === 'display') {
            this.logic.execution = 'value_change';
            (<IBaseItem>parentItem).logic = this.logic;
            // if we changed the parent of this logic item, null out the previous parent's logic
            if (this.currentParentItemUuid && this.currentParentItemUuid !== this.parentItemUuid) {
                survey.findAndSetLogic(this.currentParentItemUuid, null);
            }
            survey.findAndSetLogic(this.parentItemUuid, this.logic);
        } else {
            if (this.logic.type === 'go_to_section') {
                this.logic.settings = new LogicSettings();
                this.logic.settings.sectionUuid = parentItem.uuid;
            } else if (this.logic.type === 'end_survey') {
                this.logic.settings.sectionUuid = parentItem.uuid;
            }
            this.logic.execution = 'page_transition';
            let logicExists: boolean = false;
            for (let i: number = 0; i < survey.logic.length; i++) {
                const logic: ILogic = survey.logic[i];
                if (logic.uuid === this.logic.uuid) {
                    survey.logic[i] = this.logic;
                    logicExists = true;
                }
            }
            if (!logicExists) {
                survey.logic.push(this.logic);
            }
        }
        this.surveyService
            .save(survey)
            .then((newSurvey: ISurvey) => {
                this.dialog.hide(newSurvey);
                this.surveyService.setSurvey(newSurvey);
            })
            .catch(() => {
                this.logger.warn('Failed to submit Logic');
                this.toaster.error('ERRORS.FAILED_LOGIC_SUBMIT');
                this.submitting = false;
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    protected getParentByLogicType(type: string): IBaseItem | ISection | ISurvey {
        switch (type) {
            case 'display':
                return <IBaseItem>this.surveyService.getSurvey().getItemByUuid(this.parentItemUuid);
            case 'go_to_section':
                for (const section of this.surveyService.getSurvey().sections) {
                    if (section.uuid === this.parentItemUuid) {
                        return section;
                    }
                }
                break;
            case 'end_survey':
                return this.surveyService.getSurvey();
            default:
                break;
        }
    }

    protected handleContactOperandChange(logicItem: ILogicItem, field: IDefaultContactField | ICustomField): void {
        if (!field) {
            logicItem.operand = null;
            logicItem.operandType = null;
            logicItem.operators = [];
            logicItem.fieldIsDeleted = true;
            return;
        }
        logicItem.fieldIsDeleted = false;
        if (field.isDefaultField === false) {
            logicItem.operandType = 'custom_contact_field';
        } else {
            logicItem.operandType = 'default_contact_field';
        }
        logicItem.operand = field.name;
        logicItem.operators = LogicOperatorFactory.getOperators(logicItem.type, this.translate, field);
    }

    private loadCustomFields(): void {
        this.loading = true;
        this.surveyApi
            .findAllCustomFields()
            .then((fields: PagedEntities) => {
                this.customFields = fields.getEntities();
                this.setup();
            })
            .finally(() => {
                this.loading = false;
            });
    }

    private loadLocationAndLocationTags(): void {
        this.loading = true;
        this.surveyApi
            .findAllLocationNodes(this.surveyService.getSurvey().uuid)
            .then((locationNodes: ILocationNode[]) => {
                this.locationNodes = locationNodes;
                this.locationTags = this.getUniqueLocationTagsFromLocationNodes(locationNodes);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    private getUniqueLocationTagsFromLocationNodes(locationNodes: ILocationNode[]): ILocationTag[] {
        const locationTags: ILocationTag[] = locationNodes.flatMap((location) => location.tags);
        const uniqueTagsMap: Map<string, ILocationTag> = new Map<string, ILocationTag>();
        locationTags.forEach((tag) => {
            if (!uniqueTagsMap.has(tag.uuid)) {
                uniqueTagsMap.set(tag.uuid, tag);
            }
        });
        const uniqueTagsArray: ILocationTag[] = Array.from(uniqueTagsMap.values());
        return uniqueTagsArray;
    }

    private setup(): void {
        // track this if the parent is now changed (bottom part of modal was changed)
        if (this.parentItemUuid) {
            this.currentParentItemUuid = this.parentItemUuid;
        }

        if (!this.editing) {
            this.editing = false;
        }

        if (!this.logic) {
            this.logic = new Logic(this.type);
        } else if (this.logic) {
            if (this.logic && this.logic.settings) {
                this.redirect = !!this.logic.settings.redirectUrl;
            }
            for (const item of this.logic.items) {
                if (item.type === 'question') {
                    const question: any = this.surveyService.getSurvey().getItemByUuid(item.operand);

                    // specific to matrix questions
                    if (question.type === 'question') {
                        item.question = this.surveyService.getSurvey().getItemParentByUuid(item.operand);
                    } else {
                        item.question = question;
                    }
                    if (item.operandType === 'rating') {
                        const surveyItem: IRating = <IRating>this.surveyService.getSurvey().getItemByUuid(item.operand);
                        item.selectionArray = this.buildRatingArray(surveyItem);
                    }
                    item.operators = LogicOperatorFactory.getOperators(item.question.type, this.translate);

                    if (question.type === 'calendar') {
                        for (const condition of item.conditions) {
                            condition.value = new Date(<string>condition.value);
                        }
                    }
                } else if (item.type === 'metadata') {
                    item.operators = LogicOperatorFactory.getOperators(item.type, this.translate);
                } else if (item.type === 'location_tag') {
                    item.operators = LogicOperatorFactory.getOperators(item.type, this.translate);
                } else if (item.type === 'location_node') {
                    item.operators = LogicOperatorFactory.getOperators(item.type, this.translate);
                } else if (item.type === 'contact') {
                    if (item.operandType === 'custom_contact_field') {
                        item.contactField = _.find(this.customFields, { name: item.operand });
                    } else {
                        item.contactField = _.find(this.defaultFields, { name: item.operand });
                    }
                    this.handleContactOperandChange(item, item.contactField);
                    item.operators = LogicOperatorFactory.getOperators(item.type, this.translate, item.contactField);
                }

                item.setConditionTypes();
            }
        }

        if (this.logic.hasCustomMessage()) {
            this.hasCustomMessage = true;
        }

        for (const section of this.surveyService.getSurvey().getSections()) {
            const questions: Array<IBaseItem | IBaseResponseItem> = section.getItems(
                ['image', 'text', 'group', 'video', 'location', 'ranking', 'captcha'],
                false,
                true
            );
            if (questions && questions.length > 0) {
                this.sections.push({
                    label: section.label,
                    questions: questions,
                });
            }
        }

        this.displayLogicItems = this.getDisplayLogicItems();
    }

    private setupFroala(): void {
        this.froalaOptions = FroalaProvider.defaultConfig(this.translate.instant('SURVEYS.ITEMS.TEXT.TYPE_SOMETHING'));

        if (this.config.froala && this.config.froala.key) {
            this.froalaOptions.key = this.config.froala.key;
        } else {
            this.logger.warn('Unable to set Froala key');
        }

        this.froalaOptions.pluginsEnabled.push('image');
        this.froalaOptions.toolbarButtons.splice(3, 0, 'insertImage');
        this.froalaOptions.imageUpload = true;
        this.froalaOptions.imageAllowedTypes = ['jpg', 'jpeg', 'png'];

        const self: this = this;
        this.froalaOptions.events = {
            'image.beforeUpload': function (images: Array<any>): boolean {
                const file: any = images[0];
                self.photoService
                    .parse(file, null, null, false)
                    .then((parsedImage: IParsedImage) => {
                        self.surveyApi
                            .addPhoto(parsedImage.base64)
                            .then((result) => {
                                this.image.insert(result.data.large);
                            })
                            .finally(() => {
                                this.image.hideProgressBar(true); // close froala upload progress bar
                            });
                    })
                    .catch((error) => {
                        self.toaster.error('ERRORS.PHOTO_NOT_ADDED', error);
                    });
                return false; // cancels progression of froala upload path
            },
        };
        this.froalaOptions = FroalaProvider.convertOptionsToV4(this.froalaOptions);
    }
}
