import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', ImageUploadRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/image-upload/ImageUploadRenderComponent.html'),
    controllerAs: 'vm',
    bindings: ImageUploadRenderComponent.getBindings(),
})
class ImageUploadRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'ImageUploadRenderComponent';
    static CID: string = 'itsImageUploadRender';
    static $inject: string[] = [];
}
