import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { ISurveyApi } from '../../../api/SurveyApi';
import { IContactList } from '../../../domain/contact-center/ContactList';
import { ISurvey } from '../../../domain/surveys/survey/Survey';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { IEmailDistribution } from '../../../domain/contact-center/EmailDistribution';
import { LogicOperatorFactory } from '../../../domain/surveys/logic/LogicOperatorFactory';
import { IDistributionTriggerCondition } from '../../../domain/contact-center/DistributionTriggerCondition';
import { ISchedulerData } from '@intouch/its.essential/app/essential/components/Scheduler';
import moment_tz = require('moment-timezone');
import { ISmsDistribution } from '../../../domain/contact-center/SmsDistribution';
import { IDistributeSurveyRequest } from '../../../domain/contact-center/DistributeSurveyRequest';
import { IContact } from '../../../domain/contact-center/Contact';
import { ISurveyService } from '../../../services/SurveyService';
import ClipboardJS from 'clipboard/dist/clipboard';
import * as angular from 'angular';
import ContactListDistributeSurveyModalHtml from './ContactListDistributeSurveyModal.html';

/**
 * A class to allow manually distributing a survey to a contact list
 */
export class ContactListDistributeSurveyModal extends Modal {
    static $inject: Array<string> = [
        'itsSurveyApi',
        'iteLogger',
        '$mdDialog',
        'iteToaster',
        '$translate',
        '$window',
        '$scope',
        '$state',
        'itsSurveyService',
        '$window',
    ];

    protected selectedSurveyOriginalUuid: string = null;
    protected selectedSurveyEmailUuid: string = null;
    protected selectedSurveySmsUuid: string = null;
    protected hasError: boolean = false;
    protected loading: boolean = true;
    protected emails: Array<IEmailDistribution> = [];
    protected sms: Array<ISmsDistribution> = [];
    protected pager: IPager = new Pager();
    public contactList: IContactList;
    public contact: IContact;
    public submitting: boolean = false;
    public loadingContactCount: boolean = false;
    public subscriberCount: number = null;
    public selectedSurvey: ISurvey = null;
    public schedulerData: ISchedulerData = { sendAtDate: null, sendAtDateFormatted: null, sendAtDateTimezone: null };
    public showDatepicker: boolean = false;
    public showSendAtDateError: boolean = false;
    public selectedDistributionType: string = null;

    private windowWatcher: any = null;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = ContactListDistributeSurveyModalHtml;
        config.controller = ContactListDistributeSurveyModal;

        return super.instantiate(config);
    }

    public constructor(
        private api: ISurveyApi,
        private logger: ILogger,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private translate: ng.translate.ITranslateService,
        private windowService: ng.IWindowService,
        private scope: ng.IScope,
        private stateService: ng.ui.IStateService,
        private surveyService: ISurveyService,
        private window: ng.IWindowService
    ) {
        super();

        if (this.contactList) {
            this.loadContactCount();
        }

        const clipboard: ClipboardJS = new ClipboardJS('#triggerBtn');
        clipboard.on('success', () => {
            this.toaster.info(this.translate.instant('SURVEYS.THEMES.COPIED_URL_TO_CLIPBOARD'));
        });

        const reloadOnFocus: () => void = () => {
            if (this.selectedSurvey) {
                this.handleSurveySelect(this.selectedSurvey);
            }
        };

        this.windowWatcher = angular.element(this.windowService);
        if (this.windowWatcher) {
            this.windowWatcher.on('focus', reloadOnFocus);

            scope.$on('$destroy', () => {
                this.windowWatcher.off('focus', reloadOnFocus);
            });
        }
    }

    /**
     * Allow a user to search for a survey to distribute
     *
     * @param {string} search
     * @param {IPager} pager
     * @returns {angular.IPromise<any>}
     */
    public searchSurveys(search: string, pager: IPager): ng.IPromise<any> {
        const filters: string = 'filter[active]=true&filter[status]=published';

        return this.api.findSurveys(pager, search, filters);
    }

    /**
     * Display a survey in a string format
     *
     * @param {ISurvey} survey
     * @returns {string}
     */
    public displaySurvey(survey: ISurvey): string {
        return survey ? survey.name : '';
    }

    /**
     * The on-change handler for the survey selection
     * @param {ISurvey} survey
     */
    public handleSurveySelect(survey: ISurvey): void {
        // clear out the current selections
        this.selectedSurvey = survey;
        this.selectedSurveyOriginalUuid = survey.originalUuid;
        this.selectedDistributionType = null;
        this.resetExistingSelections();
    }

    public handleDistributionTypeSelected(): void {
        this.resetExistingSelections();

        if (this.selectedDistributionType === 'email') {
            // load the emails
            this.loadSurveyEmails();
        } else if (this.selectedDistributionType === 'sms') {
            // load the sms
            this.loadSurveySms();
        }
    }

    /**
     * Submit survey and email to distribute to the API
     */
    public submit(form: ng.IFormController): void {
        this.showSendAtDateError = false;
        this.submitting = true;

        if (this.schedulerData.sendAtDate) {
            // compare the selected send date w/ current time
            if (this.sendAtDateTimeHasPast()) {
                this.showSendAtDateError = true;
                this.submitting = false;
                return;
            }
        }

        // are we distributing the Survey to a Contact or a Contact List?
        if (!this.contactList && !!this.contact) {
            this.distributeSurveyToContact();
        } else {
            this.distributeSurveyToContactList();
        }
    }

    public nameFallback(emailOrSmsDistribution: IEmailDistribution | ISmsDistribution): string {
        if (
            emailOrSmsDistribution &&
            emailOrSmsDistribution.triggers.length > 0 &&
            emailOrSmsDistribution.triggers[0].conditions.length > 0
        ) {
            const condition: IDistributionTriggerCondition = emailOrSmsDistribution.triggers[0].conditions[0];
            return (
                condition.operand +
                ' ' +
                LogicOperatorFactory.getSimpleOperatorLabel(condition.operator, this.translate) +
                ' ' +
                condition.value
            );
        }

        return emailOrSmsDistribution.uuid;
    }

    /**
     * Opens the survey in a new tab
     */
    public openLink(contact?: IContact): void {
        this.window.open(this.getSurveyUrl(), '_blank');
    }

    /**
     * Manually reset custom validation state
     *
     * @param {angular.INgModelController} element
     * @param {string} key
     */
    public resetValidity(element: ng.INgModelController, key: string): void {
        element.$setValidity(key, true);
    }

    /**
     * Cancels form creation and closes dialog
     */
    public cancel(): void {
        this.dialog.cancel();
    }

    public isEmptyState(): boolean {
        return (
            !this.loading &&
            this.selectedSurveyOriginalUuid &&
            this.selectedDistributionType &&
            ((this.selectedDistributionType === 'email' &&
                !this.selectedSurveyEmailUuid &&
                (!this.emails || this.emails.length === 0)) ||
                (this.selectedDistributionType === 'sms' &&
                    !this.selectedSurveySmsUuid &&
                    (!this.sms || this.sms.length === 0)))
        );
    }

    public handleShowDatepicker(): void {
        this.showSendAtDateError = false;

        if (this.showDatepicker) {
            this.resetSchedulerData();
        }
    }

    public createDistribution(): void {
        if (this.selectedDistributionType === 'sms') {
            this.stateService.go('home.surveys.edit.distributions.sms', { uuid: this.selectedSurvey.uuid });
        } else if (this.selectedDistributionType === 'email') {
            this.stateService.go('home.surveys.edit.distributions.email', { uuid: this.selectedSurvey.uuid });
        } else {
            this.stateService.go('home.surveys.edit.distributions.link', { uuid: this.selectedSurvey.uuid });
        }
    }

    /**
     * Retrieves the public facing URL of the survey
     *
     * @returns {string}
     */
    public getSurveyUrl(): string {
        return (
            this.surveyService.getSurveyUrl(this.selectedSurvey) +
            '/' +
            this.selectedSurveyOriginalUuid +
            '/p/' +
            this.contact.uuid
        );
    }

    private resetExistingSelections(): void {
        this.selectedSurveyEmailUuid = null;
        this.selectedSurveySmsUuid = null;
        this.sms = [];
        this.emails = [];
        this.showDatepicker = false;
        this.resetSchedulerData();
    }

    private resetSchedulerData(): void {
        this.schedulerData.sendAtDate = null;
        this.schedulerData.sendAtDateFormatted = null;
        this.schedulerData.sendAtDateTimezone = null;
    }

    private loadContactCount(): void {
        this.loadingContactCount = true;
        this.subscriberCount = null;

        const filter: string = 'filter[contact_lists.uuid]=' + this.contactList.uuid + '&filter[status]=subscribed';
        this.api
            .findContacts(Pager.make(1, 'email', 'asc', 1), null, filter)
            .then((result: PagedEntities) => {
                this.subscriberCount = result.getPager().total;
            })
            .finally(() => {
                this.loadingContactCount = false;
            });
    }

    /**
     * Make the API call to distribute the selected survey email to the current contact list
     */
    private distributeSurveyToContactList(): void {
        const emailOrSmsUuid: string = this.emailOrSmsUuid();

        const requestData: IDistributeSurveyRequest = {
            contactListUuid: this.contactList.uuid,
            surveyOriginalUuid: this.selectedSurveyOriginalUuid,
            distributionType: this.selectedDistributionType,
            emailOrSmsUuid: emailOrSmsUuid,
            sendAtDateFormatted: this.schedulerData.sendAtDateFormatted,
            sendAtDateTimezone: this.schedulerData.sendAtDateTimezone,
        };

        this.api
            .distributeSurveyToContactList(requestData)
            .then((result: any) => {
                const returnData: any = {
                    contactsNotified: result.contacts_notified,
                };
                this.dialog.hide(returnData);
                this.toaster.info(
                    this.translate.instant('GENERAL.SURVEY_DISTRIBUTED', { item: returnData.contactsNotified })
                );
            })
            .catch((error: IErrorResponse) => {
                // if there are more errors, change if/else to switch
                if (error && error.type && error.type === 'ContactListHasNoSubscribersException') {
                    this.toaster.error('ERRORS.CONTACT_LIST_HAS_NO_SUBSCRIBERS');
                } else {
                    this.toaster.error('ERRORS.CONTACT_CENTER.FAILED_TO_DISTRIBUTE_SURVEY');
                }
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    private distributeSurveyToContact(): void {
        const emailOrSmsUuid: string = this.emailOrSmsUuid();

        this.api
            .distributeSurveyToContact(
                this.contact.uuid,
                this.selectedSurveyOriginalUuid,
                this.selectedDistributionType,
                emailOrSmsUuid
            )
            .then((result: any) => {
                const returnData: any = {
                    contactsNotified: result.distributed === true ? 1 : 0,
                };
                this.dialog.hide(returnData);
                this.toaster.info(
                    this.translate.instant('GENERAL.SURVEY_DISTRIBUTED', { item: returnData.contactsNotified })
                );
            })
            .catch((error: IErrorResponse) => {
                // if there are more errors, change if/else to switch
                if (error && error.type && error.type === 'CannotDistributeSurveyToContactException') {
                    this.toaster.error('ERRORS.CANNOT_DISTRIBUTE_SURVEY_TO_CONTACT');
                } else {
                    this.toaster.error('ERRORS.CONTACT_CENTER.FAILED_TO_DISTRIBUTE_SURVEY');
                }
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    private emailOrSmsUuid(): string {
        if (this.selectedDistributionType === 'email') {
            return this.selectedSurveyEmailUuid;
        } else if (this.selectedDistributionType === 'sms') {
            return this.selectedSurveySmsUuid;
        } else {
            return null;
        }
    }

    /**
     * Compare the send at date/time w/ the current date/time to ensure it is in the future
     *
     * @return boolean
     */
    private sendAtDateTimeHasPast(): boolean {
        const now: number = moment_tz().toDate().getTime();
        const sendAt: number = moment_tz
            .tz(
                moment_tz(this.schedulerData.sendAtDate).format('YYYY-MM-DD HH:mm:ss'),
                this.schedulerData.sendAtDateTimezone
            )
            .utc()
            .toDate()
            .getTime();

        return now > sendAt;
    }

    private loadSurveyEmails(): void {
        this.loading = true;
        let emailsPager: IPager = Pager.make(1, 'created_at', 'asc');

        // load the survey emails for the selected survey
        this.api
            .getSurveyEmails(this.selectedSurvey.originalUuid, emailsPager)
            .then((emailResponse: PagedEntities) => {
                this.loading = false;
                this.emails = emailResponse.getEntities();
                emailsPager = emailResponse.getPager();
                this.selectedSurveyOriginalUuid = this.selectedSurvey.originalUuid;
            })
            .catch(() => {
                this.hasError = true;
                this.toaster.error('ERRORS.FAILED_EMAIL_LOAD');
            });
    }

    private loadSurveySms(): void {
        this.loading = true;
        let smsPager: IPager = Pager.make(1, 'created_at', 'asc');

        // load the survey sms for the selected survey
        this.api
            .getSurveySmsList(this.selectedSurvey.originalUuid, smsPager)
            .then((smsResponse: PagedEntities) => {
                this.loading = false;
                this.sms = smsResponse.getEntities();
                smsPager = smsResponse.getPager();
                this.selectedSurveyOriginalUuid = this.selectedSurvey.originalUuid;
            })
            .catch(() => {
                this.hasError = true;
                this.toaster.error('ERRORS.FAILED_SMS_LOAD');
            });
    }
}
