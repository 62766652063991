import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import SendTestSmsModalHtml from './SendTestSmsModal.html';
export class SendTestSmsModal extends Modal {
    static $inject: Array<string> = [
        'iteLogger',
        '$translate',
        'itsSurveyApi',
        'iteToaster',
        '$mdDialog',
        'iteAccessService',
    ];

    protected smsMessage: string;
    protected surveyOriginalUuid: string;
    protected loading: boolean = true;
    protected sending: boolean = false;
    protected canSendTestSms: boolean;
    protected toPhone: string;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = SendTestSmsModalHtml;
        config.controller = SendTestSmsModal;

        return super.instantiate(config);
    }

    /**
     * @param {ILogger} logger
     * @param {ng.translate.ITranslateService} translate
     * @param {ISurveyApi} surveyApi
     * @param {IToaster} toaster
     * @param {ng.material.IDialogService} dialog
     * @param {IAccessService} accessService
     */
    public constructor(
        private logger: ILogger,
        private translate: ng.translate.ITranslateService,
        private surveyApi: ISurveyApi,
        private toaster: IToaster,
        private dialog: ng.material.IDialogService,
        private accessService: IAccessService
    ) {
        super();

        this.canSendTestSms = this.accessService.getToken().getUser().isAdmin();
    }

    /**
     * Sends a test SMS
     */
    public sendTestSms(): void {
        this.sending = true;
        this.surveyApi
            .sendTestSurveySms(this.surveyOriginalUuid, this.smsMessage, this.toPhone)
            .then((response) => {
                this.dialog.hide();
                this.toaster.success(this.translate.instant('DISTRIBUTIONS.SMS.TEST_SMS_SENT_SUCCESSFULLY'));
            })
            .catch(() => {
                this.toaster.error(this.translate.instant('ERRORS.FAILED_TEST_SMS_SEND'));
            })
            .finally(() => {
                this.sending = false;
            });
    }

    /**
     * Cancels form creation and closes dialog
     */
    public close(): void {
        this.dialog.hide();
    }
}
