import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { String } from '@intouch/its.essential/app/essential/utils/String';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { ITranslation, Translation } from '../Translation';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { ISkip, Skip } from './Skip';
import { TranslationLocator } from '../TranslationLocator';
import { ILogic, Logic } from '../logic/Logic';
import { IBaseResponse } from './BaseResponse';
import { IWeightedTag } from './shared/WeightedTag';
import { ILogicReference, LogicOwner, LogicReference } from '../logic/LogicReference';
import { BaseItemJS } from './BaseItemJS';
import { IHealthPoints } from './IHealthPoints';
import { ITranslatedEntity } from '../../ITranslatedEntity';

export type ItemType =
    | 'audio'
    | 'calendar'
    | 'captcha'
    | 'checkbox'
    | 'group'
    | 'image'
    | 'image_upload'
    | 'input'
    | 'location'
    | 'matrix'
    | 'nps'
    | 'number'
    | 'radio'
    | 'ranking'
    | 'rating'
    | 'scale'
    | 'select'
    | 'slider'
    | 'text'
    | 'textarea'
    | 'video'
    | 'video_input'
    | 'section';

/**
 * A form user object interface
 */
export interface IBaseItem extends IEntity {
    uuid: string;
    label: string;
    disabled: boolean;
    translations: Array<ITranslation>;
    type: string;
    required: boolean;
    skip: ISkip;
    logic: ILogic;
    settings: IBaseSettings;
    accessTags: Array<IWeightedTag>;
    healthPoints: number;

    // not enumerable
    logicReferences: Array<ILogicReference>;
    $$showSettings: boolean;

    getTranslation(locale: string, createOnNotFound?: boolean): ITranslation;
    initialize(translate?: ng.translate.ITranslateService): void;
    buildLabelMap(): any;
    isValidScoringItemType(): boolean;
    isTaggable(): boolean;
    getLogicItems(): Array<IBaseItem | IBaseResponse>;
    updateTagWeight(tagUuid: string, weight: number): void;
    buildTagMap(tagObj: { [uuid: string]: IWeightedTag }): void;
    findTagByUuid(tagUuid: string): IWeightedTag;
    getAllLogicItemBelongsTo(itemUuid: string): Array<ILogicReference>;
    duplicate();
    getTranslationKeys(): Array<string>;
}

/**
 * A form user object
 */
export class BaseItem extends Entity implements IBaseItem, IHealthPoints, ITranslatedEntity {
    uuid: string = String.uuid();
    label: string = null;
    disabled: boolean = false;
    translations: Array<ITranslation> = [];
    type: string = null;
    required: boolean = true;
    skip: ISkip = null;
    logic: ILogic = null;
    settings: IBaseSettings = new BaseSettings();
    accessTags: Array<IWeightedTag> = [];

    @NotEnumerable
    editingLabel: boolean = false;
    @NotEnumerable
    editIcon: boolean = false;
    @NotEnumerable
    editedLabel: string = null;
    @NotEnumerable
    originalLabel: string = null;
    @NotEnumerable
    $$showSettings: boolean = false;
    @NotEnumerable
    logicReferences: Array<ILogicReference> = [];
    @NotEnumerable
    _healthPoints: number = 1;

    // value is strictly used to pre-populate rendered default selections
    @NotEnumerable
    value: any;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey.
     */
    get healthPoints(): number {
        return this._healthPoints;
    }

    /**
     * Returns if the item label can contain a token which can reference
     * another item's response (items which can override this)
     */
    get hasTokenReference(): boolean {
        return false;
    }

    /**
     * Build the base skip item from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IBaseItem}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ITranslation>(Translation, jsonObject.translations, true);
        }

        if (jsonObject.skip) {
            this.skip = EntityBuilder.buildOne<ISkip>(Skip, jsonObject.skip, true);
        }

        if (jsonObject.logic) {
            this.logic = EntityBuilder.buildOne<ILogic>(Logic, jsonObject.logic, true);
        }

        if (jsonObject.access_tags) {
            this.accessTags = jsonObject.access_tags;
        }

        return this;
    }

    /**
     * Duplicate current object and set a new UUID
     */
    public duplicate(): BaseItem {
        const dupe: BaseItem = <BaseItem>this.clone();
        dupe.uuid = String.uuid();
        dupe.logic = null;
        dupe.logicReferences = [];
        dupe.settings.customJavascript = new BaseItemJS();
        return dupe;
    }

    /**
     * Creates a string map of uuid and label
     *
     * @returns {any}
     */
    public buildLabelMap(): any {
        const mapObj: any = {};

        mapObj[this.uuid] = this.label;

        return mapObj;
    }

    /**
     * Returns if the uuid is used in defined logic
     *
     * @param itemUuid
     */
    public getAllLogicItemBelongsTo(itemUuid: string): Array<ILogicReference> {
        const allLogic: Array<ILogicReference> = [];
        if (this.logic) {
            for (const logicItem of this.logic.items) {
                if (logicItem.operand === itemUuid) {
                    allLogic.push(new LogicReference(new LogicOwner(this.uuid, this.label), this.logic));
                }
                for (const logicCondition of logicItem.conditions) {
                    if (logicCondition.value === itemUuid) {
                        allLogic.push(new LogicReference(new LogicOwner(this.uuid, this.label), this.logic));
                    }
                }
            }
        }

        return allLogic;
    }

    /**
     * Get logic in form of an array
     *
     * @returns {Array<IBaseItem|IBaseResponse>}
     */
    public getLogicItems(): Array<IBaseItem | IBaseResponse> {
        const logicArr: Array<IBaseItem | IBaseResponse> = [];
        if (this.logic) {
            logicArr.push(this);
        }
        return logicArr;
    }

    /**
     * Function used for initializing anything we might need on item creation when
     * toggling to an item type
     */
    public initialize(translate?: ng.translate.ITranslateService): void {
        // override me!
    }

    /**
     * Removes logic
     */
    public removeLogic(): void {
        this.logic = null;
    }

    /**
     * Retrieve the given translation for a locale
     *
     * @param {string} locale
     * @param {boolean} createOnNotFound
     * @returns {ITranslation}
     */
    public getTranslation(locale: string, createOnNotFound: boolean = true): ITranslation {
        return TranslationLocator.locate(this.translations, 'label', locale);
    }

    /**
     * Returns true if the type is a valid scoring item type
     *
     */
    public isValidScoringItemType(): boolean {
        return ['radio', 'matrix', 'select', 'group', 'rating', 'slider', 'scale', 'nps'].indexOf(this.type) > -1;
    }

    /**
     * Returns if the type is a taggable item type.
     *
     */
    public isTaggable(): boolean {
        return ['image', 'text', 'video'].indexOf(this.type) === -1;
    }

    /**
     * Updates the item's tag weight by tag uuid
     *
     * @param tagUuid
     * @param weight
     */
    public updateTagWeight(tagUuid: string, weight: number): void {
        for (const tag of this.accessTags) {
            if (tag.uuid === tagUuid) {
                tag.weight = weight;
            }
        }
    }

    /**
     * Adds the tags contained on this item to a tag map
     *
     * @param tagObj
     */
    public buildTagMap(tagObj: { [uuid: string]: IWeightedTag }): void {
        for (const tag of this.accessTags) {
            if (!tagObj.hasOwnProperty(tag.uuid)) {
                tagObj[tag.uuid] = tag;
            }
        }
    }

    /**
     * Find a tag by uuid
     *
     * @param tagUuid
     */
    public findTagByUuid(tagUuid: string): IWeightedTag {
        for (const tag of this.accessTags) {
            if (tag.uuid === tagUuid) {
                return tag;
            }
        }
    }

    /**
     * Returns all of the translation keys the item can have
     */
    public getTranslationKeys(): Array<string> {
        return ['label'];
    }
}
