import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface IDefaultContactField extends IEntity {
    modelName: string;
    name: string;
    label: string;
    type: string;
    required: boolean;
    isDefaultField: boolean;
}

export class DefaultContactField extends Entity implements IDefaultContactField {
    public modelName: string = null;
    public name: string = null;
    public label: string = null;
    public type: string = null;
    public required: boolean = true;
    public isDefaultField: boolean = true;

    public static make(settings: any): IDefaultContactField {
        const me: IDefaultContactField = new DefaultContactField();

        if (settings) {
            if (settings.hasOwnProperty('modelName')) {
                me.modelName = settings.modelName;
            }

            if (settings.hasOwnProperty('name')) {
                me.name = settings.name;
            }

            if (settings.hasOwnProperty('label')) {
                me.label = settings.label;
            }

            if (settings.hasOwnProperty('type')) {
                me.type = settings.type;
            }

            if (settings.hasOwnProperty('required')) {
                me.required = settings.required;
            }

            if (settings.hasOwnProperty('isDefaultField')) {
                me.isDefaultField = settings.isDefaultField;
            }
        }

        return me;
    }
}
