import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IUxStateConfig } from '@intouch/its.essential/app/essential/components/state/UxStateConfig';
import { ISurvey } from '../../domain/surveys/survey/Survey';
import { ISurveyApi } from '../../api/SurveyApi';
import { IQuestion } from '@intouch/its.essential/app/essential/domain/programs/Question';
import { IIql, Iql } from '@intouch/iql-ts-sdk/src/domain/Iql';
import { IFilterGroup, FilterGroup } from '@intouch/iql-ts-sdk/src/domain/filters/FilterGroup';
import { Filter } from '@intouch/iql-ts-sdk/src/domain/filters/Filter';
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import { IIqlQueryResult, IIqlQueryResultSeriesData } from '@intouch/iql-ts-sdk/src/domain/IqlQueryResult';
import { IqlQueryResultSeriesAdapter } from '@intouch/iql-ts-sdk/src/domain/adapters/IqlQueryResultSeriesAdapter';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import { IProgramsApi } from '@intouch/its.essential/app/essential/api/ProgramsApi';
import { IProgram } from '@intouch/its.essential/app/essential/domain/programs/Program';
import { TranslationUtils } from '@intouch/its.essential/app/essential/utils/TranslationUtils';
import { IqlDataTranslatorFactory } from '@intouch/its.essential/app/essential/domain/translation/IqlDataTranslatorFactory';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IconUtils } from '@intouch/its.essential/app/essential/utils/IconUtils';
import * as _ from 'lodash';
import { IQueryFilter, QueryFilter } from '@intouch/its.essential/app/essential/domain/api/QueryFilter';
import { IFilterService } from '@intouch/its.essential/app/essential/domain/filters/FilterService';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { PageService } from '../../services/PageService';

interface ILoadingState {
    loading: boolean;
    error: boolean;
    errorMessage?: string;
}

interface IResponseData {
    label: string;
    records: number;
    recordsPercentage: number;
    value?: any;
}

interface IQuestionData {
    question: IQuestion;
    state: ILoadingState;
    label: string;
    score: number;
    records: number;
    responses: Array<IResponseData>;
    displayLimit?: number;
}

interface IQuestionResultsState {
    loading: boolean;
    questions: Array<IQuestion>;
    questionsRemaining: number;
    questionResults: Array<IQuestionData>;
}

interface IRecordSingleValues {
    [id: string]: {
        value: number;
        isEmpty: boolean;
        diff?: number;
    };
}

interface IScoreData {
    label: string;
    value: number;
}

@Controller('its.survey.module.surveys', ResultsController.IID, ResultsController)
class ResultsController {
    static IID: string = 'its.survey.module.surveys.ResultsController';
    static $inject: Array<string> = [
        '$translate',
        '$state',
        'itsSurveyApi',
        'iteIqlApi',
        '$q',
        'iteProgramsApi',
        'iteAccessService',
        '$mdMedia',
        'APPCONFIG',
        'iteIqlRecordDateOfDocumentFilterService',
        '$scope',
        'itsPageService',
    ];

    public survey: ISurvey = null;
    public loading: boolean = false;
    public error: boolean = false;
    public empty: boolean = false;
    public mobileBreakPoint: string = 'gt-sm';
    public colorThresholds: any = { 'its-primary-color-bg': { start: 0 } };

    public program: IProgram = null;
    public questionResultsState: IQuestionResultsState = null;
    public sectionScoreData: Array<IScoreData> = [];
    public tagScoreData: Array<IScoreData> = [];
    public recordSingleValues: IRecordSingleValues = {};
    public questionScores: any = {};
    public translations: any = {};

    public questionEmptyStateConfig: IUxStateConfig = {
        imageUrl: 'assets/images/essential/branding/survey_empty.png',
        heading: this.translate.instant('GENERAL.NO_RESPONSES') + '!',
        content: 'ERRORS.NO_RESPONSES_FOR_QUESTION_YET',
    };

    public pageEmptyStateConfig: IUxStateConfig = {
        imageUrl: 'assets/images/essential/branding/survey_empty.png',
        heading: this.translate.instant('GENERAL.NO_RESPONSES') + '!',
        content: 'ERRORS.SURVEY_NO_RESULTS_IN_TIMEFRAME_YET',
    };

    private surveyUuid: string = null;
    private locale: string = null;

    private hasSentiment: boolean = false;
    private userHasIq: boolean = false;
    private userNodes: Array<string> = [];

    constructor(
        private translate: ng.translate.ITranslateService,
        private state: ng.ui.IStateService,
        private surveyApi: ISurveyApi,
        private iqlApi: IIqlApi,
        private q: ng.IQService,
        private programsApi: IProgramsApi,
        private accessService: IAccessService,
        public mdMedia: any,
        public appConfig: any,
        private dateFilterService: IFilterService,
        private scope: ng.IScope,
        private pageService: PageService
    ) {
        this.translate(['GENERAL.NO_RESPONSES'])
            .then((translations) => {
                this.translations = translations;
            })
            .finally(() => {
                this.surveyUuid = this.state.params['uuid'];
                this.locale = this.accessService.getUser().language;
                this.userHasIq = this.checkForIqProduct();
                this.userNodes = this.accessService.getUser().nodes;
                const subscriber: SubjectSubscription = this.dateFilterService.filterObserver.subscribe(() => {
                    this.load();
                });
                subscriber.unsubscribeOnScopeDestruction(this.scope);
            });
    }

    public viewSurveyDashboard(): void {
        const url: string =
            this.appConfig.products.urls.intelligence.app +
            this.accessService.getToken().getRawToken() +
            '?redirect=/analytics/standard/survey/' +
            this.survey.originalUuid;
        window.open(url, '_blank');
    }

    public viewSentimentDashboard(): void {
        const url: string =
            this.appConfig.products.urls.intelligence.app +
            this.accessService.getToken().getRawToken() +
            '?redirect=/analytics/standard/sentiment';
        window.open(url, '_blank');
    }

    public showContent(): boolean {
        return !!this.survey && !this.empty && !this.loading && !this.error;
    }

    public showQuestionEmptyState(result: IQuestionData): boolean {
        return !result.responses || result.responses.length === 0;
    }

    public getQuestionIcon(question: IQuestion): string {
        return IconUtils.getProgramQuestionIcon(question);
    }

    public showSentimentCard(): boolean {
        return this.hasSentiment && this.userHasIq;
    }

    public getNpsClass(questionType: string, response: IResponseData): string {
        if (questionType !== 'nps') {
            return undefined;
        }

        switch (response.value) {
            case 'detractor':
                return 'its-danger-fill';
            case 'passive':
                return 'its-warning-fill';
            case 'promoter':
                return 'its-positive-fill';
            default:
                return undefined;
        }
    }

    public showRightContent(): boolean {
        return (
            this.showContent() &&
            (this.hasRecordSingleValues() ||
                this.hasScoreSingleValue() ||
                this.hasSectionScoreData() ||
                this.hasTagScoreData())
        );
    }

    public hasRecordSingleValues(): boolean {
        return (
            !!this.recordSingleValues &&
            !!this.recordSingleValues['records'] &&
            !this.recordSingleValues['records'].isEmpty
        );
    }

    public hasScoreSingleValue(): boolean {
        return (
            !!this.recordSingleValues && !!this.recordSingleValues['score'] && !this.recordSingleValues['score'].isEmpty
        );
    }

    public hasTagScoreData(): boolean {
        return !!this.tagScoreData && this.tagScoreData.length > 0;
    }

    public hasSectionScoreData(): boolean {
        return !!this.sectionScoreData && this.sectionScoreData.length > 0;
    }

    public loadMoreOnScroll(): ng.IPromise<any> {
        return this.triggerLoadMoreQuestions();
    }

    private load(): ng.IPromise<any> {
        this.loading = true;
        this.error = false;
        this.empty = false;
        this.initializeData();
        return this.loadSurvey()
            .then(() => {
                return this.loadData().finally(() => {
                    this.loading = false;
                    this.setEmptyStateCtas();
                    this.empty = this.getEmptyState();
                });
            })
            .catch((e) => {
                this.error = true;
                this.loading = false;
            });
    }

    private loadData(): ng.IPromise<any> {
        return this.q
            .all([
                this.loadProgram(),
                this.loadQuestionScores(),
                this.loadScoreBySection(),
                this.loadScoreByTag(),
                this.loadAverageScoreAndRecordsReceivedCharts(),
            ])
            .then(() => {
                this.questionResultsState.questions = this.getProgramQuestions();
                this.questionResultsState.questionsRemaining = this.questionResultsState.questions.length;
                this.hasSentiment = this.checkOrgSentiment() && this.checkProgramForSentiment();
                return this.triggerLoadMoreQuestions();
            });
    }

    private getProgramQuestions(): Array<IQuestion> {
        return this.program.findQuestions(
            (question: IQuestion) => {
                return (
                    !question.disabled &&
                    [
                        'checkbox',
                        'radio',
                        'select',
                        'nps',
                        'scale',
                        'ranking',
                        'slider',
                        'rating',
                        'dropdown',
                        'question', // matrix sub question
                    ].indexOf(question.type) > -1
                );
            },
            true,
            false
        );
    }

    private triggerLoadMoreQuestions(): ng.IPromise<any> {
        if (!this.questionResultsState.loading) {
            this.questionResultsState.loading = true;
            return this.loadMoreQuestions().finally(() => {
                this.questionResultsState.loading = false;
            });
        } else {
            return this.q.resolve();
        }
    }

    private getEmptyState(): boolean {
        if (this.questionResultsState.questions.length > 0) {
            for (const result of this.questionResultsState.questionResults) {
                if (result.responses.length > 0) {
                    return false;
                }
            }
        }

        return true;
    }

    /**
     * Load the program with Essential's Programs API. We do this because it gives us back a format that works better
     * with the analytics requirements.
     * @private
     */
    private loadProgram(): ng.IPromise<any> {
        if (!this.survey) {
            return this.q.reject('No survey');
        }
        return this.programsApi
            .findProgramByUuid('survey', this.survey.originalUuid, this.getProgramFilters(), false)
            .then((result: IProgram) => {
                this.program = result;
            });
    }

    private getLoadQuestionScoresIql(): IIql {
        return this.setContextFilters(new Iql())
            .metric('data.questions.*->score.percentage', Iql.SCORE, Iql.AVERAGE, 'score')
            .dimension('data.questions.*->field_name', Iql.STRING, 'text')
            .filter(
                new FilterGroup(Iql.AND, [
                    new Filter('data.questions.*->score.percentage', Iql.GREATER_THAN_EQUAL, 0, Iql.NUMBER),
                ])
            );
    }

    private loadQuestionScores(): ng.IPromise<any> {
        if (!this.survey) {
            return this.q.reject('No Survey');
        }

        return this.iqlApi
            .execute(this.getLoadQuestionScoresIql(), null, { name: 'question-scores' })
            .then((response: IIqlQueryResult) => {
                this.setQuestionScores(response);
            });
    }

    private setQuestionScores(result: IIqlQueryResult): void {
        if (result.hasData()) {
            for (const row of result.series[0].data) {
                if (row.metrics['score'] !== null) {
                    this.questionScores[row.value] = row.metrics['score'];
                }
            }
        }
    }

    /**
     * Check the program records to see if sentiment data exists
     *
     * @private
     */
    private checkProgramForSentiment(): boolean {
        return (
            this.survey.getItems((i) => {
                return i.settings['enableSentiment'];
            }).length > 0
        );
    }

    private checkOrgSentiment(): boolean {
        return this.accessService.getOrganization().hasEnabledFeature('survey', 'sentiment-analysis');
    }

    private getLoadScoreBySectionIql(): IIql {
        return this.setContextFilters(new Iql())
            .dimension('data.sections.*->id', Iql.STRING, 'label')
            .metric('data.sections.*->score.percentage', Iql.SCORE, Iql.AVERAGE, 'score');
    }

    private loadScoreBySection(): ng.IPromise<any> {
        this.sectionScoreData = [];

        return this.iqlApi
            .execute(this.getLoadScoreBySectionIql(), null, { name: 'section-scores' })
            .then((result: IIqlQueryResult) => {
                this.setSectionScoreData(result);
            });
    }

    private setSectionScoreData(result: IIqlQueryResult): void {
        if (result.hasData()) {
            this.sectionScoreData = result.series[0].data
                .map((d) => {
                    return {
                        label: d.name,
                        value: d.metrics['score'],
                    };
                })
                .filter((d) => {
                    return NumberUtils.isNumber(d.value);
                });
        }
    }

    private getLoadScoreByTagIql(): IIql {
        return this.setContextFilters(new Iql())
            .dimension('data.question_tags.*->uuid', Iql.STRING, 'name')
            .metric('data.question_tags.*->score.percentage', Iql.SCORE, Iql.AVERAGE, 'score');
    }

    private loadScoreByTag(): ng.IPromise<any> {
        this.tagScoreData = [];

        return this.iqlApi
            .execute(this.getLoadScoreByTagIql(), null, { name: 'tag-scores' })
            .then((result: IIqlQueryResult) => {
                this.setTagScoreData(result);
            });
    }

    private setTagScoreData(result: IIqlQueryResult): void {
        if (result.hasData()) {
            this.tagScoreData = result.series[0].data
                .map((d) => {
                    return {
                        label: d.name,
                        value: d.metrics['score'],
                    };
                })
                .filter((d) => {
                    return NumberUtils.isNumber(d.value);
                });
        }
    }

    private initRecordSingleValues(): void {
        this.recordSingleValues = {
            score: {
                value: null,
                isEmpty: true,
            },
            records: {
                value: null,
                isEmpty: true,
            },
        };
    }

    private loadQuestionSubmissionCount(question: IQuestion): ng.IPromise<IIqlQueryResult> {
        const id: string = question.getRecordIdentifier('survey');

        const iql: IIql = this.setContextFilters(
            new Iql()
                .dimension('meta->product', Iql.STRING)
                .metric('meta->synthesis_id', Iql.COUNT, Iql.COUNT, 'record_count')
                .filter(
                    new FilterGroup(Iql.AND, [new Filter('data.questions.*->field_name', Iql.EQUAL, id, Iql.STRING)])
                )
        );

        return <ng.IPromise<IIqlQueryResult>>this.iqlApi.execute(iql, null, { name: 'question-submission-count' });
    }

    private getLoadAverageScoreAndRecordsReceivedChartsIql(): IIql {
        return this.setContextFilters(new Iql())
            .dimension('meta.reference->program_id', Iql.STRING)
            .metric('meta->score.percentage', Iql.SCORE, Iql.AVERAGE, 'score')
            .metric('meta->synthesis_id', Iql.COUNT, Iql.COUNT, 'count');
    }

    private loadAverageScoreAndRecordsReceivedCharts(): ng.IPromise<any> {
        this.initRecordSingleValues();

        return this.iqlApi
            .execute(this.getLoadAverageScoreAndRecordsReceivedChartsIql(), null, { name: 'score-and-records' })
            .then((result: IIqlQueryResult) => {
                if (result.hasData()) {
                    const score: number = IqlQueryResultSeriesAdapter.toSingleValue(
                        result.series[0],
                        0,
                        'metrics.score'
                    );
                    const records: number = IqlQueryResultSeriesAdapter.toSingleValue(
                        result.series[0],
                        0,
                        'metrics.count'
                    );

                    this.recordSingleValues['score'] = {
                        value: score || 0,
                        isEmpty: !NumberUtils.isNumber(score),
                    };

                    this.recordSingleValues['records'] = {
                        value: records || 0,
                        isEmpty: !NumberUtils.isNumber(records),
                    };
                }
            });
    }

    private loadMoreQuestions(increment: number = 10): ng.IPromise<any> {
        if (this.questionResultsState.questionsRemaining <= 0) {
            return this.q.resolve();
        }
        increment =
            this.questionResultsState.questionsRemaining < increment
                ? this.questionResultsState.questionsRemaining
                : increment;

        const originalResultCount: number = this.getVisibleQuestionResultsCount();
        const promises: Array<ng.IPromise<any>> = this.getLoadQuestionMetricsPromises(increment);

        return this.q.all(promises).then(() => {
            if (this.getVisibleQuestionResultsCount() === originalResultCount) {
                // no items succeeded, try again until we are out of questions
                return this.loadMoreQuestions(increment);
            } else {
                // order questions
                this.sortQuestionResults(this.questionResultsState.questionResults);
                return this.q.resolve();
            }
        });
    }

    private getLoadQuestionMetricsPromises(increment: number): Array<ng.IPromise<any>> {
        const promises: Array<ng.IPromise<any>> = [];

        for (let i: number = 0; i < increment; i++) {
            const question: IQuestion =
                this.questionResultsState.questions[
                    this.questionResultsState.questions.length - this.questionResultsState.questionsRemaining + i
                ];
            const questionResult: IQuestionData = this.initQuestionResult(question);

            promises.push(
                this.q
                    .all([this.loadQuestionSubmissionCount(question), this.loadQuestionResponseMetrics(question)])
                    .then((results) => {
                        questionResult.records = results[0].getSingleValueResult();
                        this.addQuestionResult(results[1], questionResult, question);
                    })
                    .catch((error) => {
                        questionResult.state.error = true;
                    })
                    .finally(() => {
                        this.questionResultsState.questionsRemaining--;
                        questionResult.state.loading = false;
                    })
            );
        }

        return promises;
    }

    private initQuestionResult(question: IQuestion): IQuestionData {
        let questionLabel: string = TranslationUtils.getTranslatedLabel(
            question.translations,
            this.translate.use(),
            question.name,
            'label'
        );
        if (question.type === 'question') {
            const parent: IQuestion = this.program.findParentQuestion(question);
            if (parent) {
                questionLabel =
                    TranslationUtils.getTranslatedLabel(
                        parent.translations,
                        this.translate.use(),
                        parent.name,
                        'label'
                    ) +
                    ' - ' +
                    questionLabel;
            }
        }

        return {
            label: questionLabel,
            score: this.questionScores.hasOwnProperty(question.uuid) ? this.questionScores[question.uuid] : null,
            responses: [],
            question: question,
            state: { loading: true, error: false },
            records: null,
        };
    }

    private addQuestionResult(result: IIqlQueryResult, questionResult: IQuestionData, question: IQuestion): void {
        if (result.hasData()) {
            for (const item of result.series[0].data) {
                questionResult.responses.push(this.buildQuestionResultResponse(item, question));
            }
        }
        this.setQuestionResultResponseSupplementalData(questionResult);
        this.questionResultsState.questionResults.push(questionResult);
    }

    private buildQuestionResultResponse(item: IIqlQueryResultSeriesData, question: IQuestion): IResponseData {
        return {
            label: !item.value
                ? '[' + this.translations['GENERAL.NO_RESPONSES'] + ']'
                : this.getLocalizedResponseLabel(item, question),
            records: item.metrics['meta_count_count'],
            recordsPercentage: 0,
            value: item.value,
        };
    }

    private setQuestionResultResponseSupplementalData(questionResult: IQuestionData): void {
        if (questionResult.responses && questionResult.responses.length > 0) {
            for (const item of questionResult.responses) {
                item.recordsPercentage = (item.records / questionResult.records) * 100;
            }
            if (questionResult.responses.length > 10) {
                questionResult.displayLimit = 10;
            }
        }
    }

    private sortQuestionResults(results: Array<IQuestionData>): void {
        this.questionResultsState.questionResults = _.sortBy(this.questionResultsState.questionResults, (qr) => {
            return _.findIndex(this.questionResultsState.questions, qr.question);
        });
    }

    private getLoadQuestionResponseMetricsIql(question: IQuestion): IIql {
        const id: string = question.getRecordIdentifier('survey');
        return this.setContextFilters(new Iql())
            .dimension(
                'data.questions[field_name=' + id + '].*.response.*->' + this.getQuestionDimensionProperty(question),
                Iql.STRING,
                'label'
            )
            .metric('meta->synthesis_id', Iql.COUNT, Iql.COUNT)
            .metric('data.questions[field_name=' + id + '].*->score.percentage', Iql.SCORE, Iql.AVERAGE);
    }

    private loadQuestionResponseMetrics(question: IQuestion): ng.IPromise<any> {
        return this.iqlApi.execute(this.getLoadQuestionResponseMetricsIql(question), null, {
            name: 'question-metrics',
            id: question.fieldName,
        });
    }

    /**
     * Load the survey. This is the full survey with all settings.
     * @private
     */
    private loadSurvey(): ng.IPromise<ISurvey> {
        this.survey = null;
        return this.surveyApi.findSurveyByUuid(this.surveyUuid, this.getProgramFilters()).then((survey: ISurvey) => {
            this.survey = survey;
            this.pageService.setPageTitle(this.survey.name);
            return this.survey;
        });
    }

    private getProgramFilters(): IQueryFilter {
        const filters: IQueryFilter = new QueryFilter();
        filters.addParam('filter[status]', 'all');
        return filters;
    }

    private setEmptyStateCtas(): void {
        this.questionEmptyStateConfig.ctas = this.survey
            ? [
                  {
                      text: 'GENERAL.DISTRIBUTE_SURVEY',
                      icon: 'send',
                      onClick: () => {
                          this.state.go('home.surveys.edit.distributions.link', { uuid: this.survey.uuid });
                      },
                      cssClass: 'its-btn--primary',
                  },
              ]
            : [];
    }

    /**
     * Initialize controller data
     *
     */
    private initializeData(): void {
        this.questionResultsState = {
            loading: false,
            questions: [],
            questionsRemaining: 0,
            questionResults: [],
        };
    }

    private getVisibleQuestionResultsCount(): number {
        if (!this.questionResultsState || !this.questionResultsState.questionResults) {
            return 0;
        }
        return this.questionResultsState.questionResults.filter((q) => {
            return q.responses && q.responses.length > 0;
        }).length;
    }

    private getLocalizedResponseLabel(item: any, question?: IQuestion): string {
        if (!!question && item && item.name) {
            if (question.type === 'check') {
                return this.translate.instant('MODULES.DOCUMENT.' + item.name.replace(/-/g, '_').toUpperCase());
            } else if (question.type === 'nps') {
                return this.translate.instant('ESSENTIAL.GENERAL.NPS.' + item.name.toUpperCase());
            }
        }
        return IqlDataTranslatorFactory.build('survey').getLocalizeResponseName(item, this.program, this.locale);
    }

    private getQuestionDimensionProperty(question: IQuestion): string {
        switch (question.type) {
            case 'slider':
            case 'rating':
                return 'number';
            case 'radio':
            case 'checkbox':
                if (question.responses && question.responses.length > 0) {
                    return 'label';
                }
                break;
            default:
                return question.type === 'nps' || question.type === 'scale' || question.type === 'ranking'
                    ? 'label'
                    : 'string.value';
        }
    }

    private setContextFilters(iql: IIql, excludeHierarchies: boolean = false): IIql {
        iql.filter(
            new FilterGroup(Iql.AND, [
                new Filter('meta.reference->program_id', Iql.EQUAL, this.survey.originalUuid, Iql.STRING),
            ])
        );

        if (!excludeHierarchies) {
            iql.filter(this.getHierarchyFilterGroup());
        }

        return this.dateFilterService.applyFilters(iql);
    }

    private getHierarchyFilterGroup(): IFilterGroup {
        return new FilterGroup(
            Iql.OR,
            this.userNodes.map((uuid) => {
                return new Filter('meta.hierarchy->uuid', Iql.EQUAL, uuid, Iql.HIERARCHY);
            })
        );
    }

    private checkForIqProduct(): boolean {
        const iq: any = _.find(this.accessService.getToken().getProducts(), { slug: 'intelligence' });
        return !!iq;
    }
}
