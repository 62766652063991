import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { TextArea } from '../../../../../../domain/surveys/items/TextArea';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';

@Component('its.survey.module.surveys', TextareaSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/textarea/TextareaSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: TextareaSettingsComponent.getBindings(),
})
class TextareaSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'TextareaSettingsComponent';
    static CID: string = 'itsTextareaSettings';
    static $inject: string[] = [];

    public item: TextArea;
}
