import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { Entity } from '@intouch/its.essential/app/essential/domain/Entity';
import { Section } from '../../../../../../domain/surveys/Section';
import { SurveyService } from '../../../../../../services/SurveyService';
import { Survey } from '../../../../../../domain/surveys/survey/Survey';

@Component('its.survey.module.surveys', AddNewSection.CID, {
    template: require('/app/modules/surveys/components/items/shared/add-new-section/AddNewSectionComponent.html'),
    controllerAs: 'vm',
    bindings: {
        index: '<',
    },
})
class AddNewSection {
    static IID: string = 'AddNewSection';
    static CID: string = 'itsAddNewSection';
    static $inject: string[] = ['itsSurveyService', '$scope'];

    public index: number;
    public editingItem: Entity = null;
    private survey: Survey = null;

    public constructor(private surveyService: SurveyService, private scope: ng.IScope) {
        const surveySubscription: SubjectSubscription = this.surveyService.surveyValue.subscribe((survey) => {
            this.survey = survey;
        });
        surveySubscription.unsubscribeOnScopeDestruction(scope);
        const editingItemSubscription: SubjectSubscription = this.surveyService.editingItem.subscribe((editingItem) => {
            this.editingItem = editingItem;
        });
        editingItemSubscription.unsubscribeOnScopeDestruction(scope);
    }

    public addSectionToSurvey(): void {
        const newSection: Section = Section.make('');
        const indexToUse: number = this.index >= 0 ? this.index : this.survey.sections.length - 1;
        this.surveyService.addAndEditNewSection(newSection, indexToUse);
    }
}
