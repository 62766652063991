import { AbstractTheme } from '../AbstractTheme';
import config from '../../../../config';
import { IThemeSettings } from '../ThemeSettings';

export class BaseImageTheme extends AbstractTheme {
    public constructor(image: string) {
        super();
        this.customizations['its-theme--background']['background-image'] =
            'url(' + config.app.themeBackgrounds + '/' + image + ')';
        this.customizations['its-theme--background']['background-attachment'] = 'fixed';
        this.customizations['its-theme--background']['background-size'] = 'cover';
        this.customizations['its-theme--background']['background-position'] = 'center center';
        this.customizations['its-theme--background']['background-repeat'] = 'no-repeat';
    }

    public getName(): string {
        return null;
    }

    public getSettings(): IThemeSettings {
        return this.customizations;
    }
}
