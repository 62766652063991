import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IScrollService } from '@intouch/its.essential/app/essential/services/ScrollService';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IProduct } from '@intouch/its.essential/app/essential/domain/access/Product';
import { IOrganization } from '@intouch/its.essential/app/essential/domain/access/Organization';
import { IPhotoService, IParsedImage } from '@intouch/its.essential/app/essential/services/PhotoService';
import { FroalaProvider } from '@intouch/its.essential/app/essential/domain/FroalaProvider';
import { IPageService } from '../../services/PageService';
import { ISurveyApi } from '../../api/SurveyApi';
import { ISection, Section } from '../../domain/surveys/Section';
import { IBaseItem } from '../../domain/surveys/items/BaseItem';
import { ICalendar } from '../../domain/surveys/items/Calendar';
import { ISurveyService } from '../../services/SurveyService';
import { IGeneralSettings } from '../../domain/settings/GeneralSettings';
import { BaseSurvey } from './BaseSurvey';
import { ILanguage, LanguageProvider } from '../../domain/LanguageProvider';

/**
 * Settings Controller for saving the current state of Settings Panel toggle switches
 *
 * @constructor
 */
@Controller('its.survey.module.surveys', SettingsController.IID, SettingsController)
class SettingsController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.SettingsController';
    static $inject: Array<string> = [
        '$state',
        'itsPageService',
        'itsSurveyApi',
        'itsSurveyService',
        'iteScrollService',
        '$mdDialog',
        'iteLogger',
        'APPCONFIG',
        'iteToaster',
        '$translate',
        '$sce',
        '$stateParams',
        '$scope',
        'iteUserLocalTimeService',
        'iteAccessService',
        'itePhotoService',
    ];

    public editingItem: ISection | IBaseItem;
    public publishing: boolean = false;
    public submitting: boolean = false;
    public openPanel: string = 'elements';
    public froalaOptions: any = null;
    public customDomainList: Array<string> = [];
    public hasCustomDomain: boolean = false;
    public languages: Array<ILanguage> = [];
    public hasWeightedScoringFeature: boolean = false;

    constructor(
        protected stateService: ng.ui.IStateService,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected surveyService: ISurveyService,
        private scrollService: IScrollService,
        protected dialog: ng.material.IDialogService,
        private logger: ILogger,
        private config: any,
        protected toaster: IToaster,
        private translate: ng.translate.ITranslateService,
        private sce: ng.ISCEService,
        protected stateParams: ng.ui.IStateParamsService,
        protected scope: ng.IScope,
        protected userLocalTimeService: IUserLocalTimeService,
        private accessService: IAccessService,
        private photoService: IPhotoService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );

        this.languages = LanguageProvider.getAvailableLanguages();
        this.load().then(() => {
            if (this.survey.getSections().length === 0) {
                const newSection: ISection = Section.make('Section 1');
                this.survey.addSection(newSection);
            }
            this.setCustomDomains();
            if (this.survey.settings.customDomain) {
                this.hasCustomDomain = true;
            }

            const survey: IProduct = this.accessService.getToken().getProductBySlug('survey');
            if (survey && survey.hasFeatureEnabled('weighted-scoring')) {
                this.hasWeightedScoringFeature = true;
            }
        });

        this.setupFroala();
    }

    /**
     * Get all calendar type items.
     */
    public getCalendarItems(): Array<IBaseItem> {
        return this.survey.getItems((i: IBaseItem) => {
            return i.type === 'calendar';
        });
    }

    public isNotLeadOrEnterprise(): boolean {
        const organization: IOrganization = this.accessService.getOrganization();
        return !organization.hasOneOfProductTier('survey', ['lead']);
    }

    /**
     * Sets the list of custom domains available
     */
    public setCustomDomains(): void {
        this.surveyApi
            .getSettings()
            .then((data: IGeneralSettings) => {
                this.customDomainList = data.customDomains;
            })
            .catch(() => {
                this.logger.warn('Unable to get General Survey Settings');
            });
    }

    /**
     * Handle the change of the custom submission date field.
     */
    public handleCustomSubmissionDateChange(item: ICalendar): void {
        if (item) {
            item.settings.isDateOfDocument = true;

            // reset all others to false.
            this.clearIsDateOfDocument(item);
        }
    }

    /**
     * Handle switching submissions period in days, if we are 0, null out the submissions per period
     *
     * @param submissionsPeriodInDays
     */
    public handleSubmissionLimitChange(submissionsPeriodInDays: number): void {
        if (submissionsPeriodInDays === 0) {
            this.survey.settings.submissionsPerPeriod = null;
        }
    }

    /**
     * Handle enabling/disabling of the submission limit setting
     *
     * @param state
     */
    public handleSubmissionLimitToggle(state: boolean): void {
        if (state === false) {
            this.survey.settings.resetSubmissionSettings();
        }
    }

    /**
     * Clear the isDateofDocument settings on calendar items, with option to exclude the newly selected item.
     */
    public clearIsDateOfDocument(excludeSelectedItem?: ICalendar): void {
        for (const calendarItem of this.getCalendarItems()) {
            if (excludeSelectedItem && excludeSelectedItem === calendarItem) {
                continue;
            }
            (<ICalendar>calendarItem).settings.isDateOfDocument = false;
        }
    }

    /**
     * Send survey to the API to be saved
     */
    public saveSurvey(): void {
        this.save(this.survey);
    }

    /**
     * Publish survey
     */
    public publishSurvey(): void {
        this.publish(this.survey);
    }

    private setupFroala(): void {
        this.froalaOptions = FroalaProvider.defaultConfig(this.translate.instant('SURVEYS.ITEMS.TEXT.TYPE_SOMETHING'));

        if (this.config.froala && this.config.froala.key) {
            this.froalaOptions.key = this.config.froala.key;
        } else {
            this.logger.warn('Unable to set Froala key');
        }

        this.froalaOptions.pluginsEnabled.push('image');
        this.froalaOptions.toolbarButtons.splice(3, 0, 'insertImage');
        this.froalaOptions.imageUpload = true;
        this.froalaOptions.imageAllowedTypes = ['jpg', 'jpeg', 'png'];

        const self: this = this;
        this.froalaOptions.events = {
            'image.beforeUpload': function (images: any[]): boolean {
                const file: any = images[0];
                self.photoService
                    .parse(file, null, null, false)
                    .then((parsedImage: IParsedImage) => {
                        self.surveyApi
                            .addPhoto(parsedImage.base64)
                            .then((result) => {
                                this.image.insert(result.data.large);
                            })
                            .finally(() => {
                                this.image.hideProgressBar(true); // close froala upload progress bar
                            });
                    })
                    .catch((error) => {
                        self.toaster.error('ERRORS.PHOTO_NOT_ADDED', error);
                    });
                return false; // cancels progression of froala upload path
            },
        };
        this.froalaOptions = FroalaProvider.convertOptionsToV4(this.froalaOptions);
    }
}
