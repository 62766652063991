import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import {
    BaseResponseItem,
    BaseResponseItemSettings,
    IBaseResponseItem,
    IBaseResponseItemSettings,
} from './BaseResponseItem';
import { IconResponse, IIconResponse } from './IconResponse';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { IHelpTextTranslation } from './shared/HelpText';
import { BaseResponse, IBaseResponse } from './BaseResponse';
import { ITranslatedEntity } from '../../ITranslatedEntity';

export type ISelectTranslation = IHelpTextTranslation;

export class SelectTranslation extends Translation implements ISelectTranslation {
    additionalText: string = null;
}

export interface ISelectSettings extends IBaseResponseItemSettings {
    additionalText: string;
    placeholder: string;
    responseType: string;
    translations: Array<ISelectTranslation>;
}

export class SelectSettings extends BaseResponseItemSettings implements ISelectSettings, ITranslatedEntity {
    additionalText: string = null;
    placeholder: string = null;
    responseType: string = 'labels';
    translations: Array<ISelectTranslation> = [];

    public fromJson(jsonObject: any, convertToCamel?: boolean): SelectSettings {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ISelectTranslation>(
                SelectTranslation,
                jsonObject.translations,
                true
            );
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.placeholder) {
            keys.push('placeholder');
        }

        if (this.additionalText) {
            keys.push('additionalText');
        }
        return keys;
    }

    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

export type ISelect = IBaseResponseItem;

export class Select extends BaseResponseItem implements ISelect {
    type: string = 'select';
    settings: ISelectSettings = new SelectSettings();
    responses: Array<IIconResponse | IBaseResponse> = [];

    /**
     * Build the Select item from json
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {ISelect}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<ISelectSettings>(SelectSettings, jsonObject.settings, true);
        }

        if (jsonObject.responses) {
            switch (this.settings.responseType) {
                case 'icons':
                case 'labels_icons':
                    this.responses = EntityBuilder.buildMany<IIconResponse>(IconResponse, jsonObject.responses, true);
                    break;
                default:
                    this.responses = EntityBuilder.buildMany<IBaseResponse>(BaseResponse, jsonObject.responses, true);
            }
        }

        return this;
    }

    /**
     * Gets the default selected response
     *
     * @returns {IIconResponse}
     */
    public getDefaultSelected(): IIconResponse | IBaseResponse {
        for (const response of this.getResponses()) {
            if (response.defaultSelected) {
                return response;
            }
        }
        return null;
    }

    /**
     * Gets all responses
     *
     * @returns {Array<IIconResponse>}
     */
    public getResponses(): Array<IIconResponse | IBaseResponse> {
        return this.responses;
    }

    /**
     * Converts responses to the passed in type
     * @param {string} type
     */
    public convertResponses(): void {
        switch (this.settings.responseType) {
            case 'icons':
            case 'labels_icons':
                this.responses = EntityBuilder.buildMany<IIconResponse>(IconResponse, this.responses, true);
                break;
            default:
                this.responses = EntityBuilder.buildMany<IBaseResponse>(BaseResponse, this.responses, true);
                break;
        }
    }

    /**
     * Adds responses to the item.
     * Defaults to adding one empty response at the end of the array of responses.
     *
     * @param {number} count
     * @param {Array<string>} labels
     * @param {number} index
     */
    public addResponses(count: number = 1, labels: Array<string> = []): void {
        for (let i: number = 0; i < count; i++) {
            const label: string = labels.length > i ? labels[i] : '';
            switch (this.settings.responseType) {
                case 'icons':
                case 'labels_icons':
                    this.responses.push(IconResponse.make(label));
                    break;
                default:
                    this.responses.push(BaseResponse.make(label));
            }
        }
    }
}
