import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { IIqlQueryResult, IIqlQueryResultSeries, IqlQueryResult } from '@intouch/iql-ts-sdk/src/domain/IqlQueryResult';
import { IIql, Iql } from '@intouch/iql-ts-sdk/src/domain/Iql';
import { SurveyIqlBuilder } from '../domain/surveys/SurveyIqlBuilder';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import { FilterGroup } from '@intouch/iql-ts-sdk/src/domain/filters/FilterGroup';
import { Filter } from '@intouch/iql-ts-sdk/src/domain/filters/Filter';
import { DateDimension } from '@intouch/iql-ts-sdk/src/domain/dimensions/DateDimension';
import { IFilterService } from '@intouch/its.essential/app/essential/domain/filters/FilterService';
import moment from 'moment';
import { DateFilter } from '@intouch/iql-ts-sdk/src/domain/filters/DateFilter';

@Service('its.survey.services', SurveyIqlService.IID, SurveyIqlService)
export class SurveyIqlService {
    static IID: string = 'itsSurveyIqlService';
    static $inject: Array<string> = ['iteAccessService', 'iteIqlApi', 'iteIqlRecordDateOfDocumentFilterService'];

    public constructor(
        private accessService: IAccessService,
        private iqlApi: IIqlApi,
        private dateFilterService: IFilterService
    ) {}

    public getSurveySubmissionCountAndAverageScoreByOriginalUuid(
        surveyOriginalUuid: string
    ): ng.IPromise<{ [originalUuid: string]: { score: number; count: number } }> {
        const iqlBuilder: SurveyIqlBuilder = new SurveyIqlBuilder();

        const currentUserAccessNodes: string[] = this.accessService.getToken().getUser().nodes;
        iqlBuilder
            .addRecordCountMetric()
            .addRecordAverageScoreMetric()
            .addProgramDimension('program_name')
            .addProgramIdFilterGroup(surveyOriginalUuid)
            .addHierarchyNodesFilterGroup(currentUserAccessNodes);

        let iqlQuery: IIql = iqlBuilder.getIql();
        iqlQuery = this.dateFilterService.applyFilters(iqlQuery);
        return this.iqlApi.execute(iqlQuery, null, { name: 'total-count-score' }).then((result: IqlQueryResult) => {
            return this.formatResultsByOriginalUuidAndMetric(result);
        });
    }

    public getRecordCountByDistribution(distributionUuid: string): ng.IPromise<number> {
        const queryBuilder: SurveyIqlBuilder = new SurveyIqlBuilder();
        const queryContext: any = { name: 'distribution-record-count' };
        queryBuilder
            .addRecordCountMetric()
            .addDateDimension()
            .openOrGroup()
            .addSurveyEmailFilter(distributionUuid)
            .addSurveySMSFilter(distributionUuid)
            .closeGroup();
        return this.iqlApi.execute(queryBuilder.getIql(), null, queryContext).then((iqlQueryResult: IqlQueryResult) => {
            if (iqlQueryResult.hasData()) {
                return iqlQueryResult.getSingleValueResult();
            }

            return 0;
        });
    }

    public getSurveySubmissionsByDateFilter(surveyOriginalUuid: string): ng.IPromise<IIqlQueryResultSeries> {
        const iqlBuilder: SurveyIqlBuilder = new SurveyIqlBuilder();
        iqlBuilder
            .addRecordCountMetric()
            .addDateDimension()
            .openAndGroup()
            .addProgramIdFilter(surveyOriginalUuid)
            .closeGroup();
        let iql: Iql = <Iql>iqlBuilder.getIql();
        iql = this.dateFilterService.applyFilters(iql);
        return this.iqlApi.execute(iql).then((result: IIqlQueryResult) => {
            if (result.hasData()) {
                return result.series[0];
            }
            return null;
        });
    }

    public getSurveyViewsByDateFilter(surveyOriginalUuid: string): ng.IPromise<IIqlQueryResultSeries> {
        const iqlBuilder: SurveyIqlBuilder = new SurveyIqlBuilder('trace');

        const iql: Iql = <Iql>(
            iqlBuilder
                .getIql()
                .filter(this.getSurveyViewsIqlFilterGroup(surveyOriginalUuid))
                .dimension(new DateDimension('data->created_at', 'day'))
                .metric('data->record_uuid', Iql.COUNT, Iql.COUNT, 'views')
        );
        return this.iqlApi.execute(iql, null, { name: 'survey-views' }).then((result: IIqlQueryResult) => {
            if (result.hasData()) {
                return result.series[0];
            }
            return null;
        });
    }

    public getSurveyViewsIqlFilterGroup(surveyOriginalUuid: string): FilterGroup {
        const currentDateIql: Iql = this.dateFilterService.applyFilters(new Iql());
        const currentDateFilters: Filter[] = this.getDateFilterFromIql(currentDateIql);
        if (currentDateFilters.length === 1) {
            const dateIntervalPeriod: string = this.getCurrentDateInterval();
            return new FilterGroup(Iql.AND, [
                new Filter('data.targets.*->type', Iql.EQUAL, 'survey', Iql.STRING),
                new Filter('data.targets.*->id', Iql.EQUAL, surveyOriginalUuid, Iql.STRING),
                new Filter('data->action', Iql.EQUAL, 'viewed', Iql.STRING),
                new Filter('data->created_at', Iql.EQUAL, dateIntervalPeriod, Iql.DATE),
            ]);
        } else {
            const dateRangeInterval: string[] = this.getCurrentCustomDateRangeInterval();
            return new FilterGroup(Iql.AND, [
                new Filter('data.targets.*->type', Iql.EQUAL, 'survey', Iql.STRING),
                new Filter('data.targets.*->id', Iql.EQUAL, surveyOriginalUuid, Iql.STRING),
                new Filter('data->action', Iql.EQUAL, 'viewed', Iql.STRING),
                new Filter('data->created_at', Iql.GREATER_THAN_EQUAL, dateRangeInterval[0], Iql.DATE),
                new Filter('data->created_at', Iql.LESS_THAN_EQUAL, dateRangeInterval[1], Iql.DATE),
            ]);
        }
    }

    public getSentAndFailedCountByDistribution(distributionUuid: string): ng.IPromise<any> {
        const queryBuilder: SurveyIqlBuilder = new SurveyIqlBuilder('trace');
        queryBuilder
            .addActionCountMetric()
            .addActionDimension()
            .openOrGroup()
            .addActionFilter('sent')
            .addActionFilter('undelivered')
            .closeGroup()
            .openAndGroup()
            .addDomainFilter('distribution')
            .addTargetIdFilter(distributionUuid)
            .closeGroup();
        const queryContext: any = { name: 'distribution-sent-failed-count' };
        return this.iqlApi
            .execute(queryBuilder.getIql(), null, queryContext)
            .then((iqlQueryResult: IIqlQueryResult) => {
                const dataArr: any[] = iqlQueryResult.series[0].data;
                const metricsMap: any = {};
                for (const data of dataArr) {
                    metricsMap[data.x] = data.y;
                }

                return metricsMap;
            });
    }

    public getCurrentDateRangeFromFilter(): { start: moment.Moment; end: moment.Moment } {
        const currentDateIql: Iql = this.dateFilterService.applyFilters(new Iql());
        const dateRangeMoment: { start: moment.Moment; end: moment.Moment } = this.getDateRangeFromIql(currentDateIql);
        return {
            start: dateRangeMoment.start,
            end: dateRangeMoment.end,
        };
    }

    public getCurrentDateInterval(): string {
        const currentDateIql: Iql = this.dateFilterService.applyFilters(new Iql());
        const currentDateFilters: Filter[] = this.getDateFilterFromIql(currentDateIql);
        return currentDateFilters[0].value;
    }

    public getChartTitleDatePeriod(): string {
        const currentDateIql: Iql = this.dateFilterService.applyFilters(new Iql());
        const currentDateFilters: Filter[] = this.getDateFilterFromIql(currentDateIql);
        if (currentDateFilters.length === 1) {
            return currentDateFilters[0].value;
        }
        return 'custom_range';
    }

    public getCurrentCustomDateRangeInterval(): string[] {
        const currentDateIql: Iql = this.dateFilterService.applyFilters(new Iql());
        const currentDateFilters: Filter[] = this.getDateFilterFromIql(currentDateIql);
        return [currentDateFilters[0].value, currentDateFilters[1].value];
    }

    public getDateRangeFromIql(iql: Iql): { start: moment.Moment; end: moment.Moment } {
        const range: { start: moment.Moment; end: moment.Moment } = { start: null, end: null };
        const dateFilters: Filter[] = this.getDateFilterFromIql(iql);
        const date: string = dateFilters[0].value;
        // custom date
        if (dateFilters.length > 1) {
            const fromDate: string = dateFilters[0].value;
            const toDate: string = dateFilters[1].value;
            range.start = moment.parseZone(fromDate);
            range.end = moment.parseZone(toDate);
            return range;
        }
        if (date) {
            let start: moment.Moment;
            let end: moment.Moment;
            switch (date) {
                case 'today':
                    start = moment().startOf('day');
                    end = moment().endOf('day');
                    break;
                case 'yesterday':
                    start = moment().subtract(1, 'days').startOf('day');
                    end = moment().subtract(1, 'days').endOf('day');
                    break;
                case 'past_week':
                    start = moment().subtract(7, 'days').startOf('day');
                    end = moment();
                    break;
                case 'past_30_days':
                    start = moment().subtract(30, 'days').startOf('day');
                    end = moment();
                    break;
                case 'past_90_days':
                    start = moment().subtract(90, 'days').startOf('day');
                    end = moment();
                    break;
                case 'week_to_date':
                    start = moment().startOf('isoWeek').subtract(1, 'days');
                    end = moment();
                    break;
                case 'month_to_date':
                    start = moment([moment().year(), moment().month(), 1]).startOf('day');
                    end = moment();
                    break;
                case 'year_to_date':
                    start = moment([moment().year(), 0, 1]).startOf('day');
                    end = moment();
                    break;
                case 'previous_month':
                    start = moment().subtract(1, 'months').startOf('month');
                    end = moment().subtract(1, 'months').endOf('month');
                    break;
                case 'previous_year':
                    start = moment().subtract(1, 'years').startOf('year');
                    end = moment().subtract(1, 'years').endOf('year');
                    break;
                default:
                    start = moment(date);
                    end = moment(date);
            }

            if (!range.start || range.start.isAfter(start)) {
                range.start = start;
            }
            if (!range.end || range.end.isBefore(end)) {
                range.end = end;
            }
        }

        return range;
    }

    private getDateFilterFromIql(iql: Iql): Filter[] {
        if (!iql || !iql.getQuery().filterGroups || iql.getQuery().filterGroups.length === 0) {
            return [new DateFilter()];
        }
        for (const group of iql.getQuery().filterGroups) {
            const dateFilters: Filter[] = FilterGroup.findFilters(group, (f) => {
                return f.type === Iql.DATE;
            });

            if (dateFilters && dateFilters.length > 0) {
                return dateFilters;
            }
        }
        return null;
    }

    private formatResultsByOriginalUuidAndMetric(iqlResult: IqlQueryResult): any {
        if (iqlResult.hasData()) {
            const metricsMap: { [originalUuid: string]: any } = {};
            for (const item of iqlResult.series[0].data) {
                metricsMap[item.value] = {
                    count: item.metrics['meta_count_count'],
                    score: item.metrics['score'],
                };
            }
            return metricsMap;
        } else {
            return {};
        }
    }
}
