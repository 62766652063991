import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';

/**
 * Interface for CustomFields
 */
export interface ICustomField extends IEntity {
    uuid: string;
    label: string;
    name: string;
    type: string;
    public: boolean;
    isDefaultField: boolean;

    getUserFriendlyName(): string;
}

/**
 * Class definition for CustomFields
 */
export class CustomField extends Entity implements ICustomField {
    public uuid: string = null;
    public label: string = null;
    public name: string = null;
    public type: string = 'string';
    public public: boolean = false;

    @NotEnumerable
    public isDefaultField: boolean = false;

    public getUserFriendlyName(): string {
        switch (this.type) {
            case 'string':
                return 'TEXT';
            case 'number':
                return 'NUMBER';
            case 'date':
                return 'DATE_SPECIFIC';
            default:
                return 'TEXT';
        }
    }
}
