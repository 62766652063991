import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { BaseItem, IBaseItem } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { ITranslation, Translation } from '../Translation';
import { TranslationLocator } from '../TranslationLocator';
import { IHelpTextSettings, IHelpTextTranslation } from './shared/HelpText';
import { ISmartPrompt, SmartPrompt } from './shared/SmartPrompt';
import { IHealthPoints } from './IHealthPoints';
import { ITranslatedEntity } from '../../ITranslatedEntity';

export interface IInputTranslation extends IHelpTextTranslation {
    placeholder: string;
}

export class InputTranslation extends Translation implements IInputTranslation {
    placeholder: string = null;
    additionalText: string = null;
}

export interface IInputValidation extends IEntity {
    type: string;
    regex: string;
    minLength: number;
    maxLength: number;
}

export class InputValidation extends Entity implements IInputValidation {
    type: string = null;
    regex: string = null;
    minLength: number = null;
    maxLength: number = null;

    public isMinLengthValid(): boolean {
        return (
            this.hasNoMinLength() ||
            (this.hasMinLength() && this.hasNoMaxLength()) ||
            (this.hasMinLengthAndMaxLength() && this.minLengthIsLessThanOrEqualMaxLength())
        );
    }

    public isMaxLengthValid(): boolean {
        return (
            this.hasNoMaxLength() ||
            (this.hasMaxLength() && this.hasNoMinLength()) ||
            (this.hasMinLengthAndMaxLength() && this.minLengthIsLessThanOrEqualMaxLength())
        );
    }

    private minLengthIsLessThanOrEqualMaxLength(): boolean {
        return this.minLength <= this.maxLength;
    }

    private hasMinLengthAndMaxLength(): boolean {
        return this.hasMinLength() && this.hasMaxLength();
    }

    private hasNoMinLength(): boolean {
        return this.minLength === null || this.minLength === undefined;
    }

    private hasMinLength(): boolean {
        return this.minLength !== undefined && this.minLength !== null && this.minLength > 0;
    }

    private hasNoMaxLength(): boolean {
        return this.maxLength === undefined || this.maxLength === null;
    }

    private hasMaxLength(): boolean {
        return this.maxLength !== undefined && this.maxLength !== null && this.maxLength > 0;
    }
}

export interface IInputSettings extends IBaseSettings, IHelpTextSettings<IInputTranslation> {
    maxLength: number;
    placeholder: string;
    smartPrompt: Array<ISmartPrompt>;
    mask: string;
}

export class InputSettings extends BaseSettings implements IInputSettings {
    maxLength: number = null;
    placeholder: string = null;
    additionalText: string = null;
    translations: Array<IInputTranslation> = [];
    smartPrompt: Array<ISmartPrompt> = [];
    mask: string = 'none';

    public fromJson(jsonObject: any, convertToCamel?: boolean): IInputSettings {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<IInputTranslation>(
                InputTranslation,
                jsonObject.translations,
                true
            );
        }

        if (jsonObject.smart_prompt) {
            this.smartPrompt = EntityBuilder.buildMany<ISmartPrompt>(SmartPrompt, jsonObject.smart_prompt, true);
        }

        return this;
    }

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.placeholder) {
            keys.push('placeholder');
        }

        if (this.additionalText) {
            keys.push('additionalText');
        }
        return keys;
    }

    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

export interface IInput extends IBaseItem {
    validation: IInputValidation;
}

export class Input extends BaseItem implements IInput, IHealthPoints {
    type: string = 'input';
    settings: IInputSettings = new InputSettings();
    validation: InputValidation = new InputValidation();

    @NotEnumerable
    _healthPoints: number = 2;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey. This overrides the BaseItem default value.
     */
    get healthPoints(): number {
        return this._healthPoints;
    }

    /**
     * Build the text input item from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IInput}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<IInputSettings>(InputSettings, jsonObject.settings, true);
        }

        if (jsonObject.validation) {
            this.validation = EntityBuilder.buildOne<InputValidation>(InputValidation, jsonObject.validation, true);
        }

        return this;
    }

    public toJSON(convert: boolean): any {
        if (this.validation && !this.validation.type) {
            this.validation = null;
        }
        return this;
    }
}
