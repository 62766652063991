import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { ITranslation, Translation } from '../Translation';
import { ItemFactory } from './ItemFactory';
import { BaseItem, IBaseItem, ItemType } from './BaseItem';
import { BaseSettings, IBaseSettings } from './BaseSettings';
import { TranslationLocator } from '../TranslationLocator';
import * as _ from 'lodash';
import { BaseResponseItem } from './BaseResponseItem';
import { IBaseResponse } from './BaseResponse';
import { IHelpTextTranslation } from './shared/HelpText';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import { IWeightedTag } from './shared/WeightedTag';
import { ILogic } from '../logic/Logic';
import { ILogicReference, LogicOwner, LogicReference } from '../logic/LogicReference';
import { Matrix } from './Matrix';
import { ITranslatedEntity } from '../../ITranslatedEntity';
import { IContainItems } from './ContainItems';

export type IGroupTranslation = IHelpTextTranslation;

export class GroupTranslation extends Translation implements IGroupTranslation {
    additionalText: string = null;
}

/**
 * A form user object interface
 */
export interface IGroup extends IBaseItem {
    uuid: string;
    type: string;
    label: string;
    disabled: boolean;
    translations: Array<ITranslation>;
    required: boolean;

    getItems(): Array<IBaseItem>;
    getItemByUuid(uuid: string): IBaseItem;
    findLogicByUuid(uuid: string): ILogic;
    moveItem(fromIndex: number, toIndex: number): void;
    toggleCollapse(): void;
    isValidScoringItemType(): boolean;
    hasScoringQuestion(): boolean;
    hasValidTagQuestion(): boolean;
    updateTagWeight(tagUuid: string, weight: number): void;
    buildTagMap(tagObj: { [uuid: string]: IWeightedTag }): void;
    findTagByUuid(tagUuid: string): IWeightedTag;
    getAllLogicItemBelongsTo(itemUuid: string): Array<ILogicReference>;
}

export interface IGroupSettings extends IBaseSettings {
    hideQuestionLabels: boolean;
    additionalText: string;
    translations: Array<IGroupTranslation>;
}

export class GroupSettings extends BaseSettings implements IGroupSettings, ITranslatedEntity {
    hideQuestionLabels: boolean = false;
    additionalText: string = null;
    translations: Array<IGroupTranslation> = [];

    public getTranslationKeys(): Array<string> {
        const keys: Array<string> = [];
        if (this.additionalText) {
            keys.push('additionalText');
        }
        return keys;
    }

    public fromJson(jsonObject: any, convertToCamel?: boolean): GroupSettings {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<IGroupTranslation>(
                GroupTranslation,
                jsonObject.translations,
                true
            );
        }

        return this;
    }

    public getTranslation(locale: string, label: string, createOnNotFound?: boolean): ITranslation {
        return TranslationLocator.locate(this.translations, label, locale, createOnNotFound);
    }
}

/**
 * A form user object
 */
export class Group extends BaseItem implements IGroup, IContainItems {
    label: string = null;
    type: ItemType = 'group';
    disabled: boolean = false;
    translations: Array<ITranslation> = [];
    required: boolean = false;
    items: Array<BaseItem | BaseResponseItem> = [];
    settings: IGroupSettings = new GroupSettings();

    @NotEnumerable
    expanded: boolean = true;
    @NotEnumerable
    editingLabel: boolean = false;
    @NotEnumerable
    editIcon: boolean = false;
    @NotEnumerable
    editedLabel: string = null;
    @NotEnumerable
    originalLabel: string = null;
    @NotEnumerable
    _points: number = 0;

    get points(): number {
        this._points = 0;
        for (const item of this.items) {
            if (item.isValidScoringItemType() && NumberUtils.isNumber((<any>item).points)) {
                this._points += (<any>item).points;
            }
        }

        return this._points;
    }

    set points(value: number) {
        this._points = value;
    }

    get healthPoints(): number {
        let childrenPoints: number = 0;
        for (const item of this.items) {
            childrenPoints += item.healthPoints;
        }

        return this._healthPoints + childrenPoints;
    }
    /**
     * Returns if the type is a valid group item type
     *
     * @param type
     */
    public static canHoldItem(type: string): boolean {
        return (
            ['matrix', 'nps', 'slider', 'image', 'group', 'video', 'scale', 'ranking', 'location'].indexOf(type) === -1
        );
    }

    public canHoldItemOfType(itemType: string): boolean {
        return Group.canHoldItem(itemType);
    }

    /**
     * Overrides BaseItem valid scoring function
     */
    public isValidScoringItemType(): boolean {
        return this.hasScoringQuestion();
    }

    /**
     * Returns if the uuid is used in defined logic
     *
     * @param itemUuid
     */
    public getAllLogicItemBelongsTo(itemUuid: string): Array<ILogicReference> {
        let allLogic: Array<ILogicReference> = [];
        if (this.logic) {
            for (const logicItem of this.logic.items) {
                if (logicItem.operand === itemUuid) {
                    allLogic.push(new LogicReference(new LogicOwner(this.uuid, this.label), this.logic));
                }
            }
        }

        for (const item of this.items) {
            allLogic = _.concat(allLogic, item.getAllLogicItemBelongsTo(itemUuid));
        }

        return allLogic;
    }

    /**
     * Get logic by its uuid
     *
     * @param uuid
     */
    public findLogicByUuid(uuid: string): ILogic {
        for (const item of this.items) {
            if (item.logic && item.logic.uuid === uuid) {
                return item.logic;
            }

            if (item instanceof BaseResponseItem) {
                if (item instanceof Matrix) {
                    for (const question of item.questions) {
                        if (question.logic && question.logic.uuid === uuid) {
                            return question.logic;
                        }
                    }
                } else {
                    for (const response of item.responses) {
                        if (response.logic && response.logic.uuid === uuid) {
                            return response.logic;
                        }
                    }
                }
            }
        }
        return null;
    }

    /**
     * Returns if the group contains an item which is used in logic
     */
    public hasItemWithLogicReference(): boolean {
        for (const item of this.items) {
            if (item.logicReferences.length > 0) {
                return true;
            }

            if (item instanceof BaseResponseItem) {
                for (const response of item.responses) {
                    if (response.logicReferences.length > 0) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    public findItemsByType(type: string): Array<BaseItem> {
        const itemsFound: Array<BaseItem> = [];
        for (const item of this.items) {
            if (item.type === type) {
                itemsFound.push(item);
            }

            if (item instanceof Group) {
                itemsFound.concat(item.findItemsByType(type));
            }
        }

        return itemsFound;
    }

    /**
     * Build the section object from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {ISection}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ITranslation>(Translation, jsonObject.translations, true);
        }

        if (jsonObject.items && jsonObject.items.length > 0) {
            for (const item of jsonObject.items) {
                this.items.push(<BaseItem>ItemFactory.create(item.type, item));
            }
        }

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<IGroupSettings>(GroupSettings, jsonObject.settings, true);
        }

        return this;
    }

    /**
     * Can be used to add a new item to this group
     *
     * @param {BaseItem} item
     * @param {index} index
     * @returns {IGroup}
     */
    public addItem(item: BaseItem, index: number = -1): IGroup {
        if (!Group.canHoldItem(item.type)) {
            throw new Error('Tried to add unsupported item to a group: ' + this.uuid);
        }

        if (index >= 0 && index <= this.items.length) {
            this.items.splice(index, 0, item);
        } else {
            this.items.push(item);
        }

        return this;
    }

    /**
     * Allows a user to remove the given item from the group
     *
     * @param {IBaseItem} item
     */
    public removeItem(item: IBaseItem): void {
        const index: number = _.findIndex(this.items, { uuid: item.uuid });

        if (index > -1) {
            this.items.splice(index, 1);
        }
    }

    /**
     * Get logic in form of an array
     *
     * @returns {Array<IBaseItem|IBaseResponse>}
     */
    public getLogicItems(): Array<IBaseItem | IBaseResponse> {
        let logicArr: Array<IBaseItem | IBaseResponse> = [];
        if (this.logic) {
            logicArr.push(this);
        }

        if (this.items && this.items.length > 0) {
            for (const item of this.items) {
                logicArr = _.concat(logicArr, item.getLogicItems());
            }
        }

        return logicArr;
    }

    /**
     * Return number of items
     *
     * @returns {number}
     */
    public getItemCount(): number {
        return this.items.length;
    }

    /**
     * Gets an item by uuid
     *
     * @param {string} uuid
     * @returns {IBaseItem}
     */
    public getItemByUuid(uuid: string): IBaseItem {
        for (const item of this.items) {
            if (item.uuid === uuid) {
                return item;
            }
        }
        return null;
    }

    /**
     * Return all items of this group
     *
     * @returns {Array<IBaseItem>}
     */
    public getItems(): Array<IBaseItem> {
        return this.items;
    }

    /**
     * Move item from one position in array to a destination index
     *
     * @param {number} fromIndex
     * @param {number} toIndex
     */
    public moveItem(fromIndex: number, toIndex: number): void {
        if (toIndex < 0) {
            toIndex = 0;
        }

        if (toIndex > this.items.length) {
            toIndex = this.items.length;
        }
        this.items.splice(toIndex, 0, this.items.splice(fromIndex, 1)[0]);
    }

    /**
     * Retrieve the given translation for a locale
     *
     * @param {string} locale
     * @param {boolean} createOnNotFound
     * @returns {ITranslation}
     */
    public getTranslation(locale: string, createOnNotFound: boolean = true): ITranslation {
        return TranslationLocator.locate(this.translations, 'label', locale, createOnNotFound);
    }

    /**
     * Toggle collapse of elements within, show's question count
     */
    public toggleCollapse(): void {
        this.expanded = !this.expanded;
    }

    /**
     * Get the current index of the item
     *
     * @param {BaseItem} item
     * @returns {number}
     */
    public getItemIndex(item: BaseItem): number {
        return this.items.indexOf(item);
    }

    /**
     * Returns if this section contains at least 1 item that can be scored
     */
    public hasScoringQuestion(): boolean {
        for (const item of this.items) {
            if (item.isValidScoringItemType()) {
                return true;
            }
        }
    }

    /**
     * Returns if this section contains at least 1 item that can be tagged
     */
    public hasValidTagQuestion(): boolean {
        for (const item of this.items) {
            if (item.isTaggable()) {
                return true;
            }
        }

        return false;
    }

    /**
     * Loops down to every item to update an access tag's weight
     *
     * @param tagUuid
     * @param weight
     */
    public updateTagWeight(tagUuid: string, weight: number): void {
        super.updateTagWeight(tagUuid, weight);

        for (const item of this.items) {
            item.updateTagWeight(tagUuid, weight);
        }
    }

    /**
     * Adds tags of items contained in the group to a tag map
     *
     * @param tagObj
     */
    public buildTagMap(tagObj: { [uuid: string]: IWeightedTag }): void {
        for (const item of this.items) {
            item.buildTagMap(tagObj);
        }
    }

    /**
     * Find a tag within the items of the group by tag uuid
     *
     * @param tagUuid
     */
    public findTagByUuid(tagUuid: string): IWeightedTag {
        for (const item of this.items) {
            const foundTag: IWeightedTag = item.findTagByUuid(tagUuid);
            if (foundTag) {
                return foundTag;
            }
        }
    }
}
