import { BaseItem, IBaseItem } from './BaseItem';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';

/**
 * The text item interface
 */
export interface IText extends IBaseItem {
    content: string;
}

/**
 * The text item class
 */
export class Text extends BaseItem implements IText {
    type: string = 'text';
    content: string = '';

    @NotEnumerable
    _healthPoints: number = 3;

    /**
     * Return the health points used for the calculation of approximate time to complete
     * the survey. This overrides the BaseItem default value.
     */
    get healthPoints(): number {
        return this._healthPoints;
    }
}
