import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';
import { Scale } from '../../../../../../domain/surveys/items/Scale';
import { ISurveyService } from '../../../../../../services/SurveyService';

@Component('its.survey.module.surveys', ScaleRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/scale/ScaleRenderComponent.html'),
    controllerAs: 'vm',
    bindings: ScaleRenderComponent.getBindings(),
})
class ScaleRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'ScaleRenderComponent';
    static CID: string = 'itsScaleRender';
    static $inject: string[] = ['itsSurveyService', '$translate'];

    public item: Scale;
    public naLabel: string = null;

    public constructor(private surveyService: ISurveyService, private translate: ng.translate.ITranslateService) {
        super();
    }

    public $onInit(): void {
        this.naLabel = this.getNotApplicableLabel(this.translate, this.surveyService.getSourceLanguage());
    }
}
