import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';
import { ISurveyService } from '../../../../../../services/SurveyService';

@Component('its.survey.module.surveys', SliderRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/slider/SliderRenderComponent.html'),
    controllerAs: 'vm',
    bindings: SliderRenderComponent.getBindings(),
})
class SliderRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'SliderRenderComponent';
    static CID: string = 'itsSliderRender';
    static $inject: string[] = ['itsSurveyService', '$translate'];

    public naLabel: string = null;

    public constructor(private surveyService: ISurveyService, private translate: ng.translate.ITranslateService) {
        super();
    }

    public $onInit(): void {
        this.naLabel = this.getNotApplicableLabel(this.translate, this.surveyService.getSourceLanguage());
    }
}
