import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItem } from '../../../../../../domain/surveys/items/BaseItem';
import { CustomJSModal } from '../../../../modals/CustomJSModal';

@Component('its.survey.module.surveys', CustomJSSettingComponent.CID, {
    template: require('/app/modules/surveys/components/items/shared/custom-js-setting/CustomJSSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class CustomJSSettingComponent {
    static IID: string = 'CustomJSSettingComponent';
    static CID: string = 'itsCustomJsSetting';
    static $inject: string[] = ['$mdDialog'];

    public item: BaseItem;
    public showCustomJavascript: boolean = false;

    public constructor(private dialog: ng.material.IDialogService) {}

    $onInit(): void {
        if (this.item.settings.hasCustomJavascript()) {
            this.showCustomJavascript = true;
        }
    }

    public clearJavascript(): void {
        if (this.item.settings.hasCustomJavascript() && !this.showCustomJavascript) {
            this.item.settings.resetCustomJavascript();
        }
    }

    public openCustomJSModal(): void {
        this.dialog.show(
            CustomJSModal.instantiate({
                locals: {
                    item: this.item,
                },
            })
        );
    }
}
