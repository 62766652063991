import { BaseResponse, IBaseResponse } from './BaseResponse';
import { String } from '@intouch/its.essential/app/essential/utils/String';

export interface IImageResponse extends IBaseResponse {
    url: string;
    hasError: boolean;
    errorMessage: string;
}

export class ImageResponse extends BaseResponse implements IImageResponse {
    url: string = null;
    hasError: boolean = false;
    errorMessage: string = null;

    /**
     * Allow a user to quickly create a response item
     *
     * @param label
     * @param isOther
     * @returns {ISection}
     */
    public static make<T extends typeof BaseResponse>(label: string = '', isOther: boolean = false): InstanceType<T> {
        const response: IImageResponse = new ImageResponse();
        response.label = label;
        response.uuid = String.uuid();
        response.isOther = isOther;

        return response as InstanceType<T>;
    }

    /**
     * Returns the css class to be used on the upload image icon
     * @returns {string}
     */
    public getCssClass(): string {
        const baseClass: string = 'its-checkbox--upload-photo-icon ';
        if (this.url) {
            return baseClass + 'its-checkbox--upload-photo-icon-success';
        }
        if (this.hasError) {
            return baseClass + 'its-checkbox--upload-photo-icon-error';
        }
        return baseClass;
    }
}
