import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IPageService } from '../../services/PageService';
import { ISurveyApi } from '../../api/SurveyApi';
import { FroalaProvider } from '@intouch/its.essential/app/essential/domain/FroalaProvider';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import * as _ from 'lodash';
import { ITranslation } from '../../domain/surveys/Translation';
import { ISurveyService } from '../../services/SurveyService';
import { ISurveyLanguage, SurveyLanguage } from '../../domain/surveys/survey/SurveyLanguage';
import { BaseSurvey } from './BaseSurvey';
import { ILanguage, LanguageProvider } from '../../domain/LanguageProvider';
import { UpsellTool } from '@intouch/its.essential/app/essential/tools/UpsellTool';
import { ISiteNavigationService } from '@intouch/its.essential/app/essential/services/SiteNavigationService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IPhotoService, IParsedImage } from '@intouch/its.essential/app/essential/services/PhotoService';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';

/**
 * Form edit Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.surveys', TranslationsController.IID, TranslationsController)
class TranslationsController extends BaseSurvey {
    static IID: string = 'its.survey.module.surveys.TranslationsController';
    static $inject: Array<string> = [
        '$state',
        'APPCONFIG',
        'itsPageService',
        'itsSurveyApi',
        'itsSurveyService',
        'iteToaster',
        '$translate',
        'iteLogger',
        '$sce',
        '$mdDialog',
        '$stateParams',
        '$scope',
        'iteAccessService',
        'iteSiteNavigationService',
        'itePhotoService',
        'iteUserLocalTimeService',
    ];

    /**
     * Determines translation key to use
     * @type {ISurveyLanguage}
     */
    public currentLanguage: ISurveyLanguage = null;

    /**
     * languages available to select from
     * @type {ISurveyLanguage}
     */
    public languages: ISurveyLanguage = null;
    public sourceLanguageLabel: string = null;

    public froalaOptions: any = null;
    public upsellTool: UpsellTool = null;

    constructor(
        protected stateService: ng.ui.IStateService,
        protected config: any,
        protected pageService: IPageService,
        protected surveyApi: ISurveyApi,
        protected surveyService: ISurveyService,
        protected toaster: IToaster,
        protected translate: ng.translate.ITranslateService,
        protected logger: ILogger,
        protected sce: ng.ISCEService,
        protected dialog: ng.material.IDialogService,
        protected stateParams: ng.ui.IStateParamsService,
        protected scope: ng.IScope,
        private accessService: IAccessService,
        private siteNavService: ISiteNavigationService,
        private photoService: IPhotoService,
        protected userLocalTimeService: IUserLocalTimeService
    ) {
        super(
            stateService,
            dialog,
            stateParams,
            surveyService,
            toaster,
            pageService,
            surveyApi,
            scope,
            userLocalTimeService
        );

        this.upsellTool = new UpsellTool(this.accessService, this.siteNavService).setStateByProductTiers('survey', [
            'lead',
        ]);

        if (!this.upsellTool.showUpsell()) {
            this.load().then(() => {
                this.translationsLoad();
                const sourceLanguage: ILanguage = _.find(LanguageProvider.getAvailableLanguages(), {
                    locale: this.survey.settings.sourceLanguage,
                });
                if (sourceLanguage) {
                    this.sourceLanguageLabel = sourceLanguage.label;
                }
            });

            this.setUpFroala();
        }
    }

    public translationsLoad(): void {
        const defaultLanguage: ISurveyLanguage = _.find(this.survey.getAvailableLanguages(), {
            locale: this.survey.settings.sourceLanguage,
        });
        if (defaultLanguage && !_.find(this.survey.settings.supportedLanguages, { locale: defaultLanguage.locale })) {
            this.survey.settings.supportedLanguages.unshift(defaultLanguage);
        }

        if (this.stateService.params['lang']) {
            this.currentLanguage = _.find(this.survey.settings.getSupportedLanguages(), (language: ISurveyLanguage) => {
                return language.locale === this.stateService.params['lang'];
            });
        } else {
            this.initializeSelectedLanguageDropdown();
        }
    }

    public saveSurvey(): void {
        this.save(this.survey).then(() => {
            const newReference: ISurveyLanguage = _.find(this.survey.settings.supportedLanguages, {
                locale: this.currentLanguage.locale,
            });
            if (newReference) {
                this.currentLanguage = newReference;
            }
        });
    }

    public publishSurvey(): void {
        this.publish(this.survey);
    }

    /**
     * Trust the string as HTML and allow angular to render it
     *
     * @param {string} html
     * @returns {string}
     */
    public trustAsHtml(html: string): string {
        return this.sce.trustAsHtml(html);
    }

    /**
     * Add selected language to the enabled language chip.
     * Prevent duplicates.
     * Initialize the selectedLanguage dropdown.
     */
    public addSelectedLanguage(): void {
        if (this.survey.settings) {
            if (!this.survey.settings.supportedLanguages) {
                this.survey.settings.supportedLanguages = [];
            }

            const index: number = _.findIndex(this.survey.settings.supportedLanguages, (lang: ISurveyLanguage) => {
                return lang.locale === this.languages.locale;
            });

            if (index < 0) {
                this.survey.settings.supportedLanguages.push(
                    SurveyLanguage.make(this.languages.label, this.languages.locale, this.languages.default)
                );
                this.currentLanguage =
                    this.survey.settings.supportedLanguages[this.survey.settings.supportedLanguages.length - 1];
            }
        }
    }

    /**
     * Only show translations form if the user has enabled more than 1 language
     *
     * @return {boolean}
     */
    public showTranslations(): boolean {
        if (this.survey && this.survey.settings) {
            return this.survey.settings.supportedLanguages && this.survey.settings.supportedLanguages.length > 1;
        } else {
            return false;
        }
    }

    /**
     * Chip - remove handler. Allow user to delete a translation. Prevent deletion of the English chip because english must always be here.
     *
     * @param chip
     * @param index
     */
    public onRemoveSelectedLanguages(chip: any, index: number): void {
        if (chip.locale === this.survey.settings.sourceLanguage) {
            if (!this.survey.settings.supportedLanguages) {
                this.survey.settings.supportedLanguages = [];
            }
            this.survey.settings.supportedLanguages.splice(index, 0, chip);
        } else {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: this.translate.instant('SURVEYS.TRANSLATIONS_TAB.DELETE_LANGUAGE_DIALOG_TITLE'),
                            description: this.translate.instant(
                                'SURVEYS.TRANSLATIONS_TAB.DELETE_LANGUAGE_DIALOG_CONTENT'
                            ),
                            confirmText: this.translate.instant('GENERAL.DELETE'),
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((response) => {
                    if (response) {
                        this.removeTranslation(this.survey, chip.locale);
                        this.initializeSelectedLanguageDropdown();
                        this.surveyService.save(this.survey).then(() => {
                            this.toaster.success('SURVEYS.TRANSLATIONS_TAB.LANGUAGE_DELETED');
                        });
                    } else {
                        if (!this.survey.settings.supportedLanguages) {
                            this.survey.settings.supportedLanguages = [];
                        }
                        this.survey.settings.supportedLanguages.splice(index, 0, chip);
                    }
                });
        }
    }

    /**
     * Strip out deleted translations
     *
     * @param {Array<ITranslation>} translations
     * @param {string} locale
     * @return {Array<ITranslation>}
     */
    private stripLocale(translations: Array<ITranslation>, locale: string): Array<ITranslation> {
        return _.filter(translations, (o: any) => {
            return o.locale !== locale;
        });
    }

    /**
     * Cycle through all the elements in a survey in order to delete translations not required anymore.
     *
     * @param survey
     * @param {string} locale
     */
    private removeTranslation(survey: any, locale: string): void {
        for (const key in survey) {
            if (survey.hasOwnProperty(key)) {
                if (key === 'translations') {
                    survey['translations'] = this.stripLocale(survey['translations'], locale);
                } else {
                    if (typeof survey[key] === 'object') {
                        this.removeTranslation(survey[key], locale);
                    }
                }
            }
        }
    }

    /**
     * Reset selected language dropdown on load/update.
     */
    private initializeSelectedLanguageDropdown(): void {
        if (this.survey.settings.supportedLanguages && this.survey.settings.supportedLanguages.length > 1) {
            for (let i: number = 0; i < this.survey.settings.supportedLanguages.length; i++) {
                if (this.survey.settings.supportedLanguages[i].locale !== this.survey.settings.sourceLanguage) {
                    this.currentLanguage = this.survey.settings.supportedLanguages[i];
                    break;
                }
            }
        } else {
            this.currentLanguage = this.survey.settings.supportedLanguages[0];
        }
    }

    private setUpFroala(): void {
        this.froalaOptions = FroalaProvider.defaultConfig(this.translate.instant('SURVEYS.ITEMS.TEXT.TYPE_SOMETHING'));

        if (this.config.froala && this.config.froala.key) {
            this.froalaOptions.key = this.config.froala.key;
        } else {
            this.logger.warn('Unable to set Froala key');
        }

        this.froalaOptions.pluginsEnabled.push('image');
        this.froalaOptions.toolbarButtons.splice(3, 0, 'insertImage');
        this.froalaOptions.imageUpload = true;
        this.froalaOptions.imageAllowedTypes = ['jpg', 'jpeg', 'png'];

        const self: this = this;
        this.froalaOptions.events = {
            'image.beforeUpload': function (images: Array<any>): boolean {
                const file: any = images[0];
                self.photoService
                    .parse(file, null, null, false)
                    .then((parsedImage: IParsedImage) => {
                        self.surveyApi
                            .addPhoto(parsedImage.base64)
                            .then((result) => {
                                this.image.insert(result.data.large);
                            })
                            .finally(() => {
                                this.image.hideProgressBar(true); // close froala upload progress bar
                            });
                    })
                    .catch((error) => {
                        self.toaster.error('ERRORS.PHOTO_NOT_ADDED', error);
                    });
                return false; // cancels progression of froala upload path
            },
        };
        this.froalaOptions = FroalaProvider.convertOptionsToV4(this.froalaOptions);
    }
}
