import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ICustomField } from '../../../domain/settings/CustomField';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { ISurveyApi } from '../../../api/SurveyApi';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { CustomFieldCreateModal } from './CustomFieldCreateModal';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { ISurveySession } from '../../../services/SurveySession';
import { DatatableSearch } from '@intouch/its.essential/app/essential/domain/datatable/DatatableSearch';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ISiteNavigationService } from '@intouch/its.essential/app/essential/services/SiteNavigationService';
import { UpsellTool } from '@intouch/its.essential/app/essential/tools/UpsellTool';

/**
 * Contact Center Custom Fields Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.settings', CustomFieldsController.IID, CustomFieldsController)
class CustomFieldsController {
    static IID: string = 'its.survey.module.settings.CustomFieldsController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        '$stateParams',
        '$translate',
        'iteToaster',
        'itsSurveyApi',
        'itsSurveySession',
        'iteAccessService',
        'iteSiteNavigationService',
    ];

    public hasContactAdmin: boolean = false;
    public upsellText: Array<string> = [];
    public upsellTool: UpsellTool = null;
    private pager: IPager = null;
    private datatableSearch: DatatableSearch = new DatatableSearch();
    private customFields: Array<ICustomField> = [];
    private loading: boolean = false;
    private hasError: boolean = false;
    /**
     * Create the controller
     *
     * @param stateService
     * @param dialog
     * @param stateParams
     * @param translate
     * @param toaster
     * @param surveyApi
     * @param session
     * @param accessService
     * @param siteNavigationService
     */
    constructor(
        private stateService: ng.ui.IStateService,
        private dialog: ng.material.IDialogService,
        private stateParams: ng.ui.IStateParamsService,
        private translate: ng.translate.ITranslateService,
        private toaster: IToaster,
        private surveyApi: ISurveyApi,
        private session: ISurveySession,
        private accessService: IAccessService,
        private siteNavigationService: ISiteNavigationService
    ) {
        this.hasContactAdmin = this.session.getToken().getUser().hasAcl('contact_center_admin');
        this.pager = Pager.make(
            this.stateService.params['page'],
            this.stateService.params['sort_by'],
            this.stateService.params['order']
        );
        this.datatableSearch.term = this.stateService.params['search'] || null;

        this.upsellTool = new UpsellTool(this.accessService, this.siteNavigationService).setStateByProductFeature(
            'survey',
            'custom-fields'
        );

        this.translate([
            'CONTACT_CENTER.UPSELL.CUSTOM_FIELDS.CONTENT_LN_1',
            'CONTACT_CENTER.UPSELL.CUSTOM_FIELDS.CONTENT_LN_2',
            'ESSENTIAL.GENERAL.CONTACT_ADMIN',
            'ESSENTIAL.GENERAL.FIND_ADMIN',
        ]).then((translations) => {
            this.upsellText = [
                translations['CONTACT_CENTER.UPSELL.CUSTOM_FIELDS.CONTENT_LN_1'],
                translations['CONTACT_CENTER.UPSELL.CUSTOM_FIELDS.CONTENT_LN_2'],
            ];

            if (!this.upsellTool.isAdmin) {
                this.upsellText.push(
                    translations['ESSENTIAL.GENERAL.CONTACT_ADMIN'],
                    translations['ESSENTIAL.GENERAL.FIND_ADMIN']
                );
            }
        });

        if (!this.upsellTool.showUpsell()) {
            this.load();
        }
    }

    /**
     * Allow a user to search for users by name
     */
    public search(): void {
        this.customFields = [];
        this.pager.currentPage = 1;
        this.goToSelf(this.pager);
    }

    /**
     * Allow a user to toggle the sort order of a column
     *
     * @param field
     */
    public toggleSort(field: string): void {
        if (this.pager.isCurrentSort(field)) {
            this.pager.toggleSortOrder();
        }
        this.pager.sortBy = field;
        this.goToSelf(this.pager);
    }

    public goToPage(page: string, uuid: string): void {
        this.stateService.go('home.settings.custom-fields.' + page, { uuid: uuid });
    }

    public create(createAnother: boolean = false): void {
        this.dialog
            .show(
                CustomFieldCreateModal.instantiate({
                    locals: {
                        createAnother: createAnother,
                    },
                })
            )
            .then((data: { customField: ICustomField; createAnother: boolean }) => {
                this.toaster.success(
                    this.translate.instant('CONTACT_CENTER.SETTINGS.CREATED_CUSTOM_FIELD', {
                        label: data.customField.label,
                    })
                );
                this.load();
                if (data.createAnother) {
                    this.create(true);
                }
            });
    }

    public edit(customField: ICustomField): void {
        this.dialog
            .show(
                CustomFieldCreateModal.instantiate({
                    locals: {
                        customField: customField,
                    },
                })
            )
            .then((newCustomField: ICustomField) => {
                this.toaster.success(
                    this.translate.instant('CONTACT_CENTER.SETTINGS.UPDATED_CUSTOM_FIELD', {
                        label: newCustomField.label,
                    })
                );
                this.load();
            });
    }

    /**
     * Show confirmation modal and if confirmed, delete the specified survey and reload the list
     * of surveys
     *
     * @param {ICustomField} customField
     */
    public remove(customField: ICustomField): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translate.instant('CONTACT_CENTER.SETTINGS.DELETE.TITLE'),
                        description: this.translate.instant('CONTACT_CENTER.SETTINGS.DELETE.TEXT', {
                            label: customField.label,
                        }),
                        confirmText: this.translate.instant('GENERAL.DELETE'),
                        cancelText: this.translate.instant('GENERAL.CANCEL'),
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((response: boolean) => {
                if (response) {
                    this.surveyApi
                        .deleteCustomField(customField)
                        .then(() => {
                            this.toaster.success('CONTACT_CENTER.SETTINGS.DELETE.SUCCESS');
                        })
                        .catch(() => {
                            this.toaster.error('CONTACT_CENTER.SETTINGS.FAILED_CUSTOM_FIELD_DELETE');
                        })
                        .finally(() => {
                            this.load();
                        });
                }
            });
    }

    /**
     * Reload page with pager information
     *
     * @param {IPager} pager
     */
    public goToSelf(pager: IPager): void {
        this.stateService.go(
            'home.settings.custom-fields',
            {
                search: this.datatableSearch.term,
                page: pager.currentPage,
                sort_by: pager.sortBy,
                order: pager.order,
            },
            {
                notify: false, // prevent the events onStart and onSuccess from firing
                reload: false, // prevent reload of the current state
                location: 'replace', // replace the last record when changing the params so you don't hit the back button and get old params
                inherit: true, // inherit the current params on the url
            }
        );
        this.load();
    }

    /**
     * Allow a user to go to the next page of submissions
     */
    public next(): void {
        if (this.pager.canGoNext()) {
            this.pager.currentPage++;
            this.goToSelf(this.pager);
        }
    }

    /**
     * Allow a user to go to the previous page of submissions
     */
    public prev(): void {
        if (this.pager.canGoPrevious()) {
            this.pager.currentPage--;
            this.goToSelf(this.pager);
        }
    }

    private load(): void {
        this.loading = true;
        this.surveyApi
            .findCustomFields(this.pager, this.datatableSearch.term)
            .then((results: PagedEntities) => {
                this.customFields = results.getEntities();
                this.pager = results.getPager();
                this.hasError = false;
            })
            .catch(() => {
                this.hasError = true;
                this.toaster.error('ERRORS.SETTINGS.FAILED_CUSTOM_FIELDS_LOAD');
            })
            .finally(() => {
                this.loading = false;
            });
    }
}
