import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Audio } from '../../../../../../domain/surveys/items/Audio';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';

@Component('its.survey.module.surveys', AudioInputSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/audio-input/AudioInputSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: AudioInputSettingsComponent.getBindings(),
})
class AudioInputSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'AudioInputSettingsComponent';
    static CID: string = 'itsAudioInputSettings';
    static $inject: string[] = [];

    public item: Audio;
}
