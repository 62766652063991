import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { BaseItem, IBaseItem } from './BaseItem';
import { IImageSettings, ImageSettings } from './ImageSettings';
import * as _ from 'lodash';
import { ITranslatedEntity } from '../../ITranslatedEntity';

/**
 * An interface for the image sizes
 */
export interface ISizes extends IEntity {
    480: string;
    960: string;
    1080: string;
    1440: string;
    original: string;
}

/**
 * A class for the image sizes
 */
export class Sizes extends Entity implements ISizes {
    480: string = null;
    960: string = null;
    1080: string = null;
    1440: string = null;
    original: string = null;
}

/**
 * A form translation interface
 */
export interface IImageTranslation extends IEntity {
    locale: string;
    label: string;
    sizes: ISizes;
}

/**
 * A form translation object
 */
export class ImageTranslation extends Entity implements IImageTranslation {
    locale: string = null;
    label: string = null;
    sizes: ISizes = null;

    /**
     * Build the translations for an image fromJSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IImageTranslation}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): IImageTranslation {
        super.fromJson(jsonObject, true);

        if (jsonObject.sizes) {
            this.sizes = EntityBuilder.buildOne<ISizes>(Sizes, jsonObject.sizes, true);
        }

        return this;
    }
}

/**
 * A checkbox form item interface
 */
export interface IImage extends IBaseItem {
    settings: IImageSettings;
    useOriginal: boolean;
    translations: Array<IImageTranslation>;
    sizes: ISizes;
    required: boolean;

    setTranslatedImage(locale: string, sizes: ISizes): void;
}

/**
 * A checkbox form item class
 */
export class Image extends BaseItem implements IImage, ITranslatedEntity {
    type: string = 'image';
    useOriginal: boolean = true;
    settings: IImageSettings = new ImageSettings();
    translations: any = [];
    required: boolean = false;
    sizes: ISizes = null;

    /**
     * Find existing translation by locale and replace sizes or create new translation
     *
     * @param {string} locale
     * @param {ISizes} sizes
     */
    public setTranslatedImage(locale: string, sizes: ISizes): void {
        const existingTranslationIndex: number = _.findIndex(this.translations, { locale: locale });

        if (existingTranslationIndex > -1) {
            this.translations[existingTranslationIndex].sizes = sizes;
        } else {
            const newTranslation: IImageTranslation = new ImageTranslation();
            newTranslation.sizes = sizes;
            newTranslation.locale = locale;
            this.translations.push(newTranslation);
        }
    }

    public getTranslationKeys(): Array<string> {
        return _.concat(super.getTranslationKeys(), 'sizes');
    }

    /**
     * Gets an original image by locale
     *
     * @param locale
     */
    public getImageByLocale(locale: string): string {
        for (const translation of this.translations) {
            if (translation.locale === locale && translation.sizes && translation.sizes.original) {
                return translation.sizes.original;
            }
        }

        return null;
    }

    /**
     * Build the image from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {IImage}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): this {
        super.fromJson(jsonObject, true);

        if (jsonObject.sizes) {
            this.sizes = EntityBuilder.buildOne<ISizes>(Sizes, jsonObject.sizes, true);
        }

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<IImageTranslation>(
                ImageTranslation,
                jsonObject.translations,
                true
            );
        }

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<IImageSettings>(ImageSettings, jsonObject.settings, true);
        }

        return this;
    }
}
