import { Configuration } from '@intouch/its.essential/app/essential/decorators/Configuration';
import { StateRerouter } from '@intouch/its.essential/app/essential/utils/StateRerouter';

/**
 * Will setup the routing states
 *
 * @param $stateProvider
 */
@Configuration('its.survey.module.contactCenter', ContactCenterConfiguration)
class ContactCenterConfiguration {
    /**
     * Create the instance of the configuration used for angular binding
     *
     * @returns {any}
     */
    public static instance(): any {
        const config: any = ($stateProvider: ng.ui.IStateProvider) => new ContactCenterConfiguration($stateProvider);
        config.$inject = ['$stateProvider'];
        return config;
    }

    /**
     * Create the configuration class
     *
     * @param stateProvider
     */
    constructor(private stateProvider: ng.ui.IStateProvider) {
        this.buildStates();
    }

    /**
     * Initialize the forms module
     */
    public buildStates(): void {
        this.stateProvider
            .state('home.contact-center', {
                url: '/contact-center?page&sort_by&order&search&filters',
                data: { title: 'CONTACT_CENTER.CONTACT_CENTER', translate: true },
                template: require('/app/modules/contact-center/ContactCenter.html'),
                controller: 'its.survey.module.contactCenter.ContactCenterController as vm',
                resolve: {
                    hasFeatureEnabled: StateRerouter.onNoProductFeature(
                        'survey',
                        'email-distributions',
                        {
                            state: 'home.surveys.listing',
                        },
                        undefined,
                        ['authenticatedUser']
                    ),
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                    },
                    sort_by: {
                        value: 'updated_at',
                        squash: true,
                    },
                    order: {
                        value: 'desc',
                        squash: true,
                    },
                    search: {
                        value: null,
                        squash: true,
                    },
                    filters: {
                        value: null,
                        squash: true,
                    },
                },
            })
            .state('home.contact-center.add-contacts', {
                url: '/:contactListUuid/add-contacts',
                data: { title: 'CONTACT_CENTER.CONTACT_CENTER', translate: true },
                views: {
                    '@home': {
                        template: require('/app/modules/contact-center/contact-lists/add-contacts/AddContacts.html'),
                        controller: 'its.survey.module.contactCenter.AddContactsController as vm',
                    },
                },
            })
            .state('home.contact-center.contact-details', {
                url: '/contact/:uuid',
                data: { title: 'CONTACT_CENTER.CONTACT_CENTER', translate: true },
                views: {
                    '@home': {
                        template: require('/app/modules/contact-center/contact-details/ContactDetails.html'),
                        controller: 'its.survey.module.contactCenter.ContactDetailsController as vm',
                    },
                },
            })
            .state('home.contact-center.create', {
                url: '/contact-create?contact_list',
                data: { title: 'CONTACT_CENTER.CONTACT_CENTER', translate: true },
                views: {
                    '@home': {
                        template: require('/app/modules/contact-center/contact-details/ContactDetails.html'),
                        controller: 'its.survey.module.contactCenter.ContactDetailsController as vm',
                    },
                },
                params: {
                    contact_list: {
                        value: null,
                        squash: true,
                    },
                },
            })
            .state('home.contact-center.contact-upload', {
                url: '/contact-upload',
                data: { title: 'CONTACT_CENTER.CONTACT_CENTER', translate: true },
                views: {
                    '@home': {
                        template: require('/app/modules/contact-center/contact-upload/ContactUpload.html'),
                        controller: 'its.survey.module.contactCenter.ContactUploadController as vm',
                    },
                },
            })
            .state('home.contact-center.export-download', {
                url: '/export/download/:uuid',
                data: { title: 'CONTACT_CENTER.DOWNLOAD_EXPORT', translate: true },
                views: {
                    '@home': {
                        template: require('/app/modules/contact-center/contact-download/ContactDownload.html'),
                        controller: 'its.survey.module.contactCenter.ContactDownloadController as vm',
                    },
                },
            });
    }
}
