import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', SpanSettingComponent.CID, {
    template: require('/app/modules/surveys/components/items/shared/sliders/span-setting/SpanSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class SpanSettingComponent {
    static IID: string = 'SpanSettingComponent';
    static CID: string = 'itsSpanSetting';
    static $inject: string[] = [];
}
