import { IThemeSettings } from '../ThemeSettings';
import { ThemeType } from '../../../../services/ThemeService';
import { BaseImageTheme } from './BaseImageTheme';

export class HomeOfficeTheme extends BaseImageTheme {
    public slug: ThemeType = 'home-office';

    public constructor() {
        super('workspace.jpg');
        // main bg

        // padding
        this.customizations['its-theme--content-container']['margin-top'] = '60px';
        this.customizations['its-theme--content-container']['margin-bottom'] = '60px';
        // survey bg
        // survey bg color
        this.customizations['its-theme--background-content']['background-color'] = 'transparent';
        this.customizations['its-theme--background-content']['box-shadow'] = 'none';

        this.customizations['its-theme--question-container']['background-color'] = 'rgba(255,255,255,1)';
        // question font
        this.customizations['its-theme--question-label']['color'] = '#090c11';
        // question spacing
        this.customizations['its-theme--question-container']['margin-top'] = '10px';
        this.customizations['its-theme--question-container']['margin-bottom'] = '10px';
        // drop shadow
        this.customizations['its-theme--question-container']['box-shadow'] = '0 3px 15px 0 rgb(0 0 0 / 20%)';
        this.customizations['its-theme--title-container']['box-shadow'] = '0 3px 15px 0 rgb(0 0 0 / 20%)';
        this.customizations['its-theme--button-container']['box-shadow'] = '0 3px 15px 0 rgb(0 0 0 / 20%)';
        // help font
        this.customizations['its-theme--help-text']['color'] = '#444444';
        // font family
        this.customizations['its-theme--font']['font-family'] = 'Courier New';
        this.customizations['::placeholder']['font-family'] = 'Courier New';
        this.customizations['::placeholder']['color'] = '#444444';
        // title
        this.customizations['its-theme--title']['color'] = '#8b4f35';
        // brand color
        this.customizations['its-theme--brand-primary']['background-color'] = '#8b4f35';

        // border radius
        this.customizations['its-theme--background-content']['border-radius'] = '4px';
        this.customizations['its-theme--question-container']['border-radius'] = '4px';
        this.customizations['its-theme--title-container']['border-radius'] = '4px';
        this.customizations['its-theme--button-container']['border-radius'] = '4px';
    }

    public getName(): string {
        return 'SURVEYS.THEMES.HOME_OFFICE';
    }

    public getSettings(): IThemeSettings {
        return this.customizations;
    }
}
