import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IParsedImage, IPhotoService } from '@intouch/its.essential/app/essential/services/PhotoService';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IBaseResponse } from '../../../../../../../domain/surveys/items/BaseResponse';
import { SurveyApi } from '../../../../../../../api/SurveyApi';
import { IIconResponse } from '../../../../../../../domain/surveys/items/IconResponse';
import { IconSelectModal } from '../../../../../modals/IconSelectModal';

@Component('its.survey.module.surveys', ItemResponsesComponent.CID, {
    template: require('/app/modules/surveys/components/items/response-items/shared/responses/ItemResponsesComponent.html'),
    controllerAs: 'vm',
    bindings: {
        responses: '<',
        type: '<',
        onResponseAdd: '&',
        onResponseRemove: '&',
    },
})
class ItemResponsesComponent {
    static IID: string = 'ItemResponsesComponent';
    static CID: string = 'itsItemResponses';
    static $inject: string[] = ['$translate', '$mdDialog', 'iteToaster', 'itePhotoService', 'itsSurveyApi'];

    public responses: IBaseResponse[];
    public type: string;
    public onResponseAdd: (data: any) => void;
    public onResponseRemove: (data: any) => void;

    public uploadInProgress: boolean = false;

    public constructor(
        private translate: ng.translate.ITranslateService,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private photoService: IPhotoService,
        private surveyApi: SurveyApi
    ) {}

    public addResponse(index: number): void {
        this.onResponseAdd({ responseType: this.type || null, index: index });
    }

    public removeResponse(response: IBaseResponse): void {
        this.onResponseRemove({ response: response });
    }

    public openIconSelectModal(response: IIconResponse): void {
        this.dialog.show(
            IconSelectModal.instantiate({
                locals: {
                    response: response,
                },
            })
        );
    }

    public uploadResponseImage(file: File, response: any): void {
        this.uploadInProgress = true;
        response.hasError = false;
        if (!file) {
            return;
        }
        response.errorMessage = null;
        if (file.size > 2000000) {
            response.errorMessage = this.translate.instant('ERRORS.IMAGE_TOO_LARGE');
            response.hasError = true;
            return;
        } else if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            response.errorMessage = this.translate.instant('ERRORS.IMAGE_NOT_JPEG_PNG');
            response.hasError = true;
            return;
        }

        this.photoService
            .parse(file, null, null, true)
            .then((parsedImage: IParsedImage) => {
                this.surveyApi.addPhoto(parsedImage.base64).then((data) => {
                    response.url = data.data.original;
                });
            })
            .catch(() => {
                this.toaster.warn('ERRORS.PHOTO_NOT_ADDED');
            })
            .finally(() => {
                this.uploadInProgress = false;
            });
    }
}
