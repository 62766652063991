import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BulkUploaderBase } from '@intouch/its.essential/app/essential/components/bulkuploader/BulkUploaderBase';
import { IFileProcessingService } from '@intouch/its.essential/app/essential/services/FileProcessingService';
import { INavigationService } from '../../../../../services/NavigationService';

@Component('its.survey.module.contactCenter', AddContactsToListUploader.CID, {
    templateUrl: AddContactsToListUploader.getDefaultTemplate(),
    controllerAs: 'vm',
    bindings: AddContactsToListUploader.getBindings(),
})
class AddContactsToListUploader extends BulkUploaderBase {
    static IID: string = 'AddContactsUploader';
    static CID: string = 'itsAddContactsToListUploader';

    static $inject: Array<string> = ['iteFileProcessingService', 'itsNavigationService', '$state', '$translate'];

    public uploadInstructionText: string = null;
    public attestations: Array<{ label: string; value: boolean }> = [];

    constructor(
        fileProcessing: IFileProcessingService,
        private navigationService: INavigationService,
        private stateService: ng.ui.IStateService,
        translate: ng.translate.ITranslateService
    ) {
        super(fileProcessing, translate);
        navigationService.setBackRoute('home.contact-center');
        this.templates[0] =
            'app/modules/contact-center/contact-lists/add-contacts/components/AddContactsToListUploaderStep1.html';
        this.templates[2] =
            'app/modules/contact-center/contact-lists/add-contacts/components/AddContactsToListUploaderStep3.html';

        this.uploadInstructionText = translate.instant('CONTACT_CENTER.UPLOAD_ADD_CONTACTS_TO_CONTACT_LIST');

        this.attestations.push({
            label: translate.instant('CONTACT_CENTER.BULK_UPLOAD.ATTESTATION'),
            value: false,
        });
    }

    public goBack(): void {
        this.navigationService.clearBackRoute();
        this.stateService.go('home.contact-center');
    }

    /**
     * Ensure all attestations are satisfied.
     */
    public attestationsSatisfied(): boolean {
        for (const attestation of this.attestations) {
            if (!attestation.value) {
                return false;
            }
        }

        return true;
    }
}
