import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.survey.module.surveys', GroupSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/group/GroupSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class GroupSettingsComponent {
    static IID: string = 'GroupSettingsComponent';
    static CID: string = 'itsGroupSettings';
    static $inject: string[] = [];
}
