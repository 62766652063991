export interface ITranslationEntityAnalysis {
    missingTranslationsCount: number;
    translationsCount: number;
    label: string;
    uuid: string;
    addMissing(count?: number): void;
    addTranslated(): void;
    analyze(translation: string): void;
    hasMissingTranslations(): boolean;
}

export class TranslationEntityAnalysis implements ITranslationEntityAnalysis {
    missingTranslationsCount: number = 0;
    translationsCount: number = 0;

    constructor(public uuid: string, public label: string) {}

    public analyze(translation: string): void {
        if (!translation) {
            this.addMissing();
        } else {
            this.addTranslated();
        }
    }

    public addMissing(missingCount: number = 1): void {
        this.missingTranslationsCount += missingCount;
        this.translationsCount += missingCount;
    }

    public addTranslated(): void {
        this.translationsCount++;
    }

    public hasMissingTranslations(): boolean {
        return this.missingTranslationsCount > 0;
    }
}
