import { IThemeSettings } from '../ThemeSettings';
import { ThemeType } from '../../../../services/ThemeService';
import { BaseImageTheme } from './BaseImageTheme';

export class FineDiningTheme extends BaseImageTheme {
    public slug: ThemeType = 'fine-dining';

    public constructor() {
        super('fine_dining.jpg');
        // main bg

        // padding
        this.customizations['its-theme--content-container']['margin-top'] = '40px';
        this.customizations['its-theme--content-container']['margin-bottom'] = '40px';
        // survey bg
        this.customizations['its-theme--background-content']['background-color'] = 'rgba(255,255,255,1)';
        // question font
        this.customizations['its-theme--question-label']['color'] = '#090c11';
        // help font
        this.customizations['its-theme--help-text']['color'] = '#444444';
        // font family
        this.customizations['its-theme--font']['font-family'] = 'Times New Roman';
        this.customizations['::placeholder']['font-family'] = 'Times New Roman';
        this.customizations['::placeholder']['color'] = '#444444';
        // title
        this.customizations['its-theme--title']['color'] = '#090c11';
        // brand color
        this.customizations['its-theme--brand-primary']['background-color'] = '#ce7e4d';

        // border radius
        this.customizations['its-theme--background-content']['border-radius'] = '0';
        this.customizations['its-theme--question-container']['border-radius'] = '0';
        this.customizations['its-theme--title-container']['border-radius'] = '0';
        this.customizations['its-theme--button-container']['border-radius'] = '0';
    }

    public getName(): string {
        return 'SURVEYS.THEMES.FINE_DINING';
    }

    public getSettings(): IThemeSettings {
        return this.customizations;
    }
}
