import * as angular from 'angular';

const module: any = angular.module('its.survey.module.surveys.distributions', []);

import './_configuration';

import './DistributionsController';
import './email/EmailController';
import './email/CreateEmailController';
import './email-reminder/CreateEmailReminderController';
import './link/LinkController';
import './health/HealthController';
import './website/WebsiteController';
import './qr-code/QrCodeController';
import './social-media/SocialMediaController';
import './sms/SmsController';
import './sms/CreateSmsController';

export default module;
