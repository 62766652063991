import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', VideoInputRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/video-input/VideoInputRenderComponent.html'),
    controllerAs: 'vm',
    bindings: VideoInputRenderComponent.getBindings(),
})
class VideoInputRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'VideoInputRenderComponent';
    static CID: string = 'itsVideoInputRender';
    static $inject: string[] = [];
}
