import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';
import { Rating } from '../../../../../../domain/surveys/items/Rating';

@Component('its.survey.module.surveys', RatingRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/rating/RatingRenderComponent.html'),
    controllerAs: 'vm',
    bindings: RatingRenderComponent.getBindings(),
})
class RatingRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'RatingRenderComponent';
    static CID: string = 'itsRatingRender';
    static $inject: string[] = [];

    public item: Rating;

    public buildIconArray(): Array<string> {
        const maxScore: number = this.item.settings.maxScore;
        if (this.item.settings.icon !== 'tag_faces') {
            return new Array(maxScore).fill(this.item.settings.icon);
        } else {
            const scoreFace1: string = 'sentiment_dissatisfied';
            const scoreFace2: string = 'sentiment_neutral';
            const scoreFace3: string = 'sentiment_satisfied';
            const scoreFace4: string = 'insert_emoticon';
            const scoreFace5: string = 'sentiment_very_satisfied';
            switch (maxScore) {
                case 2:
                    return [scoreFace1, scoreFace5];
                case 3:
                    return [scoreFace1, scoreFace3, scoreFace5];
                case 4:
                    return [scoreFace1, scoreFace2, scoreFace4, scoreFace5];
                case 5:
                    return [scoreFace1, scoreFace2, scoreFace3, scoreFace4, scoreFace5];
                case 6:
                    return [scoreFace1, scoreFace2, scoreFace3, scoreFace3, scoreFace4, scoreFace5];
                case 7:
                    return [scoreFace1, scoreFace2, scoreFace2, scoreFace3, scoreFace4, scoreFace4, scoreFace5];
                case 8:
                    return [
                        scoreFace1,
                        scoreFace2,
                        scoreFace2,
                        scoreFace3,
                        scoreFace3,
                        scoreFace4,
                        scoreFace4,
                        scoreFace5,
                    ];
                case 9:
                    return [
                        scoreFace1,
                        scoreFace2,
                        scoreFace2,
                        scoreFace3,
                        scoreFace3,
                        scoreFace3,
                        scoreFace4,
                        scoreFace4,
                        scoreFace5,
                    ];
                case 10:
                    return [
                        scoreFace1,
                        scoreFace1,
                        scoreFace2,
                        scoreFace2,
                        scoreFace3,
                        scoreFace3,
                        scoreFace4,
                        scoreFace4,
                        scoreFace5,
                        scoreFace5,
                    ];
                default:
                    return [scoreFace1, scoreFace5];
            }
        }
    }
}
