import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItem, IBaseItem } from '../../../../../../domain/surveys/items/BaseItem';
import { IWeightedTag } from '../../../../../../domain/surveys/items/shared/WeightedTag';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import { SurveyService } from '../../../../../../services/SurveyService';
import { Survey } from '../../../../../../domain/surveys/survey/Survey';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';

@Component('its.survey.module.surveys', TagSettingComponent.CID, {
    template: require('/app/modules/surveys/components/items/shared/tag-setting/TagSettingComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
        hideText: '<',
    },
})
class TagSettingComponent {
    static IID: string = 'TagSettingComponent';
    static CID: string = 'itsTagSetting';
    static $inject: string[] = ['itsSurveyService', '$scope'];

    public item: BaseItem;
    public survey: Survey;
    public hideText: boolean;

    public constructor(private surveyService: SurveyService, private scope: ng.IScope) {}

    public $onInit(): void {
        this.subscribeToSurvey();
    }

    public mapTags(item: IBaseItem): void {
        if (!this.survey) {
            return;
        }
        if (item.accessTags && item.accessTags.length > 0) {
            item.accessTags = item.accessTags.map((tag: IWeightedTag) => {
                return {
                    name: tag.name,
                    uuid: tag.uuid,
                    weight: NumberUtils.isNumber(tag.weight) ? tag.weight : this.survey.getTagWeight(tag.uuid),
                };
            });
        }
    }

    private subscribeToSurvey(): void {
        const subscription: SubjectSubscription = this.surveyService.surveyValue.subscribe((survey) => {
            this.survey = survey;
        });
        subscription.unsubscribeOnScopeDestruction(this.scope);
    }
}
