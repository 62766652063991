import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import SurveyDeleteModalHtml from './SurveyDeleteModal.html';

export class SurveyDeleteModal extends Modal {
    static $inject: Array<string> = ['$mdDialog'];

    public deleteConfirmText: string = '';

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = SurveyDeleteModalHtml;
        config.controller = SurveyDeleteModal;

        return super.instantiate(config);
    }

    constructor(private dialog: ng.material.IDialogService) {
        super();
    }

    public cancel(): void {
        this.dialog.cancel();
    }

    public delete(): void {
        this.dialog.hide(true);
    }

    public confirmIsDisabled(): boolean {
        return this.deleteConfirmText.toLowerCase() !== 'survey';
    }
}
