import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ISurveyApi } from '../../../api/SurveyApi';
import { INavigationService } from '../../../services/NavigationService';

@Controller('its.survey.module.contactCenter', ContactDownloadController.IID, ContactDownloadController)
class ContactDownloadController {
    static IID: string = 'its.survey.module.contactCenter.ContactDownloadController';
    static $inject: Array<string> = ['itsSurveyApi', 'itsNavigationService', 'itsSurveyApi', '$stateParams', '$window'];

    constructor(
        private api: ISurveyApi,
        private navigationService: INavigationService,
        private surveyApi: ISurveyApi,
        private stateParams: ng.ui.IStateParamsService,
        private window: ng.IWindowService
    ) {
        this.navigationService.setBackRoute('home.contact-center');
    }

    public download(): void {
        this.surveyApi.generateContactExportDownload(this.stateParams['uuid']).then((data) => {
            this.window.open(data.data.url);
        });
    }
}
