import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { IBaseItem } from '../items/BaseItem';
import { IBaseResponse } from '../items/BaseResponse';
import { ITranslation, Translation } from '../Translation';
import { String } from '@intouch/its.essential/app/essential/utils/String';
import { TranslationLocator } from '../TranslationLocator';
import { IDefaultContactField } from '../../contact-center/DefaultContactField';
import { ICustomField } from '../../settings/CustomField';
import moment from 'moment';

export interface ILogicCondition extends IEntity {
    type: string;
    operator: string;
    value: string | Date;
    tempValue: Date;
}

export class LogicCondition extends Entity {
    type: string = 'questions';
    operator: string = null;
    value: string | Date = null;

    @NotEnumerable
    tempValue: Date = null;

    public fromJson(json: any, convert: boolean): ILogicCondition {
        super.fromJson(json, convert);
        if (
            moment.utc(<string>this.value, 'YYYY-MM-DD', true).isValid() ||
            moment.utc(<string>this.value, 'YYYY-MM-DDTHH:mm:ss.SSS\\Z', true).isValid()
        ) {
            this.tempValue = new Date(<string>this.value);
            this.value = moment(<string>this.value).format('YYYY-MM-DD');
        }

        return this;
    }
}

export interface ILogicItem extends IEntity {
    type: string;
    operand: string;
    operandType: string;
    conditions: Array<ILogicCondition>;
    // not enumerables
    operators: Array<any>;
    question: IBaseItem | IBaseResponse;
    matrixQuestion: IBaseResponse;
    contactField: IDefaultContactField | ICustomField;
    selectionArray: Array<{ icon: string; value: string | number; label: string }>;
    fieldIsDeleted: boolean;

    setConditionTypes(): void;
}

export class LogicItem extends Entity implements ILogicItem {
    type: string = 'question';
    operand: string = null;
    operandType: string = null;
    conditions: Array<ILogicCondition> = [];
    @NotEnumerable
    operators: Array<any> = [];
    @NotEnumerable
    question: IBaseItem | IBaseResponse = null;
    @NotEnumerable
    matrixQuestion: IBaseResponse = null;
    @NotEnumerable
    contactField: IDefaultContactField | ICustomField = null;
    @NotEnumerable
    fieldIsDeleted: boolean = false;
    /**
     *  Used to create a readable rating label / value
     * @type {any[]}
     */
    @NotEnumerable
    selectionArray: Array<{ icon: string; value: string | number; label: string }> = [];

    public fromJson(jsonObject: any, convertToCamel?: boolean): ILogicItem {
        super.fromJson(jsonObject, true);

        if (jsonObject.conditions) {
            this.conditions = EntityBuilder.buildMany<ILogicCondition>(LogicCondition, jsonObject.conditions, true);
        }

        return this;
    }

    /**
     * Sets the type of condition for the selected question
     */
    public setConditionTypes(): void {
        for (const condition of this.conditions) {
            if (
                this.type === 'metadata' ||
                this.type === 'contact' ||
                this.type === 'location_tag' ||
                this.type === 'location_node'
            ) {
                condition.type = 'input';
            } else {
                switch (this.question.type) {
                    case 'calendar':
                    case 'textarea':
                    case 'input':
                    case 'number':
                    case 'hidden_input':
                    case 'slider':
                    case 'rating':
                        if (condition) {
                            condition.type = 'input';
                        }
                        break;
                    default:
                        if (condition) {
                            condition.type = 'response';
                        }
                        break;
                }
            }
        }
    }
}

export interface ICustomMessage extends IEntity {
    message: string;
    translations: Array<ITranslation>;
}

export class CustomMessage extends Entity implements ICustomMessage {
    message: string = null;
    translations: Array<ITranslation> = [];

    /**
     * Retrieve the given translation for a locale
     *
     * @param {string} locale
     * @param {boolean} createOnNotFound
     * @returns {ITranslation}
     */
    public getTranslation(locale: string, createOnNotFound: boolean = true): ITranslation {
        return TranslationLocator.locate(this.translations, 'message', locale, createOnNotFound);
    }

    public fromJson(jsonObject: any, convertToCamel?: boolean): ICustomMessage {
        super.fromJson(jsonObject, true);

        if (jsonObject.translations) {
            this.translations = EntityBuilder.buildMany<ITranslation>(Translation, jsonObject.translations, true);
        }

        return this;
    }
}

export interface ILogicSettings extends IEntity {
    customMessage: ICustomMessage;
    redirectUrl: string;
    enableRedirect: boolean;
    redirectSeconds: number;
    ignoreResults: boolean;
    sectionUuid: string;
    endSurvey: boolean;
}

export class LogicSettings extends Entity implements ILogicSettings {
    customMessage: ICustomMessage = null;
    redirectUrl: string = null;
    enableRedirect: boolean = false;
    redirectSeconds: number = null;
    ignoreResults: boolean = null;
    sectionUuid: string = null;
    endSurvey: boolean = true;

    public fromJson(jsonObject: any, convertToCamel?: boolean): ILogicSettings {
        super.fromJson(jsonObject, true);

        if (jsonObject.custom_message) {
            this.customMessage = EntityBuilder.buildOne<ICustomMessage>(CustomMessage, jsonObject.custom_message, true);
        }

        return this;
    }
}

export interface ILogic extends IEntity {
    uuid: string;
    type: string;
    execution: string;
    condition: string;
    action: string;
    items: Array<ILogicItem>;
    settings: ILogicSettings;
    hasCustomMessage(): boolean;
}

export class Logic extends Entity implements ILogic {
    uuid: string = String.uuid();
    type: string = null;
    execution: string = null;
    condition: string = null;
    action: string = null;
    items: Array<ILogicItem> = [];
    settings: ILogicSettings = null;

    constructor(logicType: string) {
        super();
        this.type = logicType;
        this.items.push(new LogicItem());
        if (this.type === 'end_survey') {
            this.settings = new LogicSettings();
        }
    }

    /**
     * Returns if a custom message is setup
     */
    public hasCustomMessage(): boolean {
        return !!(this.settings && this.settings.customMessage && this.settings.customMessage.message);
    }

    /**
     * Build the logic object from JSON
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @returns {ILogic}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): ILogic {
        super.fromJson(jsonObject, true);

        if (jsonObject.items) {
            this.items = EntityBuilder.buildMany<ILogicItem>(LogicItem, jsonObject.items, true);
        }

        if (jsonObject.settings) {
            this.settings = EntityBuilder.buildOne<ILogicSettings>(LogicSettings, jsonObject.settings, true);
        }

        return this;
    }
}
