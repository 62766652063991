import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItem } from '../../../../../../../domain/surveys/items/BaseItem';

@Component('its.survey.module.surveys', PointsSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/shared/inputs/points-setting/PointsSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: {
        item: '<',
    },
})
class PointsSettingsComponent {
    static IID: string = 'PointsSettingsComponent';
    static CID: string = 'itsPointsSettings';
    static $inject: string[] = [];

    public item: BaseItem;
}
