import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemRenderComponent } from '../../shared/BaseItemRenderComponent';

@Component('its.survey.module.surveys', ImageRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/display-items/image/ImageRenderComponent.html'),
    controllerAs: 'vm',
    bindings: ImageRenderComponent.getBindings(),
})
class ImageRenderComponent extends BaseItemRenderComponent {
    static IID: string = 'ImageRenderComponent';
    static CID: string = 'itsImageRender';
    static $inject: string[] = [];
}
