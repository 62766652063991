import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { ISurveyApi } from '../../../api/SurveyApi';
import { IGeneralSettings } from '../../../domain/settings/GeneralSettings';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import CustomDomainCreateModalHtml from './CustomDomainCreateModal.html';

export class CustomDomainCreateModal extends Modal {
    static $inject: Array<string> = ['itsSurveyApi', 'iteLogger', '$translate', '$mdDialog', 'iteToaster'];

    public createAnother: boolean;
    public settings: IGeneralSettings;
    public submitting: boolean = false;
    public editing: boolean;
    public domain: string;
    public oldDomain: string;

    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = CustomDomainCreateModalHtml;
        config.controller = CustomDomainCreateModal;

        return super.instantiate(config);
    }

    public constructor(
        private api: ISurveyApi,
        private logger: ILogger,
        private translate: ng.translate.ITranslateService,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster
    ) {
        super();
        if (this.editing === null) {
            this.editing = false;
        }
        this.oldDomain = this.domain;
    }

    public submit(form: ng.IFormController): void {
        this.editing ? this.edit(form) : this.create(form);
    }

    public create(form: ng.IFormController): void {
        this.logger.info('Creating custom domain: ' + this.domain);
        this.submitting = true;
        this.settings.customDomains.push(this.domain);
        this.api
            .saveSettings(this.settings)
            .then(() => {
                this.logger.info('Created custom domain: ' + this.domain);
                form.$setPristine();
                form.$setUntouched();
                this.dialog.hide({ domain: this.domain, createAnother: this.createAnother });
            })
            .catch((error: IErrorResponse) => {
                this.toaster.error(
                    this.translate.instant('ERRORS.SETTINGS.FAILED_CUSTOM_DOMAIN_CREATE'),
                    'Failed to create custom field with name: ' + this.domain + ' ' + error.message
                );
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    public edit(form: ng.IFormController): void {
        this.logger.info('Editing custom domain: ' + this.domain);
        this.submitting = true;
        this.settings.customDomains[this.settings.customDomains.indexOf(this.oldDomain)] = this.domain;
        this.api
            .saveSettings(this.settings)
            .then((response) => {
                this.logger.info('Updated custom domain: ' + this.domain);
                form.$setPristine();
                form.$setUntouched();
                this.dialog.hide(this.domain);
            })
            .catch((error: IErrorResponse) => {
                this.toaster.error(
                    this.translate.instant('ERRORS.SETTINGS.FAILED_CUSTOM_DOMAIN_UPDATE'),
                    'Failed to update custom field with name: ' + this.domain + ' ' + error.message
                );
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    /**
     * Cancels form creation and closes dialog
     */
    public cancel(): void {
        this.dialog.cancel();
    }
}
