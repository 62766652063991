import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Nps } from '../../../../../../domain/surveys/items/Nps';
import { SurveyService } from '../../../../../../services/SurveyService';
import { Survey } from '../../../../../../domain/surveys/survey/Survey';
import { BaseItemSettingsComponent } from '../../shared/BaseItemSettingsComponent';
import { ItemSettingsTab } from '../../shared/item-settings-tabs/ItemSettingsTabs';

@Component('its.survey.module.surveys', NpsSettingsComponent.CID, {
    template: require('/app/modules/surveys/components/items/input-items/nps/NpsSettingsComponent.html'),
    controllerAs: 'vm',
    bindings: NpsSettingsComponent.getBindings(),
})
class NpsSettingsComponent extends BaseItemSettingsComponent {
    static IID: string = 'NpsSettingsComponent';
    static CID: string = 'itsNpsSettings';
    static $inject: string[] = ['itsSurveyService'];

    public item: Nps;
    public tabs: ItemSettingsTab[] = ['basic', 'advanced', 'points'];
    public survey: Survey = null;

    public constructor(private surveyService: SurveyService) {
        super();
    }

    $onInit(): void {
        this.surveyService.getSurveyPromise().then((survey: Survey) => {
            this.survey = survey;
        });
    }
}
