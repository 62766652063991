import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { IEmailDistribution } from '../../../../domain/contact-center/EmailDistribution';
import { IContactList } from '../../../../domain/contact-center/ContactList';
import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { ISchedulerData } from '@intouch/its.essential/app/essential/components/Scheduler';
import moment_tz = require('moment-timezone');
import { ISmsDistribution } from '../../../../domain/contact-center/SmsDistribution';
import { IDistributeSurveyRequest } from '../../../../domain/contact-center/DistributeSurveyRequest';
import DistributeSurveyModalHtml from './DistributeSurveyModal.html';

export class DistributeSurveyModal extends Modal {
    static $inject: Array<string> = ['itsSurveyApi', 'iteLogger', '$mdDialog', 'iteToaster', '$translate'];

    public submitting: boolean = false;
    public selectedContactList: IContactList = null;
    public distributionType: string;
    public emailOrSmsDistribution: IEmailDistribution | ISmsDistribution;
    public surveyOriginalUuid: string;
    public loadingContactCount: boolean = false;
    public subscriberCount: number = null;
    public schedulerData: ISchedulerData = { sendAtDate: null, sendAtDateFormatted: null, sendAtDateTimezone: null };
    public showDatepicker: boolean = false;
    public showSendAtDateError: boolean = false;

    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = DistributeSurveyModalHtml;
        config.controller = DistributeSurveyModal;

        return super.instantiate(config);
    }

    public constructor(
        private api: ISurveyApi,
        private logger: ILogger,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private translate: ng.translate.ITranslateService
    ) {
        super();
    }

    /**
     * Distributes the survey to the selected contact list
     */
    public submit(): void {
        this.showSendAtDateError = false;
        this.submitting = true;

        if (this.schedulerData.sendAtDate) {
            // compare the selected send date w/ current time
            if (this.sendAtDateTimeHasPast()) {
                this.showSendAtDateError = true;
                this.submitting = false;
                return;
            }
        }

        const requestData: IDistributeSurveyRequest = {
            contactListUuid: this.selectedContactList.uuid,
            surveyOriginalUuid: this.surveyOriginalUuid,
            distributionType: this.distributionType,
            emailOrSmsUuid: this.emailOrSmsDistribution.uuid,
            sendAtDateFormatted: this.schedulerData.sendAtDateFormatted,
            sendAtDateTimezone: this.schedulerData.sendAtDateTimezone,
        };

        this.api
            .distributeSurveyToContactList(requestData)
            .then((result: any) => {
                this.toaster.success(
                    this.translate.instant('GENERAL.SURVEY_DISTRIBUTED', { item: result.contacts_notified })
                );
                this.cancel();
            })
            .catch(() => {
                this.toaster.error('ERRORS.CONTACT_CENTER.FAILED_TO_DISTRIBUTE_SURVEY');
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    /**
     * Display a survey in a string format
     *
     * @returns {string}
     * @param contactList
     */
    public displayContactList(contactList: IContactList): string {
        return contactList ? contactList.name : '';
    }

    /**
     * Allow a user to search for a contact list to distribute to
     *
     * @param {string} search
     * @param {IPager} pager
     * @returns {angular.IPromise<any>}
     */
    public searchContactLists(search: string, pager: IPager): ng.IPromise<any> {
        return this.api.findContactLists(pager, search);
    }

    /**
     * The on change handler for the contact list selection
     *
     * @param {IContactList} contactList
     */
    public handleSurveySelect(contactList: IContactList): void {
        this.selectedContactList = contactList;
        this.loadContactCount();
    }

    public cancel(): void {
        this.dialog.hide();
    }

    public handleShowDatepicker(): void {
        this.showSendAtDateError = false;

        if (this.showDatepicker) {
            this.schedulerData.sendAtDate = null;
            this.schedulerData.sendAtDateFormatted = null;
            this.schedulerData.sendAtDateTimezone = null;
        }
    }

    private loadContactCount(): void {
        this.loadingContactCount = true;
        this.subscriberCount = null;

        const filter: string =
            'filter[contact_lists.uuid]=' + this.selectedContactList.uuid + '&filter[status]=subscribed';
        this.api
            .findContacts(Pager.make(1, 'email', 'asc', 1), null, filter)
            .then((result: PagedEntities) => {
                this.subscriberCount = result.getPager().total;
            })
            .finally(() => {
                this.loadingContactCount = false;
            });
    }

    /**
     * Compare the send at date/time w/ the current date/time to ensure it is in the future
     *
     * @return boolean
     */
    private sendAtDateTimeHasPast(): boolean {
        const now: number = moment_tz().toDate().getTime();
        const sendAt: number = moment_tz
            .tz(
                moment_tz(this.schedulerData.sendAtDate).format('YYYY-MM-DD HH:mm:ss'),
                this.schedulerData.sendAtDateTimezone
            )
            .utc()
            .toDate()
            .getTime();

        return now > sendAt;
    }
}
