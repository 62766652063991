import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { ISurveySms, SurveySms } from './SurveySms';
import { DistributionTrigger, IDistributionTrigger } from './DistributionTrigger';
import { LogicOperatorFactory } from '../surveys/logic/LogicOperatorFactory';
import moment from 'moment';
import Moment = moment.Moment;

export interface ISmsDistribution extends IEntity {
    uuid: string;
    name: string;
    sms: ISurveySms;
    enableAutoDelivery: boolean;
    active: boolean;
    expirationDays: number;
    triggers: Array<IDistributionTrigger>;
    createdAt: Moment;
    submissionsPeriodInDays: number;
    submissionsPerPeriod: number;
    getAvailableOperators(translate: ng.translate.ITranslateService): Array<{ label: string; value: string }>;
    isValid(): boolean;
}

export class SmsDistribution extends Entity implements ISmsDistribution {
    uuid: string = null;
    name: string = null;
    sms: ISurveySms = null;
    enableAutoDelivery: boolean = false;
    active: boolean = true;
    expirationDays: number = null;
    createdAt: Moment = null;
    submissionsPeriodInDays: number = null;
    submissionsPerPeriod: number = null;
    triggers: Array<IDistributionTrigger> = [new DistributionTrigger()];

    public constructor(name: string = null, message: string = null) {
        super();

        this.name = name;
        this.sms = new SurveySms().fromJson({ name: this.name, message: message });
    }

    public fromJson(jsonObject: any, convert: boolean): ISmsDistribution {
        super.fromJson(jsonObject, convert);

        if (jsonObject.sms) {
            this.sms = EntityBuilder.buildOne<ISurveySms>(SurveySms, jsonObject.sms, convert);
        }

        if (jsonObject.createdAt) {
            this.createdAt = moment(jsonObject.createdAt);
        }

        if (jsonObject.triggers) {
            this.triggers = EntityBuilder.buildMany<IDistributionTrigger>(
                DistributionTrigger,
                jsonObject.triggers,
                convert
            );
        }

        return this;
    }

    /**
     * Do some massaging for the date type fields
     *
     * @param {boolean} convert
     * @returns {string}
     */
    public toJson(convert: boolean): string {
        const json: string = super.toJson(convert),
            decoded: any = JSON.parse(json);

        if (!!decoded.triggers && decoded.triggers.length > 0 && decoded.enable_auto_delivery) {
            for (const trigger of decoded.triggers) {
                if (trigger.conditions) {
                    for (const condition of trigger.conditions) {
                        if (condition.operand_type === 'date' && condition.value) {
                            condition.value = moment(condition.value).utc().format('YYYY-MM-DD');
                        }
                    }
                }
            }
        } else {
            decoded.enable_auto_delivery = false;
            decoded.triggers = [];
        }

        return JSON.stringify(decoded);
    }

    /**
     * Returns if the sms distribution is valid
     *
     * @returns {boolean}
     */
    public isValid(): boolean {
        return this.sms && this.sms.isValid();
    }

    /**
     * Returns if the sms contains the survey link token
     * @returns {boolean}
     */
    public hasSurveyLink(): boolean {
        return this.sms && this.sms.message && this.sms.message.indexOf('[[SURVEY.LINK]]') > -1;
    }

    /**
     * Get sms distribution trigger condition operators
     */
    public getAvailableOperators(translate: ng.translate.ITranslateService): Array<{ label: string; value: string }> {
        // 'Equals', 'Not Equals', 'Contains', 'Does Not Contain', 'Less Than', 'Greater Than', 'Empty', 'Not Empty'
        return [
            {
                value: 'eq',
                label: LogicOperatorFactory.getSimpleOperatorLabel('eq', translate),
            },
            {
                value: 'neq',
                label: LogicOperatorFactory.getSimpleOperatorLabel('neq', translate),
            },
            {
                value: 'contains',
                label: LogicOperatorFactory.getSimpleOperatorLabel('contains', translate),
            },
            {
                value: 'ncontains',
                label: LogicOperatorFactory.getSimpleOperatorLabel('ncontains', translate),
            },
            {
                value: 'lt',
                label: LogicOperatorFactory.getSimpleOperatorLabel('lt', translate),
            },
            {
                value: 'gt',
                label: LogicOperatorFactory.getSimpleOperatorLabel('gt', translate),
            },
            {
                value: 'empty',
                label: LogicOperatorFactory.getSimpleOperatorLabel('empty', translate),
            },
            {
                value: 'nempty',
                label: LogicOperatorFactory.getSimpleOperatorLabel('nempty', translate),
            },
        ];
    }
}
