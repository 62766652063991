import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ItemSettingsTab } from './ItemSettingsTabs';

@Component('its.survey.module.surveys', ItemSettingsTabsComponent.CID, {
    template: require('/app/modules/surveys/components/items/shared/item-settings-tabs/ItemSettingsTabsComponent.html'),
    controllerAs: 'vm',
    bindings: {
        tabs: '<',
        onTabSelect: '&',
    },
})
class ItemSettingsTabsComponent {
    static IID: string = 'ItemSettingsTabsComponent';
    static CID: string = 'itsItemSettingsTabs';
    static $inject: string[] = [];

    public onTabSelect: (data: any) => void;
    public tabs: ItemSettingsTab[];

    public activeSettingsTab: ItemSettingsTab = null;
    public displayBasicTab: boolean = false;
    public displayAdvancedTab: boolean = false;
    public displayPointsTab: boolean = false;

    $onInit(): void {
        this.displayBasicTab = this.tabs.indexOf('basic') > -1;
        this.displayAdvancedTab = this.tabs.indexOf('advanced') > -1;
        this.displayPointsTab = this.tabs.indexOf('points') > -1;
    }

    public setSettingsTab(activeTab: ItemSettingsTab): void {
        this.activeSettingsTab = activeTab;
        this.onTabSelect({ activeTab: activeTab });
    }
}
