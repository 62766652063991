import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IContact } from '../../../domain/contact-center/Contact';
import { IPager } from '@intouch/its.essential/app/essential/domain/Pager';
import { ISurveyApi } from '../../../api/SurveyApi';
import SurveyTestLinkModalHtml from './SurveyTestLinkModal.html';

export class SurveyTestLinkModal extends Modal {
    static $inject: Array<string> = ['$mdDialog', '$translate', 'itsSurveyApi'];

    private surveyUrl: string;

    private linkType: string = 'anonymous';
    private contact: IContact = null;

    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = SurveyTestLinkModalHtml;
        config.controller = SurveyTestLinkModal;

        return super.instantiate(config);
    }

    public constructor(
        private dialog: ng.material.IDialogService,
        private translate: ng.translate.ITranslateService,
        private surveyApi: ISurveyApi
    ) {
        super();
    }

    /**
     * Opens the survey in a new window as a contact if you have a contact selected
     */
    public openSurveyTestLink(): void {
        if (this.linkType === 'contact' && this.contact) {
            this.surveyUrl = this.surveyUrl.substring(0, this.surveyUrl.indexOf('?'));
            window.open(this.surveyUrl + '/p/' + this.contact.uuid + '?mode=preview&sample=1', '_blank');
        } else {
            window.open(this.surveyUrl + '&sample=1', '_blank');
        }
        this.cancel();
    }

    /**
     * Allow a user to search for a contact to generate a personal link
     *
     * @param {string} search
     * @param {IPager} pager
     * @returns {angular.IPromise<any>}
     */
    public searchContacts(search: string, pager: IPager): ng.IPromise<any> {
        return this.surveyApi.findContacts(pager, search, null);
    }

    /**
     * Display a contact in a string format
     *
     * @param {IContact} contact
     * @returns {string}
     */
    public displayContact(contact: IContact): string {
        this.contact = contact;
        return contact ? contact.firstName + ' ' + contact.lastName + ' - ' + contact.email : '';
    }

    /**
     * Cancels the modal
     */
    public cancel(): void {
        this.dialog.cancel();
    }
}
