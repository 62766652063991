import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { SurveyService } from '../../../../services/SurveyService';
import { IBaseItem } from '../../../../domain/surveys/items/BaseItem';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';

@Component('its.survey.module.surveys', SurveyRenderComponent.CID, {
    template: require('/app/modules/surveys/components/survey-render/SurveyRenderComponent.html'),
    controllerAs: 'vm',
    bindings: {
        survey: '<',
    },
})
class SurveyRenderComponent {
    static IID: string = 'SurveyRenderComponent';
    static CID: string = 'itsSurveyRender';
    static $inject: string[] = ['itsSurveyService', '$scope'];

    public editingItem: IBaseItem = null;
    private editingItemSubscription: SubjectSubscription = null;

    constructor(private surveyService: SurveyService, private scope: ng.IScope) {
        this.editingItemSubscription = this.surveyService.editingItem.subscribe((newEditingItem) => {
            this.editingItem = <IBaseItem>newEditingItem;
        });
        this.editingItemSubscription.unsubscribeOnScopeDestruction(this.scope);
    }
}
