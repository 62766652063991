import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { ISurvey } from '../../../../domain/surveys/survey/Survey';
import { ISurveyApi } from '../../../../api/SurveyApi';
import { IPageService } from '../../../../services/PageService';
import { IEmailDistribution } from '../../../../domain/contact-center/EmailDistribution';
import { Contact } from '../../../../domain/contact-center/Contact';
import { DefaultContactField } from '../../../../domain/contact-center/DefaultContactField';
import { IGeneralSettings } from '../../../../domain/settings/GeneralSettings';
import { IPhotoService } from '@intouch/its.essential/app/essential/services/PhotoService';

import * as _ from 'lodash';
import { AbstractEmailController } from '../AbstractEmailController';

declare let $: JQuery;

@Controller(
    'its.survey.module.surveys.distributions',
    DistributionCreateEmailController.IID,
    DistributionCreateEmailController
)
class DistributionCreateEmailController extends AbstractEmailController {
    static IID: string = 'its.survey.module.surveys.distributions.DistributionCreateEmailController';
    static $inject: Array<string> = [
        'itsSurveyApi',
        '$state',
        'itsPageService',
        '$q',
        'APPCONFIG',
        'iteToaster',
        'iteAccessService',
        '$mdDialog',
        '$state',
        '$translate',
        'itePhotoService',
    ];

    constructor(
        surveyApi: ISurveyApi,
        stateService: ng.ui.IStateService,
        pageService: IPageService,
        q: ng.IQService,
        config: any,
        toaster: IToaster,
        accessService: IAccessService,
        dialog: ng.material.IDialogService,
        state: ng.ui.IStateService,
        translate: ng.translate.ITranslateService,
        photoService: IPhotoService
    ) {
        super(
            surveyApi,
            stateService,
            pageService,
            q,
            config,
            toaster,
            accessService,
            dialog,
            state,
            translate,
            photoService
        );
    }

    /**
     * Load the given form by its uuid
     */
    public load(uuid: string, emailUuid: string): void {
        this.pageService.startLoading();
        this.surveyApi
            .findSurveyByUuid(uuid)
            .then((survey: ISurvey) => {
                this.survey = survey;
                this.pageService.setPageTitle(survey.name);
                this.surveyApi.findAllCustomFields().then((pagedCustomFields: PagedEntities) => {
                    this.customFields = pagedCustomFields.getEntities();
                    this.defaultFields = Contact.getDefaultFields(this.translate);
                    this.defaultFields.push(
                        DefaultContactField.make({
                            modelName: 'contactCreationDate',
                            name: 'created_at',
                            label: 'Contact Creation Date',
                            type: 'date',
                            isDefaultField: false,
                        })
                    );
                    this.surveyApi.getSettings().then((settings: IGeneralSettings) => {
                        this.settings = settings;
                        this.setFromAddressOptions();
                        this.selectedFromAddress = this.fromAddresses[0];

                        this.loadExistingEmail(emailUuid).then((emailDistribution: IEmailDistribution) => {
                            if (emailDistribution) {
                                this.emailDistribution = emailDistribution;
                                if (this.emailDistribution.submissionsPerPeriod === 1) {
                                    this.limitToggle = true;
                                }
                                if (this.emailDistribution.expirationDays) {
                                    switch (this.emailDistribution.expirationDays) {
                                        case 3:
                                        case 7:
                                        case 14:
                                        case 30:
                                            this.emailExpiryDays = this.emailDistribution.expirationDays;
                                            this.emailCustomExpiry = null;
                                            break;
                                        default:
                                            this.emailExpiryDays = 'custom';
                                            this.emailCustomExpiry = this.emailDistribution.expirationDays;
                                            break;
                                    }
                                }

                                // todo: maybe.. not do this? we only have 1 trigger + condition for now so... easy way out.
                                if (
                                    this.emailDistribution &&
                                    this.emailDistribution.triggers &&
                                    this.emailDistribution.triggers.length &&
                                    this.emailDistribution.triggers[0].conditions &&
                                    this.emailDistribution.triggers[0].conditions.length
                                ) {
                                    for (const condition of this.emailDistribution.triggers[0].conditions) {
                                        this.setField(
                                            condition,
                                            this.getSavedField(condition.operand),
                                            condition.value,
                                            true
                                        );
                                    }
                                }
                                this.editing = true;

                                // find and set the selected mail sender if applicable.
                                if (this.emailDistribution.mailSenderUuid) {
                                    const mailSender: { uuid: string; fromAddress: string } = _.find(
                                        this.fromAddresses,
                                        {
                                            uuid: this.emailDistribution.mailSenderUuid,
                                            fromAddress: this.emailDistribution.email.fromAddress,
                                        }
                                    );

                                    if (mailSender) {
                                        this.selectedFromAddress = mailSender;
                                    }
                                }
                            }

                            this.pageService.stopLoading();
                        });
                    });
                });
            })
            .catch(() => {
                this.pageService.failLoading();
            });
    }
}
