import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { Section } from '../../../../../domain/surveys/Section';
import { SurveyService } from '../../../../../services/SurveyService';
import { BaseItem, IBaseItem } from '../../../../../domain/surveys/items/BaseItem';
import { ItemFactory } from '../../../../../domain/surveys/items/ItemFactory';
import { ElementLibraryService } from '../../../../../services/ElementLibraryService';
import { IElementLibraryGroupItem } from '../../../../../domain/surveys/element-library/IElementLibraryGroup';
import { IContainItems } from '../../../../../domain/surveys/items/ContainItems';
import { Survey } from '../../../../../domain/surveys/survey/Survey';
import * as _ from 'lodash';
import { SurveyQuestionMover } from '../../../../../domain/surveys/survey/utils/SurveyQuestionMover';
import { SectionMoveModal } from '../../../modals/SectionMoveModal';

@Component('its.survey.module.surveys', SectionRenderComponent.CID, {
    template: require('/app/modules/surveys/components/items/section/SectionRenderComponent.html'),
    controllerAs: 'vm',
    bindings: {
        section: '<',
    },
})
class SectionRenderComponent {
    static IID: string = 'SectionRenderComponent';
    static CID: string = 'itsSectionRender';
    static $inject: string[] = [
        'itsSurveyService',
        '$scope',
        'itsElementLibraryService',
        '$mdMedia',
        '$translate',
        '$mdDialog',
    ];

    public section: Section;
    public sectionExpanded: boolean = true;
    public surveyElements: IElementLibraryGroupItem[] = [];
    public editingItem: IBaseItem = null;
    public previousSectionIndex: number = null;
    private survey: Survey = null;

    public constructor(
        private surveyService: SurveyService,
        private scope: ng.IScope,
        private elementLibraryService: ElementLibraryService,
        private mdMedia: ng.material.IMedia,
        private translate: ng.translate.ITranslateService,
        private dialog: ng.material.IDialogService
    ) {
        this.subscribeToEditingItem();
        this.subscribeToSurvey();
        this.surveyElements = this.elementLibraryService.getSurveyElementsAvailable();
    }

    $onChanges(): void {
        // need this because we use @NotEnumerable on the temporary property to handle
        // collapsing and when we recover from canceled edit, we lose the default state
        // hack fix but it was a tradeoff to sharing the label on all elements outside of the elements
        this.section.expanded = true;
    }

    public edit(selectedItem: BaseItem): void {
        this.surveyService.saveSnapshotAndEdit(selectedItem);
    }

    public delete(item: BaseItem): void {
        this.surveyService.showItemDeleteConfirmation(item);
    }

    public addQuestionTypeToSection(type: string): void {
        const itemToCreate: BaseItem = ItemFactory.create<BaseItem>(type);
        itemToCreate.initialize(this.translate);
        this.surveyService.addQuestionToParentAndEdit(itemToCreate, this.section, this.section.items.length);
    }

    public handleMoveItem(
        section: Section,
        currentQuestionIndex: number,
        targetParent: IContainItems,
        targetIndex: number
    ): boolean {
        SurveyQuestionMover.moveQuestion(section, currentQuestionIndex, targetParent, targetIndex);
        this.surveyService.saveSurvey(this.survey);
        return true;
    }

    public openMoveSectionModal(): void {
        this.dialog.show(
            SectionMoveModal.instantiate({
                locals: {
                    section: this.section,
                },
            })
        );
    }

    private subscribeToEditingItem(): void {
        const editingItemSubscription: SubjectSubscription = this.surveyService.editingItem.subscribe((editingItem) => {
            this.editingItem = <IBaseItem>editingItem;
        });
        editingItemSubscription.unsubscribeOnScopeDestruction(this.scope);
    }

    private subscribeToSurvey(): void {
        const surveySubscription: SubjectSubscription = this.surveyService.surveyValue.subscribe((survey) => {
            this.survey = survey;
            if (survey) {
                this.previousSectionIndex = _.findIndex(survey.sections, { uuid: this.section.uuid });
            }
        });
        surveySubscription.unsubscribeOnScopeDestruction(this.scope);
    }
}
