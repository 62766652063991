import * as angular from 'angular';

const module: any = angular.module('its.survey.module.surveys', []);

import './_configuration';
import './ListingController';
import './EditController';
import './DetailsController';
import './BuildController';
import './TranslationsController';
import './PreviewController';
import './LogicController';
import './SettingsController';
import './CreateController';
import './ResultsController';

import './summary/SummaryController';
import './summary/components/survey-details-box/SurveyDetailsBoxComponent';
import './summary/components/survey-published-state/SurveyPublishedStateComponent';
import './summary/components/survey-submission-count-score/SurveySubmissionCountScoreComponent';
import './summary/components/survey-health-summary/SurveyHealthSummaryComponent';
import './summary/components/survey-distributions-listing/SurveyDistributionsListingComponent';
import './summary/components/survey-completion-rate/SurveyCompletionRateComponent';

// scoring controllers
import './scoring/ScoringController';
import './scoring/points/PointsController';
import './scoring/tagging/TaggingController';
import './scoring/weighting/WeightingController';

import './components/translations/TranslationGroupItemComponent';
import './components/translations/TranslationImageItemComponent';
import './components/translations/TranslationItemComponent';
import './components/EmailPreviewComponent';
import './components/PipedTextSelectorComponent';
import './components/LogicEditComponent';
import './components/health/HealthSectionComponent';
import './components/survey-preview-thumbnail/SurveyPreviewThumbnailComponent';

import './components/survey-render/SurveyRenderComponent';
import './components/inline-edit-field/InlineEditFieldComponent';

import './components/items/_imports';

export default module;
