export abstract class BaseItemRenderComponent {
    public static getBindings(): any {
        return {
            item: '<',
        };
    }

    public getOtherLabel(translateService: ng.translate.ITranslateService, sourceLanguage: string): string {
        return translateService.instant('GENERAL.OTHER', undefined, undefined, sourceLanguage);
    }

    public getNotApplicableLabel(translateService: ng.translate.ITranslateService, sourceLanguage: string): string {
        return translateService.instant('SURVEYS.ITEMS.SCALE.NOT_APPLICABLE', undefined, undefined, sourceLanguage);
    }
}
