import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ITableColumn, TableColumn } from '@intouch/its.essential/app/essential/domain/table/TableColumn';
import { TableColumnManager } from '@intouch/its.essential/app/essential/domain/table/TableColumnManager';
import { RemoteStorageService } from '@intouch/its.essential/app/essential/services/RemoteStorageService';
import { RemoteStorage } from '@intouch/its.essential/app/essential/domain/RemoteStorage';
import { TableColumnSettingsPanel } from '@intouch/its.essential/app/essential/panels/table-column-settings/TableColumnSettingsPanel';
import { INavigationService } from '../../services/NavigationService';
import {
    LinkListMenuItem,
    ILinkListMenuItem,
} from '@intouch/its.essential/app/essential/domain/link-list/LinkListMenuItem';
import { ILinkListMenuAction } from '@intouch/its.essential/app/essential/domain/link-list/LinkListMenuAction';
import { IContact } from '../../domain/contact-center/Contact';
import { ISurveyApi } from '../../api/SurveyApi';
import { ICustomField } from '../../domain/settings/CustomField';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { ContactListCreateModal } from './contact-lists/ContactListCreateModal';
import { IContactList } from '../../domain/contact-center/ContactList';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ISurveySession } from '../../services/SurveySession';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { ContactListDistributeSurveyModal } from './contact-lists/ContactListDistributeSurveyModal';
import { INameUuidPair } from '@intouch/its.essential/app/essential/utils/datatypes/NameUuidPair';
import * as angular from 'angular';
import * as _ from 'lodash';
import moment = require('moment-timezone');
import {
    ISubscriptionChartData,
    SubscriptionChartData,
} from '../../domain/contact-center/charts/SubscriptionChartData';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ContactsExportModal } from './modals/ContactsExportModal';
import { INavigationCollectionItem } from '@intouch/its.essential/app/essential/domain/collections/NavigationCollectionItem';
import {
    INavigationCollection,
    NavigationCollection,
} from '@intouch/its.essential/app/essential/domain/collections/NavigationCollection';
import { SelectionManager } from '@intouch/its.essential/app/essential/tools/SelectionManager';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';
import { IPageService } from '@intouch/its.essential/app/essential/services/PageService';

// iql imports
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import { IIql, Iql } from '@intouch/iql-ts-sdk/src/domain/Iql';
import { FilterGroup } from '@intouch/iql-ts-sdk/src/domain/filters/FilterGroup';
import { Filter } from '@intouch/iql-ts-sdk/src/domain/filters/Filter';
import { DateFilter } from '@intouch/iql-ts-sdk/src/domain/filters/DateFilter';
import { DateDimension } from '@intouch/iql-ts-sdk/src/domain/dimensions/DateDimension';
import { IIqlQueryResult } from '@intouch/iql-ts-sdk/src/domain/IqlQueryResult';
import { IqlQueryResultSeriesAdapter } from '@intouch/iql-ts-sdk/src/domain/adapters/IqlQueryResultSeriesAdapter';
import { DatatableSearch } from '@intouch/its.essential/app/essential/domain/datatable/DatatableSearch';
import { IPanelConfig } from '@intouch/its.essential/app/essential/panels/PanelConfig';
import { IStandardPanelConfig } from '@intouch/its.essential/app/essential/panels/StandardPanelConfig';
import * as Highcharts from 'highcharts';

type ContactCenterFilterSlug = 'unsubscribed' | 'subscribed' | 'undeliverable';

interface IContactCenterColumn {
    sortSlug?: string;
    label: string;
    contactKey: string;
}

/**
 * Contact Center Listing Controller for our application
 *
 * @constructor
 */
@Controller('its.survey.module.contactCenter', ContactCenterController.IID, ContactCenterController)
class ContactCenterController {
    static IID: string = 'its.survey.module.contactCenter.ContactCenterController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        'itsNavigationService',
        '$stateParams',
        'itsSurveyApi',
        '$translate',
        'iteToaster',
        'itsSurveySession',
        '$mdMedia',
        '$timeout',
        '$scope',
        'iteIqlApi',
        '$q',
        'iteAccessService',
        'itePanelService',
        'itePageService',
        'iteRemoteStorageService',
    ];

    public contacts: Array<IContact> = [];
    public customFields: Array<ICustomField> = [];
    public selectedContactList: IContactList = null;
    public activeContactListFilters: Array<INameUuidPair> = [];
    public loading: boolean = false;
    public hasError: boolean = false;
    public contactsPager: IPager = null;
    public datatableSearch: DatatableSearch = new DatatableSearch();
    public filters: string = null;
    public hasContactAdmin: boolean = false;
    public translations: any;
    public showChart: boolean = false;
    public chartLoading: boolean = false;
    public chartError: boolean = false;
    public chartEmpty: boolean = false;
    public chartMeta: ISubscriptionChartData = new SubscriptionChartData();
    public contactListUuid: string = null;
    public navCollection: INavigationCollection = new NavigationCollection();
    public navCollectionSearch: string = undefined;
    public showNavigation: boolean = this.media('gt-sm');
    public selectionManager: SelectionManager;
    public bulkActioning: boolean = false;
    public selectedContacts: Array<IContact>;
    public columnRemoteStorageKey: string = 'contact-list-columns';
    public defaultColumns: ITableColumn<IContactCenterColumn>[] = [];
    public currentFilterSlug: string = null;
    public columnSettings: TableColumnManager<IContactCenterColumn> = null;

    public navExpanded: boolean = false;

    constructor(
        private stateService: ng.ui.IStateService,
        private dialog: ng.material.IDialogService,
        private navigationService: INavigationService,
        private stateParams: ng.ui.IStateParamsService,
        private surveyApi: ISurveyApi,
        private translate: ng.translate.ITranslateService,
        private toaster: IToaster,
        private session: ISurveySession,
        private media: ng.material.IMedia,
        private timeout: ng.ITimeoutService,
        private scope: ng.IScope,
        private iqlApi: IIqlApi,
        private q: ng.IQService,
        private accessService: IAccessService,
        private panelService: IPanelService,
        private pageService: IPageService,
        private remoteStorageService: RemoteStorageService
    ) {
        // this watcher shows the chart on any viewport size larger than sm
        this.scope.$watch(
            () => {
                return this.media('gt-sm');
            },
            (newValue, oldValue) => {
                this.showChart = newValue;
                if (this.showChart && newValue !== oldValue) {
                    this.loadChart();
                }
            }
        );

        this.showChart = this.media('gt-sm');

        this.translate([
            'GENERAL.CANCEL',
            'GENERAL.DELETE',
            'ERRORS.FAILED_CONTACT_DELETE',
            'ERRORS.FAILED_CONTACT_UPDATE',
            'CONTACT_CENTER.DELETE_CONTACT_TEXT_UNSUBSCRIBE',
            'CONTACT_CENTER.DELETE_CONTACT_CONFIRM',
            'CONTACT_CENTER.MESSAGES.DELETED_CONTACT',
            'CONTACT_CENTER.MESSAGES.DISTRIBUTED',
            'CONTACT_CENTER.MESSAGES.UPDATED_CONTACT',
            'CONTACT_CENTER.MESSAGES.UPDATED_CONTACT',
            'CONTACT_CENTER.CONTACT_LIST.EMPTY',
            'CONTACT_CENTER.CONTACT_LIST.EMPTY_SUBSCRIBE',
            'CONTACT_CENTER.CONTACT_LIST.EMPTY_UNSUBSCRIBE',
            'CONTACT_CENTER.CONTACT_LIST.EMPTY_ALL',
            'CONTACT_CENTER.CONTACT_LIST.ADD',
            'CONTACT_CENTER.ADD_CONTACTS',
            'CONTACT_CENTER.CREATE_CONTACT',
            'CONTACT_CENTER.MENU.CONTACT_LISTS',
            'CONTACT_CENTER.MESSAGES.CREATED',
            'CONTACT_CENTER.CONTACT_LIST.DELETE_TEXT_2',
            'CONTACT_CENTER.CONTACT_LIST.DELETE_CONFIRM',
            'CONTACT_CENTER.CONTACT_LIST.DELETE_TITLE',
            'CONTACT_CENTER.CONTACT_CENTER',
            'CONTACT_CENTER.MESSAGES.DELETED',
            'CONTACT_CENTER.CONTACTS.UNDELIVERABLE',
            'GENERAL.CREATED',
            'GENERAL.UPDATED',
            'GENERAL.UNSUBSCRIBED',
        ]).then((translations: any) => {
            this.translations = translations;
        });

        this.defaultColumns = [
            {
                label: this.translate.instant('GENERAL.FIRST_NAME'),
                id: 'first_name',
                show: true,
                originalColumn: {
                    label: this.translate.instant('GENERAL.FIRST_NAME'),
                    sortSlug: 'first_name',
                    contactKey: 'firstName',
                },
            },
            {
                label: this.translate.instant('GENERAL.LAST_NAME'),
                id: 'last_name',
                show: true,
                originalColumn: {
                    label: this.translate.instant('GENERAL.LAST_NAME'),
                    sortSlug: 'last_name',
                    contactKey: 'lastName',
                },
            },
            {
                label: this.translate.instant('GENERAL.EMAIL'),
                id: 'email',
                show: true,
                originalColumn: {
                    label: this.translate.instant('GENERAL.EMAIL'),
                    sortSlug: 'email',
                    contactKey: 'email',
                },
            },
            {
                label: this.translate.instant('GENERAL.PHONE'),
                id: 'phone_number',
                show: true,
                originalColumn: {
                    label: this.translate.instant('GENERAL.PHONE'),
                    sortSlug: 'phone_number',
                    contactKey: 'phoneNumberFormatted',
                },
            },
            {
                label: this.translate.instant('CONTACT_CENTER.CONTACT_LISTS'),
                id: 'contact_lists',
                show: true,
                originalColumn: {
                    label: this.translate.instant('CONTACT_CENTER.CONTACT_LISTS'),
                    contactKey: 'contactListsString',
                },
            },
            {
                label: this.translate.instant('GENERAL.UPDATED'),
                id: 'updated',
                show: true,
                originalColumn: {
                    label: this.translate.instant('GENERAL.UPDATED'),
                    sortSlug: 'updated_at',
                    contactKey: 'updatedAt',
                },
            },
        ];
        this.filters = this.stateParams['filters'];
        this.currentFilterSlug = this.getFilterSlugFromUrlParams(this.stateParams['filters']);
        this.contactListUuid = this.getContactListUuidFromFilter();
        const currentPage: number = this.stateParams['page'] ? parseInt(this.stateParams['page'], 10) : 1;
        this.contactsPager = this.contactsPager ? this.contactsPager : Pager.make(currentPage, 'updated_at', 'desc');
        this.datatableSearch.term = this.stateService.params['search'] || null;

        if (this.stateParams['filters'] && this.filteringByContactList()) {
            this.surveyApi
                .getContactList(this.getContactListUuidFromFilter())
                .then((list) => {
                    this.setSelectedContactList(list);
                })
                .catch(() => {
                    this.filters = null;
                    this.goToSelf();
                });
        }
        this.initializeNavCollection();
        this.loadChart();
        this.loadListing(this.filters);
        this.loadContactLists();
        this.hasContactAdmin = this.session.getToken().getUser().hasAcl('contact_center_admin');
    }

    public isActiveLink(item: INavigationCollectionItem): boolean {
        return (
            item.state === this.stateService.current.name &&
            ((!item.stateParams && !item.stateParams.filters) ||
                item.stateParams.filters === this.stateService.params['filters'])
        );
    }

    public openColumnSettings(): void {
        const panelSettings: IStandardPanelConfig = TableColumnSettingsPanel.instantiate<IContactCenterColumn>({
            relativeTo: '#tableMoreSettings',
            locals: {
                columnSettings: this.columnSettings,
                remoteStorageKey: this.columnRemoteStorageKey,
                onChange: (newColumns) => {
                    this.columnSettings.setUserColumns(newColumns);
                },
            },
        });
        this.panelService.openStandardPanel(panelSettings);
    }

    /**
     * Sets the selected contact list and active filters
     *
     * @param {IContactList} list
     */
    public setSelectedContactList(list: IContactList): void {
        this.selectedContactList = list;
        if (this.selectedContactList) {
            this.activeContactListFilters[0] = {
                name: list.name,
                uuid: list.uuid,
            };
            this.contactListUuid = this.getContactListUuidFromFilter();
            this.pageService.setPageTitle(this.selectedContactList.name);
        } else {
            this.pageService.setPageTitle(this.translations['CONTACT_CENTER.CONTACT_CENTER']);
        }
    }

    public loadFromNavigation(filterParams: string): void {
        this.contactsPager.currentPage = 1;
        this.filters = filterParams;
        this.currentFilterSlug = this.getFilterSlugFromUrlParams(filterParams);
        this.contactListUuid = this.getContactListUuidFromFilter();
        this.loadChart();
        this.loadListing(filterParams);
    }

    public navIsExpanded(): boolean {
        return this.media('gt-sm') || this.navExpanded;
    }

    /**
     * Load the contact listing
     */
    public loadListing(filterParams: string = ''): void {
        this.loading = true;
        this.contacts = [];
        this.filters = filterParams;
        this.contactListUuid = this.getContactListUuidFromFilter();
        let remoteColumnSettings: RemoteStorage<ITableColumn<IContactCenterColumn>[]> = null;
        const contactListTableStorageKey: string = this.getRemoteStorageColumnKey();
        this.columnSettings = new TableColumnManager<IContactCenterColumn>(
            this.defaultColumns,
            contactListTableStorageKey
        );
        const remoteColumnUrl: string = this.columnRemoteStorageKey + '.' + contactListTableStorageKey;
        this.remoteStorageService
            .getUserDataAsEntities<TableColumn<IContactCenterColumn>>(remoteColumnUrl, TableColumn)
            .then((remoteColumnSettingsLoaded) => {
                remoteColumnSettings = remoteColumnSettingsLoaded;
            })
            .finally(() => {
                this.surveyApi
                    .findContacts(this.contactsPager, this.datatableSearch.term, this.filters)
                    .then((results: PagedEntities) => {
                        this.contacts = results.getEntities();

                        this.resetSelectionManager(false);
                        this.contactsPager = results.getPager();
                        this.surveyApi
                            .findAllCustomFields()
                            .then((customFieldsResult: PagedEntities) => {
                                this.customFields = customFieldsResult.getEntities();
                                this.hasError = false;
                            })
                            .catch(() => {
                                this.hasError = true;
                            })
                            .finally(() => {
                                if (this.customFields.length > 0) {
                                    const transformedCustomFields: ITableColumn<IContactCenterColumn>[] =
                                        this.transformCustomFieldsToColumns();
                                    const mergedDefaultAndCustomFields: ITableColumn<IContactCenterColumn>[] =
                                        this.defaultColumns.concat(transformedCustomFields);
                                    this.columnSettings.setDefaultColumns(mergedDefaultAndCustomFields);
                                }
                                this.columnSettings.setUserColumnsFromRemoteSettings(remoteColumnSettings);
                                this.loading = false;
                            });
                    })
                    .catch(() => {
                        this.loading = false;
                        this.hasError = true;
                    });
            });
    }

    /**
     * Returns true if we're currently filtering on contact lists
     *
     * @returns {boolean}
     */
    public filteringByContactList(): boolean {
        return this.filters && this.filters.indexOf('contact_lists') > -1;
    }

    /**
     * Create new contact
     */
    public createContact(): void {
        if (this.filteringByContactList()) {
            this.stateService.go(
                'home.contact-center.create',
                { contact_list: this.getContactListUuidFromFilter() },
                { inherit: false }
            );
        } else {
            this.stateService.go('home.contact-center.create', {}, { inherit: false });
        }
    }

    /**
     * Removes currently filter contact list and goes to default page
     */
    public removeFilterChip(): void {
        this.filters = null;
        this.goToSelf();
    }

    /**
     * Edit the selected user
     *
     * @param {string} uuid
     */
    public editContact(uuid: string): void {
        if (this.hasContactAdmin) {
            this.stateService.go('home.contact-center.contact-details', { uuid: uuid });
        }
    }

    /**
     * Remove a contact
     *
     * @param {IContact} contact
     */
    public removeContact(contact: IContact): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        cancelText: this.translations['GENERAL.CANCEL'],
                        confirmText: this.translations['GENERAL.DELETE'],
                        description: this.translations['CONTACT_CENTER.DELETE_CONTACT_TEXT_UNSUBSCRIBE'],
                        title: this.translations['CONTACT_CENTER.DELETE_CONTACT_CONFIRM'],
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((confirmation: boolean) => {
                if (confirmation) {
                    this.surveyApi
                        .deleteContact(contact.uuid)
                        .then(() => {
                            this.toaster.success(
                                this.translate.instant('CONTACT_CENTER.MESSAGES.DELETED_CONTACT', {
                                    email: contact.email,
                                })
                            );
                            this.loadListing(this.filters);
                        })
                        .catch(() => {
                            this.toaster.error('ERRORS.FAILED_CONTACT_DELETE');
                        });
                }
            });
    }

    /**
     * Open distribute survey modal
     *
     * @param {IContactList} contactList
     */
    public distributeSurvey(contactList: IContactList): void {
        this.dialog
            .show(
                ContactListDistributeSurveyModal.instantiate({
                    locals: {
                        contactList: contactList,
                    },
                })
            )
            .then((result: any) => {
                if (result && result.contacts_notified) {
                    this.toaster.success(
                        this.translate.instant('CONTACT_CENTER.MESSAGES.DISTRIBUTED', {
                            item: result.contacts_notified,
                        })
                    );
                }
            });
    }

    /**
     * Toggle subscription of a contact
     *
     * @param {IContact} contact
     */
    public toggleSubscription(contact: IContact): void {
        if (contact.status === 'unsubscribed') {
            // call subscribe
            this.surveyApi
                .subscribeContact(contact)
                .then((updatedContact: IContact) => {
                    this.toaster.success(
                        this.translate.instant('CONTACT_CENTER.MESSAGES.UPDATED_CONTACT', {
                            email: updatedContact.email,
                        })
                    );
                    this.loadChart();
                    this.loadListing(this.filters);
                })
                .catch(() => {
                    this.toaster.error('ERRORS.FAILED_CONTACT_UPDATE');
                });
        } else {
            // call unsubscribe
            this.surveyApi
                .unsubscribeContact(contact)
                .then((updatedContact: IContact) => {
                    this.toaster.success(
                        this.translate.instant('CONTACT_CENTER.MESSAGES.UPDATED_CONTACT', {
                            email: updatedContact.email,
                        })
                    );
                    this.loadChart();
                    this.loadListing(this.filters);
                })
                .catch(() => {
                    this.toaster.error('ERRORS.FAILED_CONTACT_UPDATE');
                });
        }
    }

    /**
     * Gets the empty text for the datalist based on the currently selected filter
     *
     * @returns {string}
     */
    public getListingEmptyText(): string {
        if (this.filteringByContactList() && this.selectedContactList) {
            return this.translate.instant('CONTACT_CENTER.CONTACT_LIST.EMPTY', { list: this.selectedContactList.name });
        }

        switch (this.filters) {
            case 'filter[status]=subscribed':
                return this.translate.instant('CONTACT_CENTER.CONTACTS.EMPTY_SUBSCRIBE');
            case 'filter[status]=unsubscribed':
                return this.translate.instant('CONTACT_CENTER.CONTACTS.EMPTY_UNSUBSCRIBE');
            default:
                return this.translate.instant('CONTACT_CENTER.CONTACTS.EMPTY_ALL');
        }
    }

    /**
     * Gets the empty CTA text for the empty state button
     *
     * @returns {string}
     */
    public getListingEmptyButtonText(): string {
        if (this.filteringByContactList()) {
            return this.translate.instant('CONTACT_CENTER.ADD_CONTACTS');
        } else {
            return this.translate.instant('CONTACT_CENTER.CREATE_CONTACT');
        }
    }

    /**
     * Returns the function to use for the listing empty states
     *
     * @returns {Function}
     */
    public emptyListingButtonFunction(): void {
        if (this.filteringByContactList()) {
            this.addContactsToContactList(this.getContactListUuidFromFilter());
        } else {
            this.createContact();
        }
    }

    /**
     * Gets the contact list uuid from the filters
     *
     * @returns {string}
     */
    public getContactListUuidFromFilter(): string {
        if (this.filteringByContactList()) {
            return this.filters.substring(this.filters.indexOf('=') + 1);
        }
        return null;
    }

    /**
     * Loads the contact lists
     */
    public loadContactLists(): void {
        // load contact lists
        const parent: INavigationCollectionItem = _.find(this.navCollection.getCollection(), {
            slug: 'home.contact-center.contact-lists.parent',
        });
        parent.loading = true;
        parent.children = [];
        this.surveyApi
            .findContactLists(Pager.make(1, 'name', 'asc', 1000))
            .then((results: PagedEntities) => {
                if (parent) {
                    parent.children = results.getEntities().map((i) => {
                        return {
                            label: i.name,
                            state: this.stateService.current.name,
                            contactList: i,
                            stateParams: {
                                filters: 'filter[contact_lists.uuid]=' + i.uuid,
                                page: undefined,
                            },
                            slug: 'home.contact-center.contact-lists.' + i.uuid,
                            icon: 'recent_actors',
                        };
                    });
                }
            })
            .catch(() => {
                this.toaster.error('ERRORS.FAILED_CONTACT_LISTING');
            })
            .finally(() => {
                parent.showChildren = true;
                parent.loading = false;
            });
    }

    /**
     * Allow a user to toggle the sort order of a column
     *
     * @param field
     */
    public toggleSort(field: string): void {
        if (this.contactsPager.isCurrentSort(field)) {
            this.contactsPager.toggleSortOrder();
        }
        this.contactsPager.sortBy = field;
        this.loadListing(this.filters);
    }

    /**
     * Reloads the page
     */
    public goToSelf(): void {
        this.stateService
            .go(
                'home.contact-center',
                {
                    search: this.datatableSearch.term,
                    page: this.contactsPager.currentPage,
                    sort_by: this.contactsPager.sortBy,
                    order: this.contactsPager.order,
                    filters: this.filters,
                },
                {
                    notify: false, // prevent the events onStart and onSuccess from firing
                    reload: false, // prevent reload of the current state
                    location: 'replace', // replace the last record when changing the params so you don't hit the back button and get old params
                    inherit: true, // inherit the current params on the url
                }
            )
            .then(() => {
                this.loadChart();
                this.loadListing(this.filters);
            });
    }

    /**
     * Open create contact modal
     */
    public createContactList(event: any = null): void {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        this.dialog.show(ContactListCreateModal.instantiate({})).then((returnData: { contactList: IContactList }) => {
            if (returnData.contactList) {
                this.toaster.success(
                    this.translate.instant('CONTACT_CENTER.MESSAGES.CREATED', { item: returnData.contactList.name })
                );
                this.filters = 'filter[contact_lists.uuid]=' + returnData.contactList.uuid;
                this.setSelectedContactList(returnData.contactList);
                this.goToSelf();
                this.loadContactLists();
            }
        });
    }

    /**
     * Edit the contact list
     *
     * @param {IContactList} contactList
     */
    public editContactList(contactList: IContactList): void {
        this.dialog
            .show(
                ContactListCreateModal.instantiate({
                    locals: {
                        contactList: contactList,
                    },
                })
            )
            .then(() => {
                this.loadContactLists();
            });
    }

    /**
     * Add contacts to the contact list
     *
     * @param uuid
     */
    public addContactsToContactList(uuid: string): void {
        this.stateService.go('home.contact-center.add-contacts', { contactListUuid: uuid }, { inherit: false });
    }

    /**
     * Delete the contact list
     *
     * @param {IContactList} contactList
     */
    public deleteContactList(contactList: IContactList): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        cancelText: this.translations['GENERAL.CANCEL'],
                        confirmText: this.translations['GENERAL.DELETE'],
                        description: this.translations['CONTACT_CENTER.CONTACT_LIST.DELETE_TEXT_2'],
                        title: this.translations['CONTACT_CENTER.CONTACT_LIST.DELETE_TITLE'],
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((confirmation: boolean) => {
                if (confirmation) {
                    this.surveyApi
                        .deleteContactList(contactList)
                        .then(() => {
                            this.toaster.success(
                                this.translate.instant('CONTACT_CENTER.MESSAGES.DELETED', { item: contactList.name })
                            );
                            if (this.selectedContactList.uuid === contactList.uuid) {
                                this.filters = null;
                                this.selectedContactList = null;
                                this.activeContactListFilters = [];
                            }
                            this.goToSelf();
                            this.loadContactLists();
                        })
                        .catch(() => {
                            this.toaster.error('ERRORS.FAILED_CONTACT_DELETE');
                        });
                }
            });
    }

    /**
     * Check if the mobile expand toggle button can be shownd
     *
     */
    public canShowMobileExpandToggle(): boolean {
        return !this.showNavigation && !this.media('print');
    }

    /**
     * Toggle navigation visibility
     *
     */
    public toggleNavigation(): void {
        this.showNavigation = !this.showNavigation;
    }

    /**
     * Creates a new link list menu item
     *
     * @param {string} title
     * @param {string} state
     * @param data
     * @param {string} icon
     * @param {Function} func
     * @param actions
     * @param search
     * @returns {ILinkListMenuItem}
     */
    public makeLinkListMenuItem(
        title: string,
        state: string,
        data: any,
        icon: string,
        func: () => void,
        actions?: Array<ILinkListMenuAction>
    ): ILinkListMenuItem {
        const item: ILinkListMenuItem = new LinkListMenuItem();
        item.stateOptions = {
            notify: false, // prevent the events onStart and onSuccess from firing
            reload: false, // prevent reload of the current state
            inherit: true, // inherit the current params on the url
        };
        item.title = title;
        item.state = state;
        item.icon = icon;
        item.data = data;
        item.onClick = func;
        item.actions = actions ? actions : null;
        return item;
    }

    /**
     * Allow a user to search for contacts by name, last name or email
     */
    public searchContacts(): void {
        this.contacts = [];
        this.contactsPager.currentPage = 1;
        this.loadListing(this.filters);
    }

    /**
     * Allow a user to go to the next page of contacts
     */
    public contactsNext(): void {
        if (this.contactsPager.canGoNext()) {
            this.contactsPager.currentPage++;
            this.goToSelf();
        }
    }

    /**
     * Allow a user to go to the previous page of contacts
     */
    public contactsPrev(): void {
        if (this.contactsPager.canGoPrevious()) {
            this.contactsPager.currentPage--;
            this.goToSelf();
        }
    }

    public export(): void {
        let status: string = null;
        let contactLists: Array<string> = [];

        if (this.filters === 'filter[status]=unsubscribed') {
            status = 'unsubscribed';
        } else if (this.filters === 'filter[status]=subscribed') {
            status = 'subscribed';
        } else if (this.filters === 'filter[deliverable]=false') {
            status = 'undeliverable';
        }

        if (this.selectedContactList) {
            contactLists = [this.selectedContactList.uuid];
        }

        this.surveyApi.exportContacts(status, contactLists).then(() => {
            this.dialog.show(ContactsExportModal.instantiate());
        });
    }

    public toggleSelection(contact: IContact, event: any): void {
        event.stopPropagation();

        this.selectionManager.toggleSelection(contact);
    }

    public bulkDelete(): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translate.instant('CONTACT_CENTER.ARE_YOU_SURE_DELETE_CONTACTS'),
                        description: this.translate.instant('CONTACT_CENTER.DELETE_CONTACTS_CONFIRM_TEXT', {
                            count: this.selectionManager.selectionCount(),
                        }),
                        confirmText: this.translate.instant('GENERAL.DELETE'),
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((confirmResp: boolean) => {
                if (confirmResp) {
                    this.bulkActioning = true;
                    const contacts: Array<any> = this.selectionManager.getSelections().map((s: IContact) => {
                        return {
                            uuid: s.uuid,
                        };
                    });

                    this.surveyApi
                        .deleteContacts(contacts)
                        .then((failures: Array<IContact>) => {
                            this.timeout(() => {
                                this.toastDeleteResponseToUser(failures, contacts);
                                this.loadListing();
                                this.bulkActioning = false;
                            }, 1000);
                        })
                        .catch((err: IErrorResponse) => {
                            if (err.type === 'BulkRequestCountMismatchException') {
                                this.toaster.info('CONTACT_CENTER.COULD_NOT_FIND_SELECTED_CONTACTS');
                            } else {
                                this.toaster.error('CONTACT_CENTER.CONTACTS_DELETED_FAILURE');
                            }

                            this.bulkActioning = false;
                        })
                        .then(() => {
                            this.resetSelectionManager(false);
                        });
                }
            });
    }

    public openContactListPicker(event: any): void {
        const config: any = {
            relativeTo: event.target,
            onSelect: (contactList: IContactList) => {
                this.addMultiSelectedContactsToContactList(contactList);
            },
            multiSelect: false,
            template: require('/app/components/pickers/contact-list/ContactListPickerPanel.html'),
            controller: 'ContactListPickerPanelController',
        };

        this.panelService.openCustomPickerPanel(config);
    }

    private transformCustomFieldsToColumns(): ITableColumn<IContactCenterColumn>[] {
        return this.customFields.map((customField) => {
            return {
                id: customField.name,
                label: customField.label,
                show: false,
                originalColumn: {
                    label: customField.label,
                    contactKey: 'customFields.' + customField.name,
                },
            };
        });
    }

    private getRemoteStorageColumnKey(): string {
        let contactListOrFilterKey: string = this.currentFilterSlug ? this.currentFilterSlug : this.contactListUuid;
        if (!contactListOrFilterKey) {
            contactListOrFilterKey = 'all';
        }

        return contactListOrFilterKey ? contactListOrFilterKey : this.columnRemoteStorageKey;
    }

    private getFilterSlugFromUrlParams(filterUrlParameters: string): ContactCenterFilterSlug {
        if (filterUrlParameters === 'filter[status]=unsubscribed') {
            return 'unsubscribed';
        } else if (this.filters === 'filter[status]=subscribed') {
            return 'subscribed';
        } else if (this.filters === 'filter[deliverable]=false') {
            return 'undeliverable';
        }

        return null;
    }

    private addMultiSelectedContactsToContactList(contactList: IContactList): void {
        this.bulkActioning = true;
        const contacts: Array<any> = this.selectionManager.getSelections().map((s: IContact) => {
            return {
                uuid: s.uuid,
            };
        });

        this.surveyApi
            .addContactsToList(contactList.uuid, contacts)
            .then((failures: Array<IContact>) => {
                this.toastAddToListResponseToUser(failures, contacts);
                this.bulkActioning = false;
            })
            .catch((err: IErrorResponse) => {
                if (err.type === 'BulkRequestCountMismatchException') {
                    this.toaster.info('CONTACT_CENTER.COULD_NOT_FIND_SELECTED_CONTACTS');
                } else {
                    this.toaster.error('CONTACT_CENTER.CONTACTS_ADDED_TO_CONTACT_LIST_FAILURE');
                }
                this.bulkActioning = false;
            })
            .then(() => {
                this.resetSelectionManager(true);
            });
    }

    private resetSelectionManager(clear: boolean): void {
        this.selectionManager = this.selectionManager || new SelectionManager(this.contacts);
        this.selectionManager.refresh(this.contacts, (item, selection) => {
            return item.uuid === selection.uuid;
        });
        if (clear) {
            this.selectionManager.clearSelections();
        }
    }

    private toastDeleteResponseToUser(failures: Array<IContact>, contacts: Array<IContact>): void {
        if (failures.length === 0) {
            this.toaster.success('CONTACT_CENTER.CONTACTS_DELETED');
        } else if (failures.length > 0 && failures.length < contacts.length) {
            this.toaster.warn('CONTACT_CENTER.CONTACTS_PARTIALLY_DELETED');
        } else {
            this.toaster.error('CONTACT_CENTER.CONTACTS_DELETED_FAILURE');
        }
    }

    private toastAddToListResponseToUser(failures: Array<IContact>, contacts: Array<IContact>): void {
        if (failures.length === 0) {
            this.toaster.success('CONTACT_CENTER.CONTACTS_ADDED_TO_LIST');
        } else if (failures.length > 0 && failures.length < contacts.length) {
            this.toaster.warn('CONTACT_CENTER.CONTACTS_PARTIALLY_ADDED_TO_LIST');
        } else {
            this.toaster.error('CONTACT_CENTER.CONTACTS_ADDED_TO_CONTACT_LIST_FAILURE');
        }
    }

    /**
     * Load the subscriptions chart
     *
     */
    private loadChart(): void {
        if (this.showChart) {
            this.chartLoading = true;
            this.chartError = false;
            this.chartEmpty = true;
            this.chartMeta = new SubscriptionChartData();

            const series: Array<any> = [];
            const timezone: string = this.accessService.getToken()
                ? this.accessService.getToken().getUser().timezone
                : null;
            const startDate: any = moment().tz(timezone).subtract(11, 'months').startOf('month');
            const endDate: any = moment().tz(timezone);
            let createdResults: IIqlQueryResult = null;
            let unsubscribedResults: IIqlQueryResult = null;
            let undeliverableResults: IIqlQueryResult = null;

            const queries: { created: IIql; unsubscribed: IIql; undeliverable?: IIql } =
                this.getSubscriptionChartQueries(
                    timezone,
                    startDate.format('YYYY-MM-DD HH:mm:ss'),
                    endDate.format('YYYY-MM-DD HH:mm:ss')
                );

            const promises: Array<ng.IPromise<any>> = [
                this.iqlApi.execute(queries.created).then((result: IIqlQueryResult) => {
                    createdResults = result;
                    if (result.hasData()) {
                        this.chartEmpty = false;

                        series.push(
                            IqlQueryResultSeriesAdapter.toHighchartsSeries(result.series[0], {
                                name: this.translations['GENERAL.CREATED'],
                                metric: 'created',
                                color: this.chartMeta.createdColor,
                            })
                        );
                    }
                }),
                this.iqlApi.execute(queries.unsubscribed).then((result: IIqlQueryResult) => {
                    unsubscribedResults = result;
                    if (result.hasData()) {
                        this.chartEmpty = false;
                        series.push(
                            IqlQueryResultSeriesAdapter.toHighchartsSeries(result.series[0], {
                                name: this.translations['GENERAL.UNSUBSCRIBED'],
                                metric: 'unsubscribed',
                                color: this.chartMeta.unsubscribedColor,
                            })
                        );
                    }
                }),
            ];

            if (queries.undeliverable) {
                promises.push(
                    this.iqlApi.execute(queries.undeliverable).then((result: IIqlQueryResult) => {
                        undeliverableResults = result;
                        if (result.hasData()) {
                            this.chartEmpty = false;
                            series.push(
                                IqlQueryResultSeriesAdapter.toHighchartsSeries(result.series[0], {
                                    name: this.translations['CONTACT_CENTER.CONTACTS.UNDELIVERABLE'],
                                    metric: 'undeliverable',
                                    color: this.chartMeta.undeliverableColor,
                                })
                            );
                        }
                    })
                );
            }

            this.q
                .all(promises)
                .then(() => {
                    // i hate dates with highcharts sometimes. The endDate.startOf('month').add(14, 'days').unix() * 1000 line removes the extra month at
                    // certain points in the month. It's fine here because we are asking for monthly data, which iql returns as the start of each month per month
                    // i feel dirty
                    this.buildSubscriptionsChart(
                        series,
                        startDate.unix() * 1000,
                        endDate.startOf('month').add(14, 'days').unix() * 1000,
                        timezone
                    );
                    this.chartMeta = this.chartMeta.setCreatedStats(createdResults);
                    this.chartMeta = this.chartMeta.setUnsubscribedStats(unsubscribedResults);
                    this.chartMeta = this.chartMeta.setUndeliverableStats(undeliverableResults);
                    this.chartMeta = this.chartMeta.setGrowthStats();
                })
                .catch(() => {
                    this.chartError = true;
                })
                .finally(() => {
                    this.chartLoading = false;
                });
        }
    }

    /**
     * Build the subscriptions highchart
     *
     * @param series
     * @param xMin
     * @param xMax
     * @param timezone
     */
    private buildSubscriptionsChart(series: Array<any>, xMin: number, xMax: number, timezone: string): void {
        series = _.orderBy(series, [(s) => s.metric, ['desc']]);

        if (!this.chartError && !this.chartEmpty) {
            this.timeout(() => {
                Highcharts.chart('its-contact-subscription-chart', {
                    chart: {
                        type: 'column',
                    },
                    title: {
                        enabled: false,
                        text: '',
                    },
                    xAxis: {
                        type: 'datetime',
                        endOnTick: false,
                        startOnTick: false,
                        min: xMin,
                        max: xMax,
                        labels: {
                            format: '{value:%Y-%m-%d}',
                            padding: 12,
                        },
                        lineColor: '#ddd',
                        tickmarkPlacement: 'on',
                    },
                    yAxis: {
                        visible: false,
                        title: {
                            enabled: false,
                            text: '',
                        },
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                        },
                    },
                    legend: {
                        enabled: false,
                    },
                    credits: {
                        enabled: false,
                    },
                    time: {
                        useUTC: false,
                        timezone: timezone,
                    },
                    series: series,
                } as Highcharts.Options);
            });
        }
    }

    /**
     * Get the iql query for the subscriptions chart
     *
     */
    private getSubscriptionChartQueries(
        timezone: string,
        startDate: string,
        endDate: string
    ): { created: IIql; unsubscribed: IIql; undeliverable?: IIql } {
        const baseIql: IIql = new Iql()
            .dataSource('trace')
            .dimension(new DateDimension('data->created_at', 'month'))
            .filter(
                new FilterGroup(Iql.AND, [
                    new Filter('data->product', Iql.EQUAL, 'survey', Iql.STRING),
                    new DateFilter('data->created_at', Iql.GREATER_THAN_EQUAL, startDate, timezone),
                    new DateFilter('data->created_at', Iql.LESS_THAN_EQUAL, endDate, timezone),
                ])
            );

        if (this.contactListUuid) {
            return {
                created: angular
                    .copy(baseIql)
                    .metric('data->action', Iql.COUNT, Iql.COUNT, 'created')
                    .filter(
                        new FilterGroup(Iql.AND, [
                            new Filter('data->domain', Iql.EQUAL, 'contact-list', Iql.STRING),
                            new Filter('data->action', Iql.EQUAL, 'contact.added', Iql.STRING),
                            new Filter(
                                'data.targets[type=contact-list].*->id',
                                Iql.EQUAL,
                                this.contactListUuid,
                                Iql.STRING
                            ),
                        ])
                    ),
                unsubscribed: angular
                    .copy(baseIql)
                    .metric('data->action', Iql.COUNT, Iql.COUNT, 'unsubscribed')
                    .filter(
                        new FilterGroup(Iql.AND, [
                            new Filter('data->domain', Iql.EQUAL, 'contact-list', Iql.STRING),
                            new Filter('data->action', Iql.EQUAL, 'contact.removed', Iql.STRING),
                            new Filter(
                                'data.targets[type=contact-list].*->id',
                                Iql.EQUAL,
                                this.contactListUuid,
                                Iql.STRING
                            ),
                        ])
                    ),
            };
        } else {
            return {
                created: angular
                    .copy(baseIql)
                    .metric('data->action', Iql.COUNT, Iql.COUNT, 'created')
                    .filter(
                        new FilterGroup(Iql.AND, [
                            new Filter('data->domain', Iql.EQUAL, 'contact', Iql.STRING),
                            new Filter('data->action', Iql.EQUAL, 'created', Iql.STRING),
                        ])
                    ),
                unsubscribed: angular
                    .copy(baseIql)
                    .metric('data->action', Iql.COUNT, Iql.COUNT, 'unsubscribed')
                    .filter(
                        new FilterGroup(Iql.AND, [
                            new Filter('data->domain', Iql.EQUAL, 'contact', Iql.STRING),
                            new Filter('data->action', Iql.EQUAL, 'unsubscribed', Iql.STRING),
                        ])
                    ),
                undeliverable: angular
                    .copy(baseIql)
                    .metric('data->action', Iql.COUNT, Iql.COUNT, 'undeliverable')
                    .filter(
                        new FilterGroup(Iql.AND, [
                            new Filter('data->domain', Iql.EQUAL, 'distribution', Iql.STRING),
                            new Filter('data->action', Iql.EQUAL, 'undelivered', Iql.STRING),
                        ])
                    ),
            };
        }
    }

    private initializeNavCollection(): void {
        this.navCollection = new NavigationCollection();
        this.navCollection
            .addToRoot({
                label: 'GENERAL.ALL',
                state: this.stateService.current.name,
                stateParams: {
                    filters: null,
                    page: undefined,
                },
                slug: 'home.contact-center.all',
                icon: 'people',
            })
            .addToRoot({
                label: 'CONTACT_CENTER.CONTACTS.SUBSCRIBED',
                state: this.stateService.current.name,
                stateParams: {
                    filters: 'filter[status]=subscribed',
                    page: undefined,
                },
                slug: 'home.contact-center.subscribed',
                icon: 'mail',
            })
            .addToRoot({
                label: 'CONTACT_CENTER.CONTACTS.UNSUBSCRIBED',
                state: this.stateService.current.name,
                stateParams: {
                    filters: 'filter[status]=unsubscribed',
                    page: undefined,
                },
                slug: 'home.contact-center.unsubscribed',
                icon: 'mail',
            })
            .addToRoot({
                label: 'CONTACT_CENTER.CONTACTS.UNDELIVERABLE',
                state: this.stateService.current.name,
                stateParams: {
                    filters: 'filter[deliverable]=false',
                    page: undefined,
                },
                slug: 'home.contact-center.undeliverable',
                icon: 'mail',
            })
            .addToRoot({
                label: 'CONTACT_CENTER.MENU.CONTACT_LISTS',
                state: null,
                slug: 'home.contact-center.contact-lists.parent',
                icon: 'recent_actors',
                divider: true,
                showChildren: true,
                children: [],
            });
    }
}
